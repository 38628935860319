export default class ArrayPreemptive {
  constructor(limit = 64) {
    this.__count = 0;
    this.__limit = limit;
    this.__queue = [];
  }
  get length() {
    return this.__count;
  }
  set length(n) {
    this.__count = Math.min(this.__limit, n);
    this.__queue.splice(this.__count, Number.MAX_VALUE);
  }
  get(idx) {
    return this.__queue[idx];
  }
  push(...items) {
    const n = items.length;
    const diff = this.__count + n - this.__limit;
    const deleteCount = diff > 0 ? Math.min(diff, this.__limit) : 0;
    const shiftDiff = n - this.__limit;
    const shift = shiftDiff > 0 ? shiftDiff : 0;
    this.__count = Math.min(this.__limit, n);
    this.__queue.splice(0, deleteCount, ...items.slice(shift));
  }
  slice() {
    return this.__queue.slice();
  }
  reverse() {
    return this.__queue.reverse();
  }
  toJSON() {
    return this.__queue;
  }
  toString() {
    return this.__queue.toString();
  }
}
