export const DASHBOARD_EVENTS = {
  SPA_LK_Mainpage_open: 'SPA_LK_Mainpage_open',
  SPA_LK_Mainpage_new_order: 'SPA_LK_Mainpage_new_order',
  SPA_LK_Mainpage_order_click: 'SPA_LK_Mainpage_order_click',
  SPA_LK_Mainpage_payment_info: 'SPA_LK_Mainpage_payment_info',
  SPA_LK_Mainpage_add_money: 'SPA_LK_Mainpage_add_money',
  SPA_LK_Mainpage_Business_click: 'SPA_LK_Mainpage_Business_click',
  SPA_LK_Mainpage_Vipiska_click: 'SPA_LK_Mainpage_Vipiska_click',
  SPA_LK_Mainpage_BKI_click: 'SPA_LK_Mainpage_BKI_click',
  SPA_LK_Limit_Info_screen_open: 'SPA_LK_Limit_Info_screen_open',
  SPA_LK_Limit_Info_screen_close: 'SPA_LK_Limit_Info_screen_close',
  SPA_LK_Hold_Info_screen_open: 'SPA_LK_Hold_Info_screen_open',
  SPA_LK_Hold_Info_screen_my_business_click:
    'SPA_LK_Hold_Info_screen_my_business_click',
};
