<template>
  <div class="white-block">
    <div class="form__title">
      <span v-if="isApprovedByCreditCommittee || isScoringStateAutoFlow"
        >нужно сделать следующее</span
      ><span v-else>пока можно сделать следующее</span>
    </div>
    <div class="form__menu">
      <template v-for="item in menuItems" :key="item.id">
        <div
          v-if="isShownSection(item)"
          class="form__menu-item d-flex justify-content-between align-items-center"
          @click="onClickGoToChosenStep(item.name)"
        >
          <span :class="isMobile ? 'small' : ''">{{ item.title }}</span>
          <span
            v-if="!isMobile"
            class="text-end"
            :class="detectTextColor(item)"
            v-text="whichTextShown(item)"
          ></span>
          <span
            v-if="isMobile"
            class="ms-4 text-end small"
            :class="detectTextColor(item)"
            v-text="whichMobileTextShown(item)"
          ></span>
          <i v-if="isShowIcon(item)" class="correct"></i></div
      ></template>
    </div>
  </div>
</template>
<script setup>
import { loanCollectUtils } from '@/components/auth/loan-card/main-flow/utils/index.js';
import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';
import { detectMobile } from '@/utils';
const {
  isScoringStateAutoFlow,
  onClickGoToChosenStep,
  isShownSection,
  detectTextColor,
  whichTextShown,
  whichMobileTextShown,
  isShowIcon,
} = loanCollectUtils();
const { isApprovedByCreditCommittee } = loanCardShared();

const { isMobile } = detectMobile();

const menuItems = [
  {
    id: 1,
    title: 'подписать присоединение к правилам',
    name: 'rules',
  },
  {
    id: 2,
    title: 'приложить фотографии паспорта',
    name: 'passport',
  },
  {
    id: 3,
    title: 'заполните паспортные данные',
    name: 'passport-manual-fill',
  },
  {
    id: 4,
    title: 'заполнить анкету поручителя',
    name: 'guarantee',
  },
  {
    id: 5,
    title: 'приложить дополнительные документы',
    name: 'documents',
  },
  {
    id: 6,
    title: 'проверить и подписать документы по СМС',
    name: 'signing',
  },
  {
    id: 7,
    title: 'подписать документы у поручителей',
    name: 'founder_signing',
  },
];
</script>
<style lang="scss" scoped>
.form__title {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: $dipedive;
  margin-bottom: 10px;
}
.form__menu {
  .form__menu-item {
    padding: 20px 0px;
    cursor: pointer;
    white-space: pre-wrap;
    @media (max-width: $size_767) {
      padding: 16px 0px;
    }
    &:not(:first-child) {
      padding: 20px 0px;
      border-top: 1px solid rgba($grey, 0.2);
    }
    &:hover:not(:first-child) {
      border-top: 1px solid rgba($grey, 0);
    }
    &:hover {
      background: $background;
      border-radius: 14px;
      padding: 20px;
    }
    &:hover + .form__menu-item {
      border-top: 1px solid rgba($grey, 0);
    }
    .correct {
      width: 18px;
      height: 18px;
      background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
      background-repeat: no-repeat;
      background-position: center;
      filter: $filter-breakwater;
    }
  }
}
</style>
