<template>
  <div class="d-flex mt-4 mb-5 align-items-center position-relative">
    <Checkbox
      v-model:isChecked="check"
      :is-disabled="props.loading"
      @callback="emit('update:isChecked', !check)"
      ><template #default>есть иные органы управления</template></Checkbox
    >
    <i class="icon-question ms-1"></i>
    <div class="popover__content">
      Если есть, укажите органы управления компанией и ФИО лиц, входящих в эти
      органы. Кроме генерального директора, общего собрания
      участников/единственного участника. Приложите документ, подтверждающий
      полномочия органа
    </div>
  </div>
  <div v-if="check" class="upload-container">
    <input
      v-model="store.currentCompanyInfo.other_management_description"
      type="text"
      placeholder="добавьте описание"
      @change="onChangeSendDescription()"
    />
    <FileUpload
      ref="upload"
      v-model="files"
      :post-action="postUserAttachmentsAsync()"
      :headers="{ Authorization: store.session.token }"
      :data="{
        kind: 'management_authority_proof',
        attachable_id: store.currentOrderId,
        attachable_type: 'Order',
      }"
      name="file"
      class="upload-wrapper"
      :multiple="true"
      :maximum="2"
      :thread="1"
      extensions="pdf"
      accept="application/pdf"
      @input-file="inputFile"
      @input-filter="inputFilter"
    />
    <div class="d-flex align-items-center mt-3">
      <i class="clip-icon me-2"></i>

      <label for="file">загрузить документ</label>
    </div>
    <div class="d-flex">
      <template v-for="(file, index) in files" :key="index">
        <div
          class="file-wrapper d-flex align-items-center position-relative mt-5"
        >
          <i class="icon-upload"></i>
          <div>
            <span>{{ file?.name || file?.filename }}</span>
            <div class="file-ext text-start color-grey">PDF</div>
          </div>
          <i
            class="icon-delete"
            @click="onClickRemoveElementAsync(file?.response?.id || file?.id)"
          ></i>
        </div>
      </template>
    </div>
  </div>
  <ErrorItem
    v-if="validationErrorRef.length !== 0"
    v-model="validationErrorRef"
  />
</template>

<script setup>
import FileUpload from 'vue-upload-component';
import Checkbox from '@/components/_generic/ui/widgets/Checkbox.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import { ref, onMounted, computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import config from '@/../config';
import server from '@/server/index';

const check = ref(false);
const files = ref([]);
const upload = ref();

const emit = defineEmits([
  'update:isChecked',
  'update:isDocumentUploaded',
  'update:validationError',
]);
const props = defineProps({
  isChecked: {
    type: Boolean,
    default: false,
  },
  isDocumentUploaded: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  validationError: {
    type: Array,
    default() {
      return [];
    },
  },
});
const store = useStateStore();

onMounted(() => {
  getUserAttachmentsAsync();
});

const validationErrorRef = computed({
  get() {
    return props.validationError;
  },
  set(value) {
    emit('update:validationError', value);
  },
});

const getUserAttachmentsAsync = () => {
  const query = {
    attachable_id: store.currentOrderId,
    kind: 'management_authority_proof',
    attachable_type: 'Order',
  };
  return server.getAttachments
    .send(query, {})
    .pipe(onGetUserAttachmentsAsyncSuccess, onGetUserAttachmentsAsyncError)
    .exec();
};
const onGetUserAttachmentsAsyncSuccess = ({ data }) => {
  if (Array.isArray(data) && data.length !== 0) {
    check.value = true;
    emit('update:isChecked', check.value);
  } else {
    check.value = false;
    emit('update:isChecked', check.value);
    store.currentCompanyInfo.other_management_description = '';
    onChangeSendDescription();
  }
  emit('update:isDocumentUploaded', check.value);
  files.value = data;
};
const onGetUserAttachmentsAsyncError = () => {};
// передаём куки в запросе на загрузку выписки
const inputFilter = (newFile, oldFile) => {
  if (newFile && oldFile && newFile.xhr && !newFile.xhr.withCredentials) {
    newFile.xhr.withCredentials = true;
  }
};
// автозагрузка файлов при добавлении
const inputFile = (newFile, oldFile) => {
  if (
    Boolean(newFile) !== Boolean(oldFile) ||
    oldFile.error !== newFile.error
  ) {
    if (upload.value && upload.value?.active === false) {
      upload.value.active = true;
    }
  }
  // show loader until load
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    if (newFile.xhr) {
      emit('update:isDocumentUploaded', check.value);
    }
  }
};
// POST запрос на загрузку файлов
const postUserAttachmentsAsync = () => {
  return config.VITE_BASE_URL + '/spa/business/attachments';
};
const onClickRemoveElementAsync = (id) => {
  const query = {
    kind: 'management_authority_proof',
    attachable_id: store.currentOrderId,
    attachable_type: 'Order',
  };
  return server.deleteAttachment$id
    .send(query, {
      params: {
        id: id,
      },
    })
    .pipe(
      () => {
        getUserAttachmentsAsync();
      },
      () => {},
    )
    .exec();
};
const onChangeSendDescription = () => {
  return server.putCompanyProfiles$company_id
    .send(
      {
        company_profile: {
          other_management_description:
            store.currentCompanyInfo.other_management_description,
        },
      },
      {
        params: {
          company_id: store.currentCompany.id,
        },
      },
    )
    .pipe(
      () => {},
      () => {},
    )
    .exec();
};
</script>

<style lang="scss" scoepd>
.icon-question {
  display: block;
  height: 12px;
  width: 12px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(@/assets/icons/rounded/question_icon.svg);
  &:hover ~ .popover__content {
    visibility: visible;
  }
}
.popover__content {
  visibility: hidden;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 14px;
  position: absolute;
  left: 0px;
  top: -140px;
  background-color: white;
  box-shadow: 0px 16px 64px rgba(183, 203, 228, 0.5);
  border-radius: 8px;
  width: auto;
}
.upload-container {
  input {
    @include input;
    height: 60px;
    border: none;
    border-radius: 8px;
  }
  .clip-icon {
    width: 18px;
    height: 18px;
    background-image: url(@/assets/icons/clip.svg);
    background-repeat: no-repeat;
    background-position: center;
    filter: $filter-tiffany;
    cursor: pointer;
  }
  label {
    cursor: pointer;
    color: $tiffany;
  }

  .file-wrapper {
    width: 220px;
    height: 60px;
    background-color: $background;
    border-radius: 8px;
    padding: 10px;
    text-overflow: ellipsis;
    &:first-child {
      margin-right: 10px;
    }
    .icon-upload {
      min-width: 40px;
      min-height: 40px;
      margin-right: 10px;
      background-image: url(@/assets/icons/files/file-uploads-sm-void.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
    .icon-delete {
      position: absolute;
      right: -5px;
      top: -5px;
      width: 16px;
      height: 16px;
      background-image: url(@/assets/icons/exits/close-icon-rounded.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
    span {
      display: inline-block;
      width: 150px;
      font-size: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .file-ext {
      margin-top: -4px;
      font-size: 8px;
      line-height: 12px;
      text-transform: uppercase;
      font-weight: 600;
      letter-spacing: 0.1em;
    }
  }
}
</style>
