<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="container-page-background">
    <div class="container-page">
      <div class="page-title">полное досрочное погашение</div>
      <div class="content-wrapper">
        <div class="content-left white-block">
          <div class="content-row">
            <div class="row-title">заём</div>
            <div class="row-content">
              <CustomSelect
                v-model="selectedLoanId"
                :items="activeLoans"
                :default-title="defaultTitle"
                :disabled="loading"
                type="alt"
                @update:model-value="changeSelectedLoanId"
              >
                <template #item="{ item }">
                  <div class="custom-select-block">
                    <div class="custom-select-block-left">
                      <div class="custom-select-title">
                        №{{ item.data.id }} от
                        {{ formatDate(item.data.started_at) }}
                      </div>
                      <div class="custom-select-amount">
                        {{ formatCurrency(item.data.amount, 2) }}
                        ₽
                      </div>
                    </div>
                    <div class="custom-select-block-right">
                      <div
                        v-if="
                          item.data.early_payments_notification_type ===
                          'success'
                        "
                        class="tag-allow-repayment"
                      >
                        доступно для погашения
                      </div>
                      <div
                        v-if="
                          item.data.early_payments_notification_type !==
                          'success'
                        "
                        class="tag-disable-repayment"
                      >
                        сейчас погашение недоступно
                      </div>
                    </div>
                  </div>
                </template>
              </CustomSelect>

              <div
                v-if="currentLoanNotificationType === 'success' && calculated"
                class="calc-items"
              >
                <div class="calc-item">
                  <div class="text-desc">
                    предварительный расчет суммы для полного погашения
                  </div>
                  <div class="text-title color-tiffany">
                    {{ formatCurrency(calculated.calculation.total, 2) }} ₽
                  </div>
                </div>
                <div class="divider"></div>
                <div class="calc-item">
                  <div class="text-desc">ндфл для уплаты в бюджет</div>
                  <div class="text-title">
                    {{ formatCurrency(calculated.calculation.tax, 2) }} ₽
                  </div>
                </div>
                <div class="divider"></div>
                <div class="calc-item">
                  <div class="text-desc">сумма на лицевом счету</div>
                  <div class="text-title">
                    {{
                      formatCurrency(calculated.calculation.current_balance, 2)
                    }}
                    ₽
                  </div>
                </div>
              </div>

              <div v-if="errors && errors.length > 0" class="text-danger mt-4">
                <div v-for="(error, index) in errors" :key="index">
                  <div v-html="error"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="activeLoans.length > 0"
          class="content-right"
          :class="{
            'notes-info-block': isInfoStatus,
            'notes-alert-block': !isInfoStatus,
          }"
        >
          <template
            v-if="currentLoanNotificationType === 'success' && !calculated"
          >
            <div>
              <div class="notes-title">полезный совет</div>
              <div class="notes-list">
                запустите погашение до обеда. Так деньги успеют поступить на
                расчетный счет
                <span style="text-transform: initial">Потока</span> в тот же
                день.
              </div>
            </div>
          </template>
          <template
            v-if="currentLoanNotificationType === 'success' && calculated"
          >
            <div>
              <div class="notes-title">условия</div>
              <div class="notes-list">
                после запуска погашения проверьте точную сумму платежа и внесите
                ее на лицевой счет.
                <br /><br />
                для успешного погашения полная сумма должна поступить на лицевой
                счет до конца дня.
              </div>
            </div>
          </template>
          <template v-if="currentLoanNotificationType === 'already_launched'">
            <div>
              <div class="notes-title">запустили погашение</div>
              <div class="notes-list">
                вы можете досрочно погасить заем {{ selectedLoanId }} от
                {{ formatDate(selectedLoan?.started_at) }}
                <span v-if="selectedLoan?.active_early_payment_amount">{{
                  `на сумму ${formatCurrency(
                    selectedLoan?.active_early_payment_amount,
                    2,
                  )} ₽`
                }}</span
                >. не забудьте пополнить баланс
              </div>
            </div>
            <div class="text-desc text-desc-down">
              сделать полное досрочное погашение нового займа, можно будет после
              выполненного погашения
            </div>
          </template>
          <template v-if="currentLoanNotificationType === 'upcoming_payment'">
            <div>
              <div class="notes-title">скоро день платежа</div>
              <div class="notes-list">
                вы не можете совершить полное досрочное погашение в день платежа
                по графику. попробуйте завтра
              </div>
            </div>
          </template>
          <template v-if="currentLoanNotificationType === 'overdue'">
            <div>
              <div class="notes-title">задолженность</div>
              <div class="notes-list">
                пперед тем, как запустить процесс погашения, закройте текущую
                задолженность.
                <a href="mailto:ok@potok.team" class="color-tiffany"
                  >ok@potok.team</a
                >
              </div>
            </div>
          </template>
          <template v-if="currentLoanNotificationType === 'commission'">
            <div>
              <div class="notes-title">у займа есть комиссия</div>
              <div class="notes-list">
                если вы хотите запустить процесс досрочного погашения, свяжитесь
                с нами по почте
                <a href="mailto:ok@potok.team" class="color-tiffany"
                  >ok@potok.team</a
                >
              </div>
            </div>
          </template>
          <template
            v-if="currentLoanNotificationType === 'other_already_launched'"
          >
            <div>
              <div class="notes-title">уже есть активное погашение</div>
              <div class="notes-list">
                проводить полное досрочное погашение можно только по одному
                займу одновременно
              </div>
            </div>
          </template>
        </div>
      </div>
      <div class="actions-wrapper">
        <PotokButton
          v-if="
            !loading && currentLoanNotificationType === 'success' && !calculated
          "
          class="button button-repayment"
          text="рассчитать сумму"
          size="large"
          @click="onClickCalculateRepayment()"
        />

        <PotokButton
          v-if="
            !loading && currentLoanNotificationType === 'success' && calculated
          "
          class="button button-repayment"
          text="запустить погашение"
          size="large"
          @click="onClickRepayment()"
        />

        <PotokButton
          v-if="
            (!loading && currentLoanNotificationType === 'commission') ||
            currentLoanNotificationType === 'overdue' ||
            currentLoanNotificationType === 'upcoming_payment'
          "
          class="button button-repayment"
          text="внести платеж"
          size="large"
          @click="onClickRefill()"
        />

        <LoadingBtn v-if="loading" class="button button-repayment" />

        <PotokButton
          class="button button-cancel"
          :disabled="loading"
          text="отмена"
          size="large"
          theme="tertiary"
          @click="onClickCancel()"
        />
      </div>
    </div>

    <ModalPhoneConfirmation
      v-if="isShowModalConfirmation"
      :left-time="leftTime"
      :agreement-id="chdpId"
      :loading="loading"
      :errors-arr="errors"
      :title="`полное досрочное погашение займа № ${selectedLoanId} от ${formatDate(
        new Date(),
      )}`"
      :width="'440px'"
      :phone="store.currentUser.phone"
      @confirm-otp-async="confirmOtpAsync"
      @send-otp-async="sendOtpAsync"
      @clear-errors="clearErrors"
      @close-modal="onClickCloseModalConfirmation"
      @go-back="onClickCloseModalConfirmation"
    />
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { ref, onMounted } from 'vue';
import { useRouter } from 'vue-router';

import CustomSelect from '@/components/_generic/ui/widgets/CustomSelect.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { formatCurrency, formatDate } from '@/utils';
import server from '@/server/index';
import ModalPhoneConfirmation from '@/components/_generic/ui/modals/ModalPhoneConfirmation.vue';
import { loansUtils } from '@/components/auth/loan-repayment/loansUtils.js';
import { useStateStore } from '@/stores/stateStore';

const router = useRouter();
const store = useStateStore();

const {
  loading,
  currentLoanNotificationType,
  isInfoStatus,
  selectedLoan,
  selectedLoanId,
  defaultTitle,
  activeLoans,
  leftTime,
  getLoansAsync,
  onClickCancel,
  startCountdown,
} = loansUtils();

const errors = ref([]);
const isShowModalConfirmation = ref(false);
const chdpId = ref(null);
const signatureId = ref(null);
const calculated = ref(null);

const clearErrors = () => {
  errors.value = [];
};

const onClickCalculateRepayment = () => {
  loading.value = true;
  calculated.value = null;
  return server.getCalculateEarlyPayment
    .send(
      {
        loan_id: selectedLoanId.value,
      },
      {},
    )
    .pipe(onCalculateRepaymentAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};

const onCalculateRepaymentAsyncSuccess = ({ data }) => {
  loading.value = false;
  calculated.value = data;
};

const onClickRepayment = () => {
  loading.value = true;
  chdpId.value = null;
  calculated.value = null;
  return server.postEarlyPayments
    .send(
      {
        early_payment_type: 'full',
        loan_id: selectedLoanId.value,
      },
      {},
    )
    .pipe(onClickRepaymentAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};
const onClickRepaymentAsyncSuccess = ({ data }) => {
  loading.value = false;
  if (data.id) {
    chdpId.value = data.id;
    sendOtpAsync();
  }
  if (data.error && data.error.messages) {
    errors.value = data.error.messages;
  }
};

const sendOtpAsync = () => {
  loading.value = true;
  calculated.value = null;
  return server.postRequestOtp
    .send(
      {
        signature: {
          kind: 'full_early_payment',
          confirmable_type: 'EarlyPayment',
          confirmable_id: chdpId.value,
        },
      },
      {},
    )
    .pipe(onSendOtpAsyncAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};

const onSendOtpAsyncAsyncSuccess = ({ data }) => {
  loading.value = false;
  isShowModalConfirmation.value = true;
  if (data.id) {
    signatureId.value = data.id;
    startCountdown();
  }
  if (data.error && data.error.messages) {
    errors.value = data.error.messages;
  }
};
const confirmOtpAsync = (rawOtp) => {
  loading.value = true;
  return server.putEarlyPaymentsId
    .send(
      {
        signature: {
          code: rawOtp,
        },
      },
      {
        params: {
          signatureId: signatureId.value,
        },
      },
    )
    .pipe(onConfirmOtpAsyncAsyncAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};
const onConfirmOtpAsyncAsyncAsyncSuccess = ({ response }) => {
  loading.value = false;
  onClickCloseModalConfirmation();

  if (response.status === 200) {
    getLoansAsync({ early_payment: 'full' });
  }
};
const onClickCloseModalConfirmation = () => {
  isShowModalConfirmation.value = false;
};
const onClickRefill = () => {
  calculated.value = null;
  router.push({
    name: 'accountRefill',
  });
};

const changeSelectedLoanId = () => {
  calculated.value = null;
  errors.value = [];
};

onMounted(() => {
  calculated.value = null;
  getLoansAsync({ early_payment: 'full' });
});
</script>
<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}
.content-left {
  margin-right: 30px;
  flex: 1;

  @media (max-width: $size_767) {
    margin-right: initial;
  }
}
.row-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2px;
  text-transform: lowercase;
}
.row-content {
  margin-top: 20px;
}
.text-desc {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $grey;
  margin-top: 10px;
}
.text-desc-down {
  margin-top: 78px;
}
.content-right {
  max-width: 373px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $size_767) {
    max-width: initial;
  }
}
.notes-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2px;
  text-transform: lowercase;
}
.notes-list {
  margin-top: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: lowercase;
}
.actions-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.button {
  width: initial;
  @media (max-width: $size_767) {
    width: 100%;
    margin-bottom: 20px;
  }
}
.button-repayment {
  margin-right: 20px;
  min-width: 130px;
  @media (max-width: $size_767) {
    margin-right: initial;
  }
}
.button-cancel {
  min-width: 116px;
}
.custom-select-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $grey;
}
.custom-select-amount {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2px;
  text-transform: lowercase;
  color: $dipedive;
  margin-top: 14px;
}
.custom-select-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.custom-select-block-left {
  flex: 2;
}
.tag-allow-repayment {
  background-color: rgba(4, 204, 217, 0.1);
  border-radius: 6px;
  color: $tiffany;
  padding: 4px 8px;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
.tag-disable-repayment {
  background-color: rgba(255, 87, 84, 0.1);
  border-radius: 6px;
  color: #ff5754;
  padding: 4px 8px;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.calc-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 14px 0;
}
</style>
