<template>
  <div class="terms-title">{{ props.title }}</div>
  <div class="terms-content" :class="props.fontSize ? 'small' : ''">
    <div class="terms-content-row">
      <td class="terms-content-item">сумма займа</td>
      <td class="terms-content-item">
        {{
          formatCurrency(store.pages?.loanTerm?.amountCustomParams, 0)
        }}&nbsp;₽
      </td>
    </div>
    <div class="terms-content-row">
      <div class="terms-content-item">
        <div class="commision-title">
          комиссия
          <i
            v-if="store.isCommissionChoiceAllowed"
            :class="{
              'icon-setting-grey': !isShowSelectCommision,
              'icon-setting-black': isShowSelectCommision,
            }"
            @click="onClickSelectComission()"
          ></i>
        </div>
        <div class="commision-title-panel">
          <ModalCommisionChoice
            v-if="store.isCommissionChoiceAllowed && isShowSelectCommision"
            v-model="store.pages.loanTerm.selectedComissionTypeCustomParams"
            @close="isShowSelectCommision = false"
          >
          </ModalCommisionChoice>
        </div>
      </div>
      <div class="terms-content-item">
        <template v-if="!store.isFeatureCommissionDiscount"
          ><span
            >{{ formatCurrency(oneTimeCommission, 0) }} ₽&nbsp;({{
              oneTimeCommissionPercent
            }}%)</span
          >
        </template>
        <template v-if="store.isFeatureCommissionDiscount">
          <span
            v-if="isShowComissionLineThrough"
            class="color-grey text-line-through me-1"
          >
            {{ formatCurrency(oneTimeCommission, 0) }}
            ₽&nbsp;({{ oneTimeCommissionPercent }}%)</span
          >
          <span
            :class="{
              'color-tiffany font-bold': oneTimeCommissionPercent === 0,
            }"
          >
            <span>{{ formatCurrency(oneTimeCommissionWithDiscount, 0) }} ₽</span
            >&nbsp;({{ oneTimeCommissionPercentWithDiscount }}%)</span
          >
        </template>
      </div>
    </div>
    <div class="terms-content-row">
      <div class="terms-content-item">вы получите</div>
      <div v-if="!isRegularCommisionSelected" class="terms-content-item">
        {{ formatCurrency(calculateAmountMinusCommission, 0) }}
        ₽
      </div>
      <div v-if="isRegularCommisionSelected" class="terms-content-item">
        {{ formatCurrency(store.pages?.loanTerm?.amountCustomParams, 0) }}
        ₽
      </div>
    </div>
    <div class="terms-content-row">
      <div class="terms-content-item">срок</div>
      <div class="terms-content-item">
        {{ props.loanPeriod }} месяц{{
          pluralize(props.loanPeriod, ['', 'а', 'ев'])
        }}
      </div>
    </div>
    <div v-if="!store.isProductTypeInstallment" class="terms-content-row">
      <div class="terms-content-item">частота погашения</div>
      <div class="terms-content-item">
        {{
          calculateRepaymentFrequency(store.currentOrder.repayment_frequency)
        }}
      </div>
    </div>
    <div class="terms-content-row">
      <div class="terms-content-item">регулярный платеж</div>
      <div class="terms-content-item text-tiffany">
        ~&nbsp;{{ formatCurrency(regularPaymentAmount, 0) }}&nbsp;₽
      </div>
    </div>
    <div
      v-if="
        !store.isProductTypeInstallment &&
        store.currentOrder.visual_rate_mode !==
          constants.VISUAL_RATE_MODES.hidden
      "
      class="terms-content-row"
    >
      <div class="terms-content-item">
        ставка
        <span
          v-if="
            store.currentOrder.visual_rate_mode ===
            constants.VISUAL_RATE_MODES.monthly
          "
          >в месяц</span
        ><span
          v-if="
            store.currentOrder.visual_rate_mode ===
              constants.VISUAL_RATE_MODES.yearly ||
            !store.currentOrder.visual_rate_mode
          "
          >годовых</span
        >
      </div>
      <div class="terms-content-item">
        {{
          store.currentOrder.visual_rate_mode ===
          constants.VISUAL_RATE_MODES.monthly
            ? (
                calculateAnnualRate(
                  props.loanPeriod,
                  store.currentOrder.loan_terms_offer?.terms,
                  store.currentOrder?.rate,
                  props.type,
                ) / 12
              )?.toFixed(1) || 0
            : calculateAnnualRate(
                props.loanPeriod,
                store.currentOrder.loan_terms_offer?.terms,
                store.currentOrder?.rate,
                props.type,
              ) || 0
        }}%
      </div>
    </div>

    <div class="terms-content-row">
      <div class="terms-content-item w-100">
        <FeedbackIcon
          v-if="store.currentOrder.feedback_allowed"
          @close="onCloseFeedbackIcon"
        />
      </div>
    </div>
  </div>
  <slot name="documents"></slot>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import {
  getCurrentPeriodData,
  calculateOneTimeCommissionPercent,
  calculateOneTimeCommission,
  calculateRepaymentFrequency,
  calculateAnnualRate,
  calculateInterestAmount,
  calculateInterestAmountAnnuitet,
} from '@/components/auth/dashboard/loan-terms/utils';
import { formatCurrency, pluralize } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import constants from '@/constants';
import ModalCommisionChoice from '@/components/auth/dashboard/loan-terms/modules/ModalCommisionChoice.vue';
import tracker from '@/tracker';
import FeedbackIcon from '@/components/_generic/auth/feedback-form/FeedbackIcon.vue';

const store = useStateStore();
const props = defineProps({
  loanPeriod: { type: Number, default: 0 },
  title: {
    type: String,
    default: '',
  },
  fontSize: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    required: true,
    default: '',
  },
});

const isTriggeredShowModalFeedback = ref(false);
const isShowSelectCommision = ref(false);

const calculateAmountMinusCommission = computed(() => {
  return (
    store.pages?.loanTerm?.amountCustomParams -
    calculateOneTimeCommission({
      amount: store.pages?.loanTerm?.amountCustomParams,
      period: props.loanPeriod,
      terms: store.currentOrder.loan_terms_offer?.terms,
      percent: store.currentOrder.upfront_percent,
      type: props.type,
    })
  );
});

/**
 * Если включена фича выбора типа комиссии и выбран тип комиссия в ежемесячном платеже
 */
const isRegularCommisionSelected = computed(() => {
  return (
    store.isCommissionChoiceAllowed &&
    store.pages.loanTerm.selectedComissionTypeCustomParams ===
      'monthly_commission'
  );
});

/**
 * Расчет регулярного платежа
 */
const regularPaymentAmount = computed(() => {
  let result;
  if (store.currentOrder.schedule_type === constants.SCHEDULE_TYPE.annuitet) {
    result = calculateInterestAmountAnnuitet({
      amount: store.pages?.loanTerm?.amountCustomParams,
      period: props.loanPeriod,
      terms: store.currentOrder.loan_terms_offer?.terms,
      repayment_frequency: store.currentOrder.repayment_frequency,
      upfront_percent: store.currentOrder.upfront_percent,
      rate: store.currentOrder?.rate,
      type: props.type,
    });
  } else {
    result = calculateInterestAmount({
      amount: store.pages?.loanTerm?.amountCustomParams,
      period: props.loanPeriod,
      terms: store.currentOrder.loan_terms_offer?.terms,
      repayment_frequency: store.currentOrder.repayment_frequency,
      upfront_percent: store.currentOrder.upfront_percent,
      rate: store.currentOrder?.rate,
      type: props.type,
    });
  }

  if (isRegularCommisionSelected.value) {
    result += store.isFeatureCommissionDiscount
      ? oneTimeCommissionWithDiscount.value
      : oneTimeCommission.value;
  }
  return result;
});

/**
 * Возвращает комиссию без скидки
 */
const oneTimeCommissionPercent = computed(() => {
  let type = 'custom_params';
  let commission;
  const period = props.loanPeriod;
  const terms = store.currentOrder.loan_terms_offer?.terms;

  if (isRegularCommisionSelected.value) {
    type = 'monthly_commission';
    commission = getCurrentPeriodData(
      period,
      terms,
    )?.monthly_commission_percent;
  }
  return calculateOneTimeCommissionPercent({
    period: period,
    terms: terms,
    inputedAmount: store.pages?.loanTerm?.amountCustomParams,
    type: type,
    commission: commission,
  });
});
/**
 * Возвращает процент комиссию с учетом скидки
 */
const oneTimeCommissionPercentWithDiscount = computed(() => {
  let type = 'custom_params_commission_discount';
  let commission;
  const period = props.loanPeriod;
  const terms = store.currentOrder.loan_terms_offer?.terms;

  if (isRegularCommisionSelected.value) {
    type = 'monthly_commission_with_discount';
    commission = getCurrentPeriodData(
      period,
      terms,
    )?.monthly_commission_percent_with_discount;
  }
  return calculateOneTimeCommissionPercent({
    period: period,
    terms: terms,
    percent: store.currentOrder.upfront_percent,
    inputedAmount: store.pages?.loanTerm?.amountCustomParams,
    type: type,
    commission: commission,
  });
});

/**
 * Возвращает сумму комиссию без скидки
 */
const oneTimeCommission = computed(() => {
  const period = props.loanPeriod;
  const terms = store.currentOrder.loan_terms_offer?.terms;

  return calculateOneTimeCommission({
    amount: store.pages?.loanTerm?.amountCustomParams,
    period: period,
    terms: terms,
    percent: store.currentOrder.upfront_percent,
    inputedAmount: store.pages?.loanTerm?.amountCustomParams,
    type: isRegularCommisionSelected.value
      ? 'monthly_commission'
      : 'custom_params',
    commission: isRegularCommisionSelected.value
      ? getCurrentPeriodData(period, terms)?.monthly_commission_percent
      : null,
  });
});

/**
 * Возвращает сумму комиссию с учетом скидки
 */
const oneTimeCommissionWithDiscount = computed(() => {
  const period = props.loanPeriod;
  const terms = store.currentOrder.loan_terms_offer?.terms;
  return calculateOneTimeCommission({
    amount: store.pages?.loanTerm?.amountCustomParams,
    period: props.loanPeriod,
    terms: terms,
    percent: store.currentOrder.upfront_percent,
    inputedAmount: store.pages?.loanTerm?.amountCustomParams,
    type: isRegularCommisionSelected.value
      ? 'monthly_commission_with_discount'
      : 'custom_params_commission_discount',
    commission: isRegularCommisionSelected.value
      ? getCurrentPeriodData(period, terms)
          ?.monthly_commission_percent_with_discount
      : null,
  });
});

const isShowComissionLineThrough = computed(() => {
  if (isRegularCommisionSelected.value) {
    const period = props.loanPeriod;
    const terms = store.currentOrder.loan_terms_offer?.terms;

    const monthly_commission_percent = getCurrentPeriodData(
      period,
      terms,
    )?.monthly_commission_percent;
    const monthly_commission_percent_with_discount = getCurrentPeriodData(
      period,
      terms,
    )?.monthly_commission_percent_with_discount;

    return (
      monthly_commission_percent !== monthly_commission_percent_with_discount
    );
  }
  const commission = getCurrentPeriodData(
    props.loanPeriod,
    store.currentOrder.loan_terms_offer?.terms,
  )?.commission_percent;
  const commission_with_discount = getCurrentPeriodData(
    props.loanPeriod,
    store.currentOrder.loan_terms_offer?.terms,
  )?.commission_percent_with_discount;
  return (
    (store.pages?.loanTerm?.amountCustomParams * commission) / 100 !==
    (store.pages?.loanTerm?.amountCustomParams * commission_with_discount) / 100
  );
});

const onClickSelectComission = () => {
  tracker.queue(tracker.commands.SEND, 'SPA_VKL_commision_type_click');
  isShowSelectCommision.value = true;
};

const onCloseFeedbackIcon = () => {
  isTriggeredShowModalFeedback.value = false;
};

onMounted(() => {
  if (store.isCommissionChoiceAllowed) {
    tracker.queue(tracker.commands.SEND, 'SPA_VKL_commision_type_applicable');
  }
});
</script>

<style lang="scss" scoped>
.terms-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: $dipedive;
  margin-bottom: 10px;
}

.terms-content {
  width: 100%;
  display: flex;
  flex-direction: column;

  .terms-content-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .terms-content-item:first-child {
      color: $grey;
    }
  }

  .terms-content-item {
    padding: 12px 0px;

    &:last-child {
      text-align: end;
    }
  }
}

.commision-title {
  display: flex;
  align-items: center;

  i {
    margin-left: 5px;
    cursor: pointer;
  }
}
.commision-title-panel {
  position: relative;
}
</style>
