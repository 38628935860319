export const LOAN_EVENTS = {
  SPA_LK_Loan_open: 'SPA_LK_Loan_open',
  SPA_LK_Loan_schedule_pdf: 'SPA_LK_Loan_schedule_pdf',
  SPA_LK_Loan_schedule_xls: 'SPA_LK_Loan_schedule_xls',
  SPA_LK_Loan_schedule_switch: 'SPA_LK_Loan_schedule_switch',
  SPA_LK_Loan_history_switch: 'SPA_LK_Loan_history_switch',
  SPA_LK_Loan_comission_pay_click: 'SPA_LK_Loan_comission_pay_click',
  SPA_LK_Loan_main_page_click: 'SPA_LK_Loan_main_page_click',
  SPA_LK_Loan_balance_add_click: 'SPA_LK_Loan_balance_add_click',
  SPA_LK_Loan_repay_partly_click: 'SPA_LK_Loan_repay_partly_click',
  SPA_LK_Loan_repay_early_click: 'SPA_LK_Loan_repay_early_click',
  SPA_LK_Loan_offer_click: 'SPA_LK_Loan_offer_click',
  SPA_LK_Loan_docks_click: 'SPA_LK_Loan_docks_click',
};
