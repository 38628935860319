<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="message message-info"
    :class="{
      'message-info': props.messageType === 'notice',
      'message-alert': props.messageType === 'alert',
      'message-marketing': props.messageType === 'marketing',
    }"
  >
    <div
      class="icon-message"
      :class="{
        'icon-message-info': props.messageType === 'notice',
        'icon-message-alert': props.messageType === 'alert',
        'icon-message-marketing': props.messageType === 'marketing',
      }"
    ></div>

    <div class="message-content">
      <slot name="content"></slot>
      <div v-if="message" v-html="props.message"></div>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  message: {
    type: String,
    required: false,
    default: '',
  },
  messageType: {
    type: String,
    default: 'notice',
  },
});
</script>
<style lang="scss" scoped></style>
