<template>
  <div class="dadata-component">
    <input
      :id="`input-${uuid}`"
      v-model="model"
      v-maska
      type="text"
      class="dadata-component-input"
      :data-maska="props.dataMaska"
      :readonly="props.disabled"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      @keyup="debouncedFn()"
      @focus="onFocus"
    />
    <DaDataComponentSuggestionList
      v-if="suggestionsArr.length !== 0"
      v-model="suggestionsArr"
      class="mt-2"
      width="340px"
      :keys-in-arr="['name', 'inn']"
      @choose-suggestion="onClickSelect"
    />
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import DaDataComponentSuggestionList from './DaDataComponentSuggestionList.vue';

import { vMaska } from 'maska';
import { useDebounceFn } from '@vueuse/core';
import server from '@/server/index';

const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const emit = defineEmits(['update:modelValue', 'inputValue', 'focus']);
const props = defineProps({
  modelValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  dataMaska: {
    type: String,
    default: '',
  },
});

const loading = ref(false);
const suggestionsArr = ref([]);

const uuid = uuidv4();

const model = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const onFocus = () => {
  emit('focus');
};
const debouncedFn = useDebounceFn(() => {
  postDadataInnAsync();
}, 500);
const postDadataInnAsync = () => {
  const query = {
    query: model.value,
    status: ['ACTIVE'],
  };
  loading.value = true;
  return server.postDadataInn
    .send(query)
    .pipe(onPostDadataInnAsyncSuccess, onPostDadataInnAsyncError)
    .exec();
};

const onPostDadataInnAsyncSuccess = ({ data }) => {
  const response = Array.isArray(data.suggestions) ? data.suggestions : [];
  response.forEach(function (array_item, index) {
    suggestionsArr.value[index] = {
      inn: array_item.data.inn,
      name: array_item.data.name.full_with_opf,
    };
  });
  loading.value = false;
};
const onPostDadataInnAsyncError = () => {
  loading.value = false;
};

const onClickSelect = (selectedInn) => {
  if (!selectedInn) {
    return;
  }
  model.value = selectedInn.inn;
  suggestionsArr.value = [];
  emit('inputValue');
};
</script>
<style lang="scss" scoped>
input {
  &:focus,
  &:focus-visible {
    background-color: #f2f4f8;
    box-shadow: none;
    border-color: transparent;
    outline: 0;
  }
}
label {
  width: 100%;
}
.dadata-component {
  position: relative;
}
.dadata-component-input {
  background: $background;
  border-radius: 8px;
  height: 60px;
  color: $dipedive;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  border: 0;
  padding: 0 20px;
  width: 100%;
}

:deep(.suggestions-wrapper) {
  .suggestions-suggestions {
    margin-top: 10px;
    background: #ffffff;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    border-radius: 12px;
    border: 1px solid #eee;
    padding: 10px;
  }
  .suggestions-suggestion {
    padding: 10px;
    &:hover {
      background: $background;
      border-radius: 8px;
    }
  }
  .suggestions-subtext {
    color: $grey;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
  }
  .suggestions-subtext_inline {
    color: $tiffany;
    margin-right: 5px;
  }
  .suggestions-value {
    color: $dipedive;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}
</style>
