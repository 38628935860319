<template>
  <div>
    <div class="button-wrapper">
      <PotokButton
        class="mb-3"
        text="пополнить баланс"
        size="medium"
        theme="secondary"
        @click="onClickGoToRefill"
      />
      <PotokButton
        text="сводный график платежей"
        size="medium"
        theme="secondary"
        @click="onClickOpenPayments"
      />
    </div>

    <table class="table-mobile mt-3 w-100">
      <tbody>
        <tr v-if="store.isOverdue" class="color-grey">
          <td>
            просрочка
            <OverdueHint
              class="ms-1"
              :main="store.currentCompany.funds.overdue?.main"
              :interest="store.currentCompany.funds.overdue?.interest"
              :fine="store.currentCompany.funds.overdue?.fine"
              :fine-tax="store.currentCompany.funds.overdue?.fine_tax"
              :ndfl="store.currentCompany.funds.overdue?.ndfl"
              :monthly-commission="
                store.currentCompany.funds.overdue?.monthly_commission
              "
            />
          </td>
          <td class="text-danger text-end">
            {{ overdueAmount }}
            ₽
          </td>
        </tr>
        <tr>
          <td class="color-grey">осталось погасить</td>
          <td class="text-end">
            {{
              formatCurrency(store.currentCompany.funds?.debt?.residue?.main, 0)
            }}
            ₽
          </td>
        </tr>
        <tr>
          <td v-if="store.isLoanExists" class="color-grey">
            НДФЛ<i
              class="question-icon-black ms-1 cursor-pointer"
              @click="emit('update:isModalShown', true)"
            ></i>
          </td>
          <td
            v-if="store.isLoanExists"
            class="text-end"
            v-text="
              store.isPaymentToday
                ? formatCurrency(
                    store.currentCompany.funds?.next_payment?.ndfl,
                    0,
                  ) + ' ₽'
                : 'будет рассчитан в дату платежа'
            "
          ></td>
        </tr>
        <NdflHint
          v-if="props.isModalShown"
          @close-modal="emit('update:isModalShown', false)"
        />
      </tbody>
    </table>

    <PaymentSummaryMobile
      v-if="isShowPaymentSummaryMobile"
      :is-payment-today="store.isPaymentToday"
      @close="isShowPaymentSummaryMobile = false"
    />
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { PotokButton } from 'potok-uikit';

import OverdueHint from '@/components/auth/dashboard/modules/OverdueHint.vue';
import NdflHint from '@/components/auth/dashboard/modules/NdflHint.vue';
import { useStateStore } from '@/stores/stateStore';
import { formatCurrency } from '@/utils';
import { useRouter } from 'vue-router';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';
import PaymentSummaryMobile from '@/components/auth/dashboard/payment-summary/PaymentSummaryMobile.vue';

const { SPA_LK_Mainpage_add_money } = TRACKER_EVENTS;

const store = useStateStore();
const router = useRouter();

const props = defineProps({
  isModalShown: {
    type: Boolean,
    required: true,
  },
});
const emit = defineEmits(['update:isModalShown']);

const isShowPaymentSummaryMobile = ref(false);

const onClickGoToRefill = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Mainpage_add_money);
  router.push({
    name: 'accountRefill',
  });
};

const onClickOpenPayments = () => {
  isShowPaymentSummaryMobile.value = true;
};

const overdueAmount = computed(() => {
  let value = 0;
  if (store.currentCompany?.funds?.overdue?.main) {
    value += store.currentCompany?.funds?.overdue?.main;
  }
  if (store.currentCompany?.funds?.overdue?.interest) {
    value += store.currentCompany?.funds?.overdue?.interest;
  }
  if (store.currentCompany?.funds?.overdue?.penalty) {
    value += store.currentCompany?.funds?.overdue?.penalty;
  }
  if (store.currentCompany?.funds?.overdue?.fine) {
    value += store.currentCompany?.funds?.overdue?.fine;
  }
  if (
    store.isBillingMonthlyCommission &&
    store.currentCompany?.funds?.overdue?.monthly_commission
  ) {
    value += store.currentCompany?.funds?.overdue?.monthly_commission;
  }

  return formatCurrency(value);
});
</script>

<style lang="scss" scoped>
.table-mobile {
  td {
    padding-bottom: 16px;
  }
  tr:not(:first-child) {
    td {
      padding-top: 16px;
    }
  }
  tr:not(:last-child) {
    td {
      border-bottom: 1px solid $dividers;
    }
  }
}
</style>
