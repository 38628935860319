import fetch from './modules/fetch';
import statements from './modules/statements';
import orders from './modules/orders';
import users from './modules/users';
import companies from './modules/companies';
import features from './modules/features';
import guarantors from './modules/guarantors';
import ui from './modules/ui';
import loan from './modules/loan';

export default {
  ...fetch,
  ...statements,
  ...orders,
  ...users,
  ...companies,
  ...features,
  ...guarantors,
  ...ui,
  ...loan,
  initialStore() {
    if (localStorage.getItem('user')) {
      const data = localStorage.getItem('user');
      const appVersion = this.$state.app.version;
      if (data && data !== 'undefined') {
        try {
          this.$state = JSON.parse(data);
          this.$state.app.version = appVersion;
        } catch (e) {
          console.error('Ошибка чтения состояния: ', e);
        }
      }
    }
    if (localStorage.getItem('session')) {
      try {
        const login = JSON.parse(localStorage.getItem('session'));
        this.$state.session.login = login;
      } catch (e) {
        return null;
      }
    }
  },
  loginSuccess() {
    this.session.loggedIn = true;
    this.session.startSession = new Date().getTime();

    localStorage.setItem('user', JSON.stringify(this.$state));
    localStorage.setItem('session', JSON.stringify(this.$state.session.login));
  },
  logout() {
    this.session.loggedIn = false;
    this.session.token = null;
    this.session.startSession = null;
    localStorage.removeItem('user');
  },
};
