export default {
  setOrdersFilters(filters) {
    if (!filters) {
      return;
    }
    if (Object.prototype.hasOwnProperty.call(filters, 'selectedFilter')) {
      this.orders.filters.selectedFilter = filters.selectedFilter;
    }
    if (Object.prototype.hasOwnProperty.call(filters, 'selectedSortType')) {
      this.orders.filters.selectedSortType = filters.selectedSortType;
    }
    if (Object.prototype.hasOwnProperty.call(filters, 'selectedSortUp')) {
      this.orders.filters.selectedSortUp = filters.selectedSortUp;
    }
  },
  setCurrentOrder(data) {
    this.currentOrder = data;
  },
  setOrders(data) {
    this.orders = data;
  },
  setMonthlyCommission(data) {
    this.monthlyCommission = data;
  },
  setMonthlyCommissionOverdue(data) {
    this.monthlyCommissionOverdue = data;
  },
  setCurrentManager(data) {
    this.currentManager = data;
  },
};
