<template>
  <div class="server-notices">
    <div v-if="notices.length > 0" class="pb-6">
      <div class="">
        <template v-for="(notice, index) in notices" :key="index">
          <NoticeItem :notice="notice" @close="onClose" />
        </template>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import server from '@/server/index';
import NoticeItem from './NoticeItem.vue';

const loading = ref(false);
const notices = ref([]);

const getNotices = () => {
  loading.value = true;
  return server.getNotices
    .send()
    .pipe(onGetNoticesSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onGetNoticesSuccess = ({ data }) => {
  loading.value = false;
  notices.value = data;
  setTimeout(() => {
    getNotices();
  }, 10000);
};

const onClose = (params) => {
  closeNotice(params.uuid);
};

const closeNotice = (noticeUUID) => {
  if (!noticeUUID) {
    return;
  }
  return server.putNotice
    .send(
      {
        read: true,
      },
      {
        params: {
          uuid: noticeUUID,
        },
      },
    )
    .pipe(() => {
      const index = notices.value.findIndex(
        (i) => i.notice_uuid === noticeUUID,
      );
      notices.value.splice(index, 1);
    })
    .exec();
};

getNotices();
</script>
<style lang="scss" scoped>
.server-notices {
  background-color: #f2f4f8;
  width: 100%;
}
</style>
