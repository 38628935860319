<template>
  <ModalFull @close-modal="emit('close-modal')">
    <template #title
      ><span class="d-inline-block w-100 text-start fw-normal">
        есть вопросы?
      </span>
    </template>
    <template #content>
      <div class="mx-auto text-start">
        <span class="d-inline-block">
          свяжитесь с вашим персональным менеджером,
          <p>любым удобным способом</p></span
        >
      </div>
      <div
        class="form d-flex align-items-center position-relative bg-background mt-6"
      >
        <span
          class="form-placeholder position-absolute d-inline-block color-grey text-uppercase"
        >
          ваш персональный менеджер
        </span>
        <span class="form-value fw-bold">{{ currentManager.name }}</span>
      </div>
      <div class="d-block d-sm-flex">
        <PotokButton
          class="whatsapp-btn mt-6 me-3"
          text="да, точно"
          size="medium"
          theme="secondary"
          @click="onClickOpenChat"
          ><template #default
            ><div class="d-flex align-items-center justify-content-center">
              <i class="whatsapp me-2"></i>whatsapp
            </div>
          </template></PotokButton
        >

        <PotokButton
          class="email-btn mt-6"
          text="да, точно"
          size="medium"
          theme="secondary"
          @click="onClickSendEmail"
          ><template #default
            ><div class="d-flex align-items-center justify-content-center">
              <i class="email me-2"></i>email
            </div></template
          ></PotokButton
        >
      </div>
    </template>
  </ModalFull>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import { useStateStore } from '@/stores/stateStore';
import { managerModalUtils } from '@/components/auth/loan-card/modules/manager-modal/utils/';
import { storeToRefs } from 'pinia';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Sales_contact_open } = TRACKER_EVENTS;

const { onClickOpenChat, onClickSendEmail } = managerModalUtils();
const emit = defineEmits(['close-modal', 'get-orders-async']);
const store = useStateStore();
const { currentManager } = storeToRefs(store);

tracker.queue(tracker.commands.SEND, SPA_LK_Sales_contact_open);
</script>

<style lang="scss" scoped>
.form {
  border-radius: 14px;
  padding: 25px 20px;
  height: 110px;
  .form-placeholder {
    top: 15px;
    font-size: 8px;
  }
  .form-value {
    font-size: 30px;
  }
}
.whatsapp-btn {
  .whatsapp {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/contacts/whatsapp.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    .whatsapp {
      filter: $filter-white;
    }
  }
}
.email-btn {
  .email {
    display: inline-block;
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/contacts/sms.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    .email {
      filter: $filter-white;
    }
  }
}
</style>
