<template>
  <div class="container pt-7 pb-7">
    <div class="form m-auto">
      <div class="title fw-bold mb-3">
        {{ store.currentFounder.name }}
      </div>
      <div class="mb-6 text-center">
        проверьте и заполните паспортные данные
      </div>
      <div class="inputs-container">
        <div class="d-sm-flex d-block mb-4">
          <div class="input-container me-2 w-sm-50 w-100 mb-sm-0 mb-4">
            <input
              v-model="store.currentFounder.passport.series"
              v-maska
              type="text"
              :disabled="!!props.isDisabled"
              :class="{
                'is-invalid':
                  v$.passport.series.$invalid && v$.passport.series.$dirty,
              }"
              placeholder="серия паспорта"
              :data-maska="masks.passport.series()"
              @blur="v$.passport.series.$touch()"
            />
            <span class="placeholder">серия паспорта</span>
            <span
              v-if="
                v$.passport.series?.$silentErrors?.[0]?.$validator ===
                  'required' && v$.passport.series.$dirty
              "
              class="text-danger"
              >обязательное поле</span
            >
            <span
              v-if="
                v$.passport.series?.$silentErrors?.[0]?.$validator ===
                  'minLength' && v$.passport.series.$dirty
              "
              class="text-danger"
              >ошибка в заполнении</span
            >
          </div>
          <div class="input-container w-sm-50 w-100">
            <input
              v-model="store.currentFounder.passport.number"
              v-maska
              type="text"
              :disabled="!!props.isDisabled"
              :class="{
                'is-invalid':
                  v$.passport.number.$invalid && v$.passport.number.$dirty,
              }"
              :data-maska="masks.passport.number()"
              placeholder="номер паспорта"
              @blur="v$.passport.number.$touch()"
            />
            <span class="placeholder">номер паспорта</span>
            <span
              v-if="
                v$.passport.number?.$silentErrors?.[0]?.$validator ===
                  'required' && v$.passport.number.$dirty
              "
              class="text-danger"
              >обязательное поле</span
            >
            <span
              v-if="
                v$.passport.number?.$silentErrors?.[0]?.$validator ===
                  'minLength' && v$.passport.number.$dirty
              "
              class="text-danger"
              >ошибка в заполнении</span
            >
          </div>
        </div>
        <div class="d-sm-flex d-block mb-4">
          <div class="input-container me-2 w-sm-50 w-100 mb-sm-0 mb-4">
            <input
              v-model="store.currentFounder.passport.department_code"
              v-maska
              type="text"
              :disabled="!!props.isDisabled"
              :class="{
                'is-invalid':
                  v$.passport.department_code.$invalid &&
                  v$.passport.department_code.$dirty,
              }"
              :data-maska="masks.passport.department_code()"
              placeholder="код подразделения"
              @blur="v$.passport.department_code.$touch()"
            />
            <span class="placeholder">код подразделения</span>
            <span
              v-if="
                v$.passport.department_code?.$silentErrors?.[0]?.$validator ===
                  'required' && v$.passport.department_code.$dirty
              "
              class="text-danger"
              >обязательное поле</span
            >
            <span
              v-if="
                v$.passport.department_code?.$silentErrors?.[0]?.$validator ===
                  'minLength' && v$.passport.department_code.$dirty
              "
              class="text-danger"
              >ошибка в заполнении</span
            >
          </div>
          <div class="input-container w-sm-50 w-100">
            <input
              v-model="store.currentFounder.passport.issued_date"
              v-maska
              type="text"
              :disabled="!!props.isDisabled"
              :class="{
                'is-invalid':
                  v$.passport.issued_date.$invalid &&
                  v$.passport.issued_date.$dirty,
              }"
              :data-maska="masks.passport.issued_date()"
              placeholder="дата выдачи"
              @blur="v$.passport.issued_date.$touch()"
            />
            <span class="placeholder">дата выдачи</span>
            <span
              v-if="
                v$.passport.issued_date.$invalid &&
                v$.passport.issued_date.$dirty
              "
              class="text-danger"
              >обязательное поле</span
            >
          </div>
        </div>
        <div class="input-container mb-4">
          <input
            v-model="store.currentFounder.passport.place_of_birth"
            type="text"
            :disabled="!!props.isDisabled"
            :class="{
              'is-invalid':
                v$.passport.place_of_birth.$invalid &&
                v$.passport.place_of_birth.$dirty,
            }"
            placeholder="место рождения"
            @blur="v$.passport.place_of_birth.$touch()"
          />
          <span class="placeholder">место рождения</span>
          <span
            v-if="
              v$.passport.place_of_birth.$invalid &&
              v$.passport.place_of_birth.$dirty
            "
            class="text-danger"
            >обязательное поле</span
          >
        </div>
        <div class="input-container mb-4">
          <input
            v-model="store.currentFounder.passport.birthday"
            v-maska
            type="text"
            :disabled="!!props.isDisabled"
            :class="{
              'is-invalid':
                v$.passport.birthday.$invalid && v$.passport.birthday.$dirty,
            }"
            :data-maska="masks.passport.birthday()"
            placeholder="дата рождения"
            @blur="v$.passport.birthday.$touch()"
          />
          <span class="placeholder">дата рождения</span>
          <span
            v-if="v$.passport.birthday.$invalid && v$.passport.birthday.$dirty"
            class="text-danger"
            >обязательное поле</span
          >
        </div>
        <div class="input-container mb-4">
          <InputAddress
            v-model="store.currentFounder.passport.registration_address"
            :disabled="props.isDisabled"
            :placeholder="'адрес регистрации'"
            :class="{
              'is-invalid':
                v$.passport.registration_address.$invalid &&
                v$.passport.registration_address.$dirty,
            }"
          />

          <span class="placeholder">адрес регистрации</span>
          <span
            v-if="
              v$.passport.registration_address?.$silentErrors?.[0]
                ?.$validator === 'required' &&
              v$.passport.registration_address.$dirty
            "
            class="text-danger"
            >обязательное поле</span
          >
          <span
            v-if="
              v$.passport.registration_address?.$silentErrors?.[0]
                ?.$validator === 'containsNumbers' &&
              v$.passport.registration_address.$dirty
            "
            class="text-danger"
            >введите полный адрес с указанием номера дома</span
          >
        </div>
        <div class="input-container">
          <input
            v-model="store.currentFounder.phone"
            v-maska
            type="phone"
            :disabled="!!props.isDisabled"
            :data-maska="masks.phone()"
            placeholder="+7 (999) 999-99-99"
            @blur="v$.contact_phone.$touch()"
          />
          <span class="placeholder">телефон</span>
          <i
            :class="{
              'icon-correct': store.currentFounder.phone,
            }"
            class="icon"
          ></i>
          <span
            v-if="
              v$.contact_phone.$silentErrors?.[0]?.$validator === 'required' &&
              v$.contact_phone.$dirty
            "
            class="text-danger"
            >обязательное поле</span
          >
          <span
            v-if="
              v$.contact_phone.$silentErrors?.[0]?.$validator === 'valid' &&
              v$.contact_phone.$dirty
            "
            class="text-danger"
            >ошибка в заполнении</span
          >
        </div>
      </div>
      <ErrorItem
        v-if="errorsArr.length !== 0"
        v-model="errorsArr"
        class="mb-2"
      />
      <PotokButton
        v-if="!loading"
        class="mb-4 w-100"
        :disabled="!!props.isDisabled"
        size="large"
        @click="onClickPostUsersPassportsAsync()"
        ><template #default>
          <span v-if="isUnfilledFounders">к следующему</span>
          <span v-if="!isUnfilledFounders">продолжить</span></template
        ></PotokButton
      >
      <LoadingBtn v-if="loading" class="mb-4" />
      <h6
        class="row-link fw-normal text-center mb-4"
        @click="onClickGoToFoundersList()"
      >
        назад
      </h6>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import UL_SummaryInfo from '@/components/auth/user-passports/ul-user-type/UL_SummaryInfo.vue';
import FoundersList from '../FoundersList.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import useVuelidate from '@vuelidate/core';
import { required, minLength, helpers } from '@vuelidate/validators';
import { ref, computed } from 'vue';
import { vMaska } from 'maska';
import { formatNumber } from '@/utils';
import masks from '@/libs/js-text-masks';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';
import InputAddress from '@/components/_generic/ui/widgets/InputAddress.vue';

const { SPA_LK_BKI_passport_cofaunder_open, SPA_LK_BKI_passport_success } =
  TRACKER_EVENTS;

const emit = defineEmits([
  'change-component',
  'redirectToUserCabinet',
  'sendOtpAsync',
  'update:errorsArr',
  'getUserDataAsync',
  'get-founders-async',
  'get-users-passports-async',
]);

const props = defineProps({
  isDisabled: {
    type: Boolean,
  },
});

const store = useStateStore();
const loading = ref(false);
const errorsArr = ref([]);

tracker.queue(tracker.commands.SEND, SPA_LK_BKI_passport_cofaunder_open);

const isUnfilledFounders = computed(() => {
  return store.currentFounder.unFilledFounders.length !== 0;
});

const containsNumbers = (value) => (/\d/.test(value) ? true : false);
const phone = helpers.regex(
  /^(\+7[ ]\([0-9]{3}\)[ ])([0-9]{3}-)([0-9]{2}-)[0-9]{2}$/,
);
const validations = {
  passport: {
    series: {
      required,
      minLength: minLength(4),
    },
    number: {
      required,
      minLength: minLength(6),
    },
    department_code: {
      required,
      minLength: minLength(7),
    },
    issued_date: {
      required,
    },
    place_of_birth: {
      required,
    },
    birthday: {
      required,
    },
    registration_address: {
      required,
      containsNumbers,
    },
  },
  contact_phone: {
    required,
    valid: phone,
  },
};

const v$ = useVuelidate(validations, {
  passport: computed(() => store.currentFounder.passport),
  contact_phone: computed(() => store.currentFounder.phone),
});

const onClickPostUsersPassportsAsync = () => {
  v$.value.$touch();
  if (v$.value.$invalid) {
    return;
  }
  loading.value = true;
  const query = {
    founder: {
      contact_phone: formatNumber(store.currentFounder.phone),
      passport: {
        series_number:
          store.currentFounder.passport.series +
          ' ' +
          store.currentFounder.passport.number,
        issued_date: store.currentFounder.passport.issued_date,
        birthday: store.currentFounder.passport.birthday,
        place_of_birth: store.currentFounder.passport.place_of_birth,
        registration_address:
          store.currentFounder.passport.registration_address,
        department_code: store.currentFounder.passport.department_code,
      },
    },
  };
  return server.patchFounders$id
    .send(query, {
      params: {
        id: store.currentFounder.id,
      },
    })
    .pipe(onPostUsersPassportsAsyncSuccess, onPostUsersPassportsAsyncError)
    .exec();
};
const onPostUsersPassportsAsyncSuccess = () => {
  loading.value = false;
  /* 
  если поля прошли валидацию, отправляется запрос
  дальше проверяем что массив unFilledFounders не пустой

  если условие ложно (т.е. все фаундеры
  заполнены), чистим оба массива и отправляем на сводный экран

  если условие истинно, то берём первый элемент из массива unFilledFounders,
  присваеиваем данные из элемента массива текущим данным
  и в конце удаляем элемент из массива unFilledFounders
  */
  if (isUnfilledFounders.value) {
    v$.value.$reset();
    let idx = 0;
    store.currentFounder.id = store.currentFounder?.unFilledFounders[idx].id;
    store.currentFounder.name =
      store.currentFounder?.unFilledFounders[idx].full_name;
    store.currentFounder.phone =
      store.currentFounder?.unFilledFounders[idx].contact_phone;
    if (store.currentFounder?.unFilledFounders[idx].passport) {
      if (store.currentFounder?.unFilledFounders[idx].passport.series_number) {
        store.currentFounder.unFilledFounders[idx].passport.series =
          store.currentFounder?.unFilledFounders[
            idx
          ].passport.series_number.slice(0, 4);
      }
      if (store.currentFounder?.unFilledFounders[idx].passport.series_number) {
        store.currentFounder.unFilledFounders[idx].passport.number =
          store.currentFounder?.unFilledFounders[
            idx
          ].passport.series_number.slice(5, 11);
      }
      store.currentFounder.passport =
        store.currentFounder?.unFilledFounders[idx].passport;
      store.currentFounder.unFilledFounders.splice(idx, 1);
    } else {
      store.currentFounder.unFilledFounders.splice(idx, 1);
      Object.keys(store.currentFounder.passport).forEach(
        (key) => (store.currentFounder.passport[key] = ''),
      );
    }
  } else {
    tracker.queue(tracker.commands.SEND, SPA_LK_BKI_passport_success);
    emit('change-component', UL_SummaryInfo);
    emit('get-founders-async');
    emit('get-users-passports-async');
  }
};
const onPostUsersPassportsAsyncError = (error) => {
  errorsArr.value = error.data?.messages;
  loading.value = false;
};
const onClickGoToFoundersList = () => {
  store.currentFounder.unFilledFounders = [];
  emit('change-component', FoundersList);
  emit('get-founders-async');
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 460px;
  .form {
    .title {
      font-size: 30px;
      line-height: 36px;
      text-align: center;
    }
    .inputs-container {
      border-radius: 14px;
      padding: 20px 0px;
      .input-container {
        position: relative;
        :deep(input) {
          @include input;
          padding-left: 15px;
          background-color: transparent;
          border-color: $input;
          height: 44px;
        }
        .is-invalid {
          border: 1px solid rgba($red, 0.2);
          &:focus-visible {
            border: 1px solid rgba($red, 0.2);
          }
        }
        .placeholder {
          position: absolute;
          left: 10px;
          top: -5px;
          font-size: 8px;
          line-height: 12px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $grey;
          background-color: white;
          padding: 0 5px 0 5px;
        }
        .icon {
          position: absolute;
          right: 15px;
          top: 12px;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center;
          &-not_done {
            background-image: url(@/assets/icons/rounded/icon_not_done.svg);
            cursor: pointer;
            &:hover ~ .popover__content {
              visibility: visible;
            }
          }
          &-correct {
            background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
            filter: $filter-tiffany;
          }
        }
        .popover__content {
          visibility: hidden;
          font-size: 14px;
          line-height: 22px;
          padding: 10px 14px;
          position: absolute;
          right: -70px;
          top: -40px;
          background-color: white;
          box-shadow: 0px 16px 64px rgba(183, 203, 228, 0.5);
          border-radius: 8px;
          width: auto;
        }
      }
    }
    .row-link {
      cursor: pointer;
      color: $grey;
      &:hover {
        color: $breakwater;
      }
    }
  }
}
</style>
