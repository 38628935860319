<template>
  <div class="btn-container">
    <label class="switch btn-color-mode-switch">
      <input
        id="color_mode"
        type="checkbox"
        name="color_mode"
        :value="props.modelValue"
        @change="emit('update:modelValue', $event.target.checked)"
      />
      <label
        for="color_mode"
        data-on="qr код"
        data-off="реквизиты"
        class="btn-color-mode-switch-inner"
      ></label>
    </label>
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:modelValue']);
</script>

<style scoped>
div.btn-container {
  vertical-align: middle;
  text-align: center;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 288px;
  height: 44px;
  border-radius: 22px;
  border: 1px solid #dce0e3;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: block;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  cursor: pointer;
  position: absolute;
  top: 9px;
  right: 30px;
  color: #818e99;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 144px;
  height: 32px;
  background: white;
  border-radius: 16px;
  position: absolute;
  left: 6px;
  top: 5px;
  text-align: center;
  transition: all 0.3s ease;
  padding: 4px 0px;
}

.btn-color-mode-switch input[type='checkbox'] {
  cursor: pointer;
  width: 110px;
  height: 44px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch
  input[type='checkbox']:checked
  + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 136px;
}

.btn-color-mode-switch
  input[type='checkbox']:checked
  + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 20px;
}
</style>
