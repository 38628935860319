<template>
  <div class="w-100">
    <div class="loan-term">
      <div>
        <template v-if="!store.isCompanyBanned">
          <LoanTermContent v-if="!isOnboardingOrLimitUpdateState" />
          <LimitUpdateState v-if="isOnboardingOrLimitUpdateState" />
        </template>
        <template v-if="store.isCompanyBanned">
          <CompanyBanned />
        </template>
      </div>
    </div>

    <div class="buttons-panel d-flex align-items-center">
      <PotokButton
        v-if="store.isRejectedState"
        size="large"
        theme="secondary"
        class="me-2"
        :text="'подробнее в анализе бизнеса'"
        @click="onClickGoToAnalytic()"
      ></PotokButton>

      <PotokButton
        v-if="!store.isRejectedState"
        :disabled="isActionButtonDisabled()"
        size="large"
        theme="primary"
        @click="getActionForButton('action')"
        ><template #default>
          <div class="d-flex align-items-center">
            <span>{{ getActionForButton('title') }}</span>
          </div>
        </template></PotokButton
      >
      <ErrorItem
        v-if="errors && errors.length !== 0"
        v-model="errors"
        class="ms-4"
      />
    </div>

    <ModalPhoneConfirmation
      v-if="isSigning"
      :left-time="leftTime"
      :errors-arr="otpErrors"
      :agreement-id="agreementId"
      :loading="loading"
      title="для оформления транша"
      @confirm-otp-async="confirmOtpAsync"
      @send-otp-async="sendOtpAsync"
      @clear-errors="otpErrors = []"
      @close-modal="isSigning = false"
      @go-back="isSigning = false"
    >
      <template #desc
        ><div class="potok-text-body-3">
          на сумму
          {{
            formatCurrency(store.pages?.loanTerm?.amountCustomParams, 0)
          }}&nbsp;₽
        </div></template
      >
    </ModalPhoneConfirmation>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import { onMounted, computed, watch } from 'vue';
import LimitUpdateState from './modules/update-limit/LimitUpdateState.vue';
import ModalPhoneConfirmation from '@/components/_generic/ui/modals/ModalPhoneConfirmation.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import { useStateStore } from '@/stores/stateStore';
import { loanTermsUtils } from '@/components/auth/dashboard/loan-terms/utils/loanTermsUtils';
import LoanTermContent from '@/components/auth/dashboard/loan-terms/LoanTermContent.vue';
import CompanyBanned from '@/components/auth/dashboard/loan-terms/CompanyBanned.vue';
import { generalUtils } from '@/modules/generalUtils.js';
import { formatCurrency } from '@/utils';

const store = useStateStore();
const {
  leftTime,
  agreementId,
  loading,
  isSigning,
  errors,
  otpErrors,
  getActionForButton,
  isActionButtonDisabled,
  onClickGoToAnalytic,
  sendOtpAsync,
  confirmOtpAsync,
  onGetOrderAsyncSuccess,
} = loanTermsUtils();

const { getOrderAsync } = generalUtils();

const isOnboardingOrLimitUpdateState = computed(() => {
  if (!store.isPassedOnboarding) {
    return true;
  }
  if (store.isMainFlowRequiredUpdateLimit || store.isVKLFlowOnbloarding) {
    return true;
  }
  return false;
});

const initPage = () => {
  getOrderAsync({
    callback: (data) => {
      store.pages.loanTerm.amountCustomParams =
        store.currentOrder.loan_terms_offer?.available_limit;
      onGetOrderAsyncSuccess({ data });
    },
  });
};

onMounted(() => {
  initPage();
});

watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    initPage();
  },
);
</script>

<style lang="scss" scoped>
.loan-term {
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;

  @media (max-width: $size_767) {
    background-color: $background;
  }
}

.buttons-panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 20px;

  @media (max-width: $size_767) {
    margin-top: initial;
  }
}

.button-panel {
  @media (max-width: $size_767) {
    width: 100%;
    margin: 20px 0px;
  }
}
</style>
