<template>
  <div class="companies-mobile-menu-wrapper">
    <div>
      <button class="modal-close" type="button" @click="emit('close')">
        &#8592; назад
      </button>
    </div>
    <div class="companies-mobile-menu content_scroll">
      <div class="companies-mobile-title">в потоке</div>
      <template v-for="(item, index) in store.companies.items" :key="index">
        <div
          v-if="item.id"
          class="companies-mobile-item"
          :class="{
            'companies-mobile-item-last':
              index === store.companies.items.length - 1,
          }"
          @click="onClickSelectItem(item)"
        >
          <div>
            {{ item.short_title || item.title }}
          </div>
          <div class="small color-grey mt-1">инн {{ item.inn }}</div>
        </div>
        <template v-if="!item.id"
          ><div class="companies-mobile-title mt-4 mb-3">ещё нет в потоке</div>
          <div
            class="companies-mobile-item"
            :class="{
              'companies-mobile-item-last':
                index === store.companies.items.length - 1,
            }"
            @click="onClickCreateCompany(item.inn)"
          >
            <div>
              {{ item.short_title || item.title }}
            </div>
            <div class="small color-grey mt-1">инн {{ item.inn }}</div>
            <span class="text-tiffany mt-2">добавить в поток &#8594;</span>
          </div></template
        >
      </template>
      <div class="companies-mobile-item" @click="emit('close')">
        <div class="d-flex">
          <i class="icon-setting"></i>
          <router-link
            class="ms-3"
            :to="{
              name: 'profile',
            }"
            >мой профиль</router-link
          >
        </div>
      </div>
    </div>

    <div class="companies-mobile-item-exit" @click="onClickLogOut()">
      <i class="icon-exit"></i>
      <button type="button" class="ms-2">выход</button>
    </div>
  </div>
</template>
<script setup>
import { menuUtils } from './menuUtils.js';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const emit = defineEmits(['close']);

const { onSelectItem, onCreateCompany, logOutAsync } = menuUtils();

const onClickCreateCompany = (inn) => {
  onCreateCompany(inn);
};

const onClickSelectItem = (value) => {
  onSelectItem(value);
  emit('close');
};

const onClickLogOut = () => {
  logOutAsync();
};
</script>
<style lang="scss" scoped>
.content_scroll {
  height: calc(100vh - 100px);
}
.companies-mobile-menu-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 1000;
  margin: 0;
  padding: 0 0 0 20px;
}
.companies-mobile-menu {
  padding: 0;
  margin: 0;
}
.modal-close {
  border: none;
  margin-top: 30px;
  margin-left: 0px;
  width: 76px;
  height: 30px;
  background: $background;
  border-radius: 22px;
  padding: 4px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $dipedive;
}
.companies-mobile-title {
  display: flex;
  justify-content: center;
  margin: 30px 0px 5px 0px;
  width: fit-content;
  padding: 4px 8px;
  height: 20px;
  background-color: #f1f3f5;
  border-radius: 6px;
  align-items: center;
  font-size: 8px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: $grey;
}
.companies-mobile-item {
  padding: 5px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 85px;
  color: $dipedive;
  border-bottom: 1px solid $dividers;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  div {
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.companies-mobile-item-last {
  border: none;
}
.companies-mobile-item-exit {
  position: absolute;
  align-items: center;
  justify-content: flex-start;
  display: flex;
  color: $danger;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: lowercase;
  background-color: white;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 9px 0px 9px 20px;

  &:hover {
    background-color: $background;
    box-shadow: none;
  }
  button {
    color: $danger;
    border: none;
    background-color: transparent;
  }
}
</style>
