<template>
  <ModalFull @close-modal="onClickCloseModal">
    <template #title>{{ props.title }} <slot name="desc"></slot></template>
    <template #content>
      <PhoneConfirmation
        :left-time="props.leftTime"
        :loading="props.loading"
        :errors-arr="props.errorsArr"
        :phone="props.phone"
        @confirm-otp-async="confirmOtpAsync"
        @send-otp-async="emit('send-otp-async')"
        @clear-errors="emit('clear-errors')"
      />
    </template>
  </ModalFull>
</template>
<script setup>
import { onMounted, onUnmounted } from 'vue';
import PhoneConfirmation from '@/components/_generic/unauth/PhoneConfirmation.vue';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import { blockScrollWindow } from '@/utils';

const { disableScroll, enableScroll } = blockScrollWindow();
const props = defineProps({
  leftTime: {
    type: Number,
    default: 0,
  },
  title: {
    type: String,
    default: '',
  },
  agreementId: {
    type: Number,
    default: 0,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  errorsArr: {
    type: Array,
    default() {
      return [];
    },
  },
  phone: {
    type: String,
    default: null,
  },
});

const emit = defineEmits([
  'confirm-otp-async',
  'send-otp-async',
  'clear-errors',
  'close-modal',
  'go-back',
]);

onMounted(() => {
  disableScroll();
});

onUnmounted(() => {
  enableScroll();
});

const onClickCloseModal = () => {
  emit('close-modal');
};

const confirmOtpAsync = (rawOtp) => {
  emit('confirm-otp-async', rawOtp);
};
</script>
<style></style>
