<template>
  <div class="w-100 m-auto">
    <h1 class="text-title text-center">создайте пароль</h1>
    <div class="text-desc-wrapper">
      <div class="text-desc text-center">
        придумайте пароль <br />для входа на платформу
      </div>
    </div>
  </div>
  <div class="form">
    <div class="form-row mt-3">
      <FieldPasspord
        id="password"
        v-model:password="v$.password.$model"
        :loading="loading"
        :placeholder="'введите пароль'"
        @update:password="onClickPressKeyPassword"
        @keyup.enter="onClickSetPassword()"
      />
      <div v-if="v$.password.$invalid && isError" class="text-danger mt-2">
        минимум 8 символов английского алфавита, буквы и цифры
      </div>
      <ErrorItem v-if="errors.length !== 0" v-model="errors" />
    </div>

    <div class="form-row input-with-icon position-relative">
      <div class="actions-panel">
        <PotokButton
          v-if="!loading"
          id="createpassword"
          class="w-100"
          text="создать пароль"
          size="large"
          @click="onClickSetPassword()"
        />
        <LoadingBtn v-if="loading" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { ref, reactive } from 'vue';
import { helpers, required, minLength } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import FieldPasspord from '@/components/_generic/ui/inputs/FieldPasspord.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import server from '@/server/index';

const emit = defineEmits(['change-step']);
const loading = ref(null);
const errors = ref([]);
const isError = ref(false);

const passwords = reactive({
  password: '',
});
const validations = {
  password: {
    required,
    minLength: minLength(8),
    passwordRequirements: helpers.withMessage(
      () => `Минимум 8 символов, буквы и цифры`,
      (value) => /(?=.*[a-zA-Z])(?=.*[0-9])/.test(value),
    ),
  },
};
const v$ = useVuelidate(validations, passwords);

const onClickPressKeyPassword = () => {
  isError.value = false;
  errors.value = [];
};
const onClickSetPassword = () => {
  if (v$.value.password.$invalid) {
    isError.value = true;
    return;
  }

  loading.value = true;
  const query = {
    user: {
      password: passwords.password,
    },
  };
  return server.updateUserPassword
    .send(query)
    .pipe(onSetUserPasswordAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};

const onSetUserPasswordAsyncSuccess = () => {
  loading.value = false;
  emit('change-step', {
    step: 4,
  });
};
</script>
<style lang="scss" scoped>
.text-desc {
  max-width: 369px;
}
.text-desc-wrapper {
  padding-top: 4px;
}
.actions-panel {
  margin-top: 30px;
  margin-bottom: 40px;
}
</style>
