<template>
  <div class="orders-filters">
    <div class="orders-tags">
      <Switcher
        v-model="selectedFilter"
        :items="[
          { value: 'open', title: 'открытые' },
          { value: 'closed', title: 'закрытые' },
        ]"
        @update:model-value="changeFilter()"
      />
    </div>
    <div class="orders-sort">
      <CustomSelect
        v-if="!isMobile"
        v-model="selectedSortType"
        :items="sortTypes"
        :disabled="store.orders.loading"
        class="select-element"
        @update:model-value="changeFilter('sort')"
      />
      <button
        class="btn-text"
        type="button"
        :class="{
          'icon-sort-up': selectedSortUp,
          'icon-sort-down': !selectedSortUp,
        }"
        :disabled="store.orders.loading"
        @click="changeFilter('selected_sort_up')"
      ></button>
    </div>
  </div>
</template>
<script setup>
import { ref, watch, onMounted } from 'vue';
import CustomSelect from '@/components/_generic/ui/widgets/CustomSelect.vue';
import Switcher from '@/components/_generic/ui/widgets/Switcher.vue';
import { detectMobile } from '@/utils';
import { useStateStore } from '@/stores/stateStore';

const emit = defineEmits(['change-filters']);
const store = useStateStore();

const sortTypes = [
  { title: 'сортировать по дате оформления', value: 'sort_form' },
  { title: 'сортировать по дате платежа', value: 'sort_payment' },
];
const selectedSortType = ref(sortTypes[0].value);
const selectedSortUp = ref(false);
const { isMobile } = detectMobile();

const selectedFilter = ref('open');

watch(
  () => {
    return isMobile.value;
  },
  () => {
    if (isMobile.value) {
      selectedSortType.value = sortTypes[0].value;
      saveFilters();
    }
  },
);

watch(
  () => {
    return store.orders.filters.selectedFilter;
  },
  () => {
    selectedFilter.value = store.orders.filters.selectedFilter;
  },
);
watch(
  () => {
    return store.orders.filters.selectedSortType;
  },
  () => {
    selectedSortType.value = store.orders.filters.selectedSortType;
  },
);
watch(
  () => {
    return store.orders.filters.selectedSortUp;
  },
  () => {
    selectedSortUp.value = store.orders.filters.selectedSortUp;
  },
);

const changeFilter = (type) => {
  if (store.orders.loading) {
    return;
  }
  if (type === 'selected_sort_up') {
    selectedSortUp.value = !selectedSortUp.value;
  }
  saveFilters();
};

const saveFilters = () => {
  store.setOrdersFilters({
    selectedFilter: selectedFilter.value,
    selectedSortType: selectedSortType.value,
    selectedSortUp: selectedSortUp.value,
  });
  emit('change-filters');
};

const calculateInitSelectedSortType = () => {
  if (isMobile.value) {
    return sortTypes[0].value;
  }
  if (store.orders.filters.selectedSortType) {
    return store.orders.filters.selectedSortType;
  }
  return sortTypes[0].value;
};

const initDefaultOrdersValues = () => {
  const data = {
    filters: {
      selectedFilter: 'open',
      selectedSortType: null,
      selectedSortUp: true,
    },
    items: [],
  };
  store.setOrders(data);
};
onMounted(() => {
  if (!store.orders) {
    initDefaultOrdersValues();
  }

  selectedSortType.value = calculateInitSelectedSortType();
  selectedSortUp.value = store.orders.filters.selectedSortUp;
  selectedFilter.value = store.orders.filters.selectedFilter || 'open';
});
</script>
<style lang="scss" scoped>
.orders-filters {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: $dipedive;
  margin-bottom: 30px;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  margin-top: 40px;
  width: 100%;
}
.orders-tags {
  display: flex;
}
.orders-tag {
  display: flex;
  align-items: center;
  color: $grey;
  border-radius: 8px;
  padding: 8px 12px;
  margin-right: 9px;
  border: 1px solid #dce0e3;
  cursor: pointer;
  height: 34px;
}
.orders-tag-active {
  background-color: white;
  color: $dipedive;
}
.orders-tag-disabled {
  opacity: 0.5;
}
.orders-tag-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 8px;
}
.icon-green {
  background-color: $tiffany;
}
.icon-grey {
  background-color: $breakwater;
}

.orders-sort {
  display: flex;
  align-items: center;
}
.select-element {
  margin-right: 10px;
}
</style>
