<template>
  <div>
    <PhoneConfirmation
      class="pt-7"
      :left-time="leftTime"
      :loading="loadingLocal"
      :errors-arr="errorsArr"
      title="подпись анкеты"
      @confirm-otp-async="confirmOtpAsync"
      @send-otp-async="sendOtpAsync"
      @clear-errors="clearErrors"
    />
    <div class="form-row mt-4">
      <div
        class="row-link fw-normal m-auto"
        @click="
          store.isUserUL
            ? emit('change-component', UL_SummaryInfo)
            : emit('change-component', IP_SummaryInfo)
        "
      >
        назад
      </div>
    </div>
  </div>
</template>

<script setup>
import PhoneConfirmation from '@/components/_generic/unauth/PhoneConfirmation.vue';
import IP_SummaryInfo from '@/components/auth/user-passports/ip-user-type/IP_SummaryInfo.vue';
import UL_SummaryInfo from '@/components/auth/user-passports/ul-user-type/UL_SummaryInfo.vue';
import { ref, onActivated, inject } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_BKI_success, SPA_LK_BKI_signature_open } = TRACKER_EVENTS;
const store = useStateStore();
const props = defineProps({
  countdown: {
    type: Object,
    default() {
      return {};
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['change-component']);

const errorsArr = ref([]);
const loadingLocal = ref(false);
const leftTime = ref(0);
const countdownTimer = props.countdown;

const agreementId = inject('agreementId');

const emitter = inject('emitter');

onActivated(() => {
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_signature_open);
  leftTime.value = 0;
  countdownTimer.onTick = (val) => {
    leftTime.value = val;
  };
});
const clearErrors = () => {
  errorsArr.value = [];
};
const sendOtpAsync = () => {
  loadingLocal.value = true;
  const query = {
    signature: {
      confirmable_id: parseInt(
        store.currentOrderId || store.currentCompany.last_order_with_limit_id,
      ),
      confirmable_type: 'Order',
      kind: 'signing_bki_agreement',
    },
  };
  return server.postSignatures
    .send(query)
    .pipe(onSendOtpAsyncSuccess, onSendOtpAsyncError)
    .exec();
};
const onSendOtpAsyncSuccess = () => {
  props.countdown.reset();
  props.countdown.start();
  loadingLocal.value = false;
};
const onSendOtpAsyncError = (error) => {
  loadingLocal.value = false;
  error.data.messages ? (errorsArr.value = error.data.messages) : null;
};
const confirmOtpAsync = (rawOtp) => {
  const query = {
    signature: {
      code: rawOtp,
    },
  };
  loadingLocal.value = true;
  return server.putSignatures$id
    .send(query, {
      params: {
        id: agreementId.value,
      },
    })
    .pipe(onConfirmOtpAsyncSuccess, onConfirmOtpAsyncError)
    .exec();
};
const onConfirmOtpAsyncSuccess = () => {
  loadingLocal.value = false;
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_success);
  emitter.emit('agreement-sign-success');
};
const onConfirmOtpAsyncError = (error) => {
  loadingLocal.value = false;
  error.data.messages ? (errorsArr.value = error.data.messages) : null;
};
</script>

<style lang="scss" scoped>
.form-row {
  .row-link {
    color: $grey;
    width: fit-content;
    cursor: pointer;
    &:hover {
      color: $grey-hover;
    }
  }
}
</style>
