<template>
  <ModalFull maxwidth="550px" @close-modal="onClickCloseModal">
    <template #title>{{ store.fullName }}</template>
    <template #content>
      <div class="mb-6 text-center">
        проверьте и заполните паспортные данные
      </div>
      <PassportForm
        v-model:isDataCorrect="isDataCorrect"
        :touch-form="touchForm"
      />
      <PotokButton
        v-if="!loading"
        class="mt-6 w-100"
        text="сохранить"
        size="large"
        @click="postUsersPassportsAsync()"
      />
      <LoadingBtn v-if="loading" class="mt-6" />
      <ErrorItem
        v-if="errors.length !== 0"
        v-model="errors"
        class="error text-center mx-auto mt-4"
      />
    </template>
  </ModalFull>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import PassportForm from '@/components/_generic/auth/passport-form/PassportForm.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import { ref } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import server from '@/server/index';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_BKI_passport_success } = TRACKER_EVENTS;

const store = useStateStore();

const isDataCorrect = ref(false);
const touchForm = ref(false);

const emit = defineEmits(['close-modal', 'update:isPassportFilled']);

const errors = ref([]);
const loading = ref(false);

const onClickCloseModal = () => {
  emit('close-modal');
};
const postUsersPassportsAsync = () => {
  touchForm.value = true;
  if (!isDataCorrect.value) {
    return;
  }
  loading.value = true;
  const query = {
    user: {
      series_number:
        store.currentUserPassport.series +
        ' ' +
        store.currentUserPassport.number,
      issued_date: store.currentUserPassport.issued_date,
      birthday: store.currentUserPassport.birthday,
      place_of_birth: store.currentUserPassport.place_of_birth,
      registration_address: store.currentUserPassport.registration_address,
      department_code: store.currentUserPassport.department_code,
    },
  };
  return server.postUsersPassports
    .send(query)
    .pipe(onPostUsersPassportsAsyncSuccess, onPostUsersPassportsAsyncError)
    .exec();
};
const onPostUsersPassportsAsyncSuccess = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_passport_success);
  emit('update:isPassportFilled', true);
  emit('close-modal');
  loading.value = false;
};
const onPostUsersPassportsAsyncError = (error) => {
  errors.value = error.data?.messages;
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.upload {
  background-color: $background;
  border-radius: 14px;
  @media (max-width: $size_767) {
    background-color: transparent;
  }
}
.error {
  max-width: 350px;
}
</style>
