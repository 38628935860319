<template>
  <div>
    <div class="d-flex align-items-center">
      <FileUpload
        ref="upload_main"
        v-model="pages.passport_main"
        :disabled="props.isUploadDisabled"
        name="file"
        input-id="passport_main"
        :post-action="postUserAttachmentsAsync()"
        :headers="{ Authorization: store.session.token }"
        :data="requestData('passport_main')"
        class="upload-wrapper"
        :style="
          changeIconCover(
            'passport_main',
            pages.passport_main[0]?.url,
            pages.passport_main[0]?.filename || pages.passport_main[0]?.name,
          )
        "
        :extensions="constants.PASSPORT_FILE_EXTENSIONS"
        accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
        @input-file="inputFile"
        @input-filter="inputFilter"
      />
      <span class="file-name small ms-4">{{
        pages.passport_main[0]?.filename ||
        pages.passport_main[0]?.name ||
        'главный разворот паспорта'
      }}</span>
    </div>
    <hr class="color-grey" />
    <div class="d-flex align-items-center">
      <FileUpload
        ref="upload_registration"
        v-model="pages.passport_registration"
        :disabled="props.isUploadDisabled"
        name="file"
        input-id="passport_registration"
        :post-action="postUserAttachmentsAsync()"
        :headers="{ Authorization: store.session.token }"
        :data="requestData('passport_registration')"
        class="upload-wrapper"
        :style="
          changeIconCover(
            'passport_registration',
            pages.passport_registration[0]?.url,
            pages.passport_registration[0]?.filename ||
              pages.passport_registration[0]?.name,
          )
        "
        :extensions="constants.PASSPORT_FILE_EXTENSIONS"
        accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
        @input-file="inputFile"
        @input-filter="inputFilter"
      />
      <span class="file-name small ms-4">{{
        pages.passport_registration[0]?.filename ||
        pages.passport_registration[0]?.name ||
        'страница с пропиской'
      }}</span>
    </div>
    <hr class="color-grey" />
    <div class="d-flex align-items-center">
      <FileUpload
        ref="upload_selfie"
        v-model="pages.passport_selfie"
        :disabled="props.isUploadDisabled"
        name="file"
        input-id="passport_selfie"
        :post-action="postUserAttachmentsAsync()"
        :headers="{ Authorization: store.session.token }"
        :data="requestData('passport_selfie')"
        class="upload-wrapper"
        :style="
          changeIconCover(
            'passport_selfie',
            pages.passport_selfie[0]?.url,
            pages.passport_selfie[0]?.filename ||
              pages.passport_selfie[0]?.name,
          )
        "
        :extensions="constants.PASSPORT_FILE_EXTENSIONS"
        accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
        @input-file="inputFile"
        @input-filter="inputFilter"
      />
      <span class="file-name small ms-4 me-4">{{
        pages.passport_selfie[0]?.filename ||
        pages.passport_selfie[0]?.name ||
        'селфи с главным разворотом паспорта'
      }}</span>
    </div>
  </div>
</template>

<script setup>
import FileUpload from 'vue-upload-component';
import { onMounted, ref, watch, computed, reactive } from 'vue';
import config from '@/../config';
import server from '@/server/index';
import constants from '@/constants';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const pages = reactive({
  passport_main: [],
  passport_registration: [],
  passport_selfie: [],
});

const upload_main = ref();
const upload_registration = ref();
const upload_selfie = ref();

const props = defineProps({
  isPassportUploaded: {
    type: Boolean,
    default: false,
  },
  isPassportPartiallyUploaded: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isUploadDisabled: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array,
    default() {
      return [];
    },
  },
  attachableId: {
    type: [String, Number],
  },
  attachableType: {
    type: String,
  },
});

const emit = defineEmits([
  'update:isPassportUploaded',
  'update:isPassportPartiallyUploaded',
  'update:loading',
  'update:errors',
]);

onMounted(() => {
  getPassportsAsync();
});

const checkSuccessStatus = computed(() => {
  return (
    pages.passport_main.some(
      (el) =>
        el.success || !Object.prototype.hasOwnProperty.call(el, 'success'),
    ) &&
    pages.passport_registration.some(
      (el) =>
        el.success || !Object.prototype.hasOwnProperty.call(el, 'success'),
    ) &&
    pages.passport_selfie.some(
      (el) =>
        el.success || !Object.prototype.hasOwnProperty.call(el, 'success'),
    )
  );
});
const checkPartiallySuccessStatus = computed(() => {
  return (
    pages.passport_main.some(
      (el) =>
        el.success || !Object.prototype.hasOwnProperty.call(el, 'success'),
    ) ||
    pages.passport_registration.some(
      (el) =>
        el.success || !Object.prototype.hasOwnProperty.call(el, 'success'),
    ) ||
    pages.passport_selfie.some(
      (el) =>
        el.success || !Object.prototype.hasOwnProperty.call(el, 'success'),
    )
  );
});

watch(
  () => checkSuccessStatus.value,
  () => {
    emit('update:isPassportUploaded', checkSuccessStatus.value);
  },
);
watch(
  () => checkPartiallySuccessStatus.value,
  () =>
    emit(
      'update:isPassportPartiallyUploaded',
      checkPartiallySuccessStatus.value,
    ),
);

const changeIconCover = (variable, url, filename) => {
  return pages[variable].length > 0 && !filename.includes('.pdf')
    ? `background-image: url(` + url + `)`
    : {};
};

const inputFilter = (newFile, oldFile, prevent) => {
  if (newFile && !oldFile) {
    if (!/\.(gif|jpg|jpeg|png|webp|pdf)$/i.test(newFile.name)) {
      return prevent();
    }
  }
  if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
    newFile.url = '';
    let URL = window.URL || window.webkitURL;
    if (URL && URL.createObjectURL) {
      newFile.url = URL.createObjectURL(newFile.file);
    }
  }
  // передаём куки в запросе
  if (newFile && oldFile && newFile.xhr && !newFile.xhr.withCredentials) {
    newFile.xhr.withCredentials = true;
  }
};
const requestData = (type) => {
  return {
    kind: type,
    attachable_id: props.attachableId,
    attachable_type: props.attachableType,
  };
};
const postUserAttachmentsAsync = () => {
  return config.VITE_BASE_URL + '/spa/business/attachments';
};
const inputFile = (newFile, oldFile) => {
  // автозагрузка файлов при добавлении
  if (
    Boolean(newFile) !== Boolean(oldFile) ||
    oldFile.error !== newFile.error
  ) {
    if (
      (upload_main.value && upload_main.value?.active === false) ||
      (upload_registration.value &&
        upload_registration.value?.active === false) ||
      (upload_selfie.value && upload_selfie.value?.active === false)
    ) {
      upload_main.value.active = true;
      upload_registration.value.active = true;
      upload_selfie.value.active = true;
      emit('update:loading', true);
    }
  }
  // show loader until load
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    if (newFile.xhr) {
      emit('update:loading', false);
    }
  }
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    if (newFile?.response?.error?.messages) {
      emit('update:errors', newFile?.response?.error?.messages);
    }
  }
};
const getPassportsAsync = () => {
  emit('update:loading', true);
  const query = {
    kind: 'passport_main',
    attachable_id: props.attachableId,
    attachable_type: props.attachableType,
  };
  return server.getUploadAttachments
    .send(query, {})
    .pipe(getPassportsAsyncSuccess, getPassportsAsyncError)
    .exec();
};
const getPassportsAsyncSuccess = ({ data }) => {
  if (!data.main && !data.registration && !data.selfie) {
    emit('update:loading', false);
    return;
  }
  if (data.main) {
    getPassportMainAsync(data.main);
  }
  if (data.registration) {
    getPassportRegistrationAsync(data.registration);
  }
  if (data.selfie) {
    getPassportSelfieAsync(data.selfie);
  }
};
const getPassportsAsyncError = (error) => {
  emit('update:errors', error.data?.messages);
  emit('update:loading', false);
};
const getPassportMainAsync = (filename) => {
  const query = {
    kind: 'passport_main',
    attachable_id: props.attachableId,
    attachable_type: props.attachableType,
  };
  return server.getUploadAttachments$id
    .send(query, {
      params: {
        id: props.attachableId,
      },
    })
    .pipe(({ data }) => {
      pages.passport_main[0] = {
        url: '',
        filename,
      };
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        pages.passport_main[0].url = URL.createObjectURL(data);
      }
      emit('update:loading', false);
    }, getPassportMainAsyncError)
    .exec();
};
const getPassportMainAsyncError = (error) => {
  emit('update:errors', error.data?.messages);
  emit('update:loading', false);
};
const getPassportRegistrationAsync = (filename) => {
  const query = {
    kind: 'passport_registration',
    attachable_id: props.attachableId,
    attachable_type: props.attachableType,
  };
  return server.getUploadAttachments$id
    .send(query, {
      params: {
        id: props.attachableId,
      },
    })
    .pipe(({ data }) => {
      pages.passport_registration[0] = {
        url: '',
        filename,
      };
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        pages.passport_registration[0].url = URL.createObjectURL(data);
      }
      emit('update:loading', false);
    }, getPassportRegistrationAsyncError)
    .exec();
};
const getPassportRegistrationAsyncError = (error) => {
  emit('update:errors', error.data?.messages);
  emit('update:loading', false);
};
const getPassportSelfieAsync = (filename) => {
  const query = {
    kind: 'passport_selfie',
    attachable_id: props.attachableId,
    attachable_type: props.attachableType,
  };
  return server.getUploadAttachments$id
    .send(query, {
      params: {
        id: props.attachableId,
      },
    })
    .pipe(({ data }) => {
      pages.passport_selfie[0] = {
        url: '',
        filename,
      };
      let URL = window.URL || window.webkitURL;
      if (URL && URL.createObjectURL) {
        pages.passport_selfie[0].url = URL.createObjectURL(data);
      }
      emit('update:loading', false);
    }, getPassportSelfieAsyncError)
    .exec();
};
const getPassportSelfieAsyncError = (error) => {
  emit('update:errors', error.data?.messages);
  emit('update:loading', false);
};
</script>

<style lang="scss" scoped>
.upload-wrapper {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-image: url(@/assets/icons/files/file-uploads-sm-img.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:hover {
    border: 1px dashed $primary;
  }
}
.file-name {
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
