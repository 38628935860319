<template>
  <div
    id="limit-tab"
    class="head-tabmenu-limit form"
    :class="applyFormStyles(true)"
    @mouseover="isLimitTabHovered = true"
    @mouseleave="isLimitTabHovered = false"
    @click="onClickSwitchTab(true)"
  >
    <div class="form-block">
      <div class="form-title-wrapper flex-nowrap">
        <div class="form-title-block text-nowrap head-section-title">
          <div class="form-title head-section-title">ДОСТУПНО СЕГОДНЯ</div>
          <div class="form-title-value head-section-title-amount potok-text-h2">
            {{
              formatCurrency(
                store.currentOrder.loan_terms_offer?.available_limit,
                0,
              )
            }}
            ₽
          </div>
        </div>
        <div class="form-title-block text-nowrap">
          <div class="form-title head-section-title">ИЗ ЛИМИТА</div>
          <div
            class="form-title-value text-grey head-section-title-amount potok-text-h2"
          >
            {{
              formatCurrency(store.currentCompany.limits?.available_limit, 0)
            }}
            ₽
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isMobile" style="max-width: 297px" class="ms-auto">
      <div
        v-if="!store.uiStates.isDashboardLimitTabActive"
        class="d-flex align-items-center p-2"
        :class="{
          'bg-primary text-white rounded-5': isLimitTabHovered,
          'text-grey': !isLimitTabHovered,
        }"
      >
        <span class="small"> взять новый заем &#8594;</span>
      </div>
      <PotokButton
        v-if="store.uiStates.isDashboardLimitTabActive"
        :class="{ 'color-grey border-grey': isActionButtonDisabled() }"
        :disabled="isActionButtonDisabled()"
        size="medium"
        theme="secondary"
        @click="getActionForButton('action')"
        ><template #default
          ><div class="d-flex align-items-center">
            <span>{{ getActionForButton('title') }}</span>
          </div></template
        ></PotokButton
      >
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { headUtils } from './headUtils.js';
import { formatCurrency } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import { loanTermsUtils } from '@/components/auth/dashboard/loan-terms/utils/loanTermsUtils';
import { detectMobile } from '@/utils';

const { isMobile } = detectMobile();
const store = useStateStore();

const { isLimitTabHovered, applyFormStyles, onClickSwitchTab } = headUtils();
const { getActionForButton, isActionButtonDisabled } = loanTermsUtils();
</script>
<style lang="scss" scoped>
.head-tabmenu-limit {
  display: flex;
  align-items: center;
  justify-content: start;
  @media (max-width: $size_767) {
    align-items: initial;
  }
}
</style>
