<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="message"
    :class="{
      'message-info': props.notice.notice_type === 'notice',
      'message-alert': props.notice.notice_type === 'alert',
      'message-marketing': props.notice.notice_type === 'marketing',
      'message-marketing-logo':
        props.notice.notice_type === 'marketing_with_logo',
    }"
  >
    <div
      class="icon-message no_mobile"
      :class="{
        'icon-message-info': props.notice.notice_type === 'notice',
        'icon-message-alert': props.notice.notice_type === 'alert',
        'icon-message-marketing': props.notice.notice_type === 'marketing',
        'logo-message-marketing':
          props.notice.notice_type === 'marketing_with_logo',
      }"
    ></div>

    <div class="message-content">
      <div
        :class="[
          props.notice.notice_type === 'marketing_with_logo'
            ? 'message-content-text-logo'
            : 'message-content-text',
        ]"
        class="m"
        v-html="props.notice.notice_message"
      ></div>
    </div>
    <div
      v-if="props.notice.notice_type === 'marketing_with_logo'"
      class="message-action"
    >
      <PotokButton
        size="medium"
        theme="secondary"
        :text="'ок'"
        @click="
          emit('close', {
            uuid: props.notice.notice_uuid,
          })
        "
      />
    </div>
    <div v-if="props.notice.redirect_data" class="message-action">
      <button
        type="button"
        class="btn-text"
        :class="{
          'btn-text-alert': props.notice.notice_type === 'alert',
          'btn-text-tiffany': props.notice.notice_type !== 'alert',
        }"
        @click="onClickAction"
      >
        {{ props.notice.redirect_data.link_title }}
        <i
          v-if="props.notice.notice_type === 'alert'"
          class="icon-notice-arrow-alert"
        ></i>
        <i
          v-if="props.notice.notice_type !== 'alert'"
          class="icon-notice-arrow-tiffany"
        ></i>
      </button>
    </div>
    <i
      v-if="
        props.notice.closable &&
        props.notice.notice_type !== 'marketing_with_logo'
      "
      class="icon-close"
      @click="
        emit('close', {
          uuid: props.notice.notice_uuid,
        })
      "
    >
    </i>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { useRouter } from 'vue-router';

const emit = defineEmits(['close', 'create-order']);
const props = defineProps({
  notice: {
    type: Object,
    required: true,
  },
});
const router = useRouter();

const onClickAction = () => {
  if (props.notice.redirect_data.url) {
    window.open(props.notice.redirect_data.url, '_blank');
  }
  if (props.notice.redirect_data.tag) {
    if (props.notice.redirect_data.tag === 'dashboardLimit') {
      return router.push({
        name: 'dashboard',
        query: {
          action: 'limits',
        },
      });
    }
    if (props.notice.redirect_data.tag === 'holdMessage') {
      return router.push({
        name: 'dashboard',
        query: {
          action: 'hold',
        },
      });
    }
    return router.push({
      name: props.notice.redirect_data.tag,
    });
  }
};
</script>
<style lang="scss" scoped>
.message-action {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.btn-text {
  margin-right: 10px;
  display: flex;
  align-items: center;
  word-break: break-all;
  i {
    margin-left: 5px;
  }
  @media (max-width: $size_767) {
    margin-top: 20px;
    margin-right: 0px;
    text-align: left;
    padding-left: 0;
  }
}
.icon-close {
  margin: 6px 0;
  flex-shrink: 0;
  @media (max-width: $size_767) {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}
</style>
