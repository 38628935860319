<template>
  <div class="loan-content-wrapper mb-6">
    <div class="loan-content-wrapper-left">
      <slot></slot>

      <div v-if="isShowProgress" class="white-block">
        <div class="text-h2">
          вы получите деньги на счет, как только они соберутся
        </div>

        <Progress class="mt-3" />
        <div class="mt-3 d-flex justify-content-between">
          <div class="text-title">
            собрано
            {{ formatCurrency(store.currentOrder.loan?.invested_amount, 0) }}
            ₽
          </div>
          <div class="text-title-grey">
            {{ formatCurrency(store.currentOrder?.amount, 0) }} ₽
          </div>
        </div>
      </div>

      <RequiredActions v-if="isRequiredAction" @run-action="runAction" />

      <div class="white-block">
        <div class="text-h2 mb-4">счет, на который поступят деньги</div>
        <Account />
      </div>

      <ActionBlock />
    </div>

    <div class="loan-content-wrapper-right white-block">
      <TermsTable title="ваши условия"
        ><template #documents>
          <SelectAdditionalDocs
            class="mt-2"
            :title="'скачать документы и договоры'"
            :items="additionalDocs"
            @select="onClickOpenDoc"
          />
          <PotokButton
            class="mt-2 w-100"
            text="график платежей"
            size="medium"
            theme="secondary"
            @click="onClickOpenDoc('schedule')"
          /> </template
      ></TermsTable>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { computed } from 'vue';
import constants from '@/constants';
import { useStateStore } from '@/stores/stateStore';
import Progress from '@/components/_generic/ui/Progress.vue';
import { formatCurrency } from '@/utils';
import Account from '@/components/_generic/ui/widgets/Account.vue';
import TermsTable from '@/components/_generic/auth/TermsTable.vue';
import ActionBlock from '@/components/auth/loan-card/vkl-flow/content/modules/ActionBlock.vue';
import RequiredActions from '@/components/auth/loan-card/vkl-flow/content/modules/RequiredActions.vue';
import SelectAdditionalDocs from '@/components/_generic/ui/widgets/SelectAdditionalDocs.vue';
import { loanCardVKLUtils } from '@/components/auth/loan-card/vkl-flow/utils/loanCardVKLUtils.js';

const emit = defineEmits(['run-action']);
const store = useStateStore();

const { additionalDocs, onClickOpenDoc } = loanCardVKLUtils();

const isShowProgress = computed(() => {
  const statuses = [
    constants.CLIENT_STATES.manual_check,
    constants.CLIENT_STATES.auto_scoring,
  ];
  if (statuses.includes(store.currentOrder.client_state)) {
    return false;
  }
  return true;
});

const isRequiredAction = computed(() => {
  const statuses = [
    constants.CLIENT_STATES.manual_check,
    constants.CLIENT_STATES.ready,
  ];
  if (statuses.includes(store.currentOrder.client_state)) {
    return true;
  }
  return false;
});

const runAction = (data) => {
  emit('run-action', data);
};
</script>
