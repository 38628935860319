<template>
  <div class="inputs-container mb-2">
    <div class="d-sm-flex d-block mb-4">
      <div class="input-container me-2 w-sm-50 w-100 mb-sm-0 mb-4">
        <input
          v-model="store.currentUserPassport.series"
          v-maska
          type="text"
          :disabled="!!props.isDisabled"
          :class="{
            'is-invalid':
              (v$.series.$invalid && v$.series.$dirty) || props.isHighlighted,
          }"
          placeholder="серия паспорта"
          :data-maska="masks.passport.series()"
          @blur="v$.series.$touch()"
        />
        <span v-if="store.currentUserPassport.series" class="placeholder">серия паспорта</span>
        <span
          v-if="
            v$.series?.$silentErrors?.[0]?.$validator === 'required' &&
            v$.series.$dirty
          "
          class="text-danger"
          >обязательное поле</span
        >
        <span
          v-if="
            v$.series?.$silentErrors?.[0]?.$validator === 'minLength' &&
            v$.series.$dirty
          "
          class="text-danger"
          >ошибка в заполнении</span
        >
        <span
          v-if="!v$.series.$invalid && props.isHighlighted"
          class="text-danger"
          >проверьте данные</span
        >
      </div>
      <div class="input-container w-sm-50 w-100">
        <input
          v-model="store.currentUserPassport.number"
          v-maska
          type="text"
          :disabled="!!props.isDisabled"
          :class="{
            'is-invalid':
              (v$.number.$invalid && v$.number.$dirty) || props.isHighlighted,
          }"
          :data-maska="masks.passport.number()"
          placeholder="номер паспорта"
          @blur="v$.number.$touch()"
        />
        <span v-if="store.currentUserPassport.number" class="placeholder">номер паспорта</span>
        <span
          v-if="
            v$.number?.$silentErrors?.[0]?.$validator === 'required' &&
            v$.number.$dirty
          "
          class="text-danger"
          >обязательное поле</span
        >
        <span
          v-if="
            v$.number?.$silentErrors?.[0]?.$validator === 'minLength' &&
            v$.number.$dirty
          "
          class="text-danger"
          >ошибка в заполнении</span
        >
        <span
          v-if="!v$.number.$invalid && props.isHighlighted"
          class="text-danger"
          >проверьте данные</span
        >
      </div>
    </div>
    <div class="d-sm-flex d-block mb-4">
      <div class="input-container me-2 w-sm-50 w-100 mb-sm-0 mb-4">
        <input
          v-model="store.currentUserPassport.department_code"
          v-maska
          type="text"
          :disabled="!!props.isDisabled"
          :class="{
            'is-invalid':
              (v$.department_code.$invalid && v$.department_code.$dirty) ||
              props.isHighlighted,
          }"
          :data-maska="masks.passport.department_code()"
          placeholder="код подразделения"
          @blur="onDepartmentCodeBlur"
        />
        <span v-if="store.currentUserPassport.department_code" class="placeholder">код подразделения</span>
        <span
          v-if="
            v$.department_code?.$silentErrors?.[0]?.$validator === 'required' &&
            v$.department_code.$dirty
          "
          class="text-danger"
          >обязательное поле</span
        >
        <span
          v-if="
            v$.department_code?.$silentErrors?.[0]?.$validator ===
              'minLength' && v$.department_code.$dirty
          "
          class="text-danger"
          >ошибка в заполнении</span
        >
        <span
          v-if="!v$.department_code.$invalid && props.isHighlighted"
          class="text-danger"
          >проверьте данные</span
        >
      </div>
      <div class="input-container w-sm-50 w-100">
        <input
          v-model="store.currentUserPassport.issued_date"
          v-maska
          type="text"
          :disabled="!!props.isDisabled"
          :class="{
            'is-invalid':
              (v$.issued_date.$invalid && v$.issued_date.$dirty) ||
              props.isHighlighted,
          }"
          :data-maska="masks.passport.issued_date()"
          placeholder="дата выдачи"
          @blur="v$.issued_date.$touch()"
        />
        <span v-if="store.currentUserPassport.issued_date" class="placeholder">дата выдачи</span>
        <span
          v-if="v$.issued_date.$invalid && v$.issued_date.$dirty"
          class="text-danger"
          >обязательное поле</span
        >
        <span
          v-if="!v$.issued_date.$invalid && props.isHighlighted"
          class="text-danger"
          >проверьте данные</span
        >
      </div>
    </div>
    <div class="input-container mb-4">
      <input
        v-model="store.currentUserPassport.issued_by"
        type="text"
        :disabled="!!props.isDisabled || issuedByDisabled"
        class="issued-by-input"
        :class="{
          'is-invalid':
            (v$.issued_by.$invalid && v$.issued_by.$dirty) ||
            props.isHighlighted,
        }"
        placeholder="кем выдан"
        @blur="v$.issued_by.$touch()"
      />
      <span class="placeholder">кем выдан</span>
    </div>
    <div class="input-container mb-4">
      <input
        v-model="store.currentUserPassport.place_of_birth"
        type="text"
        :disabled="!!props.isDisabled"
        :class="{
          'is-invalid':
            (v$.place_of_birth.$invalid && v$.place_of_birth.$dirty) ||
            props.isHighlighted,
        }"
        placeholder="место рождения"
        @blur="v$.place_of_birth.$touch()"
      />
      <span v-if="store.currentUserPassport.place_of_birth" class="placeholder">место рождения</span>
      <span
        v-if="v$.place_of_birth.$invalid && v$.place_of_birth.$dirty"
        class="text-danger"
        >обязательное поле</span
      >
      <span
        v-if="!v$.place_of_birth.$invalid && props.isHighlighted"
        class="text-danger"
        >проверьте данные</span
      >
    </div>
    <div class="input-container mb-4">
      <input
        v-model="store.currentUserPassport.birthday"
        v-maska
        type="text"
        :disabled="!!props.isDisabled"
        :class="{
          'is-invalid':
            (v$.birthday.$invalid && v$.birthday.$dirty) || props.isHighlighted,
        }"
        :data-maska="masks.passport.birthday()"
        placeholder="дата рождения"
        @blur="v$.birthday.$touch()"
      />
      <span v-if="store.currentUserPassport.birthday" class="placeholder">дата рождения</span>
      <span
        v-if="v$.birthday.$invalid && v$.birthday.$dirty"
        class="text-danger"
        >обязательное поле</span
      >
      <span
        v-if="!v$.birthday.$invalid && props.isHighlighted"
        class="text-danger"
        >проверьте данные</span
      >
    </div>
    <div class="input-container mb-4">
      <InputAddress
        v-model="store.currentUserPassport.registration_address"
        :disabled="props.isDisabled"
        :placeholder="'адрес регистрации'"
        :class="{
          'is-invalid':
            (v$.registration_address.$invalid &&
              v$.registration_address.$dirty) ||
            props.isHighlighted,
        }"
      />
      <span class="placeholder">адрес регистрации</span>
      <span
        v-if="
          v$.registration_address?.$silentErrors?.[0]?.$validator ===
            'required' && v$.registration_address.$dirty
        "
        class="text-danger"
        >обязательное поле</span
      >
      <span
        v-if="
          v$.registration_address?.$silentErrors?.[0]?.$validator ===
            'containsNumbers' && v$.registration_address.$dirty
        "
        class="text-danger"
        >введите полный адрес с указанием номера дома</span
      >
      <span
        v-if="!v$.registration_address.$invalid && props.isHighlighted"
        class="text-danger"
        >проверьте данные</span
      >
    </div>
    <div class="input-container mb-4">
      <input
        v-model="store.currentUser.phone"
        v-maska
        type="phone"
        :disabled="!!props.isDisabled"
        :data-maska="masks.phone()"
        placeholder="79999999999"
      />
      <span class="placeholder">телефон</span>
      <i :class="{ 'icon-correct': store.currentUser.phone }" class="icon"></i>
    </div>
    <div class="input-container">
      <input
        v-model="store.currentUser.email"
        type="email"
        placeholder="ivanovivan@gmail.ru"
        disabled
      />
      <span class="placeholder">почта</span>
      <div class="popover__wrapper">
        <i
          :class="[
            store.currentUser.email && store.currentUser.email_confirmed
              ? 'icon-correct'
              : 'icon-not_done',
          ]"
          class="icon"
        ></i>
        <div class="popover__content">почта не подтверждена</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, watch, ref } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import useVuelidate from '@vuelidate/core';
import { required, minLength } from '@vuelidate/validators';
import { vMaska } from 'maska';
import masks from '@/libs/js-text-masks';
import server from '@/server/index';
import InputAddress from '@/components/_generic/ui/widgets/InputAddress.vue';

const store = useStateStore();
const props = defineProps({
  isDisabled: {
    type: Boolean,
  },
  isDataCorrect: {
    type: Boolean,
  },
  touchForm: {
    type: Boolean,
  },
  isHighlighted: {
    type: Boolean,
  },
});
const emit = defineEmits(['update:isDataCorrect']);

const containsNumbers = (value) => (/\d/.test(value) ? true : false);
const validations = {
  series: {
    required,
    minLength: minLength(4),
  },
  number: {
    required,
    minLength: minLength(6),
  },
  department_code: {
    required,
    minLength: minLength(7),
  },
  issued_date: {
    required,
  },
  issued_by: {
    required,
  },
  place_of_birth: {
    required,
  },
  birthday: {
    required,
  },
  registration_address: {
    required,
    containsNumbers,
  },
};

const v$ = useVuelidate(
  validations,
  computed(() => {
    return store.currentUserPassport;
  }),
);

const issuedByDisabled = ref(false);

const onDepartmentCodeBlur = () => {
  v$.value.department_code.$touch();
  const code = store.currentUserPassport.department_code;
  if (code && !store.currentUserPassport.issued_by) {
    issuedByDisabled.value = true;
    loadIssuedByInfo(code);
  }
};

const loadIssuedByInfo = (code) => {
  const query = {
    query: code,
  };
  return server.postDadataIssuedBy
    .send(query)
    .pipe(onloadIssuedByInfo, () => {
      issuedByDisabled.value = false;
    })
    .exec();
};

const onloadIssuedByInfo = ({ data }) => {
  const res = data?.suggestions[0];
  store.currentUserPassport.issued_by =
    store.currentUserPassport.department_code === res?.data.code
      ? res.value
      : '';
  issuedByDisabled.value = false;
};

watch(
  () => v$.value.$invalid,
  () => {
    emit('update:isDataCorrect', !v$.value.$invalid);
  },
);
watch(
  () => props.touchForm,
  () => {
    v$.value.$touch();
  },
);
</script>

<style lang="scss" scoped>
.inputs-container {
  border-radius: 14px;
  padding: 20px 0px;
  .input-container {
    position: relative;
    :deep(input) {
      @include input;
      padding-left: 15px;
      background-color: transparent;
      border-color: $input;
      height: 44px !important;
    }
    .is-invalid {
      border: 1px solid rgba($red, 0.2);
      &:focus-visible {
        border: 1px solid rgba($red, 0.2);
      }
    }
    .placeholder {
      position: absolute;
      left: 10px;
      top: -5px;
      font-size: 8px;
      line-height: 12px;
      letter-spacing: 0.1em;
      text-transform: uppercase;
      color: $grey;
      background-color: white;
      padding: 0 5px 0 5px;
    }
    .icon {
      position: absolute;
      right: 15px;
      top: 12px;
      width: 20px;
      height: 20px;
      background-repeat: no-repeat;
      background-position: center;
      &-not_done {
        background-image: url(@/assets/icons/rounded/icon_not_done.svg);
        cursor: pointer;
        &:hover ~ .popover__content {
          visibility: visible;
        }
      }
      &-correct {
        background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
        filter: $filter-tiffany;
      }
    }
    .popover__content {
      visibility: hidden;
      font-size: 14px;
      line-height: 22px;
      padding: 10px 14px;
      position: absolute;
      right: -70px;
      top: -40px;
      background-color: white;
      box-shadow: 0px 16px 64px rgba(183, 203, 228, 0.5);
      border-radius: 8px;
      max-width: 100%;
    }
    .issued-by-input:disabled {
      opacity: 0.6;
    }
  }
}
</style>
