<template>
  <div class="terms-title">{{ props.title }}</div>
  <div class="terms-content" :class="props.fontSize ? 'small' : ''">
    <div class="terms-content-row">
      <td class="terms-content-item">сумма займа</td>
      <td class="terms-content-item">
        {{ formatCurrency(store.currentOrder.amount, 0) }}&nbsp;₽
      </td>
    </div>
    <div class="terms-content-row">
      <div class="terms-content-item">комиссия</div>
      <div class="terms-content-item">
        <span
          :class="{
            'color-tiffany font-bold': oneTimeCommissionPercent === 0,
          }"
        >
          <span
            >{{
              formatCurrency(
                oneTimeCommission,

                0,
              )
            }}
            ₽</span
          >&nbsp;({{ oneTimeCommissionPercent }}%)</span
        >
      </div>
    </div>
    <div class="terms-content-row">
      <div class="terms-content-item">вы получите</div>
      <div class="terms-content-item">
        <template
          v-if="store.currentOrder?.commission_type !== 'monthly_commission'"
        >
          {{ formatCurrency(calcAmountMinusCommission, 0) }}
          ₽
        </template>
        <template
          v-if="store.currentOrder?.commission_type === 'monthly_commission'"
        >
          {{ formatCurrency(store.currentOrder.amount, 0) }}
          ₽
        </template>
      </div>
    </div>
    <div class="terms-content-row">
      <div class="terms-content-item">срок</div>
      <div class="terms-content-item">
        {{ loanPeriod }} месяц{{ pluralize(loanPeriod, ['', 'а', 'ев']) }}
      </div>
    </div>
    <div v-if="!store.isProductTypeInstallment" class="terms-content-row">
      <div class="terms-content-item">частота погашения</div>
      <div class="terms-content-item">
        {{
          calculateRepaymentFrequency(store.currentOrder.repayment_frequency)
        }}
      </div>
    </div>
    <div class="terms-content-row">
      <div class="terms-content-item">регулярный платеж</div>
      <div class="terms-content-item">
        ~&nbsp;{{
          formatCurrency(store.currentOrder.regular_payment_amount, 0)
        }}&nbsp;₽
      </div>
    </div>
    <div
      v-if="
        !store.isProductTypeInstallment &&
        store.currentOrder.visual_rate_mode !==
          constants.VISUAL_RATE_MODES.hidden
      "
      class="terms-content-row"
    >
      <div class="terms-content-item">
        ставка
        <span
          v-if="
            store.currentOrder.visual_rate_mode ===
            constants.VISUAL_RATE_MODES.monthly
          "
          >в месяц</span
        ><span
          v-if="
            store.currentOrder.visual_rate_mode ===
              constants.VISUAL_RATE_MODES.yearly || !store.ёisual_rate_mode
          "
          >годовых</span
        >
      </div>
      <div class="terms-content-item">
        {{
          store.currentOrder.visual_rate_mode ===
          constants.VISUAL_RATE_MODES.monthly
            ? store.currentOrder.rate?.toFixed(1) || 0
            : store.currentOrder.rate || 0
        }}%
      </div>
    </div>
    <div class="terms-content-row">
      <div class="terms-content-item">досрочное погашение</div>
      <div class="terms-content-item d-flex align-items-center">
        <div
          class="me-2"
          v-text="isRegularCommisionSelected ? '1% от остатка' : 'бесплатно'"
        ></div>
        <PopoverIcon
          v-if="isRegularCommisionSelected"
          position="left_center"
          width="330px"
        >
          <template #icon> <i class="question-icon-black"></i> </template>
          <template #text>
            Сумма досрочного погашения включает в себя 1% от остатка основного
            долга на дату погашения и сумму комиссии за неполный период
          </template>
        </PopoverIcon>
      </div>
    </div>
  </div>
  <slot name="documents"></slot>
</template>

<script setup>
import { computed } from 'vue';
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import { calculateRepaymentFrequency } from '@/components/auth/dashboard/loan-terms/utils';
import { formatCurrency, pluralize } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import constants from '@/constants';

const store = useStateStore();
const props = defineProps({
  title: {
    type: String,
    default: '',
  },
  fontSize: {
    type: String,
    default: '',
  },
});

const loanPeriod = computed(() => {
  return store.currentOrder.loan_period / 30;
});

const calcAmountMinusCommission = computed(() => {
  return (
    store.currentOrder.amount -
    (store.currentOrder.amount * store.currentOrder.upfront_percent) / 100
  );
});
/**
 * Если включена фича выбора типа комиссии и выбран тип комиссия в ежемесячном платеже
 */
const isRegularCommisionSelected = computed(() => {
  return (
    store.isCommissionChoiceAllowed &&
    store.currentOrder.commission_type === 'monthly_commission'
  );
});

/**
 * Расчет комиссии
 */
const oneTimeCommissionPercent = computed(() => {
  let percent = store.currentOrder.upfront_percent;
  if (store.currentOrder?.commission_type === 'monthly_commission') {
    percent = store.currentOrder?.monthly_commission_percent;
    if (store.isFeatureCommissionDiscount) {
      percent = store.currentOrder?.monthly_commission_percent_with_discount;
    }
  }
  return percent;
});

const oneTimeCommission = computed(() => {
  let percent = store.currentOrder.upfront_percent;
  if (store.currentOrder?.commission_type === 'monthly_commission') {
    percent = store.currentOrder?.monthly_commission_percent;
    if (store.isFeatureCommissionDiscount) {
      percent = store.currentOrder?.monthly_commission_percent_with_discount;
    }
  }
  let comission = (store.currentOrder.amount * percent) / 100;
  return comission;
});
</script>

<style lang="scss" scoped>
.terms-title {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: $dipedive;
  margin-bottom: 10px;
}

.terms-content {
  width: 100%;
  display: flex;
  flex-direction: column;

  .terms-content-row {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    .terms-content-item:first-child {
      color: $grey;
    }
  }

  .terms-content-item {
    padding: 12px 0px;

    &:last-child {
      text-align: end;
    }
  }
}
</style>
