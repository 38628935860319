<template>
  <div class="white-block graphs-item">
    <div class="block-title">
      <div>топ 3 покупателей за период</div>
      <div v-if="isHold" class="hold-reason">ПРИЧИНА БЛОКИРОВКИ</div>
    </div>
    <template v-if="loading">
      <Loader class="mt-4" />
    </template>
    <template v-if="!loading">
      <div class="top-wrapper graph-wrapper">
        <template v-if="turnovers.length > 0">
          <div class="top-desc">
            <template v-for="(item, index) in turnoversColored" :key="index">
              <PopoverIcon class="w-100">
                <template #icon>
                  <div class="top-desc-item">
                    <div class="top-desc-item-text">
                      <div
                        class="top-desc-item-icon"
                        :style="{ backgroundColor: item.color }"
                      ></div>
                      <div class="top-desc-item-desc">
                        <div class="me-2">
                          <div class="top-desc-item-title">
                            {{ item.title }}
                          </div>
                        </div>
                        <div class="top-desc-item-value">
                          {{ item.percent }}%
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
                <template #text>{{ item.title }}</template>
              </PopoverIcon>
            </template>
          </div>
          <div class="top-graph">
            <Doughnut
              v-if="loaded"
              :data="chartData"
              :options="chartOptions"
              class="doughnut mt-4"
            />
            <div class="top-graph-desc-wrapper">
              <div class="top-graph-desc">
                <div class="top-graph-desc-title">выручка</div>
                <div class="top-graph-desc-value">
                  {{ formatCurrency(totalProfit, 0) }} ₽
                </div>
                <div class="top-graph-desc-title">100%</div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="turnovers.length === 0">
          <div class="graph-wrapper-empty">
            <div class="pt-6">данных для графика нет</div>
          </div>
        </template>
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref, watch, computed, onMounted, nextTick } from 'vue';
import { Chart, registerables } from 'chart.js';
import { Doughnut } from 'vue-chartjs';
import { formatCurrency } from '@/utils';
import { Loader } from 'potok-uikit';
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';

Chart.register(...registerables);

const store = useStateStore();

const props = defineProps({
  selectedPeriod: {
    type: [String, Number],
    default: 12,
  },
});

const loading = ref(false);
const turnovers = ref([]);
const loaded = ref(false);

const isHold = computed(() => {
  return (
    store.currentOrder?.loan_terms_offer &&
    store.currentOrder?.hold_manual_scoring_reasons &&
    store.currentOrder?.hold_manual_scoring_reasons.find((i) => {
      return i.reason_name === 'pre_contractors_concentration_indicator';
    })
  );
});

const totalProfit = computed(() => {
  return totalProfitForPeriod(turnovers.value);
});

const turnoversColored = computed(() => {
  const result = [];
  if (turnovers.value[0]) {
    result.push({
      title: turnovers.value[0].title,
      percent: turnovers.value[0].percent,
      amount: turnovers.value[0].amount,
      color: '#173B57',
    });
  }
  if (turnovers.value[1]) {
    result.push({
      title: turnovers.value[1].title,
      percent: turnovers.value[1].percent,
      amount: turnovers.value[1].amount,
      color: '#04CCD9',
    });
  }
  if (turnovers.value[2]) {
    result.push({
      title: turnovers.value[2].title,
      percent: turnovers.value[2].percent,
      amount: turnovers.value[2].amount,
      color: '#818E99',
    });
  }
  if (turnovers.value[3]) {
    result.push({
      title: turnovers.value[3].title,
      percent: turnovers.value[3].percent,
      amount: turnovers.value[3].amount,
      color: '#E5E9EE',
    });
  }
  return result;
});

const dataTopCustomers = computed(() => {
  return turnovers.value.map((item) => {
    return item.percent;
  });
});

const labels = computed(() => {
  return turnovers.value.map((item) => {
    return item.title;
  });
});
const chartOptions = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const chartData = {
  labels: [],
  datasets: [
    {
      label: 'выручка, %',
      data: [],
      backgroundColor: ['#173B57', '#04CCD9', '#818E99', '#E5E9EE'],
      cutout: '90%',
    },
  ],
};

const totalProfitForPeriod = (items) => {
  let total = 0;
  items.forEach((item) => {
    total += item?.amount ? item.amount : 0;
  });
  return total;
};

const getTurnoversAsync = () => {
  loaded.value = false;
  loading.value = true;

  server.getTurnovers
    .send(
      {
        period: props.selectedPeriod,
      },
      {
        params: { company_id: store.currentCompany.id },
      },
    )
    .pipe(onGetTurnoversAsyncSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onGetTurnoversAsyncSuccess = (data) => {
  const result = data.data || {};
  turnovers.value = result;
  loading.value = false;

  nextTick(() => {
    chartData.labels = labels.value;
    chartData.datasets[0].data = dataTopCustomers.value;
    loaded.value = true;
  });
};

watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    getTurnoversAsync();
  },
);

watch(
  () => {
    return props.selectedPeriod;
  },
  () => {
    getTurnoversAsync();
  },
);

onMounted(() => {
  getTurnoversAsync();
});
</script>
<style lang="scss" scoped>
.white-block {
  min-height: 300px;
}

.block-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.graphs-item {
  margin-right: 30px;
  width: 100%;

  &:last-child {
    margin-right: 0;
  }

  @media (max-width: $size_767) {
    margin-right: initial;
  }
}

.graph-wrapper-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.top-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: $size_767) {
    flex-direction: column-reverse;
    align-items: center;
  }
}

.top-desc {
  width: 270px;
  padding-top: 14px;
  display: flex;
  flex-direction: column;

  @media (max-width: $size_767) {
    width: 100%;
    margin-top: 20px;
  }
}

.top-desc-item {
  display: flex;
  align-items: center;
  border: 1px solid $dividers;
  border-radius: 8px;
  padding: 10px 5px;
  margin-bottom: 7px;
  width: 100%;

  @media (max-width: $size_767) {
    height: 30px;
    padding: 20px 10px;
  }
}

.top-desc-item-text {
  display: flex;
  align-items: center;
  width: 100%;
}

.top-desc-item-value {
  min-width: 30px;
}

.top-desc-item-desc {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.top-desc-item-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-right: 10px;
  flex-shrink: 0;
}

.top-desc-item-title {
  overflow-x: hidden;
  max-width: 155px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $grey;
}

.top-graph {
  position: relative;
  display: flex;
  justify-content: center;
  max-height: 224px;
  max-width: 224px;

  @media (max-width: $size_767) {
    margin-top: 0px;
    max-height: 127px;
    max-width: 127px;
  }
}

.top-graph-desc-wrapper {
  position: absolute;
  top: 44%;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.top-graph-desc {
  text-align: center;
  margin-top: 26px;
}

.top-graph-desc-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  text-transform: lowercase;
  color: $grey;
}

.top-graph-desc-value {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: $dipedive;
}
</style>
