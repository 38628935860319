<template>
  <div class="container-page pt-7 mb-7">
    <div class="form m-auto">
      <h1 class="h3 fw-bold mb-6 text-center">
        проверьте и дополните паспортные данные
      </h1>
      <div class="inputs-container mb-2">
        <div
          v-for="(input, index) in inputFields"
          :key="index"
          class="input-box"
          @click="onClickGoToComponent(inputFields[index].type)"
          @mouseover="showEditIcon = index"
          @mouseleave="showEditIcon = null"
        >
          <input
            v-if="!isEqualAdditionalPhoneOrEmail(inputFields[index].name)"
            type="text"
            :class="{
              'text-danger': isTextDanger(inputFields[index].type),
              'white-hover': isHoverWhite(
                inputFields[index].name,
                inputFields[index].type,
              ),
            }"
            :disabled="/full_name|email|phone/.test(inputFields[index].name)"
            :readonly="/passport|founders/.test(inputFields[index].type)"
            :value="inputFields[index].value || 'нет данных'"
          />
          <template v-if="isEqualAdditionalPhone(inputFields[index].name)">
            <input
              v-model="store.currentCompanyInfo.head_additional_phones[0]"
              v-maska
              :disabled="!!props.isDisabled"
              class="white-hover"
              :data-maska="masks.phone()"
              placeholder="+7 (999) 999-99-99"
              @blur="v$.phone.$touch()"
              @change="onChangeSendAdditionalPhone()"
            />
            <span
              v-if="v$.phone.$invalid && v$.phone.$dirty"
              class="text-danger"
              >введите корректный телефон</span
            >
          </template>
          <template v-if="isEqualAdditionalEmail(inputFields[index].name)">
            <input
              v-model="store.currentCompanyInfo.head_additional_emails[0]"
              type="email"
              :disabled="!!props.isDisabled"
              class="white-hover"
              @blur="v$.email.$touch()"
              @change="onChangeSendAdditionalEmail()"
            />
            <span
              v-if="v$.email.$invalid && v$.email.$dirty"
              class="text-danger"
              >введите корректную почту</span
            >
          </template>
          <span class="placeholder">{{ input.title }}</span>
          <div
            v-if="isShownTextOnInput(inputFields[index].name, index)"
            class="text-fill"
            :class="{
              'text-danger': isTextDanger(inputFields[index].type),
            }"
          >
            заполнить &#8594;
          </div>
          <i
            class="icon"
            :class="{
              'icon-edit': showEditIcon === index,
              'icon-correct': isShownCorrectIcon(
                inputFields[index].name,
                input.value,
                index,
              ),
            }"
          ></i>
          <template
            v-if="isShownAddPhoneIcon(inputFields[index].name, input.value)"
          >
            <i
              class="icon icon-add"
              @click="onClickAddPhoneOrEmail(index, inputFields[index].name)"
            ></i>
            <div class="popover__content_add">добавить ещё номер</div></template
          >
          <template
            v-if="isShownAddEmailIcon(inputFields[index], input.value, index)"
          >
            <i
              class="icon icon-add"
              @click="onClickAddPhoneOrEmail(index, inputFields[index].name)"
            ></i>
            <div class="popover__content_add">добавить ещё почту</div></template
          >
          <hr v-if="index !== inputFields.length - 1" class="divider mb-2" />
        </div>
      </div>
      <span v-if="props.error" class="text-danger">{{ props.error }}</span>
      <OtherFounders
        v-model:isChecked="isChecked"
        v-model:isDocumentUploaded="isDocumentUploaded"
        v-model:validationError="validationError"
        :loading="props.loading"
      />
      <ErrorItem v-if="errorsArrRef.length !== 0" v-model="errorsArrRef" />

      <div class="d-flex mt-4 mb-5 align-items-center position-relative">
        <Checkbox v-model:isChecked="isConfirmCondition"
          ><template #default
            ><div class="potok-text-body-3 color-grey">
              подтверждаю, что Генеральный директор, участники акционеры или
              бенефициары заемщика не являются
              <PopoverIcon :minwidth="'350px'">
                <template #icon
                  ><span class="color-dipedive"
                    >публичным должностным лицом</span
                  ></template
                >
                <template #text>
                  ДЛПМО — должностные лица публичных международных организаций
                  <br />
                  ИПДЛ — иностранные публичные должностные лица<br />
                  РПДЛ — лица, замещающие (занимающие) государственные должности
                  РФ
                </template>
              </PopoverIcon>

              или родственником такого лица
            </div></template
          ></Checkbox
        >
      </div>
      <PotokButton
        v-if="!props.loading"
        :disabled="leftTime !== 0 || !isConfirmCondition"
        class="mb-4 mt-5 w-100"
        size="large"
        @click="onClickSignAgreement()"
      >
        <template #default
          ><span v-if="leftTime === 0">подтвердить данные</span>
          <span v-if="leftTime !== 0"
            >отправить заново через {{ leftTime }} секунд{{
              pluralize(leftTime, ['у', 'ы', ''])
            }}</span
          ></template
        >
      </PotokButton>
      <LoadingBtn v-if="props.loading" class="mb-4 mt-5" />
      <h6
        class="row-link fw-normal text-center"
        @click="onClickRedirectToUserCabinet()"
      >
        заполнить позже
      </h6>
    </div>
    <PageAlert v-if="invalidPassportData || invalidFounderData" color="red">
      <i class="icon-notify"></i>
      чтобы продолжить, заполните все данные
    </PageAlert>
    <div class="footer-text color-grey text-center mx-auto">
      Нажимая подтвердить, вы предоставляете
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
        target="_blank"
        >Согласие на обработку персональных данных и запрос в бюро кредитных
        историй.
      </a>
      Если у вас более одного учредителя или бенефициара, вы подтверждаете
      согласие от лица каждого из них.

      <br /><br />
      Нажимая подтвердить, вы подтверждаете отсутствие у руководителя и
      контролирующих лиц неснятой или непогашенной судимости за преступления в
      сфере экономики или против государственной власти, интересов
      государственной службы и службы в органах местного самоуправления.
      Отсутствие фактов, которые могут оказать существенное негативное влияние
      на возврат займа. Деловая репутация заемщика — положительная.
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import WithoutParser from '@/components/auth/user-passports/_generic/WithoutParser.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import FoundersList from './FoundersList.vue';
import OtherFounders from './modules/OtherFounders.vue';
import WithParser from '@/components/auth/user-passports/_generic/WithParser.vue';
import { ref, computed, watch, onActivated } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { Mask, vMaska } from 'maska';
import { pluralize, formatNumber, isPassportFullFileld } from '@/utils';
import useVuelidate from '@vuelidate/core';
import { email, helpers } from '@vuelidate/validators';
import masks from '@/libs/js-text-masks';
import server from '@/server/index';
import { usePassport } from '../_generic/utils';
import tracker from '@/tracker';
import PageAlert from '@/components/_generic/ui/widgets/PageAlert.vue';
import { TRACKER_EVENTS } from '@/tracker/events';
import Checkbox from '@/components/_generic/ui/widgets/Checkbox.vue';
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';

const { SPA_LK_BKI_nummber_add, SPA_LK_BKI_email_add } = TRACKER_EVENTS;

const emit = defineEmits([
  'change-component',
  'redirect-to-user-cabinet',
  'send-otp-async',
  'update:errorsArr',
]);
const props = defineProps({
  ulFoundersArr: {
    type: Array,
    default() {
      return [];
    },
  },
  ipFoundersArr: {
    type: Array,
    default() {
      return [];
    },
  },
  errorsArr: {
    type: Array,
    default() {
      return [];
    },
  },
  countdown: {
    type: Object,
    default() {
      return {};
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isFoundersPassportsFilled: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isPassportDataCorrect: {
    type: Boolean,
    default: false,
  },
});

const store = useStateStore();

const mask = new Mask({ mask: masks.phone() });
const { isParsed, isError } = usePassport();
const invalidPassportData = ref(false);
const invalidFounderData = ref(false);
const showEditIcon = ref(null);
const isChecked = ref(false);
const isDocumentUploaded = ref(false);
const validationError = ref([]);
const countdownTimer = props.countdown;
const leftTime = ref(0);
const isConfirmCondition = ref(true);

const phone = helpers.regex(
  /^(\+7[ ]\([0-9]{3}\)[ ])([0-9]{3}-)([0-9]{2}-)[0-9]{2}$/,
);
const validations = {
  phone: {
    valid: phone,
  },
  email: {
    valid: email,
  },
};

const v$ = useVuelidate(validations, {
  phone: computed(() => store.currentCompanyInfo.head_additional_phones[0]),
  email: computed(() => store.currentCompanyInfo.head_additional_emails[0]),
});

onActivated(() => {
  countdownTimer.onTick = (val) => {
    leftTime.value = val;
  };
  checkPhoneToAdd();
  checkEmailToAdd();
});

const errorsArrRef = computed({
  get() {
    return props.errorsArr;
  },
  set(value) {
    emit('update:errorsArr', value);
  },
});

// Свойство, которое используется для смены текста в зависимости от isPassportFullFileld(store.currentUserPassport)
const passportValidText = computed(() => {
  return isPassportFullFileld(store.currentUserPassport)
    ? store.currentUserPassport.series + ' ' + store.currentUserPassport.number
    : 'нет данных';
});
const hasAdditionalPhone = computed(() => {
  return !!(
    inputFields.value.filter((e) => e.name === 'additional_phone').length !== 0
  );
});
const hasAdditionalEmail = computed(() => {
  return !!(
    inputFields.value.filter((e) => e.name === 'additional_email').length !== 0
  );
});
const isEqualAdditionalPhone = (name) => {
  return !!(name === 'additional_phone');
};
const isEqualAdditionalEmail = (name) => {
  return !!(name === 'additional_email');
};
const isEqualAdditionalPhoneOrEmail = (name) => {
  if (name === 'additional_phone' || name === 'additional_email') {
    return true;
  } else {
    return false;
  }
};
const isShownAddPhoneIcon = (name, value) => {
  return !!(value && name === 'phone' && !hasAdditionalPhone.value);
};
const isShownAddEmailIcon = (input, value, index) => {
  if (
    value &&
    ((input.name === 'email' && !hasAdditionalEmail.value) ||
      (index !== 0 &&
        index !== 2 &&
        input.type === 'phone' &&
        !hasAdditionalEmail.value &&
        input.name !== 'phone'))
  ) {
    return true;
  } else {
    return false;
  }
};
const isShownTextOnInput = (name, index) => {
  if (
    ((name === 'passport' &&
      !isPassportFullFileld(store.currentUserPassport)) ||
      (name === 'founders' && !props.isFoundersPassportsFilled)) &&
    showEditIcon.value !== index
  ) {
    return true;
  } else {
    return false;
  }
};
const isShownCorrectIcon = (name, value, index) => {
  if (name === 'founders') {
    return props.isFoundersPassportsFilled;
  }
  if (
    store.currentCompanyInfo?.head_additional_phones[0] &&
    name === 'additional_phone' &&
    showEditIcon.value !== index
  ) {
    return true;
  }
  if (
    store.currentCompanyInfo?.head_additional_emails[0] &&
    name === 'additional_email' &&
    showEditIcon.value !== index
  ) {
    return true;
  }
  if (
    name === 'passport' &&
    isPassportFullFileld(store.currentUserPassport) &&
    showEditIcon.value !== index
  ) {
    return true;
  }
  if (value && /phone|email|full_name/.test(name)) {
    return true;
  }
  return false;
};
const isTextDanger = (type) => {
  return (
    (/passport/.test(type) && invalidPassportData.value) ||
    (/founders/.test(type) && invalidFounderData.value)
  );
};
const isHoverWhite = (name, type) => {
  return (
    !/full_name/.test(name) &&
    ((/phone/.test(name) && !hasAdditionalPhone.value) ||
      (/email/.test(name) && !hasAdditionalEmail.value) ||
      /passport|founders/.test(type))
  );
};
// watch error notify value, to reset after animation (4s)
watch(
  () => invalidPassportData.value,
  () => {
    invalidPassportData.value
      ? setTimeout(() => (invalidPassportData.value = false), 3500)
      : null;
  },
);
watch(
  () => invalidFounderData.value,
  () => {
    invalidFounderData.value
      ? setTimeout(() => (invalidFounderData.value = false), 3500)
      : null;
  },
);
const checkPhoneToAdd = () => {
  if (
    store.currentCompanyInfo?.head_additional_phones.length !== 0 &&
    !hasAdditionalPhone.value
  ) {
    onClickAddPhoneOrEmail(1, 'phone');
  }
};
const checkEmailToAdd = () => {
  if (
    store.currentCompanyInfo?.head_additional_emails.length !== 0 &&
    !hasAdditionalPhone.value &&
    !hasAdditionalEmail.value
  ) {
    onClickAddPhoneOrEmail(2, 'email');
  }
  if (
    store.currentCompanyInfo?.head_additional_emails.length !== 0 &&
    hasAdditionalPhone.value &&
    !hasAdditionalEmail.value
  ) {
    onClickAddPhoneOrEmail(3, 'email');
  }
};
const inputFields = ref([
  {
    title: 'генеральный директор',
    value: computed(() => {
      return store.fullName || store.currentCompanyInfo.title;
    }),
    type: 'full_name',
    name: 'full_name',
  },
  {
    title: 'телефон генерального директора',
    value: computed(() => {
      return store.currentUser.phone
        ? mask.masked(store.currentUser.phone)
        : '';
    }),
    type: 'phone',
    name: 'phone',
  },
  {
    title: 'почта генерального директора',
    value: computed(() => {
      return store.currentUser.email;
    }),
    type: 'email',
    name: 'email',
  },
  {
    title: 'паспортные данные',
    value: computed(() => {
      return passportValidText.value;
    }),
    type: 'passport',
    name: 'passport',
  },
  {
    title: 'другие учредители',
    value: computed(() => {
      return (
        props.ulFoundersArr?.length +
        props.ipFoundersArr?.length +
        ' учредител' +
        pluralize(props.ulFoundersArr?.length + props.ipFoundersArr?.length, [
          'ь',
          'я',
          'ей',
        ])
      );
    }),
    type: 'founders',
    name: 'founders',
  },
]);
const onClickGoToComponent = (type) => {
  if (props.loading) {
    return;
  }
  switch (type) {
    case 'passport':
      isPassportFullFileld(store.currentUserPassport) ||
      isParsed.value ||
      isError.value ||
      !store.isParsingEnabled
        ? emit('change-component', WithoutParser)
        : emit('change-component', WithParser);
      break;
    case 'founders':
      emit('change-component', FoundersList);
      break;
    default:
      break;
  }
};
const onClickAddPhoneOrEmail = (idx, value) => {
  if (value === 'phone' && idx === 1 && !hasAdditionalPhone.value) {
    const newPhoneInput = {
      title: 'дополнительный телефон',
      name: 'additional_phone',
      type: 'phone',
    };
    inputFields.value.splice(idx + 1, 0, newPhoneInput);
    tracker.queue(tracker.commands.SEND, SPA_LK_BKI_nummber_add);
  } else if (value === 'email' && (idx === 2 || idx === 3)) {
    const newEmailInput = {
      title: 'дополнительная почта',
      name: 'additional_email',
      type: 'email',
    };
    inputFields.value.splice(idx + 1, 0, newEmailInput);
    tracker.queue(tracker.commands.SEND, SPA_LK_BKI_email_add);
  }
};
const onClickSignAgreement = () => {
  const goToAgreement = () => {
    emit('send-otp-async');
  };

  v$.value.$touch();
  if (v$.value.$invalid) {
    return;
  }

  switch (true) {
    case !isPassportFullFileld(store.currentUserPassport) ||
      !props.isFoundersPassportsFilled:
      !isPassportFullFileld(store.currentUserPassport)
        ? (invalidPassportData.value = true)
        : null;
      !props.isFoundersPassportsFilled
        ? (invalidFounderData.value = true)
        : null;
      break;
    case isChecked.value:
      if (
        !isDocumentUploaded.value ||
        !store.currentCompanyInfo.other_management_description
      ) {
        validationError.value = ['добавьте описание и загрузите документ'];
        return;
      }
      goToAgreement();
      break;
    case !isChecked.value:
      goToAgreement();
      break;
  }
};
const onChangeSendAdditionalPhone = () => {
  return server.putCompanyProfiles$company_id
    .send(
      {
        company_profile: {
          head_additional_phones: [
            formatNumber(store.currentCompanyInfo.head_additional_phones[0]),
          ],
        },
      },
      {
        params: {
          company_id: store.currentCompany.id,
        },
      },
    )
    .pipe(
      () => {},
      () => {},
    )
    .exec();
};
const onChangeSendAdditionalEmail = () => {
  return server.putCompanyProfiles$company_id
    .send(
      {
        company_profile: {
          head_additional_emails:
            store.currentCompanyInfo.head_additional_emails,
        },
      },
      {
        params: {
          company_id: store.currentCompany.id,
        },
      },
    )
    .pipe(
      () => {},
      () => {},
    )
    .exec();
};
const onClickRedirectToUserCabinet = () => {
  if (props.loading) {
    return;
  }
  emit('redirect-to-user-cabinet');
};
</script>

<style lang="scss" scoped>
.container-page {
  max-width: 460px;
  .form {
    .inputs-container {
      background-color: $background;
      border-radius: 14px;
      padding: 20px 0px;
      .input-box {
        position: relative;
        margin: 0px 10px;
        &:hover .white-hover {
          background-color: white;
        }
        input {
          @include input;
          height: 78px;
          padding: 20px 40px 20px 20px;
          border: none;
          cursor: pointer;
          border-radius: 14px;
          margin-bottom: 8px;
          white-space: nowrap;
          overflow: hidden;
        }
        .placeholder {
          position: absolute;
          left: 20px;
          top: 10px;
          font-size: 8px;
          line-height: 12px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $grey;
        }
        .text-fill {
          position: absolute;
          right: 20px;
          top: 30px;
          cursor: pointer;
          color: $tiffany;
        }
        .icon {
          position: absolute;
          right: 20px;
          top: 30px;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center;
          &-edit {
            pointer-events: none;
            background-image: url(@/assets/icons/edit/edit-icon.svg);
            @media (max-width: $size_575) {
              background-image: url(@/assets/icons/arrows/edit-icon-arr.svg);
            }
          }
          &-correct {
            pointer-events: none;
            background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
            filter: $filter-breakwater;
          }
          &-add {
            cursor: pointer;
            background-image: url(@/assets/icons/rounded/plus-icon.svg);
            right: 55px;
            &:hover ~ .popover__content_add {
              visibility: visible;
            }
          }
        }
        .popover__content_add {
          visibility: hidden;
          font-size: 14px;
          line-height: 22px;
          padding: 10px 14px;
          position: absolute;
          right: -25px;
          top: -30px;
          background-color: white;
          box-shadow: 0px 16px 64px rgba(183, 203, 228, 0.5);
          border-radius: 8px;
          width: auto;
        }

        .divider {
          border-top: 1px solid $grey;
          margin: 0px;
        }
      }
    }
    .row-link {
      cursor: pointer;
      color: $grey;
      &:hover {
        color: $breakwater;
      }
    }
  }
  .icon-notify {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/notices/notify-alert.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}
.footer-text {
  font-size: 12px;
  margin-top: 30px;
  line-height: 20px;
}
</style>
