export default {
  setCurrentCompanyInfo(data) {
    this.currentCompanyInfo = data;
  },
  setCompaniesItems(data) {
    this.companies.items = data;
  },
  setCurrentCompany(data) {
    this.currentCompany = data;
  },
};
