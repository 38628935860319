import config from '@/../config';

export default {
  getPayouts: {
    url: config.VITE_BASE_URL + `/spa/business/payouts`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
};
