<template>
  <ModalFull @close-modal="onClickCloseModal"
    ><template #title>как отсканировать qr код из галереи?</template
    ><template #content>
      <div class="modal-text">
        <div class="d-flex align-items-center mb-2">
          <div class="circle">1</div>
          <div>скачайте qr код на ваше устройство</div>
        </div>
        <div class="d-flex align-items-center color-grey">
          <div class="dot-container"><div class="dot"></div></div>
          <div>кликните на скачать qr код</div>
        </div>
        <div class="d-flex align-items-center color-grey">
          <div class="dot-container"><div class="dot"></div></div>
          <div>или сделайте скриншот страницы с qr кодом</div>
        </div>
        <hr />
        <div class="d-flex align-items-center mb-2">
          <div class="circle">2</div>
          <div>откройте мобильное приложение вашего банка</div>
        </div>
        <div class="d-flex align-items-center color-grey">
          <div class="dot-container"><div class="dot"></div></div>
          <div>запустите в нем функцию для оплаты по qr коду</div>
        </div>
        <div class="d-flex align-items-center color-grey">
          <div class="dot-container"><div class="dot"></div></div>
          <div>теперь выберите способ оплаты по фотографии</div>
        </div>
        <hr />
        <div class="d-flex align-items-center mb-2">
          <div class="circle">3</div>
          <div>кликните на скачанный ранее qr код</div>
        </div>
        <hr />
        <div class="d-flex align-items-center mb-2">
          <div class="circle">4</div>
          <div>переведите 1 рубль по полученным реквизитам</div>
        </div>
      </div>
    </template></ModalFull
  >
</template>

<script setup>
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';

const emit = defineEmits(['close-modal']);

const onClickCloseModal = () => {
  emit('close-modal');
};
</script>

<style lang="scss" scoped>
.modal-text {
  font-size: 14px;
  .circle {
    border-radius: 50%;
    width: 14px;
    height: 14px;
    font-size: 8px;
    font-weight: 500;
    line-height: 14px;
    border: 1px solid $dipedive;
    text-align: center;
    margin-right: 10px;
  }
  .dot-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: 14px;
    margin-right: 10px;
    .dot {
      height: 6px;
      width: 6px;
      background-color: $grey;
      border-radius: 50%;
    }
  }
}
</style>
