<template>
  <div class="form m-auto">
    <h1 class="h3 fw-bold mb-6 text-center">проверьте информацию</h1>
    <div class="inputs-container mb-6">
      <div
        v-for="(input, index) in inputs"
        :key="index"
        class="input-box"
        @mouseover="showIcon = index"
        @mouseleave="showIcon = ''"
        @click="onClickHandleAction(index, input)"
      >
        <template v-if="input.visible">
          <div class="input">
            <span v-if="input.readonly">{{ inputs[index].value }}</span>
            <CurrencyInput
              v-if="!input.readonly"
              v-model="currentOrder.desired_loan_amount"
              v-model:isFocused="isFocused"
            />
          </div>
          <span class="placeholder">{{ input.title }}</span>
          <i v-if="showIcon === index" class="icon icon-edit"></i>
          <span
            v-if="
              index > 1 && showIcon !== index && v$[input.variable].$invalid
            "
            class="action-text text-tiffany"
            >заполнить &#8594;</span
          >
          <i
            v-if="isShowSuccessIcon(input, index)"
            class="icon icon-correct"
          ></i>
          <div
            v-if="
              index > 1 &&
              v$[input.variable].$invalid &&
              v$[input.variable].$dirty
            "
            class="text-danger text-start error"
            :class="{
              'error-amount':
                v$.desired_loan_amount.$invalid &&
                v$.desired_loan_amount.$dirty,
            }"
          >
            обязательное поле
          </div>
          <div
            v-if="
              !input.readonly && v$.desired_loan_amount.$invalid && !isFocused
            "
            class="text-start error"
          >
            не указана
          </div>
        </template>
      </div>
    </div>
    <PotokButton
      v-if="!props.loading"
      id="continue"
      class="mb-4 w-100"
      text="все верно, продолжить"
      size="large"
      @click="goToOnboarding()"
    />
    <LoadingBtn v-if="props.loading" class="mb-4" />
    <h6
      class="row-link fw-normal text-center"
      @click="props.loading ? null : emit('redirect-to-user-cabinet')"
    >
      заполнить позже
    </h6>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import GeneralInfo from './GeneralInfo.vue';
import CompanySites from './CompanySites.vue';
import CompanyLocation from './CompanyLocation.vue';
import AboutCompany from './AboutCompany.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import useVuelidate from '@vuelidate/core';
import CurrencyInput from './ui/CurrencyInput.vue';
import { required } from '@vuelidate/validators';
import { ref, computed, inject } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import { storeToRefs } from 'pinia';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Business_fill_success } = TRACKER_EVENTS;

const store = useStateStore();
const { currentCompanyInfo, currentOrder, isHadOrders } = storeToRefs(store);
const emitter = inject('emitter');

const emit = defineEmits([
  'change-component',
  'redirect-to-user-cabinet',
  'put-company-profiles',
]);
const props = defineProps({
  dictionary: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
  loading: {
    type: Boolean,
    default: false,
    required: true,
  },
  industryCategory: {
    type: String,
    default: '',
    required: true,
  },
});

const isNoSite = computed(() => {
  return currentCompanyInfo.value.no_site
    ? 'нет сайта'
    : currentCompanyInfo.value.sites.join(', ');
});

const isFocused = ref(false);
const showIcon = ref('');
// ключ variable в объектах используется для vuelidate (v$[input.variable].$invalid), т.к. поля юр.лицо и инн валидируются в компоненте GeneralInfo, тут их нет
const inputs = ref([
  {
    title:
      currentCompanyInfo.value.kind === 'UL'
        ? 'юридическое лицо'
        : 'индивидуальный предприниматель',
    value: currentCompanyInfo.value.short_title,
    readonly: true,
    visible: true,
  },
  {
    title: 'инн',
    value: currentCompanyInfo.value.inn,
    readonly: true,
    visible: true,
  },
  {
    title: 'сайт',
    value: isNoSite.value,
    variable: 'sites',
    readonly: true,
    visible: true,
  },
  {
    title: 'фактический адрес',
    value: currentCompanyInfo.value.business_location,
    variable: 'business_location',
    readonly: true,
    visible: true,
  },
  {
    title: 'отрасль',
    value: props.industryCategory,
    variable: 'industry_dictionary_category_id',
    readonly: true,
    visible: true,
  },
  {
    title: 'описание деятельности',
    value: currentCompanyInfo.value.primary_occupation,
    variable: 'primary_occupation',
    readonly: true,
    visible: true,
  },
  {
    title: 'желаемая сумма займа',
    value: computed(() => {
      return currentOrder.value.desired_loan_amount;
    }),
    variable: 'desired_loan_amount',
    readonly: false,
    visible: computed(() => {
      return !isHadOrders.value;
    }),
  },
]);

// валидаторы на пустое значение, в случае с сайтом, проверяем value[0] != '', т.к. масив sites всегда должен содержать хотя бы один элемент для отрисовки фреймворком первого инпута
const notEmptySite = (value) =>
  currentCompanyInfo.value.no_site ? true : value[0] !== '';
const notEmptyAddress = (value) =>
  currentCompanyInfo.value.business_location_eq_company_address
    ? true
    : value !== '';
const notNull = (value) => {
  return !!value;
};
const validations = {
  primary_occupation: {
    required,
  },
  business_location: {
    notEmptyAddress,
  },
  sites: {
    notEmptySite,
  },
  industry_dictionary_category_id: {
    required,
  },
  desired_loan_amount: {
    notNull: isHadOrders.value ? true : notNull,
  },
};

const v$ = useVuelidate(validations, {
  primary_occupation: computed(
    () => currentCompanyInfo.value.primary_occupation,
  ),
  business_location: computed(() => currentCompanyInfo.value.business_location),
  sites: computed(() => currentCompanyInfo.value.sites),
  industry_dictionary_category_id: computed(
    () => currentCompanyInfo.value.industry_dictionary_category_id,
  ),
  desired_loan_amount: computed(() => currentOrder.value.desired_loan_amount),
});

// порядок компонентов, согласно макету
const onClickHandleAction = (index, input) => {
  v$.value.$reset();
  if (props.loading || !input?.readonly) {
    if (!input?.readonly && !isFocused.value) {
      isFocused.value = true;
    }
    return;
  }
  const componentsOrder = [
    GeneralInfo,
    GeneralInfo,
    CompanySites,
    CompanyLocation,
    AboutCompany,
    AboutCompany,
  ];
  emit('change-component', componentsOrder[index]);
};
const isShowSuccessIcon = (input, index) => {
  return !!(showIcon.value !== index && input?.value);
};
const goToOnboarding = () => {
  if (v$.value.$invalid) {
    v$.value.$touch();
  } else {
    tracker.queue(tracker.commands.SEND, SPA_LK_Business_fill_success);
    emit(
      'put-company-profiles',
      {
        desired_loan_amount: currentOrder.value.desired_loan_amount,
      },
      {
        onSuccess: () => emitter.emit('company-profile-success'),
      },
    );
  }
};
</script>

<style lang="scss" scoped>
.form {
  .inputs-container {
    background-color: $background;
    border-radius: 14px;
    padding: 20px 0px;
    .input-box {
      position: relative;
      margin: 0px 10px;
      padding: 0px 10px;
      cursor: pointer;
      &:not(:first-child) {
        border-top: 1px solid rgba($grey, 0.2);
      }
      &:hover:not(:first-child) {
        border-top: 1px solid rgba($grey, 0);
      }
      &:hover {
        background: white;
        border-radius: 14px;
      }
      &:hover + .input-box {
        border-top: 1px solid rgba($grey, 0);
      }
      &:hover .input {
        background: white;
      }
      .input {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        height: 78px;
        padding: 30px 40px 20px 0px;
        background-color: $background;
        color: $dipedive;
      }

      .placeholder {
        position: absolute;
        top: 10px;
        font-size: 8px;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $grey;
      }
      .action-text {
        position: absolute;
        top: 35%;
        right: 20px;
      }
      .icon {
        position: absolute;
        right: 20px;
        top: 30px;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        cursor: pointer;
        &-edit {
          background-image: url(@/assets/icons/edit/edit-icon.svg);
        }
        &-correct {
          background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
          filter: $filter-breakwater;
        }
      }
      .error {
        position: absolute;
        top: 28px;
        z-index: 0;
      }
      .error-amount {
        top: 48px;
      }
    }
  }
  .row-link {
    cursor: pointer;
    color: $grey;
    &:hover {
      color: $breakwater;
    }
  }
}
</style>
