export default {
  setStatements(data) {
    this.statements = data;
    localStorage.setItem('statements', JSON.stringify(data));
  },
  getStatements() {
    if (localStorage.getItem('statements')) {
      let data = JSON.parse(localStorage.getItem('statements'));
      this.statements = data;
    }
  },
};
