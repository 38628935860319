import { inject } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { useRouter } from 'vue-router';

export function useRegEvents() {
  const store = useStateStore();
  const router = useRouter();
  const emitter = inject('emitter');

  /**
   * событие to-reg возникает когда АПИ users возвращает следующие признаки === false:
   * store.currentUser.confirmed &&
   * store.currentUser.password_set &&
   * store.currentUser.email_set &&
   * store.currentUser.agent_inn_set
   * в UserLogin.vue
   * что означает что пользователь не прошел до конца регистрацию
   * то есть у него не установлен какой либо признак
   * далее отправляем его на страницу регистрации на соответствующий шаг
   */
  emitter.on('to-reg', () => {
    const getStep = () => {
      if (!store.currentUser.confirmed) return 2;
      if (!store.currentUser.password_set) return 3;
      if (!store.currentUser.email_set) return 4;
      if (!store.currentUser.agent_inn_set && store.currentUser.agent) return 5;
      return null; // Default case if none of the conditions match
    };

    const step = getStep();

    if (step !== null) {
      const routeName = store.currentUser.agent ? 'agentReg' : 'registration';

      router.push({
        name: routeName,
        query: { step },
      });
    }
  });
}
