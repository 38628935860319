import { ref, computed, reactive, inject } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { useRouter } from 'vue-router';
import server from '@/server/index';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import {
  getAndSortPeriodInMonth,
  calculateSteps,
  calculateInterestAmount,
} from './index';
import tracker from '@/tracker';
import constants from '@/constants';
import { TRACKER_EVENTS } from '@/tracker/events';
import Countdown from '@/modules/Countdown';
import { headUtils } from '@/components/auth/dashboard/head-section/headUtils.js';
import { generalUtils } from '@/modules/generalUtils.js';

const {
  SPA_LK_Conditions_Business_analytics,
  SPA_LK_VKL_Conditions_Business_analytics,
  SPA_LK_Conditions_open,
  SPA_LK_VKL_Conditions_open,
  SPA_LK_Conditions_limit_refrash_success,
  SPA_LK_VKL_Conditions_limit_refrash_success,
  SPA_LK_Conditions_reject,
  SPA_LK_VKL_Conditions_reject,
  SPA_LK_Conditions_success,
  SPA_LK_VKL_Conditions_success,
  SPA_LK_Conditions_personal_aim,
  SPA_LK_VKL_Conditions_personal_aim,
  SPA_LK_application_signature_start,
  SPA_LK_VKL_application_signature_start,
  SPA_LK_VKL_signature_SMS_success,
  SPA_LK_signature_SMS_success,
  SPA_LK_VKL_Conditions_update_limit_click,
} = TRACKER_EVENTS;

// --------------SHARED STATE----------//
const loanTermsAccept = reactive({
  loanPurpose: 'выберите цель займа',
  loanPurposeInput: '',
  promo: '',
});
const loading = ref(false);
const errors = ref([]);
const otpErrors = ref([]);
const isFocused = ref(false);
const isPromoAccepted = ref(false);
const isSigning = ref(false);
const periodInMonth = ref([]);
const leftTime = ref(0);
const countdown = new Countdown();
const agreementId = ref();

const isNotDefault = (value) => !!(value !== 'выберите цель займа');
const validations = {
  loanPurpose: {
    required,
    isNotDefault,
  },
  loanPurposeInput: {
    required,
  },
  promo: {
    required,
  },
};
const v$ = useVuelidate(validations, loanTermsAccept);

const isOtherSelected = computed(() => {
  return !!(loanTermsAccept.loanPurpose === 'прочие цели');
});

const periodMarks = computed(() => {
  const periods = periodInMonth.value;

  if (periods.length < 12) {
    const defaultMarkers = [3, 6, 9, 12];
    const markers = [];

    markers.push(periods[0]);
    defaultMarkers.forEach((el) => {
      if (periods.includes(el) && periods[0] !== el) {
        markers.push(el);
      }
    });

    return markers;
  }

  if (periods.length > 12 && periods.length < 24) {
    const defaultMarkers = [6, 12, 18, 24];
    const markers = [];

    markers.push(periods[0]);
    defaultMarkers.forEach((el) => {
      if (periods.includes(el) && periods[0] !== el) {
        markers.push(el);
      }
    });

    return markers;
  }

  if (periods.length === 12) {
    return [1, 3, 6, 9, 12];
  }
  if (periods.length === 24) {
    return [1, 6, 12, 18, 24];
  }
  return true;
});

export function loanTermsUtils() {
  const { getOrderAsync } = generalUtils();

  const emitter = inject('emitter');
  const store = useStateStore();
  const router = useRouter();

  const { limits } = headUtils();

  const { steps } = computed(() => {
    return calculateSteps(
      store.currentOrder.loan_terms_offer?.min_limit,
      store.currentOrder.loan_terms_offer?.available_limit,
    );
  });
  let intervalId = 0;

  const startPolling = () => {
    if (!intervalId) {
      intervalId = setInterval(function () {
        getOrderAsync({
          callback: () => {
            onGetOrderAsyncSuccess();
          },
        });
      }, 5000);
    }
  };
  const stopPolling = () => {
    clearInterval(intervalId);
    intervalId = null;
  };

  const interestAmount = computed(() => {
    return calculateInterestAmount({
      amount: store.isCustomParams
        ? store.pages?.loanTerm?.amountCustomParams
        : store.currentOrder.amount,
      period: store.pages?.loanTerm?.loanPeriodCustomParams,
      terms: store.currentOrder.loan_terms_offer?.terms,
      repayment_frequency: store.currentOrder.repayment_frequency,
      upfront_percent: store.currentOrder.upfront_percent,
      rate: store.currentOrder?.rate,
      type: 'custom_params',
    });
  });
  const onClickSignDocs = () => {
    if (
      v$.value.loanPurpose.$invalid ||
      (isOtherSelected.value && v$.value.loanPurposeInput.$invalid)
    ) {
      errors.value.push('укажите цель займа');
      return;
    }
    if (leftTime.value === 0) {
      sendOtpAsync();
    }
    isSigning.value = true;
  };
  const onClickGoToAnalytic = () => {
    tracker.queue(
      tracker.commands.SEND,
      store.isVKLFlow
        ? SPA_LK_VKL_Conditions_Business_analytics
        : SPA_LK_Conditions_Business_analytics,
    );
    router.push({ name: 'companyAnalytic' });
  };

  const sendOtpAsync = () => {
    loading.value = true;
    let query = null;
    if (
      store.isVKLFirstTranche &&
      store.currentOrder.credit_line.state === 'init'
    ) {
      query = {
        signature: {
          confirmable_id: parseInt(store.currentOrder.credit_line?.id),
          confirmable_type: 'CreditLine',
          kind: 'credit_line',
          order_amount: store.pages?.loanTerm?.amountCustomParams,
        },
      };
    } else {
      query = {
        signature: {
          confirmable_id: parseInt(store.currentOrder.id),
          confirmable_type: 'Order',
          kind: 'signing_documents',
          order_amount: store.pages?.loanTerm?.amountCustomParams,
        },
      };
    }
    return server.postSignatures
      .send(query)
      .pipe(onSendOtpAsyncSuccess, onSendOtpAsyncError)
      .exec();
  };
  const onSendOtpAsyncSuccess = ({ data }) => {
    tracker.queue(
      tracker.commands.SEND,
      store.isVKLFlow
        ? SPA_LK_VKL_application_signature_start
        : SPA_LK_application_signature_start,
    );
    agreementId.value = data?.id;
    countdown.reset();
    countdown.start();
    loading.value = false;
    isSigning.value = true;
  };
  const onSendOtpAsyncError = (error) => {
    loading.value = false;
    otpErrors.value = error.data?.messages;
  };
  const confirmOtpAsync = (rawOtp) => {
    const query = {
      signature: {
        code: rawOtp,
      },
    };
    loading.value = true;
    return server.putSignatures$id
      .send(query, {
        params: {
          id: agreementId.value,
        },
      })
      .pipe(onConfirmOtpAsyncSuccess, onConfirmOtpAsyncError)
      .exec();
  };
  const onConfirmOtpAsyncSuccess = () => {
    tracker.queue(
      tracker.commands.SEND,
      store.isVKLFlow
        ? SPA_LK_VKL_signature_SMS_success
        : SPA_LK_signature_SMS_success,
    );
    loading.value = false;
    isSigning.value = false;
    onClickSendOnAcceptance();
  };
  const onConfirmOtpAsyncError = (error) => {
    loading.value = false;
    otpErrors.value = error.data?.messages;
  };
  const updateOrderAsync = () => {
    loading.value = true;
    return server.getOrders$order_id
      .send(
        {},
        {
          params: {
            order_id: store.companyOrderId,
          },
        },
      )
      .pipe(
        ({ data }) => {
          loading.value = false;
          store.setCurrentOrder(data);
        },
        () => {
          loading.value = false;
        },
      )
      .exec();
  };

  /**
   * Совершаем действия после загрузки ордера
   * @param {*} param0
   */
  const onGetOrderAsyncSuccess = (obj) => {
    if (!obj) {
      return;
    }
    const data = obj.data;
    store.setCurrentOrder(data);

    loanTermsAccept.promo = data?.promo_code;
    isPromoAccepted.value = !!loanTermsAccept.promo;

    // сортируем полученные значения по возрастанию
    periodInMonth.value = getAndSortPeriodInMonth(
      store.currentOrder.loan_terms_offer?.terms || [],
    );

    // передвигаем слайдеры в крайнее правое положение при инициализации
    if (store.currentOrder.loan_terms_offer) {
      store.pages.loanTerm.amountCustomParams =
        store.currentOrder.loan_terms_offer.available_limit;
    }
    store.pages.loanTerm.loanPeriodCustomParams =
      periodInMonth.value[periodInMonth.value.length - 1];

    // передаем признак наличия холда
    tracker.queue(tracker.commands.PARAMS, {
      is_user_in_hold: store.currentOrder.loan_terms_offer?.hold_manual_scoring,
    });

    if (!store.isBeforeCustomParams && !store.isRejectedState) {
      tracker.queue(
        tracker.commands.SEND,
        store.isVKLFlow ? SPA_LK_VKL_Conditions_open : SPA_LK_Conditions_open,
      );
      tracker.queue(
        tracker.commands.SEND,
        store.isVKLFlow
          ? SPA_LK_VKL_Conditions_limit_refrash_success
          : SPA_LK_Conditions_limit_refrash_success,
      );
    }

    if (store.isRejectedState) {
      tracker.queue(
        tracker.commands.SEND,
        store.isVKLFlow
          ? SPA_LK_VKL_Conditions_reject
          : SPA_LK_Conditions_reject,
      );
      tracker.queue(
        tracker.commands.SEND,
        store.isVKLFlow
          ? SPA_LK_VKL_Conditions_limit_refrash_success
          : SPA_LK_Conditions_limit_refrash_success,
      );
    }
    loading.value = false;
  };

  const onClickPostPromoAsync = () => {
    if (v$.value.promo.$invalid) {
      errors.value.push('введите промокод');
      return;
    }
    loading.value = true;
    const query = {
      promo_code: loanTermsAccept.promo,
    };
    return server.postPromo
      .send(query, {
        params: {
          order_id: store.companyOrderId,
        },
      })
      .pipe(onPostPromoAsyncSuccess, onPostPromoAsyncError)
      .exec();
  };
  const onPostPromoAsyncSuccess = () => {
    isPromoAccepted.value = true;
    loading.value = false;
  };
  const onPostPromoAsyncError = (error) => {
    errors.value = error.data?.messages;
    loading.value = false;
  };
  const onClickSendOnAcceptance = () => {
    if (
      v$.value.loanPurpose.$invalid ||
      (isOtherSelected.value && v$.value.loanPurposeInput.$invalid)
    ) {
      errors.value.push('укажите цель займа');
      return;
    }
    loading.value = true;
    let query = {
      loan_terms_acceptance: {
        amount: store.isCustomParams
          ? store.pages?.loanTerm?.amountCustomParams
          : store.currentOrder.amount,
        desired_loan_amount: 0,
        loan_period: store.pages?.loanTerm?.loanPeriodCustomParams * 30,
        refinance_applicable: store.currentOrder.refinance_applicable,
        loan_purpose: isOtherSelected.value
          ? loanTermsAccept.loanPurposeInput
          : loanTermsAccept.loanPurpose,
      },
    };
    if (
      store.pages?.loanTerm?.selectedComissionTypeCustomParams &&
      store.isCommissionChoiceAllowed
    ) {
      if (
        store.pages?.loanTerm?.selectedComissionTypeCustomParams ===
        'upfront_percent'
      ) {
        query.loan_terms_acceptance.commission_type = 'upfront';
      }
      if (
        store.pages?.loanTerm?.selectedComissionTypeCustomParams ===
        'monthly_commission'
      ) {
        query.loan_terms_acceptance.commission_type = 'monthly_commission';
      }
    } else {
      query.loan_terms_acceptance.commission_type = 'upfront';
    }
    return server.postOrderTermsAcceptance
      .send(query, {
        params: {
          order_id: store.companyOrderId,
        },
      })
      .pipe(onClickSendOnAcceptanceSuccess, onClickSendOnAcceptanceError)
      .exec();
  };
  const onClickSendOnAcceptanceSuccess = () => {
    loading.value = false;
    tracker.queue(
      tracker.commands.SEND,
      store.isVKLFlow
        ? SPA_LK_VKL_Conditions_success
        : SPA_LK_Conditions_success,
    );
    router.push({
      name: 'loanCard',
      params: {
        order_id: store.companyOrderId,
      },
    });
  };
  const onClickSendOnAcceptanceError = (error) => {
    errors.value = error.data?.messages;
    loading.value = false;
  };
  const onChangeSendAnalytics = () => {
    tracker.queue(
      tracker.commands.SEND,
      store.isVKLFlow
        ? SPA_LK_VKL_Conditions_personal_aim
        : SPA_LK_Conditions_personal_aim,
    );
  };
  const createOrderAsync = () => {
    loading.value = true;
    return server.postOrders
      .send()
      .pipe(onCreateOrderAsyncSuccess, onCreateOrderAsyncError)
      .exec();
  };
  const onCreateOrderAsyncSuccess = ({ data }) => {
    store.setCurrentOrder(data);
    loading.value = false;
    router.push({ name: 'bankStatement' });
  };
  const onCreateOrderAsyncError = () => {
    loading.value = false;
  };

  const isActionButtonDisabled = () => {
    if (store.isCompanyBanned) {
      return true;
    }
    if (store.isOrderHold) {
      if (store.isRequiredUpdateLimit) {
        return false;
      }
      return true;
    }
    if (!store.isRejectedState && store.isVKLFlowRequiredUpdateLimit) {
      return false;
    }
    if (
      store.isRejectedState &&
      store.currentCompany.allow_next_loan_after_date
    ) {
      return true;
    }
    if (!store.isVKLFlow && store.isMainFlowRequiredUpdateLimit) {
      return true;
    }
    if (
      store.currentOrder.client_state ===
        constants.CLIENT_STATES.auto_scoring ||
      store.isVKLOnboardingContinueDisabled
    ) {
      return true;
    }
    if (store.isNewLimit && limits.value.available <= 0) {
      return true;
    }
    return false;
  };
  const getActionForButton = (action) => {
    if (store.isRejectedState) {
      if (action === 'title') {
        return 'взять транш';
      }
      if (action === 'action') {
        return createOrderAsync();
      }
      if (action === 'blur') {
        return false;
      }
    }
    if (!store.isVKLFlow) {
      if (store.isHasLoan && (store.isManualCheck || store.isCustomParams)) {
        if (action === 'blur') {
          return true;
        }
        if (action === 'title') {
          return 'продолжить оформление';
        }
        if (action === 'action') {
          return emitter.emit('order-card-click', store.currentOrder);
        }
      }
      if (store.isMainFlowRequiredUpdateLimit || store.isNewOrderAllowed) {
        if (action === 'title') {
          return 'начать оформление';
        }
        if (action === 'action') {
          return onClickSendOnAcceptance();
        }
        if (action === 'blur') {
          return false;
        }
      }
    }
    if (store.isVKLFlow) {
      if (store.isVKLFlowRequiredUpdateLimit) {
        if (action === 'title') {
          return 'обновить лимит';
        }
        if (action === 'action') {
          return onClickUpdateLimit();
        }
      }
      if (
        store.currentOrder.client_state === constants.CLIENT_STATES.investing ||
        store.currentOrder.client_state ===
          constants.CLIENT_STATES.manual_check ||
        store.currentOrder.client_state === constants.CLIENT_STATES.ready
      ) {
        if (action === 'title') {
          return 'продолжить оформление';
        }
        if (action === 'action') {
          return emitter.emit('order-card-click', store.currentOrder);
        }
      }
      if (action === 'title') {
        if (store.currentOrder.experimental_take_tranche_button_name) {
          return 'на рассмотрение';
        }
        return 'взять транш';
      }
      if (action === 'action') {
        if (store.currentOrder.experimental_take_tranche_button_name) {
          tracker.queue(
            tracker.commands.SEND,
            'SPA_Experiment_LK_VKL_Conditions_success',
          );
        }
        return onClickSignDocs();
      }
    }
    if (action === 'title') {
      return 'продолжить оформление';
    }
    if (action === 'action') {
      if (store.isProductTypeInstallment) {
        return onClickSendOnAcceptance();
      }
      return emitter.emit('order-card-click', store.currentOrder);
    }
  };

  const onClickUpdateLimit = () => {
    tracker.queue(
      tracker.commands.SEND,
      SPA_LK_VKL_Conditions_update_limit_click,
    );

    const query = {
      command: {
        command_name: 'refresh_limit',
        resource_name: 'Order',
        resource_id: store.currentOrder.id,
      },
    };
    return server.postCommands
      .send(query)
      .pipe(
        () => {
          loading.value = false;
          updateOrderAsync();
        },
        () => {
          loading.value = false;
        },
      )
      .exec();
  };
  return {
    leftTime,
    countdown,
    agreementId,
    loanTermsAccept,
    loading,
    isFocused,
    isSigning,
    isPromoAccepted,
    periodInMonth,
    periodMarks,
    errors,
    otpErrors,
    steps,
    intervalId,
    v$,
    isOtherSelected,
    interestAmount,
    getActionForButton,
    isActionButtonDisabled,
    startPolling,
    stopPolling,
    onClickGoToAnalytic,
    sendOtpAsync,
    confirmOtpAsync,
    onGetOrderAsyncSuccess,
    onClickPostPromoAsync,
    onClickSendOnAcceptance,
    onChangeSendAnalytics,
    onClickSignDocs,
  };
}
