<template>
  <div class="form m-auto">
    <h1 class="h3 fw-bold mb-2 text-center">введите сайт вашей компании</h1>
    <h6 class="fw-normal mb-6 text-secondary text-center lh-base">
      шаг 3 из 4
    </h6>
    <div
      v-for="(site, index) in store.currentCompanyInfo.sites"
      :key="index"
      class="position-relative"
    >
      <input
        v-model="store.currentCompanyInfo.sites[index]"
        class="address-input mb-2"
        placeholder="сайт вашей компании"
        type="text"
        :disabled="store.currentCompanyInfo.no_site"
        @focus="siteFormatError = false"
      />
      <i
        v-if="index !== 0"
        class="trash-icon"
        @click="store.currentCompanyInfo.sites.splice(index, 1)"
      ></i>
    </div>
    <div v-if="siteFormatError" class="text-danger">неверный формат</div>
    <div
      class="d-flex align-items-center justify-content-end mb-4"
      style="cursor: pointer"
      @click="addSiteInput"
    >
      <i class="add-site-icon me-1"></i>
      <div>добавить сайт</div>
    </div>
    <Checkbox
      v-model:isChecked="store.currentCompanyInfo.no_site"
      class="mb-6"
      @callback="
        !store.currentCompanyInfo.no_site
          ? (store.currentCompanyInfo.sites = ['нет сайта'])
          : (store.currentCompanyInfo.sites = [''])
      "
      ><template #default>нет сайта</template></Checkbox
    >
    <PotokButton
      v-if="!props.loading"
      class="mb-4 w-100"
      text="продолжить"
      size="large"
      @click="putCompanyProfiles()"
    />

    <LoadingBtn v-if="props.loading" class="mb-4" />
    <h6
      class="row-link fw-normal text-center mb-3"
      @click="props.loading ? null : emit('redirect-to-user-cabinet')"
    >
      заполнить позже
    </h6>
    <h6
      class="row-link fw-normal text-center"
      @click="props.loading ? null : emit('change-component', CompanyLocation)"
    >
      назад
    </h6>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { ref } from 'vue';
import Checkbox from '@/components/_generic/ui/widgets/Checkbox.vue';
import SummaryInfo from './SummaryInfo.vue';
import CompanyLocation from './CompanyLocation.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Business_webpage_add, SPA_LK_Business_webpage_success } =
  TRACKER_EVENTS;

const store = useStateStore();

const emit = defineEmits([
  'change-component',
  'redirect-to-user-cabinet',
  'put-company-profiles',
]);
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
    required: true,
  },
});
const siteFormatError = ref(false);
const passRegex = ref(false);

const addSiteInput = () => {
  if (!store.currentCompanyInfo.no_site) {
    store.currentCompanyInfo.sites.push('');
    tracker.queue(tracker.commands.SEND, SPA_LK_Business_webpage_add);
  }
};
/* Фильтруем массив sites, если есть пустые инпуты - отбрасываем. Проверяем, удовлетворяет ли каждый элемент массива регулярному выражению.
Если да, присваиваем переменной passRegex значение true. Проверяем, что отфильтрованный массив не пустой и не содержит пустых строк.
Если passRegex, отправляем запрос, если !passRegex - выводим сообщение об ошибке. Если no_site - то отправляем запрос без массива sites*/
const putCompanyProfiles = () => {
  const expression =
    /(https?:\/\/)?([а-яА-Яa-zA-Z0-9-]+\.)+[а-яА-Яa-zA-Z0-9]{2,}\/?\S*$/;
  const regex = new RegExp(expression);
  const filteredArr = store.currentCompanyInfo.sites.filter((n) => n);
  filteredArr.forEach(function (entry) {
    if (entry.match(regex)) {
      passRegex.value = true;
    } else {
      passRegex.value = false;
    }
  });
  if (filteredArr.length && !store.currentCompanyInfo.no_site) {
    if (passRegex.value) {
      emit('put-company-profiles', {
        no_site: store.currentCompanyInfo.no_site,
        sites: filteredArr,
      });
      tracker.queue(tracker.commands.SEND, SPA_LK_Business_webpage_success);
      emit('change-component', SummaryInfo);
    } else {
      siteFormatError.value = true;
    }
  } else {
    emit('put-company-profiles', {
      no_site: store.currentCompanyInfo.no_site,
    });
    emit('change-component', SummaryInfo);
  }
};
</script>

<style lang="scss" scoped>
.form {
  .address-input {
    @include input;
    padding-right: 50px;
  }
  .trash-icon {
    position: absolute;
    right: 19px;
    top: 19px;
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/trash-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .add-site-icon {
    width: 16px;
    height: 16px;
    background-image: url(@/assets/icons/rounded/plus-icon.svg);
    background-repeat: no-repeat;
  }
  .row-link {
    cursor: pointer;
    color: $grey;
    &:hover {
      color: $breakwater;
    }
  }
}
</style>
