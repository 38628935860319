<template>
  <OnClickOutside @trigger="emit('update:modelValue', [])">
    <div
      v-if="props.modelValue.length !== 0"
      class="suggestion-wrapper text-left"
    >
      <div
        v-for="(suggestion, index) in props.modelValue"
        :key="index"
        class="suggestion"
        @click="chooseSuggestion(suggestion)"
      >
        <span v-for="(key, idx) in props.keysInArr" :key="idx"
          ><span v-if="idx !== 0"> - </span>{{ suggestion[key] }}</span
        >
      </div>
    </div>
  </OnClickOutside>
</template>

<script setup>
import { OnClickOutside } from '@vueuse/components';
const emit = defineEmits(['choose-suggestion', 'update:modelValue']);
const props = defineProps({
  modelValue: { type: Array, required: true },
  width: {
    type: String,
    default: 'auto',
  },
  keysInArr: {
    type: Array,
    default() {
      return [];
    },
  },
});

const chooseSuggestion = (rawSuggestion) => {
  emit('choose-suggestion', rawSuggestion);
  emit('update:modelValue', []);
};
</script>

<style lang="scss" scoped>
.suggestion-wrapper {
  position: absolute;
  max-width: v-bind('props.width');
  z-index: 9999;
  background: $white;
  border: 1px solid rgba(204, 216, 255, 0.5);
  box-shadow: 0px 4px 53px rgba(183, 203, 228, 0.3);
  border-radius: 6px;
  max-height: 200px;
  overflow-y: auto;
  padding: 10px 0 10px 0;
  @media (max-width: $size_767) {
    width: 100%;
  }
  .suggestion {
    cursor: pointer;
    font-size: 12px;
    border-radius: 8px;
    margin: 0px 10px 0 10px;
    padding: 10px 0 10px 10px;
    &:hover {
      background-color: $background;
    }
  }
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: $background;
}
::-webkit-scrollbar-thumb {
  background: $input;
}
</style>
