<template>
  <div class="container pt-7">
    <AgentRegStep1 v-if="currentStep === 1" @change-step="changeStep" />
    <AgentRegStep2
      v-if="currentStep === 2"
      :inputed-phone="inputedPhone"
      @change-step="changeStep"
    />
    <AgentRegStep3 v-if="currentStep === 3" @change-step="changeStep" />
    <AgentRegStep4 v-if="currentStep === 4" @change-step="changeStep" />
    <AgentRegStep5 v-if="currentStep === 5" @change-step="changeStep" />
  </div>
</template>
<script setup>
import { ref, computed, onMounted } from 'vue';
import { useRoute } from 'vue-router';
import { useRedirection } from '@/server/index';

import { useStateStore } from '@/stores/stateStore';

import AgentRegStep1 from '@/components/unauth/agent/AgentRegStep1.vue';
import AgentRegStep2 from '@/components/unauth/agent/AgentRegStep2.vue';
import AgentRegStep3 from '@/components/unauth/agent/AgentRegStep3.vue';
import AgentRegStep4 from '@/components/unauth/agent/AgentRegStep4.vue';
import AgentRegStep5 from '@/components/unauth/agent/AgentRegStep5.vue';

const route = useRoute();
const store = useStateStore();
const currentStep = ref(1);

const inputedPhone = ref(null);

/**
 * Проверка на завершеность регистрации агента
 */
const isAgentRegisteted = computed(() => {
  return !!(
    store.currentUser.confirmed &&
    store.currentUser.password_set &&
    store.currentUser.email_set &&
    store.currentUser.agent_inn_set
  );
});

const changeStep = (obj) => {
  if (obj.step) {
    currentStep.value = obj.step;
  }
  if (obj.inputedPhone) {
    inputedPhone.value = obj.inputedPhone;
  }
  if (obj.inn) {
    useRedirection('/pvp/business/companies/');
  }
};

onMounted(() => {
  if (!isAgentRegisteted.value && route.query?.step) {
    const step = parseInt(route.query.step);
    if (store.currentUser.phone) {
      inputedPhone.value = store.currentUser.phone;
    }
    changeStep({
      step: step,
    });
  }
});
</script>
<style lang="scss" scoped>
.container {
  margin-top: 50px;
}
:deep(.form) {
  max-width: 340px;
  margin: auto;
}
:deep(.text-title) {
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: -0.3px;
}
:deep(.text-desc) {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: lowercase;
  color: #173b57;
  margin: auto;
}
:deep(input) {
  width: 100%;
  height: 60px;
  border: 1px solid transparent;
  padding: 20px 0 20px 20px;
  background-color: #f2f4f8;
  border-radius: 8px;
  outline: none;
  text-overflow: ellipsis;
  box-shadow: none;
  color: #10273d;
  &:focus {
    background-color: #f2f4f8;
    box-shadow: none;
    border-color: transparent;
  }
}
</style>
