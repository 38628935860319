<template>
  <footer>
    <div class="wrapper mx-auto">
      <a href="mailto:ok@potok.team" target="_blank">ok@potok.team</a>
      <a href="https://t.me/OkPotokDigitalBot" target="_blank">telegram</a>
      <a href="https://www.potok.digital/faq" target="_blank">faq</a>
      <a href="https://business.potok.digital/information" target="_blank"
        >раскрытие информации</a
      >

      <a href="https://www.potok.digital/documents" target="_blank"
        >документы платформы</a
      >
    </div>
  </footer>
</template>

<script setup></script>

<style lang="scss" scoped>
footer {
  // height: $footer-height;
  background-color: $background;
  padding: 28px 0 28px 0;
  @media (max-width: $size_767) {
  }
  .wrapper {
    max-width: 700px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    @media (max-width: $size_767) {
      padding: 20px;
      flex-direction: column;
      align-items: flex-start;
    }

    a {
      font-size: 14px;
      color: $grey;
      &:hover {
        color: $tiffany;
      }
    }
  }
}
</style>
