<template>
  <OnClickOutside @trigger="emit('close-modal')">
    <div class="wrapper">
      <template v-if="props.modalType === 'isSuggestion'"
        ><div class="form m-auto w-100">
          <i class="icon-close" @click="emit('close-modal')"></i>
          <h5 class="fw-normal mb-4">как загрузить выписку</h5>
          <div class="form-row mb-6 d-flex">
            <div class="d-flex flex-column align-items-center">
              <i class="numbers number-1 mt-1"></i
              ><span class="row-text-line"></span
              ><i class="numbers number-2"></i
              ><span class="row-text-line"></span
              ><i class="numbers number-3"></i>
            </div>
            <div class="ms-2 d-flex flex-column">
              <div class="mb-3">зайдите в личный кабинет банка</div>
              <div class="mb-2">
                скачайте выписку в формате экспорта для 1С (файл .txt)
              </div>
              <div>загрузите полученный файл нажав кнопку Загрузить</div>
            </div>
          </div>
          <span class="row-text-divider mb-6"></span>
          <h5 class="fw-normal mb-2">требования</h5>
          <div class="form-row mb-6">
            <ul class="list-group ps-3">
              <li class="list-group-item">
                выписка должна включать текущий месяц (должна быть сформирована
                по текущую дату)
              </li>
              <li class="list-group-item">
                выписка должна иметь не менее 12 месяцев
              </li>
              <li class="list-group-item">
                если вашему бизнесу менее 1 года, загрузите выписку за весь
                фактический срок работы
              </li>
              <li class="list-group-item">
                если вы планируете брать заем на срок более года, загрузите
                выписку за 24 месяца
              </li>
            </ul>
          </div>
          <span class="row-text-divider mb-6"></span>
          <h5 class="fw-normal mb-2">рекомендации</h5>
          <div>
            если вы используете несколько счетов в одном или нескольких банках,
            загрузите выписки со всех счетов для корректного вычисления лимита
            компании
          </div>
        </div></template
      >
      <template v-else>
        <div class="form m-auto w-100">
          <div class="icon-close" @click="emit('close-modal')"></div>
          <h5 class="fw-normal mb-4">{{ props.fileName }}</h5>
          <h5 class="fw-bold mb-4">ошибка в выписке</h5>
          <div class="text-wrapper text-left mb-6">
            <h5 class="fw-normal p-4">{{ props.errorText }}</h5>
          </div>
          <template v-if="props.suggestionText">
            <span class="row-text-divider mb-6"></span>
            <h5 class="fw-bold mb-4">как исправить ошибку</h5>
            <div class="text-wrapper-green text-left mb-4">
              <h5 class="fw-normal p-4">{{ props.suggestionText }}</h5>
            </div>
          </template>
        </div>
      </template>
    </div>
  </OnClickOutside>
</template>

<script setup>
import { OnClickOutside } from '@vueuse/components';

const emit = defineEmits(['close-modal']);
const props = defineProps({
  modalType: {
    type: String,
    default: '',
  },
  fileName: {
    type: String,
    default: '',
  },
  errorText: {
    type: String,
    default: '',
  },
  suggestionText: {
    type: String,
    default: '',
  },
});
</script>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  overflow-y: auto;
  width: 385px;
  background-color: white;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  position: fixed;
  top: 0;
  right: 0;
  .form {
    padding: 20px 25px 20px 30px;
    h5 {
      width: 90%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &-row {
      .row-text-line {
        height: 31px;
        background-color: $grey;
        border: 0.5px solid $grey;
      }
      .numbers {
        width: 14px;
        height: 14px;
      }
      .number-1 {
        background-image: url(@/assets/icons/rounded/icon-1.svg);
        margin-bottom: 4px;
      }
      .number-2 {
        background-image: url(@/assets/icons/rounded/icon-2.svg);
        margin: 4px 0px;
      }
      .number-3 {
        background-image: url(@/assets/icons/rounded/icon-3.svg);
        margin-top: 4px;
      }
    }
    .row-text-divider {
      display: block;
      width: 100%;
      background-color: $grey-hover;
      border: 0.5px solid $grey-hover;
      opacity: 0.2;
    }
    .text-wrapper {
      width: fit-content;
      height: fit-content;
      background: #fef1f2;
      border: 1px solid rgba(255, 87, 84, 0.2);
      border-radius: 8px;
      &-green {
        background: #f0fcfd;
        border: 1px solid #9febf0;
        border-radius: 8px;
      }
    }
    .icon-close {
      margin: 22px 25px 0px 0px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: $background;
}
::-webkit-scrollbar-thumb {
  background: $grey;
}
</style>
