<template>
  <div class="modal">
    <div class="modal-overlay"></div>
    <i v-if="!isMobile" class="icon-close" @click="onClickCloseModal"> </i>
    <div class="modal-wrapper">
      <BackBtnMobile
        v-if="isMobile"
        class="mt-4 ms-4"
        @click="onClickCloseModal"
      />
      <div class="modal-container content_scroll">
        <div v-if="slots.title" class="modal-title text-center">
          <slot name="title"></slot>
        </div>
        <div class="modal-content">
          <slot name="content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BackBtnMobile from '@/components/_generic/ui/buttons/BackBtnMobile.vue';
import { detectMobile } from '@/utils';
import { onMounted, onUnmounted } from 'vue';
import { blockScrollWindow } from '@/utils';
import { useSlots } from 'vue';

const { isMobile } = detectMobile();
const { disableScroll, enableScroll } = blockScrollWindow();

const slots = useSlots();
const props = defineProps({
  maxwidth: {
    type: String,
    required: false,
    default: '454px',
  },
});
const emit = defineEmits(['close-modal']);

onMounted(() => {
  disableScroll();
});

onUnmounted(() => {
  enableScroll();
});

const onClickCloseModal = () => {
  emit('close-modal');
};
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 10000;
}
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  white-space: nowrap;
  z-index: 100;
  outline: 0;
  @media (max-width: $size_767) {
    background-color: white;
  }
}
.modal-wrapper {
  height: auto;
  max-height: 90%;
  max-width: 454px;
  background-color: white;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  z-index: 1000;
  margin: auto;
  padding: 25px;
  border-radius: 14px;
  overflow: hidden;
  position: fixed;
  display: flex;

  @media (max-width: $size_767) {
    display: block;
    width: 100%;
    height: 100%;
    max-height: initial;
    padding: 20px;
    margin: 0px;
    min-width: 100%;
    position: relative;
  }
}
.modal-title {
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
}
.modal-container {
  max-width: v-bind('props.maxwidth');
  position: relative;
  padding: 25px;
  text-align: left;
  white-space: normal;
  background-color: $white;
  border-radius: 14px;

  @media (max-width: $size_767) {
    padding: 20px;
    margin: 0px;
    min-width: 100%;
    padding-bottom: 40px;
    height: 100%;
  }
}
.icon-close {
  position: absolute;
  top: 20px;
  right: 20px;
  filter: $filter-white;
  z-index: 100;
}
</style>
