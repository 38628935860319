<template>
  <div class="progress">
    <div class="current-value" :style="{ width: currentValuePercent }"></div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const currentValuePercent = computed(() => {
  if (!store.currentOrder.loan) {
    return '0%';
  }
  const value =
    (store.currentOrder.loan?.invested_amount * 100) /
    store.currentOrder.loan?.amount;
  if (value > 100) {
    return '100%';
  }
  return `${value}%`;
});
</script>
<style lang="scss" scoped>
.progress {
  height: 7px;
  width: 100%;
  background-color: $background;
  border-radius: 9.5px;
}
.current-value {
  height: 7px;
  background-color: $breakwater;
  border-radius: 9.5px;
}
</style>
