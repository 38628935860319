export const ML_EVENTS = {
  SPA_LK_Magic_Link_Page_View: 'SPA_LK_Magic_Link_Page_View',
  SPA_LK_Magic_Link_success_event: 'SPA_LK_Magic_Link_success_event',
  SPA_LK_Magic_Link_Phone_Check_Page_View:
    'SPA_LK_Magic_Link_Phone_Check_Page_View',
  SPA_LK_Magic_Link_Phone_Check_Next_Click:
    'SPA_LK_Magic_Link_Phone_Check_Next_Click',
  SPA_LK_Magic_Link_Phone_Check_success_event:
    'SPA_LK_Magic_Link_Phone_Check_success_event',
};
