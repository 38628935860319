<template>
  <FileUpload
    ref="upload"
    v-model="localValue"
    :name="options.file"
    :input-id="options.inputId"
    :post-action="options.postAction"
    :headers="{ Authorization: store.session.token }"
    :data="options.data"
    :extensions="options.extensions"
    :accept="options.accept"
    @input-file="inputFile"
    @input-filter="inputFilter"
  />
  <div v-if="!localValue?.[0]?.active && !options.loading">
    <label
      class="upload-wrapper position-relative"
      :style="
        changeIconCover(
          localValue[0]?.url,
          localValue[0]?.filename || localValue[0]?.name,
        )
      "
      :for="options.inputId"
      @mouseover="isEditShown = true"
      @mouseleave="isEditShown = false"
    >
      <PopoverIcon
        v-if="isEditShown && localValue.length"
        position="right_center"
        class="text-nowrap position-absolute start-50 top-50 translate-middle"
      >
        <template #icon
          ><div class="edit-wrapper">
            <span class="edit-icon"></span></div
        ></template>
        <template #text>загрузить другое фото</template>
      </PopoverIcon>
    </label>
  </div>
  <div v-else class="load-wrapper">
    <Loader class="m-4"></Loader>
  </div>
</template>

<script setup>
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import FileUpload from 'vue-upload-component';
import { Loader } from 'potok-uikit';
import { ref, computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';

const upload = ref();
const isEditShown = ref(false);

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
    default: () => {
      return [];
    },
  },
  options: {
    type: Object,
    required: true,
  },
});
const store = useStateStore();

const emit = defineEmits(['update:modelValue']);

const localValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});

const changeIconCover = (url, filename) => {
  return localValue.value.length > 0 && !filename.includes('.pdf')
    ? `background-image: url(` + url + `)`
    : {};
};
const inputFilter = (newFile, oldFile, prevent) => {
  if (newFile && !oldFile) {
    if (!/\.(gif|jpg|jpeg|png|webp|pdf)$/i.test(newFile.name)) {
      return prevent();
    }
  }
  if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
    newFile.url = '';
    let URL = window.URL || window.webkitURL;
    if (URL && URL.createObjectURL) {
      newFile.url = URL.createObjectURL(newFile.file);
    }
  }
  // передаём куки в запросе
  if (newFile && oldFile && newFile.xhr && !newFile.xhr.withCredentials) {
    newFile.xhr.withCredentials = true;
  }
};
const inputFile = (newFile, oldFile) => {
  // автозагрузка файлов при добавлении
  if (
    Boolean(newFile) !== Boolean(oldFile) ||
    oldFile.error !== newFile.error
  ) {
    if (upload.value && upload.value?.active === false) {
      upload.value.active = true;
      isEditShown.value = false;
    }
  }
  // show loader until load
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    if (newFile.xhr) {
      // loading.value = false;
    }
  }
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    if (newFile?.response?.error?.messages) {
      //   emit('update:errorsArr', newFile?.response?.error?.messages);
    }
  }
};
</script>

<style lang="scss" scoped>
.upload-wrapper {
  cursor: pointer;
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-image: url(@/assets/icons/files/file-uploads-sm-img-white.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  .edit-wrapper {
    background: rgba($dipedive, 0.6);
    border-radius: 8px;
    .edit-icon {
      pointer-events: none;
      display: inline-block;
      width: 60px;
      height: 55px;
      border-radius: 8px;
      background-repeat: no-repeat;
      background-position: center;
      background-image: url(@/assets/icons/edit/edit.svg);
    }
  }
}

.load-wrapper {
  width: 60px;
  height: 60px;
  background-color: white;
  border-radius: 8px;
}
</style>
