<template>
  <input
    ref="inputRef"
    type="text"
    :disabled="props.disabled"
    @focusin="emit('update:isFocused', true)"
    @focusout="emit('update:isFocused', false)"
  />
</template>

<script setup>
import { useCurrencyInput } from 'vue-currency-input';
import { watch } from 'vue';

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  options: {
    type: Object,
    default() {
      return {};
    },
  },
  disabled: {
    type: Boolean,
    defUult: false,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:isFocused', 'update:modelValue', 'change']);

const { inputRef, setValue } = useCurrencyInput(props.options);

watch(
  () => props.modelValue,
  (value) => {
    value ? setValue(value) : setValue(null);
  },
);
watch(
  () => props.isFocused,
  (value) => {
    if (value) {
      inputRef.value.focus();
    }
  },
);
</script>
