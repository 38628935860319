<template>
  <label class="checkbox-container"
    ><span class="checkbox-label" :class="props.fontSmall ? 'small' : ''"
      ><slot></slot
    ></span>
    <input
      v-model="value"
      type="checkbox"
      :disabled="props.isDisabled"
      @click="emit('callback', $event)"
    />
    <span class="checkmark"></span>
  </label>
</template>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  isChecked: {
    type: Boolean,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  fontSmall: {
    type: String,
    default: '',
  },
});
const emit = defineEmits(['update:isChecked', 'callback']);

const value = computed({
  get() {
    return props.isChecked;
  },
  set(value) {
    emit('update:isChecked', value);
  },
});
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkbox-label {
    line-height: 20px;
    margin-left: 30px;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: $background;
    border: 7px solid $background;
    border-radius: 4px;
  }
  &:hover input ~ .checkmark {
    border: 7px solid $grey;
  }
  input:checked ~ .checkmark {
    border: 7px solid $primary;
  }
}
</style>
