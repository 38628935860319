<template>
  <div class="blue-wrapper">
    <div class="blue-shadow"></div>
    <div class="blue-message">
      <template v-if="isBlurAutoScoring">
        <div class="await-text text-center">
          <Loader class="mt-2 m-auto" />
          обновляем информацию по компании
        </div>
      </template>
      <template v-else-if="isBlurContinueCreateLoan">
        <div class="await-text text-center">
          у вас есть
          <PotokButton
            class="mx-auto btn-active"
            text="активная заявка"
            size="medium"
            theme="linkIconUpload"
            @click="onClickContinueCreateLoan()"
          />
          на транш,<br />
          завершите ее оформление и дождитесь отправки средств на ваш расчетный
          счет
        </div>
      </template>
      <template v-else-if="isBlurUpdateLimit">
        <span class="await-text text-center"
          >чтобы взять новый заем,<br />нужно обновить лимиты
        </span>
      </template>
    </div>
  </div>
</template>
<script setup>
import { PotokButton, Loader } from 'potok-uikit';
import { loanBlurUtils } from '@/components/auth/dashboard/loan-terms/utils/loanBlurUtils.js';
import { useStateStore } from '@/stores/stateStore';

const { isBlurAutoScoring, isBlurContinueCreateLoan, isBlurUpdateLimit } =
  loanBlurUtils();
const store = useStateStore();

const onClickContinueCreateLoan = () => {
  store.setUiState('isDashboardLimitTabActive', false);
};
</script>
<style lang="scss" scoped>
.blue-wrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}
.blue-shadow {
  filter: blur(8px);
  opacity: 0.7;
  width: 100%;
  height: 100%;
  background-color: $background;
}
.blue-message {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.await-text {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  padding: 0 30px;
  @media (max-width: $size_767) {
    padding: initial;
  }
}
.btn-active {
  font-size: 20px !important;
}
</style>
