<template>
  <div>
    <div
      v-if="store.isVKLFirstTranche && !isDocsVKLSigned"
      class="white-block mb-6"
    >
      <div class="potok-text-h2">
        <span> подпишите документы транша</span>
      </div>
      <div class="form__menu">
        <template v-for="item in actionsFirstTranshItems" :key="item.id">
          <div
            class="form__menu-item d-flex justify-content-between align-items-center"
            @click="onClickAction(item.name)"
          >
            <span>{{ item.title }}</span>
            <span
              class="text-end"
              :class="detectTextColorVKL(item.name)"
              v-html="whichTextShownVKL(item.name)"
            ></span>
            <i v-if="isShowIconVKL(item.name)" class="correct"></i>
          </div>
        </template>
      </div>
    </div>

    <div class="white-block mb-6">
      <div v-if="store.isVKLFirstTranche" class="potok-text-h2">
        еще немного действий, так как это ваш первый транш
      </div>
      <div class="list-conditions">
        <div
          v-for="(action, index) in actionsItems"
          :key="action.id"
          class="list-conditions-row"
        >
          <div class="list-conditions-content">
            <div class="list-conditions-name potok-text-body-1">
              {{ action.title }}
            </div>
            <PotokButton
              v-if="isActionEnabledVKL(action.name)"
              theme="linkIconUpload"
              size="medium"
              @click="onClickAction(action.name)"
              ><template #default>
                <span
                  class="text-end"
                  :class="detectTextColorVKL(action.name)"
                  v-html="whichTextShownVKL(action.name)"
                ></span> </template
            ></PotokButton>
            <span
              v-if="!isActionEnabledVKL(action.name)"
              class="text-grey"
              v-html="whichTextShownVKL(action.name)"
            ></span>
            <i v-if="isShowIconVKL(action.name)" class="correct"></i>
          </div>
          <div v-if="index < actionsItems.length - 1" class="divider"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { computed } from 'vue';
import { loanCardVKLUtils } from '@/components/auth/loan-card/vkl-flow/utils/loanCardVKLUtils.js';
import { useStateStore } from '@/stores/stateStore';
import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';

const store = useStateStore();
const emit = defineEmits(['run-action']);
const {
  detectTextColorVKL,
  whichTextShownVKL,
  isShowIconVKL,
  isActionEnabledVKL,
} = loanCardVKLUtils();
const { isDocsVKLSigned } = loanCardShared();

const actionsFirstTranshItems = [
  {
    id: 1,
    title: 'подписать инвестпредложение',
    name: 'signing_vkl',
  },
];
const actionsItems = computed(() => {
  let result = [];
  if (store.isVKLFirstTranche) {
    result = [
      {
        id: 1,
        title: 'перевести 1 рубль',
        name: 'rules',
      },
      {
        id: 2,
        title: 'приложить фотографии паспорта',
        name: 'passport',
      },
      {
        id: 3,
        title: 'заполните паспортные данные',
        name: 'passport-manual-fill',
      },
      {
        id: 4,
        title: 'заполнить анкету поручителя и приложить сканы',
        name: 'guarantee',
      },
      {
        id: 5,
        title: 'подписать документы у поручителей',
        name: 'founder_signing',
      },
      {
        id: 6,
        title: 'приложить дополнительные документы',
        name: 'documents',
      },
    ];
  }
  if (!store.isVKLFirstTranche) {
    result = [
      {
        id: 2,
        title: 'загрузить дополнительные документы',
        name: 'documents',
      },
      {
        id: 3,
        title: 'добавить поручителя',
        name: 'guarantee',
      },
      {
        id: 4,
        title: 'подписать документы поручительства',
        name: 'founder_signing',
      },
    ];

    if (store.currentOrder.need_to_upload_statement_file !== null) {
      result.unshift({
        id: 1,
        title: 'загрузить выписку',
        name: 'upload_statement',
      });
    }
  }

  return result;
});

const onClickAction = (actionName) => {
  emit('run-action', { action: actionName });
};
</script>

<style lang="scss" scoped>
.list-conditions {
  padding-top: 20px;
}
.list-conditions-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px 0;
}
.divider {
  margin-top: 15px;
}
</style>
