import ContinueRegistrations from '@/pages/ContinueRegistrationsPage.vue';
import PhoneConfirmation from '@/pages/PhoneConfirmationPage.vue';

export default [
  {
    path: '/continue_registrations',
    name: 'continueRegistrations',
    component: ContinueRegistrations,
  },
  {
    path: '/phone_confirmation_ml',
    name: 'phoneConfirmationMl',
    component: PhoneConfirmation,
  },
];
