<template>
  <div class="container-page pt-7 mb-7">
    <div class="form m-auto">
      <div v-show="files.length === 0 && !errorAndLoading">
        <h1 class="page-title text-center">подключите ваш банк</h1>
        <div class="potok-text-body-1 mb-sm-6 mb-5 text-center lh-base">
          Авторизуйтесь в личном кабинете банка и дайте согласие на доступ к
          вашему счету. Выписка загрузится автоматически. Платформа получит
          только права на чтение данных.
        </div>
        <div v-if="banks.length > 0">
          <div v-for="(item, index) in banks" :key="index">
            <PotokButton
              class="mt-2 w-100"
              size="large"
              theme="tertiary"
              :disabled="loading"
              @click="onClickGoBank(item)"
              ><template #default>
                <i :class="getBank(item).icon"></i>
                <div>{{ getBank(item).title }}</div></template
              ></PotokButton
            >
          </div>

          <div class="potok-text-body-1 text-grey text-center mt-4 mb-3">
            или загрузите выписку
          </div>
        </div>
        <div
          v-show="files.length === 0"
          class="form-row-before mb-4 d-sm-flex d-block"
        >
          <div
            v-show="$refs.upload && $refs.upload.dropActive"
            class="drop-active"
          >
            <h3>Перетащите файлы для загрузки</h3>
          </div>
          <FileUpload
            ref="upload"
            v-model="files"
            :post-action="postStatementFilesAsync()"
            :data="{
              kind: 'statement_file',
              attachable_id: store.currentOrderId || store.companyOrderId,
              attachable_type: 'Order',
            }"
            name="file"
            class="upload-wrapper"
            :multiple="true"
            :maximum="101"
            :thread="1"
            :drop="true"
            :drop-directory="true"
            extensions="txt"
            accept="text/plain"
            @input-file="inputFile"
            @input-filter="inputFilter"
          />
          <div>
            <ul class="list-group mb-5 mb-sm-7 mt-2 mt-sm-6">
              <li class="list-group-item">формат экспорта .txt</li>
              <li class="list-group-item">по всем расчетным счетам</li>
              <li class="list-group-item">
                за 24 месяца или за срок ведения деятельности
              </li>
              <li class="list-group-item">по сегодняшнюю дату</li>
            </ul>
            <a href="#" class="ms-4" @click="showModal"
              >где получить выписку?</a
            >
          </div>
        </div>
        <label class="confirm-btn text-center mb-4 cursor-pointer" for="file"
          >загрузить выписки</label
        >
      </div>
      <Succeeded
        v-if="files.length !== 0 && !switchTo"
        :files="files"
        :is-contains-succeeded="isContainsSucceeded"
        :is-contains-errors="isContainsErrors"
        :switch-to="switchTo"
        :loading="loading"
        title="загрузите банковские выписки"
        description="загрузите выписки по всем расчетным счетам компании, чтобы рассчитать показатели бизнеса"
        btn-text="отправить"
        btn-width="28.7%"
        btn-padding="22px 0px"
        btn-margin="12px"
        @remove-element-async="removeElementAsync"
        @post-begin-scoring-async="postBeginScoringAsync"
        @emit-file-info="emitFileInfo"
        @switch-to-component="switchToComponent"
      />
      <Succeeded
        v-if="
          files.length !== 0 &&
          errorAndLoading &&
          isContainsSucceeded &&
          switchTo
        "
        :files="files"
        :is-contains-succeeded="isContainsSucceeded"
        :is-contains-errors="isContainsErrors"
        :switch-to="switchTo"
        :loading="loading"
        title="часть выписок успешно загружена, но"
        description="некоторые выписки содержат ошибки, мы не можем использовать их для анализа финансов"
        btn-text="далее без исправления"
        btn-width="54%"
        btn-padding="22px 0px"
        btn-margin="12px"
        @remove-element-async="removeElementAsync"
        @post-begin-scoring-async="postBeginScoringAsync"
        @emit-file-info="emitFileInfo"
      />
      <unSucceeded
        v-if="
          files.length !== 0 &&
          errorAndLoading &&
          !isContainsSucceeded &&
          switchTo
        "
        :files="arrStateError"
        @emit-file-info="emitFileInfo"
        @clear-errors-arr="(arrStateError = []), (files = [])"
      />

      <h6 class="row-link fw-normal text-center" @click="redirectToUserCabinet">
        загрузить позже
      </h6>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed, watch, inject } from 'vue';

import { PotokButton } from 'potok-uikit';
import FileUpload from 'vue-upload-component';
import Succeeded from './Succeeded.vue';
import unSucceeded from './unSucceeded.vue';
import server from '@/server/index';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import config from '@/../config';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Vipiska_show_screen_main,
  step3,
  SPA_LK_Vipiska_problems_click_where,
  SPA_LK_Vipiska_click_delete,
  SPA_LK_Vipiska_download_success,
  step_3_statement,
  SPA_LK_Later,
} = TRACKER_EVENTS;

/**
 * Есть четыре состояния:
 * 1. Загрузка выписки.
 * 2. Успешно загруженная выписка.
 * 3. Выписка загружена неуспешно, но есть успешно загруженные выписки.
 * 4. Все выписки загружены неуспешно.
 */

const store = useStateStore();
const emit = defineEmits(['show-modal', 'emit-file-info']);

const loading = ref(false);
const files = ref([]);
const upload = ref();
const banks = ref([]);

// arrStateError, isContainsErrors и isContainsSucceeded используется в состоянии 3 и 4
const isContainsErrors = ref(false);
const isContainsSucceeded = ref(false);
const arrStateError = ref([]);
const switchTo = ref(false);
const orderState = ref('');
const emitter = inject('emitter');

const switchToComponent = (value) => {
  switchTo.value = value;
};

// есть ошибки в загруженных выписках и файл загружен
const errorAndLoading = computed(() => {
  return (
    isContainsErrors.value &&
    !upload.value?.active &&
    arrStateError.value.length !== 0
  );
});

/* смотрим на изменения массива files и когда выписки успешно загружены, ищем в нём первую попавшиюся выписку с ошибками (foundErrors) и без ошибок (foundSucceeded)
присваиваем переменным isContainsErrors и isContainsSucceeded значения полей response.valid найденных выписок
фильтруем массив files на наличие поля response.valid === false и заносим в новыйЦ массив arrStateError */
watch(
  () => files.value,
  function () {
    if (!upload.value?.active) {
      const foundErrors = files.value.find(
        (item) =>
          item.response?.valid === false ||
          item.response?.errors?.[0] ||
          item.response?.error?.messages?.[0],
      );
      const foundSucceeded = files.value.find((item) => item.response?.valid);
      isContainsErrors.value = Boolean(foundErrors);
      isContainsSucceeded.value = Boolean(foundSucceeded);
      switchTo.value = false;
      filterArray(files.value);
    }
  },
);
// фильтруем массив files и копируем ошибочные выписки в массив arrStateError. Если файлов больше 100, выводим ошибку для последнего загруженного файла.
const filterArray = (arr) => {
  const rawArr = arr.filter((file) => !file.response?.valid);
  if (rawArr.length > 100) {
    rawArr[100].response.errors[0] =
      'максимальное количество одновременно загружаемых файлов - 100 штук';
    rawArr[100].response.suggestions[0] = '';
  }
  arrStateError.value = rawArr;
};
// автозагрузка файлов при добавлении
const inputFile = (newFile, oldFile) => {
  if (
    Boolean(newFile) !== Boolean(oldFile) ||
    oldFile.error !== newFile.error
  ) {
    if (upload.value && upload.value?.active === false) {
      upload.value.active = true;
    }
  }
};
// передаём куки в запросе на загрузку выписки
const inputFilter = (newFile, oldFile) => {
  if (newFile && oldFile && newFile.xhr && !newFile.xhr.withCredentials) {
    newFile.xhr.withCredentials = true;
  }
};
const redirectToUserCabinet = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Later);
  emitter.emit('to-dashboard');
};
// POST запрос на загрузку выписок
const postStatementFilesAsync = () => {
  return config.VITE_BASE_URL + '/spa/business/attachments/';
};
const emitFileInfo = (name, error, suggestion) => {
  emit('show-modal', 'isError');
  emit('emit-file-info', name, error, suggestion);
};
const showModal = () => {
  emit('show-modal', 'isSuggestion');
  tracker.queue(tracker.commands.SEND, SPA_LK_Vipiska_problems_click_where);
};
const removeElementAsync = (index, id) => {
  files.value.splice(index, 1);
  loading.value = true;
  const query = {
    kind: 'statement_file',
    attachable_id: store.currentOrderId || store.companyOrderId,
    attachable_type: 'Order',
  };
  return server.deleteAttachment$id
    .send(query, {
      params: {
        id: id,
      },
    })
    .pipe(removeElementAsyncSuccess, removeElementAsyncError)
    .exec();
};
const removeElementAsyncSuccess = () => {
  loading.value = false;
  tracker.queue(tracker.commands.SEND, SPA_LK_Vipiska_click_delete);
};
const removeElementAsyncError = () => {
  loading.value = false;
};
const getUserDataAsync = () => {
  loading.value = true;
  return server.getUserData
    .send()
    .pipe(getUserDataAsyncSuccess, getUserDataAsyncError)
    .exec();
};
const getUserDataAsyncSuccess = ({ data }) => {
  loading.value = false;
  store.setCurrentUser(data);
  store.loginSuccess();
  getOrdersAsync();
};
const getUserDataAsyncError = () => {
  loading.value = false;
};
const getOrdersAsync = () => {
  return server.getOrders$order_id
    .send(
      {},
      {
        params: {
          order_id: store.currentOrderId || store.companyOrderId,
        },
      },
    )
    .pipe(onGetOrdersAsyncSuccess, onGetOrdersAsyncError)
    .exec();
};
const onGetOrdersAsyncSuccess = ({ data }) => {
  orderState.value = data.state;
  getStatementsAsync();
};
const onGetOrdersAsyncError = () => {
  loading.value = false;
};
const getStatementsAsync = () => {
  loading.value = true;
  const query = {
    kind: 'statement_file',
    attachable_id: store.currentOrderId || store.companyOrderId,
    attachable_type: 'Order',
  };
  return server.getUploadAttachments
    .send(query, {})
    .pipe(getStatementsAsyncSuccess, getStatementsAsyncError)
    .exec();
};
const getStatementsAsyncSuccess = ({ data }) => {
  data.length !== 0
    ? (isContainsSucceeded.value = true)
    : (isContainsSucceeded.value = false);
  loading.value = false;
  store.setStatements(data);
  checkUploadedStatements();
};
const getStatementsAsyncError = () => {
  loading.value = false;
};
const checkUploadedStatements = () => {
  store.getStatements();
  store.statements.map(
    (el, index) => (files.value[index] = { response: [el][0] }),
  );
};
const postBeginScoringAsync = () => {
  loading.value = true;
  return server.postBeginScoring$order_id
    .send(
      {},
      {
        params: {
          order_id: store.currentOrderId || store.companyOrderId,
        },
      },
    )
    .pipe(postBeginScoringAsyncSuccess, postBeginScoringAsyncError)
    .exec();
};
const postBeginScoringAsyncSuccess = () => {
  loading.value = false;
  tracker.queue(tracker.commands.SEND, SPA_LK_Vipiska_download_success);
  tracker.queue(tracker.commands.SEND, step_3_statement);
  emitter.emit('statement-upload-success');
};
const postBeginScoringAsyncError = () => {
  loading.value = false;
};

const getEverypayBanks = () => {
  loading.value = true;
  return server.getEverypayBanks
    .send({}, {})
    .pipe(
      (obj) => {
        loading.value = false;
        const data = obj.data || [];
        banks.value = data;
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

const onClickGoBank = (item) => {
  loading.value = true;
  return server.postEverypayAuthorizations
    .send({ bank_type: item.bank_type }, {})
    .pipe(
      (obj) => {
        loading.value = false;
        const data = obj.data || [];
        if (data.everypay_authorization_redirect_uri) {
          window.open(
            data.everypay_authorization_redirect_uri,
            '_self',
            '',
            '',
          );
        }
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

const getBank = (item) => {
  const banks = {
    alfa: {
      icon: 'icon-alfa',
      title: 'подключиться через Альфабанк',
    },
    sber: {
      icon: 'icon-sber',
      title: 'подключиться через Сбербанк',
    },
    tochka: {
      icon: 'icon-tochka',
      title: 'подключиться через банк Точка',
    },
    modul: {
      icon: 'icon-module',
      title: 'подключиться через Модульбанк',
    },
    tinkoff: {
      icon: 'icon-tbank',
      title: 'подключиться через Т-банк',
    },
  };
  return banks[item.bank_type];
};

onMounted(() => {
  getUserDataAsync();
  getEverypayBanks();
  tracker.queue(tracker.commands.SEND, SPA_LK_Vipiska_show_screen_main);
  tracker.queue(tracker.commands.SEND, step3);
});
</script>

<style lang="scss" scoped>
.icon-alfa {
  width: 28px;
  height: 28px;
  background-image: url(@/assets/icons/banks/alfa.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}
.icon-sber {
  width: 28px;
  height: 28px;
  background-image: url(@/assets/icons/banks/sber.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}
.icon-module {
  width: 28px;
  height: 28px;
  background-image: url(@/assets/icons/banks/module.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}
.icon-tbank {
  width: 28px;
  height: 28px;
  background-image: url(@/assets/icons/banks/tbank.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}
.icon-tochka {
  width: 28px;
  height: 28px;
  background-image: url(@/assets/icons/banks/tochka.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
}

.bank-btn {
  width: 100%;
  color: #173b57;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid #173b57;
  width: 100%;
  padding: 18px 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-page {
  .form {
    width: 470px;
    @media (max-width: $size_575) {
      width: 288px;
    }
    h1 {
      font-size: 30px;
      margin-bottom: 12px;
      @media (max-width: $size_575) {
        font-size: 20px;
        line-height: 26px;
      }
    }
    h6 {
      @media (max-width: $size_575) {
        font-size: 14px;
        line-height: 22px;
      }
    }
    &-row-before {
      height: 245px;
      background: $background;
      box-shadow: 0px 4px 25px rgba(211, 217, 247, 0.2);
      border-radius: 20px;
      .upload-wrapper {
        flex-shrink: 0;
        width: 180px;
        height: 204px;
        border-radius: 20px;
        margin: 20px 10px 20px 20px;
        background: white;
        background-image: url(@/assets/icons/files/file-uploads.svg);
        background-repeat: no-repeat;
        background-position: center;
        @media (max-width: $size_575) {
          width: 248px;
          height: 143px;
          margin-bottom: 0;
        }
        &:hover {
          border: 1px dashed $primary;
        }
      }
      ul {
        color: $grey;
        font-size: 14px;
        li:not(:last-child) {
          margin-bottom: 8px;
        }
      }
      @media (max-width: $size_575) {
        height: 357px;
      }
      a {
        color: $tiffany;
        &:hover {
          opacity: 0.5;
        }
      }
    }
    .row-text-line {
      display: block;
      height: 0;
      width: 100%;
      border: 1px solid $grey-hover;
      background-color: $grey-hover;
      opacity: 0.5;
    }
    .row-text {
      color: $grey-hover;
      opacity: 0.8;
    }
    .confirm-btn {
      @include button-secondary;
    }
    .row-link {
      font-size: 16px;
      line-height: 24px;
      cursor: pointer;
      color: $grey;
      &:hover {
        color: $breakwater;
      }
    }
  }
}
// Drag and drop overlay
.drop-active {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  position: fixed;
  z-index: 9999;
  opacity: 0.6;
  text-align: center;
  background: $dipedive;
}
.drop-active h3 {
  margin: -0.5em 0 0;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: $white;
  padding: 0;
}
</style>
