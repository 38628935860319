import { http, utils, IncomingError } from '@/libs/browser-http';

const { Pipe } = http;
const { parseResponseBody } = utils;

export default [
  Pipe.from(parseResponse, parseResponse),
  Pipe.from(throwErrorWhenItHappened, throwErrorWhenItHappened),
  Pipe.from(appendLogToDevTools, appendLogToDevTools),
];

/**
 * @see https://gist.github.com/odewahn/5a5eeb23279eed6a80d7798fdb47fe91
 * @param {Error|Response} errOrRes
 * @return {Promise.<Array>}
 */
export function parseResponse(errOrRes) {
  return errOrRes.ok
    ? Promise.all([parseResponseBody(errOrRes), errOrRes])
    : Promise.all([IncomingError.factoryAsync(errOrRes), errOrRes]);
}

/**
 * @param {Object|String|Blob|IncomingError} dataOrIncomingError
 * @param {Error|Response} errOrRes
 * @returns {Promise.<Error|*>}
 */
export function throwErrorWhenItHappened(data) {
  if (!data || !Array.isArray(data)) {
    return next(data);
  }
  const [dataOrIncomingError, errOrRes] = data;
  if (errOrRes.status >= 200 && errOrRes.status <= 299) {
    return next({
      data: dataOrIncomingError,
      response: errOrRes,
    });
  }

  if (errOrRes.status >= 400 && errOrRes.status <= 499) {
    const error = dataOrIncomingError.toError();
    return next(error);
  }

  if (errOrRes.status >= 500) {
    const error = dataOrIncomingError.toError();
    return next(error);
  }
}
/**
 * @param {Error|*} payloadOrError
 * @returns {Promise.<Error|*>}
 */
export function appendLogToDevTools(payloadOrError) {
  return next(payloadOrError);
}

/**
 * @param {Object|Error} payloadOrError
 * @returns {Promise}
 */
function next(payloadOrError) {
  return payloadOrError instanceof Error
    ? Promise.reject(payloadOrError)
    : Promise.resolve(payloadOrError);
}
