<template>
  <template v-if="!isPlatformRulesOpen">
    <div class="title fw-bold py-6 px-0">
      <span v-if="props.type === 'guarantor'"
        >чтобы подписать поручительство, каждый поручитель <br />
        должен отправить 1 рубль с личного счета</span
      >

      <span v-else
        >скачайте шаблон согласия на проверку кредитной истории поручителя,
        каждый поручитель должен отправить 1 рубль с личного счета</span
      >
    </div>
    <div
      v-for="person in props.guarantors"
      :key="person.id"
      class="form p-6 d-flex justify-content-between align-items-center"
    >
      <span class="form__name">{{ person.full_name }}</span>
      <div class="d-flex">
        <div
          v-if="props.type === 'guarantor' && person.confirmed_by_rouble_at"
          class="d-flex align-items-center"
        >
          <i class="correct me-2"></i
          ><span class="form__status">подписано</span>
        </div>
        <PotokButton
          class="ms-6"
          size="medium"
          theme="secondary"
          @click="
            onClickGoToGuarantorRequisites(
              person.last_name,
              person.first_name,
              person.patronymic,
              person.id,
            )
          "
          ><template #default
            ><span v-if="props.type === 'guarantor'">показать реквизиты</span
            ><span v-else>отправить реквизиты</span></template
          ></PotokButton
        >
      </div>
    </div>
    <span class="small color-grey">
      * для каждого поручителя сформированы индивидуальные реквизиты для оплаты
    </span>
    <div class="d-flex flex-column-reverse flex-md-row align-items-center py-4">
      <div class="btn-wrapper me-md-4 me-0 mt-4 mt-md-0 text-center">
        <PotokButton
          text="закрыть"
          size="medium"
          @click="onClickBackToSummary()"
        />
      </div>
      <ErrorItem v-if="errors.length !== 0" v-model="errors" />
    </div>
  </template>
  <PlatformRules
    v-if="isPlatformRulesOpen"
    v-model:isPlatformRulesOpen="isPlatformRulesOpen"
    :amount="0"
    :order="props.order"
    :type="props.type"
    ><template #title>
      <span v-if="!isOrderVKL"
        >отправьте эти реквизиты<span v-if="props.type === 'guarantor'">
          и оферту</span
        >
        {{ fullName }} <br />
        по ним нужно перевести 1 рубль с личного счета
      </span>
      <span v-if="isOrderVKL">
        отправьте эти реквизиты {{ fullName }},<br />
        по ним для подтверждения нужно перевести 1 рубль с личного счета
      </span> </template
    ><template #description>реквизиты</template></PlatformRules
  >
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import PlatformRules from '@/components/auth/loan-card/modules/platform-rules/PlatformRules.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import { ref, computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { inclineFirstname, inclineLastname, inclineMiddlename } from 'lvovich';

const store = useStateStore();

const isPlatformRulesOpen = ref(false);
const fullName = ref('');
const errors = ref([]);

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  guarantors: {
    type: Array,
    default() {
      return [];
    },
  },
  type: {
    type: String,
    default: 'guarantor',
  },
});
const emit = defineEmits(['update:modelValue']);

const isOrderVKL = computed(() => {
  return !!props.order.credit_line;
});
const onClickBackToSummary = () => {
  emit('update:modelValue', false);
};
const onClickGoToGuarantorRequisites = (
  last_name,
  first_name,
  patronymic,
  id,
) => {
  fullName.value =
    inclineLastname(last_name || '', 'dative') +
    ' ' +
    inclineFirstname(first_name || '', 'dative') +
    ' ' +
    inclineMiddlename(patronymic || '', 'dative');
  store.currentGuarantor.id = id;
  isPlatformRulesOpen.value = true;
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 30px;
  line-height: 36px;
  @media (max-width: $size_575) {
    font-size: 20px;
    padding: 20px 0px;
  }
}
.form {
  background-color: white;
  border-radius: 14px;
  margin-bottom: 30px;
  .form__name {
    font-size: 20px;
    line-height: 26px;
  }
  .correct {
    display: inline-block;
    width: 18px;
    height: 18px;
    background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
    background-repeat: no-repeat;
    background-position: center;
    filter: $tiffany;
  }
  .form__status {
    line-height: 26px;
    color: $tiffany;
    padding: 10px 0px;
  }
}
.btn-wrapper {
  width: 125px;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
</style>
