<template>
  <h1 class="fw-bold text-center">{{ props.title }}</h1>
  <h6 class="fw-normal mb-sm-6 mb-5 text-center lh-base">
    {{ props.description }}
  </h6>
  <div
    :class="{
      'scroll-wrapper': props.files.length > 2,
    }"
  >
    <template v-for="(file, index) in props.files" :key="index">
      <div
        class="form-row-after-item"
        @mouseover="showTrash = index"
        @mouseleave="showTrash = null"
      >
        <div class="d-flex align-items-center">
          <div
            v-if="
              file?.progress &&
              file?.progress !== '0.00' &&
              file?.progress !== '100.00' &&
              file?.active
            "
            class="icon-wrapper"
          >
            <Loader class="m-4"></Loader>
          </div>
          <i
            v-else
            class="upload-icon"
            :class="{
              'upload-icon-fail':
                file?.response?.errors?.[0] ||
                file?.response?.error?.messages?.[0],
            }"
          ></i>
          <div style="height: 40px; font-size: 14px">
            <span class="ms-4 d-flex align-items-center"
              ><span class="file-name">{{
                file?.name || file?.response?.filename
              }}</span>
              <span v-if="file?.size && !isMobile"
                >- {{ formatSize(file.size) }} -</span
              >
              <span v-if="file?.size && !isMobile"
                >&nbsp;{{ file.progress }} %</span
              >
            </span>
            <i
              v-if="
                (isMobile &&
                  props.title !== 'часть выписок успешно загружена, но') ||
                (showTrash === index &&
                  props.title !== 'часть выписок успешно загружена, но')
              "
              class="trash-icon"
              @click="emit('remove-element-async', index, file.response.id)"
            ></i>
            <span
              v-if="
                file?.response?.errors?.[0] ||
                file?.response?.error?.messages?.[0]
              "
              class="error-hint"
              @click="
                emit(
                  'emit-file-info',
                  file?.name,
                  file?.response?.errors?.[0] ||
                    file?.response?.error?.messages?.[0],
                  file?.response?.suggestions?.[0],
                )
              "
              ><span class="d-sm-inline d-none"
                >здесь есть ошибки, подробнее &#8594;</span
              ><span class="d-sm-none d-inline"
                >смотреть ошибки &#8594;</span
              ></span
            >
          </div>
        </div>
        <span class="row-text-line-after"></span>
      </div>
    </template>
  </div>
  <div class="form-row-after mb-4">
    <div class="upload-wrapper d-block d-sm-flex justify-content-between">
      <div class="d-flex align-items-center mb-2">
        <span class="clip-icon me-2"></span>
        <label for="file">загрузить ещё</label>
      </div>
      <button
        v-if="!isLoading"
        class="confirm-btn"
        @click="postBeginScoringAsync"
      >
        {{ props.btnText }}
      </button>
      <LoadingBtn
        v-if="isLoading"
        :width="isMobile ? '100%' : props.btnWidth"
        :padding="props.btnPadding"
        :margin="props.btnMargin"
      />
    </div>
  </div>
</template>

<script setup>
import { Loader } from 'potok-uikit';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { ref, onMounted, computed } from 'vue';
import { formatSize } from '../utils';
import tracker from '@/tracker';
import { detectMobile } from '@/utils';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Vipiska_show_screen_some_problems,
  SPA_LK_Vipiska_some_problems_click_continius,
} = TRACKER_EVENTS;

const { isMobile } = detectMobile();

const emit = defineEmits([
  'remove-element-async',
  'emit-file-info',
  'switch-to-component',
  'post-begin-scoring-async',
]);
const props = defineProps({
  files: {
    type: Array,
    default() {
      return [];
    },
  },
  isContainsSucceeded: {
    type: Boolean,
    default: false,
  },
  isContainsErrors: {
    type: Boolean,
    default: false,
  },
  switchTo: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  description: {
    type: String,
    default: '',
  },
  btnText: {
    type: String,
    default: '',
  },
  btnWidth: {
    type: String,
    default: '',
  },
  btnPadding: {
    type: String,
    default: '',
  },
  btnMargin: {
    type: String,
    default: '',
  },
});

const showTrash = ref(null);

const isLoading = computed(() => {
  return props.files.find((el) => el.active) || props.loading;
});

onMounted(() => {
  props.title === 'часть выписок успешно загружена, но'
    ? tracker.queue(
        tracker.commands.SEND,
        SPA_LK_Vipiska_show_screen_some_problems,
      )
    : null;
});

const postBeginScoringAsync = () => {
  console.log('postBeginScoringAsync');
  if (
    props.title === 'загрузите банковские выписки' &&
    props.isContainsErrors
  ) {
    emit('switch-to-component', true);
  } else {
    emit('post-begin-scoring-async');
    props.btnText === 'далее без исправления'
      ? tracker.queue(
          tracker.commands.SEND,
          SPA_LK_Vipiska_some_problems_click_continius,
        )
      : null;
  }
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 30px;
  margin-bottom: 12px;
  @media (max-width: $size_575) {
    font-size: 20px;
    line-height: 26px;
  }
}
h6 {
  @media (max-width: $size_575) {
    font-size: 14px;
    line-height: 22px;
  }
}
.form-row-after-item {
  height: 90px;
  background: $background;
  border-radius: 20px 20px 0px 0px;
  padding: 20px 20px 20px 20px;
  position: relative;
  .icon-wrapper {
    width: 60px;
    height: 60px;
    background-color: white;
    border-radius: 8px;
  }
  .upload-icon {
    min-width: 60px;
    height: 60px;
    background-image: url(@/assets/icons/files/file-uploads-sm-txt.svg);
    background-repeat: no-repeat;
    background-position: center;
    &-fail {
      background-image: url(@/assets/icons/files/file-uploads-sm-fail.svg);
    }
  }
  .trash-icon {
    position: absolute;
    right: 20px;
    top: 33px;
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/trash-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 130px;
  }
  .error-hint {
    position: absolute;
    left: 100px;
    bottom: 20px;
    color: red;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .row-text-line-after {
    position: absolute;
    bottom: 0;
    left: 5%;
    display: block;
    width: 90%;
    background-color: $grey-hover;
    border: 0.5px solid $grey-hover;
    opacity: 0.2;
  }
}
.form-row-after-item:not(:first-child) {
  border-radius: 0px 0px 0px 0px;
  box-shadow: none;
}
.form-row-after {
  height: 90px;
  background: $background;
  border-radius: 0px 0px 20px 20px;
  padding: 20px 20px 20px 20px;
  @media (max-width: $size_575) {
    height: 110px;
    padding: 10px 20px 20px 20px;
  }
  .upload-wrapper {
    .clip-icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-image: url(@/assets/icons/clip.svg);
      background-repeat: no-repeat;
      background-position: center;
    }
    label {
      cursor: pointer;
      color: $primary;
      &:hover {
        color: $link-hover-color;
      }
    }
    .confirm-btn {
      @include button-primary;
      width: 225px;
      padding: 10px 18px;
      @media (max-width: $size_575) {
        width: 100%;
      }
    }
  }
  ul {
    color: $grey;
    font-size: 14px;
    li:not(:last-child) {
      margin-bottom: 8px;
    }
  }
}
// Custom scrollbar for all browsers
.scroll-wrapper {
  height: 180px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: $background;
  margin-top: 20px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: $grey;
  border-radius: 10px;
}
</style>
