<template>
  <div class="white-block graphs-item">
    <div class="block-title">качество чистых кредитовых оборотов</div>
    <template v-if="store.companies.loading">
      <Loader class="mt-4" />
      <div class="graph-empty-block"></div>
    </template>
    <template v-if="!store.companies.loading">
      <template v-if="!props.isStatementLoaded">
        <div class="desc-text">
          {{ MESSAGE_LOAD_REQUIRED }}
        </div>
        <div class="graph-empty-block">
          <PotokButton
            text="загрузить выписку"
            class="button-redirect"
            size="large"
            theme="secondary"
            @click="onClickRedirectLoadStatement()"
          />
        </div>
      </template>
      <template v-if="props.isStatementLoaded">
        <template v-if="!props.isCalculationFinished">
          <div class="desc-text">
            {{ MESSAGE_WAIT_CALCULATION }}
          </div>
          <div class="graph-empty-block"></div>
        </template>
        <template v-if="props.isCalculationFinished">
          <div class="graph-wrapper">
            <template v-if="props.items.length > 0">
              <Bar
                v-if="loaded"
                :data="chartData"
                :options="chartOptions"
                class="mt-4"
              />
              <div class="legend-cko-wrapper">
                <div class="legend-trans">
                  <div></div>
                  количество транзакций
                </div>
                <div class="legend-cont">
                  <div></div>
                  количество контрагентов
                </div>
              </div>
            </template>
            <template v-if="props.items.length === 0">
              <div class="graph-wrapper-empty">
                <div class="pt-6">данных для графика нет</div>
              </div>
            </template>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>
<script setup>
import { PotokButton, Loader } from 'potok-uikit';

import { ref, watch, nextTick, onMounted } from 'vue';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'vue-chartjs';

import analyticUtils from './analyticUtils.js';

import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

Chart.register(...registerables);

const {
  MESSAGE_WAIT_CALCULATION,
  MESSAGE_LOAD_REQUIRED,
  getLabels,
  onClickRedirectLoadStatement,
} = analyticUtils();

const props = defineProps({
  items: {
    type: Array,
    default: () => {
      return [];
    },
  },
  isStatementLoaded: {
    type: Boolean,
    default: false,
  },
  isCalculationFinished: {
    type: Boolean,
    default: false,
  },
});

const labels = ref([]);
const graphData = ref([]);
const graphCustomersData = ref([]);
const loaded = ref(false);

const calcLabels = () => {
  const data = getLabels(props.items);
  labels.value = data;
};

const calcGraphData = () => {
  const data = props.items.map((item) => {
    let value = 0;
    value += item?.top1_customer?.transactions_count;
    value += item?.top2_customer?.transactions_count;
    value += item?.top3_customer?.transactions_count;
    value += item?.other_customers?.transactions_count;
    return value;
  });
  graphData.value = data;
};

const calcGraphCustomersData = () => {
  const data = props.items.map((item) => {
    return item.customers_count;
  });
  graphCustomersData.value = data;
};

const chartOptions = {
  responsive: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (value) => {
          if (!value[0]) {
            return '';
          }
          let title = value[0].label;
          if (title) {
            title = title.replace(',', ' ');
          }
          return title;
        },
      },
    },
  },
  elements: {
    point: {
      pointStyle: 'circle',
      radius: 0,
      borderWidth: 2,
    },
    line: {
      borderWidth: 2,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        color: '#818E99',
        font: {
          size: 8,
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0,
        color: '#818E99',
        font: {
          size: 8,
        },
      },
    },
  },
};

const chartData = {
  labels: labels.value,
  datasets: [
    {
      label: 'количество транзакций',
      data: graphData.value,
      borderColor: '#173B57',
      backgroundColor: '#173B57',
      borderRadius: 4,
      order: 1,
    },
    {
      label: 'количество контрагентов',
      data: graphCustomersData.value,
      borderColor: '#FFC11E',
      backgroundColor: '#FFC11E',
      fill: false,
      type: 'line',
      order: 0,
    },
  ],
};

const updateGraph = () => {
  calcLabels();
  calcGraphData();
  calcGraphCustomersData();
  loaded.value = false;
  nextTick(() => {
    chartData.labels = labels.value;
    chartData.datasets[0].data = graphData.value;
    chartData.datasets[1].data = graphCustomersData.value;
    loaded.value = true;
  });
};
watch(
  () => {
    return props.items;
  },
  () => {
    updateGraph();
  },
);
onMounted(() => {
  updateGraph();
});
</script>
<style lang="scss" scoped>
.graphs-item {
  margin-right: 30px;
  width: 100%;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: $size_767) {
    margin-right: initial;
  }
}
.graph-wrapper {
  min-height: 248px;
}
.graph-wrapper-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.legend-cko-wrapper {
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $grey;
  margin-top: 20px;

  .legend-trans {
    display: flex;
    align-items: center;
    margin-right: 20px;
    div {
      width: 10px;
      height: 10px;
      background-color: $dipedive;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
  .legend-cont {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      background-color: #ffc11e;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
}
</style>
