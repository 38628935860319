<template>
  <div></div>
</template>
<script setup>
import { useRoute, useRouter } from 'vue-router';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';
/**
 * Задача компонента обрабатывать определенные марштуры указаные в routers
 * если route содержит переменные
 * - company_id проверить явялется то компания текущей и если нет сделать ее такой
 * - order_id назначить текущей компании этот order_id
 * далее перейти по заранее определенному маршруту
 */

const store = useStateStore();
const router = useRouter();
const route = useRoute();

const putCompaniesAsync = (callback) => {
  store.companies.loading = true;
  return server.putCompanies$company_id
    .send(
      {},
      {
        params: {
          company_id: route.params.company_id,
        },
      },
    )
    .pipe(() => {
      store.companies.loading = false;
      store.currentCompany.current = true;

      if (callback) {
        callback();
      }
    }, onPutCompanyProfilesError)
    .exec();
};

const onPutCompanyProfilesError = () => {
  store.companies.loading = false;
};

const getCompaniesAsync = (callback) => {
  const options = {
    expand: ['funds', 'limits'],
    onSuccess: () => getCompaniesAffiliatesAsync(callback),
  };
  store.fetchCompanies(options);
};

const getCompaniesAffiliatesAsync = (callback) => {
  store.companies.loading = true;
  return server.getCompaniesAffiliates
    .send()
    .pipe(
      ({ data }) => {
        data.forEach((el) => store.companies.items.push(el));
        store.companies.loading = false;
        const newCurrentCompany = store.companies.items.find((i) => {
          return i.id === parseInt(route.params.company_id);
        });
        if (newCurrentCompany) {
          putCompaniesAsync(callback);
        } else {
          console.error(
            'не найдена компания с указаным id',
            route.params.company_id,
          );
          if (callback) {
            callback();
          }
        }
      },
      () => {
        store.companies.loading = false;
      },
    )
    .exec();
};

const redirectToRoute = () => {
  if (route.name === 'redirectRouteCompanyProfile') {
    router.push({
      name: 'companyProfile',
    });
  }

  if (route.name === 'redirectRouteUserPassports') {
    router.push({
      name: 'userPassports',
    });
  }

  if (route.name === 'redirectRouteLoanCollect') {
    router.push({
      name: 'orderCard',
      params: {
        order_id: route.params.order_id,
      },
    });
  }

  if (route.name === 'redirectRouteDashboard') {
    router.push({
      name: 'dashboard',
    });
  }

  if (route.name === 'redirectRouteBankStatement') {
    router.push({
      name: 'bankStatement',
    });
  }
};
if (route.params?.company_id) {
  if (store.currentCompany.id !== route.params?.company_id) {
    getCompaniesAsync(() => {
      if (!route.params?.order_id) {
        redirectToRoute();
      } else {
        store.currentCompany.order_id = route.params.order_id;
        redirectToRoute();
      }
    });
  }
}
</script>
