<template>
  <div class="w-100 m-auto">
    <h1 class="text-title text-center">предварительная регистрация</h1>
    <div class="text-desc-wrapper">
      <div class="text-desc text-center">
        вы регистрируетесь в качестве партнера платформы. Для регистрации вам
        потребуется телефон, email и инн вашего юридического лица или ИП
      </div>
    </div>
    <div class="form">
      <div class="form-row input-with-icon position-relative">
        <input
          id="phone"
          v-model="v$.login.$model"
          v-maska
          type="tel"
          class="input-phone"
          :class="{
            'is-invalid': v$.login.$invalid && v$.login.$dirty,
          }"
          :disabled="loading"
          :data-maska="masks.phone()"
          placeholder="+7 (999) 999-99-99"
          autofocus
          @focus="clearErrors"
        />
        <div v-if="v$.login.$invalid && isError" class="text-danger mt-2">
          <div>введите корректный телефон</div>
        </div>
        <ErrorItem v-if="errors.length !== 0" v-model="errors" />
      </div>
      <div class="actions-panel">
        <PotokButton
          v-if="!loading"
          id="signIn"
          class="w-100"
          text="продолжить"
          size="large"
          @click="onClickNextStep()"
        />
        <LoadingBtn v-else />

        <div class="form-row mt-4">
          <div class="h6 row-link fw-normal text-center">
            <button
              class="btn-text btn-text-primary"
              :disabled="loading"
              @click="onClickGoLogin"
            >
              вход
            </button>
          </div>
        </div>
      </div>
      <div class="form-row text-condition">
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/2023 02 27_Поток Диджитал_Агентский договор_с изменениями 2.docx.pdf"
          target="_blank"
          >полные условия программы для партнеров</a
        >
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { ref } from 'vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';

import { useRouter } from 'vue-router';

import useVuelidate from '@vuelidate/core';
import { required, or, helpers } from '@vuelidate/validators';

import { vMaska } from 'maska';
import masks from '@/libs/js-text-masks';
import server from '@/server/index';
import { formatNumber } from '@/utils';

const emit = defineEmits(['change-step']);
const router = useRouter();
const isError = ref(false);
const isPhone = helpers.regex(
  /^(\+7[ ]\([0-9]{3}\)[ ])([0-9]{3}-)([0-9]{2}-)[0-9]{2}$/,
);
const validations = {
  login: {
    required,
    valid: or(isPhone),
  },
};
const user = ref({
  login: '',
});
const v$ = useVuelidate(validations, user.value);
const loading = ref(false);
const errors = ref([]);

const onClickNextStep = () => {
  if (v$.value.login.$invalid) {
    isError.value = true;
    return;
  }

  loading.value = true;
  const query = {
    user: {
      phone: isPhone(user.value.login)
        ? formatNumber(user.value.login)
        : user.value.login,
    },
  };

  server.postRegAgent
    .send(query)
    .pipe(onCheckUserSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};

const onCheckUserSuccess = (result) => {
  loading.value = false;
  if (result.data?.isExist) {
    errors.value = [
      'данный номер уже зарегистрирован в системе. перейдите на страницу входа и залогинтесь',
    ];
  } else {
    emit('change-step', {
      step: 2,
      inputedPhone: v$.value.login.$model,
    });
  }
};
const clearErrors = () => {
  isError.value = false;
  errors.value = [];
};
const onClickGoLogin = () => {
  router.push({
    name: 'login',
  });
};
</script>
<style lang="scss" scoped>
.text-desc {
  max-width: 439px;
}

.text-desc-wrapper {
  padding-top: 4px;
}

.input-phone {
  width: 100%;
  height: 60px;
  border: 1px solid transparent;
  padding: 20px 0 20px 20px;
  background-color: #f2f4f8;
  border-radius: 8px;
  outline: none;
  text-overflow: ellipsis;
  box-shadow: none;
  color: #10273d;
  margin-top: 30px;
}
.actions-panel {
  margin-top: 24px;
}
.text-condition {
  margin-top: 165px;
  margin-bottom: 0px;
  a: {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: -0.12px;
    text-decoration-line: underline;
  }
}
</style>
