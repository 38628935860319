<template>
  <div class="w-100 m-auto">
    <h1 class="text-title text-center">введите email</h1>
    <div class="text-desc-wrapper">
      <div class="text-desc text-center">
        понадобится для <br />
        восстановления пароля
      </div>
    </div>
  </div>
  <div class="form">
    <div class="form-row mt-6">
      <input
        id="email"
        v-model="v$.email.$model"
        class="form-control"
        type="email"
        :readonly="loading"
        placeholder="почта"
        @input="onClickPressKeyEmail"
        @keyup.enter="onClickSave()"
        @focus="clearErrors"
      />
      <div v-if="v$.email.$invalid && isError" class="text-danger mt-2">
        <div>введите корректный еmail</div>
      </div>
      <ErrorItem v-if="errors.length !== 0" v-model="errors" />
    </div>

    <div class="form-row input-with-icon position-relative">
      <div class="actions-panel">
        <PotokButton
          v-if="!loading"
          id="createpassword"
          class="w-100"
          text="сохранить"
          size="large"
          @click="onClickSave()"
        />
        <LoadingBtn v-if="loading" />
      </div>

      <div class="text-desc">
        Не забудьте потом проверить почту, мы отправим туда письмо подтверждение
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { ref, reactive } from 'vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';

import useVuelidate from '@vuelidate/core';
import { required, email, or } from '@vuelidate/validators';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import server from '@/server/index';

const emit = defineEmits(['change-step']);

const isError = ref(false);
const loading = ref(null);
const errors = ref([]);

const validations = {
  email: {
    required,
    valid: or(email),
  },
};
const user = reactive({
  email: '',
});
const v$ = useVuelidate(validations, user);

const clearErrors = () => {
  isError.value = false;
};

const onClickPressKeyEmail = () => {};

const onClickSave = () => {
  if (v$.value.email.$invalid) {
    isError.value = true;
    return;
  }

  loading.value = true;
  const query = {
    user: {
      email: user.email,
    },
  };
  return server.patchUserEmail
    .send(query)
    .pipe(onSendEmailAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};

const onSendEmailAsyncSuccess = () => {
  loading.value = false;

  emit('change-step', {
    step: 5,
  });
};
</script>
<style lang="scss" scoped>
.text-desc {
  max-width: 369px;
}
.text-desc-wrapper {
  padding-top: 4px;
}
.actions-panel {
  margin-top: 22px;
  margin-bottom: 25px;
}
.text-desc {
  margin-top: 24px;
  margin-bottom: 30px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: lowercase;
  color: $grey !important;
  text-align: center;
}
</style>
