<template>
  <div class="d-flex align-items-center">
    <div v-if="props.icon" :class="props.icon"></div>
    <div class="title fw-bold ms-3">{{ props.title }}</div>
  </div>
</template>
<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  icon: {
    type: String,
    required: true,
  },
});
</script>
<style lang="scss" scoped>
.title {
  margin-bottom: 5px;
}
</style>
