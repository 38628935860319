<template>
  <div
    class="notify animate-open d-flex justify-content-center align-items-center"
  >
    <slot></slot>
  </div>
</template>

<script setup>
const props = defineProps({
  color: {
    type: String,
    default: '#173b57',
  },
});
</script>

<style lang="scss" scoped>
.notify {
  position: absolute;
  top: 100px;
  right: 30px;
  background: $white;
  padding: 20px;
  border-left: 3px solid v-bind('props.color');
  border-radius: 8px;
  box-shadow: 0px 16px 64px 0px rgba(183, 203, 228, 0.5);
  z-index: 100;
  @media (max-width: $size_575) {
    top: auto;
    bottom: 100px;
  }
}
.animate-open {
  -webkit-animation: moveOpen 4s;
  -webkit-animation-iteration-count: 1;
  -webkit-animation-fill-mode: forwards;
  @media (max-width: $size_575) {
    -webkit-animation: moveOpenMobile 4s;
  }
}
/* Safari and Chrome */
@-webkit-keyframes moveOpen {
  from {
    -webkit-transform: translate(0, -100px);
  }
  10% {
    -webkit-transform: translate(0, 20px);
  }
  12% {
    -webkit-transform: translate(0, 22px);
  }
  16% {
    -webkit-transform: translate(0, 20px);
  }
  80% {
    -webkit-transform: translate(0, 20px);
  }
  85% {
    -webkit-transform: translate(0, 25px);
  }
  to {
    -webkit-transform: translate(0, -100px);
  }
}
@-webkit-keyframes moveOpenMobile {
  from {
    -webkit-transform: translate(200px, 0);
  }
  10% {
    -webkit-transform: translate(20px, 0);
  }
  12% {
    -webkit-transform: translate(15px, 0);
  }
  16% {
    -webkit-transform: translate(20px, 0);
  }
  80% {
    -webkit-transform: translate(20px, 0);
  }
  85% {
    -webkit-transform: translate(15px, 0);
  }
  to {
    -webkit-transform: translate(200px, 0);
  }
}
</style>
