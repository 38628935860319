<template>
  <div class="recover-link">
    <div class="subtitle potok-text-body-1">
      мы отправили ссылку для восстановления пароля на почту {{ props.savedEmail }}
    </div>

    <PotokButton
        text="войти"
        size="large"
        class="mt-6"
        fullWidthDesktop
        @click="onLogin"
    />

    <PotokButton
        theme="link"
        class="mt-3"
        size="link"
        text="назад"
        fullWidthDesktop
        @click="onBack"
    />
  </div>
</template>
<script setup>
import { PotokButton } from "potok-uikit";
import { useRouter } from "vue-router";

const emit = defineEmits([
  'changeStep',
]);

const props = defineProps({
  savedEmail: {
    type: [String, null],
    required: true,
  }
})

const router = useRouter();

const onLogin = () => {
  router.push({ name: 'login' })
};

const onBack = () => {
  emit('changeStep', 'email');
};

</script>

<style lang="scss" scoped>
.subtitle {
  text-align: center;
}
</style>
