export default {
  isParsingEnabled() {
    return this.features.yandex_passport_parsing;
  },
  isNewLoanPageEnabled() {
    return this.features.redirect_to_spa_loan_page;
  },
  isPDPEnabled() {
    if (!hasOwnProperty.call(this.features, 'disable_pdp')) {
      return false;
    }
    return !this.features.disable_pdp;
  },
  isCHDPEnabled() {
    if (!hasOwnProperty.call(this.features, 'disable_chdp')) {
      return false;
    }
    return !this.features.disable_chdp;
  },
  isFeatureCommissionDiscount() {
    return this.features.commission_discount;
  },
  isNewBorrowerBankAccount() {
    return this.features.new_borrower_bank_accounts;
  },
  isCommissionChoiceAllowed() {
    return this.features.commission_choice_allowed;
  },
  isBillingMonthlyCommission() {
    return this.features.billing_monthly_commission;
  },
};
