<template>
  <div class="support-form content_scroll">
    <BackBtnMobile class="d-block d-sm-none mt-4 ms-4" @click="emit('close')" />
    <div class="form-title d-flex justify-content-between align-items-center">
      <span class="fw-bold">обращение</span
      ><i class="icon-close d-none d-sm-block" @click="emit('close')"></i>
    </div>
    <div class="form-body">
      <template v-for="(input, index) in inputs" :key="index">
        <PotokInputSmall
          v-if="
            isShowSection(
              input.varName,
              index !== inputs.findIndex((el) => el.varName === 'description'),
            )
          "
          v-model="data[input.varName]"
          class="mt-4"
          :is-invalid="isShowRequired(input.varName)"
          :placeholder="input.placeholder"
          @input="v$[input.varName].$touch"
        ></PotokInputSmall>
        <PotokTextArea
          v-if="
            isShowSection(
              input.varName,
              index === inputs.findIndex((el) => el.varName === 'description'),
            )
          "
          v-model="data[input.varName]"
          class="mt-4"
          :is-invalid="isShowRequired(input.varName)"
          :placeholder="input.placeholder"
          :keyup="v$[input.varName].$touch"
        ></PotokTextArea>
        <span v-if="isShowRequired(input.varName)" class="text-danger small"
          >обязательное поле</span
        >
      </template>
      <FileUpload
        ref="supportUpload"
        v-model="files"
        name="file"
        input-id="support_file"
        :data="data"
        :post-action="postUserAttachmentsAsync()"
        :headers="{ Authorization: store.session.token }"
        :maximum="1"
        :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
        accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
        @input-file="inputFile"
        @input-filter="inputFilter"
      />
      <div class="upload-btn d-flex align-items-center mb-2">
        <i class="clip-icon me-2"></i>
        <label for="support_file">прикрепить файл</label>
      </div>
      <div class="d-flex align-items-center mb-3">
        <template v-for="(file, index) in files" :key="index">
          <div
            class="uploaded-file me-2"
            :style="changeIconCover(file.url, file.name)"
          >
            <i class="remove-uploaded" @click="onClickRemoveFile(index)"></i>
          </div>
        </template>
      </div>
      <div class="d-flex">
        <PotokButton
          v-if="!loading"
          class="me-2 w-100"
          size="large"
          theme="primary"
          :text="'отправить'"
          @click="onClickSendTicketAsync()"
        />
        <LoadingBtn v-else class="me-2" padding="22px 18px" margin="12px" />
        <PotokButton
          v-if="!loading"
          class="me-2 w-100"
          size="large"
          theme="tertiary"
          :text="'отменить'"
          @click="emit('close')"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton, PotokInputSmall, PotokTextArea } from 'potok-uikit';
import { reactive, ref, computed } from 'vue';
import BackBtnMobile from '@/components/_generic/ui/buttons/BackBtnMobile.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import FileUpload from 'vue-upload-component';
import useVuelidate from '@vuelidate/core';
import { required, email, requiredIf } from '@vuelidate/validators';
import { useStateStore } from '@/stores/stateStore';
import constants from '@/constants';
import config from '@/../config';
import server from '@/server/index';
import { storeToRefs } from 'pinia';

const emit = defineEmits(['close', 'update:modelValue']);
const store = useStateStore();
const { isAuth } = storeToRefs(store);
const inputs = [
  {
    varName: 'name',
    placeholder: 'ваше имя',
  },
  {
    varName: 'email',
    placeholder: 'почта',
  },
  {
    varName: 'title',
    placeholder: 'тема обращения',
  },
  {
    varName: 'inn',
    placeholder: 'инн компании',
  },
  {
    varName: 'companyName',
    placeholder: 'название компании',
  },
  {
    varName: 'ls',
    placeholder: 'номер лицевого счета',
  },
  {
    varName: 'description',
    placeholder: 'опишите ваше проблему',
  },
];

const data = reactive({
  name: '',
  email: '',
  companyName: '',
  inn: '',
  title: '',
  ls: '',
  description: '',
});

const files = ref([]);
const supportUpload = ref();
const loading = ref(false);

const validations = {
  name: {
    required: requiredIf(!isAuth.value),
  },
  email: {
    required: requiredIf(!isAuth.value),
    email,
  },
  companyName: {},
  inn: {},
  title: {
    required,
  },
  ls: {},
  description: {
    required,
  },
};

const v$ = useVuelidate(validations, data);

const calcFormHeight = computed(() => {
  return isAuth.value ? '300px' : '622px';
});

const isShowSection = (type, isTypeDescription) => {
  // если пользователь залогинен, отрисовываем только два поля
  if (isAuth.value) {
    return !!(
      isTypeDescription &&
      (type === 'title' || type === 'description')
    );
  } else {
    return !!isTypeDescription;
  }
};
const isShowRequired = (type) => {
  switch (type) {
    case 'name':
    case 'email':
    case 'title':
    case 'description':
      return v$.value[type].$invalid && v$.value[type].$dirty;
    default:
      return false;
  }
};
// POST запрос на загрузку файлов
const postUserAttachmentsAsync = () => {
  return config.VITE_BASE_URL + '/spa/support_requests';
};
const inputFile = (newFile, oldFile) => {
  // show loader until load
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    if (newFile.xhr) {
      loading.value = false;
      emit('update:modelValue', true);
    }
  }
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    if (newFile?.response?.error?.messages) {
      // errorsArr.value = newFile?.response?.error?.messages;
    }
  }
};
const inputFilter = (newFile, oldFile, prevent) => {
  if (newFile && !oldFile) {
    if (!/\.(gif|jpg|jpeg|png|webp|pdf)$/i.test(newFile.name)) {
      return prevent();
    }
  }
  if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
    newFile.url = '';
    let URL = window.URL || window.webkitURL;
    if (URL && URL.createObjectURL) {
      newFile.url = URL.createObjectURL(newFile.file);
    }
  }
  // передаём куки в запросе
  if (newFile && oldFile && newFile.xhr && !newFile.xhr.withCredentials) {
    newFile.xhr.withCredentials = true;
  }
};
const changeIconCover = (url, filename) => {
  return !filename.includes('.pdf') ? `background-image: url(` + url + `)` : {};
};
const onClickRemoveFile = (index) => {
  files.value.splice(index, 1);
};
const onClickSendTicketAsync = () => {
  v$.value.$touch();
  if (v$.value.$invalid) {
    return;
  }
  loading.value = true;
  if (files.value.length) {
    supportUpload.value.update(files.value.forEach((el) => (el.data = data)));
    supportUpload.value.active = true;
  } else {
    return server.postSupportTicket
      .send(data)
      .pipe(onClickSendTicketAsyncSuccess, onClickSendTicketAsyncError)
      .exec();
  }
};
const onClickSendTicketAsyncSuccess = () => {
  loading.value = false;
  emit('update:modelValue', true);
};
const onClickSendTicketAsyncError = () => {
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.support-form {
  position: absolute;
  bottom: 115px;
  right: 50px;
  width: 390px;
  min-height: v-bind('calcFormHeight');
  border-radius: 8px;
  border: 1px solid rgba(204, 216, 255, 0.2);
  background: $white;
  box-shadow: 0px 4px 53px 0px rgba(183, 203, 228, 0.3);

  @media (max-width: $size_575) {
    width: auto;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  .form-title {
    border-radius: 8px 8px 0 0;
    border-bottom: 1px solid $dividers;
    background: $background;
    padding: 14px;

    @media (max-width: $size_575) {
      background: transparent;
      padding: 20px 0 0 20px;
      border: none;
    }
  }

  .form-body {
    padding: 0px 20px 20px 20px;

    .upload-btn {
      cursor: pointer;

      &:hover .clip-icon {
        filter: $filter-tiffany;
      }

      &:hover label {
        color: $tiffany;
      }

      .clip-icon {
        width: 18px;
        height: 18px;
        background-image: url(@/assets/icons/clip.svg);
        background-repeat: no-repeat;
        background-position: center;
      }

      label {
        cursor: pointer;
      }
    }

    .uploaded-file {
      width: 60px;
      height: 60px;
      border-radius: 8px;
      border: 1px solid $input;
      background-image: url(@/assets/icons/files/file-uploads-sm-void.svg);
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;

      &:hover .remove-uploaded {
        display: block;
      }
    }

    .remove-uploaded {
      display: none;
      position: absolute;
      right: -5px;
      top: -5px;
      width: 16px;
      height: 16px;
      background-image: url(@/assets/icons/exits/close-icon-rounded.svg);
      background-repeat: no-repeat;
      background-position: center;
      cursor: pointer;
    }
  }
}
</style>
