import { encode } from 'querystring';
import { fetchRetry } from './../fetch-retry';
import { useStateStore } from '@/stores/stateStore';

const fetchRetryOptions = {
  retries: 7,
  delays: [3000, 5000, 7000, 9000, 11000, 13000, 15000],
  statuses: [502, 503],
};

/**
 * @param {Object} dst
 * @param {Object} src
 * @returns {Object|Array}
 */
export function extend(dst, ...src) {
  for (let source of src) {
    merge(dst, source);
  }
  return dst;
}
/**
 * Recursively merges objects
 * @param {Object} dst
 * @param {Object} src
 * @returns {Object}
 */
export function merge(dst, src) {
  for (let key in src) {
    // eslint-disable-next-line no-prototype-builtins
    if (src.hasOwnProperty(key)) {
      if (isObject(dst[key]) && getType(dst[key]) === getType(src[key])) {
        merge(dst[key], src[key]);
      } else {
        dst[key] = copy(src[key]);
      }
    }
  }
  return dst;
}
/**
 * @param {*} any
 * @return {String}
 */
export function getType(any) {
  return Object.prototype.toString.call(any);
}
/**
 * @param {*} any
 * @return {Boolean}
 */
export function isObject(any) {
  return any !== null && typeof any === 'object';
}
/**
 * @param {*} any
 * @return {Boolean}
 */
export function isFormData(any) {
  return getType(any) === '[object FormData]';
}
/**
 * @param {*} any
 * @return {Boolean}
 */
export function isFile(any) {
  return getType(any) === '[object File]';
}
/**
 * @param {*} any
 * @returns {Boolean}
 */
export function isPrimitive(any) {
  if (any === null) {
    return true;
  }
  switch (typeof any) {
    case 'boolean':
    case 'number':
    case 'string':
    case 'null':
    case 'undefined':
      return true;
    default:
      return false;
  }
}
/**
 * @param {*} any
 * @returns {Boolean}
 */
export function isString(any) {
  return typeof any === 'string';
}
/**
 * @param {*} any
 * @returns {Boolean}
 */
export function isUndefined(any) {
  return any === undefined;
}
/**
 * @param {String} url
 * @param {Object} options
 * @param {Function} fetchFn
 * @param {Object} retryOptions
 * @returns {Promise}
 */
export function request(
  url,
  options,
  fetchFn,
  retryOptions = fetchRetryOptions,
) {
  const store = useStateStore();

  let finishUrl = url;
  const { method, headers, body, cache, credentials, mode } = options;
  const contentTypeKey = 'Content-Type';
  const contentTypeValue = String(headers[contentTypeKey]);
  const body_ =
    contentTypeKey in headers ? buildBody(contentTypeValue, body) : body;

  const isMultipart =
    contentTypeValue.slice(0, 19) === 'multipart/form-data' ||
    isFormData(body_);
  // We must remove contentType when the request is multipart/form-data
  // Otherwise we will have the "Multipart: Boundary not found" error
  if (isMultipart) {
    delete headers[contentTypeKey];
  }

  if (store.session?.token && !headers.Authorization) {
    headers.Authorization = store.session.token;
  }

  if (window.fetch === fetchFn) {
    if ((method === 'GET' && body) || (method === 'DELETE' && body)) {
      finishUrl += '?' + encode(body);
    }
    return fetchRetry(
      finishUrl,
      {
        cache,
        credentials,
        mode,
        method,
        // https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API/Using_Fetch#Headers
        headers: new window.Headers(headers),
        body:
          method === 'GET' || method === 'HEAD' || method === 'DELETE'
            ? null
            : body_,
      },
      retryOptions,
    );
  }
  if (window.jQuery && window.jQuery.ajax === fetchFn) {
    return fetchFn(finishUrl, {
      cache: cache === true || cache === 'default' || cache === 'force-cache',
      crossDomain: mode !== 'same-origin',
      type: method,
      headers,
      processData: !isMultipart,
      // jQuery must not control contentType for "multipart/form-data" requests
      // Otherwise we will have the "Multipart: Boundary not found" error
      // https://stackoverflow.com/a/8244082
      contentType: isMultipart ? false : contentTypeValue,
      data: body_,
      xhrFields: {
        withCredentials: credentials === 'include',
      },
    });
  }
  throw new Error('You must specify the "fetch" function!');
}
/**
 * @param {String} url
 * @param {Object} [params]
 */
export function formatUrlWithNamedParams(url, params) {
  return params ? url.replace(/\$([^/]+)/g, (match, p1) => params[p1]) : url;
}
/**
 * @param {Object|Error} payloadOrError
 * @returns {Promise}
 */
export function next(payloadOrError) {
  return payloadOrError instanceof Error
    ? Promise.reject(payloadOrError)
    : Promise.resolve(payloadOrError);
}
/**
 * @param {Function} callback
 * @returns {Function}
 */
export function wrapCallback(callback) {
  return function (response) {
    callback(response);
    return next(response);
  };
}
/**
 * WARNING: works only with {Boolean|Number|String|Array|Object|null|undefined} types
 * @see https://github.com/nervgh/yum.js/blob/master/src/yum.js#L143
 * @param any
 * @returns {*}
 */
export function copy(any) {
  // Number, String, Boolean, null, undefined
  if (isPrimitive(any)) {
    return any;
  }
  // We cannot copy FormData or File objects
  if (isFormData(any) || isFile(any)) {
    return any;
  }

  let root = Array.isArray(any) ? [] : {};
  for (let key in any) {
    // eslint-disable-next-line no-prototype-builtins
    if (any.hasOwnProperty(key)) {
      root[key] = copy(any[key]);
    }
  }
  return root;
}

// ---------------------------------
/**
 * @param {String} contentType
 * @param {Object} rawBody
 * @returns {String|FormData|*}
 */
function buildBody(contentType, rawBody) {
  switch (contentType) {
    case 'application/json':
      return JSON.stringify(rawBody);
    case 'multipart/form-data':
      return toFormData(rawBody);
    default:
      throw new TypeError(`Unsupported type "${contentType}"`);
  }
}
/**
 * @param {Object} obj A plainObject
 * @returns {FormData}
 */
function toFormData(obj) {
  const form = new FormData();
  for (let key in obj) {
    const item = obj[key];
    switch (getType(item)) {
      case '[object File]':
        form.append(key, item, item.name); // item.name is filename
        break;
      default:
        form.append(key, item);
        break;
    }
  }
  return form;
}
