<template>
  <div class="container">
    <div class="form w-100 m-auto">
      <h1 class="h3 fw-bold text-center">
        {{ props.title }}
      </h1>
      <h6 class="fw-normal text-center">
        введите код, отправленный на номер
        <p>{{ mask.masked(props.phone || store.currentUser.phone) }}</p>
      </h6>
      <div class="input-wrapper m-auto">
        <div class="form-row text-center d-flex">
          <input
            v-for="(digit, index) in OTP_DIGITS"
            :id="'tel-' + index"
            ref="inputRef"
            :key="index"
            v-model="digitsArr[digit - 1]"
            type="tel"
            maxlength="1"
            autocomplete="off"
            @input="focusOnInput(index)"
            @focus="emit('clear-errors')"
            @keyup.enter="emit('confirm-otp-async', digitsArr.join(''))"
          />
        </div>
        <div v-if="errorsArrRef" class="text-danger mt-2">
          <div v-for="(error, index) in errorsArrRef" :key="index">
            {{ error }}
          </div>
        </div>
        <div class="form-row mb-4 mt-5">
          <PotokButton
            v-if="!props.loading"
            id="continue"
            class="w-100"
            text="продолжить"
            size="large"
            @click="emit('confirm-otp-async', digitsArr.join(''))"
          />
          <LoadingBtn v-else />
        </div>
        <div class="form-row">
          <template v-if="props.leftTime > 0">
            <div class="row-link fw-normal m-auto">
              отправить ещё через <span>{{ props.leftTime }}</span>
              <span>
                секунд{{ pluralize(props.leftTime, ['у', 'ы', '']) }}</span
              >
            </div>
          </template>
          <template v-else>
            <div
              class="row-link fw-normal m-auto"
              @click="
                emit('send-otp-async', props.phone || store.currentUser.phone)
              "
            >
              выслать код повторно
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { ref, onActivated, onMounted, toRef } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { Mask } from 'maska';
import masks from '@/libs/js-text-masks';
import { pluralize } from '@/utils';

const props = defineProps({
  leftTime: {
    type: Number,
    default: 0,
  },
  errorsArr: {
    type: Array,
    default() {
      return [];
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    default: '',
  },
  phone: {
    type: String,
    default: null,
  },
});

const emit = defineEmits([
  'confirm-otp-async',
  'send-otp-async',
  'clear-errors',
]);

const OTP_DIGITS = 4;
const digitsArr = ref([]);
const errorsArrRef = toRef(props, 'errorsArr');
const inputRef = ref(null);

const store = useStateStore();

const mask = new Mask({ mask: masks.phone() });

onMounted(() => {
  if (inputRef.value) {
    inputRef.value[0].focus();
  }
  digitsArr.value = [];
});

onActivated(() => {
  if (inputRef.value) {
    inputRef.value[0].focus();
  }
  digitsArr.value = [];
});

const focusOnInput = (index) => {
  if (event.inputType === 'deleteContentBackward') {
    if (index === 0) {
      return;
    } else {
      event.target.addEventListener('keydown', (e) => {
        if (e.keyCode === 8) {
          if (!digitsArr.value[index]) {
            digitsArr.value[index - 1] = null;
            event.target.previousSibling.focus();
          }
        }
      });
      event.target.previousSibling.focus();
    }
  } else {
    event.target.nextSibling.addEventListener('keydown', (e) => {
      if (e.keyCode === 8) {
        if (!digitsArr.value[index]) {
          digitsArr.value[index - 1] = null;
          e.target.previousSibling.focus();
        }
      }
    });
    index < 3 ? event.target.nextSibling.focus() : null;
  }
};
</script>

<style lang="scss" scoped>
.form {
  max-width: 600px;
  h6 {
    margin: 12px 0 30px 0;
    line-height: 22px;
  }
  .input-wrapper {
    max-width: 340px;
    .form-row {
      input {
        @include input;
        padding: 20px;
        text-align: center;
        font-size: 28px;
      }
      input:not(:last-child) {
        margin-right: 20px;
      }
      .row-link {
        color: $grey;
        width: fit-content;
        cursor: pointer;
        &:hover {
          color: $grey-hover;
        }
      }
    }
  }
}
</style>
