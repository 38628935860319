export const REG_EVENTS = {
  SPA_LK_Registration_show_screen_need_email:
    'SPA_LK_Registration_show_screen: need_email',
  SPA_LK_Registration_show_screen_Agent_Cowboy:
    'SPA_LK_Registration_show_screen: Agent_Cowboy',
  SPA_LK_Registration_INN_show_screen: 'SPA_LK_Registration_INN_show_screen',
  SPA_LK_Registration_show_screen_Are_you:
    'SPA_LK_Registration_show_screen: Are you?',
  SPA_LK_Registration_show_screen_whats_your_name:
    "SPA_LK_Registration_show_screen: what's your name?",
  SPA_LK_Registration_show_screen_Registration:
    'SPA_LK_Registration_show_screen: Registration',
  SPA_LK_Registration_show_screen_Phone_check:
    'SPA_LK_Registration_show_screen: Phone_check',
  SPA_LK_Registration_Phone_check_sms_again:
    'SPA_LK_Registration_Phone_check: sms_again',
  SPA_LK_Registration_Phone_check_success:
    'SPA_LK_Registration_Phone_check: success',
  step_2_contacts: 'step_2_contacts',
  SPA_LK_Registration_need_email_success:
    'SPA_LK_Registration_need_email: success',
  SPA_LK_Registration_show_screen_Create_password:
    'SPA_LK_Registration_show_screen: Create_password',
  SPA_LK_Registration_show_screen_create_password_success:
    'SPA_LK_Registration_show_screen_create_password: success',
  SPA_LK_Registration_email_confirmation_success:
    'SPA_LK_Registration_email_confirmation: success',
};
