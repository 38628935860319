<template>
  <OnClickOutside @trigger="emit('update:modelValue', [])">
    <template v-for="(error, index) in props.modelValue" :key="index">
      <div class="text-danger text-lowercase">{{ error }}</div>
    </template>
  </OnClickOutside>
</template>

<script setup>
import { OnClickOutside } from '@vueuse/components';
const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: { type: Array, required: true },
});
</script>

<style lang="scss" scoped></style>
