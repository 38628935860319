import config from '@/../config';

export default {
  postAuth: {
    url: config.VITE_BASE_URL + `/spa/users/sign_in`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      user: {
        login: null,
        password: null,
      },
    },
  },
  getUserData: {
    url: config.VITE_BASE_URL + `/spa/business/users`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  updateUserCredentials: {
    url: config.VITE_BASE_URL + `/spa/business/users`,
    cache: 'no-cache',
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      user: {
        last_name: null,
        first_name: null,
        patronymic: null,
      },
    },
  },
  updateUserPassword: {
    url: config.VITE_BASE_URL + `/spa/business/users/passwords`,
    cache: 'no-cache',
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      user: {
        password: null,
      },
    },
  },
  postConfirmEmail: {
    url: config.VITE_BASE_URL + `/spa/business/users/email_confirmations`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      confirmation_token: null,
    },
  },
  postUserPhoneRegister: {
    url: config.VITE_BASE_URL + `/spa/users`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      user: {
        phone: null,
      },
    },
  },
  patchUserEmail: {
    url: config.VITE_BASE_URL + `/spa/business/users/emails`,
    cache: 'no-cache',
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      user: {
        email: null,
      },
    },
  },
  userSignOut: {
    url: config.VITE_BASE_URL + `/spa/users/sign_out`,
    cache: 'no-cache',
    method: 'DELETE',
    credentials: 'include',
  },
  getNotices: {
    url: config.VITE_BASE_URL + '/spa/business/notices',
    method: 'GET',
    credentials: 'include',
  },
  postRegAgent: {
    url: config.VITE_BASE_URL + `/spa/agents`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  postRegAgentINN: {
    url: config.VITE_BASE_URL + `/spa/business/agents/inns`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  postRequestRecoverPassword: {
    url: config.VITE_BASE_URL + `/spa/users/passwords`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      user: {
        email: null,
      },
    },
  },
  putChangeRecoverPassword: {
    url: config.VITE_BASE_URL + `/spa/users/passwords`,
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: {
      user: {
        password: null,
        password_confirmation: null,
        reset_password_token: null,
      },
    },
  },
  getHeads: {
    url: config.VITE_BASE_URL + `/spa/business/heads`,
    method: 'GET',
    credentials: 'include',
  },
  patchHeads: {
    url: config.VITE_BASE_URL + `/spa/business/heads/$company_id`,
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      user: {
        reset_password_token: '',
        password: '',
        password_confirmation: '',
      },
    },
  },
};
