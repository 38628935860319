<template>
  <div class="guarantors-block-wrapper">
    <div class="title fw-bold">проверьте и дополните данные поручителей</div>
    <div class="guarantors-wrapper">
      <template v-for="person in props.guarantors" :key="person.id">
        <div class="form-element">
          <div
            v-for="input in inputFields"
            :key="input.id"
            class="input-box"
            @click="onClickHandleForm(input.type, person)"
          >
            <span class="placeholder">{{ input.title }}</span>
            <input
              v-model="person[input.type]"
              v-maska
              type="text"
              :data-maska="input.type === 'phone' ? masks.phone() : ''"
              :disabled="isDisabled(input.type)"
              @change="onChangeSendData(input.type, person)"
            />
            <div
              v-if="input.type === 'series_number' && !person[input.type]"
              class="position-absolute top-50"
            >
              нет данных
            </div>
            <div
              v-if="input.type === 'photo_upload'"
              class="position-absolute top-50"
            >
              <div
                v-if="
                  person.passport.state ===
                  constants.PARSING_STATES.waiting_for_parsing
                "
                class="d-flex align-items-center"
              >
                <Loader class="me-3" /><span class="d-block"
                  >распознаем фото паспорта</span
                >
              </div>
              <div v-else>
                <div v-if="person.passport_uploaded">
                  <span>загружено</span>
                </div>
                <div v-else>
                  <span>нет данных</span>
                </div>
              </div>
            </div>
            <span class="input-btn">сохранить</span>
            <span
              class="input-text"
              :class="detectTextColor(input.type, person)"
              v-text="whichTextShown(input.type, person)"
            ></span>
            <i v-if="isIconShown(input.type, person)" class="input-icon"></i>
          </div>
        </div>
      </template>
    </div>
    <div class="d-flex flex-column-reverse flex-md-row align-items-center">
      <div class="btn-wrapper me-md-4 me-0 mt-4 mt-md-0 text-center">
        <PotokButton
          v-if="!loading"
          text="закрыть"
          size="medium"
          @click="onClickBackToSummary()"
        />
        <LoadingBtn v-if="loading" />
      </div>
      <ErrorItem v-if="errors.length !== 0" v-model="errors" />
    </div>
    <WithParser
      v-if="isParserModalOpen"
      :is-guaranter="true"
      @close-modal="closeModal"
      @get-guarantors-async="emit('get-guarantors-async')"
    />
    <WithoutParser
      v-if="isPassportModalOpen"
      :order="props.order"
      :is-guarantee-signed="props.isGuaranteeAllSignedByRub"
      :is-highlighted="isFormHighLighted"
      @close-modal="closeModal"
      @get-guarantors-async="emit('get-guarantors-async')"
      @update-is-highlighted="isFormHighLighted = $event"
    />
  </div>
</template>

<script setup>
import { PotokButton, Loader } from 'potok-uikit';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import WithoutParser from '@/components/auth/loan-card/modules/guarantee-issue/WithoutParser.vue';
import WithParser from '@/components/auth/loan-card/modules/guarantee-issue/WithParser.vue';
import { ref, computed, watch, onMounted } from 'vue';
import { vMaska } from 'maska';
import masks from '@/libs/js-text-masks';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import { useStateStore } from '@/stores/stateStore';
import { formatNumber } from '@/utils';
import tracker from '@/tracker';
import server from '@/server/index';
import constants from '@/constants';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_application_guarantor_open,
  SPA_LK_VKL_application_guarantor_open,
  SPA_LK_application_guarantor_success,
  SPA_LK_VKL_application_guarantor_success,
} = TRACKER_EVENTS;

const store = useStateStore();
const isPassportModalOpen = ref(false);
const isParserModalOpen = ref(false);
const isFormHighLighted = ref(false);
const errors = ref([]);
const loading = computed({
  get() {
    return props.loading;
  },
  set(value) {
    emit('update:loading', value);
  },
});
let intervalID;

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
  isGuaranteeIssueOpen: {
    type: Boolean,
    default: false,
  },
  isGuaranteeFullFilled: {
    type: Boolean,
    default: false,
  },
  isGuaranteePartiallyFilled: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isGuaranteeAllSignedByRub: {
    type: Boolean,
    default: false,
  },
  guarantors: {
    type: Array,
    default() {
      return [];
    },
  },
});
const emit = defineEmits([
  'update:loading',
  'update:is-guarantee-issue-open',
  'update:isGuaranteeFullFilled',
  'update:isGuaranteePartiallyFilled',
  'get-guarantors-async',
]);

const inputFields = ref([
  {
    id: 1,
    title: 'фио поручителя',
    type: 'full_name',
  },
  { id: 2, title: 'телефон поручителя', type: 'phone' },
  {
    id: 3,
    title: 'фотографии паспорта поручителя',
    type: 'photo_upload',
  },
  {
    id: 4,
    title: 'паспортные данные поручителя',
    type: 'series_number',
  },
]);

const isPhone = helpers.regex(
  /^(\+7[ ]\([0-9]{3}\)[ ])([0-9]{3}-)([0-9]{2}-)[0-9]{2}$/,
);
const validations = {
  phone: {
    required,
    isPhone,
  },
  full_name: {
    required,
  },
};

const startPolling = () => {
  if (!intervalID) {
    intervalID = setInterval(function () {
      emit('get-guarantors-async');
    }, 5000);
  }
};
const stopPolling = () => {
  clearInterval(intervalID);
  intervalID = null;
};

onMounted(() => {
  emit('get-guarantors-async');
  tracker.queue(
    tracker.commands.SEND,
    props.order.credit_line
      ? SPA_LK_VKL_application_guarantor_open
      : SPA_LK_application_guarantor_open,
  );
  if (isParsing.value) {
    startPolling();
  }
});

const checkSuccessStatus = computed(() => {
  return props.guarantors.every(
    (el) => el.success_status === 'success' && el.full_name,
  );
});
const checkPartiallySuccessStatus = computed(() => {
  return props.guarantors.some(
    (el) =>
      el.success_status === 'success' || el.success_status === 'partially',
  );
});

const isParsing = computed(() => {
  return props.guarantors.some(
    (el) => el.passport.state === constants.PARSING_STATES.waiting_for_parsing,
  );
});

watch(
  () => checkSuccessStatus.value,
  () => {
    tracker.queue(
      tracker.commands.SEND,
      props.order.credit_line
        ? SPA_LK_VKL_application_guarantor_success
        : SPA_LK_application_guarantor_success,
    );
    emit('update:isGuaranteeFullFilled', checkSuccessStatus.value);
  },
);
watch(
  () => checkPartiallySuccessStatus.value,
  () => {
    emit(
      'update:isGuaranteePartiallyFilled',
      checkPartiallySuccessStatus.value,
    );
  },
);

watch(
  () => isParsing.value,
  () => {
    isParsing.value ? startPolling() : stopPolling();
  },
);

const closeModal = () => {
  isPassportModalOpen.value = false;
  isParserModalOpen.value = false;
};
const isDisabled = (type) => {
  return !!(
    type === 'series_number' ||
    type === 'photo_upload' ||
    props.isGuaranteeAllSignedByRub
  );
};
const whichTextShown = (type, data) => {
  return type === 'series_number' && data.success_status === 'not upload'
    ? `заполнить \u{2192}`
    : type === 'series_number' &&
        data.success_status === 'partially' &&
        data.passport.state !== constants.PARSING_STATES.parsed_with_success
      ? `продолжить \u{2192}`
      : type === 'series_number' &&
          data.success_status === 'partially' &&
          data.passport.state === constants.PARSING_STATES.parsed_with_success
        ? `проверить \u{2192}`
        : type === 'photo_upload' && !data.passport_uploaded
          ? `загрузить \u{2192}`
          : undefined;
};
const detectTextColor = (type, data) => {
  return type === 'series_number' && data.success_status === 'not upload'
    ? 'text-tiffany'
    : type === 'series_number' &&
        data.success_status === 'partially' &&
        data.passport.state !== constants.PARSING_STATES.parsed_with_success
      ? 'text-primary'
      : type === 'series_number' &&
          data.success_status === 'partially' &&
          data.passport.state === constants.PARSING_STATES.parsed_with_success
        ? 'text-tiffany'
        : type === 'photo_upload' && !data.passport_uploaded
          ? 'text-tiffany'
          : '';
};
const isIconShown = (type, data) => {
  if (type === 'full_name' && data.full_name) {
    return true;
  } else if (type === 'phone' && data.phone) {
    return true;
  } else if (type === 'photo_upload' && data.passport_uploaded) {
    return true;
  } else if (type === 'series_number' && data.success_status === 'success') {
    return true;
  }
  return false;
};
const onClickHandleForm = (type, person) => {
  if (person.passport.state === constants.PARSING_STATES.waiting_for_parsing) {
    return;
  }
  if (type === 'series_number') {
    if (person.success_status === 'success') {
      return;
    }
    if (
      person.passport.state === constants.PARSING_STATES.parsed_with_success ||
      person.passport.state === constants.PARSING_STATES.approved_by_user
    ) {
      isFormHighLighted.value = true;
    }
    store.setCurrentGuarantor(person);
    isPassportModalOpen.value = true;
  }
  if (type === 'photo_upload') {
    if (
      person.passport.state === constants.PARSING_STATES.waiting_for_parsing ||
      person.passport.state === constants.PARSING_STATES.parsed_with_success ||
      person.passport.state === constants.PARSING_STATES.approved_by_user ||
      person.passport_uploaded
    ) {
      return;
    }
    store.setCurrentGuarantor(person);
    isParserModalOpen.value = true;
  }
};
const onClickBackToSummary = () => {
  emit('update:is-guarantee-issue-open', false);
};
const onChangeSendData = (type, data) => {
  const v$ = useVuelidate(validations, {
    phone: data.phone,
    full_name: data.full_name,
  });
  if (type === 'phone' && !v$.value.phone.$invalid) {
    patchPersonGuarantorsAsync(data.id, data.phone, type);
    return;
  }
  if (type === 'full_name' && !v$.value.full_name.$invalid) {
    patchPersonGuarantorsAsync(data.id, data.full_name, type);
    return;
  }
};
const patchPersonGuarantorsAsync = (id, data, type) => {
  let query = {
    guarantor: {},
  };
  switch (type) {
    case 'phone':
      query.guarantor.phone = formatNumber(data);
      break;
    default:
      query.guarantor.full_name = data;
      break;
  }
  loading.value = true;
  return server.patchPersonGuarantors$id
    .send(query, {
      params: {
        id: id,
      },
    })
    .pipe(patchPersonGuarantorsAsyncSuccess, patchPersonGuarantorsAsyncError)
    .exec();
};
const patchPersonGuarantorsAsyncSuccess = () => {
  loading.value = false;
};
const patchPersonGuarantorsAsyncError = (error) => {
  errors.value = error.data?.messages;
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.guarantors-block-wrapper {
  padding-bottom: 50px;
}
.guarantors-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.title {
  padding: 30px 0px;
  font-size: 30px;
  line-height: 36px;
  @media (max-width: $size_575) {
    font-size: 20px;
    padding: 20px 0px;
  }
}
.form-element {
  background-color: white;
  padding: 10px;
  border-radius: 14px;
  min-width: 540px;
  min-height: 324px;
  margin-bottom: 30px;
  &:nth-child(odd) {
    margin-right: 30px;
    @media (max-width: $size_767) {
      margin-right: 0px;
    }
  }
  @media (max-width: $size_767) {
    min-width: 100%;
  }
  .input-box {
    position: relative;
    min-height: 75px;
    padding: 0px 10px;
    cursor: pointer;
    &:hover {
      padding: 0px 20px;
      background-color: $background;
      border-radius: 14px;
    }
    &:hover input {
      background-color: $background;
    }
    &:not(:first-child) {
      border-top: 1px solid rgba($grey, 0.2);
    }
    &:hover:not(:first-child) {
      border-top: 1px solid rgba($grey, 0);
    }
    &:hover + .input-box {
      border-top: 1px solid rgba($grey, 0);
    }
    &:focus-within {
      background-color: transparent;
      cursor: auto;
    }
    input {
      cursor: pointer;
      width: 100%;
      height: 75px;
      padding: 20px 35px 0 0;
      text-overflow: ellipsis;
      outline: none;
      border: none;
      border-radius: 8px;
      color: $dipedive;
      &:focus {
        padding: 10px 16px;
        width: 283px;
        height: 44px;
        margin: 38px 0 16px 0;
        background-color: $background;
        & ~ .input-btn {
          display: inline;
        }
      }
    }
    .input-btn {
      display: none;
      margin-left: 10px;
      padding: 12px 18px;
      color: white;
      background-color: $primary;
      border-radius: 8px;
      cursor: pointer;
      &:hover {
        color: $primary;
        background-color: transparent;
        border: 1px solid $primary;
      }
    }
    .input-text {
      position: absolute;
      right: 20px;
      line-height: 24px;
      top: calc(50% - 12px);
    }
    .input-icon {
      position: absolute;
      right: 20px;
      top: calc(50% - 9px);
      display: inline-block;
      width: 18px;
      height: 18px;
      background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
      background-repeat: no-repeat;
      background-position: center;
      filter: $filter-breakwater;
    }
  }
  .placeholder {
    position: absolute;
    top: 17px;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $grey;
  }
}
.btn-wrapper {
  width: 124px;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
</style>
