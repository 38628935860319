<template>
  <ModalFull v-if="!isSigning" @close-modal="onClickCloseModal">
    <template #title
      ><span>{{
        isDocsSigned
          ? 'ознакомьтесь с документами'
          : 'ознакомьтесь и подпишите документы'
      }}</span></template
    >
    <template #content>
      <div
        class="flex-wrapper d-sm-flex d-block flex-wrap"
        :class="showFullDocs ? 'flex-wrapper-show' : ''"
      >
        <div
          v-if="order.credit_line"
          class="doc-wrapper"
          @click="onClickOpenDoc('credit_line')"
        >
          <span class="small">договор кредитной линии</span><i></i>
        </div>
        <div class="doc-wrapper" @click="onClickOpenDoc('invest')">
          <span class="small">инвестиционное предложение</span><i></i>
        </div>
        <div class="doc-wrapper" @click="onClickOpenDoc('rules')">
          <span class="small">заявление о присоединении к правилам</span><i></i>
        </div>
        <div class="doc-wrapper" @click="onClickOpenDoc('schedule')">
          <span class="small">предварительный график платежей</span><i></i>
        </div>
        <div v-if="!order.credit_line">
          <template v-for="doc in countGuarantorsIds" :key="doc.id">
            <div class="doc-wrapper" @click="onClickOpenDoc('offer', doc.id)">
              <span class="small"
                >оферта поручителя <br />{{ doc.full_name }}</span
              ><i></i>
            </div>
          </template>
        </div>
      </div>
      <div
        class="docs-toggle text-tiffany small"
        @click="showFullDocs = !showFullDocs"
        v-text="showFullDocs ? 'скрыть' : 'показать все документы'"
      ></div>
      <hr class="my-4 color-grey" />
      <Checkbox v-model:isChecked="isChecked" class="mb-4" :font-small="'small'"
        ><template #default
          >подтверждаю, что
          <span v-if="isCompanyUL"
            >генеральный директор, участники (акционеры) и бенефициары заемщика
            не являются </span
          ><span v-if="!isCompanyUL">я не являюсь </span>
          <PopoverIcon>
            <template #icon
              ><span v-if="isCompanyUL" class="text-tiffany"
                >публичными должностными лицами</span
              ><span v-if="!isCompanyUL" class="text-tiffany"
                >публичным должностным лицом</span
              ></template
            >
            <template #text
              >ДЛПМО — должностные лица публичных международных организаций<br />
              ИПДЛ — иностранные публичные должностные лица<br />
              РПДЛ — лица, замещающие (занимающие) государственные должности
              РФ</template
            ></PopoverIcon
          >
          и/или
          <span v-if="isCompanyUL">супругом/супругой таких лиц, </span
          ><span v-if="!isCompanyUL"
            >родственником такого лица, я являюсь бенефициарным владельцем,
          </span>
          <PopoverIcon :position="'bottom_center'">
            <template #icon
              ><span class="text-tiffany">выгодоприобретатели</span></template
            >
            <template #text
              ><span class="text-nowrap"
                >лица, к выгоде которых действует заемщик,<br />
                в том числе на основании агентского договора,<br />
                договоров поручения, комиссии и <br />
                доверительного управления, при проведении операций с<br />
                денежными средствами и иным имуществом</span
              ></template
            ></PopoverIcon
          >
          отсутствуют</template
        ></Checkbox
      >
      <div class="notify d-flex justify-content-center align-items-center">
        в документах указан предварительный график платежей, окончательный
        появится в личном кабинете, вы сможете ознакомиться с ним после
        поступления денег на счет
      </div>
      <hr class="my-6 color-grey" />
      <div class="d-sm-flex d-block align-items-center mb-4">
        <div v-if="!isDocsSigned" class="btn-wrapper me-md-4 me-0">
          <PotokButton
            :disabled="!isChecked"
            class="w-100"
            text="подписать"
            size="large"
            @click="onClickSignDocs()"
          />
        </div>
        <div class="btn-wrapper">
          <PotokButton
            v-if="!isMobile"
            class="w-100"
            text="подписать"
            size="large"
            theme="tertiary"
            @click="emit('close-modal')"
          >
            <template #default>{{
              isDocsSigned ? 'закрыть' : 'назад'
            }}</template>
          </PotokButton>
        </div>
        <ErrorItem
          v-if="errors.length !== 0"
          v-model="errors"
          class="text-center ms-sm-4 ms-0 mt-sm-0 mt-3"
        />
      </div>
      <span v-if="!isDocsSigned" class="disclaimer text-center">
        Нажимая подписать, вы подтверждаете: Отсутствие у руководителя и
        контролирующих лиц неснятой или непогашенной судимости за преступления в
        сфере экономики или против государственной власти, интересов
        государственной службы и службы в органах местного самоуправления.
        Отсутствие фактов, которые могут оказать существенное негативное влияние
        на возврат займа. Деловая репутация заемщика - положительная.
      </span>
    </template>
  </ModalFull>
  <ModalPhoneConfirmation
    v-if="isSigning"
    :left-time="leftTime"
    :loading="loading"
    :errors-arr="errors"
    :agreement-id="agreementId"
    title="для подписи документов"
    @confirm-otp-async="confirmOtpAsync"
    @send-otp-async="sendOtpAsync"
    @clear-errors="clearErrors"
    @close-modal="closePhoneCodeModal"
    @go-back="goBackFromSigning"
  />
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import Checkbox from '@/components/_generic/ui/widgets/Checkbox.vue';
import ModalPhoneConfirmation from '@/components/_generic/ui/modals/ModalPhoneConfirmation.vue';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import { detectMobile } from '@/utils';
import { onMounted, ref, computed, toRefs } from 'vue';
import tracker from '@/tracker';
import { useStateStore } from '@/stores/stateStore';
import { storeToRefs } from 'pinia';
import Countdown from '@/modules/Countdown';
import server, { openInNewTab } from '@/server/index';
import { TRACKER_EVENTS } from '@/tracker/events';
import { documentsUtils } from '@/modules/documentsUtils.js';

const {
  SPA_LK_application_signature_open,
  SPA_LK_VKL_application_signature_open,
  SPA_LK_application_signature_start,
  SPA_LK_VKL_application_signature_start,
  SPA_LK_signature_SMS_success,
  SPA_LK_VKL_signature_SMS_success,
} = TRACKER_EVENTS;

const {
  onClickOpenUnsignedInvestOffer,
  onClickOpenUnsignedCreditLineAgreement,
} = documentsUtils();

const store = useStateStore();
const { isCompanyUL } = storeToRefs(store);
const { isMobile } = detectMobile();

const countdown = new Countdown();
const leftTime = ref(0);

const showFullDocs = ref(false);
const isChecked = ref(true);
const isSigning = ref(false);
const agreementId = ref(null);

const loading = ref(false);
const errors = ref([]);

const emit = defineEmits(['close-modal', 'update:isDocsSigned']);
const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
  isDocsSigned: {
    type: [Boolean, String],
    default: false,
  },
  guarantors: {
    type: Array,
    default() {
      return [];
    },
  },
});
const { isDocsSigned, guarantors, order } = toRefs(props);

onMounted(() => {
  countdown.onTick = (val) => {
    leftTime.value = val;
  };
  tracker.queue(
    tracker.commands.SEND,
    order.value.credit_line
      ? SPA_LK_VKL_application_signature_open
      : SPA_LK_application_signature_open,
  );
});

const countGuarantorsIds = computed(() => {
  const resultArr = guarantors.value.map((el) => ({
    id: el.id,
    full_name: el.full_name,
  }));
  return resultArr;
});

const onClickCloseModal = () => {
  emit('close-modal');
};
const closePhoneCodeModal = () => {
  isSigning.value = false;
  emit('close-modal');
};
const goBackFromSigning = () => {
  isSigning.value = false;
};
const clearErrors = () => {
  errors.value = [];
};
const onClickSignDocs = () => {
  if (leftTime.value === 0) {
    sendOtpAsync();
  }
  isSigning.value = true;
};
const onClickOpenDoc = (type, guarantorId) => {
  switch (type) {
    case 'invest':
      if (order.value.credit_line) {
        return window.open(
          'https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D0%BB%D0%BA%D0%B7/investment_offer_template.pdf',
          '_blank',
        );
      }
      return onClickOpenUnsignedInvestOffer(order.value);
    case 'rules':
      openInNewTab(
        '/documents/unsigned/borrower_accepting_the_rules.pdf?order_id=' +
          order.value.id,
      );
      break;
    case 'schedule':
      openInNewTab('/documents/draft_schedule.pdf?order_id=' + order.value.id);
      break;
    case 'offer':
      openInNewTab(
        '/documents/unsigned/guarantor_offer.pdf?guarantor_id=' +
          guarantorId +
          '&order_id=' +
          order.value.id,
      );
      break;
    case 'credit_line':
      return onClickOpenUnsignedCreditLineAgreement(order.value);
    default:
      break;
  }
};
const sendOtpAsync = () => {
  loading.value = true;
  const query = {
    signature: {
      confirmable_id: parseInt(order.value.id),
      confirmable_type: 'Order',
      kind: 'signing_documents',
    },
  };
  return server.postSignatures
    .send(query)
    .pipe(onSendOtpAsyncSuccess, onSendOtpAsyncError)
    .exec();
};
const onSendOtpAsyncSuccess = ({ data }) => {
  tracker.queue(
    tracker.commands.SEND,
    order.value.credit_line
      ? SPA_LK_VKL_application_signature_start
      : SPA_LK_application_signature_start,
  );
  agreementId.value = data?.id;
  countdown.reset();
  countdown.start();
  loading.value = false;
};
const onSendOtpAsyncError = (error) => {
  loading.value = false;
  error.data.messages ? (errors.value = error.data.messages) : null;
};
const confirmOtpAsync = (rawOtp) => {
  const query = {
    signature: {
      code: rawOtp,
    },
  };
  loading.value = true;
  return server.putSignatures$id
    .send(query, {
      params: {
        id: agreementId.value,
      },
    })
    .pipe(onConfirmOtpAsyncSuccess, onConfirmOtpAsyncError)
    .exec();
};
const onConfirmOtpAsyncSuccess = () => {
  tracker.queue(
    tracker.commands.SEND,
    order.value.credit_line
      ? SPA_LK_VKL_signature_SMS_success
      : SPA_LK_signature_SMS_success,
  );
  loading.value = false;
  isSigning.value = false;
  emit('update:isDocsSigned', true);
  emit('close-modal');
};
const onConfirmOtpAsyncError = (error) => {
  error.data?.messages ? (errors.value = error.data.messages) : null;
  loading.value = false;
};
</script>

<style lang="scss" scoped>
:deep(.modal-wrapper) {
  min-width: 550px;
}
:deep(.modal-container) {
  width: 100%;
}
.flex-wrapper {
  max-height: 90px;
  overflow-y: hidden;
  margin-bottom: 20px;
  &-show {
    max-height: 420px;
    overflow-y: show;
  }
  .doc-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: self-start;
    padding: 20px;
    cursor: pointer;
    min-width: 353px;
    height: 90px;
    border-radius: 8px;
    background-color: $background;
    margin-bottom: 20px;
    &:nth-child(odd) {
      margin-right: 20px;
      @media (max-width: $size_767) {
        margin-right: 0;
      }
    }
    i {
      width: 17px;
      height: 24px;
      background-image: url(@/assets/icons/files/file-icon-sm.svg);
      background-repeat: no-repeat;
      background-size: cover;
      margin-top: 26px;
    }
  }
}
.docs-toggle {
  cursor: pointer;
}
.notify {
  background: $background;
  border-left: 3px solid #173b57;
  border-radius: 8px;
  padding: 20px 18px;
  flex-wrap: wrap;
  @media (max-width: $size_767) {
    width: 100%;
    height: auto;
  }
}
.btn-wrapper {
  width: 142px;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
.disclaimer {
  font-size: 12px;
  color: $grey;
  line-height: 20px;
}
</style>
