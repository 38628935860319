/* eslint-disable */
import AbstractTracker from './AbstractTracker';

const DEFAULT_OPTIONS = {
  triggerEvent: true,
  ut: 'noindex',
};
/*global ym */
export default class YandexMetrikaTracker extends AbstractTracker {
  /**
   * @see https://yandex.ru/support/metrika/code/counter-initialize.html
   * @param {Object} options
   * @param {Boolean} isProduction
   */
  constructor(options, isProduction) {
    const mergedOptions = Object.assign({}, DEFAULT_OPTIONS, options);
    super(mergedOptions, isProduction);
  }
  /**
   * @param {Object} options
   */
  initialize(options) {
    !(function (m, e, t, r, i, k, a) {
      m[i] =
        m[i] ||
        function () {
          (m[i].a = m[i].a || []).push(arguments);
        };
      m[i].l = 1 * new Date();
      (k = e.createElement(t)),
        (a = e.getElementsByTagName(t)[0]),
        (k.async = 1),
        (k.src = r),
        a.parentNode.insertBefore(k, a);
    })(window, document, 'script', 'https://mc.yandex.ru/metrika/tag.js', 'ym');

    ym(options.id, 'init', options);

    this.run = this[this.constructor.RUN];
    this.run();
  }
  /**
   * @param {String} command
   * @param {...*} [opts]
   */
  execCommand([command, ...opts]) {
    const { id } = this.options;
    if (window['ym']) {
      window['ym'](id, command, ...opts);
    }
  }
}
