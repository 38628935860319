import RedirectRoute from '@/components/auth/RedirectRoute.vue';

export default [
  {
    path: '/business/company_profiles/:company_id/:order_id',
    name: 'redirectRouteCompanyProfile',
    component: RedirectRoute,
  },
  {
    path: '/business/users/passports/:company_id/:order_id',
    name: 'redirectRouteUserPassports',
    component: RedirectRoute,
  },
  {
    path: '/business/loans/:company_id/:order_id',
    name: 'redirectRouteLoanCollect',
    component: RedirectRoute,
  },
  {
    path: '/dashboard/:company_id',
    name: 'redirectRouteDashboard',
    component: RedirectRoute,
  },
  {
    path: '/orders/:company_id/:order_id/statement_files',
    name: 'redirectRouteBankStatement',
    component: RedirectRoute,
  },
];
