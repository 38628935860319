<template>
  <div class="container pt-7">
    <div class="form w-100 m-auto">
      <h1 class="h3 fw-bold text-center me-2">создайте пароль</h1>
      <h6 class="fw-normal text-center me-2">
        придумайте пароль
        <p>для входа в платформу</p>
      </h6>
      <div class="input-wrapper m-auto">
        <div class="form-row text-center">
          <div class="d-flex">
            <div class="input-with-icon position-relative">
              <input
                id="password"
                v-model="v$.password.$model"
                :type="fieldType"
                :class="{
                  'is-invalid': v$.password.$invalid && v$.password.$dirty,
                }"
                placeholder="пароль"
              />
              <div
                class="icon"
                :class="[showPass ? 'icon-show' : 'icon-hidden']"
                @click="toggleShow()"
              ></div>
            </div>
            <div
              v-if="v$.password.$dirty"
              class="pass-icon d-sm-block d-none"
              :class="[
                !v$.password.$invalid && v$.password.$dirty
                  ? 'pass-icon-true'
                  : 'pass-icon-false',
              ]"
            ></div>
          </div>
        </div>
        <div
          class="pass-tip fw-normal"
          :class="{
            'pass-tip-error': v$.password.$invalid && v$.password.$dirty,
          }"
        >
          минимум 8 символов, буквы и цифры
        </div>
        <div class="d-flex">
          <div class="form-row text-left">
            <div class="input-with-icon position-relative">
              <input
                id="confirmpassword"
                v-model="v$.confirmPassword.$model"
                :type="fieldType"
                :class="{
                  'is-invalid':
                    v$.confirmPassword.$invalid && v$.confirmPassword.$dirty,
                }"
                :disabled="!v$.password.$model && !v$.confirmPassword.$model"
                placeholder="повторите пароль"
                @keyup.enter="setUserPasswordAsync(passwords.password)"
              />
            </div>
          </div>
          <div
            v-if="v$.confirmPassword.$dirty"
            class="pass-icon d-sm-block d-none"
            :class="[
              !v$.confirmPassword.$invalid && v$.confirmPassword.$dirty
                ? 'pass-icon-true'
                : 'pass-icon-false',
            ]"
          ></div>
        </div>
        <div
          v-if="v$.confirmPassword.$invalid && v$.confirmPassword.$dirty"
          class="pass-icon-wrapper d-sm-none d-flex align-items-center mt-2"
        >
          <div class="pass-icon-mobile"></div>
          <div class="text-danger">пароли не совпадают</div>
        </div>
        <div
          v-if="
            v$.confirmPassword.sameAsPassword.$invalid &&
            v$.confirmPassword.$dirty
          "
          class="text-danger d-none d-sm-block mt-2"
        >
          пароли не совпадают
        </div>
        <div class="button-wrapper mb-4 mt-5">
          <PotokButton
            v-if="!loading"
            id="registration"
            class="w-100"
            :disabled="isDataUnvalid"
            text="зарегистрироваться"
            size="large"
            @click="setUserPasswordAsync(passwords.password)"
          />
          <LoadingBtn v-if="loading" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import SetUserEmail from './SetUserEmail.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required, minLength, sameAs } from '@vuelidate/validators';
import { ref, computed, onMounted, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import server from '@/server/index';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Registration_show_screen_Create_password,
  SPA_LK_Registration_show_screen_create_password_success,
} = TRACKER_EVENTS;

const route = useRoute();
const router = useRouter();
const showPass = ref(false);
const loading = ref(false);
const fieldType = ref('password');
const passwords = reactive({
  password: '',
  confirmPassword: '',
});

const emit = defineEmits(['change-component']);

const passRef = computed(() => passwords.password);

const validations = {
  password: {
    required,
    minLength: minLength(8),
    passwordRequirements: helpers.withMessage(
      () => `Минимум 8 символов, буквы и цифры`,
      (value) => /(?=.*[a-zA-Z])(?=.*[0-9])/.test(value),
    ),
  },
  confirmPassword: {
    required,
    sameAsPassword: sameAs(passRef),
    passwordRequirements: helpers.withMessage(
      () => `Минимум 8 символов, буквы и цифры`,
      (value) => /(?=.*[a-zA-Z])(?=.*[0-9])/.test(value),
    ),
  },
};

const v$ = useVuelidate(validations, passwords);

const isDataUnvalid = computed(() => {
  return v$.value.confirmPassword.$invalid || v$.value.password.$invalid;
});

onMounted(() => {
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Registration_show_screen_Create_password,
  );
});

const toggleShow = () => {
  if (fieldType.value === 'password') {
    fieldType.value = 'text';
    showPass.value = true;
  } else {
    fieldType.value = 'password';
    showPass.value = false;
  }
};
const setUserPasswordAsync = (rawPassword) => {
  loading.value = true;
  const query = {
    user: {
      password: rawPassword,
    },
  };
  return server.updateUserPassword
    .send(query)
    .pipe(onSetUserPasswordAsyncSuccess, onSetUserPasswordAsyncError)
    .exec();
};
const onSetUserPasswordAsyncSuccess = () => {
  loading.value = false;
  if (route.query?.step) {
    return router.push('/login');
  }
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Registration_show_screen_create_password_success,
  );
  emit('change-component', SetUserEmail);
};
const onSetUserPasswordAsyncError = () => {
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.form {
  max-width: 600px;
  h6 {
    margin: 12px 0 30px 0;
    line-height: 22px;
  }
  .input-wrapper {
    max-width: 358px;
    .form-row {
      .input-with-icon {
        width: 340px;
        .icon {
          background-repeat: no-repeat;
          position: absolute;
          right: 20px;
          top: 24.5px;
          width: 16px;
          height: 16px;
          cursor: pointer;
          &:hover {
            filter: $filter-breakwater;
          }
          &-hidden {
            background: url(@/assets/icons/password/toggle_pass.svg);
          }
          &-show {
            background: url(@/assets/icons/password/toggle_pass_no.svg);
          }
        }
      }
      input {
        @include input;
      }
      .is-invalid {
        border: 1px solid rgba($red, 0.2);
      }
    }
    .pass-icon {
      background-repeat: no-repeat;
      position: relative;
      left: 20px;
      top: 20px;
      width: 18px;
      height: 18px;
      &-true {
        background: url(@/assets/icons/rounded/true_icon_pass.svg);
      }
      &-false {
        background: url(@/assets/icons/rounded/false_icon_pass.svg);
      }
    }
    .pass-icon-wrapper {
      .pass-icon-mobile {
        background-repeat: no-repeat;
        width: 18px;
        height: 18px;
        margin-right: 8px;
        background: url(@/assets/icons/rounded/false_icon_pass.svg);
      }
    }
    .pass-tip {
      color: $grey;
      margin: 10px 0 15px 0;
      &-error {
        color: $red;
      }
    }
    .button-wrapper {
      width: 340px;
    }
  }
}
</style>
