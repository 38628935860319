<template>
  <div class="ui-notification-wrapper">
    <div class="ui-notification" :class="{ error: props.isError }">
      <span v-if="props.title" class="ui-notification-title">
        {{ props.title }}
      </span>
      <span class="ui-notification-message">{{ props.message }}</span>
    </div>
  </div>
</template>

<script setup>
import { onMounted } from 'vue';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const props = defineProps({
  timer: {
    type: Number,
    default: 5000,
  },
  title: {
    type: String,
    required: false,
  },
  message: {
    type: String,
    required: true,
  },
  isError: {
    type: Boolean,
    default: false,
  },
});

const closeHandler = () => {
  setTimeout(() => {
    store.setNotification({
      timer: 0,
      message: null,
      title: null,
      isError: false,
    });
  }, props.timer);
};

onMounted(() => {
  closeHandler();
});
</script>

<style scoped lang="scss">
.ui-notification-wrapper {
  position: fixed;
  top: 100px;
  right: 50px;
  z-index: 100;
}

.ui-notification {
  display: flex;
  flex-direction: column;
  max-width: 360px;
  border-radius: 8px;
  border-left: 3px solid $tiffany;
  padding: 20px 24px;
  z-index: 100;
  box-shadow: 0 16px 64px 0 rgba(183, 203, 228, 0.5);
  background-color: white;
}
.error {
  border-left: 3px solid $red;
}
.ui-notification-title {
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;
  line-height: 26px;
}
.ui-notification-message {
  font-size: 16px;
  line-height: 24px;
  text-transform: initial;
}
</style>
