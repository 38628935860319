<template>
  <div class="container-fluid">
    <div class="container">
      <PlatformRules :amount="calculateAmount()" type="refill"
        ><template #title>пополнение баланса</template
        ><template #description
          >реквизиты для платежа по основному долгу, <br />
          процентам и пеням</template
        ></PlatformRules
      >
    </div>
  </div>
</template>

<script setup>
import PlatformRules from '@/components/auth/loan-card/modules/platform-rules/PlatformRules.vue';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const calculateAmount = () => {
  const totalDebt =
    store.currentCompany.funds?.overdue?.main +
    store.currentCompany.funds?.overdue?.interest +
    store.currentCompany.funds?.overdue?.fine;
  const totalPayment =
    store.currentCompany.funds?.next_payment?.main +
    store.currentCompany.funds?.next_payment?.interest;

  if (store.isOverdue) {
    if (store.isPaymentToday) {
      return store.currentCompany.funds?.current_balance <
        totalDebt + totalPayment
        ? totalDebt + totalPayment - store.currentCompany.funds?.current_balance
        : 0;
    } else {
      return store.currentCompany.funds?.current_balance < totalDebt
        ? totalDebt - store.currentCompany.funds?.current_balance
        : 0;
    }
  } else if (store.isPaymentToday) {
    return store.currentCompany.funds?.current_balance < totalPayment
      ? totalPayment - store.currentCompany.funds?.current_balance
      : 0;
  } else {
    return 0;
  }
};
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: $background;
  overflow: auto;
  min-height: 100%;
}
</style>
