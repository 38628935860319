<template>
  <NavBar v-if="loaded" />
  <UiNotification
    v-if="store.notification.message"
    :timer="store.notification.timer"
    :title="store.notification.title"
    :message="store.notification.message"
    :is-error="store.notification.isError"
  />

  <div class="wrapper-content">
    <main>
      <div class="wrapper-page">
        <router-view />
      </div>
    </main>
  </div>
  <Footer
    v-if="loaded && !alwaysAvailable && route.name === 'dashboard' && !isMobile"
  />
  <ErrorHandler />
  <SupportIcon />
  <DebugTeleport v-if="isRunTeleport" />
</template>

<script setup>
import NavBar from '@/components/auth/header/NavBar.vue';
import Footer from '@/components/auth/footer/Footer.vue';
import ErrorHandler from '@/components/_generic/ui/widgets/ErrorHandler.vue';
import UiNotification from '@/components/_generic/auth/UiNotification.vue';
import SupportIcon from '@/components/_generic/auth/support-form/SupportIcon.vue';
import DebugTeleport from '@/components/teleport/Teleport.vue';
import { computed, ref } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { useRoute } from 'vue-router';
import { detectMobile, getCircularReplacer, isStorageSupported } from '@/utils';
import { alwaysAvailablePaths } from '@/router';
import { useEvents } from '@/services/event-bus/events';

const store = useStateStore();
const route = useRoute();
const { isMobile } = detectMobile();

const loaded = ref(false);
const isRunTeleport = ref(false);

const alwaysAvailable = computed(() => {
  return !!alwaysAvailablePaths.has(route.name);
});

store.initialStore();

store.$onAction(({ store, after }) => {
  after(() => {
    try {
      if (!isStorageSupported('localStorage')) {
        console.error('localStorage is not support');
        return;
      }

      const data = JSON.stringify(store.$state, getCircularReplacer());
      localStorage.setItem('user', data);
    } catch (e) {
      console.error(e);
    }
  });
});

useEvents();

if (import.meta.env.MODE === 'mock' || import.meta.env.MODE === 'mocklocal') {
  isRunTeleport.value = true;
}

/**
 * Хак решающий проблему перехода по URL registration/agent на iphone
 * по какой то причине на iphone идет запуск NavBar а из-за запуск АПИ companies
 * и получение ответа 401 и потом редирект на логин
 * Поэтому здесь задержка загрузки на 1 сек
 */
setTimeout(() => {
  loaded.value = true;
}, 100);
</script>

<style lang="scss">
.container-fluid {
  background-color: $background;
}
</style>
