<template>
  <ModalFull maxwidth="700px" @close-modal="onClickClose">
    <template #title>
      <div class="title potok-text-h1">
        компания прошла проверку, но пока не может брать транши
      </div></template
    >
    <template #content>
      <div class="content-wrapper">
        <div class="content-reasons">
          <div v-for="(reason, index) in props.reasons" :key="index">
            <div class="reason-title-wrapper">
              <div class="reason-title-wrapper-left">
                <div class="reason-title potok-text-h2">причина</div>
                <div class="reason-desc potok-text-body-1">
                  <div v-if="reason.reason_name === 'rule_past_due_amt'">
                    Обнаружены просрочки по займам или кредитам.
                  </div>
                  <div
                    v-if="reason.reason_name === 'pre_statements_validation'"
                  >
                    Отсутствуют обороты в одном из последних двух месяцев.
                  </div>
                  <div
                    v-if="
                      reason.reason_name ===
                      'pre_contractors_concentration_indicator'
                    "
                  >
                    Более чем {{ reason.consumers_percent }}% выручки зависит от
                    одного покупателя.
                  </div>
                  <div v-if="reason.reason_name === 'kf_business_registration'">
                    Прошло менее 6 месяцев с даты регистрации компании.
                  </div>
                  <div v-if="reason.reason_name === 'generic'">
                    Оформление траншей временно заблокировано. По компании
                    обнаружены факторы, препятствующие выдаче дополнительных
                    средств.
                  </div>
                </div>
              </div>
              <div class="reason-title-wrapper-right reason-number">
                0{{ index + 1 }}
              </div>
            </div>
            <div class="recomendations-wrapper">
              <div class="recom-title potok-text-h2">рекомендации</div>
              <div class="recom-content">
                <template v-if="reason.reason_name === 'rule_past_due_amt'">
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Зайдите на страницу
                      <router-link
                        class="main-link"
                        :to="{
                          name: 'companyAnalytic',
                        }"
                        @click="onClickMyBusiness"
                      >
                        Мой бизнес</router-link
                      >
                      и проверьте, какие займы или кредиты находятся или
                      находились в просрочке
                    </div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Закройте активные просрочки по этим обязательствам
                    </div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div v-if="reason.discipline_days">
                      Соблюдайте платежную дисциплину в течение
                      {{ reason.discipline_days }} дней
                    </div>
                    <div v-if="!reason.discipline_days">
                      Соблюдайте платежную дисциплину по всем открытым займам и
                      кредитам
                    </div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Обновляйте лимит после возврата в нормальный режим
                      погашения
                    </div>
                  </div>
                </template>

                <template
                  v-if="reason.reason_name === 'pre_statements_validation'"
                >
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Зайдите на страницу
                      <router-link
                        class="main-link"
                        :to="{
                          name: 'companyAnalytic',
                        }"
                        @click="onClickMyBusiness"
                      >
                        Мой бизнес</router-link
                      >
                      и проверьте данные по оборотам компании
                    </div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Проверьте, со всех ли расчетных счетов вы загрузили
                      выписки. Возможно, выписка предоставлена не с основного
                      расчетного счета
                    </div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Проверьте за все ли периоды, включая текущий месяц, вы
                      загрузили выписки
                    </div>
                  </div>
                </template>

                <template
                  v-if="
                    reason.reason_name ===
                    'pre_contractors_concentration_indicator'
                  "
                >
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Зайдите на страницу
                      <router-link
                        class="main-link"
                        :to="{
                          name: 'companyAnalytic',
                        }"
                        @click="onClickMyBusiness"
                      >
                        Мой бизнес</router-link
                      >
                      и проверьте данные по контрагентам
                    </div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Проверьте, со всех ли расчетных счетов вы загрузили
                      выписки. Возможно, выписка предоставлена не с основного
                      расчетного счета
                    </div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Постарайтесь увеличить количество постоянных покупателей и
                      снизить зависимость от одного контрагента
                    </div>
                  </div>
                </template>

                <template
                  v-if="reason.reason_name === 'kf_business_registration'"
                >
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      После
                      {{ formatDate(reason.date_allowed_for_order) }} загрузите
                      актуальную выписку и обновите лимит
                    </div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>Увеличивайте кредитный потенциал компании</div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>Не допускайте просрочек по кредитам и займам</div>
                  </div>
                </template>

                <template v-if="reason.reason_name === 'generic'">
                  <div class="recom-item">
                    <div>→</div>
                    <div>Увеличивайте кредитный потенциал компании</div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>
                      Уменьшайте обязательства компании за пределами платформы
                    </div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>Не допускайте просрочек по кредитам и займам</div>
                  </div>
                  <div class="recom-item">
                    <div>→</div>
                    <div>Снижайте зависимость от одного покупателя</div>
                  </div>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div>
          <PotokButton
            class="button-go"
            text="перейти в Мой бизнес"
            size="medium"
            @click="onClickGoMyBusiness"
          />
        </div>
      </div>
    </template>
  </ModalFull>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { useRouter } from 'vue-router';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import { TRACKER_EVENTS } from '@/tracker/events';
import tracker from '@/tracker';
import { formatDate } from '@/utils';

const emit = defineEmits(['close-modal']);

const props = defineProps({
  reasons: {
    type: [Array],
    required: true,
    default: () => {
      return [];
    },
  },
});

const { SPA_LK_Hold_Info_screen_my_business_click } = TRACKER_EVENTS;

const router = useRouter();

const onClickClose = () => {
  emit('close-modal');
};

const onClickGoMyBusiness = () => {
  onClickMyBusiness();
  return router.push({
    name: 'companyAnalytic',
  });
};

const onClickMyBusiness = () => {
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Hold_Info_screen_my_business_click,
  );
};
</script>
<style lang="scss" scoped>
.title {
  text-align: left;
}
.content-wrapper {
  display: flex;
  flex-direction: column;
}
.button-go {
  max-width: 240px;
}
.reason-title-wrapper {
  background-color: #f2f4f8;
  border-radius: 14px;
  padding: 20px;
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;
}
.reason-desc {
  margin-top: 15px;
}
.recom-title {
  margin-bottom: 15px;
}
.recom-content {
  .recom-item:last-child {
    margin-bottom: 24px;
  }
}
.recom-item {
  margin-bottom: 30px;
  display: flex;
  div:first-child {
    margin-right: 20px;
  }
}
.reason-title-wrapper-left {
  flex: 10;
}
.reason-number {
  font-size: 50px;
  line-height: 36px;
  letter-spacing: -1%;
  font-weight: 500;
}
.recomendations-wrapper {
  background-color: #f2f4f8;
  border-radius: 14px;
  padding: 20px 20px 5px 20px;
  margin-bottom: 30px;
}
</style>
