<template>
  <div class="container-page-background">
    <div class="container-page">
      <ProfilePageMobile v-if="isMobile"></ProfilePageMobile>
      <ProfilePageDesktop v-if="!isMobile"></ProfilePageDesktop>
    </div>
  </div>
</template>
<script setup>
import { detectMobile } from '@/utils';

import ProfilePageDesktop from '@/components/auth/profile/ProfilePageDesktop.vue';
import ProfilePageMobile from '@/components/auth/profile/ProfilePageMobile.vue';

const { isMobile } = detectMobile();
</script>
<style lang="scss" scoped></style>
