<template>
  <ModalFull @close-modal="onClickClose">
    <template #title>
      <div class="title potok-text-h1">
        предварительный график платежей
      </div></template
    >
    <template #content>
      <div class="modal-confirmation-content">
        <template v-if="loading">
          <Loader />
        </template>
        <template v-if="!loading">
          <div class="desc potok-text-body-1 color-grey mb-3">
            График платежей сформирован согласно выбранным условиям транша и
            является предварительным. Итоговый график будет доступен в личном
            кабинете после выдачи транша.
          </div>
          <div>
            <div class="head-item">
              <div
                class="head-item-name potok-text-body-1"
                :class="{
                  'head-item-name-long': calc_payments.commission_amount,
                }"
              >
                сумма займа
              </div>
              <div class="head-item-value potok-text-body-1">
                {{ formatCurrency(calc_payments.amount, 0) }}&nbsp;₽
              </div>
            </div>
            <div class="head-item">
              <div
                class="head-item-name potok-text-body-1"
                :class="{
                  'head-item-name-long': calc_payments.commission_amount,
                }"
              >
                срок займа
              </div>
              <div class="head-item-value potok-text-body-1">
                с {{ formatDate(calc_payments.loan_from) }} по
                {{ formatDate(calc_payments.loan_to) }}
              </div>
            </div>
            <div class="head-item">
              <div
                class="head-item-name potok-text-body-1"
                :class="{
                  'head-item-name-long': calc_payments.commission_amount,
                }"
              >
                ставка
              </div>
              <div class="head-item-value potok-text-body-1">
                {{ calc_payments.rate }} % годовых
              </div>
            </div>
            <div v-if="calc_payments.commission_amount" class="head-item">
              <div
                class="head-item-name potok-text-body-1"
                :class="{
                  'head-item-name-long': calc_payments.commission_amount,
                }"
              >
                комиссия в регулярном платеже
              </div>
              <div class="head-item-value potok-text-body-1">
                {{ formatCurrency(calc_payments.commission_amount, 0) }}&nbsp;₽
              </div>
            </div>
          </div>
          <div class="payments content_scroll">
            <div class="table w-100 mt-6">
              <div class="table-head">
                <div class="table-head-item color-grey potok-text-uppercase">
                  дата платежа
                </div>
                <div class="table-head-item color-grey potok-text-uppercase">
                  общий размер платежа
                </div>
                <div class="table-head-item color-grey potok-text-uppercase">
                  основной долг
                </div>
                <div class="table-head-item color-grey potok-text-uppercase">
                  проценты + ндфл
                </div>
              </div>
              <div>
                <div
                  v-for="(payment, index) in calc_payments.payments"
                  :key="index"
                  class="item-row"
                >
                  <div class="item-cell potok-text-body-2">
                    {{ formatDate(payment.date) }}
                  </div>
                  <div class="item-cell potok-text-body-2">
                    {{ formatCurrency(payment.full_amount, 0) }}&nbsp;₽
                  </div>
                  <div class="item-cell potok-text-body-2">
                    {{ formatCurrency(payment.loan_amount, 0) }}&nbsp;₽
                  </div>
                  <div class="item-cell potok-text-body-2">
                    {{ payment.interest_amount }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="actions-block mt-6"></div>
    </template>
  </ModalFull>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { formatDate, formatCurrency } from '@/utils';
import { Loader } from 'potok-uikit';
import server from '@/server/index';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import { useStateStore } from '@/stores/stateStore';

const loading = ref(false);
const store = useStateStore();
const calc_payments = ref({});

const emit = defineEmits(['close']);
const onClickClose = () => {
  emit('close');
};

const getCommonSchedulePaymentsCalculate = () => {
  loading.value = true;
  const query = {
    order_id: store.currentOrder.id,
    amount: store.pages?.loanTerm?.amountCustomParams,
    term: store.pages?.loanTerm?.loanPeriodCustomParams,
  };
  if (
    store.pages?.loanTerm?.selectedComissionTypeCustomParams &&
    store.isCommissionChoiceAllowed
  ) {
    if (
      store.pages?.loanTerm?.selectedComissionTypeCustomParams ===
      'upfront_percent'
    ) {
      query.commission_type = 'upfront';
    }
    if (
      store.pages?.loanTerm?.selectedComissionTypeCustomParams ===
      'monthly_commission'
    ) {
      query.commission_type = 'monthly_commission';
    }
  } else {
    query.commission_type = 'upfront';
  }
  return server.getDraftSchedulePayments
    .send(query)
    .pipe(onDraftSchedulePaymentsSuccess, () => {
      loading.value = false;
    })
    .exec();
};
const onDraftSchedulePaymentsSuccess = (obj) => {
  loading.value = false;
  calc_payments.value = obj.data || {};
};

onMounted(() => {
  getCommonSchedulePaymentsCalculate();
});
</script>
<style lang="scss" scoped>
:deep(.modal-wrapper) {
  min-width: 777px;
}
:deep(.modal-container) {
  min-width: 777px;
}

.desc {
  max-width: 700px;
}
.modal-confirmation-content {
  min-width: 747px;
  @media (max-width: $size_767) {
    min-width: initial;
  }
  @media (max-width: $size_991) {
    min-width: initial;
  }
}
.title {
  text-align: left;
}
.head-item {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
}
.head-item-name {
  color: $grey;
  min-width: 150px;
}
.head-item-name-long {
  min-width: 280px !important;
}
.head-item-value {
  font-weight: 500;
}
.payments {
  max-height: 500px;
  @media (max-width: $size_767) {
    max-height: initial;
  }
}
.content_scroll {
  @media (max-width: $size_767) {
    height: initial;
  }
}
.table {
  width: 100%;
}
.table-head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.table-head-item {
  padding: 10px;
  border-bottom: 1px solid $dividers;
  flex: 1;
  &:first-child {
    padding-left: 0;
  }
}
.item-row {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.item-cell {
  flex: 1;
  padding: 16px;
  border-bottom: 1px solid $dividers;
  &:first-child {
    padding-left: 0;
  }
}
</style>
