import { useRouter } from 'vue-router';
import server, { useRedirection } from '@/server/index';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_union_company_shift, SPA_LK_union_company_registred } =
  TRACKER_EVENTS;

export function menuUtils() {
  const store = useStateStore();
  const router = useRouter();

  const getCompaniesAsync = () => {
    const options = {
      expand: ['funds', 'limits'],
      onSuccess: () => getCompaniesAffiliatesAsync(),
    };
    store.fetchCompanies(options);
  };

  const getCompaniesAffiliatesAsync = () => {
    store.companies.loading = true;
    return server.getCompaniesAffiliates
      .send()
      .pipe(onGetCompaniesAffiliatesAsyncSuccess, () => {
        store.companies.loading = false;
      })
      .exec();
  };

  const onGetCompaniesAffiliatesAsyncSuccess = ({ data }) => {
    data.forEach((el) => store.companies.items.push(el));
    store.companies.loading = false;
  };

  const onSelectItem = (value) => {
    store.setCurrentCompany(value);
    putCompaniesAsync(value);
  };

  const putCompaniesAsync = (company) => {
    store.companies.loading = true;
    return server.putCompanies$company_id
      .send(
        {},
        {
          params: {
            company_id: company.id,
          },
        },
      )
      .pipe(onPutCompanyProfilesSuccess(), onPutCompanyProfilesError)
      .exec();
  };

  const onPutCompanyProfilesSuccess = () => {
    tracker.queue(tracker.commands.SEND, SPA_LK_union_company_shift);
    store.companies.loading = false;
  };

  const onPutCompanyProfilesError = () => {
    store.companies.loading = false;
  };

  const onCreateCompany = (inn) => {
    store.companies.loading = true;
    const query = {
      company: {
        inn,
      },
    };
    return server.postCompanies
      .send(query)
      .pipe(onCreateCompanySuccess, onCreateCompanyError)
      .exec();
  };

  const onCreateCompanySuccess = ({ data }) => {
    store.companies.loading = false;
    tracker.queue(tracker.commands.SEND, SPA_LK_union_company_registred);
    useRedirection('/pvp/business/companies/' + data.id);
  };

  const onCreateCompanyError = () => {
    store.companies.loading = false;
  };

  const logOutAsync = () => {
    return server.userSignOut.send().pipe(logOutAsyncSuccess).exec();
  };

  const logOutAsyncSuccess = () => {
    store.logout();
    router.push({
      name: 'login',
    });
  };

  return {
    getCompaniesAsync,
    onSelectItem,
    onCreateCompany,
    logOutAsync,
  };
}
