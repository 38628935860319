<template>
  <div class="support-wrapper">
    <OnClickOutside @trigger="isPopupShown = false">
      <div
        class="support"
        :class="{ 'support-active': isPopupShown }"
        @click="isPopupShown = !isPopupShown"
      ></div>
    </OnClickOutside>
    <div v-if="isPopupShown" class="popup">
      <button
        class="btn-text btn-text-small btn-text-primary-tiffany mb-2"
        @click="onClickHandleAction('telegram')"
      >
        поддержка в telegram
      </button>
      <button
        class="btn-text btn-text-small btn-text-primary-tiffany mb-2"
        @click="onClickHandleAction('email')"
      >
        почта: ok@potok.team
      </button>
      <button
        class="btn-text btn-text-small btn-text-primary-tiffany mb-2"
        @click="onClickHandleAction('support')"
      >
        написать в поддержку
      </button>
    </div>
    <SupportForm v-if="isFormOpen" v-model="isTicketSent" @close="close" />
  </div>
  <PageAlert v-if="isTicketSent">
    обращение отправлено, в<br />
    ближайшее время с вами<br />
    свяжутся по почте, которую<br />
    вы указали! 👍
  </PageAlert>
</template>

<script setup>
import { ref, watch } from 'vue';
import { OnClickOutside } from '@vueuse/components';
import SupportForm from './SupportForm.vue';
import PageAlert from '../../ui/widgets/PageAlert.vue';

const isPopupShown = ref(false);
const isFormOpen = ref(false);
const isTicketSent = ref(false);

watch(
  () => isTicketSent.value,
  () => {
    if (isTicketSent.value) {
      isFormOpen.value = false;
      setTimeout(() => (isTicketSent.value = false), 3700);
    }
  },
);

const onClickHandleAction = (type) => {
  switch (type) {
    case 'telegram':
      window.open('https://t.me/OkPotokDigitalBot');
      break;
    case 'email':
      window.open('mailto:ok@potok.team');
      break;
    case 'support':
      isFormOpen.value = true;
      break;
    default:
      break;
  }
};

const close = () => {
  isFormOpen.value = false;
};
</script>

<style lang="scss" scoped>
.support-wrapper {
  position: fixed;
  right: 40px;
  bottom: 40px;
  z-index: 1000;
  @media (max-width: $size_575) {
    right: 20px;
    bottom: 20px;
  }
}

.support {
  cursor: pointer;
  width: 50px;
  height: 50px;
  background-color: $breakwater;
  border-radius: 50px;
  background-image: url('@/assets/icons/support.svg');
  background-repeat: no-repeat;
  background-position: center;
  &:hover {
    background-color: $dipedive;
  }
}
.support-active {
  background-color: $dipedive;
}
.popup {
  width: 195px;
  padding: 14px;
  position: absolute;
  bottom: 45px;
  right: 50px;
  border-radius: 6px;
  border: 1px solid rgba(204, 216, 255, 0.2);
  background: $white;
  box-shadow: 0px 4px 53px 0px rgba(183, 203, 228, 0.3);
  @media (max-width: $size_575) {
    bottom: 26px;
    right: 23px;
  }
  .popup-link {
    display: inline-block;
  }
}
</style>
