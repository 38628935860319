<template>
  <div>
    <div class="progress-bar d-flex align-items-center">
      <template
        v-for="(item, idx) in order.loan?.period_months_count"
        :key="idx"
      >
        <div
          :class="{
            active: idx < order.loan?.refunded_months_count || isLoanClosed,
            'w-100': idx !== order.loan?.period_months_count - 1,
          }"
          class="wrapper d-flex align-items-center"
        >
          <div class="dot"></div>
          <div
            v-if="idx !== order.loan?.period_months_count - 1"
            class="line"
            :class="{
              active:
                idx < order.loan?.refunded_months_count - 1 || isLoanClosed,
            }"
          ></div>
        </div>
      </template>
    </div>
    <div
      class="d-flex justify-content-between mt-2"
      :class="{ small: !isMobile }"
    >
      <span
        >выплачено
        {{ formatCurrency(order.loan?.payments?.repayments_amount, 0) }}
        ₽</span
      ><span class="color-grey"
        >из {{ formatCurrency(order.loan?.amount, 0) }} ₽</span
      >
    </div>
  </div>
</template>

<script setup>
import { formatCurrency } from '@/utils';
import { toRefs } from 'vue';
import { detectMobile } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import { storeToRefs } from 'pinia';

const store = useStateStore();
const { isLoanClosed } = storeToRefs(store);
const { isMobile } = detectMobile();
const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
  width: {
    type: String,
    default: '300px',
  },
  trackColor: {
    type: String,
    default: '#f2f4f8',
  },
});
const { order, width, trackColor } = toRefs(props);
</script>

<style lang="scss" scoped>
.progress-bar {
  width: v-bind('width');
  @media (max-width: $size_575) {
    width: 100%;
  }
}
.dot {
  min-width: 10px;
  min-height: 10px;
  background-color: v-bind('trackColor');
  border-radius: 50%;
  z-index: 1;
}

.line {
  width: 100%;
  height: 6px;
  background-color: v-bind('trackColor');
  left: 8px;
  margin-left: -2px;
  margin-right: -2px;
}
.wrapper.active {
  .dot {
    background-color: $breakwater;
  }
}

.line.active {
  background-color: $breakwater;
}
</style>
