// see https://stackoverflow.com/a/48724909
import { format as formatdatetime, parseISO, parse } from 'date-fns';
import ru from 'date-fns/locale/ru';
import flatten from 'arr-flatten';
import format from 'format';

/**
 * Throws an error when a value is falsely
 * @param {*} value
 * @param {String} [message]
 */
const assert = function (value, message = '') {
  if (!value) {
    throw new Error(message);
  }
};
const _assert = assert;
export { _assert as assert };

/**
 * @see https://stackoverflow.com/a/2117523
 * @return {String}
 */
export function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}
/**
 * Используется для выбора окончания например ['день', 'дня', 'дней']
 * @param {Number} number
 * @param {String} titles
 * @return {String}
 */
export function pluralize(_number, titles) {
  let number = Math.abs(_number);
  let cases = [2, 0, 1, 1, 1, 2];
  return titles[
    number % 100 > 4 && number % 100 < 20
      ? 2
      : cases[number % 10 < 5 ? number % 10 : 5]
  ];
}

/**
 * Возвращает список чисел в указаном интервале включая конечное
 * @param {Number} maxOrMin
 * @param {Number} [minOrMax=0]
 * @param {Number} [step=1]
 * @returns {Array.<Number>}
 */
export function range(maxOrMin, minOrMax = 0, step = 1) {
  const min = Math.min(maxOrMin, minOrMax);
  const max = Math.max(maxOrMin, minOrMax);
  const len = Math.ceil((max - min + 1) / step);
  let arr = new Array(len);
  for (let i = 0; i < len; i += 1) {
    arr[i] = step * i + min;
  }
  return arr;
}
/**
 * Turns 79998887766 to 7(999)888-77-66
 * @param {String} [phone]
 * @return {String}
 */
export function formatPhone(phone = '') {
  const placeholder = new Array(11).fill('*');
  const phoneArr = Object.assign(placeholder, String(phone).split(''));
  return format('%s (%s%s%s) %s%s%s-%s%s-%s%s', ...phoneArr);
}

/**
 * @param {Number|String} n
 * @param {Number|String} [digits=2]
 * @returns {String}
 */
export function formatCurrency(n, digits = 2) {
  let hasMinus = false;
  if ((n && typeof n === 'string') || n instanceof String) {
    if (n.length > 0 && n[0] === '-') {
      hasMinus = true;
    }
  }
  if (n && typeof n === 'number') {
    if (n < 0) {
      hasMinus = true;
    }
  }
  let numberAsString = Number(formatNumber(n));
  if (digits) {
    numberAsString = numberAsString.toFixed(digits);
  } else {
    numberAsString = parseInt(numberAsString);
  }
  const pattern = /(?=\B(?:\d{3})+\b)/g;
  const result = String(numberAsString).replace(pattern, ' ');
  return hasMinus ? `-${result}` : result;
}

export function localParseDate(date) {
  let result = parse(date, 'dd.MM.yyyy', new Date());
  if (!isNaN(result.getTime())) {
    return result;
  }
  result = parse(date, 'yyyy-MM-dd', new Date());
  if (!isNaN(result.getTime())) {
    return result;
  }
}

/**
 * @param {Date|String} date
 * @param {String} [format]
 * @return {String}
 */
export function formatDate(date, format = 'dd.MM.yyyy') {
  if (!date) {
    return;
  }
  if (typeof date === 'object' && date instanceof Date) {
    return formatdatetime(date, format, { locale: ru });
  }
  if (typeof date === 'string') {
    let datetime = null;
    let result = null;
    try {
      if (date.length < 10) {
        datetime = parse(date, 'dd-MM-yyyy', new Date());
      } else {
        datetime = parseISO(date);
      }
      result = formatdatetime(datetime, format, { locale: ru });
    } catch (e) {
      console.log(e);
    }
    return result;
  }
}

/**
 * @param {String} str
 * @return {String}
 */
const formatNumber = function (str) {
  const sanitized = String(str).replace(/[^.\d]+/g, '');
  const match = sanitized.match(/\d+(\.\d+)?/);
  return match ? match[0] : '';
};
const _formatNumber = formatNumber;
export { _formatNumber as formatNumber };

/**
 * @param {String} str
 * @return {String}
 */
export function formatName(str) {
  return String(str)
    .replace(/\s{2,}/g, ' ')
    .trim()
    .toLowerCase()
    .replace(/(?:^|[- ])(\S)/g, (match) => match.toUpperCase());
}

/**
 * @param {Object} obj
 * @returns {String}
 */
export function formatJson(obj) {
  return JSON.stringify(obj, null, '  ');
}

/**
 * @param {Object} plainObject
 * @returns {Object}
 */
export function copyAsJson(plainObject) {
  return JSON.parse(JSON.stringify(plainObject));
}

/**
 * @see https://github.com/msn0/file-downloader
 * @param {String} filename
 * @param {Blob} blob
 */
export function downloadFile(filename, blob) {
  let link = document.createElement('a');
  link.setAttribute('href', window.URL.createObjectURL(blob));
  link.setAttribute('download', filename);
  link.style.display = 'none';
  document.body.appendChild(link);
  link.click();
}

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Disposition
 * @param {Response} response
 */
export function parseFilename(response) {
  const header = response.headers.get('Content-Disposition');
  return String(header).match(/filename="([^"]+)"/)[1] || '';
}

/**
 * @param {Number} len
 * @return {String}
 */
export function generateCode(len = 6) {
  let arr = new Array(len);
  for (let i = 0; i < len; i++) {
    arr[i] = getRandomIntInclusive(0, 9);
  }
  return arr.join('');
}

/**
 * Do nothing
 */
const noop = function () {
  // empty body
};
const _noop = noop;
export { _noop as noop };

/**
 * Gets [1,2,3,4,5] and returns [[1,2], [3,4], [5]]
 * @param {Array|String} input
 * @param {Number} [chunk=2]
 * @returns {Array}
 */
export function group(input, chunk = 2) {
  const maxI = input.length;
  const maxJ = Math.ceil(maxI / chunk);
  const out = new Array(maxJ);
  for (let i = 0, j = 0; j < maxJ; i += chunk, j++) {
    out[j] = input.slice(i, Math.min(i + chunk, maxI));
  }
  return out;
}

/**
 * @param {String} str
 * @returns {String}
 */
export function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * @param {Object} obj
 * @param {String} key
 * @param {*} [stubValue=null]
 * @returns {*}
 */
export function getProp(obj, key, stubValue = null) {
  return isObject(obj) ? obj[key] : stubValue;
}

/**
 * @param {...Function} fns
 * @returns {Function}
 */
export function compose(...fns) {
  return (_) => fns.reduce((acc, fn) => fn(acc), _);
}

/**
 * @param {...Function} fns
 * @returns {Function}
 */
export function composePromise(...fns) {
  return (_) => new Promise(next.bind(null, _, fns));
  // ----------
  function next(arg, fns, resolve, reject) {
    const fn = fns.shift() || noop;
    const promiseOrUndefined = fn(arg);
    const onFulfilled = next.bind(null, arg, fns, resolve, reject);
    return promiseOrUndefined
      ? promiseOrUndefined.then(onFulfilled, reject)
      : resolve(arg);
  }
}

/**
 * Filters an array or an object
 * @param {Array|Object} any
 * @param {Function} callback
 * @returns {Array|Object}
 */
export function filter(any, callback) {
  return Array.isArray(any)
    ? any.filter(callback)
    : Object.keys(any).reduce((acc, key) => {
        return callback(any[key], key)
          ? Object.assign(acc, { [key]: any[key] })
          : acc;
      }, {});
}

/**
 * Maps arrays or objects
 * @param {Array|Object} arrOrObj
 * @param {Function} [cb]
 * @return {Array}
 */
export function map(arrOrObj, cb) {
  return Array.isArray(arrOrObj)
    ? arrOrObj.map(cb)
    : Object.keys(arrOrObj).map((k) => cb(arrOrObj[k], k));
}

/**
 * @param {Array} arr
 * @param {Function} [hashFn=JSON.stringify]
 * @returns {Array} arr
 */
export function unique(arr, hashFn = JSON.stringify) {
  const set = new Set();
  return arr.filter((item) => {
    const hash = hashFn(item);
    if (set.has(hash)) {
      return false;
    } else {
      set.add(hash);
      return true;
    }
  });
}

/**
 * @param {*} arg
 * @returns {*}
 */
export function identity(arg) {
  return arg;
}

/**
 * Takes A and B and returns a list of tuples (A, B) i.e. a list of pairs
 * @see https://gitlab.mmdev.ru/modulemoney/module-money-backend-gateway/merge_requests/318/diffs#3705be42d294bc4dabd48e32cb44899d6479b180_357_425
 * @param {Array.<*>} listA
 * @param {Array.<*>} listB
 * @returns {Array.<Array>}
 */
export function zip(listA, listB) {
  const lenA = listA.length;
  const lenB = listB.length;
  assert(lenA === lenB, 'Lists must have the same length');
  const arr = new Array(listA.length);
  for (let i = 0; i < lenA; i++) {
    arr[i] = [listA[i], listB[i]];
  }
  return arr;
}

/**
 * Selects certain keys & values from an object to a new plain object
 * @see https://gitlab.mmdev.ru/modulemoney/module-money-backend-gateway/merge_requests/318/diffs#3705be42d294bc4dabd48e32cb44899d6479b180_357_405
 * @param {Object} obj
 * @param {Array.<String>} chosen An array of desired keys
 * @returns {Object}
 */
export function select(obj, chosen) {
  assert(isObject(obj), '"obj" must be an object');
  assert(Array.isArray(chosen), '"chosen" must be an array of strings');
  return chosen.reduce((acc, key) => {
    acc[key] = obj[key];
    return acc;
  }, {});
}

/**
 * flatten an array of arrays
 * @see https://stackoverflow.com/a/15030117
 * @param {Array.<*>} arr
 * @returns {Array.<*>} arr
 */
const _flatten = flatten;
export { _flatten as flatten };

/**
 * wraps the amount in nobr tag
 * @param {String} text
 * @returns {String}
 */
export function noBrCurrency(text) {
  assert(isString(text), '"text" must be a string');
  return text.replace(/(\d+)* \d+\.\d+/gi, (str) => {
    return `${str}`;
  });
}

/**
 * change \n on <br>
 * @see https://stackoverflow.com/a/7467863
 * @param {String} text
 * @returns {String}
 */
export function nl2br(text) {
  assert(isString(text), '"text" must be a string');
  return text
    .replace(/(<br\/>|<br \/>)/g, '')
    .replace(/\r\n|\n\r|\r|\n/g, '<br/>');
}

/**
 * WARNING: works only with {Boolean|Number|String|Array|Object|null|undefined} types
 * @see https://github.com/nervgh/yum.js/blob/master/src/yum.js#L143
 * @param any
 * @returns {*}
 */
const copy = function (any) {
  // Number, String, Boolean, null, undefined
  if (isPrimitive(any)) {
    return any;
  }
  // We cannot copy FormData or File objects
  if (isFormData(any) || isFile(any)) {
    return any;
  }

  let root = Array.isArray(any) ? [] : {};
  for (let key in any) {
    // eslint-disable-next-line no-prototype-builtins
    if (any.hasOwnProperty(key)) {
      root[key] = copy(any[key]);
    }
  }
  return root;
};
const _copy = copy;
export { _copy as copy };

/**
 * @param {*} any
 * @return {String}
 */
const getType = function (any) {
  return Object.prototype.toString.call(any);
};
const _getType = getType;
export { _getType as getType };

/**
 * @param {*} any
 * @returns {Boolean}
 */
const isObject = function (any) {
  return any !== null && typeof any === 'object';
};
const _isObject = isObject;
export { _isObject as isObject };

/**
 * @param {*} text
 * @returns {Boolean}
 */
const isString = function (text) {
  return typeof text === 'string';
};
const _isString = isString;
export { _isString as isString };

/**
 * @param {*} any
 * @return {Boolean}
 */
const isFile = function (any) {
  return getType(any) === '[object File]';
};
const _isFile = isFile;
export { _isFile as isFile };

// --------------

/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
 * @param {Number} min
 * @param {Number} max
 * @return {Number}
 */
function getRandomIntInclusive(_min, _max) {
  const min = Math.ceil(_min);
  const max = Math.floor(_max);
  // The maximum is inclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

/**
 * @param {*} any
 * @returns {Boolean}
 */
function isPrimitive(any) {
  if (any === null) {
    return true;
  }
  switch (typeof any) {
    case 'boolean':
    case 'number':
    case 'string':
    case 'null':
    case 'undefined':
      return true;
    default:
      return false;
  }
}

/**
 * @param {*} any
 * @return {Boolean}
 */
function isFormData(any) {
  return getType(any) === '[object FormData]';
}
