<template>
  <div
    id="limit-tab"
    class="head-tabmenu-limit form"
    :class="applyFormStyles(true)"
    @mouseover="isLimitTabHovered = true"
    @mouseleave="isLimitTabHovered = false"
    @click="onClickSwitchTab(true)"
  >
    <div class="form-block">
      <div v-if="!loading" class="form-title-wrapper flex-nowrap">
        <div class="limit-wrapper form-title-block text-nowrap cursor-pointer">
          <div
            class="form-title head-section-title"
            :class="{
              'color-grey': store.isOrderHold,
            }"
          >
            доступный лимит
          </div>
          <div
            class="form-title-value head-section-title-amount potok-text-h2"
            :class="{
              'color-red': limits.available && limits.available < 0,
              'color-grey': store.isOrderHold,
            }"
          >
            {{ formatCurrency(limits.available, 0) }}
            ₽&nbsp;
            <VMenu v-if="store.isOrderHold">
              <i class="icon-lock-grey"></i>
              <template #popper>
                <div class="order-hold-wrapper potok-text-body-1">
                  оформление траншей временно недоступно. по компании обнаружены
                  факторы, препятствующие выдаче дополнительных средств.
                  <div class="order-hold-message-link main-link">
                    <router-link
                      :to="{ name: 'dashboard', query: { action: 'hold' } }"
                      >узнать подробности</router-link
                    >
                  </div>
                </div>
              </template>
            </VMenu>
          </div>
        </div>
        <div class="form-title-block text-nowrap cursor-pointer">
          <div class="form-title head-section-title">кредитный потенциал</div>
          <div
            class="form-title-value head-section-title-amount text-grey potok-text-h2"
          >
            {{ formatCurrency(limits.final_limit, 0) }}
            ₽&nbsp;
            <i
              id="final-limit"
              class="question-icon-black"
              @click="onClickQuestionIcon()"
            ></i>
          </div>
        </div>
      </div>
      <template v-if="loading"><Loader /></template>
      <div
        v-if="!store.uiStates.isDashboardLimitTabActive"
        class="shadow-tab"
      ></div>
    </div>
    <div v-if="!isMobile" class="action-panel ms-auto">
      <div
        v-if="!store.uiStates.isDashboardLimitTabActive"
        class="action-button-not-active"
        :class="{
          'bg-primary text-white rounded-5': isLimitTabHovered,
          'text-grey': !isLimitTabHovered,
        }"
      >
        <span class="small"> взять новый заем &#8594;</span>
      </div>

      <PotokButton
        v-if="store.uiStates.isDashboardLimitTabActive"
        :class="{
          'color-grey border-grey': isActionButtonDisabled(),
        }"
        :disabled="isActionButtonDisabled()"
        size="medium"
        theme="secondary"
        @click="getActionForButton('action')"
        ><template #default
          ><div class="d-flex align-items-center">
            <span>{{ getActionForButton('title') }}</span>
          </div></template
        ></PotokButton
      >
    </div>
  </div>
</template>
<script setup>
import { onMounted } from 'vue';
import { PotokButton, Loader } from 'potok-uikit';
import { headUtils } from './headUtils.js';
import { formatCurrency } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import { loanTermsUtils } from '@/components/auth/dashboard/loan-terms/utils/loanTermsUtils';
import { detectMobile } from '@/utils';

const { isMobile } = detectMobile();
const store = useStateStore();
const emit = defineEmits(['open-modal-limits']);

const {
  loading,
  limits,
  isLimitTabHovered,
  applyFormStyles,
  onClickSwitchTab,
  getCompanyLimit,
} = headUtils();
const { getActionForButton, isActionButtonDisabled } = loanTermsUtils();

const onClickQuestionIcon = () => {
  emit('open-modal-limits');
};

onMounted(() => {
  getCompanyLimit();
});
</script>
<style lang="scss" scoped>
.action-panel {
  max-width: 297px;
  position: relative;
}
.action-button-not-active {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  min-height: 37px;
}
.text-banned {
  max-width: 357px;
}
.limit-wrapper {
  position: relative;
}
.head-tabmenu-limit {
  display: flex;
  align-items: center;
  justify-content: start;
  @media (max-width: $size_767) {
    align-items: initial;
  }
}
.question-icon-black {
  cursor: pointer;
}

.order-hold-wrapper {
  max-width: 300px;
}

.order-hold-message-link {
  text-align: right;
  margin-top: 20px;
}
.available-limit {
  display: flex;
  align-items: center;
  justify-content: space-around;
  @media (max-width: $size_767) {
    justify-content: flex-start;
  }
}
</style>
