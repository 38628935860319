import config from '@/../config';

export default {
  postCommands: {
    url: config.VITE_BASE_URL + `/spa/business/commands`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  getBankAccounts: {
    url: config.VITE_BASE_URL + `/spa/business/bank_accounts`,
    method: 'GET',
    credentials: 'include',
  },
  putBankAccount: {
    url: config.VITE_BASE_URL + `/spa/business/bank_accounts/$bank_account_id`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
};
