<template>
  <div class="white-block graphs-item">
    <div class="block-title">
      <div class="potok-text-body-1-bold">
        просрочки
        <span class="color-grey"> по данным бюро кредитных историй</span>
      </div>
      <div v-if="!loading" class="date-notice">
        <div
          class="potok-text-uppercase color-grey"
          :class="{
            'me-4': isHold,
          }"
        >
          обновлено {{ lastDateScoring }}
        </div>
        <div v-if="isHold" class="hold-reason">ПРИЧИНА БЛОКИРОВКИ</div>
      </div>
    </div>
    <template v-if="loading">
      <Loader class="mt-4" />
    </template>
    <template v-if="!loading">
      <div class="custom-table mt-4">
        <div class="custom-table-thead">
          <div class="custom-table-th">дата кредита или займа</div>
          <div class="custom-table-th">сумма кредита</div>
          <div class="custom-table-th">статус</div>
        </div>
        <div class="custom-table-tbody">
          <div
            v-for="(item, index) in creditHistories"
            :key="index"
            class="custom-table-tr"
          >
            <div class="custom-table-td">
              <div class="custom-table-th-mobile">
                дата кредита или займа:&nbsp;
              </div>
              {{ item.date }}
            </div>
            <div class="custom-table-td">
              <div class="custom-table-th-mobile">сумма кредита:&nbsp;</div>
              <div v-if="item.loan_amount">
                {{ formatCurrency(item.loan_amount, 0) }}&nbsp;₽
              </div>
              <div v-if="!item.loan_amount">нет данных из БКИ</div>
            </div>
            <div class="custom-table-td">
              <div class="custom-table-th-mobile">статус:&nbsp;</div>
              {{ item.status }}
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script setup>
import server from '@/server/index';
import { ref, onMounted, computed } from 'vue';
import { Loader } from 'potok-uikit';
import { useStateStore } from '@/stores/stateStore';
import { formatCurrency } from '@/utils';

const store = useStateStore();
const loading = ref(true);
const errors = ref([]);
const creditHistories = ref([]);
const lastDateScoring = ref(null);

const isHold = computed(() => {
  return (
    store.currentOrder?.loan_terms_offer &&
    store.currentOrder?.hold_manual_scoring_reasons &&
    store.currentOrder?.hold_manual_scoring_reasons.find((i) => {
      return i.reason_name === 'rule_past_due_amt';
    })
  );
});

const getCreditHistories = () => {
  loading.value = true;
  return server.getCreditHistories
    .send(
      {},
      {
        params: { company_id: store.currentCompany.id },
      },
    )
    .pipe(onCreditHistoriesAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};

const onCreditHistoriesAsyncSuccess = ({ data }) => {
  loading.value = false;
  creditHistories.value = data.data;
  lastDateScoring.value = data.last_scoring_date;
};

onMounted(() => {
  getCreditHistories();
});
</script>
<style lang="scss" scoped>
.block-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.date-notice {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
</style>
