<template>
  <div class="container pt-7 pb-7" @click="emit('update:isHighlighted', false)">
    <div class="form m-auto">
      <div class="title fw-bold mb-3">
        {{ store.fullName || store.currentCompanyInfo.title }}
      </div>
      <div class="mb-6 text-center">
        проверьте и заполните паспортные данные
      </div>
      <PassportForm
        v-model:isDataCorrect="isPassportDataCorrect"
        :is-disabled="props.isDisabled"
        :is-highlighted="props.isHighlighted"
        :touch-form="touchForm"
      />
      <ErrorItem
        v-if="errorsArr.length !== 0"
        v-model="errorsArr"
        class="mb-3"
      />
      <PotokButton
        v-if="!loading"
        class="mb-4 w-100"
        :disabled="!!props.isDisabled || !isPassportDataCorrect"
        text="продолжить"
        size="large"
        @click="postUsersPassportsAsync()"
      />
      <LoadingBtn v-else class="mb-4" />
      <h6
        class="row-link fw-normal text-center mb-4"
        @click="loading ? null : backToSummaryInfo()"
      >
        назад
      </h6>
      <h6
        class="row-link fw-normal text-center"
        @click="loading ? null : emit('redirect-to-user-cabinet')"
      >
        заполнить позже
      </h6>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import PassportForm from '@/components/_generic/auth/passport-form/PassportForm.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import WithParser from '@/components/auth/user-passports/_generic/WithParser.vue';
import { ref, computed, onMounted } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';
import tracker from '@/tracker';
import UL_SummaryInfo from '../ul-user-type/UL_SummaryInfo.vue';
import IP_SummaryInfo from '../ip-user-type/IP_SummaryInfo.vue';
import { usePassport } from './utils';
import { TRACKER_EVENTS } from '@/tracker/events';
import { isPassportFullFileld } from '@/utils';

const { SPA_LK_BKI_passport_signature_open, SPA_LK_BKI_passport_success } =
  TRACKER_EVENTS;

const { isParsed, isError } = usePassport();
const props = defineProps({
  isDisabled: {
    type: Boolean,
  },
  isPassportDataCorrect: {
    type: Boolean,
  },
  isHighlighted: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'change-component',
  'redirect-to-user-cabinet',
  'get-user-data-async',
  'update:isPassportDataCorrect',
  'update:isHighlighted',
]);

const isPassportDataCorrect = computed({
  get() {
    return props.isPassportDataCorrect;
  },
  set(value) {
    emit('update:isPassportDataCorrect', value);
  },
});

const store = useStateStore();
const loading = ref(false);
const errorsArr = ref([]);
const touchForm = ref(false);

onMounted(() => {
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_passport_signature_open);
});

const backToSummaryInfo = () => {
  if (
    isPassportFullFileld(store.currentUserPassport) ||
    isParsed.value ||
    isError.value ||
    !store.isParsingEnabled
  ) {
    if (store.isUserUL) {
      emit('change-component', UL_SummaryInfo);
      emit('get-user-data-async');
    } else {
      emit('change-component', IP_SummaryInfo);
      emit('get-user-data-async');
    }
  } else {
    emit('change-component', WithParser);
  }
};
const postUsersPassportsAsync = () => {
  touchForm.value = true;
  if (!isPassportDataCorrect.value) {
    return;
  }
  loading.value = true;
  const query = {
    user: {
      series_number:
        store.currentUserPassport.series +
        ' ' +
        store.currentUserPassport.number,
      issued_date: store.currentUserPassport.issued_date,
      birthday: store.currentUserPassport.birthday,
      place_of_birth: store.currentUserPassport.place_of_birth,
      registration_address: store.currentUserPassport.registration_address,
      department_code: store.currentUserPassport.department_code,
      issued_by: store.currentUserPassport.issued_by,
    },
  };
  return server.postUsersPassports
    .send(query)
    .pipe(onPostUsersPassportsAsyncSuccess, onPostUsersPassportsAsyncError)
    .exec();
};
const onPostUsersPassportsAsyncSuccess = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_passport_success);
  backToSummaryInfo();
  loading.value = false;
};
const onPostUsersPassportsAsyncError = (error) => {
  errorsArr.value = error.data?.messages;
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 460px;
  .form {
    .title {
      font-size: 30px;
      line-height: 36px;
      text-align: center;
    }
    .row-link {
      cursor: pointer;
      color: $grey;
      &:hover {
        color: $breakwater;
      }
    }
  }
}
</style>
