<template>
  <SetUserEmail
    :errors-arr="errorsArr"
    :loading="loading"
    @send-email-async="sendEmailAsync"
    @clear-errors="clearErrors"
  />
</template>

<script setup>
import SetUserEmail from '@/components/_generic/unauth/SetUserEmail.vue';
import { ref, onMounted, inject } from 'vue';
import server from '@/server/index';
import tracker from '@/tracker';
import { useRoute, useRouter } from 'vue-router';
import { useStateStore } from '@/stores/stateStore';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Registration_need_email_success } = TRACKER_EVENTS;

const route = useRoute();
const router = useRouter();
const store = useStateStore();
const emitter = inject('emitter');

const loading = ref(false);
const errorsArr = ref([]);

onMounted(() => {
  getUserData();
});

const getUserData = () => {
  loading.value = true;
  return server.getUserData
    .send()
    .pipe(onGetUserDataSuccess, () => {
      loading.value = false;
    })
    .exec();
};
const onGetUserDataSuccess = ({ data }) => {
  loading.value = false;
  store.setCurrentUser(data);
};

const clearErrors = () => {
  errorsArr.value = [];
};
const sendEmailAsync = (rawEmail) => {
  loading.value = true;
  const query = {
    user: {
      email: rawEmail,
    },
  };
  return server.patchUserEmail
    .send(query)
    .pipe(onSendEmailAsyncSuccess, onSendEmailAsyncError)
    .exec();
};
const onSendEmailAsyncSuccess = () => {
  if (route.query?.step) {
    return router.push('/login');
  }
  store.fetchFeatures({
    onSuccess: () => {
      loading.value = false;
      tracker.queue(
        tracker.commands.SEND,
        SPA_LK_Registration_need_email_success,
      );
      emitter.emit('login-success');
    },
    onError: (error) => {
      loading.value = false;
      errorsArr.value = error.data?.messages;
    },
  });
};
const onSendEmailAsyncError = (error) => {
  loading.value = false;
  errorsArr.value = error.data?.messages;
};
</script>
