import { inject, computed } from 'vue';
import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';
import constants from '@/constants';

export function loanCollectUtils() {
  const emitter = inject('emitter');
  const {
    order,
    guarantors,
    isApprovedByCreditCommittee,
    isPassportUploaded,
    isGuaranteeFullFilled,
    isPassportFilled,
    isPlatformRulesOpen,
    isPassportModalOpen,
    isPassportFillModalOpen,
    isGuaranteeIssueOpen,
    isDocsModalOpen,
    isDocsSigningModalOpen,
    isGuaranteeSigningOpen,
    isDocsSigned,
    isDocsVKLSigned,
    isSuccessModalOpen,
    isManagerModalOpen,
    docsToUpload,
    isConfirmedByRouble,
    isPassportPartiallyUploaded,
    isGuaranteePartiallyFilled,
    isAddDocsPartiallyUploadedOld,
    isGuaranteeAllSignedByRub,
    isGuaranteePartiallySignedByRub,
  } = loanCardShared();

  // признак автофлоу (внутренний статус)
  const isScoringStateAutoFlow = computed(() => {
    return (
      order.value.manual_scoring_state ===
      constants.MANUAL_SCORING_STATES.auto_issuance
    );
  });

  // признак автофлоу
  const isAutoFlow = computed(() => {
    return !!order.value.auto_issuance;
  });

  const isReadyForSigning = computed(() => {
    if (guarantors.value.length) {
      return (
        isPassportUploaded.value &&
        isPassportFilled.value &&
        isGuaranteeFullFilled.value
      );
    }
    return isPassportUploaded.value && isPassportFilled.value;
  });
  const isOrderApproved = computed(() => {
    return isApprovedByCreditCommittee.value || isScoringStateAutoFlow.value;
  });

  const loanState = computed(() => {
    if (order.value && order.value.loan) {
      return order.value.loan?.state;
    }
    return null;
  });

  const loanStateTitle = computed(() => {
    if (order.value.loan && order.value.loan?.state === 'investing') {
      return 'собираем деньги';
    }
    return isOrderApproved.value ? 'заявка проверена' : 'заявка на проверке';
  });

  const loanStateIcon = computed(() => {
    if (
      order.value.client_state === constants.CLIENT_STATES.investing ||
      order.value.client_state === constants.CLIENT_STATES.ready
    ) {
      return 'icon-done';
    }
    return 'icon-wait';
  });

  const onClickGoToChosenStep = (stepName) => {
    if (stepName === 'rules') {
      isPlatformRulesOpen.value = true;
    } else if (stepName === 'passport') {
      isPassportModalOpen.value = true;
    } else if (stepName === 'passport-manual-fill') {
      isPassportFillModalOpen.value = true;
    } else if (stepName === 'guarantee') {
      isGuaranteeIssueOpen.value = true;
    } else if (stepName === 'documents') {
      isDocsModalOpen.value = true;
    } else if (
      stepName === 'signing' &&
      isReadyForSigning.value &&
      isOrderApproved.value
    ) {
      isDocsSigningModalOpen.value = true;
    } else if (stepName === 'founder_signing' && isDocsSigned.value) {
      isGuaranteeSigningOpen.value = true;
    } else {
      return;
    }
  };

  const onClickHandleSubmit = () => {
    if (
      isReadyForSigning.value &&
      isAutoFlow.value &&
      order.value.client_state === 'manual_check'
    ) {
      isSuccessModalOpen.value = true;
      return;
    }
    emitter.emit('to-dashboard');
  };
  const closeModal = () => {
    isPassportModalOpen.value = false;
    isGuaranteeIssueOpen.value = false;
    isDocsSigningModalOpen.value = false;
    isDocsModalOpen.value = false;
    isGuaranteeSigningOpen.value = false;
    isPassportFillModalOpen.value = false;
    isSuccessModalOpen.value = false;
    isManagerModalOpen.value = false;
  };
  const isShownSection = (item) => {
    if (item.name === 'guarantee' || item.name === 'founder_signing') {
      return !!guarantors.value.length;
    } else if (item.name === 'documents') {
      return !!docsToUpload.value.length;
    } else if (item.name === 'passport-manual-fill') {
      return !isPassportFilled.value;
    } else {
      return true;
    }
  };
  const detectTextColor = (item) => {
    if (item.name === 'rules') {
      return isConfirmedByRouble.value ? '' : 'text-tiffany';
    } else if (item.name === 'passport') {
      return isPassportUploaded.value
        ? ''
        : isPassportPartiallyUploaded.value
          ? ''
          : 'text-tiffany';
    } else if (item.name === 'passport-manual-fill') {
      return isPassportFilled.value ? '' : 'text-tiffany';
    } else if (item.name === 'guarantee') {
      return isGuaranteeFullFilled.value
        ? ''
        : isGuaranteePartiallyFilled.value
          ? ''
          : 'text-tiffany';
    } else if (item.name === 'documents') {
      return isAddDocsPartiallyUploadedOld.value
        ? ''
        : isAddDocsPartiallyUploadedOld.value
          ? ''
          : 'text-tiffany';
    } else if (item.name === 'signing') {
      return isReadyForSigning.value && isOrderApproved.value
        ? 'text-tiffany'
        : 'color-grey';
    } else if (item.name === 'founder_signing') {
      return isDocsSigned.value &&
        !isGuaranteeAllSignedByRub.value &&
        !isGuaranteePartiallySignedByRub.value
        ? 'text-tiffany'
        : isGuaranteePartiallySignedByRub.value
          ? ''
          : 'color-grey';
    }
  };
  const whichTextShown = (item) => {
    if (item.name === 'rules') {
      return isConfirmedByRouble.value ? undefined : `подписать \u{2192}`;
    } else if (item.name === 'passport') {
      return isPassportUploaded.value
        ? undefined
        : isPassportPartiallyUploaded.value
          ? `продолжить \u{2192}`
          : `приложить \u{2192}`;
    } else if (item.name === 'passport-manual-fill') {
      return isPassportFilled.value ? undefined : `заполнить \u{2192}`;
    } else if (item.name === 'guarantee') {
      return isGuaranteeFullFilled.value
        ? undefined
        : isGuaranteePartiallyFilled.value
          ? `продолжить \u{2192}`
          : `оформить \u{2192}`;
    } else if (item.name === 'documents') {
      return isAddDocsPartiallyUploadedOld.value
        ? undefined
        : isAddDocsPartiallyUploadedOld.value
          ? `продолжить \u{2192}`
          : `приложить \u{2192}`;
    } else if (item.name === 'signing') {
      return !isReadyForSigning.value
        ? 'станет доступно после выполнения пунктов выше'
        : !isOrderApproved.value
          ? 'станет доступно после проверки документов'
          : isDocsSigned.value
            ? undefined
            : `проверить и подписать \u{2192}`;
    } else if (item.name === 'founder_signing') {
      return !isDocsSigned.value && !isGuaranteeAllSignedByRub.value
        ? 'станет доступно после подписи документов'
        : isGuaranteeAllSignedByRub.value
          ? undefined
          : isGuaranteePartiallySignedByRub.value
            ? `продолжить \u{2192}`
            : `подписать \u{2192}`;
    }
  };
  const whichMobileTextShown = (item) => {
    if (item.name === 'rules') {
      return isConfirmedByRouble.value ? undefined : '\u{2192}';
    } else if (item.name === 'passport') {
      return isPassportUploaded.value ? undefined : '\u{2192}';
    } else if (item.name === 'passport-manual-fill') {
      return isPassportFilled.value ? undefined : '\u{2192}';
    } else if (item.name === 'guarantee') {
      return isGuaranteeFullFilled.value ? undefined : '\u{2192}';
    } else if (item.name === 'documents') {
      return isAddDocsPartiallyUploadedOld.value ? undefined : '\u{2192}';
    } else if (item.name === 'signing') {
      return !isReadyForSigning.value
        ? 'станет доступно после выполнения пунктов выше'
        : !isOrderApproved.value
          ? 'станет доступно после проверки документов'
          : isDocsSigned.value
            ? undefined
            : `\u{2192}`;
    } else if (item.name === 'founder_signing') {
      return !isDocsSigned.value && !isGuaranteeAllSignedByRub.value
        ? 'станет доступно после подписи документов'
        : isGuaranteeAllSignedByRub.value
          ? undefined
          : '\u{2192}';
    }
  };
  const isShowIcon = (item) => {
    if (item.name === 'rules') {
      return isConfirmedByRouble.value;
    } else if (item.name === 'passport') {
      return isPassportUploaded.value;
    } else if (item.name === 'passport-manual-fill') {
      return isPassportFilled.value;
    } else if (item.name === 'guarantee') {
      return isGuaranteeFullFilled.value;
    } else if (item.name === 'documents') {
      return isAddDocsPartiallyUploadedOld.value;
    } else if (item.name === 'signing') {
      return isDocsSigned.value && isReadyForSigning.value;
    } else if (item.name === 'signing_vkl') {
      return isDocsVKLSigned.value && isReadyForSigning.value;
    } else if (item.name === 'founder_signing') {
      return isGuaranteeAllSignedByRub.value;
    }
  };

  return {
    isAutoFlow,
    isApprovedByCreditCommittee,
    isScoringStateAutoFlow,
    loanState,
    loanStateTitle,
    loanStateIcon,
    onClickGoToChosenStep,
    onClickHandleSubmit,
    closeModal,
    isShownSection,
    detectTextColor,
    whichTextShown,
    whichMobileTextShown,
    isShowIcon,
  };
}
