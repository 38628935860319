<template>
  <Loader v-if="loading" class="mt-3" />
  <template v-if="!loading">
    <div v-if="guarantors.length > 0">
      <div
        v-for="(item, index) in guarantors"
        :key="index"
        class="p-4 bg-white rounded-4 mb-6"
      >
        <div>
          <FormInput
            :text="item.full_name"
            :placeholder="'поручитель № ' + (index + 1)"
          ></FormInput>
        </div>
        <div class="divider my-4"></div>
        <div class="passport-box">
          <FormInput
            :text="statusPassportUploaded(item)"
            :placeholder="'Документы'"
          ></FormInput>
          <div
            class="btn-text btn-text-tiffany btn-passport"
            @click="openUploadPasswordModal(item)"
          >
            <span v-if="!item?.passport_uploaded"
              >заполнить {{ `\u{2192}` }}</span
            >
            <span v-if="item?.passport_uploaded"
              >обновить {{ `\u{2192}` }}</span
            >
            <i class="input-icon"></i>
          </div>
        </div>
      </div>
    </div>
    <div v-if="guarantors.length === 0">
      у выбранной компании нет поручителей
    </div>
  </template>

  <UploadPassportModal
    v-if="isPassportModalOpen"
    v-model:isPassportUploaded="isPassportUploaded"
    v-model:isPassportPartiallyUploaded="isPassportPartiallyUploaded"
    :user-name="selectedGaranter.full_name"
    :is-docs-signed="false"
    :attachable-id="selectedGaranter.id"
    :attachable-type="'Guarantor'"
    @close-modal="isPassportModalOpen = false"
  />
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
import { Loader } from 'potok-uikit';
import FormInput from '@/components/_generic/ui/inputs/FormInput.vue';
import server from '@/server/index';
import UploadPassportModal from '@/components/_generic/auth/upload-passport/UploadPassportModal.vue';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const loading = ref(false);
const isPassportModalOpen = ref(false);
const isPassportUploaded = ref(false);
const isPassportPartiallyUploaded = ref(false);

const guarantors = ref([]);
const errors = ref([]);

const selectedGaranter = ref(null);

const statusPassportUploaded = (item) => {
  if (item.person?.passport_uploaded) {
    return 'загружены';
  }
  return 'не загружены';
};

const openUploadPasswordModal = (item) => {
  selectedGaranter.value = item;
  isPassportModalOpen.value = true;
};

const getGuarantorsAsync = () => {
  loading.value = true;
  const query = {
    company_id: store.currentCompany.id,
  };
  return server.getGuarantors
    .send(query, {})
    .pipe(getGuarantorsAsyncSuccess, getGuarantorsAsyncError)
    .exec();
};
const getGuarantorsAsyncSuccess = (obj) => {
  loading.value = false;
  if (!obj) {
    return;
  }
  const data = obj.data || {};
  if (!data.person) {
    return;
  }
  data.person.forEach((el) => {
    if (el.passport) {
      el.series_number = el.passport.series_number;
      el.passport.citizenship = el.citizenship;
      el.passport.inn = el.inn;
      el.passport.series = el.passport.series_number?.slice(0, 4);
      el.passport.number = el.passport.series_number?.slice(5, 11);
    } else {
      el.passport = {};
    }
    el.full_name = el.full_name.trim();
    el.success_status = 'not upload';
  });

  data.person.forEach((el) => {
    if (
      Object.keys(el.passport).length !== 0 &&
      Object.values(el.passport).every((v) => v) &&
      el.passport_uploaded
    ) {
      el.success_status = 'success';
    } else if (
      (Object.keys(el.passport).length !== 0 &&
        Object.values(el.passport).some((v) => v)) ||
      el.passport_uploaded
    ) {
      el.success_status = 'partially';
    }
  });
  guarantors.value = data.person;
};
const getGuarantorsAsyncError = (error) => {
  errors.value = error.data?.messages;
  loading.value = false;
};

watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    getGuarantorsAsync();
  },
);

onMounted(() => {
  getGuarantorsAsync();
});
</script>
<style lang="scss" scoped>
.passport-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
