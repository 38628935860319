import { computed, ref, reactive } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import constants from '@/constants';
import server from '@/server/index';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_BKI_Parsing_success } = TRACKER_EVENTS;

export function usePassport() {
  const store = useStateStore();
  const loading = ref(false);
  const errorMsg = ref('');
  const pages = reactive({
    passport_main: [],
    passport_registration: [],
  });
  let intervalID;

  const isParsed = computed(() => {
    return (
      store.currentUserPassport.state ===
      constants.PARSING_STATES.parsed_with_success
    );
  });
  const isParsing = computed(() => {
    return (
      store.currentUserPassport.state ===
      constants.PARSING_STATES.waiting_for_parsing
    );
  });
  const isError = computed(() => {
    return (
      store.currentUserPassport.state ===
      constants.PARSING_STATES.parsed_with_failure
    );
  });
  const checkSuccessStatus = computed(() => {
    return (
      pages.passport_main.some((el) => el.success || el.filename) &&
      pages.passport_registration.some((el) => el.success || el.filename)
    );
  });

  const startPolling = () => {
    if (!intervalID) {
      intervalID = setInterval(function () {
        store.fetchUserPassport({});
      }, 5000);
    }
  };
  const stopPolling = () => {
    clearInterval(intervalID);
    intervalID = null;
  };

  const postCommandsAsync = () => {
    loading.value = true;
    const query = {
      command: {
        command_name: 'parse_passport_scans',
        resource_name: 'User',
        resource_id: store.currentUser.id,
      },
    };
    return server.postCommands
      .send(query)
      .pipe(onPostCommandsAsyncSuccess, onPostCommandsAsyncError)
      .exec();
  };
  const onPostCommandsAsyncSuccess = () => {
    store.fetchUserPassport({
      onSuccess: () => {
        loading.value = false;
        tracker.queue(tracker.commands.SEND, SPA_LK_BKI_Parsing_success);
      },
      onError: (error) => {
        loading.value = false;
        errorMsg.value = error.data?.messages?.[0];
      },
    });
  };
  const onPostCommandsAsyncError = (error) => {
    loading.value = false;
    errorMsg.value = error.data?.messages?.[0];
  };

  const getUploadedPassportsListAsync = () => {
    loading.value = true;
    const query = {
      kind: 'passport_main',
      attachable_id: store.currentUser.id,
      attachable_type: 'User',
    };
    return server.getUploadAttachments
      .send(query, {})
      .pipe(getPassportsAsyncSuccess, getPassportsAsyncError)
      .exec();
  };
  const getPassportsAsyncSuccess = ({ data }) => {
    if (!data.main && !data.registration) {
      loading.value = false;
      return;
    }
    if (data.main) {
      getPassportMainAsync(data.main);
    }
    if (data.registration) {
      getPassportRegistrationAsync(data.registration);
    }
  };
  const getPassportsAsyncError = () => {
    // не выводим ошибку, т.к. у нового пользователя не будет в базе паспорта и АПИ вернёт ошибку
    loading.value = false;
  };
  const getPassportMainAsync = (filename) => {
    const query = {
      kind: 'passport_main',
      attachable_id: store.currentUser.id,
      attachable_type: 'User',
    };
    return server.getUploadAttachments$id
      .send(query, {
        params: {
          id: store.currentUser.id,
        },
      })
      .pipe(({ data }) => {
        pages.passport_main[0] = {
          url: '',
          filename,
          success: true,
        };
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          pages.passport_main[0].url = URL.createObjectURL(data);
        }
        loading.value = false;
      }, getPassportMainAsyncError)
      .exec();
  };
  const getPassportMainAsyncError = (error) => {
    loading.value = false;
    errorMsg.value = error.data?.messages?.[0];
  };
  const getPassportRegistrationAsync = (filename) => {
    const query = {
      kind: 'passport_registration',
      attachable_id: store.currentUser.id,
      attachable_type: 'User',
    };
    return server.getUploadAttachments$id
      .send(query, {
        params: {
          id: store.currentUser.id,
        },
      })
      .pipe(({ data }) => {
        pages.passport_registration[0] = {
          url: '',
          filename,
          success: true,
        };
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          pages.passport_registration[0].url = URL.createObjectURL(data);
        }
        loading.value = false;
      }, getPassportRegistrationAsyncError)
      .exec();
  };
  const getPassportRegistrationAsyncError = (error) => {
    loading.value = false;
    errorMsg.value = error.data?.messages?.[0];
  };
  return {
    pages,
    loading,
    errorMsg,
    isParsed,
    isParsing,
    isError,
    checkSuccessStatus,
    startPolling,
    stopPolling,
    postCommandsAsync,
    getUploadedPassportsListAsync,
  };
}
