<template>
  <div>
    <div
      v-if="store.isVKLFirstTranche"
      class="notices-block potok-text-body-1 d-flex align-items-center mb-6"
    >
      <i class="icon-clock me-3" />
      наш менеджер свяжется с вами
    </div>

    <div
      v-if="
        !store.currentOrder.need_to_new_amount_confirm &&
        !store.currentOrder.confirmed_by_sms_at
      "
      class="white-block sign-transh"
    >
      <div>
        <div class="potok-text-h2">мы готовы выдать транш</div>
        <div class="potok-text-body-1 mt-2">подпишите документы</div>
      </div>
      <div class="sign-transh-right">
        <PotokButton
          :disabled="isNeedAdditionalDocsUpload || isNeedUploadStatementFile"
          text="подписать"
          size="medium"
          @click="emit('run-action', { action: 'signing_vkl' })"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { useStateStore } from '@/stores/stateStore';
import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';

const store = useStateStore();
const emit = defineEmits(['run-action']);

const { isNeedAdditionalDocsUpload, isNeedUploadStatementFile } =
  loanCardShared();
</script>
<style></style>
