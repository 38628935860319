<template>
  <div class="w-100 m-auto">
    <h1 class="text-title text-center">
      подтвердите номер и <br />подпишите публичную оферту
    </h1>
    <div v-if="!isStartConfirm" class="text-desc-wrapper">
      <div class="text-desc text-center">
        подтвердите номер телефона <br />{{ props.inputedPhone }} кодом из смс
      </div>
    </div>
  </div>
  <div class="form">
    <div v-if="isStartConfirm" class="form-row">
      <PhoneConfirmation
        :left-time="leftTime"
        :loading="loading"
        :errors-arr="errors"
        :phone="props.inputedPhone"
        title=""
        @confirm-otp-async="confirmOtpAsync"
        @send-otp-async="sendOtpAsync"
        @clear-errors="clearErrors"
      />
    </div>
    <div class="form-row input-with-icon position-relative">
      <div v-if="!isStartConfirm" class="actions-panel">
        <PotokButton
          v-if="!loading"
          id="confirm"
          class="w-100"
          text="получить код"
          size="large"
          @click="onClickStartConfirm()"
        />

        <LoadingBtn v-else />

        <div class="form-row mt-6">
          <div class="h6 row-link fw-normal text-center mt-6">
            <button
              class="btn-text color-grey"
              :disabled="loading"
              @click="onClickGoBack"
            >
              назад1
            </button>
          </div>
        </div>
      </div>
      <div v-if="isStartConfirm" class="form-row mt-6">
        <div class="h6 row-link fw-normal text-center mt-6">
          <button
            class="btn-text color-grey"
            :disabled="loading"
            @click="onClickGoBackMode"
          >
            назад
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row text-condition">
    <div class="agreement-text text-center">
      регистрируясь, вы соглашаетесь на обработку
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
        target="_blank"
        >персональных данных</a
      >
      в соответствии с
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
        target="_blank"
        >политикой</a
      >, ознакомлены с
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Правила Инвестиционной платформы Поток.pdf"
        target="_blank"
        >правилами платформы</a
      >, принимаете
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/2023 02 27_Поток Диджитал_Агентский договор_с изменениями 2.docx.pdf"
        target="_blank"
        >публичную оферту платформы</a
      >
      и согласны с использованием
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Соглашение участников электронного взаимодействия_Инвестплатформа Поток.pdf"
        target="_blank"
        >простой электронной подписи</a
      >
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { ref, onMounted } from 'vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import PhoneConfirmation from '@/components/_generic/unauth/PhoneConfirmation.vue';
import Countdown from '@/modules/Countdown';
import server from '@/server/index';

const emit = defineEmits(['change-step']);
const props = defineProps({
  inputedPhone: {
    type: String,
    default: '',
  },
});
const loading = ref(false);
const errors = ref([]);
const isStartConfirm = ref(false);
const countdown = new Countdown();
const leftTime = ref(0);

const onClickGoBack = () => {
  emit('change-step', {
    step: 1,
  });
};

const onClickStartConfirm = () => {
  isStartConfirm.value = true;
  loading.value = true;
  return server.postRequestOtp$Phone
    .send()
    .pipe(onSendOtpAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};

const clearErrors = () => {
  errors.value = [];
};

const sendOtpAsync = () => {
  loading.value = true;
  return server.postRequestOtp$Phone
    .send()
    .pipe(onSendOtpAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};
const onSendOtpAsyncSuccess = () => {
  countdown.reset();
  countdown.start();
  loading.value = false;
};

const confirmOtpAsync = (rawOtp) => {
  if (!rawOtp || rawOtp.length !== 4) {
    errors.value = ['введите код из смс'];
    return;
  }
  const query = {
    otp: rawOtp,
  };
  loading.value = true;
  return server.postUsersConfirmOtp
    .send(query)
    .pipe(onConfirmOtpAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};
const onConfirmOtpAsyncSuccess = () => {
  loading.value = false;
  emit('change-step', {
    step: 3,
  });
};

const onClickGoBackMode = () => {
  isStartConfirm.value = false;
};

onMounted(() => {
  countdown.onTick = (val) => {
    leftTime.value = val;
  };
});
</script>
<style lang="scss" scoped>
.text-desc {
  max-width: 369px;
}
.text-desc-wrapper {
  padding-top: 5px;
}
.actions-panel {
  margin-top: 30px;
}
.text-condition {
  margin-top: 242px;
  margin-bottom: 70px;
  max-width: 514px;
  margin-left: auto;
  margin-right: auto;
  color: $grey;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.12px;
}
</style>
