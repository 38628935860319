<template>
  <div class="white-block graphs-item">
    <div class="block-title no_mobile">
      арбитражи и открытые исполнительные производства
    </div>
    <div class="block-title only_mobile">сумма арбитражей</div>
    <div class="court">
      <template v-if="isCourtExists">
        <div class="court-item">
          <div class="court-title d-flex">
            <div class="no_mobile me-1">
              сумма арбитражей на рассмотрении за последний год
            </div>
            <div class="only_mobile me-1">сумма арбитражей на рассмотрении</div>
            <PopoverIcon position="left_center" width="329px">
              <template #icon
                ><span class="question-icon-black"></span
              ></template>
              <template #text>
                арбитражи — это сумма исковых требований в арбитражных делах, в
                которых заемщик участвовал в качестве ответчика. Учитываем
                проигранные дела, в том числе — частично, за последний год.
              </template>
            </PopoverIcon>
          </div>
          <div class="court-graph">
            <div
              class="court-graph-value"
              :style="{ width: styleExecutiveProduction }"
            ></div>
          </div>
          <div class="court-graph-desc">
            <div class="court-graph-desc-proc">{{ executiveProduction }}%</div>
            <div class="court-graph-desc-value">
              {{ formatCurrency(totalAvarage6m, 0) }} ₽
            </div>
          </div>
        </div>
        <div class="court-item">
          <div class="court-title">
            сумма открытых исполнительных производств
            <PopoverIcon position="left_center" width="303px">
              <template #icon
                ><span class="question-icon-black"></span
              ></template>
              <template #text>
                исполнительные производства — это сумма задолженности заемщика в
                действующих исполнительных производствах.
              </template>
            </PopoverIcon>
          </div>
          <div class="court-graph">
            <div
              class="court-graph-value"
              :style="{
                width: stylePendingArbitration,
              }"
            ></div>
          </div>
          <div class="court-graph-desc">
            <div class="court-graph-desc-proc">{{ pendingArbitration }}%</div>
            <div class="court-graph-desc-value">
              {{ formatCurrency(totalAvarage6m, 0) }} ₽
            </div>
          </div>
        </div>
        <div class="court-desc">
          <div class="court-desc-item">
            <div class="icon-dole"></div>
            <div>доля арбитражей/производств</div>
          </div>
          <div class="court-desc-item">
            <div class="icon-cko"></div>
            <div>среднемесячные ЧКО за 6 месяцев</div>
          </div>
        </div>
      </template>
      <template v-if="!isCourtExists">
        <div class="court-item">
          <div class="court-title" style="max-width: 371px">
            у вашей компании нет арбитражных и открытых исполнительных
            производств
          </div>
          <div class="empty-wrapper">
            <div class="empty-wrapper-item">
              <div class="empty-wrapper-title">0%</div>
              <div class="court-title mt-2">
                доля арбитражей на рассмотрении
              </div>
            </div>
            <div class="empty-wrapper-item">
              <div class="empty-wrapper-title">0%</div>
              <div class="court-title mt-2">
                доля исполнительных производств
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { formatCurrency } from '@/utils';
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const isCourtExists = computed(() => {
  return (
    store.currentCompany?.legal_info?.pending_arbitration_amount_m12 &&
    store.currentCompany?.legal_info?.executive_production_amount_m12
  );
});
const totalAvarage6m = computed(() => {
  if (!store.currentCompany.income_avg6m) {
    return 0;
  }
  return store.currentCompany.income_avg6m;
});

const pendingArbitration = computed(() => {
  if (
    !store.currentCompany ||
    !store.currentCompany.legal_info ||
    !totalAvarage6m.value
  ) {
    return 0;
  }
  return Math.round(
    (store.currentCompany.legal_info.pending_arbitration_amount_m12 * 100) /
      totalAvarage6m.value,
  );
});

const stylePendingArbitration = computed(() => {
  if (pendingArbitration.value > 100) {
    return '100%';
  }
  if (pendingArbitration.value < 0) {
    return '0%';
  }
  return `${pendingArbitration.value}%`;
});

const executiveProduction = computed(() => {
  if (
    !store.currentCompany ||
    !store.currentCompany.legal_info ||
    !totalAvarage6m.value
  ) {
    return 0;
  }
  return Math.round(
    (store.currentCompany.legal_info.executive_production_amount_m12 * 100) /
      totalAvarage6m.value,
  );
});

const styleExecutiveProduction = computed(() => {
  if (executiveProduction.value > 100) {
    return '100%';
  }
  if (executiveProduction.value < 0) {
    return '0%';
  }
  return `${executiveProduction.value}%`;
});
</script>
<style lang="scss" scoped>
.court {
  margin-top: 20px;
}
.court-item {
  margin-top: 10px;
}
.court-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $grey;
}
.court-graph {
  width: 100%;
  background-color: $breakwater;
  height: 7px;
  border-radius: 9.5px;
  margin-top: 9px;
}
.court-graph-value {
  background-color: $red;
  height: 7px;
  border-radius: 9.5px;
}
.court-graph-desc {
  display: flex;
  justify-content: space-between;
  margin-top: 9px;
}
.court-graph-desc-proc {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  text-transform: lowercase;
  color: $red;
}
.court-graph-desc-value {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: right;
  text-transform: lowercase;
  color: $dipedive;
}
.court-desc {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $grey;
}
.court-desc-item {
  display: flex;
  align-items: center;
}
.icon-dole {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $red;
  margin-right: 10px;
}
.icon-cko {
  flex-shrink: 0;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $dipedive;
  margin-right: 10px;
}
.empty-wrapper {
  margin-top: 60px;
  display: flex;
  flex-wrap: wrap;
}
.empty-wrapper-item {
  max-width: 150px;
  margin-right: 60px;
  @media (max-width: $size_767) {
    max-width: initial;
    margin-right: initial;
    margin-top: 20px;
  }
}
.empty-wrapper-title {
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2px;
  text-transform: lowercase;
  color: $tiffany;
}
</style>
