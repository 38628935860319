<template>
  <div>
    <canvas ref="canvas"></canvas>
  </div>
</template>

<script setup>
import QRCode from 'qrcode/lib/browser';
import { watch, onMounted, ref } from 'vue';

const canvas = ref();

const props = defineProps({
  payeeName: {
    type: String,
    default: '',
  },
  payeeInn: {
    type: String,
    default: '',
  },
  payeeKpp: {
    type: String,
    default: '',
  },
  payeePersonalAcc: {
    type: String,
    default: '',
  },
  payeeBankName: {
    type: String,
    default: '',
  },
  payeeBankBic: {
    type: String,
    default: '',
  },
  payeeBankCorrespAcc: {
    type: String,
    default: '',
  },
  purpose: {
    type: String,
    default: '',
  },
  sum: {
    type: [String, Number],
    default: 0,
  },
  width: {
    type: [String, Number],
    default: 'auto',
  },
});

watch(
  () => props,
  () => renderPayment(),
  { deep: true },
);

onMounted(() => renderPayment());

const mapToQRPaymentString = (descriptor) => {
  const {
    payeeName,
    payeeInn,
    payeeKpp,
    payeePersonalAcc,
    payeeBankName,
    payeeBankBic,
    payeeBankCorrespAcc,
    sum,
    purpose,
  } = descriptor;
  return [
    `ST00012`,
    `Name=${payeeName}`,
    `PersonalAcc=${payeePersonalAcc}`,
    `BankName=${payeeBankName}`,
    `BIC=${payeeBankBic}`,
    `CorrespAcc=${payeeBankCorrespAcc}`,
    `Purpose=${purpose}`,
    `Sum=${sum}`,
    `PayeeINN=${payeeInn}`,
    `KPP=${payeeKpp}`,
    `QR_TYPE=02`,
  ].join('|');
};

const renderPayment = () => {
  const str = mapToQRPaymentString(props);
  let options = {};
  if (props.width) {
    options.width = props.width;
    options.margin = 0;
  }
  QRCode.toCanvas(canvas.value, str, options);
};
</script>
