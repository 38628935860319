import common from './common';
import magicLink from './magic-link';
import redirections from './redirections';

const routes = [
  ...common,
  ...magicLink,
  ...redirections,
  {
    path: '/:catchAll(.*)',
    redirect: '/dashboard',
  },
];

export default routes;
