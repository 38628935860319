import YandexMetrikaTracker from './YandexMetrikaTracker';
import AmplitudeTracker from './AmplitudeTracker';

export default class Tracker {
  constructor({ ymtOptions, amplOptions, isProduction }) {
    this.ymt = ymtOptions
      ? new YandexMetrikaTracker(ymtOptions, isProduction)
      : null;

    this.ampl = amplOptions
      ? new AmplitudeTracker(amplOptions, isProduction)
      : null;

    this.commands = {
      SEND: Symbol('SEND'),
      SET: Symbol('SET'),
      PARAMS: Symbol('PARAMS'),
    };
  }
  /**
   * Queues a command
   * @param {String} command
   * @param {...*} [opts]
   */
  queue(command, ...opts) {
    switch (command) {
      case this.commands.SET:
        // @see https://yandex.ru/support/metrika/objects/hit.html
        this.ymt && this.ymt.queue('hit', ...opts);
        break;
      case this.commands.SEND:
        // @see https://yandex.ru/support/metrika/objects/reachgoal.html
        this.ymt && this.ymt.queue('reachGoal', ...opts);
        // @see https://developers.amplitude.com/docs/tracking-events
        this.ampl && this.ampl.queue('logEvent', opts[0]);
        break;
      case this.commands.PARAMS:
        // @see https://yandex.ru/support/metrica/objects/params-method.html
        this.ymt && this.ymt.queue('params', ...opts);
        // @see https://www.docs.developers.amplitude.com/data/sdks/javascript/
        this.ampl && this.ampl.queue('setUserProperties', ...opts);
        break;
      default:
        throw new Error('Unsupported command');
    }
  }
}
