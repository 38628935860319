<template>
  <div class="inputed-address-wrapper">
    <input
      v-model="inputedAddress"
      class="address-input"
      type="text"
      :placeholder="props.placeholder"
      :disabled="props.disabled"
      @input="debouncedFn()"
    />
    <SuggestionList
      v-model="suggestions"
      class="mt-2"
      width="100%"
      :keys-in-arr="['address']"
      @choose-suggestion="chooseSuggestion"
      @update:model-value="updateSuggestions"
    />
  </div>
</template>
<script setup>
import { ref, onMounted, nextTick, watch } from 'vue';
import SuggestionList from '@/components/_generic/ui/widgets/SuggestionList.vue';
import server from '@/server/index';
import { useDebounceFn } from '@vueuse/core';

const model = defineModel({
  type: String,
});

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
});

const suggestions = ref([]);
const inputedAddress = ref(null);

const debouncedFn = useDebounceFn(() => {
  postDadataAddressAsync();
}, 500);
const postDadataAddressAsync = () => {
  const query = {
    query: inputedAddress.value,
  };
  return server.postDadataAddress
    .send(query)
    .pipe(onPostDadataAddressAsyncSuccess, () => {})
    .exec();
};
const onPostDadataAddressAsyncSuccess = ({ data }) => {
  data.suggestions.forEach(function (array_item, index) {
    suggestions.value[index] = { address: array_item.value };
  });
};

const chooseSuggestion = (suggestion) => {
  nextTick(() => {
    inputedAddress.value = suggestion.address;
    model.value = suggestion.address;
  });
};

const updateSuggestions = () => {
  if (inputedAddress.value) {
    inputedAddress.value = model.value;
  }
};

onMounted(() => {
  inputedAddress.value = model.value;
});

watch(
  () => {
    return model.value;
  },
  () => {
    inputedAddress.value = model.value;
  },
);
</script>
<style lang="scss" scoped>
.address-input {
  @include input;
  padding-right: 20px;
}
</style>
