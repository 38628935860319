<template>
  <ModalFull @close-modal="onClickCloseModal">
    <template #content>
      <div class="modal-feedback-wrapper">
        <div class="modal-feedback-desc color-dipedive">
          <div class="potok-text-body-2-bold text-uppercase">
            ПРЕДЛОЖИТЕ ВАШ ВАРИАНТ УСЛОВИЙ ПО ЗАЙМУ
          </div>
          <div class="potok-text-body-2 mt-4">
            Заполните форму, если знаете оптимальные для вас условия по займу.
            Ваше предложение будет рассмотрено в индивидуальном порядке. Можно
            заполнить одно или несколько полей. При наличии альтернативных
            предложений вы можете загрузить подтверждающие документы.
          </div>
        </div>
        <div>
          <div>
            <PotokInputSmall
              v-model="form.rate"
              :data-maska="masks.rate()"
              :keyup="onChange"
              :placeholder="'ставка годовых'"
              class="mt-4"
            ></PotokInputSmall>
          </div>
          <div>
            <PotokInputSmall
              v-model="form.amount"
              :data-maska="masks.digits(12)"
              :keyup="onChange"
              :placeholder="'сумма'"
              class="mt-4"
            ></PotokInputSmall>
          </div>
          <div>
            <PotokInputSmall
              v-model="form.loan_period"
              :data-maska="masks.digits(3)"
              :placeholder="'максимальный срок, мес'"
              :keyup="onChange"
              class="mt-4"
            >
            </PotokInputSmall>
          </div>
          <div>
            <PotokInputSmall
              v-model="form.regular_payment_amount"
              :data-maska="masks.digits(8)"
              :placeholder="'ежемесячный платеж'"
              :keyup="onChange"
              class="mt-4"
            ></PotokInputSmall>
          </div>
          <div>
            <PotokTextArea
              v-model="form.other_comment"
              class="mt-4"
              :placeholder="'другое'"
              :keyup="onChange"
            ></PotokTextArea>
          </div>
          <FileUpload
            ref="supportUpload"
            v-model="files"
            name="file"
            input-id="support_file"
            :maximum="5"
            :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
          >
            <div class="upload-btn d-flex align-items-center mt-4">
              <i class="clip-icon me-2"></i>загрузите документ
            </div>
          </FileUpload>
        </div>
        <div class="d-flex align-items-center mb-3">
          <template v-for="(file, index) in files" :key="index">
            <div class="uploaded-file me-2">
              <i class="remove-uploaded" @click="onClickRemoveFile(index)"></i>
            </div>
          </template>
        </div>
        <div class="text-danger">{{ errorMessage }}</div>
        <div class="actions-block mt-6">
          <PotokButton
            :disabled="loading"
            class="w-100"
            size="large"
            theme="primary"
            :text="'отправить'"
            @click="onClickAction"
          />
          <PotokButton
            :disabled="loading"
            class="button button-cancel mt-2 w-100"
            size="large"
            theme="tertiary"
            :text="'отмена'"
            @click="onClickCloseModal"
          />
        </div>
      </div>
    </template>
  </ModalFull>
</template>
<script setup>
import { PotokButton, PotokInputSmall, PotokTextArea } from 'potok-uikit';
import { ref } from 'vue';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import FileUpload from 'vue-upload-component';
import constants from '@/constants';
import masks from '@/libs/js-text-masks';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';
import tracker from '@/tracker';

const emit = defineEmits(['close-modal', 'run-action']);
const store = useStateStore();

const loading = ref(false);
const supportUpload = ref();

const form = ref({
  rate: '',
  amount: null,
  loan_period: null,
  regular_payment_amount: null,
  other_comment: '',
});

const errorMessage = ref(null);

const files = ref([]);

const onClickCloseModal = () => {
  return emit('close-modal');
};
const onClickAction = () => {
  if (
    !form.value.rate &&
    !form.value.amount &&
    !form.value.loan_period &&
    !form.value.regular_payment_amount &&
    !form.value.other_comment
  ) {
    errorMessage.value = 'нужно заполнить хотя бы одно поле';
    return;
  }
  tracker.queue(tracker.commands.SEND, 'SPA_LK_VKL_Conditions_Feedback_send');
  postFeedback();
};

const onClickRemoveFile = (index) => {
  files.value.splice(index, 1);
};

const onChange = () => {
  if (errorMessage.value) {
    errorMessage.value = null;
  }
};

const postFeedback = () => {
  loading.value = true;
  const query = {
    order_id: store.currentOrderId,
    kind: 'loan_terms',
  };
  if (form.value.amount) {
    query.amount = form.value.amount;
  }
  if (form.value.loan_period) {
    query.loan_period = form.value.loan_period;
  }
  if (form.value.other_comment) {
    query.other_comment = form.value.other_comment;
  }
  if (form.value.rate) {
    query.rate = form.value.rate;
  }
  if (form.value.regular_payment_amount) {
    query.regular_payment_amount = form.value.regular_payment_amount;
  }
  return server.postFeedback
    .send(query, {
      params: {
        company_id: store.currentCompany.id,
      },
    })
    .pipe(onPostFeedbackSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onPostFeedbackSuccess = (data) => {
  loading.value = false;
  const result = data.data || {};
  if (!result.id) {
    return;
  }
  if (files.value.length > 0) {
    postUserAttachmentsAsync(result.id);
  } else {
    closeFeedbackFormAfterSending();
  }
};

const postUserAttachmentsAsync = (attachable_id) => {
  loading.value = true;
  Promise.all(
    files.value.map((item) => {
      let formData = new FormData();
      formData.append('kind', 'feedback_document');
      formData.append('attachable_id', attachable_id);
      formData.append('attachable_type', 'Feedback');
      formData.append('file', item.file);
      return server.postAttachments
        .send(formData)
        .pipe(
          () => {},
          () => {},
        )
        .exec();
    }),
  );
  loading.value = false;
  closeFeedbackFormAfterSending();
};

const closeFeedbackFormAfterSending = () => {
  store.currentOrder.feedback_allowed = false;
  store.setNotification({
    message: 'благодарим за обратную связь, ваше сообщение отправлено',
  });
  emit('close-modal');
};
</script>
<style lang="scss" scoped>
.modal-feedback-wrapper {
  max-width: 340px;
  @media (max-width: $size_767) {
    max-width: initial;
  }
}

.modal-feedback-desc {
  text-align: center;
}

.upload-btn {
  cursor: pointer;
  color: $tiffany;

  &:hover .clip-icon {
    filter: $filter-tiffany;
  }

  .clip-icon {
    width: 18px;
    height: 18px;
    background-image: url(@/assets/icons/clip-green.svg);
    background-repeat: no-repeat;
    background-position: center;
    color: $tiffany;
  }

  label {
    cursor: pointer;
  }
}

.uploaded-file {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid $input;
  background-image: url(@/assets/icons/files/file-uploads-sm-void.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:hover .remove-uploaded {
    display: block;
  }
}

.remove-uploaded {
  display: none;
  position: absolute;
  right: -5px;
  top: -5px;
  width: 16px;
  height: 16px;
  background-image: url(@/assets/icons/exits/close-icon-rounded.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}
</style>
