<template>
  <div class="white-block graphs-item">
    <div class="block-title">
      <div>чистые кредитовые обороты (ЧКО)</div>
      <div v-if="isHold" class="hold-reason">ПРИЧИНА БЛОКИРОВКИ</div>
    </div>
    <template v-if="store.companies.loading">
      <Loader class="mt-4" />
      <div class="graph-empty-block"></div>
    </template>
    <template v-if="!store.companies.loading">
      <template v-if="!props.isStatementLoaded">
        <div class="desc-text">
          {{ MESSAGE_LOAD_REQUIRED }}
        </div>
        <div class="graph-empty-block">
          <PotokButton
            text="загрузить выписку"
            class="button-redirect"
            size="large"
            theme="secondary"
            @click="onClickRedirectLoadStatement()"
          />
        </div>
      </template>
      <template v-if="props.isStatementLoaded">
        <template v-if="!props.isCalculationFinished">
          <div class="desc-text">
            {{ MESSAGE_WAIT_CALCULATION }}
          </div>
          <div class="graph-empty-block"></div>
        </template>
        <template v-if="props.isCalculationFinished">
          <div class="graph-wrapper">
            <template v-if="props.items.length > 0">
              <Bar
                v-if="loaded"
                :data="chartData"
                :options="chartOptions"
                class="mt-4"
              />
              <div class="legend-cko-wrapper">
                <div class="legend-cko">
                  <div></div>
                  ЧКО по месяцам, ₽
                </div>
                <div class="legend-itogo">
                  всего за {{ props.items.length }} мес. —
                  <span>{{ formatCurrency(totalForPeriod, 0) }} ₽</span>
                </div>
              </div>
            </template>
            <template v-if="props.items.length === 0">
              <div class="graph-wrapper-empty">
                <div class="pt-6">данных для графика нет</div>
              </div>
            </template>
          </div>
        </template>
      </template>
    </template>
  </div>
</template>
<script setup>
import { PotokButton, Loader } from 'potok-uikit';

import { ref, computed, watch, nextTick, onMounted } from 'vue';
import { Chart, registerables } from 'chart.js';
import { Bar } from 'vue-chartjs';

import { formatCurrency } from '@/utils';
import analyticUtils from './analyticUtils.js';

import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

Chart.register(...registerables);

const {
  MESSAGE_WAIT_CALCULATION,
  MESSAGE_LOAD_REQUIRED,
  getLabels,
  totalProfitForPeriod,
  onClickRedirectLoadStatement,
} = analyticUtils();

const props = defineProps({
  items: {
    type: Array,
    default: () => {
      return [];
    },
  },
  isStatementLoaded: {
    type: Boolean,
    default: false,
  },
  isCalculationFinished: {
    type: Boolean,
    default: false,
  },
});

const labels = ref([]);
const graphData = ref([]);
const avarageForPeriod = ref([]);
const loaded = ref(false);

const isHold = computed(() => {
  return (
    store.currentOrder?.loan_terms_offer &&
    store.currentOrder?.hold_manual_scoring_reasons &&
    store.currentOrder?.hold_manual_scoring_reasons.find((i) => {
      return i.reason_name === 'pre_statements_validation';
    })
  );
});

const totalForPeriod = computed(() => {
  return totalProfitForPeriod(props.items);
});

const calcLabels = () => {
  const data = getLabels(props.items);
  labels.value = data;
};

const calcGraphData = () => {
  const data = props.items.map((item) => {
    let value = 0;
    value += item?.top1_customer?.amount;
    value += item?.top2_customer?.amount;
    value += item?.top3_customer?.amount;
    value += item?.other_customers?.amount;
    return value;
  });
  graphData.value = data;
};

const calcAvarageForPeriod = () => {
  const data = props.items.map(() => {
    return Math.round(totalForPeriod.value / props.items.length);
  });
  avarageForPeriod.value = data;
};

const chartOptions = {
  responsive: true,
  scaleShowValues: true,
  interaction: {
    mode: 'index',
    intersect: false,
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      callbacks: {
        title: (value) => {
          if (!value[0]) {
            return '';
          }
          let title = value[0].label;
          if (title) {
            title = title.replace(',', ' ');
          }
          return title;
        },
      },
    },
  },
  elements: {
    point: {
      pointStyle: 'circle',
      radius: 0,
      borderWidth: 2,
    },
    line: {
      borderWidth: 2,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        display: false,
      },
      ticks: {
        color: '#818E99',
        font: {
          size: 8,
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        autoSkip: false,
        maxRotation: 0,
        minRotation: 0,
        color: '#818E99',
        font: {
          size: 8,
        },
      },
    },
  },
};

const chartData = {
  labels: labels.value,
  datasets: [
    {
      label: 'ЧКО по месяцам, ₽',
      data: graphData.value,
      borderColor: '#173B57',
      backgroundColor: '#173B57',
      borderRadius: 4,
      order: 1,
    },
    {
      label: `средняя выручка, ₽`,
      data: avarageForPeriod.value,
      borderColor: '#FFC11E',
      backgroundColor: '#FFC11E',
      fill: false,
      type: 'line',
      order: 0,
    },
  ],
};

const updateGraph = () => {
  calcLabels();
  calcGraphData();
  calcAvarageForPeriod();
  loaded.value = false;
  nextTick(() => {
    chartData.labels = labels.value;
    chartData.datasets[0].data = graphData.value;
    chartData.datasets[1].data = avarageForPeriod.value;
    chartData.datasets[1].label = `средняя выручка за ${props.items.length} мес., ₽`;
    loaded.value = true;
  });
};

watch(
  () => {
    return props.items;
  },
  () => {
    updateGraph();
  },
);
onMounted(() => {
  updateGraph();
});
</script>
<style lang="scss" scoped>
.block-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.text-statement {
  text-align: right;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: $grey;
}
.graphs-item {
  margin-right: 30px;
  width: 100%;
  &:last-child {
    margin-right: 0;
  }
  @media (max-width: $size_767) {
    margin-right: initial;
  }
}

.graph-wrapper {
  min-height: 248px;
}
.graph-wrapper-empty {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.legend-cko-wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $grey;
  margin-top: 20px;

  .legend-cko {
    display: flex;
    align-items: center;
    div {
      width: 10px;
      height: 10px;
      background-color: $dipedive;
      border-radius: 50%;
      margin-right: 6px;
    }
  }
  .legend-itogo {
    span {
      color: $dipedive;
    }
  }
}
</style>
