<template>
  <div class="page-title">{{ store.currentCompany.short_title }}</div>

  <div class="content">
    <div class="content-left">
      <div class="menu-tab potok-text-body-2">
        <div
          class="menu-tab-item"
          :class="{
            'menu-tab-item-active': selectedMenuTab === 'manager',
          }"
          @click="selectedMenuTab = 'manager'"
        >
          руководитель
        </div>
        <div
          class="menu-tab-item"
          :class="{
            'menu-tab-item-active': selectedMenuTab === 'founders',
          }"
          @click="selectedMenuTab = 'founders'"
        >
          учредители
        </div>
        <div
          class="menu-tab-item"
          :class="{
            'menu-tab-item-active': selectedMenuTab === 'garanters',
          }"
          @click="selectedMenuTab = 'garanters'"
        >
          поручители
        </div>
      </div>
      <component :is="currentComponent"></component>
    </div>
    <div class="content-right">
      <div class="content-right-wrapper p-4 bg-white rounded-4 mt-6">
        <i class="icon-house me-2"></i>
        <span v-if="companiesCount > 1">мои компании</span>
        <span v-if="companiesCount <= 1">моя компания</span>
        &nbsp;{{ companiesCount }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import ProfileManager from '@/components/auth/profile/ProfileManager.vue';
import ProfileFounders from '@/components/auth/profile/ProfileFounders.vue';
import ProfileGaranters from '@/components/auth/profile/ProfileGaranters.vue';

const store = useStateStore();

const selectedMenuTab = ref('manager');
const selectedCompany = ref({});

const currentComponent = computed(() => {
  if (selectedMenuTab.value === 'manager') {
    return ProfileManager;
  }
  if (selectedMenuTab.value === 'founders') {
    return ProfileFounders;
  }
  return ProfileGaranters;
});

const companiesCount = computed(() => {
  return store.companies.items.filter((i) => i.id).length;
});

onMounted(() => {
  selectedCompany.value = store.currentCompany;
});
</script>
<style lang="scss" scoped>
.menu-tab {
  display: flex;
  width: 100%;
  border-bottom: 2px solid $input;
  margin-top: 30px;
  margin-bottom: 30px;
}
.menu-tab-item {
  margin-right: 30px;
  cursor: pointer;
  color: $grey;
  padding-bottom: 11px;
}
.menu-tab-item-active {
  color: $dipedive !important;
  border-bottom: 2px solid $dipedive;
  margin-bottom: -2px;
}
.content {
  display: flex;
}
.content-left {
  flex: 2;
}
.content-right {
  flex: 1;
  padding-left: 20px;
}
.content-right-wrapper {
  height: 84px;
  display: flex;
  align-items: center;
}
</style>
