import server from '@/server/index';
import { ref } from 'vue';
import { useStateStore } from '@/stores/stateStore';

export function paymentUtils() {
  const store = useStateStore();

  const payments = ref([]);
  const loading = ref(false);
  const page = ref(1);
  const per = ref(10);
  const totalPages = ref(0);

  const getCommonSchedulePayments = (options) => {
    const query = {
      company_id: options.companyId,
      page: options.currentPage.value,
      per: options.perPage.value,
    };
    loading.value = true;
    return server.getCommonSchedulePayments
      .send(query, {})
      .pipe(onGetCommonSchedulePaymentsSuccess, () => {
        loading.value = false;
      })
      .exec();
  };

  const onGetCommonSchedulePaymentsSuccess = ({ response, data }) => {
    loading.value = false;
    totalPages.value = response.headers.get('x-totalpages');
    page.value === 1
      ? (payments.value = data)
      : (payments.value = [...payments.value, ...data]);
  };

  const getAmountPayment = (item) => {
    let amount = 0;
    if (item.status === 'REFUNDED') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    if (item.status === 'REFUNDED_AFTER_OVERDUE') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        amount += payment.fine;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    if (item.status === 'OVERDUE') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        amount += payment.fine;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    if (item.status === 'AWAIT') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        amount += payment.ndfl;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    if (item.status === 'ACTIVE') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    return amount;
  };
  const getStatusTitlePayment = (payment) => {
    const statuses = {
      REFUNDED: 'выплачен',
      REFUNDED_AFTER_OVERDUE: 'выплачен после просрочки',
      AWAIT: 'следующий платеж',
      OVERDUE: 'просрочен',
      ACTIVE: 'следующий платеж',
    };
    return statuses[payment.status];
  };
  const getStatusClassPayment = (payment) => {
    const statuses = {
      REFUNDED: 'payment-item-icon-green',
      REFUNDED_AFTER_OVERDUE: 'payment-item-icon-yellow',
      OVERDUE: 'payment-item-icon-red',
      AWAIT: 'payment-item-icon-default',
      ACTIVE: 'payment-item-icon-default',
    };
    return statuses[payment.status];
  };

  return {
    payments,
    loading,
    page,
    per,
    totalPages,
    getCommonSchedulePayments,
    getAmountPayment,
    getStatusTitlePayment,
    getStatusClassPayment,
  };
}
