<template>
  <ModalFull @close-modal="onClickCloseModal">
    <template #title
      ><span class="fw-normal">
        Поздравляем, вы прошли<br />
        все шаги оформления
      </span>
    </template>
    <template #content>
      <div class="mx-auto text-center" style="width: 315px">
        <span>
          прежде чем деньги поступят на ваш счет, наши специалисты проверят
          документы. нажмите “подтвердить”, чтобы завершить оформление.
        </span>
        <span class="mt-3 d-inline-block">
          по всем вопросам вы можете связаться с поддержкой по email
          ok@potok.team или по кнопке “Написать в поддержку” в правом углу
          экрана.
        </span>
      </div>
      <div class="d-block d-sm-flex">
        <PotokButton
          v-if="!loading"
          class="mt-6 me-2"
          text="подтвердить"
          size="medium"
          @click="postCommandsAsync"
        />
        <LoadingBtn
          v-if="loading"
          class="mt-6 me-2"
          padding="20px 0"
          margin="13px"
        />
        <PotokButton
          class="mt-3 mt-sm-6"
          text="отменить"
          size="medium"
          theme="tertiary"
          @click="onClickCloseModal"
        />
      </div>
      <ErrorItem
        v-if="errors.length !== 0"
        v-model="errors"
        class="error text-center mx-auto mt-4"
      />
    </template>
  </ModalFull>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { ref, inject, toRefs } from 'vue';
import server from '@/server/index';

const emit = defineEmits(['close-modal', 'get-orders-async']);
const emitter = inject('emitter');

const loading = ref(false);
const errors = ref([]);

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
});
const { order } = toRefs(props);

const onClickCloseModal = () => {
  emit('close-modal');
};
const postCommandsAsync = () => {
  loading.value = true;
  const query = {
    command: {
      command_name: 'start_investing',
      resource_name: 'Order',
      resource_id: order.value.id,
      confirmed: true,
    },
  };
  return server.postCommands
    .send(query)
    .pipe(onPostCommandsAsyncSuccess, onPostCommandsAsyncError)
    .exec();
};
const onPostCommandsAsyncSuccess = () => {
  loading.value = false;
  emitter.emit('to-dashboard');
};
const onPostCommandsAsyncError = (error) => {
  loading.value = false;
  errors.value = error.data?.messages;
};
</script>

<style lang="scss" scoped>
.error {
  max-width: 350px;
}
</style>
