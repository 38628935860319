<template>
  <swiper :slides-per-view="'auto'" :space-between="25">
    <swiper-slide style="width: 90%">
      <CHKO_1
        :items="props.turnoversFiltered"
        :is-calculation-finished="props.isCalculationFinished"
        :is-statement-loaded="props.isStatementLoaded"
        style="padding-right: 15px"
      />
    </swiper-slide>
    <swiper-slide style="width: 90%">
      <CHKO_2
        :items="props.turnoversFiltered"
        :is-calculation-finished="props.isCalculationFinished"
        :is-statement-loaded="props.isStatementLoaded"
      />
    </swiper-slide>
    <template v-if="!store.companies.loading">
      <swiper-slide style="width: 90%">
        <Top :selected-period="props.selectedPeriod" />
      </swiper-slide>
      <swiper-slide style="width: 90%">
        <Court v-if="store.currentCompany" />
      </swiper-slide>
    </template>
  </swiper>
  <div>
    <Overdues />
  </div>
  <div>
    <button
      class="btn-text btn-text-primary"
      type="button"
      @click="emit('show-modal-help')"
    >
      как это рассчитано и расшифровывается?
    </button>
  </div>
</template>
<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';

import CHKO_1 from '@/components/auth/company-analytic/modules/CHKO_1.vue';
import CHKO_2 from '@/components/auth/company-analytic/modules/CHKO_2.vue';
import Top from '@/components/auth/company-analytic/modules/Top.vue';
import Court from '@/components/auth/company-analytic/modules/Court.vue';
import Overdues from '@/components/auth/company-analytic/modules/Overdues.vue';

import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();
const emit = defineEmits(['show-modal-help']);

const props = defineProps({
  turnoversFiltered: {
    type: Array,
    required: true,
  },
  isStatementLoaded: {
    type: Boolean,
    default: false,
  },
  isCalculationFinished: {
    type: Boolean,
    default: false,
  },
  selectedPeriod: {
    type: [String, Number],
    default: 12,
  },
});
</script>
<style lang="scss" scoped>
:deep(.hold-reason) {
  background-color: #ee3f4a1a;
  font-size: 8px;
  line-height: 12px;
  font-weight: 500;
  padding: 5px 10px;
  color: $red;
  border-radius: 4px;
  letter-spacing: 1px;
}

.btn-text {
  text-align: left;
  margin: 0 0 20px 0px;
}
</style>
