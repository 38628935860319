<template>
  <div class="container pt-7">
    <div class="form w-100 m-auto">
      <h1 v-if="!isAgent" class="h3 fw-bold text-center">
        подтверждение номера
      </h1>
      <h1 v-if="isAgent" class="h3 fw-bold text-center">
        подтвердите номер и подпишите публичную оферту
      </h1>
      <template v-if="!isSmsSent">
        <h6 class="fw-normal text-center">
          подтвердите номер телефона +{{ store.currentUser.phone }}
          <p>кодом из СМС</p>
        </h6>
        <div class="input-wrapper m-auto">
          <div class="form-row mb-4 mt-4">
            <PotokButton
              v-if="!loading"
              class="w-100"
              text="получить код"
              size="large"
              @click="sendOtpAsync"
            />
            <LoadingBtn v-if="loading" />
          </div>
        </div>
      </template>
      <template v-else>
        <h6 class="fw-normal text-center">
          введите код, отправленный на номер
          <p>+{{ store.currentUser.phone }}</p>
        </h6>
        <div class="input-wrapper m-auto">
          <div class="form-row text-center">
            <input
              v-model="v$.otp.$model"
              placeholder="код подтверждения"
              :class="{
                'is-invalid': v$.otp.$invalid && v$.otp.$dirty,
              }"
            />
          </div>
          <div v-if="v$.otp.$invalid && v$.otp.$dirty" class="text-danger">
            код должен содержать 4 цифры
          </div>
          <ErrorItem v-if="errorsArr.length !== 0" v-model="errorsArr" />
          <div class="form-row mb-4 mt-4">
            <PotokButton
              v-if="!loading"
              :disabled="isDataUnvalid"
              class="w-100"
              text="продолжить"
              size="large"
              @click="confirmOtpAsync(state.otp)"
            />
            <LoadingBtn v-if="loading" />
          </div>
          <div class="form-row">
            <template v-if="leftTime > 0">
              <div class="row-link fw-normal m-auto">
                отправить код повторно через <span>{{ leftTime }}</span>
                <span> секунд{{ pluralize(leftTime, ['у', 'ы', '']) }}</span>
              </div>
            </template>
            <template v-else>
              <div class="row-link fw-normal m-auto" @click="sendOtpAsync()">
                выслать СМС повторно
              </div>
            </template>
          </div>
        </div>
      </template>
    </div>
    <div v-if="isAgent" class="text-wrapper m-auto">
      <div class="agreement-text text-center">
        регистрируясь, вы соглашаетесь на обработку
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
          target="_blank"
          >персональных данных</a
        >
        в соответствии с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
          target="_blank"
          >политикой</a
        >, ознакомлены с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Правила Инвестиционной платформы Поток.pdf"
          target="_blank"
          >правилами платформы</a
        >, принимаете
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/2023 02 27_Поток Диджитал_Агентский договор_с изменениями 2.docx.pdf"
          target="_blank"
          >публичную аферту платформы</a
        >, и согласны с использованием
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Соглашение участников электронного взаимодействия_Инвестплатформа Поток.pdf"
          target="_blank"
          >простой электронной подписи</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import useVuelidate from '@vuelidate/core';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import { required, minLength } from '@vuelidate/validators';
import { ref, computed, onMounted, reactive, inject } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { useRoute } from 'vue-router';
import server, { useRedirection } from '@/server/index';
import tracker from '@/tracker';
import Countdown from '@/modules/Countdown';
import { pluralize } from '@/utils';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Magic_Link_Phone_Check_Page_View,
  SPA_LK_Magic_Link_Phone_Check_Next_Click,
  SPA_LK_Magic_Link_Phone_Check_success_event,
} = TRACKER_EVENTS;

const store = useStateStore();
const route = useRoute();
const emitter = inject('emitter');

const loading = ref(false);
const errorsArr = ref([]);
const leftTime = ref(0);
const isSmsSent = ref(false);
const countdown = new Countdown();
const isAgent = ref(false);

const state = reactive({
  otp: '',
});

const validations = {
  otp: {
    required,
    minLength: minLength(4),
  },
};

const v$ = useVuelidate(validations, state);

const isDataUnvalid = computed(() => {
  return v$.value.otp.$invalid || !store.currentUser.phone;
});

onMounted(() => {
  getQueryParams(route.query);
  countdown.onTick = (val) => {
    leftTime.value = val;
  };
  tracker.queue(tracker.commands.SEND, SPA_LK_Magic_Link_Phone_Check_Page_View);
});

const getQueryParams = (query) => {
  query?.agent_type ? (isAgent.value = true) : (isAgent.value = false);
};
const startCountdown = () => {
  countdown.reset();
  countdown.start();
};
const confirmOtpAsync = (rawOtp) => {
  const query = {
    otp: rawOtp,
  };
  loading.value = true;
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Magic_Link_Phone_Check_Next_Click,
  );
  return server.postUsersConfirmOtp
    .send(query)
    .pipe(onConfirmOtpAsyncSuccess.bind(this, query), onConfirmOtpAsyncError)
    .exec();
};
const onConfirmOtpAsyncSuccess = () => {
  if (store.currentUser.agent || isAgent.value) {
    loading.value = false;
    tracker.queue(
      tracker.commands.SEND,
      SPA_LK_Magic_Link_Phone_Check_success_event,
    );
    useRedirection('/spa/users/sign_in');
  } else {
    store.fetchFeatures({
      onSuccess: () => {
        tracker.queue(
          tracker.commands.SEND,
          SPA_LK_Magic_Link_Phone_Check_success_event,
        );
        loading.value = false;
        emitter.emit('login-success');
      },
      onError: (error) => {
        loading.value = false;
        errorsArr.value = error.data?.messages;
      },
    });
  }
};
const onConfirmOtpAsyncError = (error) => {
  loading.value = false;
  errorsArr.value = error.data?.messages;
};
const sendOtpAsync = () => {
  loading.value = true;
  startCountdown();
  return server.postRequestOtp$Phone
    .send()
    .pipe(onSendOtpAsyncSuccess.bind(this), onSendOtpAsyncError)
    .exec();
};
const onSendOtpAsyncSuccess = () => {
  loading.value = false;
  isSmsSent.value = true;
};
const onSendOtpAsyncError = (error) => {
  loading.value = false;
  errorsArr.value = error.data?.messages;
};
</script>

<style lang="scss" scoped>
.form {
  max-width: 600px;

  h6 {
    margin: 12px 0 30px 0;
    line-height: 22px;
  }

  .input-wrapper {
    max-width: 340px;

    .form-row {
      input {
        @include input;
      }

      .is-invalid {
        border: 1px solid rgba($red, 0.2);
      }

      .row-link {
        color: $grey;
        width: fit-content;
        cursor: pointer;

        &:hover {
          color: $grey-hover;
        }
      }
    }
  }
}

.text-wrapper {
  max-width: 670px;

  .agreement-text {
    color: $grey;
    margin-top: 113px;

    @media (max-width: $size_767) {
      margin-top: 20px;
      font-size: 10px;
    }
  }
}
</style>
