import config from '@/../config';

export default {
  getLoans: {
    url: config.VITE_BASE_URL + '/spa/business/loans',
    method: 'GET',
    credentials: 'include',
  },
  postEarlyPayments: {
    url: config.VITE_BASE_URL + `/spa/business/early_payments`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  postRequestOtp: {
    url: config.VITE_BASE_URL + `/spa/business/signatures`,
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  putEarlyPaymentsId: {
    url: config.VITE_BASE_URL + `/spa/business/signatures/$signatureId`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  getCalculateEarlyPayment: {
    url: config.VITE_BASE_URL + `/spa/business/early_payments/calculate`,
    method: 'GET',
    credentials: 'include',
  },
};
