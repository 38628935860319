<template>
  <div class="head-section">
    <HeadTabMenuOrder />
    <HeadSectionLimitDesktop v-if="!store.isNewLimit" />
    <HeadSectionLimit2Desktop
      v-if="store.isNewLimit"
      @open-modal-limits="emit('open-modal-limits')"
    />
  </div>
</template>

<script setup>
import { watch, onMounted } from 'vue';
import { detectMobile } from '@/utils';
import HeadSectionLimitDesktop from './HeadSectionLimitDesktop.vue';
import HeadSectionLimit2Desktop from './HeadSectionLimit2Desktop.vue';
import { useStateStore } from '@/stores/stateStore';
import HeadTabMenuOrder from './HeadTabMenuOrder.vue';
import tracker from '@/tracker';

const { isMobile } = detectMobile();
const store = useStateStore();
const emit = defineEmits(['open-modal-limits']);

watch(isMobile, () => {
  store.setUiState('isDashboardLimitTabActive', false);
});

onMounted(() => {
  tracker.queue(tracker.commands.PARAMS, {
    limit_ui_version_mode: store.isNewLimit ? 'full_version' : 'old_version',
  });
});
</script>

<style lang="scss" scoped>
.head-section {
  display: flex;
  margin-top: 14px;
  @media (max-width: $size_767) {
    margin-top: initial;
  }
}

:deep(.shadow-tab) {
  width: 100%;
  height: 100%;
  position: absolute;
}

:deep(.form) {
  border-radius: 14px 14px 0px 0px;
  padding: 20px;
  flex: 1 1 0px;

  @media (max-width: $size_767) {
    height: initial;
    padding: 16px;
    min-width: initial;
  }

  .form-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    position: relative;
    @media (max-width: $size_767) {
      display: block;
      width: 100%;
    }
  }
  .form-title-wrapper {
    display: flex;
    flex-wrap: wrap;
    @media (max-width: $size_767) {
      flex-direction: column;
    }
  }
  .form-title-block {
    padding: 0 10px 0 16px;
    min-width: 122px;
    &:first-child {
      padding-left: 0;
      border-right: 1px solid rgba($grey, 0.2);
    }
    &:last-child {
      @media (max-width: $size_767) {
        padding: 20px 0px 0px 0px;
      }
    }
    @media (max-width: $size_767) {
      padding: 0px 0;
    }
  }
}
:deep(.bg-desert .head-section-title-amount) {
  color: $grey !important;
}

:deep(.bg-desert .head-section-title) {
  color: $grey !important;
}

:deep(.head-section-title) {
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  color: $dipedive;
  margin-bottom: 10px;
  @media (max-width: $size_767) {
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    margin-bottom: initial;
  }
}

:deep(.head-section-title-amount) {
  color: $dipedive;
  display: flex;
  align-items: center;
  @media (max-width: $size_767) {
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
  }
}

.form-main-tab {
  border-bottom: 1px solid $dividers;
  @media (max-width: $size_767) {
    border-bottom: none;
  }
}
</style>
