<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="container-page-background">
    <div class="container-page">
      <div class="page-title">частично-досрочное погашение</div>
      <div class="content-wrapper">
        <div class="content-left white-block">
          <div class="content-row">
            <div class="row-title">заём</div>
            <div class="row-content">
              <CustomSelect
                v-model="selectedLoanId"
                :items="activeLoans"
                :default-title="defaultTitle"
                :disabled="loading"
                type="alt"
                @update:model-value="changeSelectedLoanId"
              >
                <template #item="{ item }">
                  <div class="custom-select-block">
                    <div class="custom-select-block-left">
                      <div class="custom-select-title">
                        №{{ item.data.id }} от
                        {{ formatDate(item.data.started_at) }}
                      </div>
                      <div class="custom-select-amount">
                        {{ formatCurrency(item.data.amount, 0) }}
                        ₽
                      </div>
                    </div>
                    <div class="custom-select-block-right">
                      <div
                        v-if="
                          item.data.early_payments_notification_type ===
                          'success'
                        "
                        class="tag-allow-repayment"
                      >
                        доступно для погашения
                      </div>
                      <div
                        v-if="
                          item.data.early_payments_notification_type !==
                          'success'
                        "
                        class="tag-disable-repayment"
                      >
                        сейчас погашение недоступно
                      </div>
                    </div>
                  </div>
                </template>
              </CustomSelect>
            </div>
          </div>
          <div v-if="activeLoans.length > 0" class="content-row mt-6">
            <div class="row-title">сумма</div>
            <div class="row-content">
              <InputMoney
                v-model="rawInputedAmount"
                :disabled="loading"
                placeholder="сумма для частичного досрочного погашения, ₽"
                @update:model-value="clearInputedAmount"
              />
            </div>
            <div class="balance-panel">
              <div class="text-desc">
                доступно на балансе
                <button
                  type="button"
                  class="btn-text btn-text-tiffany"
                  :disabled="loading"
                  @click="onClickBalance"
                >
                  {{ formatCurrency(currentCompany.funds?.current_balance) }}
                  ₽
                </button>
              </div>
              <div class="text-danger mt-2">
                <div v-if="isinputedAmountMoreBalance">
                  недостаточно средств на счете,
                  <button type="button" class="btn-text" @click="onClickRefill">
                    пополнить баланс
                  </button>
                </div>
                <div v-for="(error, index) in errors" :key="index">
                  <div v-html="error"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="activeLoans.length > 0"
          class="content-right"
          :class="{
            'notes-info-block': isInfoStatus,
            'notes-alert-block': !isInfoStatus,
          }"
        >
          <template v-if="currentLoanNotificationType === 'success'">
            <div>
              <div class="notes-title">частично-досрочное погашение</div>
              <ul class="notes-list">
                <li>
                  при частично-досрочном погашении сокращается срок займа. Сумма
                  платежа не меняется.
                </li>
                <li>
                  платёж будет учтён в пересчёте последних платежей по графику.
                </li>
              </ul>
            </div>
          </template>
          <template v-if="currentLoanNotificationType === 'already_launched'">
            <div>
              <div class="notes-title">начали процесс погашения</div>
              <div class="notes-list">
                запущено частично-досрочное погашение
                <span v-if="selectedLoan?.active_early_payment_amount">
                  {{
                    `на сумму ${formatCurrency(
                      selectedLoan?.active_early_payment_amount,
                    )} ₽`
                  }}</span
                >
                для займа {{ selectedLoanId }} от
                {{ formatDate(selectedLoan?.started_at) }}.
              </div>
            </div>
            <div class="text-desc text-desc-down">
              сделаем перерасчет в течение дня, но не раньше, чем через 1-2 часа
              после запуска.
            </div>
          </template>
          <template v-if="currentLoanNotificationType === 'upcoming_payment'">
            <div>
              <div class="notes-title">скоро день платежа</div>
              <ul class="notes-list">
                <li>
                  в день платежа по графику вы не можете запустить
                  частично-досрочное погашение для займа
                  {{ selectedLoanId }} от {{ formatDate(new Date()) }}.
                </li>
                <li>
                  вы сможете сделать это только после списания платежа с
                  баланса.
                </li>
              </ul>
              <div class="text-desc text-desc-down">
                оплатите нужную сумму и попробуйте погасить заем досрочно
                завтра.
              </div>
            </div>
          </template>
          <template v-if="currentLoanNotificationType === 'overdue'">
            <div>
              <div class="notes-title">у вас есть задолженость</div>
              <div class="notes-list">
                закройте просроченную задолженность, чтобы избежать переплаты.
                для этого свяжитесь с нашим специалистом для консультации по
                почте
                <a href="mailto:ok@potok.team" class="color-tiffany"
                  >ok@potok.team</a
                >
              </div>
            </div>
            <div class="text-desc text-desc-down">
              оплатите задолженность и попробуйте сделать частичное погашение
              завтра
            </div>
          </template>
          <template v-if="currentLoanNotificationType === 'commission'">
            <div>
              <div class="notes-title">у займа есть комиссия</div>
              <div class="notes-list">
                если вы хотите запустить процесс досрочного погашения, свяжитесь
                с нами по почте
                <a href="mailto:ok@potok.team" class="color-tiffany"
                  >ok@potok.team</a
                >
              </div>
            </div>
          </template>
          <template
            v-if="currentLoanNotificationType === 'other_already_launched'"
          >
            <div class="notes-title">уже есть активное погашение</div>
            <div class="notes-list">
              проводить полное досрочное погашение можно только по одному займу
              одновременно
            </div>
          </template>
        </div>
      </div>
      <div class="actions-wrapper">
        <PotokButton
          v-if="
            !loading &&
            selectedLoan?.allow_partial_early_payments &&
            currentLoanNotificationType === 'success'
          "
          class="button button-repayment"
          text="погасить"
          size="large"
          @click="onClickRepayment"
        />

        <PotokButton
          v-if="
            !loading &&
            (currentLoanNotificationType === 'commission' ||
              currentLoanNotificationType === 'overdue' ||
              currentLoanNotificationType === 'upcoming_payment')
          "
          class="button button-repayment"
          text="внести платеж"
          size="large"
          @click="onClickRefill"
        />

        <LoadingBtn v-if="loading" class="button button-repayment" />

        <PotokButton
          class="button button-cancel"
          text="отмена"
          size="large"
          theme="tertiary"
          :disabled="loading"
          @click="onClickCancel"
        />
      </div>
    </div>
    <ModalPhoneConfirmation
      v-if="isShowModalConfirmation"
      :left-time="leftTime"
      :agreement-id="chdpId"
      :loading="loading"
      :errors-arr="errors"
      :title="`частично-досрочное погашение займа № ${selectedLoanId} от ${formatDate(
        new Date(),
      )}`"
      :phone="store.currentUser.phone"
      :width="'440px'"
      @clear-errors="clearErrors"
      @confirm-otp-async="confirmOtpAsync"
      @send-otp-async="sendOtpAsync"
      @close-modal="onClickCloseModalConfirmation"
      @go-back="onClickCloseModalConfirmation"
    />
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { ref, onMounted, computed } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';

import CustomSelect from '@/components/_generic/ui/widgets/CustomSelect.vue';
import InputMoney from '@/components/_generic/ui/inputs/InputMoney.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { useStateStore } from '@/stores/stateStore';
import { formatCurrency, formatDate } from '@/utils';
import server from '@/server/index';
import ModalPhoneConfirmation from '@/components/_generic/ui/modals/ModalPhoneConfirmation.vue';
import { loansUtils } from '@/components/auth/loan-repayment/loansUtils.js';

const store = useStateStore();
const router = useRouter();
const { currentCompany } = storeToRefs(store);

const inputedAmount = ref(null);
const rawInputedAmount = ref(null);
const errors = ref([]);
const isShowModalConfirmation = ref(false);
const chdpId = ref(null);
const signatureId = ref(null);

const {
  loading,
  selectedLoanId,
  currentLoanNotificationType,
  isInfoStatus,
  selectedLoan,
  defaultTitle,
  activeLoans,
  leftTime,
  getLoansAsync,
  onClickCancel,
  startCountdown,
} = loansUtils();

const isinputedAmountMoreBalance = computed(() => {
  return inputedAmount.value > currentCompany.value.funds?.current_balance;
});

const clearInputedAmount = (value) => {
  let clearValue;
  if (value) {
    clearValue = value.replace(/\s/g, '');
    clearValue = clearValue.substring(0, clearValue.length - 1);
  }
  inputedAmount.value = clearValue;
};

const onClickRepayment = () => {
  if (!inputedAmount.value) {
    errors.value = ['укажите сумму погашения'];
    return;
  }
  const amount = parseInt(inputedAmount.value);
  if (!amount || amount === 0) {
    errors.value = ['укажите сумму погашения'];
    return;
  }
  if (amount > parseInt(currentCompany.value.funds?.current_balance)) {
    return;
  }
  loading.value = true;
  chdpId.value = null;
  return server.postEarlyPayments
    .send(
      {
        amount: amount,
        early_payment_type: 'partial',
        loan_id: selectedLoanId.value,
      },
      {},
    )
    .pipe(onClickRepaymentAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};
const onClickRepaymentAsyncSuccess = ({ data }) => {
  loading.value = false;
  if (data.id) {
    chdpId.value = data.id;
    sendOtpAsync();
  }
  if (data.error && data.error.messages) {
    errors.value = data.error.messages;
  }
};

const onClickBalance = () => {
  rawInputedAmount.value = Math.floor(
    currentCompany.value.funds?.current_balance,
  );
};

const clearErrors = () => {
  errors.value = [];
};
const sendOtpAsync = () => {
  loading.value = true;
  signatureId.value = null;
  return server.postRequestOtp
    .send(
      {
        signature: {
          kind: 'partial_early_payment',
          confirmable_type: 'EarlyPayment',
          confirmable_id: chdpId.value,
        },
      },
      {},
    )
    .pipe(onSendOtpAsyncAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};

const onSendOtpAsyncAsyncSuccess = ({ data }) => {
  loading.value = false;
  isShowModalConfirmation.value = true;
  signatureId.value = data.id;
  startCountdown();
};
const confirmOtpAsync = (rawOtp) => {
  loading.value = true;
  return server.putEarlyPaymentsId
    .send(
      {
        signature: {
          code: rawOtp,
        },
      },
      {
        params: {
          signatureId: signatureId.value,
        },
      },
    )
    .pipe(onConfirmOtpAsyncAsyncAsyncSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};
const onConfirmOtpAsyncAsyncAsyncSuccess = ({ response }) => {
  loading.value = false;
  inputedAmount.value = 0;
  onClickCloseModalConfirmation();

  if (response.status === 200) {
    getLoansAsync({ early_payment: 'partial' });
  }
};
const onClickCloseModalConfirmation = () => {
  isShowModalConfirmation.value = false;
};
const onClickRefill = () => {
  router.push({
    name: 'accountRefill',
  });
};

const changeSelectedLoanId = () => {
  errors.value = [];
};

onMounted(() => {
  getLoansAsync({ early_payment: 'partial' });
});
</script>
<style lang="scss" scoped>
.content-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
}

.content-left {
  margin-right: 30px;
  flex: 1;

  @media (max-width: $size_767) {
    margin-right: initial;
  }
}

.row-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2px;
  text-transform: lowercase;
}

.row-content {
  margin-top: 20px;
}

.text-desc {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $grey;
  margin-top: 10px;
}

.text-desc-down {
  margin-top: 78px;
}

.content-right {
  max-width: 373px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media (max-width: $size_767) {
    max-width: initial;
  }
}
.balance-panel {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.notes-title {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2px;
  text-transform: lowercase;
}

.notes-list {
  margin-top: 14px;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: lowercase;
}

.actions-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.button {
  width: initial;

  @media (max-width: $size_767) {
    width: 100%;
    margin-bottom: 20px;
  }
}

.button-repayment {
  margin-right: 20px;
  min-width: 130px;

  @media (max-width: $size_767) {
    margin-right: initial;
  }
}

.button-cancel {
  min-width: 116px;
}

.custom-select-title {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $grey;
}

.custom-select-amount {
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: -0.2px;
  text-transform: lowercase;
  color: $dipedive;
  margin-top: 14px;
}

.custom-select-block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.custom-select-block-left {
  flex: 2;
}

.tag-allow-repayment {
  background-color: rgba(4, 204, 217, 0.1);
  border-radius: 6px;
  color: $tiffany;
  padding: 4px 8px;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}

.tag-disable-repayment {
  background-color: rgba(255, 87, 84, 0.1);
  border-radius: 6px;
  color: #ff5754;
  padding: 4px 8px;
  font-size: 8px;
  font-style: normal;
  font-weight: 500;
  line-height: 12px;
  letter-spacing: 0.8px;
  text-transform: uppercase;
}
</style>
