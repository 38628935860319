<template>
  <div class="container-fluid">
    <div v-if="loading" class="container pt-6">
      <Loader />
    </div>
    <div class="container pt-6 pt-sm-0 mb-3">
      <div v-if="isOverdue || isOverdueMonthlyCommission" class="pt-0 pt-sm-6">
        <OverdueNotify v-if="isOverdue" type="overdue" />
      </div>
      <div v-if="isMobile">
        <div class="d-flex align-items-center justify-content-between">
          <BackBtnMobile bg-color="#e8ebee" @click="onClickGoToDashboard()" />
          <OrderStatus
            v-if="order.loan?.state"
            :order="order"
            :is-type-order="false"
          />
        </div>
        <div class="d-flex align-items-center justify-content-between mt-5">
          <span class="fw-bold">заем № {{ order.id }} </span
          ><span v-if="order.created_at" class="color-grey"
            >от {{ formatDate(order.created_at) }}</span
          >
        </div>
      </div>
      <div class="d-flex flex-sm-row flex-column">
        <section v-if="!loading">
          <div class="form mt-6 p-4 p-sm-6">
            <div
              v-if="!isMobile"
              class="d-flex justify-content-between align-items-center"
            >
              <div class="d-flex align-items-center">
                <span class="small color-grey d-inline-block"
                  >заем № {{ order.id }} от
                  <span v-if="order.created_at">{{
                    formatDate(order.created_at)
                  }}</span></span
                >
                <OrderStatus
                  v-if="order.loan?.state"
                  :order="order"
                  :is-type-order="false"
                />
              </div>
              <OnClickOutside @trigger="isDocsListOpen = false">
                <div class="position-relative">
                  <div
                    class="cursor-pointer"
                    @click="isDocsListOpen = !isDocsListOpen"
                  >
                    <span class="small d-inline-block"
                      >документы и договоры</span
                    >
                    <i
                      class="d-inline-block icon-arrow-down ms-2"
                      :class="{ 'icon-arrow-up': isDocsListOpen }"
                    >
                    </i>
                  </div>
                  <div v-if="isDocsListOpen" class="form-list text-nowrap">
                    <button
                      class="btn-text btn-text-primary mb-4"
                      @click="onClickOpenSignedInvestOffer(order)"
                    >
                      инвестиционное предложение
                    </button>
                    <button
                      class="btn-text btn-text-primary d-block mb-4"
                      @click="getLoanContractsAsync()"
                    >
                      договоры с инвесторами
                    </button>
                    <button
                      v-if="order.credit_line"
                      class="btn-text btn-text-primary d-block"
                      @click="onClickOpenSignedCreditLineAgreement(order)"
                    >
                      договор кредитной линии
                    </button>
                  </div>
                </div>
              </OnClickOutside>
            </div>
            <hr v-if="!isMobile" class="color-grey my-6" />
            <LoanParams :order="order" />
            <div
              class="p-0 p-sm-4 pt-6 rounded-4"
              :class="[isMobile ? 'bg-white' : 'bg-background']"
            >
              <ProgressBar
                :order="order"
                :track-color="isMobile ? '#f2f4f8' : 'white'"
                width="100%"
              />
            </div>
            <hr v-if="!isMobile" class="color-grey my-6" />
            <div class="d-block d-sm-flex justify-content-between ms-sm-0">
              <div class="d-block d-sm-flex">
                <div
                  class="me-0 me-sm-4 my-4 my-sm-0"
                  :style="isMobile ? 'max-width: 100%' : 'max-width: 200px'"
                >
                  <PotokButton
                    text="пополнить баланс"
                    size="medium"
                    @click="onClickGoToRefill"
                  />
                </div>
                <div
                  v-if="
                    order.loan?.payments?.next_payment?.monthly_commission &&
                    !store.isBillingMonthlyCommission
                  "
                  :style="isMobile ? 'max-width: 100%' : 'max-width: 220px'"
                  class="my-4 my-sm-0"
                >
                  <PotokButton
                    size="medium"
                    theme="secondary"
                    @click="onClickGoToCommission"
                  >
                    <template #default
                      ><div class="d-block d-sm-flex align-items-center">
                        <i v-if="!isMobile" class="commission me-2"></i
                        ><span>оплатить комиссию</span>
                      </div></template
                    >
                  </PotokButton>
                </div>
              </div>

              <div v-if="!isLoanPaymentToday && (isCHDPEnabled || isPDPEnabled)">
                <OnClickOutside
                  v-if="order.commission_type === 'upfront'"
                  @trigger="isRedemptionListOpen = false"
                >
                  <div
                    class="position-relative"
                    :style="isMobile ? 'max-width: 100%' : 'max-width: 220px'"
                  >
                    <PotokButton
                      size="medium"
                      theme="tertiary"
                      @click="isRedemptionListOpen = !isRedemptionListOpen"
                    >
                      <template #default
                        >погасить досрочно<i
                          class="d-inline-block icon-arrow-down ms-2"
                          :class="{ 'icon-arrow-up': isRedemptionListOpen }"
                        ></i
                      ></template>
                    </PotokButton>

                    <div
                      v-if="isRedemptionListOpen"
                      class="form-list form-list-btn text-nowrap"
                    >
                      <button
                        v-if="isCHDPEnabled"
                        class="btn-text btn-text-primary"
                        :class="{
                          'mb-4': isPDPEnabled,
                        }"
                        @click="onClickGoToCHDP()"
                      >
                        погасить частично
                      </button>
                      <button
                        v-if="isPDPEnabled"
                        class="btn-text btn-text-primary d-block"
                        @click="onClickGoToPDP()"
                      >
                        погасить полностью
                      </button>
                    </div>
                  </div>
                </OnClickOutside>

                <PotokButton
                  v-if="order.commission_type === 'monthly_commission' && isPDPEnabled"
                  text="погасить полностью"
                  size="medium"
                  theme="tertiary"
                  @click="onClickGoToPDP()"
                />
              </div>
            </div>
          </div>
          <span class="d-inline-block mt-6 mb-3 mb-sm-6 color-grey small">
            Уважаемый пользователь, в связи со спецификой удержания НДФЛ по
            инвесторам, точная сумма к перечислению на платформу и размер
            удержанного НДФЛ формируются в платежную дату по графику
          </span>
          <div v-if="isMobile" class="mb-4">
            <button
              class="btn-text btn-text-tiffany mb-4"
              @click="onClickOpenSignedInvestOffer(order)"
            >
              инвестиционное предложение
            </button>
            <button
              class="btn-text btn-text-tiffany d-block mb-4"
              @click="getLoanContractsAsync()"
            >
              договоры с инвесторами
            </button>
            <button
              v-if="order.credit_line"
              class="btn-text btn-text-primary d-block"
              @click="onClickOpenSignedCreditLineAgreement(order)"
            >
              договор кредитной линии
            </button>
          </div>
          <div v-if="!isMobile" class="d-flex align-items-center">
            <div style="width: 220px">
              <LoadingBtn v-if="loading" />
              <PotokButton
                v-if="!loading"
                text="назад к займам"
                size="large"
                theme="tertiary"
                @click="onClickGoToDashboard"
              />
            </div>
            <ErrorItem
              v-if="errorsArr.length !== 0"
              v-model="errorsArr"
              class="ms-4"
            />
          </div>
        </section>
        <section class="ms-0 ms-sm-6 mt-0 mt-sm-6 mb-7 mb-sm-0">
          <LoanPaymentSummary
            :order="order"
            @get-schedule-error="getScheduleError($event)"
          />
        </section>
      </div>
    </div>
  </div>
  <PageAlert v-if="isDocsSent">
    <span>
      <span class="d-inline-block mb-2" style="font-size: 20px"
        >отправили вам e-mail</span
      ><br />
      архив договоров с<br />
      инвесторами, отправлен на<br />
      вашу почту</span
    >
  </PageAlert>
</template>

<script setup>
import { onMounted, ref, watch } from 'vue';
import { PotokButton, Loader } from 'potok-uikit';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import PageAlert from '@/components/_generic/ui/widgets/PageAlert.vue';
import LoanPaymentSummary from '@/components/auth/dashboard/modules/loan-card/ui/modules/loan-payment-summary/ui/LoanPaymentSummary.vue';
import OverdueNotify from '@/components/auth/dashboard/messages/OverdueNotify.vue';
import OrderStatus from '@/components/auth/dashboard/orders/OrderStatus.vue';
import ProgressBar from '@/components/auth/dashboard/orders/ProgressBar.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import LoanParams from '@/components/auth/dashboard/modules/loan-card/ui/modules/LoanParams.vue';
import BackBtnMobile from '@/components/_generic/ui/buttons/BackBtnMobile.vue';
import { formatDate } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import { storeToRefs } from 'pinia';
import { OnClickOutside } from '@vueuse/components';
import { loanCardUtils } from '@/components/auth/dashboard/modules/loan-card/utils';
import { detectMobile } from '@/utils';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';
import { generalUtils } from '@/modules/generalUtils.js';
import { documentsUtils } from '@/modules/documentsUtils.js';

const { SPA_LK_Loan_open } = TRACKER_EVENTS;

const store = useStateStore();
const { isMobile } = detectMobile();
const { isOverdue, isOverdueMonthlyCommission, isLoanPaymentToday } =
  storeToRefs(store);
const {
  order,
  onClickGoToCommission,
  onClickGoToDashboard,
  onClickGoToRefill,
  onClickGoToCHDP,
  onClickGoToPDP,
  getLoanContractsAsync,
  getScheduleError,
  isCHDPEnabled,
  isPDPEnabled,
  isDocsSent,
  errorsArr,
  loading,
} = loanCardUtils();

const { getOrderAsync } = generalUtils();

const { onClickOpenSignedInvestOffer, onClickOpenSignedCreditLineAgreement } =
  documentsUtils();

const isDocsListOpen = ref(false);
const isRedemptionListOpen = ref(false);

tracker.queue(tracker.commands.SEND, SPA_LK_Loan_open);

onMounted(() => {
  loading.value = true;
  getOrderAsync({
    query: { 'expand[]': ['loan', 'payments'] },
    callback: (data) => {
      order.value = data;
      loading.value = false;
    },
  });
});

watch(
  () => isDocsSent.value,
  () => {
    if (isDocsSent.value) {
      setTimeout(() => (isDocsSent.value = false), 3700);
    }
  },
);
</script>
<style lang="scss" scoped>
.container-fluid {
  background-color: $background;
  overflow: auto;
  min-height: 100%;
  .form {
    background-color: $white;
    border-radius: 12px;
    .form-list {
      position: absolute;
      top: 35px;
      right: 0;
      padding: 24px 20px;
      border-radius: 12px;
      background-color: $white;
      box-shadow: 0px 2px 22px 0px rgba(13, 39, 65, 0.12);
      z-index: 2;
    }
    .form-list-btn {
      top: 60px;
    }
  }
  .commission {
    display: inline-block;
    width: 15px;
    height: 15px;
    background-image: url(@/assets/icons/rounded/discount.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    filter: $filter-breakwater;
  }
  .button-third:hover {
    .commission {
      filter: $filter-white;
    }
  }
}
</style>
