<template>
  <div class="loader-wrapper">
    <div
      class="loader-item"
      :class="{ 'loader-item-loading': props.isLoading }"
    ></div>
    <div
      class="loader-item"
      :class="{ 'loader-item-loading': props.isLoading }"
    ></div>
    <div
      class="loader-item"
      :class="{ 'loader-item-loading': props.isLoading }"
    ></div>
  </div>
</template>
<script setup>
const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
});
</script>
<style lang="scss" scoped>
.loader-wrapper {
  width: 100%;
}
.loader-item {
  width: 100%;
  margin-bottom: 20px;
  height: 30px;
  border-radius: 8px;
  background: $background;
}

.loader-item-loading {
  background: linear-gradient(
    90deg,
    rgb(255 255 255 / 56%) 55%,
    rgb(255 252 255 / 55%) 77%,
    rgb(89 85 85 / 6%) 93%
  );
  background-size: 400% 100%;
  animation-name: skeleton-loading;
  animation-duration: 1.3s;
  animation-iteration-count: infinite;
}

@keyframes skeleton-loading {
  0% {
    background-position: 100% 50%;
  }
  50% {
    background-position: 20% 50%;
  }
  100% {
    background-position: 0 50%;
  }
}
</style>
