<template>
  <div class="recover-email">
    <div class="subtitle potok-text-body-1">
      введите почту, которую вы указали при регистрации
    </div>
    <div class="email-container mt-7">
      <input
          v-model="email"
          class="email form-control"
          type="email"
          :readonly="loading"
          placeholder="почта"
          @input="onPressKeyEmail"
          @keyup.enter="onContinue"
      />
      <div v-if="errorMessageEmail" class="email-error mt-2">
        {{ errorMessageEmail }}
      </div>
    </div>
    <PotokButton
        v-if="isErrorFromServer"
        text="зарегистрироваться"
        size="large"
        class="mt-6"
        :loading="loading"
        fullWidthDesktop
        @click="onRegistration"
    />
    <PotokButton
        v-if="!isErrorFromServer"
        text="продолжить"
        size="large"
        class="mt-6"
        :loading="loading"
        fullWidthDesktop
        @click="onContinue"
    />

    <PotokButton
        theme="link"
        class="mt-3"
        size="link"
        :loading="loading"
        text="назад"
        fullWidthDesktop
        @click="onBack"
    />
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import validators from '@/validators';
import tracker from '@/tracker';
import server from '@/server';
import { PotokButton } from "potok-uikit";
import { useRouter } from "vue-router";

const emit = defineEmits([
  'changeStep',
  'saveEmail',
]);

const router = useRouter();

const email = ref(null);

const errorMessageEmail = ref(null);
const isErrorFromServer = ref(false)

const loading = ref(false);

const isEmailValid = () => {
  if (!email.value) {
    errorMessageEmail.value = 'обязательное поле';
    return false;
  }
  if (!validators.email(email.value)) {
    errorMessageEmail.value = 'неверный формат email';
    return false;
  }
  return true;
};

const onPressKeyEmail = () => {
  errorMessageEmail.value = null;
  isErrorFromServer.value = false;
};

const onContinue = () => {
  if (!isEmailValid()) {
    return;
  }

  requestRecoverPassword();
};

const onRegistration = () => {
  router.push({ name: 'registration' })
}

const onBack = () => {
  router.push({ name: 'login' })
};

const requestRecoverPassword = () => {
  loading.value = true;

  const query = {
    user: { email: email.value },
  };

  server.postRequestRecoverPassword
      .send(query)
      .pipe(onSuccessRequestRecoverPassword, (error) => {
        loading.value = false;
        errorMessageEmail.value = error.data?.messages;
        isErrorFromServer.value = true;
      })
      .exec();
};
const onSuccessRequestRecoverPassword = () => {
  loading.value = false;
  emit('saveEmail', email.value);
  emit('changeStep', 'link');
};

onMounted(() => {
  tracker.queue(tracker.commands.SEND, 'SPA_LK_Password_reset_open')
})
</script>

<style lang="scss" scoped>
.subtitle {
  text-align: center;
}
.email {
  width: 340px;
  padding-left: 20px;
  color: $dipedive;
  height: 60px;
  border: 0;
  background: #f2f4f8;
  border-radius: 8px;
  @media (max-width: $size_991) {
    min-width: 288px;
    width: 100%;
  }
  &:focus {
    box-shadow: none;
    background-color: #f2f4f8;
  }
}

.email-error {
  color: $red;
}
</style>
