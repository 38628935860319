<template>
  <div class="container-fluid">
    <div class="container">
      <template
        v-if="
          !loading &&
          !isPlatformRulesOpen &&
          !isGuaranteeIssueOpen &&
          !isGuaranteeSigningOpen
        "
      >
        <OrderSteps
          v-if="!isMobile"
          :order="store.currentOrder"
          :is-scoring-state-auto-flow="isScoringStateAutoFlow"
          :is-approved-by-credit-committee="isApprovedByCreditCommittee"
          class="pt-5"
        />
        <div class="d-flex align-items-center">
          <div class="me-2" :class="loanStateIcon"></div>
          <div class="title fw-bold">{{ loanStateTitle }}</div>
        </div>

        <template v-if="loanState === 'investing'">
          <div class="white-block">
            <div class="text-h2">
              вы получите деньги на счет, как только они соберутся
            </div>
            <Progress class="mt-3" />
            <div class="mt-3 d-flex justify-content-between">
              <div class="text-title">
                собрано
                {{
                  formatCurrency(store.currentOrder.loan?.invested_amount, 0)
                }}
                ₽
              </div>
              <div class="text-title-grey">
                {{ formatCurrency(store.currentOrder.amount, 0) }} ₽
              </div>
            </div>
          </div>
        </template>

        <div class="d-block d-md-flex justify-content-between">
          <div class="flex-grow-1">
            <RequiredActions />
            <div class="white-block">
              <div class="text-h2 mb-4">счет, на который поступят деньги</div>
              <Account />
            </div>
          </div>
          <div class="form ms-0 ms-md-6">
            <TermsTable title="ваши условия"
              ><template #documents>
                <AdditionalDocs :order="store.currentOrder" /> </template
            ></TermsTable>
          </div>
        </div>
        <div v-if="isMobile">
          <ErrorItem
            v-if="errors.length !== 0"
            v-model="errors"
            class="text-center mb-3"
          />
          <PotokButton
            v-if="!loading"
            class="mb-3"
            size="medium"
            @click="onClickHandleSubmit()"
            ><template #default
              ><span
                v-if="isReadyForSigning && isAutoFlow && store.isManualCheck"
                >начать сбор</span
              ><span v-else>вернуться к списку займов</span></template
            >
          </PotokButton>
          <PotokButton
            v-if="isApprovedByCreditCommittee"
            class="mb-3"
            size="medium"
            theme="secondary"
            @click="
              currentManager.name
                ? (isManagerModalOpen = true)
                : onClickOpenChat()
            "
          >
            <template #default
              ><span v-if="currentManager.name">
                связаться с персональным менеджером </span
              ><span v-else>написать менеджеру в whatsapp</span>
            </template>
          </PotokButton>
          <LoadingBtn v-if="loading" />
        </div>

        <div
          v-if="!isMobile"
          class="py-4 d-flex justify-content-left align-items-center"
        >
          <div class="me-4">
            <PotokButton
              v-if="!loading"
              size="medium"
              @click="onClickHandleSubmit()"
              ><template #default
                ><span
                  v-if="isReadyForSigning && isAutoFlow && store.isManualCheck"
                  >начать сбор</span
                ><span v-else>вернуться к списку займов</span></template
              >
            </PotokButton>
            <LoadingBtn v-if="loading" />
          </div>
          <div v-if="isApprovedByCreditCommittee" style="width: 360px">
            <PotokButton
              v-if="!loading"
              size="medium"
              theme="third"
              @click="
                currentManager.name
                  ? (isManagerModalOpen = true)
                  : onClickOpenChat()
              "
            >
              <template #default
                ><span v-if="currentManager.name">
                  связаться с персональным менеджером </span
                ><span v-else>написать менеджеру в whatsapp</span>
              </template>
            </PotokButton>
          </div>
          <ErrorItem v-if="errors.length !== 0" v-model="errors" class="ms-4" />
        </div>
      </template>
      <PlatformRules
        v-if="isPlatformRulesOpen"
        v-model:isPlatformRulesOpen="isPlatformRulesOpen"
        :amount="0"
        type="signatory"
        ><template #title>подпишите присоединение к правилам платформы</template
        ><template #description
          >для присоединения к правилам платформы, <br />переведите 1 рубль с
          любого расчетного счета компании</template
        ></PlatformRules
      >
      <GuaranteeIssue
        v-if="isGuaranteeIssueOpen"
        v-model:loading="loading"
        v-model:isGuaranteeIssueOpen="isGuaranteeIssueOpen"
        v-model:isGuaranteeFullFilled="isGuaranteeFullFilled"
        v-model:isGuaranteePartiallyFilled="isGuaranteePartiallyFilled"
        :order="store.currentOrder"
        :guarantors="guarantors"
        :is-guarantee-signed="isGuaranteeAllSignedByRub || isDocsSigned"
        @get-guarantors-async="getGuarantorsAsync()"
      />
      <GuaranteeSigning
        v-if="isGuaranteeSigningOpen"
        v-model="isGuaranteeSigningOpen"
        :order="store.currentOrder"
        :guarantors="guarantors"
        type="guarantor"
      />
      <div v-if="loading" class="pt-5">
        <Loader />
      </div>
    </div>
  </div>
  <UploadPassportModal
    v-if="isPassportModalOpen"
    v-model:isPassportUploaded="isPassportUploaded"
    v-model:isPassportPartiallyUploaded="isPassportPartiallyUploaded"
    :order="store.currentOrder"
    :is-docs-signed="isDocsSigned"
    @close-modal="closeModal"
  />
  <PassportFillModal
    v-if="isPassportFillModalOpen"
    v-model:isPassportFilled="isPassportFilled"
    @close-modal="closeModal"
  />
  <UploadDocs
    v-if="isDocsModalOpen"
    v-model:isAdditionalDocsUploaded="isAdditionalDocsUploadedOld"
    v-model:isAdditionalDocsPartiallyUploaded="isAddDocsPartiallyUploadedOld"
    :order="store.currentOrder"
    :docs-to-upload="docsToUpload"
    @close-modal="closeModal"
  />
  <KeepAlive>
    <DocsSigning
      v-if="isDocsSigningModalOpen"
      v-model:isDocsSigned="isDocsSigned"
      :order="store.currentOrder"
      :guarantors="guarantors"
      @close-modal="closeModal"
    />
  </KeepAlive>
  <SuccessModal
    v-if="isSuccessModalOpen"
    @get-orders-async="getOrdersAsync"
    @close-modal="closeModal"
  />
  <ManagerModal v-if="isManagerModalOpen" @close-modal="closeModal" />
</template>

<script setup>
import { PotokButton, Loader } from 'potok-uikit';

import { useStateStore } from '@/stores/stateStore';
import { storeToRefs } from 'pinia';
import ManagerModal from '@/components/auth/loan-card/modules/manager-modal/ManagerModal.vue';
import SuccessModal from '@/components/auth/loan-card/modules/success-modal/SuccessModal.vue';
import OrderSteps from '@/components/auth/loan-card/modules/OrderSteps.vue';
import TermsTable from '@/components/_generic/auth/TermsTable.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import Progress from '@/components/_generic/ui/Progress.vue';
import AdditionalDocs from '@/components/auth/loan-card/modules/AdditionalDocs.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import UploadPassportModal from '../modules/upload-passport/UploadPassportModal.vue';
import PassportFillModal from '../modules/fill-passport/PassportFillModal.vue';
import UploadDocs from '../modules/UploadDocs.vue';
import PlatformRules from '../modules/platform-rules/PlatformRules.vue';
import DocsSigning from '../modules/DocsSigning.vue';
import GuaranteeIssue from '../modules/guarantee-issue/GuaranteeIssue.vue';
import GuaranteeSigning from '../modules/GuaranteeSigning.vue';
import RequiredActions from '@/components/auth/loan-card/main-flow/RequiredActions.vue';
import Account from '@/components/_generic/ui/widgets/Account.vue';
import { formatCurrency, detectMobile } from '@/utils';
import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';
import { loanCollectUtils } from '@/components/auth/loan-card/main-flow/utils/index.js';
import { managerModalUtils } from '@/components/auth/loan-card/modules/manager-modal/utils/index.js';

const { isMobile } = detectMobile();
const store = useStateStore();
const { currentManager } = storeToRefs(store);
const {
  isAutoFlow,
  isScoringStateAutoFlow,
  loanState,
  loanStateTitle,
  loanStateIcon,
  onClickHandleSubmit,
  closeModal,
} = loanCollectUtils();
const {
  loading,
  errors,
  guarantors,
  isApprovedByCreditCommittee,
  isPlatformRulesOpen,
  isPassportModalOpen,
  isPassportUploaded,
  isPassportPartiallyUploaded,
  isPassportFillModalOpen,
  isGuaranteeIssueOpen,
  isGuaranteeFullFilled,
  isGuaranteePartiallyFilled,
  isDocsModalOpen,
  isAdditionalDocsUploadedOld,
  isAddDocsPartiallyUploadedOld,
  docsToUpload,
  isDocsSigningModalOpen,
  isDocsSigned,
  isGuaranteeSigningOpen,
  isGuaranteeAllSignedByRub,
  isPassportFilled,
  isSuccessModalOpen,
  isManagerModalOpen,
  isReadyForSigning,
  getGuarantorsAsync,
  getOrdersAsync,
} = loanCardShared();
const { onClickOpenChat } = managerModalUtils();
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: $background;
  min-height: 100%;
}
.title {
  font-size: 30px;
  line-height: 36px;
  padding: 30px 0;
  @media (max-width: $size_575) {
    font-size: 20px;
    padding: 20px 0px;
  }
}

.form {
  height: auto;
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  &:first-child {
    margin-bottom: 20px;
    @media (max-width: $size_767) {
      margin-bottom: 16px;
    }
  }
  &:last-child {
    max-width: 373px;
    @media (max-width: $size_767) {
      max-width: 100%;
    }
  }
  @media (max-width: $size_767) {
    margin-bottom: 16px;
  }
}
</style>
