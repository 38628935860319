<template>
  <div>
    <ModalWindow v-if="errorMessage" :message="errorMessage" @close="close" />
  </div>
</template>

<script setup>
import ModalWindow from '@/components/_generic/ui/modals/ModalWindow.vue';
import { onBeforeMount, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { tracer } from '@/main.js';
import { getProp } from '@/libs/utils.js';

const router = useRouter();
const route = useRoute();

onBeforeMount(() => {
  init();
});

const init = () => {
  tracer.onTrace = updateErrorMessage;
};

const getErrorMessage = () => {
  let all = [];
  if (tracer) {
    all = tracer.getLog();
  }
  const onlyErrors = all.filter((item) => item.error);
  const onlyServerErrors = onlyErrors.filter(
    (item) => getProp(item.error, 'status', 0) > 0,
  );
  const error = getProp(onlyServerErrors.pop(), 'error', {
    status: 0,
    message: '',
  });
  if (error.status === 401) {
    router.push({
      name: 'login',
      query: route.query,
    });
    // 401
    return '';
  } else if (error.status === 429) {
    // 429
    return 'Вы отправляете запросы слишком часто. Подождите несколько секунд и повторите запрос.';
  } else if (error.status < 500) {
    // 0, 4xx
    return error.message;
  } else if (error.status >= 500) {
    return `К сожалению, произошла техническая ошибка. 
          Наша техническая команда уже занимается ее исправлением. 
          Приносим свои извинения за доставленные неудобства.`;
  }
};

const errorMessage = ref(getErrorMessage());

const updateErrorMessage = () => {
  errorMessage.value = getErrorMessage();
};

const close = () => {
  tracer.clearLog();
  updateErrorMessage();
};
</script>

<style lang="scss" scoped></style>
