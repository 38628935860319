<template>
  <div>
    <div
      v-if="props.type === 'overdue'"
      class="notify d-flex justify-content-between align-items-center mb-6"
    >
      <div class="d-flex">
        <i class="icon-notify ms-4"></i>
        <span>
          у вас просрочка
          <span> {{ overdueAmount }}</span>
          <span> ₽, пополните баланс на эту сумму как можно быстрее</span>
        </span>
      </div>
      <div
        class="notify-btn d-flex align-items-center cursor-pointer"
        @click="router.push({ name: 'accountRefill' })"
      >
        <span class="btn-text text-danger"
          ><span class="me-2">пополнить баланс</span
          ><i v-if="!isMobile" class="btn-arrow"></i
        ></span>
      </div>
    </div>

    <div
      v-if="props.type === 'penalty'"
      class="notify d-flex justify-content-between align-items-center mb-6"
    >
      <div class="d-flex">
        <i class="icon-notify ms-4"></i>
        <span>
          у вас задолженность по штрафам платформы
          <span>
            {{
              formatCurrency(store.currentCompany?.funds?.overdue?.penalty)
            }}</span
          >
          <span> ₽, пополните баланс на эту сумму как можно быстрее</span>
        </span>
      </div>
      <div
        class="notify-btn d-flex align-items-center cursor-pointer"
        @click="router.push({ name: 'accountRefill' })"
      >
        <span class="btn-text text-danger"
          ><span class="me-2">пополнить баланс</span
          ><i v-if="!isMobile" class="btn-arrow"></i
        ></span>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { formatCurrency } from '@/utils';
import { useRouter } from 'vue-router';
import { detectMobile } from '@/utils';

const store = useStateStore();
const router = useRouter();
const { isMobile } = detectMobile();

const props = defineProps({
  type: {
    type: String,
    default: 'overdue',
  },
});

const overdueAmount = computed(() => {
  return formatCurrency(
    store.currentCompany.funds?.overdue?.main +
      store.currentCompany.funds?.overdue?.interest +
      store.currentCompany.funds?.overdue?.fine +
      (store.isBillingMonthlyCommission
        ? store.currentCompany.funds?.overdue?.monthly_commission
        : 0),
  );
});
</script>

<style lang="scss" scoped>
.notify {
  padding: 18px 18px 18px 0;
  background: rgba(255, 87, 84, 0.1);
  border-left: 3px solid #ff5754;
  border-radius: 8px;
  .notify-btn {
    &:hover {
      .btn-text,
      .btn-arrow {
        opacity: 0.6;
      }
    }
  }
}
</style>
