export const STATEMENT_EVENTS = {
  SPA_LK_Vipiska_show_screen_main: 'SPA_LK_Vipiska_show_screen: main',
  SPA_LK_Vipiska_problems_click_where: 'SPA_LK_Vipiska_problems_click: where',
  SPA_LK_Vipiska_click_delete: 'SPA_LK_Vipiska_click_delete',
  SPA_LK_Vipiska_download_success: 'SPA_LK_Vipiska_download: success',
  SPA_LK_Vipiska_show_screen_some_problems:
    'SPA_LK_Vipiska_show_screen: some problems',
  SPA_LK_Vipiska_some_problems_click_continius:
    'SPA_LK_Vipiska_ some_problems_click: continius',
  SPA_LK_Vipiska_show_screen_all_problems:
    'SPA_LK_Vipiska_show_screen: all problems',
  SPA_LK_Vipiska_all_problems_click_try_again:
    'SPA_LK_Vipiska_all_problems_click: try again',
};
