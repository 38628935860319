<template>
  <div>
    <div
      v-if="!isOpenPromoDiscountLevelMessage"
      class="loyalty-widget d-flex align-items-center"
      @mouseover="isShowDetailMessage = true"
      @mouseleave="isShowDetailMessage = false"
      @click.stop="onClickShowModal"
    >
      <template v-if="!isShowDetailMessage">
        <i
          class="me-2"
          :class="{
            'icon-loyalty-level-0': discountPercent.uiLevel === 0,
            'icon-loyalty-level-1': discountPercent.uiLevel === 1,
            'icon-loyalty-level-2': discountPercent.uiLevel === 2,
            'icon-loyalty-level-3': discountPercent.uiLevel === 3,
            'icon-loyalty-level-4': discountPercent.uiLevel === 4,
            'icon-loyalty-level-5': discountPercent.uiLevel === 5,
          }"
        ></i>
        <div>
          <div class="group-text-title">
            {{ discountPercent.discount }}% скидка на комиссию
          </div>
        </div> </template
      ><template v-if="isShowDetailMessage">
        <div class="potok-text-body-2 d-flex align-items-center m-auto">
          подробнее <i class="ms-2 icon-arrow"></i>
        </div>
      </template>
    </div>

    <OnClickOutside
      v-if="isOpenPromoDiscountLevelMessage"
      @trigger="closePromoDiscountLevel"
    >
      <div
        class="loyalty-widget loyalty-widget-small d-flex align-items-center"
      >
        <div class="d-flex align-items-center">
          <i class="icon-loyalty-promo me-3"></i>ваша скидка
        </div>
        <div
          v-if="isOpenPromoDiscountLevelMessage"
          class="promo-discount-level-wrapper"
        >
          <div class="null-discount-level">
            <div class="potok-text-h2">программа «надежный партнер»</div>
            <div class="potok-text-body-2 promo-discount-level-wrapper-desc">
              получайте скидку на комиссию платформы уже со второго транша!
            </div>
          </div>
        </div>
      </div>
    </OnClickOutside>

    <ModalRight v-if="isShowModalHelp" @close-modal="onClickCloseModal">
      <template #title>программа «надежный партнер»</template>
      <template #content>
        <div class="potok-text-body-1 mb-4">
          получайте скидку на комиссию платформы уже со второго транша!
        </div>
        <div class="level-description mb-6">
          <div class="d-flex">
            <div class="level-description-value me-3">
              {{ discountPercent.discount }}%
            </div>
            <div class="potok-text-body-1-bold">
              ваша текущая скидка на комиссию Потока
            </div>
          </div>
          <ProgressPointLoyalty
            class="mt-3"
            :current-value="discountPercent.discount"
          />
        </div>
        <div class="potok-text-body-1-bold mb-2">подробнее о программе</div>
        <div class="potok-text-body-1 mb-4">
          Правила просты: для получения скидки вам необходимо брать транши и
          погашать их без просрочек. Каждый шестой месяц выплат повышает уровень
          вашей компании, и вы получаете скидку на комиссию платформы!
          <br />
          <br />
          Например, вы взяли первый транш на 6 месяцев и выплачиваете его. В
          последний месяц займа уровень компании автоматически повысится, и вы
          получите скидку на комиссию платформы на все следующие транши.
          <br />
          <br />
          Чем выше уровень компании, тем выше скидка на комиссию. И самое
          приятное, ваша история сохраняется и не сгорает, поэтому вы можете
          наслаждаться преимуществами программы в любое время.
          <br />
          <br />
          <a
            href="https://files.potok.digital/Документы/маркетинговые акции/Акция_Надежный партнер.pdf"
            target="_blank"
            class="color-grey"
          >
            Правила программы</a
          >
        </div>
      </template>
    </ModalRight>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import ModalRight from '@/components/_generic/ui/modals/ModalRight.vue';
import tracker from '@/tracker';
import ProgressPointLoyalty from '@/components/auth/dashboard/loyalty-widget/ProgressPointLoyalty.vue';
import { OnClickOutside } from '@vueuse/components';
import { detectMobile } from '@/utils';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Discount_program_Info_screen_open,
  SPA_LK_Discount_program_Info_screen_close,
} = TRACKER_EVENTS;

const store = useStateStore();

const { isMobile } = detectMobile();

const isShowDetailMessage = ref(false);
const isShowModalHelp = ref(false);
const isOpenPromoDiscountLevelMessage = ref(false);

const discountPercent = computed(() => {
  const level = store.currentCompany?.discount_level;
  if (!level || level === 0) {
    return {
      uiLevel: 0,
      discount: 0,
    };
  }
  if (level === 1) {
    return {
      uiLevel: 1,
      discount: 15,
    };
  }
  if (level === 2) {
    return {
      uiLevel: 2,
      discount: 25,
    };
  }
  if (level === 3) {
    return {
      uiLevel: 3,
      discount: 30,
    };
  }
  if (level === 4) {
    return {
      uiLevel: 4,
      discount: 35,
    };
  }
  if (level > 4) {
    return {
      uiLevel: 5,
      discount: 40,
    };
  }
  return {
    uiLevel: 0,
    discount: 0,
  };
});

const onClickShowModal = () => {
  isShowModalHelp.value = true;
  tracker.queue(tracker.commands.PARAMS, {
    discount_level: store.currentCompany.discount_level,
  });
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Discount_program_Info_screen_open,
  );
};
const onClickCloseModal = () => {
  isShowModalHelp.value = false;
  tracker.queue(tracker.commands.PARAMS, {
    discount_level: store.currentCompany.discount_level,
  });
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Discount_program_Info_screen_close,
  );
};

const checkShowPromoDiscountLevel = () => {
  if (
    isMobile &&
    store.currentCompany.discount_level === 0 &&
    !store.uiStates.promoDiscountLevelShowed
  ) {
    isOpenPromoDiscountLevelMessage.value = true;
  }
};

const closePromoDiscountLevel = () => {
  isOpenPromoDiscountLevelMessage.value = false;
  store.setUiState('promoDiscountLevelShowed', true);
};

onMounted(() => {
  checkShowPromoDiscountLevel();
});
</script>

<style lang="scss" scoped>
.loyalty-widget {
  min-width: 296px;
  height: 64px;
  cursor: pointer;
  background-color: $white;
  border-radius: 8px;
  padding: 6px 14px;
  position: relative;
  @media (max-width: $size_767) {
    min-width: 203px;
    height: 34px;
    padding: 6px 10px;
  }
}
.loyalty-widget-small {
  min-width: 180px !important;
}

.promo-discount-level-wrapper {
  position: absolute;
  top: 80px;
  right: 0;
  background-color: $breakwater;
  max-width: 327px;
  min-width: 320px;
  color: white;
  padding: 30px 20px 30px 20px;
  border-radius: 14px;
  z-index: 10;
  @media (max-width: $size_767) {
    top: 50px;
  }
  &::after {
    content: '';
    width: 0;
    height: 0;
    display: block;
    position: absolute;
    z-index: 10;
    border: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    margin-left: -10px;
    right: 10%;
    border-bottom: 10px solid $breakwater;
    top: -10px;
  }
}

.promo-discount-level-wrapper-desc {
  margin-top: 20px;
}

.icon-loyalty-promo {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(@/assets/icons/loyalty-widget/loyalty-promo.svg);
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: $size_767) {
    width: 16px;
    height: 16px;
  }
}

.icon-loyalty-level-0 {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(@/assets/icons/loyalty-widget/loyalty-level-0.svg);
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: $size_767) {
    width: 16px;
    height: 16px;
  }
}
.icon-loyalty-level-1 {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(@/assets/icons/loyalty-widget/loyalty-level-1.svg);
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: $size_767) {
    width: 16px;
    height: 16px;
  }
}

.icon-loyalty-level-2 {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(@/assets/icons/loyalty-widget/loyalty-level-2.svg);
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: $size_767) {
    width: 16px;
    height: 16px;
  }
}

.icon-loyalty-level-3 {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(@/assets/icons/loyalty-widget/loyalty-level-3.svg);
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: $size_767) {
    width: 16px;
    height: 16px;
  }
}

.icon-loyalty-level-4 {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(@/assets/icons/loyalty-widget/loyalty-level-4.svg);
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: $size_767) {
    width: 16px;
    height: 16px;
  }
}

.icon-loyalty-level-5 {
  display: inline-block;
  width: 30px;
  height: 30px;
  background-image: url(@/assets/icons/loyalty-widget/loyalty-level-5.svg);
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: $size_767) {
    width: 16px;
    height: 16px;
  }
}

.icon-arrow {
  display: inline-block;
  width: 15px;
  height: 10px;
  background-image: url(@/assets/icons/loyalty-widget/arrow.svg);
  background-repeat: no-repeat;
  background-position: center;
}

.group-text-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  @media (max-width: $size_767) {
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 166.667% */
    letter-spacing: -0.12px;
  }
}
.group-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.12px;
}
.level-description {
  border-radius: 14px;
  background-color: $background;
  padding: 20px;
  color: $dipedive;
  .level-description-value {
    font-size: 50px;
    font-style: normal;
    font-weight: 500;
    line-height: 50px; /* 100% */
    letter-spacing: -0.5px;
    text-transform: lowercase;
  }
}
</style>
