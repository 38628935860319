<template>
  <NavMenu v-model:isMenuShown="isMenuShown">
    <template #content>
      <template v-for="item in menuItems" :key="item.id">
        <a
          class="menu-list d-flex align-items-center justify-content-between"
          :href="goToNDFL()"
        >
          <div class="d-flex">
            <i class="icon"></i>
            <div class="menu-text me-7">
              <span>{{ item.title }}</span>
              <span class="d-block color-grey mt-1">{{ item.text }}</span>
            </div>
          </div>
          <span class="icon-arrow"></span>
        </a>
      </template>
    </template>
  </NavMenu>
</template>

<script setup>
import NavMenu from '@/components/_generic/auth/dashboard/NavMenu.vue';
import { computed } from 'vue';
import { createLinkRedirection } from '@/server/index';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const isMenuShown = computed({
  get() {
    return props.isMenuShown;
  },
  set(value) {
    emit('update:isMenuShown', value);
  },
});

const props = defineProps({
  isMenuShown: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:isMenuShown']);

const menuItems = [
  {
    id: 1,
    title: '6 НДФЛ',
    text: 'с 2021 года',
  },
];

const goToNDFL = () => {
  return createLinkRedirection(
    '/pvp/business/companies/' + store.currentCompany.id + '/ndfl6',
  );
};
</script>

<style lang="scss" scoped>
.menu-list {
  padding: 12px 10px;
  border-radius: 8px;
  &:hover {
    background-color: $background;
    .icon {
      filter: $filter-breakwater;
    }
    .icon-arrow {
      display: block;
    }
  }
  .icon {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 14px;
    background-image: url(@/assets/icons/note.svg);
  }
  .icon-arrow {
    display: none;
  }
}
</style>
