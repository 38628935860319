<template>
  <OnClickOutside @trigger="emit('close')">
    <div class="modal-commision-wrapper potok-text-body-1">
      <div class="modal-commision-row">
        <label for="one" @click="onClickSelect('monthly_commission')">
          <input
            id="one"
            v-model="selectedValue"
            class="checkbox"
            type="radio"
            value="monthly_commission"
          />
          <div class="checkmark"></div>
          <div class="title">ежемесячная в платеже по займу</div></label
        >
      </div>
      <div class="modal-commision-row">
        <label for="two" @click="onClickSelect('upfront_percent')">
          <input
            id="two"
            v-model="selectedValue"
            class="checkbox"
            type="radio"
            value="upfront_percent"
          />
          <div class="checkmark"></div>
          <div class="title">однократная при выдаче займа</div></label
        >
      </div>
    </div>
  </OnClickOutside>
</template>
<script setup>
import { computed } from 'vue';
import { OnClickOutside } from '@vueuse/components';
import tracker from '@/tracker';

const props = defineProps({
  modelValue: {
    type: [Number, String, null],
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'close']);

const selectedValue = computed({
  get() {
    return props.modelValue;
  },
  set(localValue) {
    emit('update:modelValue', localValue);
  },
});

const onClickSelect = (localValue) => {
  if (localValue === 'upfront_percent') {
    tracker.queue(tracker.commands.SEND, 'SPA_VKL_commision_type_upfront');
  }
  if (localValue === 'monthly_commission') {
    tracker.queue(tracker.commands.SEND, 'SPA_VKL_commision_type_split');
  }
  emit('update:modelValue', localValue);
  emit('close');
};
</script>
<style lang="scss" scoped>
.modal-commision-wrapper {
  position: absolute;
  top: 0;
  left: -20px;
  background-color: white;
  min-width: 340px;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  z-index: 1000;
  border-radius: 14px;
  padding: 14px 10px;
}
input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkmark {
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background-color: white;
  border: 1px solid #ccc;
}
input:checked ~ .checkmark {
  border-radius: 50%;
  background-color: white;
  border: 6px solid $breakwater;
}

.modal-commision-row {
  position: relative;
  margin-bottom: 5px;
  padding: 10px;
  cursor: pointer;
  border-radius: 8px;
  label {
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &:last-child {
    margin-bottom: initial;
  }
  &:hover {
    background-color: $background;
  }
}
.title {
  margin-left: 5px;
}
</style>
