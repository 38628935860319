import server from '@/server/index';
import { ref } from 'vue';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';
import { useStateStore } from '@/stores/stateStore';

const { SPA_LK_Loan_schedule_switch, SPA_LK_Loan_history_switch } =
  TRACKER_EVENTS;

export function useLoanPaymentUtils() {
  const store = useStateStore();

  const payments = ref([]);
  const payouts = ref([]);
  const loading = ref(false);
  const skeletonLoading = ref(false);
  const errorsArr = ref([]);
  const paymentsPage = ref(1);
  const payoutsPage = ref(1);
  const paymentsPer = ref(10);
  const payoutsPer = ref(10);
  const paymentsTotalPages = ref(0);
  const payoutsTotalPages = ref(0);
  const isScheduleTabActive = ref(true);
  const isDocsListOpen = ref(false);

  const getCommonSchedulePayments = (options, withLoader = false) => {
    const query = {
      company_id: options.companyId,
      loan_id: options.loanId,
      page: options.currentPage,
      per: options.perPage,
    };
    skeletonLoading.value = !withLoader;
    loading.value = withLoader;
    return server.getCommonSchedulePayments
      .send(query, {})
      .pipe(onGetCommonSchedulePaymentsSuccess, (error) => {
        errorsArr.value = error.data?.messages;
        loading.value = false;
        skeletonLoading.value = false;
      })
      .exec();
  };

  const onGetCommonSchedulePaymentsSuccess = ({ response, data }) => {
    paymentsTotalPages.value = Number(response.headers.get('x-totalpages'));
    paymentsPage.value === 1
      ? (payments.value = data)
      : (payments.value = [...payments.value, ...data]);
    loading.value = false;
    skeletonLoading.value = false;
  };

  const getPayoutsAsync = (options, withLoader = false) => {
    const query = {
      loan_id: options.loanId,
      page: options.currentPage,
      per: options.perPage,
    };
    skeletonLoading.value = !withLoader;
    loading.value = withLoader;
    return server.getPayouts
      .send(query, {})
      .pipe(getPayoutsAsyncSuccess, (error) => {
        errorsArr.value = error.data?.messages;
        loading.value = false;
        skeletonLoading.value = false;
      })
      .exec();
  };

  const getPayoutsAsyncSuccess = ({ response, data }) => {
    data.forEach((el) => {
      el.status = el.status.toUpperCase();
      el.payments = [];
      el.payments.push({
        order_id: el.loan_id,
        main: el.main,
        ndfl: el.ndfl,
        interest: el.interest,
        fine: el.fine,
        fine_tax: el.fine_tax,
        monthly_commission: el.monthly_commission,
      });
    });
    payoutsTotalPages.value = Number(response.headers.get('x-totalpages'));
    payoutsPage.value === 1
      ? (payouts.value = data)
      : (payouts.value = [...payouts.value, ...data]);
    loading.value = false;
    skeletonLoading.value = false;
  };

  const getAmountPayment = (item) => {
    let amount = 0;
    if (item.status === 'REFUNDED') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    if (item.status === 'REFUNDED_AFTER_OVERDUE') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        amount += payment.fine;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    if (item.status === 'OVERDUE') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        amount += payment.fine;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    if (item.status === 'AWAIT') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        amount += payment.ndfl;
        amount += payment.monthly_commission;
      });
    }
    if (item.status === 'ACTIVE') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    return amount;
  };
  const getPayoutsAmountPayment = (item) => {
    let amount = 0;
    if (item.status === 'REFUNDED') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        amount += payment.ndfl;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    if (item.status === 'REFUNDED_AFTER_OVERDUE') {
      item.payments.forEach((payment) => {
        amount += payment.main;
        amount += payment.interest;
        amount += payment.fine;
        amount += payment.ndfl;
        if (store.isBillingMonthlyCommission) {
          amount += payment.monthly_commission;
        }
      });
    }
    return amount;
  };
  const getStatusTitlePayment = (payment) => {
    const statuses = {
      REFUNDED: 'выплачен',
      REFUNDED_AFTER_OVERDUE: 'выплачен после просрочки',
      AWAIT: 'следующий платеж',
      OVERDUE: 'просрочен',
      ACTIVE: 'следующий платеж',
    };
    return statuses[payment.status];
  };
  const getStatusClassPayment = (payment) => {
    const statuses = {
      REFUNDED: 'payment-item-icon-green',
      REFUNDED_AFTER_OVERDUE: 'payment-item-icon-yellow',
      OVERDUE: 'payment-item-icon-red',
      AWAIT: 'payment-item-icon-default',
      ACTIVE: 'payment-item-icon-default',
    };
    return statuses[payment.status];
  };
  const onClickSwitchToPayments = () => {
    if (!isScheduleTabActive.value) {
      tracker.queue(tracker.commands.SEND, SPA_LK_Loan_schedule_switch);
    }
    isScheduleTabActive.value = true;
  };
  const onClickSwitchToPayouts = () => {
    if (isScheduleTabActive.value) {
      tracker.queue(tracker.commands.SEND, SPA_LK_Loan_history_switch);
    }
    isScheduleTabActive.value = false;
  };

  return {
    payments,
    payouts,
    loading,
    skeletonLoading,
    errorsArr,
    paymentsPage,
    payoutsPage,
    paymentsPer,
    payoutsPer,
    paymentsTotalPages,
    payoutsTotalPages,
    isScheduleTabActive,
    isDocsListOpen,
    getCommonSchedulePayments,
    getPayoutsAsync,
    getAmountPayment,
    getPayoutsAmountPayment,
    getStatusTitlePayment,
    getStatusClassPayment,
    onClickSwitchToPayments,
    onClickSwitchToPayouts,
  };
}
