<template>
  <table
    class="small"
    :class="[isMobile ? 'table-mobile w-100 mt-3' : 'table']"
  >
    <tbody>
      <template v-for="param in orderParams" :key="param.id">
        <template v-if="!isMobile">
          <td v-if="shouldShowSection(props.order, param.type)">
            <tr class="color-grey">
              {{
                param.name
              }}
              <OverdueHint
                v-if="param.type === 'overdue'"
                class="ms-1"
                :main="props.order.loan?.payments?.overdue?.main"
                :interest="props.order.loan?.payments?.overdue?.interest"
                :fine="props.order.loan?.payments?.overdue?.fine"
                :fine-tax="props.order.loan?.payments?.overdue?.fine_tax"
                :ndfl="props.order.loan?.payments?.overdue?.ndfl"
                :monthly-commission="
                  props.order.loan?.payments?.overdue?.monthly_commission
                "
              />
            </tr>
            <tr
              class="fw-bold"
              :class="
                detectTextColor(
                  param.type,
                  props.order.loan?.payments?.overdue?.monthly_commission,
                )
              "
            >
              {{
                getParamValue(props.order, param.type)
              }}
            </tr>
          </td>
        </template>
        <template v-if="isMobile">
          <tr v-if="shouldShowSection(props.order, param.type)">
            <td class="color-grey">
              {{ param.name }}
              <OverdueHint
                v-if="param.type === 'overdue'"
                class="ms-1"
                :main="props.order.loan?.payments?.overdue?.main"
                :interest="props.order.loan?.payments?.overdue?.interest"
                :fine="props.order.loan?.payments?.overdue?.fine"
                :fine-tax="props.order.loan?.payments?.overdue?.fine_tax"
                :ndfl="props.order.loan?.payments?.overdue?.ndfl"
                :monthly-commission="
                  props.order.loan?.payments?.overdue?.monthly_commission
                "
              />
            </td>
            <td
              class="text-end"
              :class="
                detectTextColor(
                  param.type,
                  props.order.loan?.payments?.overdue?.monthly_commission,
                )
              "
            >
              {{ getParamValue(props.order, param.type) }}
            </td>
          </tr>
        </template>
      </template>
    </tbody>
  </table>
</template>

<script setup>
import OverdueHint from '@/components/auth/dashboard/modules/OverdueHint.vue';
import { formatDate, formatCurrency, pluralize } from '@/utils';
import { ref } from 'vue';
import constants from '@/constants';
import { detectMobile } from '@/utils';
import { calculateAnnualRate } from '@/components/auth/dashboard/loan-terms/utils';
import { useStateStore } from '@/stores/stateStore';

const { isMobile } = detectMobile();
const store = useStateStore();
const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
});

const orderParams = ref([
  {
    id: 1,
    type: 'sum',
    name: 'сумма',
  },
  {
    id: 2,
    type: 'term',
    name: 'срок',
  },
  {
    id: 3,
    type: 'payment',
    name: 'платеж',
  },
  {
    id: 4,
    type: 'commission',
    name: 'комиссия',
  },
  {
    id: 5,
    type: 'date',
    name: 'дата платежа',
  },
  {
    id: 6,
    type: 'frequency',
    name: 'регулярность',
  },
  {
    id: 7,
    type: 'overdue',
    name: 'просрочка',
  },
  {
    id: 8,
    type: 'overdue_next_payment',
    name: 'сл. платеж',
  },
  {
    id: 9,
    type: 'overdue_date',
    name: 'дата платежа',
  },
  {
    id: 10,
    type: 'requirement',
    name: 'требование',
  },
  {
    id: 11,
    type: 'main_debt',
    name: 'осн. долг',
  },
  {
    id: 12,
    type: 'interest',
    name: 'проценты',
  },
  {
    id: 13,
    type: 'fine',
    name: 'пеня',
  },
  {
    id: 14,
    type: 'ndfl',
    name: 'ндфл',
  },
  {
    id: 15,
    type: 'rate',
    name: 'ставка',
  },
]);

const detectTextColor = (type, commission) => {
  switch (type) {
    case 'commission':
      return commission ? 'text-danger' : '';
    case 'overdue':
    case 'requirement':
      return 'text-danger';
    default:
      return '';
  }
};
const calculateFrequency = (freq) => {
  switch (freq) {
    case 7:
      return 'еженедельно';
    case 14:
      return 'раз в две недели';
    case 30:
      return 'ежемесячно';
  }
};
const getParamValue = (order, type) => {
  switch (type) {
    case 'sum':
      return formatCurrency(order.loan?.amount || order.amount, 0) + ' ₽';
    case 'term':
      return (
        order.loan_period / 30 +
        ' месяц' +
        pluralize(order.loan_period / 30, ['', 'а', 'ев'])
      );
    case 'payment':
      if (order.loan?.payments?.next_payment) {
        let value =
          order.loan?.payments?.next_payment?.main +
          order.loan?.payments?.next_payment?.interest +
          order.loan?.payments?.next_payment?.ndfl;
        value += order.loan?.payments?.next_payment?.monthly_commission;
        return formatCurrency(value) + ' ₽';
      }
      return formatCurrency(order.regular_payment_amount) + ' ₽';

    case 'commission':
      return order.loan?.payments?.overdue?.monthly_commission
        ? formatCurrency(
            order.loan?.payments?.next_payment?.monthly_commission +
              order.loan?.payments?.overdue?.monthly_commission,
          ) + ' ₽'
        : formatCurrency(
            order.loan?.payments?.next_payment?.monthly_commission,
          ) + ' ₽';
    case 'date':
      return order.loan?.state === constants.LOAN_STATES.overdue
        ? formatDate(order.loan?.payments?.overdue?.date)
        : formatDate(order.loan?.payments?.next_payment?.date);
    case 'frequency':
      return calculateFrequency(order.repayment_frequency);
    case 'overdue': {
      let value = 0;
      if (order.loan?.payments?.overdue?.main) {
        value += order.loan?.payments?.overdue?.main;
      }
      if (order.loan?.payments?.overdue?.interest) {
        value += order.loan?.payments?.overdue?.interest;
      }
      if (order.loan?.payments?.overdue?.penalty) {
        value += order.loan?.payments?.overdue?.penalty;
      }
      if (order.loan?.payments?.overdue?.fine) {
        value += order.loan?.payments?.overdue?.fine;
      }
      if (
        store.isBillingMonthlyCommission &&
        order.loan?.payments?.overdue?.monthly_commission
      ) {
        value += order.loan?.payments?.overdue?.monthly_commission;
      }
      return formatCurrency(value) + ' ₽';
    }
    case 'overdue_next_payment':
      return (
        formatCurrency(
          order.loan?.payments?.next_payment?.main +
            order.loan?.payments?.next_payment?.interest +
            order.loan?.payments?.next_payment?.ndfl +
            order.loan?.payments?.next_payment?.monthly_commission,
        ) + ' ₽'
      );
    case 'overdue_date':
      return formatDate(order.loan?.payments?.next_payment?.date);
    case 'requirement':
      return 'нет данных';
    case 'main_debt':
      return order.loan?.state === constants.LOAN_STATES.overdue
        ? formatCurrency(order.loan?.payments?.overdue?.main, 0) + ' ₽'
        : formatCurrency(order.loan?.payments?.next_payment?.main, 0) + ' ₽';
    case 'interest':
      return order.loan?.state === constants.LOAN_STATES.overdue
        ? order.loan?.payments?.overdue?.interest
          ? formatCurrency(order.loan?.payments?.overdue?.interest, 0) + ' ₽'
          : 'нет данных'
        : order.loan?.payments?.next_payment?.interest
          ? formatCurrency(order.loan?.payments?.next_payment?.interest, 0) +
            ' ₽'
          : 'нет данных';
    case 'fine':
      return order.loan?.state === constants.LOAN_STATES.overdue
        ? formatCurrency(order.loan?.payments?.overdue?.fine, 0) + ' ₽'
        : formatCurrency(order.loan?.payments?.next_payment?.fine, 0) + ' ₽';
    case 'ndfl':
      return order.loan?.state === constants.LOAN_STATES.overdue
        ? formatCurrency(order.loan?.payments?.overdue?.ndfl, 0) + ' ₽'
        : formatCurrency(order.loan?.payments?.next_payment?.ndfl, 0) + ' ₽';
    case 'rate':
      return (
        calculateAnnualRate(
          order.loan_period / 30,
          order.loan_terms_offer?.terms,
          order?.rate,
        ) + '%'
      );
  }
};
const shouldShowSection = (order, type) => {
  const state = order.loan?.state || order.state;
  const commission =
    order.loan?.payments?.overdue?.monthly_commission ||
    order.loan?.payments?.next_payment?.monthly_commission;
  const commonCase = [
    constants.LOAN_STATES.investing,
    constants.LOAN_STATES.invested,
    constants.LOAN_STATES.cancelled,
    constants.ORDER_STATES.user_verifying,
    constants.ORDER_STATES.rejected,
    constants.ORDER_STATES.self_destructed,
    constants.ORDER_STATES.founders_assessing,
    constants.ORDER_STATES.filling_full_borrower_profile,
    constants.ORDER_STATES.done_decision_system_statements,
    constants.ORDER_STATES.filling_borrower_profile,
    constants.ORDER_STATES.statement_file_assessing,
    constants.ORDER_STATES.done_decision_system_inn,
    constants.ORDER_STATES.custom_params_asking,
    constants.ORDER_STATES.waiting_decision_system_founders,
    constants.ORDER_STATES.waiting_for_decision,
    constants.ORDER_STATES.waiting_decision_system_inn,
    constants.ORDER_STATES.waiting_decision_system_statements,
    constants.ORDER_STATES.ready_for_billing,
    constants.ORDER_STATES.sent_to_billing,
    constants.ORDER_STATES.control_statement_file_assessing,
    constants.ORDER_STATES.monitoring,
  ];
  switch (type) {
    case 'sum':
      return commonCase.includes(state);
    case 'term':
      return (
        commonCase.includes(state) ||
        state === constants.LOAN_STATES.repaid ||
        state === constants.ORDER_STATES.user_destructed
      );
    case 'payment':
      return (
        commonCase.includes(state) || state === constants.LOAN_STATES.billing
      );
    case 'commission':
      if (store.isBillingMonthlyCommission) {
        return false;
      }
      return commission ? state === constants.LOAN_STATES.billing : false;
    case 'date':
      return state === constants.LOAN_STATES.billing;
    case 'frequency':
      return commission
        ? false
        : state === constants.LOAN_STATES.billing ||
            state === constants.LOAN_STATES.investing ||
            state === constants.LOAN_STATES.invested ||
            state === constants.ORDER_STATES.user_verifying;
    case 'overdue':
    case 'overdue_next_payment':
    case 'overdue_date':
      return state === constants.LOAN_STATES.overdue;
    case 'requirement':
    case 'ndfl':
      return state === constants.LOAN_STATES.in_cession;
    case 'main_debt':
    case 'interest':
    case 'fine':
      return (
        state === constants.LOAN_STATES.in_cession ||
        state === constants.LOAN_STATES.repaid ||
        state === constants.ORDER_STATES.user_destructed
      );
    case 'rate':
      return calculateAnnualRate(
        order.loan_period / 30,
        order.loan_terms_offer?.terms,
      )
        ? state === constants.LOAN_STATES.repaid ||
            state === constants.ORDER_STATES.user_destructed
        : false;
    default:
      return false;
  }
};
</script>

<style lang="scss" scoped>
.table {
  td:not(:first-child) {
    padding-left: 30px;
  }
}
.table-mobile {
  tr td {
    padding: 16px 0;
    border-top: 1px solid $dividers;
  }
}
</style>
