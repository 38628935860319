<template>
  <div class="title fw-bold">
    <slot name="title"></slot>
  </div>
  <MobileToggle v-if="isMobile" v-model="isQrSelected" class="mb-4" />
  <div class="d-block d-md-flex justify-content-between">
    <div
      v-if="!isMobile || (isMobile && !isQrSelected)"
      class="form flex-grow-1"
    >
      <div class="form__title">
        <slot name="description"></slot>
      </div>
      <table class="form__table">
        <tbody>
          <tr v-for="el in tableContent" :key="el.id">
            <template v-if="showIfAmountExists(el.name)">
              <td class="table-name">{{ el.title }}</td>
              <td class="table-value" @click="copyToClipboard(el.value, el.id)">
                {{ el.value }}<i class="copy_icon"></i
                ><i
                  v-if="copied && el.id === copiedItemIndex"
                  class="popover__content"
                  >👍 скопировано</i
                >
              </td>
            </template>
          </tr>
        </tbody>
      </table>
      <div class="guarantor-wrapper">
        <div class="color-grey small text-notice">
          *платежи со счета в сбербанке могут приходить с задержкой в 1 рабочий
          день
        </div>
        <div v-if="type === 'guarantor'" class="text-download">
          <i class="icon-download"></i
          ><a class="ms-1" @click="onClickOpenDoc('offer')"
            ><span v-if="!isOrderVKL">скачать оферту поручителя</span>
            <span v-if="isOrderVKL"
              >cкачать соглашение и гарантию-поручительство
            </span></a
          >
        </div>
      </div>
    </div>
    <div
      v-if="!isMobile || (isMobile && isQrSelected)"
      class="form ms-0 ms-md-6"
    >
      <div class="form__title mb-4">по qr коду</div>
      <vue-qr-code-payment
        class="code_payment"
        :payee-name="paymentData.payeeName"
        :payee-inn="paymentData.payeeInn"
        :payee-kpp="paymentData.payeeKpp"
        :payee-personal-acc="paymentData.payeePersonalAcc"
        :payee-bank-name="paymentData.payeeBankName"
        :payee-bank-bic="paymentData.payeeBankBic"
        :payee-bank-corresp-acc="paymentData.payeeBankCorrespAcc"
        :purpose="paymentData.purpose"
        :sum="calculateAmount()"
        :width="isMobile ? 280 : 335"
      />
      <span class="mt-4 small d-inline-block"
        >отсканируйте qr код в мобильном <br />
        приложении банка</span
      >
      <div
        v-if="isMobile"
        class="text-tiffany small mt-4"
        @click="isModalOpen = true"
      >
        как отсканировать из галереи?
      </div>
      <div
        v-if="isMobile"
        class="form__btn d-flex align-items-center mb-0 mb-sm-3 mt-4"
        @click="onClickTakeScreenshot('.code_payment')"
      >
        <i></i>
        скачать qr код
      </div>
      <HowToScanModal v-if="isModalOpen" @close-modal="isModalOpen = false" />
    </div>
  </div>
  <div class="d-flex flex-column-reverse flex-md-row align-items-center pb-6">
    <div class="btn-wrapper me-md-4 me-0 mt-4 mt-md-0 text-center">
      <PotokButton
        v-if="!loading"
        text="закрыть"
        size="large"
        @click="onClickBackToSummary()"
      />
      <LoadingBtn v-if="loading" />
    </div>
    <ErrorItem v-if="errors.length !== 0" v-model="errors" />
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import VueQrCodePayment from '@/components/_generic/auth/VueQrCodePayment.vue';
import MobileToggle from '@/components/auth/loan-card/modules/platform-rules/ui/MobileToggle.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import HowToScanModal from '@/components/auth/loan-card/modules/platform-rules/HowToScanModal.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import { useClipboard } from '@vueuse/core';
import { ref, computed, onMounted, reactive, toRefs } from 'vue';
import { detectMobile } from '@/utils';
import { useRouter } from 'vue-router';
import { useStateStore } from '@/stores/stateStore';
import { formatCurrency } from '@/utils';
import tracker from '@/tracker';
import server, { openInNewTab } from '@/server/index';
import html2canvas from 'html2canvas';
import FileSaver from 'file-saver';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_application_1RUB_open,
  SPA_LK_VKL_application_1RUB_open,
  SPA_LK_application_guarantor_QR_downloaded,
  SPA_LK_VKL_application_guarantor_QR_downloaded,
  SPA_LK_application_1RUB_QR_dw,
  SPA_LK_VKL_application_1RUB_QR_dw,
} = TRACKER_EVENTS;

const { isMobile } = detectMobile();
const router = useRouter();
const store = useStateStore();

const paymentData = reactive({
  payeeName: '',
  payeeInn: '',
  payeeKpp: '',
  payeePersonalAcc: '',
  payeeBankBic: '',
  payeeBankCorrespAcc: '',
  payeeBankName: '',
  purpose: '',
  recipient_company_name: '',
});
const { copy, copied } = useClipboard({ paymentData });
const errors = ref([]);
const loading = ref(false);
const isModalOpen = ref(false);
const copiedItemIndex = ref(undefined);
const isQrSelected = ref(false);
const tableContent = reactive([
  {
    id: 1,
    title: 'получатель',
    value: '',
    name: 'recipient_company_name',
  },
  {
    id: 2,
    title: 'инн',
    value: '',
    name: 'recipient_inn',
  },
  { id: 3, title: 'кпп', value: '', name: 'recipient_kpp' },
  {
    id: 4,
    title: 'расчетный счет',
    value: '',
    name: 'recipient_account_number',
  },
  { id: 5, title: 'бик', value: '', name: 'recipient_bik' },
  { id: 6, title: 'корр счет', value: '', name: 'recipient_corr_number' },
  {
    id: 7,
    title: 'наименование банка',
    value: '',
    name: 'recipient_bank_name',
  },
  { id: 8, title: 'назначение', value: '', name: 'payment_purpose' },
  { id: 9, title: 'сумма к оплате', value: '', name: 'amount' },
]);

const props = defineProps({
  order: {
    type: Object,
    default() {
      return {};
    },
  },
  isPlatformRulesOpen: {
    type: Boolean,
  },
  type: {
    type: String,
    default: '',
  },
  amount: {
    type: [Number, String],
    default: 0,
  },
});
const { order, amount, type } = toRefs(props);
const emit = defineEmits(['update:isPlatformRulesOpen']);

onMounted(() => {
  getBillsAsync();
  tracker.queue(
    tracker.commands.SEND,
    order.value.credit_line
      ? SPA_LK_VKL_application_1RUB_open
      : SPA_LK_application_1RUB_open,
  );
});

const isOrderVKL = computed(() => {
  return !!props.order.credit_line;
});

// конвертируем число в строку с двумя знаками после запятой и удаляем всем пробелы и точки,
// сумма должна быть в копейках ГОСТ Р 56042-2014
const calculateAmount = () => {
  return formatCurrency(amount.value, 2)
    .replaceAll(' ', '')
    .replaceAll('.', '');
};
const showIfAmountExists = (name) => {
  switch (name) {
    case 'amount':
      return (
        (type.value === 'refill' || type.value === 'commission') && amount.value
      );
    default:
      return true;
  }
};
const onClickBackToSummary = () => {
  if (type.value === 'refill' || type.value === 'commission') {
    router.go(-1);
  } else {
    emit('update:isPlatformRulesOpen', false);
  }
};
const copyToClipboard = (value, index) => {
  copy(value);
  copiedItemIndex.value = index;
};
const onClickTakeScreenshot = (element) => {
  html2canvas(document.querySelector(element)).then((canvas) => {
    canvas.toBlob(function (blob) {
      FileSaver.saveAs(blob, 'requisites.jpg');
    });
  });
  type.value === 'guarantor'
    ? tracker.queue(
        tracker.commands.SEND,
        order.value.credit_line
          ? SPA_LK_VKL_application_guarantor_QR_downloaded
          : SPA_LK_application_guarantor_QR_downloaded,
      )
    : type.value === 'signatory'
      ? tracker.queue(
          tracker.commands.SEND,
          order.value.credit_line
            ? SPA_LK_VKL_application_1RUB_QR_dw
            : SPA_LK_application_1RUB_QR_dw,
        )
      : '';
};
const onClickOpenDoc = (type) => {
  if (type === 'offer') {
    if (!isOrderVKL.value) {
      return openInNewTab(
        '/documents/unsigned/guarantor_offer.pdf?guarantor_id=' +
          store.currentGuarantor.id +
          '&order_id=' +
          order.value.id || store.currentOrderId,
      );
    }
    return openInNewTab(
      '/spa/business/documents/unsigned/credit_line_agreement?credit_line_id=' +
        order.value.credit_line.id,
    );
  } else {
    return openInNewTab(
      '/documents/unsigned/guarantor_agreement_to_process_in_bki?order_id=' +
        order.value.id +
        store.currentOrderId +
        '&guarantor_id=' +
        store.currentGuarantor.id,
    );
  }
};
const getBillsAsync = () => {
  loading.value = true;
  let query = {};
  if (type.value === 'guarantor') {
    query.billable_type = 'Guarantor';
    query['billable_ids[]'] = store.currentGuarantor.id;
    query.kind = isOrderVKL.value
      ? 'guarantor_credit_line_agreement_signing'
      : 'guarantor_offer_signing';
  }
  if (type.value === 'refill') {
    query.billable_type = 'Company';
    query['billable_ids[]'] = store.currentCompany.id;
    query.kind = 'personal_account_replenishment';
  }
  if (type.value === 'signatory') {
    query.billable_type = 'Order';
    query['billable_ids[]'] = order.value.id || store.currentOrderId;
    query.kind = 'potok_rules_accession_signing';
  }
  if (type.value === 'commission') {
    query.billable_type = 'Order';
    query['billable_ids[]'] = order.value.id || store.currentOrderId;
    query.kind = 'monthly_commission_requisites';
  }
  return server.getBills
    .send(query)
    .pipe(onGetBillsAsyncSuccess, onGetBillsAsyncError)
    .exec();
};
const onGetBillsAsyncSuccess = ({ data }) => {
  tableContent.forEach((el, index) => {
    tableContent[index].value = data[0]?.[el.name];
  });

  const amountIndex = tableContent.findIndex((el) => el.name === 'amount');
  // если пополнение на 1Р, то нужно провести эту проверку для работы в QR кода в различных банках
  if (type.value === 'guarantor' || type.value === 'signatory') {
    tableContent[amountIndex].value = formatCurrency(amount.value / 100) + ' ₽';
  } else {
    tableContent[amountIndex].value = formatCurrency(amount.value) + ' ₽';
  }

  paymentData.payeeName = data?.[0]?.recipient_company_name;
  paymentData.payeeInn = data?.[0]?.recipient_inn;
  paymentData.payeeKpp = data?.[0]?.recipient_kpp;
  paymentData.payeePersonalAcc = data?.[0]?.recipient_account_number;
  paymentData.payeeBankBic = data?.[0]?.recipient_bik;
  paymentData.payeeBankCorrespAcc = data?.[0]?.recipient_corr_number;
  paymentData.payeeBankName = data?.[0]?.recipient_bank_name;
  paymentData.purpose = data?.[0]?.payment_purpose;
  loading.value = false;
};
const onGetBillsAsyncError = (error) => {
  errors.value = error.data?.messages;
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.guarantor-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.text-notice {
  max-width: 300px;
  @media (max-width: $size_767) {
    max-width: initial;
  }
}
.text-download {
  display: flex;
  align-items: center;
  cursor: pointer;
  max-width: 300px;
  @media (max-width: $size_767) {
    margin-top: 10px;
    max-width: initial;
  }
}
.title {
  padding: 30px 0px;
  font-size: 30px;
  line-height: 36px;
  @media (max-width: $size_575) {
    font-size: 20px;
    padding: 20px 0px;
  }
}
.form {
  width: 100%;
  max-height: 591px;
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  margin-bottom: 30px;
  &:last-child {
    max-width: 373px;
    @media (max-width: $size_767) {
      max-width: 100%;
    }
  }
  @media (max-width: $size_575) {
    max-height: 100%;
    margin-bottom: 20px;
  }
  .form__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    margin-bottom: 23px;
    color: $dipedive;
    @media (max-width: $size_575) {
      font-size: 16px;
      line-height: 24px;
    }
  }
  .table-name {
    white-space: nowrap;
  }
  .form__table {
    max-width: 518px;
    margin-bottom: 20px;
    tr {
      vertical-align: top;
      td {
        font-size: 14px;
        line-height: 22px;
        padding: 7px 0px;
        @media (max-width: $size_767) {
          font-size: 12px;
        }
      }
      td:first-child {
        color: $grey;
        padding-right: 63px;
        @media (max-width: $size_767) {
          padding-right: 10px;
        }
      }
      td:last-child {
        position: relative;
        cursor: pointer;
        display: flex;
        align-items: center;
        &:hover {
          .copy_icon {
            visibility: visible;
          }
        }
        .popover__content {
          font-size: 14px;
          font-style: normal;
          line-height: 22px;
          padding: 13.5px 14px;
          position: absolute;
          right: 0;
          bottom: 32px;
          background-color: white;
          box-shadow: 0px 16px 64px rgba(183, 203, 228, 0.5);
          border-radius: 8px;
          white-space: nowrap;
        }
      }
      .copy_icon {
        visibility: hidden;
        min-width: 14px;
        height: 18px;
        background-image: url(@/assets/icons/clipboard.svg);
        background-repeat: no-repeat;
        background-position: center;
        margin-left: 10px;
        @media (max-width: $size_767) {
          visibility: visible;
        }
      }
    }
  }
  .form__btn {
    max-width: 181px;
    padding: 10px 16px;
    border: 1px solid $input;
    border-radius: 8px;
    cursor: pointer;
    &:hover {
      background-color: $background;
    }
  }
}
.btn-wrapper {
  width: 124px;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
</style>
