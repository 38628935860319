import * as jsUtils from '@/libs/utils.js';
import { ref, onMounted, computed } from 'vue';

export const noOp = jsUtils.noop;
export const formatLoginToPhoneNumber = jsUtils.formatPhone;

export * from '@/libs/utils.js';

export function merge(arr1, arr2) {
  const types = [jsUtils.getType(arr1), jsUtils.getType(arr2)];
  jsUtils.assert(
    types[0] === types[1],
    'You cannot merge arguments with different types.',
  );
  switch (types[0]) {
    case '[object Array]':
      return arr1.concat(arr2);
  }
}

/**
 * @param {Object} dst
 * @param {Object} src
 * @returns {Object|Array}
 */
export function extend(dst, ...src) {
  for (let source of src) {
    mergeObjects(dst, source);
  }
  return dst;
}

/**
 * Recursively merges objects
 * @param {Object} dst
 * @param {Object} src
 * @returns {Object}
 */
function mergeObjects(dst, src) {
  for (let key in src) {
    if (Object.prototype.hasOwnProperty.call(src, key)) {
      if (isObject(dst[key]) && getType(dst[key]) === getType(src[key])) {
        mergeObjects(dst[key], src[key]);
      } else {
        dst[key] = copy(src[key]);
      }
    }
  }
  return dst;
}

/**
 * @param {*} any
 * @returns {Boolean}
 */
const isObject = function (any) {
  return any !== null && typeof any === 'object';
};

/**
 * @param {*} any
 * @return {String}
 */
const getType = function (any) {
  return Object.prototype.toString.call(any);
};

/**
 * WARNING: works only with {Boolean|Number|String|Array|Object|null|undefined} types
 * @see https://github.com/nervgh/yum.js/blob/master/src/yum.js#L143
 * @param any
 * @returns {*}
 */
const copy = function (any) {
  // Number, String, Boolean, null, undefined
  if (isPrimitive(any)) {
    return any;
  }
  // We cannot copy FormData or File objects
  if (isFormData(any) || isFile(any)) {
    return any;
  }

  let root = Array.isArray(any) ? [] : {};
  for (let key in any) {
    // eslint-disable-next-line no-prototype-builtins
    if (any.hasOwnProperty(key)) {
      root[key] = copy(any[key]);
    }
  }
  return root;
};

/**
 * @param {*} any
 * @returns {Boolean}
 */
function isPrimitive(any) {
  if (any === null) {
    return true;
  }
  switch (typeof any) {
    case 'boolean':
    case 'number':
    case 'string':
    case 'null':
    case 'undefined':
      return true;
    default:
      return false;
  }
}

/**
 * @param {*} any
 * @return {Boolean}
 */
function isFormData(any) {
  return getType(any) === '[object FormData]';
}

/**
 * @param {*} any
 * @return {Boolean}
 */
const isFile = function (any) {
  return getType(any) === '[object File]';
};

export function detectMobile() {
  const windowWidth = ref(null);
  const windowHeight = ref(null);

  const isMobile = computed(() => {
    if (windowWidth.value && windowWidth.value <= 767) {
      return true;
    }
    return false;
  });

  const changeDimension = () => {
    windowWidth.value = document.documentElement.clientWidth;
    windowHeight.value = document.documentElement.clientHeight;
  };
  onMounted(() => {
    changeDimension();
    window.addEventListener('resize', changeDimension);
  });

  return { isMobile: isMobile };
}

export function blockScrollWindow() {
  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = null;
  };

  const disableMobileScroll = () => {
    if (document.documentElement.clientWidth <= 767) {
      document.body.style.overflow = 'hidden';
    }
  };

  return { disableScroll, enableScroll, disableMobileScroll };
}

export function getCircularReplacer() {
  const ancestors = [];
  return function (key, value) {
    if (typeof value !== 'object' || value === null) {
      return value;
    }
    // `this` is the object that value is contained in,
    // i.e., its direct parent.
    while (ancestors.length > 0 && ancestors.at(-1) !== this) {
      ancestors.pop();
    }
    if (ancestors.includes(value)) {
      return '[Circular]';
    }
    ancestors.push(value);
    return value;
  };
}

export function isStorageSupported(webStorageType = 'localStorage') {
  let storage;
  try {
    storage = window[webStorageType];
    if (!storage) {
      return false;
    }
    const x = `__storage_test__`;
    storage.setItem(x, x);
    storage.removeItem(x);
    return true;
  } catch (err) {
    // We acknowledge a QuotaExceededError only if there's something
    // already stored.
    console.error(err);
  }
}

export function isPassportFullFileld(passportObject) {
  const requiredFields = [
    'series_number',
    'issued_date',
    'issued_by',
    'birthday',
    'place_of_birth',
    'registration_address',
    'department_code',
  ];
  let result = true;
  if (Object.keys(passportObject).length === 0) {
    result = false;
  }
  requiredFields.forEach((key) => {
    if (!passportObject[key]) {
      result = false;
    }
  });
  return result;
}
