<template>
  <ModalFull
    maxwidth="550px"
    @close-modal="onClickCloseModal"
    @click="emit('update-is-highlighted', false)"
  >
    <template #title>{{ store.currentGuarantor.full_name }}</template>
    <template #content>
      <div
        :class="{
          'form-blur pe-none': isParsing,
        }"
      >
        <div class="mb-6 text-center">
          проверьте и заполните паспортные данные
        </div>
        <GuarantorPassportForm
          v-model:loading="loading"
          v-model:errors="errors"
          v-model:isDataCorrect="isDataCorrect"
          :is-highlighted="props.isHighlighted"
          :is-edit-disabled="props.isGuaranteeAllSignedByRub"
        />
        <PotokButton
          v-if="!loading"
          class="mt-6 w-100"
          text="сохранить"
          size="large"
          @click="patchPersonGuarantorsAsync()"
        />

        <LoadingBtn v-if="loading" class="mt-6" />
        <ErrorItem
          v-if="errors.length !== 0"
          v-model="errors"
          class="error text-center mx-auto mt-4"
        />
      </div>
      <span
        v-if="isParsing"
        class="await text-nowrap position-absolute translate-middle fw-bold fs-4 text-center"
        >пожалуйста, подождите, распознаем<br />
        предоставленные данные</span
      >
    </template>
  </ModalFull>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import GuarantorPassportForm from '@/components/auth/loan-card/modules/guarantee-issue/GuarantorPassportForm.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import { computed, ref } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import server from '@/server/index';
import constants from '@/constants';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_application_guarantor_passport_open,
  SPA_LK_VKL_application_guarantor_passport_open,
  SPA_LK_application_guarantor_passport_success,
  SPA_LK_VKL_application_guarantor_passport_success,
} = TRACKER_EVENTS;

const store = useStateStore();

const isDataCorrect = ref(false);

const isParsing = computed(() => {
  return (
    store.currentGuarantor.passport.state ===
    constants.PARSING_STATES.waiting_for_parsing
  );
});

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
  isGuaranteeAllSignedByRub: {
    type: Boolean,
    default: false,
  },
  isHighlighted: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  'close-modal',
  'get-guarantors-async',
  'update-is-highlighted',
]);

const errors = ref([]);
const loading = ref(false);

const initial = () => {
  tracker.queue(
    tracker.commands.SEND,
    props.order.credit_line
      ? SPA_LK_VKL_application_guarantor_passport_open
      : SPA_LK_application_guarantor_passport_open,
  );
};
initial();

const onClickCloseModal = () => {
  emit('update-is-highlighted', false);
  emit('close-modal');
  emit('get-guarantors-async');
};
const patchPersonGuarantorsAsync = () => {
  if (!isDataCorrect.value) {
    errors.value.push('заполните все данные');
    return;
  }
  const query = {
    guarantor: {
      inn: store.currentGuarantor.passport.inn,
      citizenship: store.currentGuarantor.passport.citizenship,
      passport: {
        series_number:
          store.currentGuarantor.passport.series +
          ' ' +
          store.currentGuarantor.passport.number,
        issued_date: store.currentGuarantor.passport.issued_date,
        birthday: store.currentGuarantor.passport.birthday,
        place_of_birth: store.currentGuarantor.passport.place_of_birth,
        registration_address:
          store.currentGuarantor.passport.registration_address,
        department_code: store.currentGuarantor.passport.department_code,
      },
    },
  };
  loading.value = true;
  return server.patchPersonGuarantors$id
    .send(query, {
      params: {
        id: store.currentGuarantor.id,
      },
    })
    .pipe(patchPersonGuarantorsAsyncSuccess, patchPersonGuarantorsAsyncError)
    .exec();
};
const patchPersonGuarantorsAsyncSuccess = () => {
  tracker.queue(
    tracker.commands.SEND,
    props.order.credit_line
      ? SPA_LK_VKL_application_guarantor_passport_success
      : SPA_LK_application_guarantor_passport_success,
  );
  loading.value = false;
  onClickCloseModal();
};
const patchPersonGuarantorsAsyncError = (error) => {
  errors.value = error.data?.messages;
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.form-blur {
  filter: blur(8px);
}
.await {
  top: 45%;
  left: 50%;
}
.error {
  max-width: 350px;
}
</style>
