<template>
  <div ref="el" class="payments-wrapper content_scroll">
    <div class="text-title">сводный график платежей</div>
    <div class="payments w-100 mt-2">
      <div class="payment-head">
        <div class="payment-head-type"></div>
        <div class="payment-head-date">дата</div>
        <div class="payment-head-value">сумма</div>
        <div></div>
      </div>
      <div>
        <template v-for="(item, index) in payments" :key="index">
          <div
            class="payment-item-row"
            :class="{
              'payment-item-row-border': !item.open,
            }"
            @click="onClickRow(item)"
          >
            <div class="payment-item-type">
              <PopoverIcon position="right_center" class="text-nowrap">
                <template #icon>
                  <div
                    class="payment-item-icon"
                    :class="getStatusClassPayment(item)"
                  ></div>
                </template>
                <template #text>
                  <div class="payment-item-icon-content">
                    {{ getStatusTitlePayment(item) }}
                  </div>
                </template>
              </PopoverIcon>
            </div>
            <div class="payment-item-date">{{ formatDate(item.date) }}</div>
            <div class="payment-item-amount">
              <div>{{ formatCurrency(getAmountPayment(item), 0) }} ₽</div>
            </div>
            <div>
              <div
                :class="{
                  'icon-arrow-down': !item.open,
                  'icon-arrow-up': item.open,
                }"
              ></div>
            </div>
          </div>
          <div v-if="item.open" class="payment-item-extend">
            <component :is="getComponentHit(item)" :item="item" />
          </div>
        </template>
      </div>
    </div>
    <div v-if="loading" class="d-flex justify-content-center w-100 mt-3">
      <Loader />
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
import { useScroll } from '@vueuse/core';
import { useStateStore } from '@/stores/stateStore';
import { Loader } from 'potok-uikit';
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import { formatCurrency, formatDate } from '@/utils';
import { paymentUtils } from './paymentUtils.js';

import PaymentHitOneRefunded from './hit/PaymentHitOneRefunded.vue';
import PaymentHitOneRefundedAfterOverdue from '@/components/auth/dashboard/payment-summary/hit/PaymentHitOneRefundedAfterOverdue.vue';
import PaymentHitOneOverdue from './hit/PaymentHitOneOverdue.vue';
import PaymentHitOneAwait from './hit/PaymentHitOneAwait.vue';
import PaymentHitOneActive from './hit/PaymentHitOneActive.vue';
import PaymentHitMany from './hit/PaymentHitMany.vue';

const store = useStateStore();
const el = ref();
const { arrivedState } = useScroll(el);
const {
  payments,
  loading,
  page,
  per,
  totalPages,
  getCommonSchedulePayments,
  getAmountPayment,
  getStatusTitlePayment,
  getStatusClassPayment,
} = paymentUtils();

const getComponentHit = (item) => {
  if (item.payments.length === 1) {
    if (item.status === 'REFUNDED') {
      return PaymentHitOneRefunded;
    }
    if (item.status === 'REFUNDED_AFTER_OVERDUE') {
      return PaymentHitOneRefundedAfterOverdue;
    }
    if (item.status === 'OVERDUE') {
      return PaymentHitOneOverdue;
    }
    if (item.status === 'AWAIT') {
      return PaymentHitOneAwait;
    }
    if (item.status === 'ACTIVE') {
      return PaymentHitOneActive;
    }
  }
  if (item.payments.length > 1) {
    return PaymentHitMany;
  }
};

const onClickRow = (item) => {
  item.open = !item.open;
};

watch(
  () => arrivedState.bottom,
  () => {
    if (arrivedState.bottom && page.value < totalPages.value) {
      page.value += 1;
      getCommonSchedulePayments({
        companyId: store.currentCompany.id,
        currentPage: page,
        perPage: per,
      });
    }
  },
);
watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    initComponent();
  },
);

const initComponent = () => {
  getCommonSchedulePayments({
    companyId: store.currentCompany.id,
    currentPage: page,
    perPage: per,
  });
};
onMounted(() => {
  initComponent();
});
</script>
<style lang="scss" scoped>
.payments-wrapper {
  max-height: 500px;
  padding: 0 10px;
}
.payment-head {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $grey;
  border-bottom: 1px solid $dividers;
  display: flex;
}
.payment-head-type {
  min-width: 30px;
}
.payment-head-date {
  min-width: 150px;
}
.payment-head-value {
  min-width: 130px;
}
.payment-head-col {
  padding: 10px 0;
}
.payment-item-row {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $dipedive;
  padding: 14px 10px;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: $background;
    cursor: pointer;
  }
}
.payment-item-row-border {
  border-bottom: 1px solid $dividers;
}
.payment-item-date {
  min-width: 150px;
}
.payment-item-amount {
  min-width: 150px;
}
.payment-item-type {
  min-width: 20px;
}
.payment-item-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $dividers;
}
.payment-item-icon-red {
  background-color: $red;
}
.payment-item-icon-yellow {
  background-image: url(@/assets/icons/rounded/yellow_ok.svg);
  background-color: initial;
  background-size: cover;
  border-radius: initial;
}
.payment-item-icon-green {
  background-image: url(@/assets/icons/rounded/green_ok.svg);
  background-color: initial;
  background-size: cover;
  border-radius: initial;
}
.payment-item-icon-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $dipedive;
}
.payment-item-extend {
  background-color: $background;
  padding: 10px 20px;
  border-radius: 8px;
}

:deep(.hit-desktop-payment) {
  display: flex;
  flex-direction: column;
  min-width: 270px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  .hit-desktop-payment-title {
    color: $dipedive !important;
  }
  .hit-desktop-payment-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .hit-desktop-payment-name {
    font-weight: 400;
    color: $grey;
  }
  .hit-desktop-payment-value {
    font-weight: 400;
    color: $dipedive;
  }
}
</style>
