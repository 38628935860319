<template>
  <Loader v-if="loading" class="mt-3" />
  <template v-if="!loading">
    <div
      v-for="(item, index) in ipFounders"
      :key="index"
      class="p-4 bg-white rounded-4 mb-6"
    >
      <div>
        <FormInput
          :text="item.full_name"
          :placeholder="'учредитель № ' + (index + 1)"
        ></FormInput>
      </div>
      <div class="divider my-4"></div>
      <div>
        <FormInput
          :text="item.share"
          :placeholder="'доля в компании'"
        ></FormInput>
      </div>
    </div>
  </template>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import { Loader } from 'potok-uikit';
import FormInput from '@/components/_generic/ui/inputs/FormInput.vue';
import server from '@/server/index';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const loading = ref(false);
const ipFounders = ref([]);
const ulFounders = ref([]);
const errors = ref([]);

const getFoundersAsync = () => {
  loading.value = true;
  const query = {
    'company_ids[]': store.currentCompany.id,
  };
  return server.getFounders
    .send(query, {})
    .pipe(onGetFoundersAsyncSuccess, onGetFoundersAsyncError)
    .exec();
};
const onGetFoundersAsyncSuccess = ({ data }) => {
  ipFounders.value = [];
  ulFounders.value = [];
  data?.[0].business?.forEach((item) => {
    const title = item.title;
    item.founders.forEach((obj) => {
      obj.passport &&
      Object.keys(obj.passport).length !== 0 &&
      Object.values(obj.passport).every((v) => v)
        ? (obj.isCorrect = true)
        : (obj.isCorrect = false);
      obj.title = title;
      obj.share ? (obj.share += '%') : (obj.share = null);
      ulFounders.value.push(obj);
    });
  });
  data?.[0].person?.forEach((item) => {
    item.passport &&
    Object.keys(item.passport).length !== 0 &&
    Object.values(item.passport).every((v) => v)
      ? (item.isCorrect = true)
      : (item.isCorrect = false);
    item.share ? (item.share += '%') : (item.share = null);
    ipFounders.value.push(item);
  });
  loading.value = false;
};
const onGetFoundersAsyncError = (error) => {
  loading.value = false;
  errors.value = error.data?.messages;
};

onMounted(() => {
  getFoundersAsync();
});
</script>
<style lang="scss" scoped></style>
