<template>
  <div class="loan-card-vkl">
    <div class="container-fluid">
      <div class="container">
        <div v-if="loading" class="pt-5">
          <Loader />
        </div>

        <VKLContent v-if="isOpenContent" @run-action="runAction" />

        <PlatformRules
          v-if="isPlatformRulesOpen"
          v-model:isPlatformRulesOpen="isPlatformRulesOpen"
          :amount="0"
          type="signatory"
          ><template #title
            >переведите 1 рубль с любого расчетного счета компании / ИП по
            указанным реквизитам</template
          ><template #description></template
        ></PlatformRules>
        <UploadPassportModal
          v-if="isPassportModalOpen"
          v-model:isPassportUploaded="isPassportUploaded"
          v-model:isPassportPartiallyUploaded="isPassportPartiallyUploaded"
          :order="store.currentOrder"
          :is-docs-signed="false"
          @close-modal="closeModal"
        />
        <UploadDocs
          v-if="isDocsModalOpen"
          v-model:isAdditionalDocsUploaded="isAdditionalDocsUploadedOld"
          v-model:isAdditionalDocsPartiallyUploaded="isNeedAdditionalDocsUpload"
          :order="store.currentOrder"
          :docs-to-upload="docsToUpload"
          @close-modal="closeModalAndGetOrder"
        />
        <PassportFillModal
          v-if="isPassportFillModalOpen"
          v-model:isPassportFilled="isPassportFilled"
          @close-modal="closeModal"
        />

        <GuaranteeIssue
          v-if="isGuaranteeIssueOpen"
          v-model:loading="loading"
          v-model:isGuaranteeIssueOpen="isGuaranteeIssueOpen"
          v-model:isGuaranteeFullFilled="isGuaranteeFullFilled"
          v-model:isGuaranteePartiallyFilled="isGuaranteePartiallyFilled"
          :order="store.currentOrder"
          :guarantors="guarantors"
          :is-guarantee-signed="false"
          @get-guarantors-async="getGuarantorsAsync()"
          @update:is-guarantee-issue-open="updateOrder"
        />
        <GuaranteeSigning
          v-if="isGuaranteeSigningOpen"
          v-model="isGuaranteeSigningOpen"
          :order="store.currentOrder"
          :guarantors="guarantors"
          type="guarantor"
        />
      </div>
    </div>
    <ManagerModal v-if="isManagerModalOpen" @close-modal="closeModal" />

    <ModalPhoneConfirmation
      v-if="isModalSigningVKLOpen"
      :left-time="leftTime"
      :loading="loadingSignVKL"
      :errors-arr="errors"
      :agreement-id="agreementId"
      title="подписать инвестпредложение"
      @confirm-otp-async="confirmOtpAsync"
      @send-otp-async="sendOtpAsync"
      @clear-errors="clearErrors"
      @close-modal="closeModal"
    >
      <template #desc
        ><div class="potok-text-body-3">
          инвестпредложение на сумму
          {{ formatCurrency(store.currentOrder.amount, 0) }}&nbsp;₽
        </div></template
      >
    </ModalPhoneConfirmation>
  </div>
</template>
<script setup>
import { onMounted, computed } from 'vue';

import { useStateStore } from '@/stores/stateStore';
import { Loader } from 'potok-uikit';
import VKLContent from '@/components/auth/loan-card/vkl-flow/content/VKLContent.vue';
import UploadDocs from '@/components/auth/loan-card/modules/UploadDocs.vue';
import UploadPassportModal from '@/components/auth/loan-card/modules/upload-passport/UploadPassportModal.vue';
import PassportFillModal from '@/components/auth/loan-card/modules/fill-passport/PassportFillModal.vue';
import GuaranteeIssue from '@/components/auth/loan-card/modules/guarantee-issue/GuaranteeIssue.vue';
import GuaranteeSigning from '@/components/auth/loan-card/modules/GuaranteeSigning.vue';
import PlatformRules from '@/components/auth/loan-card/modules/platform-rules/PlatformRules.vue';
import ModalPhoneConfirmation from '@/components/_generic/ui/modals/ModalPhoneConfirmation.vue';
import ManagerModal from '@/components/auth/loan-card/modules/manager-modal/ManagerModal.vue';

import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';
import { loanCardVKLUtils } from '@/components/auth/loan-card/vkl-flow/utils/loanCardVKLUtils.js';
import { formatCurrency } from '@/utils';

const store = useStateStore();
const {
  loading,
  guarantors,
  isPlatformRulesOpen,
  isPassportModalOpen,
  isPassportUploaded,
  isPassportPartiallyUploaded,
  isPassportFillModalOpen,
  isGuaranteeIssueOpen,
  isGuaranteeFullFilled,
  isGuaranteePartiallyFilled,
  isDocsModalOpen,
  isAdditionalDocsUploadedOld,
  isNeedAdditionalDocsUpload,
  docsToUpload,
  isGuaranteeSigningOpen,
  isPassportFilled,
  isManagerModalOpen,
  getGuarantorsAsync,
  getOrdersAsync,
} = loanCardShared();
const {
  closeModal,
  closeModalAndGetOrder,
  isModalSigningVKLOpen,
  leftTime,
  loadingSignVKL,
  errors,
  countdown,
  confirmOtpAsync,
  sendOtpAsync,
  agreementId,
  onClickGoToChosenStep,
} = loanCardVKLUtils();

const isOpenContent = computed(() => {
  return !!(
    !loading.value &&
    !isPlatformRulesOpen.value &&
    !isGuaranteeIssueOpen.value &&
    !isGuaranteeSigningOpen.value
  );
});

const runAction = (data) => {
  onClickGoToChosenStep(data.action);
};
const clearErrors = () => {
  errors.value = [];
};

const updateOrder = () => {
  getOrdersAsync();
};

onMounted(() => {
  countdown.onTick = (val) => {
    leftTime.value = val;
  };
});
</script>
<style lang="scss" scoped>
.loan-card-vkl {
  height: 100%;
}
</style>
