<template>
  <div class="container pt-7 pb-7">
    <div class="form m-auto">
      <div class="title fw-bold mb-3">
        {{ store.fullName || store.currentCompanyInfo.title }}
      </div>
      <div class="mb-6 text-center">
        загрузите фотографию паспорта и мы заполним паспортные данные
        автоматически
      </div>
      <div class="position-relative">
        <div :class="{ 'form-blur pe-none': isParsing }">
          <div
            class="bg-background rounded-3 p-4 mb-4 d-flex align-items-center"
          >
            <PassportUploader
              v-model="pages.passport_main"
              :options="makeOptions('passport_main')"
            />
            <span class="file-name small ms-4 me-4">{{
              pages.passport_main[0]?.filename ||
              pages.passport_main[0]?.name ||
              'главный разворот паспорта'
            }}</span>
            <i
              class="status-icon"
              :class="{
                'status-icon-true': pages.passport_main[0]?.success,
                'status-icon-false': pages.passport_main[0]?.success === false,
              }"
            ></i>
          </div>
          <div class="bg-background rounded-3 p-4 d-flex align-items-center">
            <PassportUploader
              v-model="pages.passport_registration"
              :options="makeOptions('passport_registration')"
            />
            <span class="file-name small ms-4 me-4">{{
              pages.passport_registration[0]?.filename ||
              pages.passport_registration[0]?.name ||
              'страница с пропиской'
            }}</span>
            <i
              class="status-icon"
              :class="{
                'status-icon-true': pages.passport_registration[0]?.success,
                'status-icon-false':
                  pages.passport_registration[0]?.success === false,
              }"
            ></i>
          </div>
          <div class="text-center mt-5">
            <PotokButton
              v-if="!loading"
              class="mb-4"
              text="распознать паспорт"
              size="medium"
              @click="onClickBeginParsing()"
            />
            <LoadingBtn v-else class="mb-4" />
            <PotokButton
              class="mb-4"
              text="заполнить самостоятельно"
              size="medium"
              theme="tertiary"
              @click="onClickGoToComponent"
            />

            <button class="btn-text btn-text-primary" @click="onClickGoBack()">
              назад
            </button>
          </div>
        </div>
        <span
          v-if="isParsing"
          class="await text-nowrap position-absolute translate-middle fw-bold fs-4 text-center"
          >пожалуйста, подождите,<br />
          распознаем паспорт</span
        >
      </div>
    </div>
    <PageAlert v-if="errorMsg" color="red">{{ errorMsg }}</PageAlert>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import PassportUploader from '@/components/_generic/auth/resource-uploader/PassportUploader.vue';
import WithoutParser from '@/components/auth/user-passports/_generic/WithoutParser.vue';
import IP_SummaryInfo from '@/components/auth/user-passports/ip-user-type/IP_SummaryInfo.vue';
import UL_SummaryInfo from '@/components/auth/user-passports/ul-user-type/UL_SummaryInfo.vue';
import PageAlert from '@/components/_generic/ui/widgets/PageAlert.vue';
import { onMounted, watch } from 'vue';
import config from '@/../config';
import { useStateStore } from '@/stores/stateStore';
import constants from '@/constants';
import { usePassport } from './utils';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_BKI_Parsing_unwanted, SPA_LK_BKI_Parsing_start } =
  TRACKER_EVENTS;

const store = useStateStore();
const {
  pages,
  loading,
  errorMsg,
  isParsing,
  isParsed,
  isError,
  checkSuccessStatus,
  startPolling,
  stopPolling,
  postCommandsAsync,
  getUploadedPassportsListAsync,
} = usePassport();

const emit = defineEmits([
  'get-user-data-async',
  'change-component',
  'update:isHighlighted',
]);

onMounted(() => {
  getUploadedPassportsListAsync();
});

watch(
  () => errorMsg.value,
  () => {
    if (errorMsg.value) {
      setTimeout(() => (errorMsg.value = ''), 3700);
    }
  },
);
watch(
  () => isParsing.value,
  () => {
    isParsing.value ? startPolling() : stopPolling();
  },
);
watch(
  () => isParsed.value,
  () => {
    if (isParsed.value) {
      emit('update:isHighlighted', true);
      emit('change-component', WithoutParser);
    }
  },
);
watch(
  () => isError.value,
  () => {
    if (isError.value) {
      emit('change-component', WithoutParser);
    }
  },
);

const makeOptions = (type) => {
  return {
    file: 'file',
    inputId: type,
    postAction: config.VITE_BASE_URL + '/spa/business/attachments',
    headers: { Authorization: store.session.token },
    data: {
      kind: type,
      attachable_id: store.currentUser.id,
      attachable_type: 'User',
    },
    extensions: constants.PASSPORT_FILE_EXTENSIONS,
    accept: 'image/png,image/gif,image/jpeg,image/webp,application/pdf',
    loading: loading.value,
  };
};
const onClickGoToComponent = () => {
  if (loading.value) {
    return;
  }
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_Parsing_unwanted);
  emit('change-component', WithoutParser);
};
const onClickGoBack = () => {
  if (loading.value) {
    return;
  }
  if (store.isUserUL) {
    emit('change-component', UL_SummaryInfo);
    emit('get-user-data-async');
  } else {
    emit('change-component', IP_SummaryInfo);
    emit('get-user-data-async');
  }
};
const onClickBeginParsing = () => {
  if (!checkSuccessStatus.value) {
    errorMsg.value = 'чтобы распознать, загрузите фото паспорта';
    return;
  }
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_Parsing_start);
  postCommandsAsync();
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 460px;
  .form {
    .title {
      font-size: 30px;
      line-height: 36px;
      text-align: center;
    }
    .form-blur {
      filter: blur(8px);
    }
    .await {
      top: 30%;
      left: 50%;
    }
    .file-name {
      width: 230px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .status-icon {
      width: 18px;
      height: 18px;
      margin-left: auto;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      &-true {
        background: url(@/assets/icons/rounded/true_icon_pass.svg);
      }
      &-false {
        background: url(@/assets/icons/rounded/false_icon_pass.svg);
      }
    }
  }
}
</style>
