<template>
  <div class="companies-dropdown-menu content_scroll">
    <div class="companies-dropdown-title">в потоке</div>
    <template v-for="(item, index) in store.companies.items" :key="index">
      <div
        v-if="item.id"
        class="companies-dropdown-item"
        @click="onClickSelectItem(item)"
      >
        <div>
          {{ item.short_title || item.title }}
        </div>
        <div class="small color-grey mt-1">инн {{ item.inn }}</div>
      </div>
      <div
        v-if="index < store.companies.items.length - 1"
        class="companies-dropdown-item-divider"
      ></div>
      <template v-if="!item.id"
        ><div class="companies-dropdown-title mt-4">ещё нет в потоке</div>
        <div
          class="companies-dropdown-item"
          @click="onClickCreateCompany(item.inn)"
        >
          <div>
            {{ item.short_title || item.title }}
          </div>
          <div class="small color-grey mt-1">инн {{ item.inn }}</div>
          <span class="text-tiffany mt-2">добавить в поток &#8594;</span>
        </div>
      </template>
    </template>
    <div class="companies-dropdown-item-divider"></div>
    <div class="companies-dropdown-item" @click="emit('close')">
      <div class="item-profile">
        <i class="icon-setting"></i>
        <router-link
          class="ms-3"
          :to="{
            name: 'profile',
          }"
          >мой профиль</router-link
        >
      </div>
    </div>
    <div class="companies-dropdown-item-exit" @click="onClickLogOut()">
      <i class="icon-exit"></i>
      <button type="button" class="ms-2">выход</button>
    </div>
  </div>
</template>
<script setup>
import { menuUtils } from './menuUtils.js';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();
const emit = defineEmits(['close']);

const { onSelectItem, onCreateCompany, logOutAsync } = menuUtils();

const onClickCreateCompany = (inn) => {
  onCreateCompany(inn);
};

const onClickSelectItem = (value) => {
  onSelectItem(value);
  emit('close');
};

const onClickLogOut = () => {
  logOutAsync();
};
</script>
<style lang="scss" scoped>
.item-profile {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.companies-dropdown-menu {
  position: absolute;
  z-index: 1000;
  background-color: white;
  right: 0px;
  top: 50px;
  height: auto;
  max-height: 384px;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  border-radius: 12px;
  border: none;
  padding: 10px;
  .companies-dropdown-title {
    margin: 10px 0px 5px 10px;
    width: fit-content;
    padding: 4px 8px;
    height: 20px;
    background-color: #f1f3f5;
    border-radius: 6px;
    align-items: center;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $grey;
  }
  .companies-dropdown-item {
    justify-content: center;
    flex-direction: column;
    display: flex;
    width: 300px;
    color: $dipedive;
    border-radius: 8px;
    cursor: pointer;
    padding: 4px 10px;
    &:last-child {
      box-shadow: none;
    }
    div {
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &:hover {
      background-color: $background;
      box-shadow: none;
    }
  }
  .companies-dropdown-item-divider {
    // box-shadow: 0px 8px 1px -8px $grey;
    border-bottom: 1px solid #e8ecf0;
    min-height: 1px;
    margin: 20px 0px;
  }
  .companies-dropdown-item-exit {
    padding: 9px 10px;
    color: $danger;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    &:hover {
      background-color: $background;
      box-shadow: none;
    }
    button {
      color: $danger;
      border: none;
      background-color: transparent;
    }
  }
}
</style>
