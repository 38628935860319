<template>
  <div>
    <div class="row-wrapper">
      <CHKO_1
        :items="props.turnoversFiltered"
        :is-calculation-finished="props.isCalculationFinished"
        :is-statement-loaded="props.isStatementLoaded"
      />
      <CHKO_2
        :items="props.turnoversFiltered"
        :is-calculation-finished="props.isCalculationFinished"
        :is-statement-loaded="props.isStatementLoaded"
      />
    </div>
    <template v-if="!store.companies.loading">
      <div class="row-wrapper">
        <Top :selected-period="props.selectedPeriod" />
        <Court v-if="store.currentCompany" />
      </div>
    </template>
    <div class="row-wrapper">
      <Overdues />
    </div>
  </div>
</template>
<script setup>
import CHKO_1 from '@/components/auth/company-analytic/modules/CHKO_1.vue';
import CHKO_2 from '@/components/auth/company-analytic/modules/CHKO_2.vue';
import Top from '@/components/auth/company-analytic/modules/Top.vue';
import Court from '@/components/auth/company-analytic/modules/Court.vue';
import Overdues from '@/components/auth/company-analytic/modules/Overdues.vue';

import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const props = defineProps({
  turnoversFiltered: {
    type: Array,
    required: true,
  },
  isStatementLoaded: {
    type: Boolean,
    default: false,
  },
  isCalculationFinished: {
    type: Boolean,
    default: false,
  },
  selectedPeriod: {
    type: [String, Number],
    default: 12,
  },
});
</script>
<style lang="scss" scoped>
.row-wrapper {
  display: flex;
  justify-content: space-between;
  @media (max-width: $size_767) {
    width: 100%;
    display: block;
  }
}

:deep(.hold-reason) {
  background-color: #ee3f4a1a;
  font-size: 8px;
  line-height: 12px;
  font-weight: 500;
  padding: 5px 10px;
  color: $red;
  border-radius: 4px;
  letter-spacing: 1px;
}
</style>
