import Tracker from '@/libs/browser-analytics';
import config from '@/../config';

// @see https://yandex.ru/support/metrika/code/counter-initialize.html
const ymtOptions = {
  id: config.VITE_YM_API_KEY,
  accurateTrackBounce: true,
  clickmap: true,
  trackLinks: true,
  webvisor: true,
};

const amplOptions = {
  apiKey: config.VITE_AMPLITUDE_API_KEY,
};

const isProduction = import.meta.env.MODE === 'production';

if (isProduction) {
  // global VK
  !(function () {
    var t = document.createElement('script');
    (t.type = 'text/javascript'),
      (t.async = !0),
      (t.src = 'https://vk.com/js/api/openapi.js?169'),
      (t.onload = function () {
        VK.Retargeting.Init('VK-RTRG-1794018-8NlGq'), VK.Retargeting.Hit(); // eslint-disable-line no-undef
      }),
      document.head.appendChild(t);
  })();

  // Top.Mail.Ru counter -->
  var _tmr = window._tmr || (window._tmr = []);
  _tmr.push({ id: '3464228', type: 'pageView', start: new Date().getTime() });
  (function (d, w, id) {
    if (d.getElementById(id)) return;
    var ts = d.createElement('script');
    ts.type = 'text/javascript';
    ts.async = true;
    ts.id = id;
    ts.src = 'https://top-fwz1.mail.ru/js/code.js';
    var f = function () {
      var s = d.getElementsByTagName('script')[0];
      s.parentNode.insertBefore(ts, s);
    };
    if (w.opera === '[object Opera]') {
      d.addEventListener('DOMContentLoaded', f, false);
    } else {
      f();
    }
  })(document, window, 'tmr-code');
}

const tracker = new Tracker({
  ymtOptions,
  amplOptions,
  isProduction,
});

export default tracker;
