<template>
  <section
    class="form position-relative"
    :class="[
      skeletonLoading ||
      (isScheduleTabActive && !payments.length) ||
      (!isScheduleTabActive && !payouts.length)
        ? 'bg-desert'
        : 'bg-white',
    ]"
  >
    <div
      v-if="payments.length || payouts.length"
      class="d-flex justify-content-between text-nowrap"
    >
      <div
        class="tab"
        :class="{
          'bg-white cursor-pointer tab-inactive': !isScheduleTabActive,
        }"
        @click="onClickSwitchToPayments()"
      >
        график
      </div>
      <div
        class="tab"
        :class="{
          'bg-white cursor-pointer tab-inactive': isScheduleTabActive,
        }"
        @click="onClickSwitchToPayouts()"
      >
        списания и ндфл
      </div>
    </div>
    <div class="payment-head mt-3">
      <div class="payment-head-type"></div>
      <div class="payment-head-date">дата платежа</div>
      <div class="payment-head-value">сумма</div>
      <div></div>
    </div>
    <div
      v-if="
        !skeletonLoading &&
        ((isScheduleTabActive && payments.length) ||
          (!isScheduleTabActive && payouts.length))
      "
      ref="el"
      class="payments w-100 pe-2 content_scroll"
      style="max-height: 460px"
    >
      <SchedulePayments v-if="isScheduleTabActive" :payments="payments" />
      <PayoutsList v-else :payouts="payouts" />
    </div>
    <div
      v-if="
        !skeletonLoading &&
        ((isScheduleTabActive && payments.length) ||
          (!isScheduleTabActive && payouts.length))
      "
      class="d-flex align-items-center mt-2"
    >
      <OnClickOutside @trigger="isDocsListOpen = false">
        <div class="position-relative" style="max-width: 140px">
          <PotokButton
            size="medium"
            theme="tertiary"
            @click="isDocsListOpen = !isDocsListOpen"
          >
            <template #default
              >скачать<i
                class="d-inline-block icon-arrow-down ms-2"
                :class="{ 'icon-arrow-up': isDocsListOpen }"
              ></i
            ></template>
          </PotokButton>
          <div v-if="isDocsListOpen" class="form-list text-nowrap">
            <button
              class="btn-text btn-text-primary mb-4"
              @click="onClickDownloadPDF()"
            >
              в формате .pdf
            </button>
            <button
              class="btn-text btn-text-primary d-block"
              @click="onClickDownloadXLSX()"
            >
              в формате .xlsx
            </button>
          </div>
        </div>
      </OnClickOutside>
      <div v-if="loading" class="ms-3">
        <Loader />
      </div>
    </div>
    <div
      v-if="
        skeletonLoading ||
        (isScheduleTabActive && !payments.length) ||
        (!isScheduleTabActive && !payouts.length)
      "
      class="mt-2"
    >
      <SkeletonLoading :is-loading="skeletonLoading" />
      <span
        v-if="isScheduleTabActive"
        class="color-grey position-absolute"
        style="bottom: 18px"
        >скоро здесь появится график <br />платежей
      </span>
      <span
        v-if="!isScheduleTabActive"
        class="color-grey position-absolute"
        style="bottom: 18px"
        >тут будет история списаний
      </span>
    </div>
  </section>
</template>
<script setup>
import { PotokButton, Loader } from 'potok-uikit';
import { toRefs } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { OnClickOutside } from '@vueuse/components';
import { useScroll } from '@vueuse/core';
import { ref, watch } from 'vue';

import PayoutsList from '@/components/auth/dashboard/modules/loan-card/ui/modules/loan-payment-summary/ui/tabs/PayoutsList.vue';
import SchedulePayments from '@/components/auth/dashboard/modules/loan-card/ui/modules/loan-payment-summary/ui/tabs/SchedulePayments.vue';
import SkeletonLoading from '@/components/auth/dashboard/modules/loan-card/ui/modules/loan-payment-summary/ui/SkeletonLoading.vue';
import { openInNewTab } from '@/server/index';
import { useLoanPaymentUtils } from '@/components/auth/dashboard/modules/loan-card/ui/modules/loan-payment-summary/utils';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Loan_schedule_pdf, SPA_LK_Loan_schedule_xls } = TRACKER_EVENTS;

const el = ref();
const { arrivedState } = useScroll(el);
const store = useStateStore();
const {
  payments,
  payouts,
  loading,
  skeletonLoading,
  errorsArr,
  paymentsPage,
  payoutsPage,
  paymentsPer,
  payoutsPer,
  paymentsTotalPages,
  payoutsTotalPages,
  isScheduleTabActive,
  isDocsListOpen,
  getCommonSchedulePayments,
  getPayoutsAsync,
  onClickSwitchToPayments,
  onClickSwitchToPayouts,
} = useLoanPaymentUtils();

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
});
const { order } = toRefs(props);

const emit = defineEmits(['get-schedule-error']);

const onClickDownloadPDF = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Loan_schedule_pdf);
  openInNewTab(
    '/documents/schedule_with_payouts.pdf?loan_id=' + order.value.loan_id,
  );
};
const onClickDownloadXLSX = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Loan_schedule_xls);
  openInNewTab(
    '/documents/schedule_with_payouts.xlsx?loan_id=' + order.value.loan_id,
  );
};

watch(
  () => errorsArr.value,
  () => {
    if (errorsArr.value) {
      emit('get-schedule-error', errorsArr.value);
    }
  },
);
watch(
  () => arrivedState.bottom,
  () => {
    if (isScheduleTabActive.value) {
      if (
        arrivedState.bottom &&
        paymentsPage.value < paymentsTotalPages.value
      ) {
        paymentsPage.value += 1;
        getCommonSchedulePayments(
          {
            companyId: store.currentCompany.id,
            loanId: order.value.loan_id,
            currentPage: paymentsPage.value,
            perPage: paymentsPer.value,
          },
          true,
        );
      }
    } else {
      if (arrivedState.bottom && payoutsPage.value < payoutsTotalPages.value) {
        payoutsPage.value += 1;
        getPayoutsAsync(
          {
            loanId: order.value.loan_id,
            currentPage: payoutsPage.value,
            perPage: payoutsPer.value,
          },
          true,
        );
      }
    }
  },
);

watch(
  () => order.value,
  () => {
    getCommonSchedulePayments({
      companyId: store.currentCompany.id,
      loanId: order.value.loan_id,
      currentPage: paymentsPage.value,
      perPage: paymentsPer.value,
    });
    getPayoutsAsync({
      loanId: order.value.loan_id,
      currentPage: payoutsPage.value,
      perPage: payoutsPer.value,
    });
  },
);
</script>
<style lang="scss" scoped>
.form {
  border-radius: 12px;
  min-width: 400px;
  padding: 18px 16px;
  min-height: 100%;
  @media (max-width: $size_575) {
    min-width: auto;
    min-height: 600px;
  }
  .tab {
    width: 50%;
    padding: 6px 0px;
    text-align: center;
    background-color: $primary;
    color: $white;
    border: 1px solid $primary;
    &:first-child {
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
    }
  }
  .tab-inactive {
    color: $primary;
    &:hover {
      color: $grey;
    }
  }
  .payment-head {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    text-transform: lowercase;
    color: $grey;
    border-bottom: 1px solid $dividers;
    display: flex;
  }
  .payment-head-type {
    min-width: 30px;
  }
  .payment-head-date {
    min-width: 150px;
  }
  .payment-head-value {
    min-width: 130px;
  }
  .form-list {
    position: absolute;
    top: 60px;
    right: 0;
    padding: 24px 20px;
    border-radius: 12px;
    background-color: $white;
    box-shadow: 0px 2px 22px 0px rgba(13, 39, 65, 0.12);
    z-index: 1;
  }
}

:deep(.hit-desktop-payment) {
  display: flex;
  flex-direction: column;
  min-width: 270px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  .hit-desktop-payment-title {
    color: $dipedive !important;
  }
  .hit-desktop-payment-row {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
  }
  .hit-desktop-payment-name {
    font-weight: 400;
    color: $grey;
  }
  .hit-desktop-payment-value {
    font-weight: 400;
    color: $dipedive;
  }
}
</style>
