import { useRouter } from 'vue-router';
import constants from '@/constants';
import tracker from '@/tracker';
import { useStateStore } from '@/stores/stateStore';
import { onMounted, computed } from 'vue';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Conditions_Business_about_rewrite,
  SPA_LK_VKL_Conditions_Business_about_rewrite,
  SPA_LK_Conditions_vipiska_refrash,
  SPA_LK_VKL_Conditions_vipiska_refrash,
  SPA_LK_Conditions_BKI_refrash,
  SPA_LK_VKL_Conditions_BKI_refrash,
} = TRACKER_EVENTS;

export function updateLimitUtils() {
  const router = useRouter();
  const { LIMIT_UPDATE_STATES } = constants;
  const store = useStateStore();

  onMounted(() => {
    store.uiStates.limitUpdateState = undefined;
  });

  const isStatementBtnShowOnPartnersFlow = computed(() => {
    return store.isLimitsBkiSigned && store.isLimitsCompanyFilled;
  });
  const isBKIBtnShowOnPartnersFlow = computed(() => {
    return store.isLimitsCompanyFilled || store.isLimitsBkiSigned;
  });

  const goToChosenStep = (step) => {
    switch (step) {
      case 'company_profile':
        store.uiStates.limitUpdateState = LIMIT_UPDATE_STATES.company;
        tracker.queue(
          tracker.commands.SEND,
          store.isVKLFlow
            ? SPA_LK_VKL_Conditions_Business_about_rewrite
            : SPA_LK_Conditions_Business_about_rewrite,
        );
        router.push({
          name: 'companyProfile',
        });
        break;
      case 'bank_statement':
        store.uiStates.limitUpdateState = LIMIT_UPDATE_STATES.statement;
        tracker.queue(
          tracker.commands.SEND,
          store.isVKLFlow
            ? SPA_LK_VKL_Conditions_vipiska_refrash
            : SPA_LK_Conditions_vipiska_refrash,
        );
        router.push({
          name: 'bankStatement',
        });
        break;
      case 'user_passports':
        store.uiStates.limitUpdateState = LIMIT_UPDATE_STATES.agreement;
        tracker.queue(
          tracker.commands.SEND,
          store.isVKLFlow
            ? SPA_LK_VKL_Conditions_BKI_refrash
            : SPA_LK_Conditions_BKI_refrash,
        );
        router.push({
          name: 'userPassports',
        });
        break;
    }
  };
  return {
    goToChosenStep,
    isStatementBtnShowOnPartnersFlow,
    isBKIBtnShowOnPartnersFlow,
  };
}
