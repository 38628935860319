import { http } from '@/libs/browser-http';
import users from './modules/users';
import magicLink from './modules/magic-link';
import statements from './modules/statements';
import companies from './modules/companies';
import passports from './modules/passports';
import orders from './modules/orders';
import dadata from './modules/dadata';
import builtInPipes from './pipes';
import founders from './modules/founders';
import bills from './modules/bills';
import attachments from './modules/attachments';
import guarantors from './modules/guarantors';
import support from './modules/support';
import commands from './modules/commands';
import features from './modules/features';
import documents from './modules/documents';
import payouts from './modules/payouts';
import notice from './modules/notice';
import loans from './modules/loans';
import business from './modules/business';
import teleport from './modules/teleport';
import config from '@/../config';

import { useStateStore } from '@/stores/stateStore';

const passRegUrl = config.VITE_PASS_REG_URL;

export const useRedirection = (url) => {
  window.location.href = passRegUrl + url;
};

export const createLinkRedirection = (url) => {
  return passRegUrl + url;
};

export const openInNewTab = (url) => {
  const openedWindow = window.open(passRegUrl + url, '_blank');
  if (openedWindow) {
    openedWindow.focus();
  }
};

export const openAuthFileInNewTab = async (url) => {
  const store = useStateStore();

  const headers = {
    'Content-Type': 'application/json',
    Authorization: store.session.token,
  };
  const res = await window.fetch(url, {
    headers,
    credentials: 'include',
  });
  const uint8arr = new Uint8Array(await new Response(res.body).arrayBuffer());
  const blob = new Blob([uint8arr], { type: 'application/pdf' });

  const newUurl = window.URL.createObjectURL(blob);
  const pdfWindow = window.open();
  pdfWindow.location.href = newUurl;
};

export function AppServer(options = {}, pipes = builtInPipes) {
  let that = new http.Server(options, pipes);

  that.addActions({
    ...users,
    ...magicLink,
    ...statements,
    ...companies,
    ...passports,
    ...dadata,
    ...founders,
    ...orders,
    ...bills,
    ...attachments,
    ...guarantors,
    ...support,
    ...commands,
    ...features,
    ...documents,
    ...payouts,
    ...notice,
    ...loans,
    ...business,
    ...teleport,
  });

  /**
   * @param {String} actionKeys...
   * @return {Boolean}
   */
  that.isPending = function (...actionKeys) {
    return actionKeys.some((key) => that[key].isPending);
  };

  that.isAnythingPending = function () {
    const allProps = Object.keys(that);
    const allActions = allProps.filter((prop) => {
      return that[prop].constructor.name === 'Action';
    });

    return allActions.some((key) => that[key].isPending);
  };

  return that;
}

export default AppServer();
