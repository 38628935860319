<template>
  <div class="content-wrapper">
    <div class="content-label">{{ props.placeholder }}</div>
    <div class="content-text">{{ props.text }}</div>
  </div>
</template>

<script setup>
const props = defineProps({
  text: {
    type: String,
    default: null,
  },
  placeholder: {
    type: String,
    default: null,
  },
});
</script>

<style lang="scss" scoped>
.content-wrapper {
  height: 44px;
  .content-label {
    bottom: 37px;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }
  .body-input {
    @include input;
    padding: 14px;
    height: 44px;
    border-radius: 8px;
    background-color: transparent;
    border: 1px solid $input;
  }
  .floating-label {
    color: $grey;
    position: absolute;
    pointer-events: none;
    left: 14px;
    bottom: 10px;
    transition: 0.2s ease all;
    background-color: $white;
  }
  .is-invalid {
    border: 1px solid rgba(238, 61, 72, 0.2);
  }
}
</style>
