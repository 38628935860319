export const ORDER_COLLECT_EVENTS = {
  SPA_LK_application_open: 'SPA_LK_application_open',
  SPA_LK_application_signature_open: 'SPA_LK_application_signature_open',
  SPA_LK_application_signature_start: 'SPA_LK_application_signature_start',
  SPA_LK_application_addition_open: 'SPA_LK_application_addition_open',
  SPA_LK_application_addition_success: 'SPA_LK_application_addition_success',
  SPA_LK_application_guarantor_open: 'SPA_LK_application_guarantor_open',
  SPA_LK_application_guarantor_success: 'SPA_LK_application_guarantor_success',
  SPA_LK_application_guarantor_passport_open:
    'SPA_LK_application_guarantor_passport_open',
  SPA_LK_application_guarantor_passport_success:
    'SPA_LK_application_guarantor_passport_success',
  SPA_LK_Guarantor_Parsing_start: 'SPA_LK_Guarantor_Parsing_start',
  SPA_LK_Guarantor_Parsing_success: 'SPA_LK_Guarantor_Parsing_success',
  SPA_LK_application_1RUB_open: 'SPA_LK_application_1RUB_open',
  SPA_LK_application_guarantor_QR_downloaded:
    'SPA_LK_application_guarantor_QR_downloaded',
  SPA_LK_application_1RUB_QR_dw: 'SPA_LK_application_1RUB_QR_dw',
  SPA_LK_application_sig_pasport_open: 'SPA_LK_application_sig_pasport_open',
  SPA_LK_application_sig_pasport_success:
    'SPA_LK_application_sig_pasport_success',
  SPA_LK_signature_SMS_success: 'SPA_LK_signature_SMS_success',
};

export const ORDER_COLLECT_VKL_EVENTS = {
  SPA_LK_VKL_application_open: 'SPA_LK_VKL_application_open',
  SPA_LK_VKL_application_signature_open:
    'SPA_LK_VKL_application_signature_open',
  SPA_LK_VKL_application_signature_start:
    'SPA_LK_VKL_application_signature_start',
  SPA_LK_VKL_application_addition_open: 'SPA_LK_VKL_application_addition_open',
  SPA_LK_VKL_application_addition_success:
    'SPA_LK_VKL_application_addition_success',
  SPA_LK_VKL_application_guarantor_open:
    'SPA_LK_VKL_application_guarantor_open',
  SPA_LK_VKL_application_guarantor_success:
    'SPA_LK_VKL_application_guarantor_success',
  SPA_LK_VKL_application_guarantor_passport_open:
    'SPA_LK_VKL_application_guarantor_passport_open',
  SPA_LK_VKL_application_guarantor_passport_success:
    'SPA_LK_VKL_application_guarantor_passport_success',
  SPA_LK_VKL_application_1RUB_open: 'SPA_LK_VKL_application_1RUB_open',
  SPA_LK_VKL_application_guarantor_QR_downloaded:
    'SPA_LK_VKL_application_guarantor_QR_downloaded',
  SPA_LK_VKL_application_1RUB_QR_dw: 'SPA_LK_VKL_application_1RUB_QR_dw',
  SPA_LK_VKL_application_sig_pasport_open:
    'SPA_LK_VKL_application_sig_pasport_open',
  SPA_LK_VKL_application_sig_pasport_success:
    'SPA_LK_VKL_application_sig_pasport_success',
  SPA_LK_VKL_signature_SMS_success: 'SPA_LK_VKL_signature_SMS_success',
};
