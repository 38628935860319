<template>
  <ModalRightLimit class="modal-right">
    <template #content>
      <div class="title-wrapper">
        <div class="potok-text-body-1">кредитный потенциал компании</div>
        <div class="potok-text-uppercase">
          обновлено {{ formatDate(props.limits.updated_at) }}
        </div>
      </div>

      <div class="modal-message-block modal-message-block-black">
        <div
          class="modal-message-block-content"
          @click="isShowContent1 = !isShowContent1"
        >
          <div class="modal-message-block-text potok-text-body-1">
            <i class="icon-item-1"></i>
            максимальная сумма заемного финансирования
          </div>
          <div class="block-right">
            <div class="potok-text-h2 block-amount no-wrap">
              {{ formatCurrency(props.limits.final_limit, 0) }}&nbsp;₽
            </div>
            <div class="description-btn">
              <i v-if="!isShowContent1" class="icon-plus"></i>
              <i v-if="isShowContent1" class="icon-minus"></i>
            </div>
          </div>
        </div>
        <div v-if="isShowContent1" class="modal-message-block-desc">
          Показатель оценивается исходя из размера бизнеса, стабильности и
          динамики свободного денежного потока, а также диверсификации и
          качества источников финансирования бизнеса.
          <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
          <div>
            <p class="mb-0 potok-text-body-2">
              <span class="potok-text-uppercase">✦</span> добавьте другие
              банковские счета компании и другие компании бизнеса
            </p>
            <p class="mb-0 potok-text-body-2">
              <span class="potok-text-uppercase">✦</span> увеличивайте выручку
              бизнеса и ее стабильность
            </p>
            <p class="mb-0 potok-text-body-2">
              <span class="potok-text-uppercase">✦</span> развивайте отношения с
              качественными кредиторами
            </p>
          </div>
        </div>
      </div>

      <div class="title-wrapper">
        <div class="potok-text-body-1">кредиты и займы вне платформы</div>
      </div>

      <div class="modal-message-block modal-message-block-red">
        <div
          class="modal-message-block-content"
          @click="isShowContent2 = !isShowContent2"
        >
          <div class="modal-message-block-text potok-text-body-1">
            <i class="icon-info-red"></i>
            остаток обязательств за пределами платформы
          </div>
          <div class="block-right">
            <div class="potok-text-h2 block-amount no-wrap">
              <span v-if="props.limits.bki_saldo_sum">-</span>
              {{ formatCurrency(props.limits.bki_saldo_sum, 0) }}&nbsp;₽
            </div>
            <div class="description-btn">
              <i v-if="!isShowContent2" class="icon-plus"></i>
              <i v-if="isShowContent2" class="icon-minus"></i>
            </div>
          </div>
        </div>
        <div v-if="isShowContent2" class="modal-message-block-desc">
          Включены обязательства по кредитам от банков, займам от МФО и
          инвестиционных платформ - как самой компании, так и ее основных
          учредителей.
          <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
          <div>
            <p class="mb-0">
              Чтобы увеличить лимит в Потоке, сократите долговую нагрузку вне
              платформы, в первую очередь за счет более коротких и/или дорогих
              источников финансирования.
            </p>
          </div>
        </div>
      </div>

      <div class="title-wrapper">
        <div class="potok-text-body-1">финансирование на платформе</div>
      </div>

      <div class="modal-message-block modal-message-block-black">
        <div
          class="modal-message-block-content"
          @click="isShowContent3 = !isShowContent3"
        >
          <div class="modal-message-block-text potok-text-body-1">
            <i class="icon-item-3"></i>
            лимит на платформе
          </div>
          <div class="block-right">
            <div class="potok-text-h2 block-amount d-flex align-items-center">
              <template v-if="props.limits.available_without_restrictions">
                <span class="crossed no-wrap"
                  >{{
                    formatCurrency(
                      props.limits.available_without_restrictions,
                      0,
                    )
                  }}&nbsp;₽</span
                >
                <span class="mx-2 mb-1">→</span>
              </template>
              <span class="no-wrap"
                >{{
                  formatCurrency(props.limits.final_limit_issue, 0)
                }}&nbsp;₽</span
              >
            </div>
            <div class="description-btn">
              <i v-if="!isShowContent3" class="icon-plus"></i>
              <i v-if="isShowContent3" class="icon-minus"></i>
            </div>
          </div>
        </div>
        <div v-if="isShowContent3" class="modal-message-block-desc">
          <div
            v-if="props.limits.available_without_restrictions"
            class="sub-wrapper mb-3"
          >
            <div class="general-limit potok-text-body-1-bold mb-3">
              общий лимит на платформе —
              <span class="crossed no-wrap"
                >{{
                  formatCurrency(
                    props.limits.available_without_restrictions,
                    0,
                  )
                }}&nbsp;₽</span
              >
            </div>
            Максимальная возможная сумма долга компании перед платформой.
            <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
            <div>
              <p class="mb-0 potok-text-body-2">
                <span class="potok-text-uppercase">✦</span> увеличивайте
                кредитный потенциал компании
              </p>
              <p class="mb-0 potok-text-body-2">
                <span class="potok-text-uppercase">✦</span> уменьшайте
                обязательства компании за пределами платформы
              </p>
            </div>
          </div>
          <template v-if="props.limits.final_limit_issue > 0">
            <div class="sub-wrapper">
              <div class="general-limit potok-text-body-1-bold mb-3">
                специальный лимит —
                <span class="crossed no-wrap"
                  >{{
                    formatCurrency(props.limits.final_limit_issue, 0)
                  }}&nbsp;₽</span
                >
              </div>
              <p class="mb-0">
                Специальные ограничения по лимиту, наложенные платформой на
                данную категорию заемщиков.
              </p>
              <p
                v-if="props.limits.individual_restriction_limit_text"
                class="mt-2 mb-0"
              >
                Лимит для вашей компании ограничен:
                {{ props.limits.individual_restriction_limit_text }}
              </p>

              <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
              <div>
                <p class="mb-0 potok-text-body-2">
                  <span class="potok-text-uppercase">✦</span> увеличивайте
                  кредитный потенциал компании
                </p>
                <p class="mb-0 potok-text-body-2">
                  <span class="potok-text-uppercase">✦</span> уменьшайте
                  обязательства компании за пределами платформы
                </p>
              </div>
              <template
                v-if="props.limits.individual_restriction_limit_text_advice"
              >
                <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
                <p class="mb-0">
                  {{ props.limits.individual_restriction_limit_text_advice }}
                </p>
              </template>
            </div>
          </template>
          <template
            v-if="!props.limits.final_limit_issue && store.isRejectedState"
          >
            <p class="mt-4 mb-0">
              Платформа пока не может предоставить вам лимит для финансирования.
              По вашей компании обнаружены существенные причины, которые относят
              компанию к категории высокого риска.
            </p>
            <p class="mt-4 mb-0">
              Узнайте больше на странице
              <router-link
                class="color-tiffany"
                :to="{
                  name: 'companyAnalytic',
                }"
                target="_blank"
                >«мой бизнес»</router-link
              >.
            </p>
            <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
            <div>
              <p class="mb-0 potok-text-body-2">
                <span class="potok-text-uppercase">✦</span> увеличивайте
                кредитный потенциал компании
              </p>
              <p class="mb-0 potok-text-body-2">
                <span class="potok-text-uppercase">✦</span> уменьшайте
                обязательства компании за пределами платформы
              </p>
            </div>
          </template>
          <template
            v-if="!props.limits.final_limit_issue && !store.isRejectedState"
          >
            <p class="mt-4 mb-0">
              Платформа пока не может предоставить вашей компании положительный
              лимит. Следуйте советам, чтобы получить лимит для финансирования
              на платформе.
            </p>
            <p class="mt-4 mb-0">
              Узнайте больше на странице
              <router-link
                class="color-tiffany"
                :to="{
                  name: 'companyAnalytic',
                }"
                target="_blank"
                >«мой бизнес»</router-link
              >.
            </p>
            <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
            <div>
              <p class="mb-0 potok-text-body-2">
                <span class="potok-text-uppercase">✦</span> увеличивайте
                кредитный потенциал компании
              </p>
              <p class="mb-0 potok-text-body-2">
                <span class="potok-text-uppercase">✦</span> уменьшайте
                обязательства компании за пределами платформы
              </p>
            </div>
          </template>
        </div>
      </div>

      <div class="modal-message-block modal-message-block-yellow">
        <div
          class="modal-message-block-content"
          @click="isShowContent4 = !isShowContent4"
        >
          <div class="modal-message-block-text potok-text-body-1">
            <i class="icon-item-4"></i>
            остаток основного долга по займам
          </div>
          <div class="block-right">
            <div class="potok-text-h2 block-amount no-wrap">
              <span v-if="props.limits.internal_debt">-</span>
              {{ formatCurrency(props.limits.internal_debt, 0) }}&nbsp;₽
            </div>
            <div class="description-btn">
              <i v-if="!isShowContent4" class="icon-plus"></i>
              <i v-if="isShowContent4" class="icon-minus"></i>
            </div>
          </div>
        </div>
        <div v-if="isShowContent4" class="modal-message-block-desc">
          Сумма остатка долга по всем непогашенным траншам.
          <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
          <div>
            <p class="mb-0">
              Погасите (в том числе досрочно) более короткие и/или дорогие
              транши.
            </p>
          </div>
        </div>
      </div>
      <template v-if="!store.isOrderHold">
        <div
          v-if="!props.limits.debt_limit_excess"
          class="modal-message-block"
          :class="{
            'modal-message-block-green':
              props.limits.final_limit_issue > 0 || props.limits.available > 0,
            'modal-message-block-red':
              props.limits.final_limit_issue === 0 ||
              props.limits.available === 0,
          }"
        >
          <div
            class="modal-message-block-content"
            @click="isShowContent5 = !isShowContent5"
          >
            <div class="modal-message-block-text potok-text-body-1">
              <i
                v-if="
                  props.limits.final_limit_issue > 0 ||
                  props.limits.available > 0
                "
                class="icon-info-green-3"
              ></i>
              <i
                v-if="
                  props.limits.final_limit_issue === 0 ||
                  props.limits.available === 0
                "
                class="icon-info-red-2"
              ></i>
              доступный лимит
            </div>
            <div class="block-right">
              <div
                class="potok-text-h2 block-amount no-wrap"
                :class="{
                  'modal-color-red':
                    props.limits.final_limit_issue === 0 ||
                    props.limits.available === 0,
                }"
              >
                {{ formatCurrency(props.limits.available, 0) }}&nbsp;₽
              </div>
              <div class="description-btn">
                <i v-if="!isShowContent5" class="icon-plus"></i>
                <i v-if="isShowContent5" class="icon-minus"></i>
              </div>
            </div>
          </div>
          <div v-if="isShowContent5" class="modal-message-block-desc">
            <template v-if="props.limits.final_limit_issue > 0">
              Максимальная сумма, которую вы можете получить прямо сейчас, одним
              или несколькими траншами.
              <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
              <div>
                <p class="mb-0 potok-text-body-2">
                  <span class="potok-text-uppercase">✦</span> увеличивайте
                  кредитный потенциал компании
                </p>
                <p class="mb-0 potok-text-body-2">
                  <span class="potok-text-uppercase">✦</span> уменьшайте
                  обязательства компании за пределами платформы
                </p>
                <p class="mb-0 potok-text-body-2">
                  <span class="potok-text-uppercase">✦</span> гасите текущие
                  обязательства перед платформой
                </p>
              </div>
            </template>
            <template v-if="props.limits.final_limit_issue === 0">
              Сейчас вы не можете взять транш, так как общий лимит компании
              равен нулю.
              <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
              <div>
                <p class="mb-0 potok-text-body-2">
                  <span class="potok-text-uppercase">✦</span> увеличивайте
                  кредитный потенциал компании
                </p>
                <p class="mb-0 potok-text-body-2">
                  <span class="potok-text-uppercase">✦</span> уменьшайте
                  обязательства компании за пределами платформы
                </p>
                <p class="mb-0 potok-text-body-2">
                  <span class="potok-text-uppercase">✦</span> гасите текущие
                  обязательства перед платформой
                </p>
              </div>
            </template>
          </div>
        </div>

        <div
          v-if="props.limits.debt_limit_excess"
          class="modal-message-block modal-message-block-red"
        >
          <div
            class="modal-message-block-content"
            @click="isShowContent6 = !isShowContent6"
          >
            <div class="modal-message-block-text potok-text-body-1">
              <i class="icon-info-red-2"></i>
              превышение лимита задолженности
            </div>
            <div class="block-right">
              <div class="potok-text-h2 block-amount no-wrap color-red">
                - {{ formatCurrency(props.limits.debt_limit_excess, 0) }}&nbsp;₽
              </div>
              <div class="description-btn">
                <i v-if="!isShowContent6" class="icon-plus"></i>
                <i v-if="isShowContent6" class="icon-minus"></i>
              </div>
            </div>
          </div>
          <div v-if="isShowContent6" class="modal-message-block-desc">
            Сейчас вы не можете взять транш, так как остаток непогашенного
            основного долга превышает ваш лимит на платформе.
            <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
            <div>
              <p class="mb-0 potok-text-body-2">
                <span class="potok-text-uppercase">✦</span> увеличивайте
                кредитный потенциал компании
              </p>
              <p class="mb-0 potok-text-body-2">
                <span class="potok-text-uppercase">✦</span> уменьшайте
                обязательства компании за пределами платформы
              </p>
              <p class="mb-0 potok-text-body-2">
                <span class="potok-text-uppercase">✦</span> гасите текущие
                обязательства перед платформой
              </p>
            </div>
          </div>
        </div>
      </template>
      <div
        v-if="store.isOrderHold"
        class="modal-message-block modal-message-block-grey"
      >
        <div
          class="modal-message-block-content"
          @click="isShowContent7 = !isShowContent7"
        >
          <div class="modal-message-block-text potok-text-body-1">
            <i class="icon-lock"></i>
            доступный лимит заблокирован
          </div>
          <div class="block-right">
            <div class="potok-text-h2 block-amount no-wrap modal-color-grey">
              {{ formatCurrency(props.limits.available, 0) }}&nbsp;₽
            </div>
            <div class="description-btn">
              <i v-if="!isShowContent5" class="icon-plus"></i>
              <i v-if="isShowContent5" class="icon-minus"></i>
            </div>
          </div>
        </div>
        <div v-if="isShowContent7" class="modal-message-block-desc">
          Оформление траншей временно заблокировано. По компании обнаружены
          факторы, препятствующие выдаче средств.

          <p class="modal-message-block-desc-title mt-4">СОВЕТ</p>
          <div>
            <p class="mb-0 potok-text-body-2">
              <span class="potok-text-uppercase">✦</span> увеличивайте кредитный
              потенциал компании
            </p>
            <p class="mb-0 potok-text-body-2">
              <span class="potok-text-uppercase">✦</span> уменьшайте
              обязательства компании за пределами платформы
            </p>
            <p class="mb-0 potok-text-body-2">
              <span class="potok-text-uppercase">✦</span> не допускайте
              просрочек по кредитам и займам
            </p>
            <p class="mb-0 potok-text-body-2">
              <span class="potok-text-uppercase">✦</span> снижайте зависимость
              от одного покупателя
            </p>
          </div>
        </div>
      </div>
    </template>
  </ModalRightLimit>
</template>
<script setup>
import { ref } from 'vue';
import ModalRightLimit from '@/components/_generic/ui/modals/ModalRightLimit.vue';
import { formatDate, formatCurrency } from '@/utils';
import { useStateStore } from '@/stores/stateStore';

const props = defineProps({
  limits: {
    type: [Object],
    required: true,
  },
});
const store = useStateStore();

const isShowContent1 = ref(false);
const isShowContent2 = ref(false);
const isShowContent3 = ref(false);
const isShowContent4 = ref(false);
const isShowContent5 = ref(false);
const isShowContent6 = ref(false);
const isShowContent7 = ref(false);
</script>
<style lang="scss" scoped>
:deep(.modal-wrapper) {
  width: 750px;
  @media (max-width: $size_767) {
    width: 100%;
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 30px;
    color: $grey;
  }

  .modal-message-block {
    background-color: $background;
    border-radius: 14px;
    padding: 26px;
    margin: 16px 0;
    min-height: 100px;
    display: flex;
    align-items: center;
    flex-direction: column;
  }
  .modal-message-block-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    flex: 1;
    width: 100%;
  }
  .modal-message-block-desc {
    margin-top: 24px;
    width: 100%;
  }
  .modal-message-block-desc-title {
    color: #818e99;
    font-size: 8px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.8px;
    text-transform: uppercase;
  }
  .modal-message-block-black {
    border-left: 4px solid #10273d;
  }
  .modal-message-block-yellow {
    border-left: 4px solid #ffc11e;
  }
  .modal-message-block-red {
    border-left: 4px solid #ff5754;
  }
  .modal-message-block-grey {
    border-left: 4px solid #818e99;
  }
  .modal-color-red {
    color: #ff5754;
  }
  .modal-color-grey {
    color: #818e99;
  }
  .modal-message-block-green {
    border-left: 4px solid #04ccd9;
  }
  .modal-message-block-text {
    max-width: 327px;
    padding: 0 10px;
    display: flex;
    justify-content: left;
    align-items: center;
    @media (max-width: $size_767) {
      max-width: initial;
    }
  }

  .block-right {
    display: flex;
    align-items: center;
    @media (max-width: $size_767) {
      margin-top: 20px;
      width: 100%;
      justify-content: space-between;
    }
  }
  .crossed {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 26px; /* 130% */
    letter-spacing: -0.2px;
    color: $grey;
  }
  .icon-plus {
    width: 5px;
    height: 2px;
    background-image: url(@/assets/icons/limits/icon-plus.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
  }
  .icon-minus {
    width: 5px;
    height: 2px;
    background-image: url(@/assets/icons/limits/icon-minus.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
  }
  .description-btn {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 20px;
    flex-shrink: 0;
  }
  .icon-item-1 {
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/limits/item-1.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 14px;
  }
  .icon-info-red {
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/limits/info-red.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 14px;
  }
  .icon-info-red-2 {
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/limits/info-red-2.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 14px;
  }
  .icon-item-3 {
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/limits/item-3.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 14px;
  }
  .icon-item-4 {
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/limits/item-4.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 14px;
  }
  .icon-info-green-3 {
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/limits/info-green-3.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 14px;
  }
  .icon-lock {
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/limits/icon-lock.svg);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    flex-shrink: 0;
    margin-right: 14px;
  }

  .sub-wrapper {
    background-color: #e8ebee;
    padding: 16px 38px 16px 16px;
    border-radius: 8px;
  }
}
</style>
