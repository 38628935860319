import features from './modules/features';
import company from './modules/company';
import user from './modules/user';
import order from './modules/order/order';
import loan from './modules/loan';

export default {
  ...features,
  ...company,
  ...user,
  ...order,
  ...loan,
};
