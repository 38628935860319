<template>
  <PopoverIcon>
    <template #icon><span class="question-icon-black"></span></template>
    <template #text>
      <table class="w-100 text-nowrap">
        <tbody>
          <tr class="potok-text-body-3">
            <td class="color-grey">основной долг</td>
            <td class="text-primary text-end ps-4">
              {{ formatCurrency(props.main) }}
              ₽
            </td>
          </tr>
          <tr class="potok-text-body-3">
            <td class="color-grey">проценты</td>
            <td class="text-primary text-end">
              {{ formatCurrency(props.interest) }} ₽
            </td>
          </tr>
          <tr class="potok-text-body-3">
            <td class="color-grey">пеня</td>
            <td class="text-primary text-end">
              {{ formatCurrency(props.fine) }}
              ₽
            </td>
          </tr>
          <tr
            v-if="store.currentCompany.funds?.overdue?.penalty"
            class="potok-text-body-3"
          >
            <td class="item-cell pe-3 color-grey">штрафы платформы</td>
            <td class="text-primary text-end">
              {{
                formatCurrency(store.currentCompany.funds?.overdue?.penalty, 0)
              }}
              ₽
            </td>
          </tr>
          <tr
            v-if="store.isBillingMonthlyCommission && props.monthlyCommission"
            class="potok-text-body-3"
          >
            <td class="color-grey">ежемесячная комиссия</td>
            <td class="text-primary text-end">
              {{ formatCurrency(props.monthlyCommission) }}
              ₽
            </td>
          </tr>
        </tbody>
      </table>
      <div class="divider my-1"></div>
      <table class="w-100 text-nowrap">
        <tbody>
          <tr class="potok-text-body-3">
            <td class="color-grey">сумма НДФЛ</td>
            <td class="text-primary text-end">
              {{ formatCurrency(props.ndfl + props.fineTax) }}
              ₽
            </td>
          </tr>
        </tbody>
      </table>
    </template>
  </PopoverIcon>
</template>

<script setup>
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import { formatCurrency } from '@/utils';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const props = defineProps({
  main: {
    type: Number,
    required: true,
    default: 0,
  },
  interest: {
    type: Number,
    required: true,
    default: 0,
  },
  fine: {
    type: Number,
    required: true,
    default: 0,
  },
  fineTax: {
    type: Number,
    required: true,
    default: 0,
  },
  ndfl: {
    type: Number,
    required: true,
    default: 0,
  },
  monthlyCommission: {
    type: Number,
    required: true,
    default: 0,
  },
});
</script>

<style lang="scss" scoped></style>
