export default {
  setUiState(key, value) {
    this.uiStates[key] = value;
  },
  setNotification(data) {
    this.notification.message = data.message;
    this.notification.title = data.title;
    this.notification.timer = data.timer;
    this.notification.isError = data.isError;
  },
};
