<template>
  <div class="d-flex align-items-center cursor-pointer mt-3 mb-2">
    <i class="icon-download"></i
    ><a class="ms-2" @click="onClickOpenDoc('schedule')"
      >предварительный график платежей</a
    >
  </div>
  <div class="d-flex align-items-center cursor-pointer my-2">
    <i class="icon-download"></i
    ><a class="ms-2" @click="onClickOpenDoc('invest')"
      >инвестиционное предложение</a
    >
  </div>
  <div
    v-if="order.credit_line"
    class="d-flex align-items-center cursor-pointer mt-2"
  >
    <i class="icon-download"></i
    ><a class="ms-2" @click="onClickOpenDoc('credit_line')"
      >договор кредитной линии</a
    >
  </div>
  <div
    v-if="order.credit_line"
    class="d-flex align-items-center cursor-pointer mt-2"
  >
    <i class="icon-download"></i
    ><a class="ms-2" @click="onClickOpenDoc('rules')"
      >присоединение к правилам</a
    >
  </div>
</template>

<script setup>
import { openInNewTab } from '@/server/index';
import { toRefs } from 'vue';
import { documentsUtils } from '@/modules/documentsUtils.js';

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
});
const { order } = toRefs(props);

const {
  onClickOpenUnsignedInvestOffer,
  onClickOpenUnsignedCreditLineAgreement,
} = documentsUtils();

const onClickOpenDoc = (type) => {
  switch (type) {
    case 'invest':
      return onClickOpenUnsignedInvestOffer(order.value);
    case 'schedule':
      openInNewTab('/documents/draft_schedule.pdf?order_id=' + order.value.id);
      break;
    case 'credit_line':
      return onClickOpenUnsignedCreditLineAgreement(order.value);
    case 'rules':
      openInNewTab(
        '/documents/unsigned/borrower_accepting_the_rules.pdf?order_id=' +
          order.value.id,
      );
      break;
  }
};
</script>
