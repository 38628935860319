import config from '@/../config';

export default {
  getEverypayBanks: {
    url: config.VITE_BASE_URL + `/spa/business/everypay/banks`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  postEverypayAuthorizations: {
    url: config.VITE_BASE_URL + `/spa/business/everypay/authorizations`,
    cache: 'no-cache',
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
  postEverypaySessions: {
    url: config.VITE_BASE_URL + `/spa/business/everypay/sessions`,
    cache: 'no-cache',
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
};
