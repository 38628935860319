export const modalHelpMessage = `
<div class="modal-message-title">что такое чко?</div>
<br>
<div class="modal-message-text">
  чистые кредитовые обороты — это сумма поступлений по вашим выпискам за вычетом: 
  <ul>
    <li>полученных кредитов и займов</li>
    <li>переводов между вашими счетами</li>
    <li>переводы между аффилированными компаниями</li>
  </ul>
</div>

<br>
<div class="modal-message-title">Зачем нужна карточка про исковые требования
арбитражных дел и открытых исполнительных производств?</div>
<br>
<div class="modal-message-text">
она показывает соотношение суммы исковых требований арбитражных дел и исполнительных производств к вашему 
среднемесячному чистому кредитовому обороту за 6 месяцев.
</div>
<br>
<div class="modal-message-text">эти данные мы рассчитываем на основе ваших выписок и данных из открытых источников</div>
<br>
<div><a href="https://focus.kontur.ru/entity?query=%s" target="_blank">https://focus.kontur.ru/entity</a></div>
<div><a href="https://kad.arbitr.ru/" target="_blank">https://kad.arbitr.ru/</a> — картотека арбитражных дел</div>
<div><a href="https://fssp.gov.ru/iss/ip/" target="_blank">https://fssp.gov.ru/iss/ip/</a> — банк исполнительных производств</div>
<br>
<div class="modal-message-title">Самые крупные покупатели</div>
<div class="modal-message-text">из полученных выписок мы определяем ваших крупнейших покупателей за выбранный период. 
сюда же может попасть ваш банк-эквайер или платежный агрегатор.
</div>
`;
