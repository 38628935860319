<template>
  <OnClickOutside @trigger="emit('close-modal')">
    <div class="modal-overlay">
      <div class="wrapper">
        <div class="form m-auto w-100">
          <i class="icon-close" @click="emit('close-modal')"></i>
          <h5 class="fw-normal mb-4">оплата НДФЛ</h5>
          <span>
            уважаемый пользователь, в связи со спецификой удержания НДФЛ по
            инвесторам, точная сумма к перечислению на платформу и размер
            удержанного НДФЛ формируются в платежную дату по графику
          </span>
        </div>
      </div>
    </div>
  </OnClickOutside>
</template>

<script setup>
import { OnClickOutside } from '@vueuse/components';

const emit = defineEmits(['close-modal']);
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  .wrapper {
    height: 100vh;
    overflow-y: auto;
    width: 385px;
    background-color: white;
    box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
    position: fixed;
    top: 0;
    right: 0;
    .form {
      padding: 20px 25px 20px 30px;
      h5 {
        width: 90%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .icon-close {
        margin: 22px 25px 0px 0px;
        position: absolute;
        top: 0;
        right: 0;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: $background;
}
::-webkit-scrollbar-thumb {
  background: $grey;
}
</style>
