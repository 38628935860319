<template>
  <div class="container-header">
    <div class="container-nav">
      <nav class="nav-bar">
        <router-link class="nav-bar__logo" aria-label="Home" :to="isRouteUnAuth ? '/' : '/dashboard'">
        </router-link>
        <template v-if="!isRouteUnAuth">
          <template v-if="!isMobile">
            <div
              class="link-main ms-6 cursor-pointer"
              :class="{
                'link-main-active': route.name === 'dashboard',
              }"
              @click="onClickHandleAction('main')"
            >
              главная
            </div>
            <router-link
              class="link ms-6 cursor-pointer position-relative"
              :class="{ 'link-active': route.name === 'companyAnalytic' }"
              :to="{
                name: 'companyAnalytic',
              }"
            >
              <div class="link-text">мой бизнес</div>
            </router-link>
            <div
              class="link ms-6 cursor-pointer position-relative"
              @click="onClickHandleAction('ndfl')"
            >
              <div class="link-text">отчетность НДФЛ</div>
              <DebtReview v-model:isMenuShown="isDebtsMenuShown" />
            </div>
            <a
              href="https://investor.potok.digital/"
              target="_blank"
              class="link ms-6 cursor-pointer position-relative"
              @click="onClickHandleAction('invest')"
              ><span class="link-text">инвестору</span>
            </a>
          </template>
          <CompaniesDropdown v-if="!store.isAgent" />
        </template>
      </nav>
    </div>
  </div>
</template>

<script setup>
import DebtReview from '@/components/_generic/auth/dashboard/DebtReview.vue';
import CompaniesDropdown from '@/components/auth/header/companies-dropdown/CompaniesDropdown.vue';
import { ref, computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { useRoute, useRouter } from 'vue-router';
import { alwaysAvailablePaths } from '@/router/index.js';
import { detectMobile } from '@/utils';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Top_Mainpage_click,
  SPA_LK_Top_NDFL_click,
  SPA_LK_Top_invest_click,
} = TRACKER_EVENTS;

const route = useRoute();
const router = useRouter();
const store = useStateStore();
const { isMobile } = detectMobile();

const isDebtsMenuShown = ref(false);

const isRouteUnAuth = computed(() => {
  return !!alwaysAvailablePaths.has(route.name);
});

const onClickHandleAction = (type) => {
  switch (type) {
    case 'main':
      tracker.queue(tracker.commands.SEND, SPA_LK_Top_Mainpage_click);
      router.push({
        name: 'dashboard',
      });
      break;
    case 'ndfl':
      isDebtsMenuShown.value = !isDebtsMenuShown.value;
      tracker.queue(tracker.commands.SEND, SPA_LK_Top_NDFL_click);
      break;
    case 'invest':
      tracker.queue(tracker.commands.SEND, SPA_LK_Top_invest_click);
      break;
    default:
      break;
  }
};
</script>

<style lang="scss" scoped>
.nav-bar {
  margin-right: auto;
  margin-left: auto;
  max-width: 1440px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  justify-content: flex-start;
  height: $navbar-height;
  @media (max-width: $size_767) {
    height: $navbar-mobile-height;
  }
  .nav-bar__logo {
    background-image: url(@/assets/icons/logo.svg);
    background-repeat: no-repeat;
    width: 72px;
    height: 24px;
  }
  .link-main {
    color: $grey;
    &:hover {
      color: $primary;
    }
  }
  .link-main-active {
    color: $primary;
    &:hover {
      color: $grey;
    }
  }
  .link {
    .link-text {
      color: $grey;
    }
    &:hover {
      .link-text {
        color: $primary;
      }
    }
  }
  .link-active {
    .link-text {
      color: $primary;
    }
  }
}
</style>
