<template>
  <table class="table-bottom">
    <thead>
      <th v-if="store.isOverdue" class="color-grey small">
        просрочка
        <OverdueHint
          class="ms-1"
          :main="store.currentCompany.funds.overdue?.main"
          :interest="store.currentCompany.funds.overdue?.interest"
          :fine="store.currentCompany.funds.overdue?.fine"
          :fine-tax="store.currentCompany.funds.overdue?.fine_tax"
          :ndfl="store.currentCompany.funds.overdue?.ndfl"
          :monthly-commission="
            store.currentCompany.funds.overdue?.monthly_commission
          "
        />
      </th>
      <th class="color-grey small">осталось погасить</th>
      <th v-if="store.isLoanExists" class="color-grey small">
        <div class="d-flex align-items-center">
          <span class="d-inline-block">НДФЛ к оплате в бюджет</span>
          <i
            class="question-icon-black ms-1 cursor-pointer"
            @click="emit('update:isModalShown', true)"
          ></i>
        </div>
      </th>
      <NdflHint
        v-if="props.isModalShown"
        @close-modal="emit('update:isModalShown', false)"
      />
    </thead>
    <tbody>
      <tr class="fw-bold">
        <td v-if="store.isOverdue" class="text-danger">
          {{ overdueAmount }}
          ₽
        </td>
        <td>
          {{
            formatCurrency(store.currentCompany.funds?.debt?.residue?.main, 0)
          }}
          ₽
        </td>
        <td
          v-if="store.isLoanExists"
          v-text="
            store.isPaymentToday
              ? formatCurrency(
                  store.currentCompany.funds?.next_payment?.ndfl,
                  0,
                ) + ' ₽'
              : 'будет рассчитан в дату платежа'
          "
        ></td>
      </tr>
    </tbody>
  </table>
</template>

<script setup>
import { computed } from 'vue';
import OverdueHint from '@/components/auth/dashboard/modules/OverdueHint.vue';
import NdflHint from '@/components/auth/dashboard/modules/NdflHint.vue';
import { useStateStore } from '@/stores/stateStore';
import { formatCurrency } from '@/utils';

const store = useStateStore();

const props = defineProps({
  isModalShown: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:isModalShown']);

const overdueAmount = computed(() => {
  let value = 0;
  if (store.currentCompany?.funds?.overdue?.main) {
    value += store.currentCompany?.funds?.overdue?.main;
  }
  if (store.currentCompany?.funds?.overdue?.interest) {
    value += store.currentCompany?.funds?.overdue?.interest;
  }
  if (store.currentCompany?.funds?.overdue?.penalty) {
    value += store.currentCompany?.funds?.overdue?.penalty;
  }
  if (store.currentCompany?.funds?.overdue?.fine) {
    value += store.currentCompany?.funds?.overdue?.fine;
  }
  if (
    store.isBillingMonthlyCommission &&
    store.currentCompany?.funds?.overdue?.monthly_commission
  ) {
    value += store.currentCompany?.funds?.overdue?.monthly_commission;
  }

  return formatCurrency(value);
});
</script>

<style lang="scss" scoped>
.table-bottom {
  th {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
    text-transform: lowercase;
    padding: 0 20px;
    &:first-child {
      padding-left: 0;
    }
  }
  td {
    padding: 0 20px;
    &:first-child {
      padding-left: 0;
    }
  }
}
</style>
