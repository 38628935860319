<template>
  <div class="w-100">
    <OrderFilters @change-filters="onChangeFilters" />
    <template v-if="store.currentCompany.dashboard_orders_count > 0">
      <div
        v-for="order in props.orders"
        :key="order.loan_id"
        class="form-order p-4 bg-white rounded-4 mb-6"
      >
        <OrderCard :order="order" />
      </div>
    </template>
    <template
      v-if="
        props.initiated && store.currentCompany.dashboard_orders_count === 0
      "
    >
      <div class="white-block form-empty">
        <i class="icon-document-filter mb-4"></i>
        <div class="text-empty">у вас пока нет займов</div>
        <PotokButton
          v-if="!store.isCompanyBanned"
          class="button-new-order mb-1"
          text="оформить новый заем"
          size="medium"
          theme="secondary"
          @click="onClickNewOrder"
        />
        <PotokButton
          class="button-new-order mt-1"
          text="пополнить баланс"
          size="medium"
          theme="secondary"
          @click="onClickGoToRefill"
        />
      </div>
    </template>
    <template
      v-if="
        props.initiated &&
        !props.loadingOrders &&
        store.currentCompany.dashboard_orders_count > 0 &&
        props.orders.length === 0
      "
    >
      <div class="white-block form-empty">
        <i class="icon-document-filter mb-4"></i>
        <div
          v-if="store.orders.filters.selectedFilter === 'open'"
          class="text-empty"
        >
          у вас нет открытых займов
        </div>
        <div
          v-if="store.orders.filters.selectedFilter === 'closed'"
          class="text-empty"
        >
          у вас нет закрытых займов
        </div>
        <PotokButton
          v-if="!store.isCompanyBanned"
          class="button-new-order"
          text="оформить новый заем"
          size="medium"
          theme="secondary"
          @click="onClickNewOrder"
        />
      </div>
    </template>

    <div v-if="props.loadingOrders" class="d-flex justify-content-center w-100">
      <LoadingOrdersPageOne v-if="props.page === 1" />
      <Loader v-if="props.page > 1" />
    </div>
  </div>
</template>

<script setup>
import { PotokButton, Loader } from 'potok-uikit';
import { useRouter } from 'vue-router';
import OrderCard from '@/components/auth/dashboard/orders/OrderCard.vue';
import LoadingOrdersPageOne from '@/components/auth/dashboard/orders/LoadingOrdersPageOne.vue';
import OrderFilters from '@/components/auth/dashboard/orders/OrderFilters.vue';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Mainpage_new_order } = TRACKER_EVENTS;

const emit = defineEmits(['change-filters']);
const props = defineProps({
  orders: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
  loadingOrders: {
    type: Boolean,
    default: false,
    required: true,
  },
  page: {
    type: Number,
    default: 1,
  },
  initiated: {
    type: Boolean,
    default: false,
  },
});
const store = useStateStore();
const router = useRouter();

const onChangeFilters = () => {
  emit('change-filters');
};

const onClickGoToRefill = () => {
  router.push({
    name: 'accountRefill',
  });
};

const onClickNewOrder = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Mainpage_new_order);
  store.setUiState('isDashboardLimitTabActive', true);
};
</script>

<style lang="scss" scoped>
.form-order {
  min-height: 154px;
  @media (max-width: $size_767) {
    min-width: 100%;
    height: auto;
  }
  &:hover {
    cursor: pointer;
  }
}
.form-empty {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 60px 20px;
}
.text-empty {
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
.button-new-order {
  margin-top: 34px;
  margin-bottom: 60px;
  max-width: 240px;
}
</style>
