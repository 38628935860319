<template>
  <div class="loan-actions-block">
    <PotokButton
      text="на главную"
      size="large"
      theme="secondary"
      @click="onClickGoDashboard"
    />
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { loanCardVKLUtils } from '@/components/auth/loan-card/vkl-flow/utils/loanCardVKLUtils.js';

const { onClickGoDashboard } = loanCardVKLUtils();
</script>
