<template>
  <div class="companies-menu-wrapper ms-auto">
    <button
      class="menu-toggle text-center"
      type="button"
      @click="isShowDropdownMenu = !isShowDropdownMenu"
    >
      <i v-if="isMobile" class="dots"></i>
      <template v-if="!isMobile">
        <div
          v-if="store.companies.loading"
          class="d-flex justify-content-center"
        >
          <Loader />
        </div>
        <div v-else class="title">
          {{ store.currentCompany.short_title || store.currentCompany.title }}
        </div>
      </template>
    </button>
    <template v-if="isShowDropdownMenu">
      <OnClickOutside @trigger="isShowDropdownMenu = !isShowDropdownMenu">
        <CompaniesDropdownDesktop v-if="!isMobile" @close="onClose" />

        <CompaniesDropdownMobile
          v-if="isMobile"
          :loading="store.companies.loading"
          @close="onClose"
        />
      </OnClickOutside>
    </template>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { OnClickOutside } from '@vueuse/components';
import { useStateStore } from '@/stores/stateStore';
import { Loader } from 'potok-uikit';
import CompaniesDropdownDesktop from './CompaniesDropdownDesktop.vue';
import CompaniesDropdownMobile from './CompaniesDropdownMobile.vue';

import { detectMobile } from '@/utils';
import { menuUtils } from './menuUtils.js';

const store = useStateStore();
const { isMobile } = detectMobile();
const { getCompaniesAsync } = menuUtils();

const isShowDropdownMenu = ref(false);

const onClose = () => {
  isShowDropdownMenu.value = false;
};
getCompaniesAsync();
</script>

<style lang="scss" scoped>
.companies-menu-wrapper {
  position: relative;
  min-width: 150px;
  max-width: 236px;
  @media (max-width: $size_767) {
    min-width: 32px;
    max-width: 32px;
    font-size: 12px;
  }
  .show {
    &::after {
      transform: rotate(180deg);
    }
  }
  .menu-toggle {
    width: 100%;
    right: 17px;
    top: 16px;
    background-color: $background;
    border: none;
    border-radius: 28px;
    color: $dipedive;
    padding: 10px 32px 10px 18px;
    @media (max-width: $size_767) {
      border-radius: 50%;
      padding: 16px 16px;
      height: 32px;
    }
    &::after {
      position: absolute;
      right: 17px;
      top: 16px;
      content: '';
      background-image: url(@/assets/icons/arrows/caret_arrow.svg);
      background-repeat: no-repeat;
      width: 8px;
      height: 8px;
      border: none;
      @media (max-width: $size_767) {
        display: none;
      }
    }
    .dots {
      position: absolute;
      left: 10px;
      bottom: 14.5px;
      width: 12px;
      height: 3px;
      display: inline-block;
      background-image: url(@/assets/icons/dots.svg);
      background-repeat: no-repeat;
      background-size: cover;
    }
    .title {
      font-size: 14px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
