<template>
  <OnClickOutside
    class="modal"
    :class="{ closing: isClosing, opening: isOpening }"
    @trigger="emit('close-modal')"
  >
    <div class="modal-overlay" @click.stop="onClickClose"></div>
    <div class="modal-wrapper">
      <div class="form m-auto w-100">
        <i class="icon-close" @click="onClickClose"></i>
        <div v-if="slots.title" class="modal-title mb-4 potok-text-h2">
          <slot name="title"></slot>
        </div>
        <slot name="content"></slot>
      </div>
    </div>
  </OnClickOutside>
</template>

<script setup>
import { OnClickOutside } from '@vueuse/components';
import { ref, useSlots, onMounted } from 'vue';

const slots = useSlots();
const emit = defineEmits(['close-modal']);
const isClosing = ref(false);
const isOpening = ref(false);

const onClickClose = () => {
  isClosing.value = true;
  setTimeout(() => {
    emit('close-modal');
  }, 3000);
};

onMounted(() => {
  isOpening.value = true;
});
</script>

<style lang="scss" scoped>
.modal {
  position: fixed;
  display: table;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  transform: scale(0);
  z-index: 10000;
  &.opening {
    transform: scale(1);
  }
  &.closing {
    animation: quickScaleDown 0s 0.1s linear forwards;
    .modal-overlay {
      animation: fadeOut 0.1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
      // .modal-wrapper {
      // animation: roadRunnerOut 0.5s cubic-bezier(0.165, 0.84, 0.44, 1)
      // forwards;
      // }
    }
  }
}
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
  animation: fadeIn 0.1s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;
}

.modal-wrapper {
  height: 100vh;
  overflow-y: auto;
  width: 454px;
  background-color: white;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1000;
  padding: 30px;

  // transform: translateX(+1500px);
  // animation: roadRunnerIn 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) forwards;

  @media (max-width: $size_767) {
    width: 100%;
  }

  .form {
    .icon-close {
      margin: 22px 25px 0px 0px;
      position: absolute;
      top: 0;
      right: 0;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-track {
  background: $background;
}
::-webkit-scrollbar-thumb {
  background: $grey;
}

@keyframes fadeIn {
  0% {
    background: rgba(0, 0, 0, 0);
  }
  100% {
    background: rgba(0, 0, 0, 0.7);
  }
}

@keyframes fadeOut {
  0% {
    background: rgba(0, 0, 0, 0.7);
  }
  100% {
    background: rgba(0, 0, 0, 0);
  }
}

@keyframes roadRunnerIn {
  0% {
    transform: translateX(+1500px) skewX(30deg) scaleX(1.3);
  }
  70% {
    transform: translateX(30px) skewX(0deg) scaleX(0.9);
  }
  100% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
}

@keyframes roadRunnerOut {
  0% {
    transform: translateX(0px) skewX(0deg) scaleX(1);
  }
  30% {
    transform: translateX(-30px) skewX(-5deg) scaleX(0.9);
  }
  100% {
    transform: translateX(+1500px) skewX(30deg) scaleX(1.3);
  }
}

@keyframes quickScaleDown {
  0% {
    transform: scale(1);
  }
  99.9% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}

.modal-title {
  width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  letter-spacing: 0.2px;
}

:deep(.modal-message-title) {
  color: $dipedive;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  text-transform: lowercase;
}

:deep(.modal-message-text) {
  color: $dipedive;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: lowercase;
}
</style>
