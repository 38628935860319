<template>
  <ModalFull @close-modal="onClickCloseModal">
    <template #title>загрузите дополнительные документы</template>
    <template #content>
      <div
        v-if="docsToUpload.includes('military_card')"
        class="d-flex align-items-center"
      >
        <FileUpload
          ref="upload_military"
          v-model="documents.military_card"
          name="file"
          input-id="military_card"
          :post-action="postUserAttachmentsAsync()"
          :headers="{ Authorization: store.session.token }"
          :data="{
            kind: 'military_card',
            attachable_id: order.id,
            attachable_type: 'Order',
          }"
          class="upload-wrapper"
          :style="
            changeIconCover(
              'military_card',
              documents.military_card[0]?.url,
              documents.military_card[0]?.filename ||
                documents.military_card[0]?.name,
            )
          "
          :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
          accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
          @input-file="inputFile"
          @input-filter="inputFilter"
        />
        <span class="file-name small ms-4">{{
          documents.military_card[0]?.filename ||
          documents.military_card[0]?.name ||
          'скан военного билета'
        }}</span>
        <!-- <span
          class="small ms-auto text-tiffany cursor-pointer"
          @click="onClickRemoveElementAsync(documents.military_card)"
          >удалить</span
        > -->
      </div>
      <template v-if="docsToUpload.includes('rent_document')">
        <hr v-if="docsToUpload.includes('military_card')" class="color-grey" />
        <div class="d-flex align-items-center">
          <FileUpload
            ref="upload_rent"
            v-model="documents.rent_document"
            name="file"
            input-id="rent_document"
            :post-action="postUserAttachmentsAsync()"
            :headers="{ Authorization: store.session.token }"
            :data="{
              kind: 'rent_document',
              attachable_id: order.id,
              attachable_type: 'Order',
            }"
            class="upload-wrapper"
            :style="
              changeIconCover(
                'rent_document',
                documents.rent_document[0]?.url,
                documents.rent_document[0]?.filename ||
                  documents.rent_document[0]?.name,
              )
            "
            :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
            accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
            @input-file="inputFile"
            @input-filter="inputFilter"
          />
          <span class="file-name small ms-4">{{
            documents.rent_document[0]?.filename ||
            documents.rent_document[0]?.name ||
            'договор аренды офисного помещения'
          }}</span>
          <!-- <span
            class="small ms-auto text-tiffany cursor-pointer"
            @click="onClickRemoveElementAsync(documents.rent_document)"
            >удалить</span
          > -->
        </div>
      </template>
      <template v-if="docsToUpload.includes('leasing_agreements_table')">
        <hr class="color-grey" />
        <div class="d-flex align-items-center">
          <FileUpload
            ref="upload_agreements"
            v-model="documents.leasing_agreements_table"
            name="file"
            input-id="leasing_agreements_table"
            :post-action="postUserAttachmentsAsync()"
            :headers="{ Authorization: store.session.token }"
            :data="{
              kind: 'leasing_agreements_table',
              attachable_id: order.id,
              attachable_type: 'Order',
            }"
            class="upload-wrapper"
            :style="
              changeIconCover(
                'leasing_agreements_table',
                documents.leasing_agreements_table[0]?.url,
                documents.leasing_agreements_table[0]?.filename ||
                  documents.leasing_agreements_table[0]?.name,
              )
            "
            :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
            accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
            @input-file="inputFile"
            @input-filter="inputFilter"
          />
          <span class="file-name small ms-4">{{
            documents.leasing_agreements_table[0]?.filename ||
            documents.leasing_agreements_table[0]?.name ||
            'таблица соглашений лизинга'
          }}</span>
          <!-- <span
            class="small ms-auto text-tiffany cursor-pointer"
            @click="onClickRemoveElementAsync(documents.leasing_agreements_table)"
            >удалить</span
          > -->
        </div>
      </template>
      <template v-if="docsToUpload.includes('osv')">
        <hr class="color-grey" />
        <div class="d-flex align-items-center">
          <FileUpload
            ref="upload_osv"
            v-model="documents.osv"
            name="file"
            input-id="osv"
            :post-action="postUserAttachmentsAsync()"
            :headers="{ Authorization: store.session.token }"
            :data="{
              kind: 'osv',
              attachable_id: order.id,
              attachable_type: 'Order',
            }"
            class="upload-wrapper"
            :style="
              changeIconCover(
                'osv',
                documents.osv[0]?.url,
                documents.osv[0]?.filename || documents.osv[0]?.name,
              )
            "
            :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
            accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
            @input-file="inputFile"
            @input-filter="inputFilter"
          />
          <span class="file-name small ms-4">{{
            documents.osv[0]?.filename || documents.osv[0]?.name || 'осв'
          }}</span>
          <!-- <span
            class="small ms-auto text-tiffany cursor-pointer"
            @click="onClickRemoveElementAsync(documents.osv)"
            >удалить</span
          > -->
        </div>
      </template>
      <template v-if="docsToUpload.includes('other_documents')">
        <hr class="color-grey" />
        <div class="d-flex align-items-center">
          <FileUpload
            ref="upload_other"
            v-model="documents.other_documents"
            name="file"
            input-id="other_documents"
            :post-action="postUserAttachmentsAsync()"
            :headers="{ Authorization: store.session.token }"
            :data="{
              kind: 'other_documents',
              attachable_id: order.id,
              attachable_type: 'Order',
            }"
            class="upload-wrapper"
            :style="
              changeIconCover(
                'other_documents',
                documents.other_documents[0]?.url,
                documents.other_documents[0]?.filename ||
                  documents.other_documents[0]?.name,
              )
            "
            :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
            accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
            @input-file="inputFile"
            @input-filter="inputFilter"
          />
          <span class="file-name small ms-4">{{
            documents.other_documents[0]?.filename ||
            documents.other_documents[0]?.name ||
            'другие документы'
          }}</span>
          <!-- <span
            class="small ms-auto text-tiffany cursor-pointer"
            @click="onClickRemoveElementAsync(documents.other_documents)"
            >удалить</span
          > -->
        </div></template
      >
      <template v-if="docsToUpload.includes('payments_schedule')">
        <hr class="color-grey" />
        <div class="d-flex align-items-center">
          <FileUpload
            ref="upload_schedule"
            v-model="documents.payments_schedule"
            name="file"
            input-id="payments_schedule"
            :post-action="postUserAttachmentsAsync()"
            :headers="{ Authorization: store.session.token }"
            :data="{
              kind: 'payments_schedule',
              attachable_id: order.id,
              attachable_type: 'Order',
            }"
            class="upload-wrapper"
            :style="
              changeIconCover(
                'payments_schedule',
                documents.payments_schedule[0]?.url,
                documents.payments_schedule[0]?.filename ||
                  documents.payments_schedule[0]?.name,
              )
            "
            :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
            accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
            @input-file="inputFile"
            @input-filter="inputFilter"
          />
          <span class="file-name small ms-4">{{
            documents.payments_schedule[0]?.filename ||
            documents.payments_schedule[0]?.name ||
            'график платежей'
          }}</span>
          <!-- <span
            class="small ms-auto text-tiffany cursor-pointer"
            @click="onClickRemoveElementAsync(documents.payments_schedule)"
            >удалить</span
          > -->
        </div>
      </template>
      <template v-if="docsToUpload.includes('previous_passport')">
        <hr class="color-grey" />
        <div class="d-flex align-items-center">
          <FileUpload
            ref="upload_passport"
            v-model="documents.previous_passport"
            name="file"
            input-id="previous_passport"
            :post-action="postUserAttachmentsAsync()"
            :headers="{ Authorization: store.session.token }"
            :data="{
              kind: 'previous_passport',
              attachable_id: order.id,
              attachable_type: 'Order',
            }"
            class="upload-wrapper"
            :style="
              changeIconCover(
                'previous_passport',
                documents.previous_passport[0]?.url,
                documents.previous_passport[0]?.filename ||
                  documents.previous_passport[0]?.name,
              )
            "
            :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
            accept="image/png,image/gif,image/jpeg,image/webp,application/pdf"
            @input-file="inputFile"
            @input-filter="inputFilter"
          />
          <span class="file-name small ms-4">{{
            documents.previous_passport[0]?.filename ||
            documents.previous_passport[0]?.name ||
            'предыдущий паспорт'
          }}</span>
        </div>
      </template>
      <PotokButton
        v-if="!loading"
        class="mt-6 w-100"
        text="отправить"
        size="large"
        @click="onClickCloseModal()"
      />
      <LoadingBtn v-if="loading" class="mt-6" />
      <ErrorItem
        v-if="errors.length !== 0"
        v-model="errors"
        class="text-center mx-auto mt-4"
      />
    </template>
  </ModalFull>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import FileUpload from 'vue-upload-component';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import { onMounted, ref, computed, watch, reactive, toRefs } from 'vue';
import tracker from '@/tracker';
import server from '@/server/index';
import config from '@/../config';
import constants from '@/constants';
import { TRACKER_EVENTS } from '@/tracker/events';
import { useStateStore } from '@/stores/stateStore';

const {
  SPA_LK_application_addition_open,
  SPA_LK_VKL_application_addition_open,
  SPA_LK_application_addition_success,
  SPA_LK_VKL_application_addition_success,
} = TRACKER_EVENTS;

const store = useStateStore();

const documents = reactive({
  leasing_agreements_table: [],
  military_card: [],
  osv: [],
  other_documents: [],
  payments_schedule: [],
  previous_passport: [],
  rent_document: [],
});

const leasing_agreements_table_id = ref();
const military_card_id = ref();
const osv_id = ref();
const other_documents_id = ref();
const payments_schedule_id = ref();
const previous_passport_id = ref();
const rent_document_id = ref();

const upload_agreements = ref();
const upload_military = ref();
const upload_osv = ref();
const upload_other = ref();
const upload_schedule = ref();
const upload_passport = ref();
const upload_rent = ref();

const errors = ref([]);
const loading = ref(false);

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
  docsToUpload: {
    type: Array,
    default() {
      return [];
    },
  },
});
const { docsToUpload, order } = toRefs(props);
const emit = defineEmits([
  'close-modal',
  'update:isAdditionalDocsUploaded',
  'update:isAdditionalDocsPartiallyUploaded',
]);

onMounted(() => {
  getAdditionalDocsAsync();
  tracker.queue(
    tracker.commands.SEND,
    order.value.credit_line
      ? SPA_LK_VKL_application_addition_open
      : SPA_LK_application_addition_open,
  );
});

const checkSuccessStatus = computed(() => {
  const filteredData = docsToUpload.value.map((el) => documents[el]);
  return filteredData.every((el) => el?.[0]?.success || el?.[0]?.kind);
});
const checkPartiallySuccessStatus = computed(() => {
  const filteredData = docsToUpload.value.map((el) => documents[el]);
  return filteredData.some((el) => el?.[0]?.success || el?.[0]?.kind);
});

watch(
  () => checkSuccessStatus.value,
  () => {
    tracker.queue(
      tracker.commands.SEND,
      order.value.credit_line
        ? SPA_LK_VKL_application_addition_success
        : SPA_LK_application_addition_success,
    );
    emit('update:isAdditionalDocsUploaded', checkSuccessStatus.value);
  },
);
watch(
  () => checkPartiallySuccessStatus.value,
  () =>
    emit(
      'update:isAdditionalDocsPartiallyUploaded',
      checkPartiallySuccessStatus.value,
    ),
);

const onClickCloseModal = () => {
  emit('close-modal');
};
const changeIconCover = (variable, url, filename) => {
  return documents[variable].length > 0 && !filename.includes('.pdf')
    ? `background-image: url(` + url + `)`
    : {};
};
const inputFilter = (newFile, oldFile, prevent) => {
  if (newFile && !oldFile) {
    if (!/\.(gif|jpg|jpeg|png|webp|pdf)$/i.test(newFile.name)) {
      return prevent();
    }
  }
  if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
    newFile.url = '';
    let URL = window.URL || window.webkitURL;
    if (URL && URL.createObjectURL) {
      newFile.url = URL.createObjectURL(newFile.file);
    }
  }
  // передаём куки в запросе
  if (newFile && oldFile && newFile.xhr && !newFile.xhr.withCredentials) {
    newFile.xhr.withCredentials = true;
  }
};
const postUserAttachmentsAsync = () => {
  return config.VITE_BASE_URL + '/spa/business/attachments';
};
const inputFile = (newFile, oldFile) => {
  // автозагрузка файлов при добавлении
  if (
    Boolean(newFile) !== Boolean(oldFile) ||
    oldFile.error !== newFile.error
  ) {
    if (upload_military.value && upload_military.value?.active === false) {
      upload_military.value.active = true;
      loading.value = true;
    }
    if (upload_rent.value && upload_rent.value?.active === false) {
      upload_rent.value.active = true;
      loading.value = true;
    }
    if (upload_osv.value && upload_osv.value?.active === false) {
      upload_osv.value.active = true;
      loading.value = true;
    }
    if (upload_other.value && upload_other.value?.active === false) {
      upload_other.value.active = true;
      loading.value = true;
    }
    if (upload_schedule.value && upload_schedule.value?.active === false) {
      upload_schedule.value.active = true;
      loading.value = true;
    }
    if (upload_passport.value && upload_passport.value?.active === false) {
      upload_passport.value.active = true;
      loading.value = true;
    }
    if (upload_agreements.value && upload_agreements.value?.active === false) {
      upload_agreements.value.active = true;
      loading.value = true;
    }
  }
  // show loader until load
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    if (newFile.xhr) {
      loading.value = false;
    }
  }
  if (newFile && oldFile && !newFile.active && oldFile.active) {
    if (newFile?.response?.error?.messages) {
      errors.value = newFile?.response?.error?.messages;
    }
  }
};
const getAdditionalDocsAsync = () => {
  loading.value = true;
  const query = {
    kind: 'additional_documents',
    attachable_id: order.value.id,
    attachable_type: 'Order',
  };
  return server.getUploadAttachments
    .send(query, {})
    .pipe(getAdditionalDocsAsyncSuccess, getAdditionalDocsAsyncError)
    .exec();
};
const getAdditionalDocsAsyncSuccess = ({ data }) => {
  const docTypes = [
    constants.ADDITIONAL_DOC_TYPES.military_card,
    constants.ADDITIONAL_DOC_TYPES.rent_document,
    constants.ADDITIONAL_DOC_TYPES.leasing_agreements_table,
    constants.ADDITIONAL_DOC_TYPES.osv,
    constants.ADDITIONAL_DOC_TYPES.other_documents,
    constants.ADDITIONAL_DOC_TYPES.payments_schedule,
    constants.ADDITIONAL_DOC_TYPES.previous_passport,
  ];
  const filteredData = data.filter((el) => docTypes.includes(el.kind));
  if (filteredData.length === 0) {
    loading.value = false;
    return;
  }
  const docsToFetch = [
    {
      kind: 'military_card',
      idSetter: (id) => {
        military_card_id.value = id;
      },
      asyncFetcher: getMilitaryCardAsync,
    },
    {
      kind: 'rent_document',
      idSetter: (id) => {
        rent_document_id.value = id;
      },
      asyncFetcher: getRentDocumentAsync,
    },
    {
      kind: 'leasing_agreements_table',
      idSetter: (id) => {
        leasing_agreements_table_id.value = id;
      },
      asyncFetcher: getLeasingAgreementsTableAsync,
    },
    {
      kind: 'osv',
      idSetter: (id) => {
        osv_id.value = id;
      },
      asyncFetcher: getOsvAsync,
    },
    {
      kind: 'other_documents',
      idSetter: (id) => {
        other_documents_id.value = id;
      },
      asyncFetcher: getOtherDocumentsAsync,
    },
    {
      kind: 'payments_schedule',
      idSetter: (id) => {
        payments_schedule_id.value = id;
      },
      asyncFetcher: getPaymentsScheduleAsync,
    },
    {
      kind: 'previous_passport',
      idSetter: (id) => {
        previous_passport_id.value = id;
      },
      asyncFetcher: getPreviousPassportAsync,
    },
  ];

  for (const doc of docsToFetch) {
    const docData = filteredData.find((el) => el.kind === doc.kind);
    if (docData) {
      doc.idSetter(docData.id);
      doc.asyncFetcher(docData.id, docData.filename);
    }
  }
};
const getAdditionalDocsAsyncError = (error) => {
  errors.value = error.data?.messages;
  loading.value = false;
};
const getAttachmentAsync = (kind, id, value, valueId, fileName) => {
  const query = {
    kind: kind,
    attachable_id: order.value.id,
    attachable_type: 'Order',
  };
  return server.getUploadAttachments$id
    .send(query, {
      params: {
        id: id,
      },
    })
    .pipe(
      ({ data }) => {
        value[0] = {
          url: '',
          kind,
          id: valueId,
          filename: fileName,
        };
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          value[0].url = URL.createObjectURL(data);
        }
        loading.value = false;
      },
      (error) => {
        errors.value = error.data?.messages;
        loading.value = false;
      },
    )
    .exec();
};
const getMilitaryCardAsync = (id, fileName) => {
  return getAttachmentAsync(
    'military_card',
    id,
    documents.military_card,
    military_card_id.value,
    fileName,
  );
};
const getRentDocumentAsync = (id, fileName) => {
  return getAttachmentAsync(
    'rent_document',
    id,
    documents.rent_document,
    rent_document_id.value,
    fileName,
  );
};
const getOsvAsync = (id, fileName) => {
  return getAttachmentAsync('osv', id, documents.osv, osv_id.value, fileName);
};
const getLeasingAgreementsTableAsync = (id, fileName) => {
  return getAttachmentAsync(
    'leasing_agreements_table',
    id,
    documents.leasing_agreements_table,
    leasing_agreements_table_id.value,
    fileName,
  );
};
const getOtherDocumentsAsync = (id, fileName) => {
  return getAttachmentAsync(
    'other_documents',
    id,
    documents.other_documents,
    other_documents_id.value,
    fileName,
  );
};
const getPaymentsScheduleAsync = (id, fileName) => {
  return getAttachmentAsync(
    'payments_schedule',
    id,
    documents.payments_schedule,
    payments_schedule_id.value,
    fileName,
  );
};
const getPreviousPassportAsync = (id, fileName) => {
  return getAttachmentAsync(
    'previous_passport',
    id,
    documents.previous_passport,
    previous_passport_id.value,
    fileName,
  );
};
// Пока не даём юзерам возможность удалять доки, по требованию продуктов

// const onClickRemoveElementAsync = (el) => {
//   loading.value = true;
//   const query = {
//     kind: el[0].kind,
//     attachable_type: 'Order',
//     attachable_id: order.value.id,
//   };
//   return server.deleteAttachment$id
//     .send(query, {
//       params: {
//         id: el[0].id,
//       },
//     })
//     .pipe(onClickRemoveElementAsyncSuccess(el), onClickRemoveElementAsyncError)
//     .exec();
// };
// const onClickRemoveElementAsyncSuccess = (el) => {
//   documents[el[0].kind].splice(0, 1);
//   loading.value = false;
// };
// const onClickRemoveElementAsyncError = (error) => {
//   errors.value = error.data?.messages;
//   loading.value = false;
// };
</script>

<style lang="scss" scoped>
.upload-wrapper {
  display: block;
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-image: url(@/assets/icons/files/file-uploads-sm-img.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &:hover {
    border: 1px dashed $primary;
  }
}
.file-name {
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
