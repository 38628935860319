import { ref, reactive } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';

export function useParser(emit) {
  const store = useStateStore();
  const loading = ref(false);
  const errors = ref([]);
  const pages = reactive({
    passport_main: [],
    passport_registration: [],
    passport_selfie: [],
  });

  const postCommandsAsync = () => {
    loading.value = true;
    const query = {
      command: {
        command_name: 'parse_passport_scans',
        resource_name: 'Guarantor',
        resource_id: store.currentGuarantor.id,
      },
    };
    return server.postCommands
      .send(query)
      .pipe(onPostCommandsAsyncSuccess, onPostCommandsAsyncError)
      .exec();
  };
  const onPostCommandsAsyncSuccess = () => {
    loading.value = false;
    emit('get-guarantors-async');
    emit('close-modal');
  };
  const onPostCommandsAsyncError = (error) => {
    loading.value = false;
    errors.value = error.data?.messages;
  };

  const getUploadedPassportsListAsync = () => {
    loading.value = true;
    const query = {
      kind: 'passport_main',
      attachable_id: store.currentGuarantor.id,
      attachable_type: 'Guarantor',
    };
    return server.getUploadAttachments
      .send(query, {})
      .pipe(getPassportsAsyncSuccess, getPassportsAsyncError)
      .exec();
  };
  const getPassportsAsyncSuccess = ({ data }) => {
    if (!data.length) {
      loading.value = false;
      return;
    }
    const filterMain = data.filter((el) => el.kind === 'passport_main');
    const filterReg = data.filter((el) => el.kind === 'passport_registration');
    const filterSelfie = data.filter((el) => el.kind === 'passport_selfie');
    if (filterMain.length) {
      getPassportMainAsync(filterMain[0].filename, filterMain[0].id);
    }
    if (filterReg.length) {
      getPassportRegistrationAsync(filterReg[0].filename, filterReg[0].id);
    }
    if (filterSelfie.length) {
      getPassportSelfieAsync(filterSelfie[0].filename, filterSelfie[0].id);
    }
  };
  const getPassportsAsyncError = (error) => {
    errors.value = error.data?.messages;
    loading.value = false;
  };
  const getPassportMainAsync = (filename, docId) => {
    const query = {
      kind: 'passport_main',
      attachable_id: store.currentGuarantor.id,
      attachable_type: 'Guarantor',
    };
    return server.getUploadAttachments$id
      .send(query, {
        params: {
          id: docId,
        },
      })
      .pipe(({ data }) => {
        pages.passport_main[0] = {
          url: '',
          filename,
          success: true,
        };
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          pages.passport_main[0].url = URL.createObjectURL(data);
        }
        loading.value = false;
      }, getPassportMainAsyncError)
      .exec();
  };
  const getPassportMainAsyncError = (error) => {
    loading.value = false;
    errors.value = error.data?.messages;
  };
  const getPassportRegistrationAsync = (filename, docId) => {
    const query = {
      kind: 'passport_registration',
      attachable_id: store.currentGuarantor.id,
      attachable_type: 'Guarantor',
    };
    return server.getUploadAttachments$id
      .send(query, {
        params: {
          id: docId,
        },
      })
      .pipe(({ data }) => {
        pages.passport_registration[0] = {
          url: '',
          filename,
          success: true,
        };
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          pages.passport_registration[0].url = URL.createObjectURL(data);
        }
        loading.value = false;
      }, getPassportRegistrationAsyncError)
      .exec();
  };
  const getPassportRegistrationAsyncError = (error) => {
    loading.value = false;
    errors.value = error.data?.messages;
  };
  const getPassportSelfieAsync = (filename, docId) => {
    const query = {
      kind: 'passport_selfie',
      attachable_id: store.currentGuarantor.id,
      attachable_type: 'Guarantor',
    };
    return server.getUploadAttachments$id
      .send(query, {
        params: {
          id: docId,
        },
      })
      .pipe(({ data }) => {
        pages.passport_selfie[0] = {
          url: '',
          filename,
          success: true,
        };
        let URL = window.URL || window.webkitURL;
        if (URL && URL.createObjectURL) {
          pages.passport_selfie[0].url = URL.createObjectURL(data);
        }
        loading.value = false;
      }, getPassportSelfieAsyncError)
      .exec();
  };
  const getPassportSelfieAsyncError = (error) => {
    loading.value = false;
    errors.value = error.data?.messages;
  };
  return {
    pages,
    loading,
    errors,
    postCommandsAsync,
    getUploadedPassportsListAsync,
  };
}
