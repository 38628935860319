<template>
  <div>
    <BankStatement
      v-if="!store.features.everypay"
      @show-modal="emitModalType"
      @emit-file-info="emitFileInfo"
    />
    <BankStatementEverypay
      v-if="store.features.everypay"
      @show-modal="emitModalType"
      @emit-file-info="emitFileInfo"
    />
    <ModalHint
      v-if="showModal"
      :modal-type="modalType"
      :file-name="fileName"
      :error-text="errorText"
      :suggestion-text="suggestionText"
      @close-modal="showModal = false"
    />
  </div>
</template>

<script setup>
import { ref } from 'vue';

import BankStatement from '@/components/auth/statement/modules/BankStatement.vue';
import BankStatementEverypay from '@/components/auth/statement/modules/BankStatementEverypay.vue';
import ModalHint from '@/components/auth/statement/modules/ModalHint.vue';

import { useStateStore } from '@/stores/stateStore';

const showModal = ref(false);
const modalType = ref('isSuggestion');
const fileName = ref('');
const errorText = ref('');
const suggestionText = ref('');

const store = useStateStore();

const emitModalType = (type) => {
  modalType.value = type;
  showModal.value = true;
};
const emitFileInfo = (name, error, suggestion) => {
  fileName.value = name;
  errorText.value = error;
  suggestionText.value = suggestion;
};
</script>
