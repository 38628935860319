<template>
  <div class="container pt-7">
    <div class="form w-100 m-auto">
      <h1 class="h3 fw-bold text-center">введите email</h1>
      <h6 class="fw-normal text-center lh-base mb-6">
        понадобится для
        <p>восстановления пароля</p>
      </h6>
      <div class="form-row">
        <input
          id="email"
          v-model="v$.rawEmail.$model"
          :class="{
            'is-invalid': v$.rawEmail.$invalid && v$.rawEmail.$dirty,
          }"
          type="email"
          placeholder="электронная почта"
          @input="postDadataEmailAsync()"
          @focus="emit('clear-errors')"
          @keyup.enter="emit('send-email-async', v$.rawEmail.$model)"
        />
        <div
          v-if="v$.rawEmail.$invalid && v$.rawEmail.$dirty"
          class="error text-danger mt-2"
        >
          <span>Введите корректный еmail</span>
        </div>
        <SuggestionList
          v-model="suggestionsArr"
          class="mt-2"
          width="340px"
          :keys-in-arr="['email']"
          @choose-suggestion="chooseSuggestion"
        />
      </div>
      <div v-if="errorsArrRef" class="fw-normal text-danger">
        <div>{{ errorsArrRef[0] }}</div>
      </div>
      <div class="form-row mb-4 mt-2 mt-sm-5">
        <PotokButton
          v-if="!props.loading"
          :disabled="v$.rawEmail.$invalid"
          text="сохранить"
          class="w-100"
          size="large"
          @click="emit('send-email-async', v$.rawEmail.$model)"
        />
        <LoadingBtn v-if="props.loading" />
      </div>
      <div class="form-row text-center">
        <span
          >после завершения регистрации, пожалуйста, проверьте почту. Мы
          отправим письмо для её подтверждения</span
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import useVuelidate from '@vuelidate/core';
import SuggestionList from '@/components/_generic/ui/widgets/SuggestionList.vue';
import { required, email } from '@vuelidate/validators';
import { ref, reactive, onMounted, watch, toRef } from 'vue';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';
import server from '@/server/index';

const { SPA_LK_Registration_show_screen_need_email } = TRACKER_EVENTS;

const emit = defineEmits(['send-email-async', 'clear-errors']);
const props = defineProps({
  errorsArr: {
    type: Array,
    default() {
      return [];
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
});

const suggestionsArr = ref([]);
const errorsArrRef = toRef(props, 'errorsArr');
const rawEmailRef = reactive({
  rawEmail: '',
});

const validations = {
  rawEmail: {
    required,
    email,
  },
};

const v$ = useVuelidate(validations, rawEmailRef);

onMounted(() => {
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Registration_show_screen_need_email,
  );
  document.body.addEventListener('click', function () {
    suggestionsArr.value = [];
  });
});

watch(
  () => rawEmailRef.rawEmail,
  function () {
    rawEmailRef.rawEmail.match(/\.[a-z]{2,4}/)
      ? (suggestionsArr.value = [])
      : null;
  },
);
const postDadataEmailAsync = () => {
  const query = {
    query: v$.value.rawEmail.$model,
  };
  return server.postDadataEmail
    .send(query)
    .pipe(onPostDadataEmailAsyncSuccess, onPostDadataEmailAsyncError)
    .exec();
};
const onPostDadataEmailAsyncSuccess = ({ data }) => {
  data.suggestions.forEach(function (array_item, index) {
    if (array_item.data.domain !== null) {
      !rawEmailRef.rawEmail.match(/\.[a-z]{2,4}/)
        ? (suggestionsArr.value[index] = {
            email:
              rawEmailRef.rawEmail.split('@')[0] + '@' + array_item.data.domain,
          })
        : null;
    }
  });
};
const onPostDadataEmailAsyncError = () => {};
const chooseSuggestion = (suggestion) => {
  rawEmailRef.rawEmail = suggestion.email;
};
</script>

<style lang="scss" scoped>
.form {
  max-width: 340px;
  &-row {
    position: relative;
    input {
      @include input;
    }
    .is-invalid {
      border: 1px solid rgba($red, 0.2);
    }
  }
}
</style>
