<template>
  <div class="steps-wrapper d-flex text-center align-items-center">
    <div class="step step-done">1. заявка создана</div>
    <div class="step" :class="order.manual_scoring_state ? 'step-done' : ''">
      2. проверка документов
    </div>
    <div
      class="step"
      :class="
        isApprovedByCreditCommittee || isScoringStateAutoFlow ? 'step-done' : ''
      "
    >
      3. выдача
    </div>
  </div>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
  isScoringStateAutoFlow: {
    type: Boolean,
    required: true,
    default: false,
  },
  isApprovedByCreditCommittee: {
    type: Boolean,
    required: true,
    default: false,
  },
});
const { isApprovedByCreditCommittee, isScoringStateAutoFlow, order } =
  toRefs(props);
</script>

<style lang="scss" scoped>
.steps-wrapper {
  width: 100%;
  .step {
    width: 100%;
    padding: 10px 0px;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    border-bottom: 1px solid $input;
    color: $grey;
    &-done {
      color: $primary;
      border-bottom: 1px solid $primary;
    }
  }
}
</style>
