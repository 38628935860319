<template>
  <ModalFull
    title="загрузите фотографии паспорта"
    @close-modal="onClickCloseModal"
  >
    <template #title>
      загрузите фотографии паспорта <br />
      {{ userName }}
    </template>
    <template #content>
      <UploadPassport
        v-model:isPassportUploaded="isPassportUploaded"
        v-model:isPassportPartiallyUploaded="isPassportPartiallyUploaded"
        v-model:loading="loading"
        v-model:errors="errors"
        :is-upload-disabled="props.isDocsSigned"
        :attachable-id="props.attachableId"
        :attachable-type="props.attachableType"
      />
      <PotokButton
        v-if="!loading"
        class="mt-6 w-100"
        size="large"
        text="отправить"
        @click="onClickCloseModal"
      ></PotokButton>
      <LoadingBtn v-if="loading" class="mt-6" />
      <ErrorItem
        v-if="errors.length !== 0"
        v-model="errors"
        class="error text-center mx-auto mt-4"
      />
    </template>
  </ModalFull>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import UploadPassport from './UploadPassport.vue';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { computed, ref } from 'vue';

const errors = ref([]);
const loading = ref(false);

const props = defineProps({
  userName: {
    type: String,
  },
  isPassportUploaded: {
    type: Boolean,
    default: false,
  },
  isPassportPartiallyUploaded: {
    type: Boolean,
    default: false,
  },
  isDocsSigned: {
    type: Boolean,
    default: false,
  },
  attachableId: {
    type: [String, Number],
  },
  attachableType: {
    type: String,
  },
});

const emit = defineEmits([
  'close-modal',
  'update:isPassportUploaded',
  'update:isPassportPartiallyUploaded',
]);

const isPassportUploaded = computed({
  get() {
    return props.isPassportUploaded;
  },
  set(value) {
    emit('update:isPassportUploaded', value);
  },
});

const isPassportPartiallyUploaded = computed({
  get() {
    return props.isPassportPartiallyUploaded;
  },
  set(value) {
    emit('update:isPassportPartiallyUploaded', value);
  },
});

const onClickCloseModal = () => {
  emit('close-modal');
};
</script>

<style lang="scss" scoped>
.error {
  max-width: 350px;
}
</style>
