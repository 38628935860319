import { openInNewTab } from '@/server/index';
import tracker from '@/tracker';

import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Loan_offer_click } = TRACKER_EVENTS;

export function documentsUtils() {
  const onClickOpenUnsignedInvestOffer = (_order) => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Loan_offer_click);
    return openInNewTab(
      '/documents/unsigned/investment_offer.pdf?order_id=' + _order.id,
    );
  };
  const onClickOpenSignedInvestOffer = (_order) => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Loan_offer_click);
    return openInNewTab(
      '/documents/signed/investment_offer.pdf?loan_id=' + _order.loan.id,
    );
  };
  const onClickOpenUnsignedCreditLineAgreement = (_order) => {
    if (!_order.credit_line) {
      return;
    }
    return openInNewTab(
      '/spa/business/documents/unsigned/credit_line_agreement.pdf?credit_line_id=' +
        _order.credit_line.id,
    );
  };
  const onClickOpenSignedCreditLineAgreement = (_order) => {
    if (!_order.credit_line) {
      return;
    }
    return openInNewTab(
      '/spa/business/documents/signed/credit_line_agreement.pdf?credit_line_id=' +
        _order.credit_line.id,
    );
  };

  return {
    onClickOpenUnsignedInvestOffer,
    onClickOpenSignedInvestOffer,
    onClickOpenUnsignedCreditLineAgreement,
    onClickOpenSignedCreditLineAgreement,
  };
}
