<template>
  <div class="hit-desktop-payment">
    <div class="hit-desktop-payment-row">
      <div class="hit-desktop-payment-name hit-desktop-payment-title">
        заем № {{ props.item.payments[0].order_id }}
      </div>
      <div class="hit-desktop-payment-value">
        <div class="only_mobile">{{ getStatusTitlePayment(props.item) }}</div>
      </div>
    </div>
    <div class="divider"></div>
    <div class="hit-desktop-payment-row">
      <div class="hit-desktop-payment-name">основной долг</div>
      <div class="hit-desktop-payment-value">
        {{ formatCurrency(props.item.payments[0].main, 0) }} ₽
      </div>
    </div>
    <div class="hit-desktop-payment-row">
      <div class="hit-desktop-payment-name">проценты</div>
      <div class="hit-desktop-payment-value">
        <span>{{ formatCurrency(props.item.payments[0].interest, 0) }} ₽</span>
      </div>
    </div>
    <div
      v-if="props.item.payments[0].monthly_commission"
      class="hit-desktop-payment-row"
    >
      <div class="hit-desktop-payment-name">ежемесячная комиссия</div>
      <div class="hit-desktop-payment-value">
        <span
          >{{
            formatCurrency(props.item.payments[0].monthly_commission, 0)
          }}
          ₽</span
        >
      </div>
    </div>
    <div v-if="props.isTypePayouts" class="hit-desktop-payment-row">
      <div class="hit-desktop-payment-name">НДФЛ</div>
      <div class="hit-desktop-payment-value">
        {{ formatCurrency(props.item.payments[0].ndfl, 0) }}
        ₽
      </div>
    </div>
    <div v-if="props.item.payments[0].fine" class="hit-desktop-payment-row">
      <div class="hit-desktop-payment-name">пеня</div>
      <div class="hit-desktop-payment-value">
        {{ formatCurrency(props.item.payments[0].fine, 0) }}
        ₽
      </div>
    </div>
    <div v-if="props.item.payments[0].penalty" class="hit-desktop-payment-row">
      <div class="hit-desktop-payment-name">штраф платформы</div>
      <div class="hit-desktop-payment-value">
        {{ formatCurrency(props.item.payments[0].penalty, 0) }}
        ₽
      </div>
    </div>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils';
import { paymentUtils } from '../paymentUtils.js';

const { getStatusTitlePayment } = paymentUtils();

const props = defineProps({
  item: {
    type: Object,
    default() {
      return {};
    },
  },
  isTypePayouts: {
    type: Boolean,
    default: false,
  },
});
</script>
<style></style>
