<template>
  <OnClickOutside @trigger="isShowSelectList = false">
    <div
      class="select text-lowercase"
      :class="{
        'select-form-disabled': props.disabled,
      }"
      @click="onClickSelect"
    >
      <div class="select-form">
        <div class="select-selected-content potok-text-body-2">
          {{ props.title }}
        </div>
        <div
          :class="{
            'select-arrow-down': !isShowSelectList,
            'select-arrow-up': isShowSelectList,
          }"
        ></div>
      </div>
      <div v-if="isShowSelectList" class="select-list">
        <div
          v-for="(item, index) in props.items"
          :key="index"
          class="select-list-item"
          @click="onClickSelectedValue(item.value)"
        >
          <div class="item potok-text-body-1" :title="item.title">
            {{ item.title }}
          </div>
        </div>
      </div>
    </div>
  </OnClickOutside>
</template>
<script setup>
import { ref } from 'vue';
import { OnClickOutside } from '@vueuse/components';

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['select']);
const isShowSelectList = ref(false);

const onClickSelect = () => {
  if (props.disabled || props.items.length === 0) {
    return;
  }
  isShowSelectList.value = !isShowSelectList.value;
};

const onClickSelectedValue = (localValue) => {
  if (props.disabled) {
    return;
  }
  emit('select', localValue);
};
</script>
<style lang="scss" scoped>
.select {
  width: 100%;
  position: relative;
}
.select-form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #dce0e3;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  height: 44px;
  padding: 6px 16px;
  font-size: 14px;
  line-height: 22px;
  color: $dipedive;
}
.select-selected-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-top: -1px;
}
.select-form-disabled {
  opacity: 0.5;
}
.select-arrow-down {
  background-image: url(@/assets/icons/arrows/caret_arrow.svg);
  background-repeat: no-repeat;
  width: 8px;
  height: 8px;
  margin-left: 6px;
}
.select-arrow-up {
  background-image: url(@/assets/icons/arrows/caret_arrow.svg);
  background-repeat: no-repeat;
  width: 8px;
  height: 8px;
  margin-left: 6px;
  transform: rotate(180deg);
}
.select-list {
  position: absolute;
  top: 46px;
  min-height: 30px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  border-radius: 12px;
  z-index: 100;
  padding: 6px;
}
.select-list-item {
  padding: 8px 6px;
  cursor: pointer;
  &:hover {
    background-color: $background;
    border-radius: 8px;
  }
}
</style>
