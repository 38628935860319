<template>
  <HeaderPage :title="statusHeader.title" :icon="statusHeader.icon" />
  <BaseContent @run-action="runAction">
    <VKLManualCheck
      v-if="
        store.currentOrder.client_state === constants.CLIENT_STATES.manual_check
      "
      @run-action="runAction"
    />

    <VKLReadyMode
      v-if="store.currentOrder.client_state === constants.CLIENT_STATES.ready"
      @run-action="runAction"
    />
    <VKLUserVerifying
      v-if="
        store.currentOrder.client_state ===
        constants.CLIENT_STATES.user_verifying
      "
    />
  </BaseContent>
</template>
<script setup>
import { computed } from 'vue';
import constants from '@/constants';
import { useStateStore } from '@/stores/stateStore';
import VKLManualCheck from './modules/VKLManualCheck.vue';
import VKLReadyMode from './modules/VKLReadyMode.vue';
import VKLUserVerifying from './modules/VKLUserVerifying.vue';
import BaseContent from '@/components/auth/loan-card/vkl-flow/content/modules/BaseContent.vue';
import HeaderPage from '@/components/auth/loan-card/vkl-flow/content/modules/HeaderPage.vue';

const emit = defineEmits(['run-action']);
const store = useStateStore();

const statusHeader = computed(() => {
  if (
    store.currentOrder.client_state === constants.CLIENT_STATES.manual_check
  ) {
    return {
      title: 'нам нужно уточнить информацию',
      icon: 'icon-wait',
    };
  }
  if (
    store.currentOrder.client_state === constants.CLIENT_STATES.user_verifying
  ) {
    return {
      title: 'транш готовится к сбору',
      icon: 'icon-wait',
    };
  }
  if (store.currentOrder.client_state === constants.CLIENT_STATES.ready) {
    return {
      title: 'транш готов к сбору',
      icon: 'icon-done',
    };
  }

  if (store.currentOrder.client_state === constants.CLIENT_STATES.investing) {
    return {
      title: 'транш собирается',
      icon: 'icon-done',
    };
  }

  if (
    store.currentOrder.client_state === constants.CLIENT_STATES.auto_scoring
  ) {
    return {
      title: 'заявка в процессе оформления',
      icon: 'icon-wait',
    };
  }

  return {
    title: 'неопределенное состояние заявки',
    icon: 'icon-wait',
  };
});

const runAction = (data) => {
  emit('run-action', data);
};
</script>
<style lang="scss" scoped>
:deep(.sign-transh) {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
}
:deep(.sign-transh-right) {
  @media (max-width: $size_767) {
    margin-top: 20px;
  }
}
</style>
