<template>
  <div class="switcher">
    <div class="items">
      <div
        v-for="(item, index) in props.items"
        :key="index"
        :class="{
          active: model === item.value,
        }"
        class="item potok-text-body-1"
        @click="onClickItem(item.value)"
      >
        {{ item.title }}
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';

const props = defineProps({
  items: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
  modelValue: {
    type: String,
    required: true,
    default: '',
  },
});
const emit = defineEmits(['update:modelValue']);

const model = computed({
  get() {
    return props.modelValue;
  },
  set(localValue) {
    emit('update:modelValue', localValue);
  },
});

const onClickItem = (localValue) => {
  model.value = localValue;
};
</script>
<style lang="scss" scoped>
.items {
  display: flex;
  @media (max-width: $size_767) {
    justify-content: space-between;
  }
  .item {
    padding: 5px 20px 6px 20px;
    height: 34px;
    color: $grey;
    cursor: pointer;
    border: 1px solid #dedee1;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    text-transform: lowercase;
    min-width: 120px;
    text-align: center;
    @media (max-width: $size_767) {
      width: 100%;
    }
    &:first-child {
      border-radius: 8px 0 0 8px;
      border-right: none;
    }
    &:last-child {
      border-radius: 0 8px 8px 0;
      border-left: none;
    }
    &:first-child.active {
      margin-right: -1px;
      position: relative;
    }
    &:last-child.active {
      margin-left: -1px;
    }
    &:hover {
      color: $breakwater;
    }
  }
  .active {
    color: $dipedive;
    border: 1px solid $dipedive;
    border-right: 1px solid $dipedive !important;
    border-left: 1px solid $dipedive !important;
  }
}
</style>
