<template>
  <div class="d-block flex-wrap d-md-flex">
    <template v-for="input in passportInputs" :key="input.id">
      <div class="input-container" :class="detectSize(input.type)">
        <input
          v-if="
            input.type !== constants.PASSPORT_FIELD_TYPES.registration_address
          "
          v-model="store.currentGuarantor.passport[input.type]"
          v-maska
          type="text"
          class="input"
          :class="isInvalid(input.type)"
          :data-maska="detectMask(input.type)"
          :disabled="disabledChecker(input.type)"
          @blur="blurHandler(input.type)"
          @input="debouncedFn(input.type)"
        />
        <InputAddress
          v-if="
            input.type === constants.PASSPORT_FIELD_TYPES.registration_address
          "
          v-model="store.currentGuarantor.passport[input.type]"
          :disabled="props.isEditDisabled"
          :class="isInvalid(input.type)"
        />
        <span class="placeholder">{{ input.title }}</span>
        <span v-if="isRequired(input.type)" class="text-danger"
          >обязательное поле</span
        >
        <span v-if="isError(input.type)" class="text-danger"
          >ошибка в заполнении</span
        >
        <span
          v-if="isHighlighted && !v$[input.type].$invalid"
          class="text-danger"
          >проверьте данные</span
        >
      </div>
    </template>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import useVuelidate from '@vuelidate/core';
import { useDebounceFn } from '@vueuse/core';
import { vMaska } from 'maska';
import masks from '@/libs/js-text-masks';
import { required, minLength } from '@vuelidate/validators';
import server from '@/server/index';
import constants from '@/constants';
import InputAddress from '@/components/_generic/ui/widgets/InputAddress.vue';

const store = useStateStore();
const props = defineProps({
  isDataCorrect: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isEditDisabled: {
    type: Boolean,
    default: false,
  },
  isHighlighted: {
    type: Boolean,
    default: false,
  },
  errors: {
    type: Array,
    default() {
      return [];
    },
  },
});
const emit = defineEmits([
  'update:loading',
  'update:errors',
  'update:isDataCorrect',
]);

const suggestionsRegArr = ref([]);

const passportInputs = ref([
  { id: 1, title: 'инн', type: constants.PASSPORT_FIELD_TYPES.inn },
  {
    id: 2,
    title: 'серия паспорта',
    type: constants.PASSPORT_FIELD_TYPES.series,
  },
  {
    id: 3,
    title: 'номер паспорта',
    type: constants.PASSPORT_FIELD_TYPES.number,
  },
  {
    id: 4,
    title: 'код подразделения',
    type: constants.PASSPORT_FIELD_TYPES.department_code,
  },
  {
    id: 5,
    title: 'кем выдан',
    type: constants.PASSPORT_FIELD_TYPES.issued_by,
  },
  {
    id: 6,
    title: 'дата выдачи',
    type: constants.PASSPORT_FIELD_TYPES.issued_date,
  },
  {
    id: 7,
    title: 'дата рождения',
    type: constants.PASSPORT_FIELD_TYPES.birthday,
  },
  {
    id: 8,
    title: 'гражданство',
    type: constants.PASSPORT_FIELD_TYPES.citizenship,
  },
  {
    id: 9,
    title: 'место рождения',
    type: constants.PASSPORT_FIELD_TYPES.place_of_birth,
  },
  {
    id: 10,
    title: 'адрес регистрации',
    type: constants.PASSPORT_FIELD_TYPES.registration_address,
  },
]);

const containsNumbers = (value) => (/\d/.test(value) ? true : false);
const isNum = /^\d+$/.test(store.currentGuarantor.passport.inn);
const exactLength = (value) =>
  value.length === 10 || value.length === 12 ? true : false;
const validations = computed(() => ({
  inn: {
    required,
    isNum,
    exactLength,
  },
  series: {
    required,
    minLength: minLength(4),
  },
  number: {
    required,
    minLength: minLength(6),
  },
  department_code: {
    required,
    minLength: minLength(7),
  },
  issued_date: {
    required,
  },
  issued_by: {
    required,
  },
  birthday: {
    required,
  },
  citizenship: {
    required,
  },
  place_of_birth: {
    required,
  },
  registration_address: {
    required,
    containsNumbers,
  },
}));

const v$ = useVuelidate(validations, store.currentGuarantor.passport);

const issuedByDisabled = ref(false);

const disabledChecker = (type) => {
  return type === 'issued_by'
    ? props.isEditDisabled && issuedByDisabled.value
    : props.isEditDisabled;
};

const blurHandler = (type) => {
  v$.value[type].$touch();
  if (
    type === 'department_code' &&
    !store.currentGuarantor.passport.issued_by
  ) {
    issuedByDisabled.value = true;
    const code = store.currentGuarantor.passport[type];
    loadIssuedByInfo(code);
  }
};

const loadIssuedByInfo = (code) => {
  const query = {
    query: code,
  };
  return server.postDadataIssuedBy
    .send(query)
    .pipe(onloadIssuedByInfo, () => {
      issuedByDisabled.value = false;
    })
    .exec();
};

const onloadIssuedByInfo = ({ data }) => {
  const res = data?.suggestions[0];
  store.currentGuarantor.passport.issued_by =
    store.currentGuarantor.passport.department_code === res?.data.code
      ? res.value
      : '';
  issuedByDisabled.value = false;
};

watch(
  () => v$.value.$invalid,
  () => {
    emit('update:isDataCorrect', !v$.value.$invalid);
  },
);

const initial = () => {
  v$.value.$invalid
    ? emit('update:isDataCorrect', false)
    : emit('update:isDataCorrect', true);
};
initial();

const detectSize = (type) => {
  return [
    constants.PASSPORT_FIELD_TYPES.inn,
    constants.PASSPORT_FIELD_TYPES.place_of_birth,
    constants.PASSPORT_FIELD_TYPES.registration_address,
  ].includes(type)
    ? 'w-100'
    : [
          constants.PASSPORT_FIELD_TYPES.series,
          constants.PASSPORT_FIELD_TYPES.department_code,
          constants.PASSPORT_FIELD_TYPES.birthday,
        ].includes(type)
      ? 'short short-margin'
      : 'short';
};
const isRequired = (type) => {
  return !!(
    v$.value[type]?.$silentErrors?.[0]?.$validator === 'required' &&
    v$.value[type].$dirty
  );
};
const isError = (type) => {
  if (
    [
      constants.PASSPORT_FIELD_TYPES.series,
      constants.PASSPORT_FIELD_TYPES.number,
      constants.PASSPORT_FIELD_TYPES.department_code,
    ].includes(type)
  ) {
    return !!(
      v$.value[type]?.$silentErrors?.[0]?.$validator === 'minLength' &&
      v$.value[type].$dirty
    );
  }
  if ([constants.PASSPORT_FIELD_TYPES.inn].includes(type)) {
    return !!(
      (v$.value[type]?.$silentErrors?.[0]?.$validator === 'isNum' ||
        v$.value[type]?.$silentErrors?.[0]?.$validator === 'exactLength') &&
      v$.value[type].$dirty
    );
  }
  if ([constants.PASSPORT_FIELD_TYPES.registration_address].includes(type)) {
    return !!(
      v$.value[type]?.$silentErrors?.[0]?.$validator === 'containsNumbers' &&
      v$.value[type].$dirty
    );
  }
};
const isInvalid = (type) => {
  return (v$.value[type].$invalid && v$.value[type].$dirty) ||
    props.isHighlighted
    ? 'is-invalid'
    : '';
};
const debouncedFn = useDebounceFn((type) => {
  if ([constants.PASSPORT_FIELD_TYPES.registration_address].includes(type)) {
    postDadataAddressAsync(type);
  }
}, 500);
const detectMask = (type) => {
  if (
    [
      constants.PASSPORT_FIELD_TYPES.series,
      constants.PASSPORT_FIELD_TYPES.number,
      constants.PASSPORT_FIELD_TYPES.department_code,
      constants.PASSPORT_FIELD_TYPES.issued_date,
      constants.PASSPORT_FIELD_TYPES.birthday,
    ].includes(type)
  ) {
    return masks.passport[type]();
  }
  if ([constants.PASSPORT_FIELD_TYPES.inn].includes(type)) {
    return masks.inn();
  }
};
const postDadataAddressAsync = (type) => {
  v$.value[type].$touch();
  emit('update:loading', true);
  let query;
  query = {
    query: store.currentGuarantor.passport.registration_address,
  };
  return server.postDadataAddress
    .send(query)
    .pipe(onPostDadataRegAddressAsyncSuccess, onPostDadataAddressAsyncError)
    .exec();
};
const onPostDadataRegAddressAsyncSuccess = ({ data }) => {
  emit('update:loading', false);
  data.suggestions.forEach(function (array_item, index) {
    suggestionsRegArr.value[index] = { address: array_item.value };
  });
};
const onPostDadataAddressAsyncError = (error) => {
  emit('update:errors', error.data?.messages);
  emit('update:loading', false);
};
</script>

<style lang="scss" scoped>
.short {
  width: 220px;
  &-margin {
    margin-right: 10px;
  }
  @media (max-width: $size_767) {
    width: 100%;
  }
}
.input-container {
  position: relative;
  margin-bottom: 15px;

  input {
    @include input;
    background-color: transparent;
    border-color: $input;
    height: 44px;
  }
  .is-invalid {
    border: 1px solid rgba($red, 0.2);
    &:focus-visible {
      border: 1px solid rgba($red, 0.2);
    }
  }
  .placeholder {
    position: absolute;
    left: 10px;
    top: -5px;
    font-size: 8px;
    line-height: 12px;
    letter-spacing: 0.1em;
    text-transform: uppercase;
    color: $grey;
    background-color: $white;
    padding: 0 5px 0 5px;
  }
  .icon {
    position: absolute;
    right: 15px;
    top: 12px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: center;
    &-not_done {
      background-image: url(@/assets/icons/rounded/icon_not_done.svg);
      cursor: pointer;
      &:hover ~ .popover__content {
        visibility: visible;
      }
    }
    &-correct {
      background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
      filter: $tiffany;
    }
  }
  .input:disabled {
    opacity: 0.6;
  }
}
</style>
