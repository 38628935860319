import { computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import constants from '@/constants';

export function loanBlurUtils() {
  const store = useStateStore();

  const isBlurAutoScoring = computed(() => {
    return (
      store.currentOrder.client_state === constants.CLIENT_STATES.auto_scoring
    );
  });

  const isBlurContinueCreateLoan = computed(() => {
    if (
      store.currentOrder?.client_state === constants.CLIENT_STATES.investing ||
      store.currentOrder?.client_state ===
        constants.CLIENT_STATES.manual_check ||
      store.currentOrder?.loan?.state === 'investing' ||
      store.currentOrder?.loan?.state === 'invested'
    ) {
      return true;
    }
    return false;
  });

  const isBlur = computed(() => {
    return isBlurAutoScoring.value || isBlurContinueCreateLoan.value;
  });

  return {
    isBlurAutoScoring,
    isBlurContinueCreateLoan,
    isBlur,
  };
}
