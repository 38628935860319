<template>
  <div class="container-fluid">
    <div class="container">
      <div>
        <div class="d-flex align-items-center">
          <span class="icon-alert"></span>
          <div class="title fw-bold ms-3">
            пока мы не готовы выдать вам транш
          </div>
        </div>

        <div class="loan-content-wrapper">
          <div class="loan-content-wrapper-left">
            <div
              class="notices-block potok-text-body-1 d-flex align-items-center"
            >
              <i class="icon-clock me-3" />
              <span v-if="!store.currentCompany.allow_next_loan_after_date"
                >рекомендуем обновить данные по компании и попробовать снова
                через некоторое время</span
              >
              <span v-if="store.currentCompany.allow_next_loan_after_date">
                рекомендуем обновить данные по компании, вы сможете попробовать
                отправить данные по компании на проверку после:
                {{
                  formatDate(store.currentCompany.allow_next_loan_after_date)
                }}
              </span>
            </div>

            <div class="white-block mt-6">
              <div>{{ store.currentOrder.rejection_reason }}</div>
              <p class="mt-4 mb-4 fw-bold">
                есть вопросы, напишите нам
                <a class="btn-text-tiffany" href="mailto:ok@potok.team"
                  >ok@potok.team</a
                >
              </p>
            </div>

            <div class="loan-actions-block">
              <PotokButton
                text="на главную"
                size="large"
                @click="onClickGoDashboard"
              />
              <PotokButton
                text="на главную"
                size="large"
                theme="secondary"
                @click="
                  store.currentManager.name
                    ? (isManagerModalOpen = true)
                    : onClickOpenChat()
                "
              >
                <template #default
                  ><span v-if="store.currentManager.name">
                    связаться с персональным менеджером </span
                  ><span v-else>написать менеджеру в whatsapp</span>
                </template>
              </PotokButton>
            </div>
          </div>
          <div class="loan-content-wrapper-right white-block">
            <TermsTable title="ваши условия"
              ><template #documents>
                <AdditionalDocs :order="store.currentOrder" /> </template
            ></TermsTable>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { useStateStore } from '@/stores/stateStore';
import TermsTable from '@/components/_generic/auth/TermsTable.vue';
import AdditionalDocs from '@/components/auth/loan-card/modules/AdditionalDocs.vue';
import { loanCardVKLUtils } from '@/components/auth/loan-card/vkl-flow/utils/loanCardVKLUtils.js';
import { managerModalUtils } from '@/components/auth/loan-card/modules/manager-modal/utils/index.js';
import { formatDate } from '@/utils';

const { onClickGoDashboard } = loanCardVKLUtils();
const { onClickOpenChat } = managerModalUtils();
const store = useStateStore();
</script>
