export const BKI_EVENTS = {
  SPA_LK_BKI_passport_success: 'SPA_LK_BKI_passport_success',
  SPA_LK_BKI_signature_start: 'SPA_LK_BKI_signature_start',
  SPA_LK_BKI_open: 'SPA_LK_BKI_open',
  SPA_LK_BKI_signature_open: 'SPA_LK_BKI_signature_open',
  SPA_LK_BKI_success: 'SPA_LK_BKI_success',
  SPA_LK_BKI_passport_signature_open: 'SPA_LK_BKI_passport_signature_open',
  SPA_LK_BKI_Parsing_unwanted: 'SPA_LK_BKI_Parsing_unwanted',
  SPA_LK_BKI_Parsing_start: 'SPA_LK_BKI_Parsing_start',
  SPA_LK_BKI_Parsing_success: 'SPA_LK_BKI_Parsing_success',
  SPA_LK_BKI_cofaunder_open: 'SPA_LK_BKI_cofaunder_open',
  SPA_LK_BKI_nummber_add: 'SPA_LK_BKI_nummber_add',
  SPA_LK_BKI_email_add: 'SPA_LK_BKI_email_add',
  SPA_LK_BKI_passport_cofaunder_open: 'SPA_LK_BKI_passport_cofaunder_open',
};
