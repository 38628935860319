<!-- eslint-disable vue/no-v-html -->
<template>
  <OnClickOutside @trigger="isShowSelectList = false">
    <div
      class="select text-lowercase"
      :class="{
        'select-form-disabled': props.disabled,
      }"
      @click="onClickSelect"
    >
      <div
        :class="{
          'select-form': props.type === 'default',
          'select-form-alt justify-content-between': props.type === 'alt',
        }"
      >
        <div
          class="select-selected-content"
          :class="{
            'active-selected': !!selectedValueTitle,
          }"
        >
          <div v-html="selectedValueTitle || props.defaultTitle"></div>
        </div>
        <div
          :class="{
            'icon-select-arrow-down': !isShowSelectList,
            'icon-select-arrow-up': isShowSelectList,
          }"
        ></div>
      </div>
      <div v-if="isShowSelectList && !props.withNode" class="select-list">
        <div
          v-for="(item, index) in props.items"
          :key="index"
          class="select-list-item"
          :class="{
            'select-list-item-active': item.value === props.modelValue,
          }"
          @click="onClickSelectedValue(item.value)"
        >
          <div v-if="!slots.item" class="item" :title="item.title">
            <div v-html="item.title"></div>
          </div>
          <slot v-if="slots.item" name="item" :item="item"></slot>
        </div>
      </div>
      <div v-if="isShowSelectList && props.withNode" class="select-list">
        <div v-for="(item, index) in props.items" :key="index">
          <div class="node-item">
            {{ item.node_name }}
          </div>
          <div
            v-for="(el, idx) in item.child_categories"
            :key="idx"
            class="select-list-item"
            :class="{
              'select-list-item-active': el.id === props.modelValue,
            }"
            @click="onClickSelectedValue(el.id)"
          >
            <div class="item p-2">
              {{ el.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </OnClickOutside>
</template>
<script setup>
import { ref, computed, useSlots } from 'vue';
import { OnClickOutside } from '@vueuse/components';

const slots = useSlots();

const props = defineProps({
  items: {
    type: Array,
    required: true,
  },
  defaultTitle: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: 'default',
  },
  withNode: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: [Number, String, null],
    required: true,
  },
});
const emit = defineEmits(['update:model-value']);

const isShowSelectList = ref(false);
const selectedValue = computed({
  get() {
    return props.modelValue;
  },
  set(localValue) {
    emit('update:model-value', localValue);
  },
});

const selectedValueTitle = computed(() => {
  const localValue = props.items.find((i) => i.value === selectedValue.value);
  if (!localValue) {
    return;
  }
  return localValue.title;
});

const onClickSelect = () => {
  if (props.disabled || props.items.length === 0) {
    return;
  }
  isShowSelectList.value = !isShowSelectList.value;
};

const onClickSelectedValue = (localValue) => {
  if (props.disabled) {
    return;
  }
  emit('update:model-value', localValue);
};
</script>
<style lang="scss" scoped>
.select {
  width: 100%;
  position: relative;
}
.select-form {
  display: flex;
  align-items: center;
  border: 1px solid #dce0e3;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 22px;
  color: $dipedive;
}
.select-form-alt {
  display: flex;
  align-items: center;
  border-radius: 8px;
  background-color: $background;
  position: relative;
  cursor: pointer;
  height: 60px;
  padding: 18px 25px 18px 20px;
  color: $secondary;
}
.select-selected-content {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  @media (max-width: $size_767) {
    white-space: initial;
  }
}
.select-form-disabled {
  opacity: 0.5;
}
// .select-arrow-down {
//   background-image: url(@/assets/icons/arrows/caret_arrow.svg);
//   background-repeat: no-repeat;
//   width: 8px;
//   height: 8px;
//   margin-left: 6px;
// }
// .select-arrow-up {
//   background-image: url(@/assets/icons/arrows/caret_arrow.svg);
//   background-repeat: no-repeat;
//   width: 8px;
//   height: 8px;
//   margin-left: 6px;
//   transform: rotate(180deg);
// }
.select-list {
  position: absolute;
  top: 70px;
  min-height: 30px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  border-radius: 12px;
  z-index: 100;
  padding: 6px;
}
.select-list-item {
  padding: 8px 6px;
  cursor: pointer;
  &:hover {
    background-color: $background;
    border-radius: 8px;
  }
}
.node-item {
  color: $dipedive;
  font-weight: 500;
  white-space: pre-wrap;
  padding: 10px;
  cursor: not-allowed;
  text-transform: lowercase;
}
.active-selected {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  color: $dipedive;
}
</style>
