import { REG_EVENTS } from './registration';
import { LOGIN_EVENTS } from './login';
import { ML_EVENTS } from './magic-link';
import { BKI_EVENTS } from './bki';
import { STATEMENT_EVENTS } from './statement';
import { DASHBOARD_EVENTS } from './dashboard';
import { LOAN_EVENTS } from './loan';
import { ORDER_COLLECT_EVENTS } from './order-collect';
import { ORDER_COLLECT_VKL_EVENTS } from './order-collect';
import { SALES_EVENTS } from './sales';
import { NAVBAR_EVENTS } from './navbar';
import { LOAN_TERMS_EVENTS } from './loan-terms';
import { LOAN_TERMS_VKL_EVENTS } from './loan-terms';
import { LEGACY_EVENTS } from './legacy';
import { ANALYTICS_EVENTS } from './analytics';
import { ABOUT_COMPANY_EVENTS } from './about-company';
import { LE_EVENTS } from './loyalty-widget';

export const TRACKER_EVENTS = {
  ...REG_EVENTS,
  ...LOGIN_EVENTS,
  ...ML_EVENTS,
  ...BKI_EVENTS,
  ...STATEMENT_EVENTS,
  ...DASHBOARD_EVENTS,
  ...LOAN_EVENTS,
  ...ORDER_COLLECT_EVENTS,
  ...ORDER_COLLECT_VKL_EVENTS,
  ...SALES_EVENTS,
  ...NAVBAR_EVENTS,
  ...LOAN_TERMS_EVENTS,
  ...LOAN_TERMS_VKL_EVENTS,
  ...LEGACY_EVENTS,
  ...ANALYTICS_EVENTS,
  ...ABOUT_COMPANY_EVENTS,
  ...LE_EVENTS,
  SPA_LK_Onbording_success: 'SPA_LK_Onbording_success',
  SPA_LK_Later: 'SPA_LK_Later',
  SPA_LK_VKL_Conditions_update_limit_click:
    'SPA_LK_VKL_Conditions_update_limit_click',
};
