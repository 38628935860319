<template>
  <div class="form m-auto">
    <h1 class="h3 fw-bold mb-6 text-center">проверьте информацию</h1>
    <div class="inputs-container mb-6">
      <div v-for="(input, index) in inputs" :key="index" class="input-box">
        <div class="input">{{ inputs[index].value || 'нет данных' }}</div>
        <span class="placeholder">{{ input.title }}</span>
      </div>
    </div>
    <PotokButton
      text="да, все верно"
      class="mb-3 w-100"
      size="large"
      @click="emit('changeComponent', SummaryInfo)"
    />
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import SummaryInfo from './SummaryInfo.vue';
import { ref } from 'vue';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const inputs = ref([
  { title: 'название компании', value: store.currentCompanyInfo.short_title },
  {
    title: 'дата регистрации',
    value: store.currentCompanyInfo.registration_date,
  },
  { title: 'инн', value: store.currentCompanyInfo.inn },
  {
    title: store.currentCompanyInfo.kind === 'UL' ? 'огрн' : 'огрнип',
    value: store.currentCompanyInfo.ogrn,
  },
  { title: 'окато', value: store.currentCompanyInfo.okato },
  { title: 'окпо', value: store.currentCompanyInfo.okpo },
  { title: 'оквэд', value: store.currentCompanyInfo.okved },
]);

const emit = defineEmits(['changeComponent']);
</script>

<style lang="scss" scoped>
.form {
  .inputs-container {
    background-color: $background;
    border-radius: 14px;
    padding: 20px 0px;
    .input-box {
      position: relative;
      margin: 0px 10px;
      padding: 0px 10px;
      cursor: pointer;
      &:not(:first-child) {
        border-top: 1px solid rgba($grey, 0.2);
      }
      &:hover:not(:first-child) {
        border-top: 1px solid rgba($grey, 0);
      }
      &:hover {
        background: white;
        border-radius: 14px;
      }
      &:hover + .input-box {
        border-top: 1px solid rgba($grey, 0);
      }
      &:hover .input {
        background: white;
      }
      .input {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 100%;
        height: 78px;
        padding: 30px 40px 20px 0px;
        background-color: $background;
        color: $dipedive;
      }
      .placeholder {
        position: absolute;
        top: 10px;
        font-size: 8px;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: $grey;
      }
    }
  }
}
</style>
