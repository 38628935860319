<template>
  <div>
    <div
      v-if="!store.currentOrder.confirmed_by_sms_at"
      class="notices-block potok-text-body-1 d-flex align-items-center"
    >
      <i class="icon-clock me-3" />
      необходимо подписать документ транша ещё раз
    </div>

    <div
      v-if="
        store.currentOrder.need_to_new_amount_confirm &&
        !store.currentOrder.confirmed_by_sms_at
      "
      class="white-block mt-6 sign-transh"
    >
      <div>
        <div class="potok-text-h2">
          сейчас мы готовы выдать
          {{ formatCurrency(store.currentOrder.amount, 0) }} ₽
        </div>
        <div class="potok-text-body-1 mt-2">
          подпишите документы, чтобы получить деньги на ваш счет
        </div>
      </div>
      <div class="sign-transh-right">
        <PotokButton
          text="подписать"
          size="medium"
          @click="emit('run-action', { action: 'signing_vkl' })"
        />
      </div>
    </div>
    <div
      v-if="
        !store.currentOrder.need_to_new_amount_confirm &&
        !store.currentOrder.confirmed_by_sms_at
      "
      class="white-block mt-6 sign-transh"
    >
      <div>
        <div class="potok-text-h2">мы готовы выдать транш</div>
        <div class="potok-text-body-1 mt-2">
          подпишите документы, после выполнения шагов ниже
        </div>
      </div>
      <div class="sign-transh-right">
        <PotokButton
          :disabled="isNeedAdditionalDocsUpload || isNeedUploadStatementFile"
          text="подписать"
          size="medium"
          @click="emit('run-action', { action: 'signing_vkl' })"
        />
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { useStateStore } from '@/stores/stateStore';
import { formatCurrency } from '@/utils';
import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';

const emit = defineEmits(['run-action']);
const store = useStateStore();

const { isNeedAdditionalDocsUpload, isNeedUploadStatementFile } =
  loanCardShared();
</script>
