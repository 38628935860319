<template>
  <div class="form m-auto">
    <h1 class="h3 fw-bold mb-2 text-center">
      введите адрес фактического ведения бизнеса
    </h1>
    <h6 class="fw-normal mb-6 text-secondary text-center lh-base">
      шаг 2 из 4
    </h6>
    <InputAddress
      v-model="store.currentCompanyInfo.business_location"
      :disabled="store.currentCompanyInfo.business_location_eq_company_address"
      :placeholder="'адрес ведения бизнеса'"
    />
    <div class="text-secondary text-description mb-3">
      пожалуйста укажите фактический адрес ведения бизнеса (включая дом и улицу)
      и тип помещения (тц, офис, магазин, производственный цех)
    </div>
    <Checkbox
      v-if="store.currentCompanyInfo.legal_address"
      v-model:isChecked="
        store.currentCompanyInfo.business_location_eq_company_address
      "
      class="mb-6"
      @callback="onClickFillAddress()"
      ><template #default>совпадает с юридическим адресом</template></Checkbox
    >
    <PotokButton
      v-if="!props.loading"
      class="mb-4 w-100"
      text="продолжить"
      size="large"
      @click="putCompanyProfiles()"
    />
    <LoadingBtn v-if="props.loading" class="mb-4" />
    <h6
      class="row-link fw-normal text-center mb-3"
      @click="props.loading ? null : emit('redirect-to-user-cabinet')"
    >
      заполнить позже
    </h6>
    <h6
      class="row-link fw-normal text-center"
      @click="props.loading ? null : emit('change-component', AboutCompany)"
    >
      назад
    </h6>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import Checkbox from '@/components/_generic/ui/widgets/Checkbox.vue';
import InputAddress from '@/components/_generic/ui/widgets/InputAddress.vue';
import CompanySites from './CompanySites.vue';
import AboutCompany from './AboutCompany.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Business_address_success } = TRACKER_EVENTS;

const store = useStateStore();

const emit = defineEmits([
  'change-component',
  'redirect-to-user-cabinet',
  'put-company-profiles',
]);
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
    required: true,
  },
});

const onClickFillAddress = () => {
  if (store.currentCompanyInfo.business_location_eq_company_address) {
    store.currentCompanyInfo.business_location = '';
  } else {
    store.currentCompanyInfo.business_location =
      store.currentCompanyInfo.legal_address;
  }
};
const putCompanyProfiles = () => {
  emit('put-company-profiles', {
    business_location_eq_company_address:
      store.currentCompanyInfo.business_location_eq_company_address,
    business_location: store.currentCompanyInfo.business_location
      ? store.currentCompanyInfo.business_location
      : '',
  });
  emit('change-component', CompanySites);
  store.currentCompanyInfo.business_location
    ? tracker.queue(tracker.commands.SEND, SPA_LK_Business_address_success)
    : null;
};
</script>

<style lang="scss" scoped>
.form {
  position: relative;
  .address-input {
    @include input;
    padding-right: 20px;
  }
  .text-description {
    font-size: 12px;
    line-height: 20px;
  }
  .row-link {
    cursor: pointer;
    color: $grey;
    &:hover {
      color: $breakwater;
    }
  }
}
</style>
