<template>
  <div>
    <PopoverIcon v-if="!isMobile">
      <template #icon> <i class="question-icon-black"></i> </template>
      <template #text>
        <table class="w-100 text-nowrap">
          <tbody>
            <tr class="potok-text-body-3">
              <td class="pe-3 color-grey item-cell">основной долг</td>
              <td class="text-primary text-end">
                {{
                  formatCurrency(store.currentCompany.funds?.next_payment?.main)
                }}
                ₽
              </td>
            </tr>
            <tr class="potok-text-body-3">
              <td
                class="item-cell color-grey"
                v-text="store.isPaymentToday ? 'проценты' : 'проценты + НДФЛ'"
              ></td>
              <td v-if="store.isPaymentToday" class="text-primary text-end">
                {{
                  formatCurrency(
                    store.currentCompany.funds?.next_payment?.interest,
                  )
                }}
                ₽
              </td>
              <td v-if="!store.isPaymentToday" class="text-primary text-end">
                {{
                  formatCurrency(
                    store.currentCompany.funds?.next_payment?.interest +
                      store.currentCompany.funds?.next_payment?.ndfl,
                  )
                }}
                ₽
              </td>
            </tr>
            <tr class="potok-text-body-3">
              <td class="item-cell pe-3 color-grey">
                остаток платежей за
                {{
                  new Intl.DateTimeFormat('ru-RU', {
                    month: 'long',
                  }).format(new Date())
                }}
              </td>
              <td class="text-primary text-end">
                {{
                  formatCurrency(
                    store.currentCompany.funds?.last_month_payments_sum,
                    0,
                  )
                }}
                ₽
              </td>
            </tr>
          </tbody>
        </table>

        <table class="w-100 text-nowrap">
          <tbody>
            <tr
              v-if="
                store.currentCompany.funds?.next_payment?.monthly_commission
              "
              class="potok-text-body-3"
            >
              <td class="item-cell pe-6 color-grey">ежемесячная комиссия</td>
              <td v-if="!store.isPaymentToday" class="text-primary text-end">
                {{
                  formatCurrency(
                    store.currentCompany.funds?.next_payment
                      ?.monthly_commission,
                  )
                }}
                ₽
              </td>
              <td v-if="store.isPaymentToday" class="text-primary text-end">
                точная сумма в займе
              </td>
            </tr>
          </tbody>
        </table>
      </template>
    </PopoverIcon>
  </div>
</template>

<script setup>
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import { formatCurrency } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import { detectMobile } from '@/utils';

const store = useStateStore();
const { isMobile } = detectMobile();
</script>

<style lang="scss" scoped>
.item-cell {
  padding: 5px 0;
}
.btn-text {
  padding: 0;
}
.info-icon {
  display: inline-block;
  width: 24px;
  height: 24px;
  background-image: url(@/assets/icons/notices/notify-alert.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  filter: $filter-grey;

  &:hover {
    filter: $filter-breakwater;
  }
}
</style>
