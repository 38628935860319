import config from '@/../config';

export default {
  postBeginScoring$order_id: {
    url: config.VITE_BASE_URL + `/spa/business/orders/$order_id/events`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      event: 'statement_files_upload_done',
    },
  },
  getCommonSchedulePayments: {
    url: config.VITE_BASE_URL + `/spa/business/common_schedule_payments`,
    cache: 'no-cache',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  getDraftSchedulePayments: {
    url: config.VITE_BASE_URL + `/spa/business/draft_schedule_payments`,
    cache: 'no-cache',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
};
