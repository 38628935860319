import config from '@/../config';

const dadataToken = config.VITE_APP_DADATA_API_KEY;

export default {
  postDadataInn: {
    url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/party',
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + dadataToken,
    },
  },
  postDadataEmail: {
    url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/email',
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + dadataToken,
    },
  },
  postDadataAddress: {
    url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + dadataToken,
    },
  },
  postDadataIssuedBy: {
    url: 'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit',
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Token ' + dadataToken,
    },
    body: {
      query: null,
    },
  },
};
