/* eslint-disable max-len*/

import {
  required,
  requiredIf,
  requiredUnless,
  minLength,
  maxLength,
  between,
  alpha,
  alphaNum,
  numeric,
  ipAddress,
  macAddress,
  sameAs,
  url,
  or,
  and,
  helpers,
} from '@vuelidate/validators';
import { isValid, parseISO, differenceInYears, add } from 'date-fns';
import {
  assert,
  formatNumber,
  zip,
  localParseDate,
} from '@/libs/utils';
import constants from './constants';

const { withParams } = helpers;
function ref(reference, vm, parentVm) {
  return typeof reference === 'function'
    ? reference.call(vm, parentVm)
    : parentVm[reference];
}

export default {
  required,
  requiredIf,
  requiredUnless,
  minLength,
  maxLength,
  between,
  alpha,
  alphaNum,
  numeric,
  ipAddress,
  macAddress,
  sameAs,
  url,
  or,
  and,
  withParams,
  minValue(n) {
    return withParams({ type: 'minValue' }, (v) => {
      return n <= Number(formatNumber(v));
    });
  },
  maxValue(n) {
    return withParams({ type: 'maxValue' }, (v) => {
      return n >= Number(formatNumber(v));
    });
  },
  passwordAlpha: withParams({ type: 'passwordAlpha' }, (v) => {
    return /^[-+=~#@!?*`_()[\]/\\$%:;,.a-zA-z0-9]{6,}$/.test(v);
  }),
  russianAlpha: withParams({ type: 'russianAlpha' }, (v) => {
    return /^[-а-яА-ЯёЁ ']*$/.test(v);
  }),
  telegram: withParams({ type: 'russianAlpha' }, (v) => {
    const arrayString = v.split('');

    if (v.match('https://t.me/')) {
      return /^[a-zA-Z0-9_-]*$/.test(v.split('https://t.me/')[1]);
    }
    if (arrayString[0] === '@' && arrayString.filter((el) => el === '@').length <= 1) {
      return /^[a-zA-Z0-9_@-]*$/.test(v)
    }
    return /^[a-zA-Z0-9_-]*$/.test(v);
  }),
  date: withParams({ type: 'date' }, (v) => {
    if (typeof v === 'object') {
      return true;
    }
    if (typeof v === 'string') {
      if (v.length > 12) {
        return isValid(parseISO(v));
      }
      return isValid(localParseDate(v));
    }
  }),
  dateAgeBetweenYears(min, max) {
    return withParams({ type: 'between', min, max }, (v) => {
      const date = localParseDate(v);
      const years = differenceInYears(new Date(), date);
      return years >= min && years <= max;
    });
  },
  exactLength(n) {
    return withParams({ type: 'length' }, (v) => {
      return Array.isArray(v) ? v.length === n : String(v).length === n;
    });
  },
  /**
   * @param {Object} locators
   * @param {String} [locators.bic]
   * @param {String} [locators.companyType]
   * @param {String} [locators.corrAccount]
   * @param {String} [locators.isLiquidated]
   * @param {Boolean} [needCheckCompanyType]
   */
  accountNumber(locators, needCheckCompanyType = true) {
    const bicLocator = locators.bic;
    const companyTypeLocator = locators.companyType;
    const corrAccountLocator = locators.corrAccount;
    const isLiquidatedLocator = locators.isLiquidated;
    assert(bicLocator, '"bicLocator" must be defined');
    if (needCheckCompanyType) {
      assert(companyTypeLocator, '"companyTypeLocator" must be defined');
    }
    return withParams({ type: 'accountNumber' }, (v, parentVm) => {
      const corrAccount = ref(corrAccountLocator, this, parentVm);
      // Отключить валидацию счета для корсчета с 20 нулями (налоговый платеж)
      // https://gitlab.mmdev.ru/potok/tasks/issues/567
      if (corrAccount === '0'.repeat(20)) {
        return true;
      }
      const bic = ref(bicLocator, this, parentVm);
      const companyType = ref(companyTypeLocator, this, parentVm);
      const isLiquidated = ref(isLiquidatedLocator, this, parentVm);
      return (
        /^\d{20}$/.test(v) &&
        /^\d{3,}$/.test(bic) &&
        ifExp(
          needCheckCompanyType,
          testCompanyType(v, companyType, isLiquidated),
          true,
        ) &&
        testCheckSum(v, bic)
      );
    });
    // ------------------
    /**
     * @param {String} an An bank account number (length of 20)
     * @param {String} companyType
     * @param {Boolean} isLiquidated
     * @return {boolean}
     */
    function testCompanyType(an, companyType, isLiquidated) {
      const { company, entrepreneur, person } = constants.companyTypes;
      switch (companyType) {
        case company:
          return /^(4070|421|474)/.test(an);
        case entrepreneur:
          if (isLiquidated) {
            return /^(40817|423|40802|421)/.test(an);
          } else {
            return /^(40802|421)/.test(an);
          }
        case person:
          return /^(40817|423)/.test(an);
        default:
          return false;
      }
    }
    /**
     * @see https://ru.wikipedia.org/wiki/Контрольное_число#Номера_банковских_счетов
     * @param {String} an An bank account number (length of 20)
     * @param {String} bic An BIK (length of >=3)
     * @return {Boolean}
     */
    function testCheckSum(an, bic) {
      const factors = [
        7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1,
      ];
      const eac = bic.slice(-3) + an;
      const digits = eac.split('').map(Number);
      const pairs = zip(digits, factors);
      const sum = pairs.reduce((sum, [d, f]) => sum + d * f, 0);
      const lastDigit = String(sum).slice(-1);
      return lastDigit === '0';
    }

    /**
     * @param {Boolean} condition
     * @param {*} trueValue
     * @param {*} falseValue
     * @return {*}
     */
    function ifExp(condition, trueValue, falseValue) {
      return condition ? trueValue : falseValue;
    }
  },
  bic: withParams({ type: 'regexp' }, (v) => {
    return /^[0-9]{9}$/.test(v);
  }),
  kpp: withParams({ type: 'regexp' }, (v) => {
    return /^[0-9]{4}[0-9A-Z]{2}[0-9]{3}$/.test(v);
  }),
  currency(v) {
    const sanitized = String(v).replace(/\s+/g, '');
    return /^\d+(?:\.\d{1,2})?$/.test(sanitized);
  },
  not(validator) {
    return withParams({ type: 'not' }, (...args) => !validator(...args));
  },
  phone: withParams({ type: 'phone' }, (v) => {
    const sanitized = String(v).replace(/[\s\-()]*/g, '');
    return /^\d{10}$/.test(sanitized);
  }),
  image: (value) => {
    if (value === null || value === undefined) {
      return true;
    } else {
      return (
        /(?:jpg|jpeg|png|gif|pdf|heic)$/i.test(String(value.type)) &&
        Number(value.size) < 7340032
      );
    }
  },
  passportIssuedDateAt(birthDateAtLocator) {
    return withParams({ type: 'passportIssuedDate' }, (v, parentVm) => {
      const birthDateLocator = ref(birthDateAtLocator, this, parentVm);
      const birthDate = localParseDate(birthDateLocator);
      const passportIssuedDate = localParseDate(v);
      // паспорт не может быть выдан в будущем
      if (passportIssuedDate > new Date()) {
        return false;
      }

      var agePerson = differenceInYears(new Date(), birthDate);
      if (passportIssuedDate < add(birthDate, { years: 14 })) {
        return false;
      }
      // первая смена паспорта
      if (agePerson >= 18 && agePerson < 20) {
        if (
          passportIssuedDate > add(birthDate, { years: 14 }) &&
          passportIssuedDate < add(birthDate, { years: 20 })
        ) {
          return true;
        }
      }
      // вторая смена паспорта
      if (agePerson >= 20 && agePerson < 45) {
        if (passportIssuedDate < add(birthDate, { years: 20 })) {
          return false;
        }
      }
      // третья смена паспорта
      if (agePerson >= 45) {
        if (passportIssuedDate < add(birthDate, { years: 45 })) {
          return false;
        }
      }
      return true;
    });
  },
  inn: (v) => {
    return /^([0-9]{10}|[0-9]{12})$/.test(v);
  },
  dividedBy: (num) => {
    return withParams({ type: 'dividedBy' }, (v) => {
      return Number(formatNumber(v)) % num === 0;
    });
  },
  email: (v) => {
    return String(v)
      .toLowerCase()
      .match(
        /^(([^<>()[\]/№*\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      );
  },
};
