import { defineStore } from 'pinia';

import stateGetters from './stateGetters';
import stateActions from './stateActions';

export const useStateStore = defineStore('state', {
  state: () => {
    return {
      app: {
        version: `v${APP_VERSION}`, // eslint-disable-line no-undef
      },
      session: {
        loggedIn: false,
        startSession: undefined,
        login: undefined,
        token: undefined,
      },
      currentUser: {
        email: undefined,
        phone: undefined,
        last_name: undefined,
        first_name: undefined,
        patronymic: undefined,
        agent: undefined,
        agent_inn_set: undefined,
        agent_type: undefined,
        confirmed: undefined,
        order_id: undefined,
        company_id: undefined,
        fio_confirmed: undefined,
        email_confirmed: undefined,
        password_set: undefined,
        email_set: undefined,
        ul_type: undefined,
        id: undefined,
        had_orders: undefined,
        had_loans: undefined,
      },
      currentUserPassport: {
        series_number: '',
        issued_date: '',
        issued_by: '',
        birthday: '',
        place_of_birth: '',
        registration_address: '',
        department_code: '',
        series: '',
        number: '',
      },
      currentOrder: {
        id: undefined,
        state: undefined,
        company_id: undefined,
        product_type: '',
        ask_for_more_statements: undefined,
        bki_agreement_signed: undefined,
        company_profile_filled: undefined,
        last_statement_file_uploaded: undefined,
        refinance_applicable: false,
        desired_loan_amount: undefined,
        confirmed_by_rouble_at: undefined,
        confirmed_by_sms_at: undefined,
        manual_scoring_state: undefined,
        created_at: undefined,
        rejection_reason: undefined,
        credit_line: {
          state: undefined,
          first_tranche: false,
          id: undefined,
        },
        loan: {
          id: undefined,
          company_id: undefined,
          order_id: undefined,
          amount: undefined,
          invested_amount: undefined,
          period_months_count: undefined,
          refunded_months_count: undefined,
          paid_interest_sum: undefined,
          paid_fine_sum: undefined,
          payments: {
            repayments_amount: undefined,
            repayments_count: undefined,
            total_payments_count: undefined,
            next_payment: {
              main: undefined,
              date: undefined,
              ndfl: undefined,
              interest: undefined,
              monthly_commission: undefined,
            },
            overdue: {
              main: undefined,
              date: undefined,
              ndfl: undefined,
              interest: undefined,
              fine: undefined,
              fine_tax: undefined,
              monthly_commission: undefined,
            },
          },
          started_at: undefined,
          state: undefined,
        },
        loan_id: undefined,
        amount: undefined,
        loan_period: 0,
        loan_purpose: undefined,
        promo_code: '',
        need_to_upload_rent_document: undefined,
        need_to_upload_payments_schedule: undefined,
        need_to_upload_previous_passport: undefined,
        need_to_upload_osv: undefined,
        need_to_upload_leasing_agreements_table: undefined,
        need_to_upload_military_card: undefined,
        need_to_upload_other_documents: undefined,
        need_to_upload_statement_file: undefined,
        monthly_commission_percent: 0,
        repayment_frequency: 0,
        base_commission_grade: 0,
        schedule_type: undefined,
        upfront_percent: 0,
        loan_terms_offer: {
          max_limit: undefined,
          available_limit: 0,
          min_limit: 0,
          terms: [],
          fcf_limit: 0,
        },
        regular_payment_amount: 0,
        auto_issuance: undefined,
        rate: undefined,
        visual_rate_mode: undefined,
        borrower_bank_name: undefined,
        borrower_bank_account: undefined,
      },
      currentCompany: {
        id: undefined,
        inn: undefined,
        current: undefined,
        title: undefined,
        short_title: undefined,
        kind: undefined,
        order_id: undefined,
        dashboard: undefined,
        last_statement_file_uploaded_at: undefined,
        bki_agreement_signed_at: undefined,
        company_profile_filled_at: undefined,
        dashboard_orders_count: undefined,
        last_order_with_limit_id: undefined,
        open_loans_count: undefined,
        users: [],
        funds: {
          current_balance: undefined,
          next_payment: {
            main: undefined,
            date: undefined,
            ndfl: undefined,
            interest: undefined,
          },
          overdue: {
            main: undefined,
            date: undefined,
            ndfl: undefined,
            interest: undefined,
            fine: undefined,
            monthly_commission: undefined,
          },
          debt: undefined,
          last_month_payments_sum: undefined,
        },
        limits: {
          fcf_limit: undefined,
          available_limit: undefined,
          max_loan_period: undefined,
        },
      },
      currentCompanyInfo: {
        industry_dictionary_category_id: '',
        title: '',
        primary_occupation: '',
        business_location: '',
        business_location_eq_company_address: false,
        no_site: false,
        sites: [],
        company_id: null,
        kind: '',
        inn: '',
        ogrn: '',
        okato: '',
        okpo: '',
        okved: '',
        registration_date: '',
        head_additional_emails: [],
        head_additional_phones: [],
      },
      orders: {
        filters: {
          selectedFilter: 'open',
          selectedSortType: null,
          selectedSortUp: true,
        },
        loading: false,
        items: [],
      },
      companies: {
        loading: false,
        items: [],
      },
      statements: [],
      currentFounder: {
        unFilledFounders: [],
        id: '',
        name: '',
        phone: '',
        passport: {
          series_number: '',
          issued_by: '',
          issued_date: '',
          birthday: '',
          place_of_birth: '',
          registration_address: '',
          department_code: '',
          series: '',
          number: '',
        },
      },
      currentGuarantor: {
        id: '',
        passport: {
          registration_address: '',
          issued_by: '',
        },
      },
      currentManager: {
        name: undefined,
        manager_type: undefined,
        phone: undefined,
        email: undefined,
      },
      features: {
        yandex_passport_parsing: false,
        redirect_to_spa_loan_page: false,
      },
      utms: {},
      uiStates: {
        isDashboardLimitTabActive: true,
        limitUpdateState: undefined,
      },
      monthlyCommission: 0,
      monthlyCommissionOverdue: 0,
      baseCommissionPercent: parseFloat(
        (Math.random() * (3 - 2) + 2).toFixed(1),
      ),
      isPageRefreshed: false,
      pages: {
        // переменные для страницы customParam
        loanTerm: {
          amountCustomParams: undefined,
          loanPeriodCustomParams: undefined,
          selectedComissionTypeCustomParams: 'monthly_commission',
        },
      },

      notification: {
        timer: 5000,
        message: undefined,
        title: undefined,
        isError: undefined,
      },
    };
  },
  getters: stateGetters,
  actions: stateActions,
});
