<template>
  <div class="container-page-background">
    <div class="container-page">
      <div class="page-title">расчетные счета компании</div>

      <Loader v-if="loading" class="mt-3" />

      <template v-if="!loading">
        <div class="columns">
          <div class="left-columns">
            <div class="accounts-block">
              <table class="formated-table">
                <thead>
                  <th class="td-bank-name">банк</th>
                  <th class="">номер счета</th>
                  <th v-if="!isMobile" class="">данные за период</th>
                  <th class="td-status"></th>
                </thead>
                <tbody class="">
                  <tr
                    v-for="(item, index) in activeBankAccounts"
                    :key="index"
                    class=""
                    @click="onClickAccount(item)"
                  >
                    <td class="td-bank-name">
                      {{ item.bank_name }}
                    </td>
                    <td class="td-bank-account">
                      {{ item.ean }}
                    </td>
                    <td v-if="!isMobile" class="">
                      <template v-if="item.first_date || item.last_date">
                        {{ formatDate(item.first_date) }}
                        <span v-if="item.last_date">
                          - {{ formatDate(item.last_date) }}</span
                        >
                      </template>
                      <template v-if="!item.first_date && !item.last_date"
                        >отсутствуют</template
                      >
                    </td>
                    <td class="td-status">
                      <VMenu v-if="item.closed === false">
                        <i class="icon-file-galka-ok"></i>
                        <template #popper>активный счет</template>
                      </VMenu>
                      <VMenu v-if="item.closed === true">
                        <i class="icon-file-galka-closed"></i
                        ><template #popper>неиспользуемый счет</template>
                      </VMenu>
                    </td>
                    <td v-if="isMobile" class="td-arrow">
                      <i class="icon-arrow"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div v-if="emptyBankAccounts.length > 0" class="accounts-block">
              <div class="potok-text-h2">
                мы нашли ещё счета, по которым можно загрузить выписки
              </div>
              <table class="formated-table mt-4">
                <thead>
                  <th class="td-bank-name">банк</th>
                  <th>номер счета</th>
                  <th v-if="!isMobile">данные за период</th>
                  <th class="td-status"></th>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in emptyBankAccounts"
                    :key="index"
                    @click="onClickAccount(item)"
                  >
                    <td class="td-bank-name">
                      {{ item.bank_name }}
                    </td>
                    <td>
                      {{ item.ean }}
                    </td>
                    <td v-if="!isMobile">отсутствуют</td>
                    <td class="td-status">
                      <i class="icon-file-galka-warning"></i>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div v-if="!isMobile" class="right-columns">
            <div class="accounts-block upload-file">
              <i class="icon-file-uploads-grey"></i>
              <div class="mt-2">
                загрузите выписки по счетам в формате экспорта для 1С
              </div>
              <div class="upload-wrapper">
                <PotokButton
                  class="btn-upload"
                  text="загрузить выписку"
                  size="medium"
                  @click="onClickGotoBankStatement"
                />
              </div>
            </div>
          </div>
          <div v-if="isMobile" class="right-columns">
            <PotokButton
              class="btn-upload"
              text="загрузить еще выписки"
              size="medium"
              @click="onClickGotoBankStatement"
            />
          </div>
        </div>
      </template>
    </div>

    <ModalFull
      v-if="isShowSelectedAccount"
      @close-modal="isShowSelectedAccount = false"
    >
      <template #content>
        <div class="modal-account-wrapper">
          <div class="content-block">
            <div class="modal-row modal-bank-name">
              {{ selectedAccount.bank_name }}
              <i
                v-if="
                  selectedAccount.has_statement_file === true &&
                  selectedAccount.closed === false
                "
                class="icon-file-galka-ok ms-2"
              ></i>
              <i
                v-if="selectedAccount.closed === true"
                class="icon-file-galka-closed ms-2"
              ></i>
              <i
                v-if="
                  selectedAccount.has_statement_file === false &&
                  selectedAccount.closed === false
                "
                class="icon-file-galka-warning ms-2"
              ></i>
            </div>
            <div class="divider"></div>
            <div class="modal-row">
              <div class="potok-text-uppercase color-grey">номер счета</div>
              <div class="mt-2">{{ selectedAccount.ean }}</div>
            </div>
            <div class="divider"></div>
            <div class="modal-row">
              <div class="potok-text-uppercase color-grey">
                данные за период
              </div>
              <div class="mt-2">
                <template
                  v-if="selectedAccount.first_date || selectedAccount.last_date"
                >
                  {{ formatDate(selectedAccount.first_date) }}
                  <span v-if="selectedAccount.last_date">
                    - {{ formatDate(selectedAccount.last_date) }}</span
                  >
                </template>
                <template
                  v-if="
                    !selectedAccount.first_date && !selectedAccount.last_date
                  "
                  >отсутствуют</template
                >
              </div>
            </div>
          </div>
          <div class="actions-block mt-2">
            <PotokButton
              size="medium"
              :disabled="loadingSaving"
              theme="secondary"
              @click="onClickChangeAccount"
              ><template #default
                ><span v-if="selectedAccount.closed === true"
                  >отметить как открытый</span
                >
                <span v-if="selectedAccount.closed === false"
                  >отметить как закрытый</span
                ></template
              ></PotokButton
            >
            <PotokButton
              v-if="!isMobile && selectedAccount.has_statement_file === false"
              class="mt-4"
              text="загрузить еще выписки"
              size="medium"
              @click="onClickGotoBankStatement"
            />
          </div>
        </div>
      </template>
    </ModalFull>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { ref, onMounted, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import server from '@/server/index';
import { Loader } from 'potok-uikit';
import { formatDate } from '@/utils';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import { detectMobile } from '@/utils';
import tracker from '@/tracker';
import { useStateStore } from '@/stores/stateStore';

const router = useRouter();
const loading = ref(false);
const loadingSaving = ref(false);
const store = useStateStore();

const { isMobile } = detectMobile();

const bankAccounts = ref([]);
const isShowSelectedAccount = ref(false);
const selectedAccount = ref(null);

const activeBankAccounts = computed(() => {
  return bankAccounts.value.filter(
    (i) => i.has_statement_file === true || i.closed === true,
  );
});

const emptyBankAccounts = computed(() => {
  return bankAccounts.value.filter(
    (i) => i.has_statement_file === false && i.closed === false,
  );
});

const getBankAccouts = () => {
  loading.value = true;

  server.getBankAccounts
    .send()
    .pipe(onGetBankAccountsSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onGetBankAccountsSuccess = ({ data }) => {
  loading.value = false;
  bankAccounts.value = data;
};

const onClickGotoBankStatement = () => {
  tracker.queue(
    tracker.commands.SEND,
    'SPA_LK_VKL_All_accounts_statement_upload_click',
  );

  return router.push({
    name: 'bankStatement',
  });
};

const onClickAccount = (item) => {
  selectedAccount.value = item;
  isShowSelectedAccount.value = true;

  tracker.queue(tracker.commands.SEND, 'SPA_LK_VKL_All_accounts_open');
};

const onClickChangeAccount = () => {
  loadingSaving.value = true;
  const query = {
    bank_account: {
      closed: !selectedAccount.value.closed,
    },
  };

  server.putBankAccount
    .send(query, {
      params: {
        bank_account_id: selectedAccount.value.id,
      },
    })
    .pipe(onPutBankAccountsSuccess, () => {
      loadingSaving.value = false;
    })
    .exec();
};

const onPutBankAccountsSuccess = () => {
  loadingSaving.value = false;
  isShowSelectedAccount.value = false;

  getBankAccouts();
};

watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    getBankAccouts();
  },
);

onMounted(() => {
  getBankAccouts();
});
</script>
<style lang="scss" scoped>
.columns {
  display: flex;
  justify-content: space-between;
  @media (max-width: $size_767) {
    flex-wrap: wrap;
  }
  @media (max-width: $size_991) {
    flex-wrap: wrap;
  }
  @media (max-width: 1192px) {
    flex-wrap: wrap;
  }
}

.left-columns {
  width: 100%;
  @media (max-width: $size_767) {
    flex: initial;
  }
}

.right-columns {
  margin-left: 30px;
  @media (max-width: 1190px) {
    margin-left: initial;
  }
  @media (max-width: $size_767) {
    margin-left: initial;
    flex: initial;
    width: 100%;
  }
  @media (max-width: $size_991) {
    margin-left: initial;
    flex: initial;
    width: 100%;
  }
}

.accounts-block {
  background-color: white;
  border-radius: 8px;
  margin-top: 30px;
  padding: 20px;
}

.upload-file {
  min-height: 337px;
  min-width: 372px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: center;
}
.btn-upload {
  max-width: 185px;
  margin-top: 33px;
  @media (max-width: $size_767) {
    max-width: initial;
  }
}

.formated-table {
  th {
    &:first-child {
      padding: 0 5px;
    }
  }
  tr {
    &:hover {
      padding: 0 5px;
      background-color: $background;
      cursor: pointer;
      border-radius: 8px;
    }
  }
  td {
    &:first-child {
      padding-left: 5px;
      border-radius: 8px 0 0 8px;
    }
    &:last-child {
      padding-right: 5px;
      border-radius: 0 8px 8px 0;
    }
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
    @media (max-width: 996px) {
      min-width: initial !important;
      max-width: 200px;
    }
    @media (max-width: $size_767) {
      min-width: initial !important;
      max-width: 200px;
    }
    @media (max-width: 500px) {
      min-width: initial !important;
      max-width: 140px;
    }
    @media (max-width: 400px) {
      min-width: initial !important;
      max-width: 100px;
    }
    @media (max-width: 350px) {
      min-width: initial !important;
      max-width: 80px;
    }
  }
}

.td-bank-name {
  min-width: 200px !important;
  @media (max-width: $size_767) {
    min-width: initial !important;
  }
}
.td-status {
  width: 20px !important;
}
.td-arrow {
  width: 30px !important;
}

:deep(.modal-alert) {
  @media (max-width: $size_767) {
    height: 100%;
  }
}
:deep(.modal-container) {
  @media (max-width: $size_767) {
    height: 100%;
  }
}
.modal-account-wrapper {
  min-width: 280px;
  @media (max-width: $size_767) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
}
.modal-bank-name {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0 !important;
  max-width: 300px;
}
.modal-row {
  padding: 20px 0;
}
.actions-block {
  @media (max-width: $size_767) {
    margin-bottom: 100px;
  }
}
</style>
