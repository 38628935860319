<template>
  <div class="page login-recover">
    <h1 class="title potok-text-h1 mb-0">
      восстановление пароля
    </h1>
    <component
        :is="currentComponent"
        :saved-email="savedEmail"
        :reset-token="resetToken"
        class="login-recover-content"
        @save-email="saveEmail"
        @change-step="changeStep"
    />
  </div>
</template>
<script setup>
import { ref, computed } from 'vue';

import LoginRecoverEmail from '@/components/unauth/main-flow/LoginRecover/LoginRecoverEmail.vue';
import LoginRecoverLink from '@/components/unauth/main-flow/LoginRecover/LoginRecoverLink.vue';
import LoginRecoverNewPass from '@/components/unauth/main-flow/LoginRecover/LoginRecoverNewPass.vue';
import { useRoute } from "vue-router";

const route = useRoute()

const currentStep = ref('email');
const savedEmail = ref(null);

const currentComponent = computed(() => {
  if (currentStep.value === 'email') {
    return LoginRecoverEmail;
  }
  if (currentStep.value === 'link') {
    return LoginRecoverLink;
  }
  if (currentStep.value === 'newPass') {
    return LoginRecoverNewPass;
  }
  return null;
});

const resetToken = computed(() => {
  return route.query.token
})

const saveEmail = (value) => {
  savedEmail.value = value;
};

const changeStep = (step) => {
  currentStep.value = step;
};

const init = () => {
  if (resetToken.value) {
    changeStep('newPass');
  }
}
init()
</script>

<style lang="scss" scoped>
@import "src/scss/typography.scss";

.title {
  color: $dipedive;
  width: 100%;
  display: flex;
  justify-content: center;
  @media (max-width: $size_767) {
    @include potok-text-h2;
  }
}
.login-recover {
  margin: 60px auto 0 auto;
  @media (max-width: $size_767) {
    padding: 0 16px;
  }
}
.login-recover-content {
  margin: 12px auto 0 auto;
  width: 340px;
  @media (max-width: $size_767) {
    max-width: 100%;
  }
}
</style>
