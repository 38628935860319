import constants from '@/constants';
import orderVKL from './orderVKL.js';

const { PRODUCT_TYPE, SCHEDULE_TYPE, MANUAL_SCORING_STATES, CLIENT_STATES } =
  constants;

export default {
  currentOrderId() {
    return this.currentOrder.id;
  },
  // признаки партнерской заявки
  isWildBerriesFlow() {
    return this.currentOrder.product_type === PRODUCT_TYPE.express_wildberries;
  },
  isAPIPartnersFlow() {
    return this.currentOrder.product_type === PRODUCT_TYPE.api_partner_full;
  },
  isAPIPartnerOrAPIWildberrieFlow() {
    return (
      this.currentOrder.product_type === PRODUCT_TYPE.express_wildberries ||
      this.currentOrder.product_type === PRODUCT_TYPE.api_partner_full
    );
  },
  // признак необходимости загрузки выписки при партнёрском флоу
  isPartnerStatementRequired() {
    return !!this.currentOrder.ask_for_more_statements;
  },
  // признак того что статус заявки не дошёл до custom_params_asking
  isBeforeCustomParams() {
    return (
      this.currentOrder.client_state === CLIENT_STATES.onboarding ||
      this.currentOrder.client_state === CLIENT_STATES.auto_scoring
    );
  },
  isManualCheck() {
    return this.currentOrder.client_state === CLIENT_STATES.manual_check;
  },
  isCustomParams() {
    return (
      this.currentOrder.client_state === CLIENT_STATES.custom_params_asking
    );
  },
  isRejectedState() {
    return this.currentOrder.client_state === CLIENT_STATES.rejected;
  },
  // признак рассрочки или Zoon
  isProductTypeInstallment() {
    return this.currentOrder.product_type === PRODUCT_TYPE.installment;
  },
  // признак аннуитета
  isScheduleTypeAnnuitet() {
    return this.currentOrder.schedule_type === SCHEDULE_TYPE.annuitet;
  },
  // признак одобрения кредитным комитетом (внутренний статус)
  isApprovedByCreditCommittee() {
    return (
      this.currentOrder.manual_scoring_state ===
      MANUAL_SCORING_STATES.approved_by_credit_committee
    );
  },
  // признак подписания БКИ при обновлении лимита заявки
  isLimitsBkiSigned() {
    return !!(
      this.currentCompany.bki_agreement_signed_actual ||
      this.currentOrder.bki_agreement_signed
    );
  },
  // признак подписания БКИ при обновлении лимита заявки
  isLimitsCompanyFilled() {
    return !!this.currentOrder.company_profile_filled;
  },
  // признак подписания БКИ при обновлении лимита заявки
  isLimitsStatementUploaded() {
    return !!this.currentOrder.last_statement_file_uploaded;
  },
  isHasLoan() {
    return !!this.currentOrder.loan;
  },
  // признак что ордер в статусе холда
  isOrderHold() {
    if (this.currentOrder && this.currentOrder.loan_terms_offer) {
      return !!this.currentOrder.loan_terms_offer.hold_manual_scoring;
    }
    return false;
  },
  isRequiredUpdateLimit() {
    if (this.currentOrder && this.currentOrder.loan_terms_offer) {
      return !this.currentOrder.loan_terms_offer.limit_actual;
    }
    return false;
  },
  ...orderVKL,
};
