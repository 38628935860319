<template>
  <div class="form">
    <p class="form-title fw-bold">
      три простых шага, чтобы обновить лимит и оформить заем
    </p>
    <div class="d-flex justify-content-start">
      <section class="actions d-flex flex-column flex-grow-1">
        <div
          :class="{
            'form-blur pe-none': isBlur,
          }"
        >
          <LimitUserFlow v-if="!isAPIPartnerOrAPIWildberrieFlow" />
          <LimitPartnersFlow v-if="isAPIPartnerOrAPIWildberrieFlow" />
        </div>
        <BlurMessage v-if="isBlur" />
      </section>

      <section v-if="!isMobile" class="advices bg-background p-4 ms-6">
        <span class="advices__title fw-bold d-inline-block mb-6"
          >несколько советов</span
        >
        <ul class="ps-4">
          <li class="mb-4">
            предоставьте выписку за 24 месяца: это поможет получить максимальный
            срок займа.
          </li>
          <li class="mb-4">
            загрузите выписки по каждому из счетов, если их несколько: это
            поможет увеличить сумму лимита.
          </li>
          <li class="mb-4">
            укажите все сайты, где вы ведете бизнес, если их несколько: это
            поможет увеличить сумму лимита.
          </li>
          <li>
            в случае затруднений с загрузкой выписки напишите вопрос в поддержку
            по адресу ok@potok.digital, наши специалисты постараются помочь вам
          </li>
        </ul>
      </section>
    </div>
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import LimitUserFlow from './LimitUserFlow.vue';
import LimitPartnersFlow from './LimitPartnersFlow.vue';
import { detectMobile } from '@/utils';
import { storeToRefs } from 'pinia';
import { useStateStore } from '@/stores/stateStore';
import BlurMessage from '@/components/auth/dashboard/loan-terms/BlurMessage.vue';
import { loanBlurUtils } from '@/components/auth/dashboard/loan-terms/utils/loanBlurUtils';
import { loanTermsUtils } from '@/components/auth/dashboard/loan-terms/utils/loanTermsUtils';

const { isMobile } = detectMobile();
const { isBlur } = loanBlurUtils();
const store = useStateStore();
const { isAPIPartnerOrAPIWildberrieFlow } = storeToRefs(store);
const { startPolling, stopPolling } = loanTermsUtils();

onMounted(() => {
  if (isBlur.value) {
    startPolling();
  }
});
onUnmounted(() => {
  stopPolling();
});
</script>

<style lang="scss" scoped>
.form-blur {
  filter: blur(8px);
}

.form {
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 0px 0px 14px 14px;
  margin-bottom: 30px;
  position: relative;

  .form-title {
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    /* 120% */
    letter-spacing: -0.3px;
  }

  .actions {
    max-width: 777px;
    position: relative;
  }

  .advices {
    max-width: 373px;
    border-radius: 14px;

    .advices__title {
      font-size: 20px;
    }
  }
}
</style>
