<template>
  <div class="progress-point-wrapper">
    <div class="progress-bar">
      <template v-for="(level, idx) in levels" :key="idx">
        <div
          :class="{
            'w-100': idx < levels.length - 1,
          }"
          class="wrapper"
        >
          <div
            v-if="idx < levels.length - 1"
            class="line"
            :class="{ active: level.discount < props.currentValue }"
          ></div>
          <div
            class="dot"
            :class="{ active: level.discount <= props.currentValue }"
          ></div>
          <div class="value">{{ level.discount }}%</div>
        </div>
      </template>
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  currentValue: {
    type: Number,
    required: true,
    default: 0,
  },
});

const levels = [
  { discount: 0 },
  { discount: 15 },
  { discount: 25 },
  { discount: 30 },
  { discount: 35 },
  { discount: 40 },
];
</script>
<style lang="scss" scoped>
.progress-point-wrapper {
  min-height: 25px;
}
.progress-bar {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  height: 4px;
}
.dot {
  width: 12px;
  height: 12px;
  background-color: white;
  border-radius: 50%;
  z-index: 1;
  margin-top: -4px;
}
.wrapper {
  position: relative;
  display: flex;
  height: 4px;
}
.line {
  position: absolute;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: white;
}
.value {
  position: absolute;
  top: 15px;
  left: 0;
  font-size: 8px;
  line-height: 12px;
}
.active.dot {
  background-color: $dipedive;
}
.active.line {
  background-color: $dipedive;
}
</style>
