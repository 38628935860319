import { computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { storeToRefs } from 'pinia';
import { loanCardShared } from '../../../utils/loanCardShared.js';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Sales_contact_wtsap, SPA_LK_Sales_contact_email } =
  TRACKER_EVENTS;

export function managerModalUtils() {
  const store = useStateStore();
  const { currentManager } = storeToRefs(store);
  const { order } = loanCardShared();

  const waLink = computed(() => {
    return (
      'https://wa.me/' +
      currentManager.value.phone +
      '?text=Здравствуйте,%20есть%20вопрос%20по%20заявке%20№' +
      order.value.id +
      '.'
    );
  });
  const onClickOpenChat = () => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Sales_contact_wtsap);
    window.open(waLink.value, '_blank').focus();
  };
  const onClickSendEmail = () => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Sales_contact_email);
    window.open(
      `mailto:${currentManager.value.email}?subject=Вопрос по одобренной заявке №${order.value.id}`,
    );
  };
  return { onClickOpenChat, onClickSendEmail };
}
