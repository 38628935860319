<template>
  <div class="w-100 m-auto">
    <h1 class="text-title text-center">укажите ваш ИНН</h1>
    <div class="text-desc-wrapper">
      <div class="text-desc text-center">
        необходим инн индивидуального предпринимателя или юридического лица
      </div>
    </div>
  </div>
  <div class="form">
    <div class="form-row mt-3">
      <DaDataComponent
        v-model="data.inn"
        :placeholder="'введите ваш инн'"
        :disabled="loading"
        :data-maska="masks.inn()"
        @focus="clearErrors"
      />

      <div v-if="v$.inn.$invalid && isError" class="text-danger mt-2">
        <div>введите корректный ИНН</div>
      </div>
      <ErrorItem v-if="errors.length !== 0" v-model="errors" />
    </div>

    <div class="form-row input-with-icon position-relative">
      <div class="actions-panel">
        <PotokButton
          v-if="!loading"
          id="createpassword"
          class="w-100"
          text="продолжить"
          size="large"
          @click="onClickSave()"
        />
        <LoadingBtn v-if="loading" />
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { ref, reactive } from 'vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import DaDataComponent from '@/components/_generic/ui/inputs/DaDataComponent.vue';
import masks from '@/libs/js-text-masks';

import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import server from '@/server/index';

const emit = defineEmits(['change-step']);

const isError = ref(false);

const loading = ref(false);
const data = reactive({
  inn: '',
});
const errors = ref([]);

const isInnValid = (v) => {
  return /^([0-9]{10}|[0-9]{12})$/.test(v);
};

const validations = {
  inn: {
    required,
    isInnValid,
  },
};

const v$ = useVuelidate(validations, data);

const clearErrors = () => {
  isError.value = false;
  errors.value = [];
};

const onClickSave = () => {
  if (v$.value.inn.$invalid) {
    isError.value = true;
    return;
  }
  loading.value = true;
  const query = {
    user: { inn: data.inn },
  };

  server.postRegAgentINN
    .send(query)
    .pipe(onCheckUserSuccess, (error) => {
      loading.value = false;
      errors.value = error.data?.messages;
    })
    .exec();
};
const onCheckUserSuccess = (result) => {
  loading.value = false;
  if (result.response.status === 201) {
    emit('change-step', {
      inn: data.inn,
    });
  }
};
</script>
<style lang="scss" scoped>
.text-desc {
  max-width: 369px;
}
.text-desc-wrapper {
  padding-top: 4px;
}
.dadata-component {
  margin-top: 30px;
}
.actions-panel {
  margin-top: 26px;
  margin-bottom: 40px;
}
</style>
