<template>
  <div class="white-block graphs-item">
    <div class="block-title">
      <div>расчетные счета компании</div>
      <div
        v-if="isHasAccountWithoutStatement"
        class="notice-alert potok-text-uppercase"
      >
        есть счета, по которым нет выписки
      </div>
    </div>
    <template v-if="store.companies.loading">
      <Loader class="mt-4" />
    </template>
    <template v-if="!store.companies.loading">
      <table class="formated-table">
        <thead>
          <th class="td-bank-name">банк</th>
          <th>номер счета</th>
          <th>данные за период</th>
          <th class="td-status"></th>
        </thead>
        <tbody class="custom-table-tbody">
          <tr v-for="(item, index) in activeBankAccounts" :key="index">
            <td class="td-bank-name">
              {{ item.bank_name }}
            </td>
            <td>
              {{ item.ean }}
            </td>
            <td>
              <template v-if="item.first_date || item.last_date">
                {{ formatDate(item.first_date) }}
                <span v-if="item.last_date">
                  - {{ formatDate(item.last_date) }}</span
                >
              </template>
              <template v-if="!item.first_date && !item.last_date"
                >отсутствуют</template
              >
            </td>
            <td class="td-status">
              <VMenu v-if="item.closed === false">
                <i class="icon-file-galka-ok"></i>
                <template #popper>активный счет</template>
              </VMenu>
              <VMenu v-if="item.closed === true">
                <i class="icon-file-galka-closed"></i
                ><template #popper>неиспользуемый счет</template>
              </VMenu>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="mt-6">
        <router-link
          class="btn-text-tiffany"
          :to="{ name: 'accounts' }"
          @click="onClickDetails"
        >
          подробнее →</router-link
        >
      </div>
    </template>
  </div>
</template>
<script setup>
import { ref, onMounted, computed, watch } from 'vue';
import { Loader } from 'potok-uikit';
import server from '@/server/index';
import { formatDate } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';

const store = useStateStore();
const loading = ref(false);
const bankAccounts = ref([]);

const isHasAccountWithoutStatement = computed(() => {
  return (
    bankAccounts.value.filter(
      (i) => i.has_statement_file === false && i.closed === false,
    ).length > 0
  );
});

const activeBankAccounts = computed(() => {
  return bankAccounts.value
    .filter((i) => i.has_statement_file === true || i.closed === true)
    .splice(0, 4);
});

const getBankAccouts = () => {
  loading.value = true;

  server.getBankAccounts
    .send()
    .pipe(onGetBankAccountsSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onGetBankAccountsSuccess = ({ data }) => {
  loading.value = false;
  bankAccounts.value = data;
};

const onClickDetails = () => {
  tracker.queue(
    tracker.commands.SEND,
    'SPA_LK_VKL_Business_analytics_All_accounts_click',
  );
};

watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    getBankAccouts();
  },
);

onMounted(() => {
  getBankAccouts();
});
</script>
<style lang="scss" scoped>
.block-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.notice-alert {
  padding: 5px 10px;
  color: #ee3d48;
  border-radius: 4px;
  background-color: #ee3f4a1a;
  height: 22px;
}
.td-bank-name {
  min-width: 200px !important;
  @media (max-width: $size_767) {
    min-width: initial !important;
  }
}

.formated-table {
  th {
    &:first-child {
      padding: 0 5px;
    }
  }
  tr {
    &:hover {
      padding: 0 5px;
      background-color: $background;
      cursor: pointer;
      border-radius: 8px;
    }
  }
  td {
    &:first-child {
      padding-left: 5px;
      border-radius: 8px;
    }
    &:last-child {
      padding-right: 5px;
      border-radius: 8px;
    }
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 250px;
    @media (max-width: 996px) {
      min-width: initial !important;
      max-width: 200px;
    }
    @media (max-width: $size_767) {
      min-width: initial !important;
      max-width: 160px;
    }
    @media (max-width: 540px) {
      min-width: initial !important;
      max-width: 110px;
    }
    @media (max-width: 400px) {
      min-width: initial !important;
      max-width: 100px;
    }
    @media (max-width: 350px) {
      min-width: initial !important;
      max-width: 80px;
    }
  }
}

.td-bank-name {
  min-width: 200px !important;
  @media (max-width: $size_767) {
    min-width: initial !important;
  }
}
.td-status {
  width: 20px !important;
}
.td-arrow {
  width: 30px !important;
}
</style>
