<template>
  <div class="container-page-background">
    <div class="container-page">
      <div
        class="dashboard-header"
        :class="{
          'dashboard-header-fixheight': store.isFeatureCommissionDiscount,
        }"
      >
        <div class="page-title">главная</div>
        <LoyaltyWidget v-if="store.isFeatureCommissionDiscount" />
      </div>
      <ServerNotices />
      <DashboardMessages />

      <HeadTabMenu @open-modal-limits="openModalLimitMessage" />

      <BottomSection v-if="!store.uiStates.isDashboardLimitTabActive" />

      <div class="panel-wrapper">
        <div
          v-if="!store.uiStates.isDashboardLimitTabActive"
          class="left-panel-wrapper"
        >
          <Orders
            id="orders"
            :orders="orders"
            :loading-orders="loadingOrders"
            :page="page"
            :initiated="initiated"
            @change-filters="changeFilters"
          />
        </div>
        <div
          v-if="!isMobile && !store.uiStates.isDashboardLimitTabActive"
          class="white-block right-panel-wrapper"
        >
          <PaymentSummaryDesktop />
        </div>
        <LoanTerm v-if="store.uiStates.isDashboardLimitTabActive" />
      </div>
    </div>

    <ModalHelpLimitMessage
      v-if="isShowModalLimitMessage"
      :limits="limits"
      @close-modal="closeModalLimitMessage"
    />

    <ModalHelpHoldMessage
      v-if="isShowModalHoldMessage"
      :reasons="store.currentOrder?.hold_manual_scoring_reasons"
      @close-modal="closeModalHoldMessage"
    />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted, ref, watch, computed } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ServerNotices from '@/components/_generic/auth/ServerNotices.vue';
import LoyaltyWidget from '@/components/auth/dashboard/loyalty-widget/LoyaltyWidget.vue';
import LoanTerm from '@/components/auth/dashboard/loan-terms/LoanTerm.vue';
import Orders from '@/components/auth/dashboard/orders/Orders.vue';

import HeadTabMenu from '@/components/auth/dashboard/head-section/HeadTabMenu.vue';
import PaymentSummaryDesktop from '@/components/auth/dashboard/payment-summary/PaymentSummaryDesktop.vue';
import BottomSection from '@/components/auth/dashboard/head-section/head-bottom-section/BottomSection.vue';
import { useStateStore } from '@/stores/stateStore';
import { detectMobile } from '@/utils';
import tracker from '@/tracker';
import constants from '@/constants';
import { ordersUtils } from '@/components/auth/dashboard/modules/ordersUtils.js';
import { TRACKER_EVENTS } from '@/tracker/events';
import server from '@/server/index';
import ModalHelpLimitMessage from '@/components/auth/dashboard/modules/ModalHelpLimitMessage.vue';
import ModalHelpHoldMessage from '@/components/auth/dashboard/modules/ModalHelpHoldMessage.vue';
import { headUtils } from '@/components/auth/dashboard/head-section/headUtils.js';
import DashboardMessages from '@/components/auth/dashboard/messages/DashboardMessages.vue';

const {
  SPA_LK_Mainpage_open,
  SPA_LK_Limit_Info_screen_open,
  SPA_LK_Limit_Info_screen_close,
  SPA_LK_Hold_Info_screen_open,
} = TRACKER_EVENTS;

const { limits } = headUtils();

const store = useStateStore();
const route = useRoute();
const router = useRouter();
const { isMobile } = detectMobile();
const {
  orders,
  loadingOrders,
  initiated,
  page,
  perPage,
  totalPages,
  getOrdersAsync,
} = ordersUtils();

const pageHeight = ref();
const arrivedState = ref();
const isShowModalLimitMessage = ref(false);
const isShowModalHoldMessage = ref(false);

let intervalID;

const confirmEmail = () => {
  if (route.query.confirmation_token) {
    const query = {
      confirmation_token: route.query.confirmation_token,
    };
    server.postConfirmEmail
      .send(query)
      .pipe(() => {
        router.push({
          name: 'dashboard',
        });
      })
      .exec();
  }
};

const closeModalLimitMessage = () => {
  isShowModalLimitMessage.value = false;
  tracker.queue(tracker.commands.SEND, SPA_LK_Limit_Info_screen_close);
};

const openModalLimitMessage = () => {
  isShowModalLimitMessage.value = true;
  tracker.queue(tracker.commands.SEND, SPA_LK_Limit_Info_screen_open);
};

const closeModalHoldMessage = () => {
  isShowModalHoldMessage.value = false;
};
const openModalHoldMessage = () => {
  isShowModalHoldMessage.value = true;
  tracker.queue(tracker.commands.SEND, SPA_LK_Hold_Info_screen_open);
};

const initPage = () => {
  getOrdersAsync({ isLoaderShown: true, companyId: store.currentCompany.id });
  store.fetchFeatures();
};

onMounted(() => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Mainpage_open);
  initPage();

  confirmEmail();
  detectScrollToBottom();
  checkRouteAndOpenModal();
});

onUnmounted(() => {
  stopPolling();
});

const detectScrollToBottom = () => {
  window.onscroll = () => {
    pageHeight.value = document.documentElement.scrollHeight;
    arrivedState.value = window.pageYOffset + window.innerHeight;
  };
};

watch(
  () => arrivedState.value,
  () => {
    if (
      (Math.ceil(arrivedState.value) === pageHeight.value ||
        Math.floor(arrivedState.value) === pageHeight.value) &&
      page.value < totalPages.value &&
      orders.value.length >= perPage.value
    ) {
      page.value += 1;
      getOrdersAsync({
        isLoaderShown: true,
        companyId: store.currentCompany.id,
      });
    }
  },
);
// polling if order state include "waiting"
watch(
  () => orders.value,
  () => {
    if (isIncludeWaitingState.value && page.value === 1) {
      startPolling();
    } else {
      stopPolling();
    }
  },
);
watch(
  () => route.query,
  () => {
    checkRouteAndOpenModal();
  },
);
watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    initPage();
  },
);

const checkRouteAndOpenModal = () => {
  if (route.query?.action === 'limits') {
    openModalLimitMessage();
    router.push({
      name: 'dashboard',
    });
  }

  if (route.query?.action === 'hold') {
    openModalHoldMessage();
    router.push({
      name: 'dashboard',
    });
  }
};

const isIncludeWaitingState = computed(() =>
  orders.value.some((el) => constants.SCORING_STATES.includes(el.state)),
);

const startPolling = () => {
  if (!intervalID) {
    intervalID = setInterval(function () {
      getOrdersAsync({
        isLoaderShown: false,
        companyId: store.currentCompany.id,
      });
    }, 5000);
  }
};
const stopPolling = () => {
  clearInterval(intervalID);
  intervalID = null;
};

const changeFilters = () => {
  page.value = 1;
  orders.value = [];
  getOrdersAsync({
    isLoaderShown: true,
    companyId: store.currentCompany.id,
  });
};
</script>

<style lang="scss" scoped>
.dashboard-header {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
  align-items: center;
}
.dashboard-header-fixheight {
  height: 80px;
}
.await-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.panel-wrapper {
  display: flex;
  .right-panel-wrapper {
    flex: 1;
    max-height: 526px;
    padding: 20px 10px;
    margin-top: 105px;
  }
  .left-panel-wrapper {
    width: fit-content;
    flex: 2;
    margin-right: 30px;
    @media (max-width: $size_767) {
      margin-right: initial;
      width: 100%;
    }
  }
}
</style>
