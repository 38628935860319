<template>
  <OnClickOutside @trigger="emit('update:isMenuShown', false)">
    <div
      v-if="props.isMenuShown"
      class="menu small"
      @click="emit('update:isMenuShown', false)"
    >
      <slot name="content"></slot>
    </div>
  </OnClickOutside>
</template>

<script setup>
import { OnClickOutside } from '@vueuse/components';

const props = defineProps({
  isMenuShown: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:isMenuShown']);
</script>

<style lang="scss" scoped>
.menu {
  z-index: 100;
  position: absolute;
  top: 65px;
  padding: 10px;
  background-color: $white;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  border-radius: 8px;
  white-space: nowrap;
}
</style>
