import config from '@/../config';

export default {
  getCompanies: {
    url: config.VITE_BASE_URL + `/spa/business/companies`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  putCompanies$company_id: {
    url: config.VITE_BASE_URL + `/spa/business/companies/$company_id`,
    cache: 'no-cache',
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  postCompanies: {
    url: config.VITE_BASE_URL + `/spa/business/companies/`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  getCompaniesAffiliates: {
    url: config.VITE_BASE_URL + `/spa/business/companies/affiliates`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  getIndustryDictionaryCategories: {
    url: config.VITE_BASE_URL + `/spa/business/industry_dictionary_categories`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  getCompanyProfiles: {
    url: config.VITE_BASE_URL + `/spa/business/company_profiles`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  putCompanyProfiles$company_id: {
    url: config.VITE_BASE_URL + `/spa/business/company_profiles/$company_id`,
    cache: 'no-cache',
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  getCompanyLimits: {
    url:
      config.VITE_BASE_URL +
      `/spa/business/companies/$company_id/company_limits`,
    method: 'GET',
    credentials: 'include',
  },
  getCreditHistories: {
    url:
      config.VITE_BASE_URL +
      `/spa/business/companies/$company_id/credit_histories`,
    method: 'GET',
    credentials: 'include',
  },
  getTurnovers: {
    url: config.VITE_BASE_URL + `/spa/business/companies/$company_id/turnovers`,
    method: 'GET',
    credentials: 'include',
  },
  postFeedback: {
    url: config.VITE_BASE_URL + `/spa/business/companies/$company_id/feedback`,
    method: 'POST',
    headers: {
      Accept: 'multipart/form-data',
      'Content-Type': 'multipart/form-data',
    },
    credentials: 'include',
  },
};
