import config from '@/../config';

export default {
  postSupportTicket: {
    url: config.VITE_BASE_URL + `/spa/support_requests`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
};
