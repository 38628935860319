import config from '@/../config';

export default {
  getUploadAttachments: {
    url: config.VITE_BASE_URL + `/spa/business/attachments`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  deleteAttachment$id: {
    url: config.VITE_BASE_URL + `/spa/business/attachments/$id`,
    cache: 'no-cache',
    method: 'DELETE',
    credentials: 'include',
  },
  getUploadAttachments$id: {
    url: config.VITE_BASE_URL + `/spa/business/attachments/$id/file`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  getAttachments: {
    url: config.VITE_BASE_URL + `/spa/business/attachments`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  postAttachments: {
    url: config.VITE_BASE_URL + `/spa/business/attachments`,
    cache: 'no-cache',
    method: 'POST',
    credentials: 'include',
    // headers: {
    //   Accept: 'application/json',
    //   'Content-Type': 'application/json',
    // },
  },
};
