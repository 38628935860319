<template>
  <div class="form m-auto">
    <h1 class="h3 fw-bold mb-2 text-center lh-lg">расскажите о бизнесе</h1>
    <h6 class="fw-normal mb-6 text-secondary text-center lh-base">
      шаг 1 из 4
    </h6>
    <CustomSelect
      v-model="store.currentCompanyInfo.industry_dictionary_category_id"
      class="mb-4"
      :items="props.dictionary"
      :with-node="true"
      :default-title="props.industryCategory || 'выберите отрасль'"
      type="alt"
    />
    <textarea
      v-model="store.currentCompanyInfo.primary_occupation"
      class="form-control bg-background mb-2 shadow-none"
      :placeholder="isMobile ? 'цель займа' : 'опишите, чем Вы занимаетесь'"
      rows="4"
    ></textarea>
    <div class="text-secondary text-description mb-6">
      коротко опишите деятельность компании. Если юридическое лицо занимается
      несколькими видами деятельности, опишите основной бизнес и укажите его
      долю в выручке
    </div>
    <PotokButton
      v-if="!props.loading"
      class="mb-4 m-auto w-100"
      text="продолжить"
      size="large"
      @click="putCompanyProfiles()"
    />
    <LoadingBtn v-if="props.loading" class="mb-4" />
    <h6
      class="row-link fw-normal text-center"
      @click="props.loading ? null : emit('redirect-to-user-cabinet')"
    >
      заполнить позже
    </h6>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { detectMobile } from '@/utils';
import CompanyLocation from './CompanyLocation.vue';
import CustomSelect from '@/components/_generic/ui/widgets/CustomSelect.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Business_about_success } = TRACKER_EVENTS;

const store = useStateStore();
const { isMobile } = detectMobile();

const emit = defineEmits([
  'change-component',
  'redirect-to-user-cabinet',
  'put-company-profiles',
]);
const props = defineProps({
  dictionary: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
  loading: {
    type: Boolean,
    default: false,
    required: true,
  },
  industryCategory: {
    type: String,
    default: '',
    required: true,
  },
});

const putCompanyProfiles = () => {
  emit('put-company-profiles', {
    primary_occupation: store.currentCompanyInfo.primary_occupation,
    industry_dictionary_category_id:
      store.currentCompanyInfo.industry_dictionary_category_id,
  });
  emit('change-component', CompanyLocation);
  store.currentCompanyInfo.primary_occupation
    ? tracker.queue(tracker.commands.SEND, SPA_LK_Business_about_success)
    : null;
};
</script>

<style lang="scss" scoped>
.form {
  textarea {
    min-height: 120px;
    max-height: 230px;
    border: none;
    padding: 18px 20px 20px 20px;
    &::placeholder {
      color: $secondary;
    }
    @media (max-width: $size_767) {
      min-height: 160px;
      max-height: 267px;
    }
  }
  .text-description {
    font-size: 12px;
    line-height: 20px;
  }
  .row-link {
    cursor: pointer;
    color: $grey;
    &:hover {
      color: $breakwater;
    }
  }
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: $background;
}
::-webkit-scrollbar-thumb {
  background: $input;
}
</style>
