import server from '@/server/index';
import { ref, computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import constants from '@/constants';
import { useRoute } from 'vue-router';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';
import { isPassportFullFileld } from '@/utils';

const {
  SPA_LK_Guarantor_Parsing_success,
  SPA_LK_application_open,
  SPA_LK_VKL_application_open,
} = TRACKER_EVENTS;

const {
  ORDER_STATES,
  ADDITIONAL_DOC_TYPES,
  PARSING_STATES,
  MANUAL_SCORING_STATES,
} = constants;

// --------------SHARED STATE----------//
const order = ref({});
const guarantors = ref([]);

const loading = ref(false);
const errors = ref([]);

const isConfirmedByRouble = ref(false);
const isPlatformRulesOpen = ref(false);

const isPassportUploaded = ref(false);
const isPassportPartiallyUploaded = ref(false);
const isPassportFilled = ref(false);

const isPassportModalOpen = ref(false);
const isPassportFillModalOpen = ref(false);

const isGuaranteeFullFilled = ref(false);
const isGuaranteePartiallyFilled = ref(true);
const isGuaranteeAllSignedByRub = ref(false);
const isGuaranteePartiallySignedByRub = ref(false);
const isGuarantorsFilledOnBackend = ref(false);

const isGuaranteeIssueOpen = ref(false);
const isGuaranteeSigningOpen = ref(false);

const isDocsModalOpen = ref(false);
const isAdditionalDocsUploadedOld = ref(false);
const isAddDocsPartiallyUploadedOld = ref(false);
const isNeedAdditionalDocsUpload = ref(false);
const docsToUpload = ref([]);

const isDocsSigningModalOpen = ref(false);
const isDocsSigned = ref(false);
const isDocsVKLSigned = ref(false);

const isSuccessModalOpen = ref(false);
const isManagerModalOpen = ref(false);
const isNeedUploadStatementFile = ref(null);
// ----------------------------------//

export function loanCardShared() {
  const route = useRoute();
  const store = useStateStore();

  // признак одобрения кредитным комитетом (внутренний статус)
  const isApprovedByCreditCommittee = computed(() => {
    return (
      order.value.manual_scoring_state ===
      MANUAL_SCORING_STATES.approved_by_credit_committee
    );
  });

  const docTypes = [
    ADDITIONAL_DOC_TYPES.military_card,
    ADDITIONAL_DOC_TYPES.rent_document,
    ADDITIONAL_DOC_TYPES.leasing_agreements_table,
    ADDITIONAL_DOC_TYPES.osv,
    ADDITIONAL_DOC_TYPES.other_documents,
    ADDITIONAL_DOC_TYPES.payments_schedule,
    ADDITIONAL_DOC_TYPES.previous_passport,
  ];

  const getUserDataAsync = () => {
    loading.value = true;
    return server.getUserData
      .send()
      .pipe(onGetUserDataAsyncSuccess, onGetUserDataAsyncError)
      .exec();
  };
  const onGetUserDataAsyncSuccess = ({ data }) => {
    store.setCurrentUser(data);
    getOrdersAsync();
  };
  const onGetUserDataAsyncError = (error) => {
    errors.value = error.data?.messages;
    loading.value = false;
  };
  const getOrdersAsync = () => {
    const query = {
      'expand[]': ['loan'],
    };

    return server.getOrders$order_id
      .send(query, {
        params: {
          order_id: route.params.order_id,
        },
      })
      .pipe(onGetOrdersAsyncSuccess, onGetOrdersAsyncError)
      .exec();
  };
  const onGetOrdersAsyncSuccess = ({ data }) => {
    order.value = data;
    store.setCurrentOrder(data);
    loading.value = false;

    isConfirmedByRouble.value = data.confirmed_by_rouble_at;
    isDocsSigned.value = data.confirmed_by_sms_at;
    // isDocsSigned.value = false
    isNeedUploadStatementFile.value = data.need_to_upload_statement_file;
    isNeedAdditionalDocsUpload.value = [
      data.need_to_upload_leasing_agreements_table,
      data.need_to_upload_military_card,
      data.need_to_upload_osv,
      data.need_to_upload_other_documents,
      data.need_to_upload_payments_schedule,
      data.need_to_upload_previous_passport,
      data.need_to_upload_rent_document,
    ].some((i) => i === true);

    if (data?.credit_line?.state && data.credit_line.state !== 'init') {
      isDocsVKLSigned.value = true;
    }

    tracker.queue(
      tracker.commands.SEND,
      store.isVKLFlow ? SPA_LK_VKL_application_open : SPA_LK_application_open,
    );

    tracker.queue(tracker.commands.PARAMS, {
      visual_rate_mode: order.value.visual_rate_mode,
    });
    /*
    Дополнительная проверка с бека
    Проверяет заполеность всех поручителей
    */
    isGuarantorsFilledOnBackend.value = data.guarantors_filled;

    let filteredData = Object.keys(data)
      .filter((k) => docTypes.includes(k.slice(15)) && !!data[k])
      .reduce((a, k) => ({ ...a, [k]: data[k] }), {});
    let slicedArr = Object.keys(filteredData).map((s) => s.slice(15));
    docsToUpload.value = slicedArr;
    getPassportsAsync();

    if (order.value.state === ORDER_STATES.user_verifying) {
      getManagerInfoAsync();
    }
  };
  const onGetOrdersAsyncError = (error) => {
    errors.value = error.data?.messages;
    loading.value = false;
  };
  const getPassportsAsync = () => {
    const query = {
      kind: 'passport_main',
      attachable_id: store.currentUser.id,
      attachable_type: 'User',
    };
    return server.getUploadAttachments
      .send(query, {})
      .pipe(getPassportsAsyncSuccess, getPassportsAsyncError)
      .exec();
  };
  const getPassportsAsyncSuccess = ({ data }) => {
    isPassportUploaded.value = !!(
      Object.values(data).every((v) => v) && Object.keys(data).length !== 0
    );
    isPassportPartiallyUploaded.value = !!(
      Object.values(data).some((v) => v) && Object.keys(data).length !== 0
    );

    getAdditionalDocsAsync();
  };
  const getPassportsAsyncError = (error) => {
    errors.value = error.data?.messages;
    loading.value = false;
  };
  const getAdditionalDocsAsync = () => {
    const query = {
      kind: 'additional_documents',
      attachable_id: route.params.order_id,
      attachable_type: 'Order',
    };
    return server.getUploadAttachments
      .send(query, {})
      .pipe(getAdditionalDocsAsyncSuccess, getAdditionalDocsAsyncError)
      .exec();
  };

  const redefinedAdditionalDocsUpload = (data) => {
    let leasingAgreementsTable =
      !!order.value.need_to_upload_leasing_agreements_table;
    if (
      leasingAgreementsTable &&
      data.find((i) => i.kind === 'leasing_agreements_table')
    ) {
      leasingAgreementsTable = false;
    }

    let requiredMilitaryCard = !!order.value.need_to_upload_military_card;
    if (requiredMilitaryCard && data.find((i) => i.kind === 'military_card')) {
      requiredMilitaryCard = false;
    }

    let osv = !!order.value.need_to_upload_osv;
    if (osv && data.find((i) => i.kind === 'osv')) {
      osv = false;
    }

    let otherDocuments = !!order.value.need_to_upload_other_documents;
    if (otherDocuments && data.find((i) => i.kind === 'other_documents')) {
      otherDocuments = false;
    }

    let paymentsSchedule = !!order.value.need_to_upload_payments_schedule;
    if (paymentsSchedule && data.find((i) => i.kind === 'payments_schedule')) {
      paymentsSchedule = false;
    }

    let previousPassport = !!order.value.need_to_upload_previous_passport;
    if (previousPassport && data.find((i) => i.kind === 'previous_passport')) {
      previousPassport = false;
    }

    let rentDocument = !!order.value.need_to_upload_rent_document;
    if (rentDocument && data.find((i) => i.kind === 'rent_document')) {
      rentDocument = false;
    }

    isNeedAdditionalDocsUpload.value = ![
      leasingAgreementsTable,
      requiredMilitaryCard,
      osv,
      otherDocuments,
      paymentsSchedule,
      previousPassport,
      rentDocument,
    ].every((i) => i === false);
  };
  const getAdditionalDocsAsyncSuccess = ({ data }) => {
    // только для мейн флою старый код
    const filteredData = data.filter((el) => docTypes.includes(el.kind));
    isAddDocsPartiallyUploadedOld.value = !!filteredData.length;

    if (isNeedAdditionalDocsUpload.value === true) {
      redefinedAdditionalDocsUpload(data);
    }
    getGuarantorsAsync();
  };
  const getAdditionalDocsAsyncError = (error) => {
    errors.value = error.data?.messages;
    loading.value = false;
  };
  const getGuarantorsAsync = () => {
    const query = {
      id: route.params.order_id,
    };
    return server.getGuarantors
      .send(query, {})
      .pipe(getGuarantorsAsyncSuccess, getGuarantorsAsyncError)
      .exec();
  };

  const getGuarantorsAsyncSuccess = ({ data }) => {
    data.person.forEach((el) => {
      if (el.passport) {
        el.series_number = el.passport.series_number;
        el.passport.citizenship = el.citizenship;
        el.passport.inn = el.inn;
        el.passport.series = el.passport.series_number?.slice(0, 4);
        el.passport.number = el.passport.series_number?.slice(5, 11);
      } else {
        el.passport = {};
      }
      el.full_name = el.full_name.trim();
      el.success_status = 'not upload';
    });

    // Опредление заполнености данных по поручмтелям на снове
    // количества поручителей и их full_name и passport_uploaded
    const countSucceeded = data.person.filter(
      (el) =>
        isPassportFullFileld(el.passport) &&
        el.full_name &&
        el.passport_uploaded,
    );
    isGuaranteeFullFilled.value = countSucceeded.length === data.person.length;
    const countPartiallySucceeded = data.person.filter(
      (el) => isPassportFullFileld(el.passport) || el.passport_uploaded,
    );

    if (!isGuaranteeFullFilled.value) {
      isGuaranteePartiallyFilled.value = !!countPartiallySucceeded.length;
    }
    data.person.forEach((el) => {
      if (isPassportFullFileld(el.passport) && el.passport_uploaded) {
        el.success_status = 'success';
      } else if (isPassportFullFileld(el.passport) || el.passport_uploaded) {
        el.success_status = 'partially';
      }
    });

    const countConfirmedByRub = data.person.filter(
      (el) => el.confirmed_by_rouble_at,
    );
    isGuaranteeAllSignedByRub.value =
      countConfirmedByRub.length === data.person.length;
    isGuaranteePartiallySignedByRub.value = !!countConfirmedByRub.length;

    const incomingParsingState = data.person.some(
      (el) =>
        el.passport?.state === PARSING_STATES.parsed_with_success &&
        el.passport?.state !==
          guarantors.value.filter((item) => item.id === el.id)?.[0]?.passport
            ?.state,
    );

    if (incomingParsingState) {
      tracker.queue(tracker.commands.SEND, SPA_LK_Guarantor_Parsing_success);
    }

    guarantors.value = data.person;
    store.fetchUserPassport({
      onSuccess: () => {
        isPassportFilled.value = isPassportFullFileld(
          store.currentUserPassport,
        );
        loading.value = false;
      },
      onError: (error) => {
        errors.value = error.data?.messages;
        loading.value = false;
      },
    });
  };
  const getGuarantorsAsyncError = (error) => {
    errors.value = error.data?.messages;
  };
  const getManagerInfoAsync = () => {
    loading.value = true;
    return server.getManagerInfo
      .send(
        {},
        {
          params: {
            order_id: route.params.order_id,
          },
        },
      )
      .pipe(onGetManagerInfoAsyncSuccess, onGetManagerInfoAsyncError)
      .exec();
  };
  const onGetManagerInfoAsyncSuccess = ({ data }) => {
    store.setCurrentManager(data);
    loading.value = false;
  };
  const onGetManagerInfoAsyncError = (error) => {
    errors.value = error.data?.messages;
    loading.value = false;
  };

  return {
    order,
    loading,
    errors,
    guarantors,
    isApprovedByCreditCommittee,
    isConfirmedByRouble,
    isPlatformRulesOpen,
    isPassportModalOpen,
    isPassportUploaded,
    isPassportPartiallyUploaded,
    isPassportFillModalOpen,
    isGuaranteeIssueOpen,
    isGuaranteeFullFilled,
    isGuaranteePartiallyFilled,
    isDocsModalOpen,
    isAdditionalDocsUploadedOld,
    isAddDocsPartiallyUploadedOld,
    isNeedAdditionalDocsUpload,
    isNeedUploadStatementFile,
    docsToUpload,
    isDocsSigningModalOpen,
    isDocsSigned,
    isDocsVKLSigned,
    isGuaranteeSigningOpen,
    isGuaranteePartiallySignedByRub,
    isGuaranteeAllSignedByRub,
    isGuarantorsFilledOnBackend,
    isPassportFilled,
    isSuccessModalOpen,
    isManagerModalOpen,
    getUserDataAsync,
    getGuarantorsAsync,
    getOrdersAsync,
    getPassportsAsync,
  };
}
