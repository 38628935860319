import { parse, format } from 'date-fns';
import ru from 'date-fns/locale/ru';
import { useRouter } from 'vue-router';

export default function analyticUtils() {
  const router = useRouter();

  const MESSAGE_WAIT_CALCULATION =
    'данные появятся после обрабоки выписки, ожидайте';
  const MESSAGE_LOAD_REQUIRED = 'данные появятся после загрузки выписки';

  const getLabels = (items) => {
    return items.map((item) => {
      const date = parse(item.period, 'yyyy-MM', new Date());
      let currentYear = format(date, 'yyyy');
      let currentMonth = format(date, 'MM', {
        locale: ru,
      }).toUpperCase();
      if (currentMonth === '01') {
        return [currentMonth, currentYear];
      }
      return currentMonth;
    });
  };

  const totalProfitForPeriod = (items) => {
    let total = 0;
    items.map((item) => {
      total += item?.top1_customer?.amount ? item.top1_customer.amount : 0;
      total += item?.top2_customer?.amount ? item.top2_customer.amount : 0;
      total += item?.top3_customer?.amount ? item.top3_customer.amount : 0;
      total += item?.other_customers?.amount ? item.other_customers.amount : 0;
    });
    return total;
  };

  const onClickRedirectLoadStatement = () => {
    router.push({
      name: 'bankStatement',
    });
  };

  return {
    MESSAGE_WAIT_CALCULATION,
    MESSAGE_LOAD_REQUIRED,
    getLabels,
    totalProfitForPeriod,
    onClickRedirectLoadStatement,
  };
}
