import constants from '@/constants';

const { LOAN_STATES } = constants;

export default {
  // признак флоу ВКЛ (Выделенная Кредитная Линия)
  isVKLFlow() {
    return !!this.currentOrder.credit_line;
  },
  // признак флоу ВКЛ и необходимости обновление лимита
  isVKLFlowRequiredUpdateLimit() {
    return (
      this.currentOrder &&
      this.currentOrder.credit_line &&
      this.currentOrder.client_state ===
        constants.CLIENT_STATES.custom_params_asking &&
      !this.currentOrder.loan_terms_offer?.limit_actual
    );
  },
  // признак необходимости обновление выписки
  isVKLRequiredUploadStatement() {
    return !!this.currentOrder?.need_to_upload_statement_file;
  },
  // признак первого транша при ВКЛ флоу
  isVKLFirstTranche() {
    return !!this.currentOrder.credit_line?.first_tranche;
  },
  isVKLSecondTranche() {
    return (
      this.currentOrder.credit_line &&
      !this.currentOrder.credit_line.first_tranche
    );
  },
  isVKLFlowInvesting() {
    return !!(
      this.currentOrder.loan?.state === LOAN_STATES.investing ||
      this.currentOrder.client_state === LOAN_STATES.investing
    );
  },
  isVKLFlowOnbloarding() {
    return (
      this.isVKLFlow &&
      this.currentOrder.client_state === constants.CLIENT_STATES.onboarding
    );
  },
  // используется для блокировки кнопки "взять транш" в ВКЛ на странице онбординга
  isVKLOnboardingContinueDisabled() {
    return (
      this.currentOrder.client_state === constants.CLIENT_STATES.onboarding &&
      (!this.isLimitsCompanyFilled ||
        !this.isLimitsStatementUploaded ||
        !this.isLimitsBkiSigned)
    );
  },
};
