import config from '@/../config';

export default {
  postContinueReg: {
    url: config.VITE_BASE_URL + `/spa/users/continue_registrations`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      token: null,
      email: null,
      password: null,
    },
  },
  postUsersConfirmOtp: {
    url: config.VITE_BASE_URL + `/spa/business/users/confirmations`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    body: {
      otp: null,
    },
  },
  postRequestOtp$Phone: {
    url: config.VITE_BASE_URL + `/spa/business/users/signatures`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
};
