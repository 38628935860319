<template>
  <div class="mb-2">
    <div
      class="form w-100 bg-white"
      :class="[
        isMobile
          ? 'd-block'
          : 'd-flex align-items-center justify-content-between pe-6',
      ]"
    >
      <template v-if="!store.companies.loading">
        <TableDesktop v-if="!isMobile" v-model:isModalShown="isModalShown" />
        <TableMobile v-if="isMobile" v-model:isModalShown="isModalShown" />
      </template>
      <template v-if="store.companies.loading">
        <div class="loader-wrapper">
          <Loader />
        </div>
      </template>
    </div>
  </div>
</template>

<script setup>
import { Loader } from 'potok-uikit';
import TableDesktop from '@/components/auth/dashboard/head-section/head-bottom-section/TableDesktop.vue';
import TableMobile from '@/components/auth/dashboard/head-section/head-bottom-section/TableMobile.vue';
import { useStateStore } from '@/stores/stateStore';
import { ref } from 'vue';
import { detectMobile } from '@/utils';

const store = useStateStore();
const { isMobile } = detectMobile();

const isModalShown = ref(false);
</script>

<style lang="scss" scoped>
.form {
  border-radius: 0px 0px 14px 14px;
  padding: 30px 20px;
  @media (max-width: $size_767) {
    height: auto;
    padding: 16px;
  }
  .button-wrapper {
    width: 220px;
    @media (max-width: $size_767) {
      width: 100%;
    }
  }
}
.loader-wrapper {
  @media (max-width: $size_767) {
    min-height: 50px;
  }
}
</style>
