<template>
  <div class="loan-term-content">
    <div class="left-panel">
      <div
        class="base-content"
        :class="{
          'form-blur pe-none': isBlur,
        }"
      >
        <div class="flex-grow-1">
          <div class="form-slider mb-4 mb-md-3">
            <div class="d-flex justify-content-between mb-4 position-relative">
              <span class="text-grey">выберите сумму</span>
            </div>
            <div class="input-value">
              <CurrencyInput
                v-model:modelValue="store.pages.loanTerm.amountCustomParams"
                v-model:isFocused="isFocused"
                :disabled="
                  !store.currentOrder.loan_terms_offer ||
                  store.currentOrder.loan_terms_offer.available_limit === 0
                "
                :options="{
                  locale: 'ru-Ru',
                  currency: 'RUB',
                  hideCurrencySymbolOnFocus: false,
                  hideGroupingSeparatorOnFocus: false,
                  valueRange: {
                    min: store.currentOrder.loan_terms_offer?.min_limit,
                    max: store.currentOrder.loan_terms_offer?.available_limit,
                  },
                }"
              />
              <div
                v-if="
                  store.pages?.loanTerm?.amountCustomParams > 0 &&
                  store.pages?.loanTerm?.amountCustomParams < AMOUNT_ONE_DAY
                "
                class="tips"
              >
                <i class="icon-flash"></i>выдача за 1 день
              </div>
              <div
                v-if="
                  store.pages?.loanTerm?.amountCustomParams >= AMOUNT_ONE_DAY
                "
                class="tips text-tiffany"
              >
                <i class="icon-call me-2"></i>потребуется звонок с экспертом
              </div>
            </div>
            <VueSlider
              v-model="store.pages.loanTerm.amountCustomParams"
              class="mt-2"
              :dot-size="[15, 15]"
              :height="7"
              :silent="true"
              :min="Math.floor(store.currentOrder.loan_terms_offer?.min_limit)"
              :max="
                Math.floor(store.currentOrder.loan_terms_offer?.available_limit)
              "
              :data="isFocused ? null : steps"
              :disabled="
                Math.floor(
                  store.currentOrder.loan_terms_offer?.available_limit,
                ) === 0 || isActionButtonDisabled()
              "
              tooltip="none"
              @change="changeSliderAmount"
            />
            <div class="d-flex justify-content-between text-grey small">
              <span
                >{{
                  formatCurrency(
                    store.currentOrder.loan_terms_offer?.min_limit,
                    0,
                  ) + ' ₽'
                }}
              </span>
              <span>{{
                formatCurrency(
                  store.currentOrder.loan_terms_offer?.available_limit,
                  0,
                ) + ' ₽'
              }}</span>
            </div>
          </div>
          <div class="form-slider mb-4">
            <div class="d-flex justify-content-between mb-4">
              <span class="text-grey">выберите срок</span>
            </div>
            <span class="slider-title"
              >{{ store.pages?.loanTerm?.loanPeriodCustomParams }} месяц{{
                pluralize(store.pages?.loanTerm?.loanPeriodCustomParams, [
                  '',
                  'а',
                  'ев',
                ])
              }}</span
            >
            <VueSlider
              v-model="store.pages.loanTerm.loanPeriodCustomParams"
              class="mt-2"
              :dot-size="15"
              :height="7"
              :adsorb="true"
              :silent="true"
              :data="periodInMonth"
              :marks="periodMarks"
              :disabled="isActionButtonDisabled()"
              tooltip="none"
            />
          </div>

          <div>
            <Account />
          </div>

          <div class="input-purpose">
            <CustomSelect
              v-model="loanTermsAccept.loanPurpose"
              class="mt-4"
              :items="suggestions"
              default-title="выберите цель займа"
              type="alt"
            />
          </div>
          <input
            v-if="isOtherSelected"
            v-model="loanTermsAccept.loanPurposeInput"
            class="dropdown_input mt-4"
            placeholder="укажите вашу цель займа"
            type="text"
            @change="onChangeSendAnalytics()"
          />
          <div class="promo__wrapper position-relative mt-4 w-100">
            <input
              v-model="loanTermsAccept.promo"
              class="promo_input"
              placeholder="введите промокод"
              :class="{
                'is-invalid': v$.promo.$invalid && v$.promo.$dirty,
              }"
              :disabled="isPromoAccepted"
              type="text"
              @input="v$.promo.$touch()"
              @change="onChangeSendAnalytics()"
            />
            <div class="promo__btn-wrapper">
              <button
                v-if="!loading && !isPromoAccepted && !intervalId"
                class="input__btn"
                @click="onClickPostPromoAsync"
              ></button>
              <i v-if="isPromoAccepted" class="correct-icon mt-2"></i>
              <Loader v-if="loading || intervalId" class="mt-2" />
            </div>
          </div>
        </div>
      </div>
      <BlurMessage v-if="isBlur" />
    </div>
    <div class="right-panel loan-terms">
      <div class="loan-terms__title">
        <span>условия транша</span>
      </div>
      <TermsTableCustomParams
        :loan-period="store.pages?.loanTerm?.loanPeriodCustomParams"
        font-size="small"
        :type="
          store.isFeatureCommissionDiscount
            ? 'custom_params_commission_discount'
            : 'custom_params'
        "
      >
        <template v-if="store.isVKLFlow" #documents>
          <SelectAdditionalDocs
            class="mt-4"
            :title="'скачать документы и договоры'"
            :items="additionalDocsWithTemplate"
            @select="onClickOpenDoc"
          />
          <PotokButton
            class="mt-2 w-100"
            :disabled="isActionButtonDisabled()"
            :text="'график платежей'"
            size="medium"
            theme="secondary"
            @click="onClickPayments()"
          ></PotokButton>
        </template>
      </TermsTableCustomParams>
    </div>
  </div>
  <ModalPayments
    v-if="isShowModalPayments"
    @close="isShowModalPayments = false"
  />
</template>
<script setup>
import { PotokButton, Loader } from 'potok-uikit';
import { useStateStore } from '@/stores/stateStore';
import { ref, watch, onMounted, onUnmounted, nextTick } from 'vue';
import TermsTableCustomParams from '@/components/auth/dashboard/loan-terms/modules/TermsTableCustomParams.vue';
import CurrencyInput from '@/components/auth/dashboard/loan-terms/modules/CurrencyInput.vue';
import CustomSelect from '@/components/_generic/ui/widgets/CustomSelect.vue';
import SelectAdditionalDocs from '@/components/_generic/ui/widgets/SelectAdditionalDocs.vue';
import Account from '@/components/_generic/ui/widgets/Account.vue';
import VueSlider from 'vue-slider-component';
import BlurMessage from '@/components/auth/dashboard/loan-terms/BlurMessage.vue';
import tracker from '@/tracker';
import {
  roundAmount,
  roundPeriod,
} from '@/components/auth/dashboard/loan-terms/utils/index.js';
import { pluralize, formatCurrency } from '@/utils';
import { loanTermsUtils } from '@/components/auth/dashboard/loan-terms/utils/loanTermsUtils';
import { loanBlurUtils } from '@/components/auth/dashboard/loan-terms/utils/loanBlurUtils';
import { loanCardVKLUtils } from '@/components/auth/loan-card/vkl-flow/utils/loanCardVKLUtils.js';
import { TRACKER_EVENTS } from '@/tracker/events';
import ModalPayments from '@/components/_generic/ui/modals/ModalPayments.vue';

const AMOUNT_ONE_DAY = 3000000;

const store = useStateStore();
const { isBlur } = loanBlurUtils();
const { additionalDocsWithTemplate, onClickOpenDoc } = loanCardVKLUtils();
const {
  leftTime,
  countdown,
  loanTermsAccept,
  loading,
  isFocused,
  isPromoAccepted,
  periodInMonth,
  periodMarks,
  steps,
  v$,
  isOtherSelected,
  interestAmount,
  intervalId,
  startPolling,
  stopPolling,
  onClickPostPromoAsync,
  onChangeSendAnalytics,
  isActionButtonDisabled,
} = loanTermsUtils();
const { step_4_verifying, SPA_LK_Conditions_limit_refrash } = TRACKER_EVENTS;

const isShowModalPayments = ref(false);

const suggestions = [
  {
    title: 'пополнение оборотных средств',
    value: 'пополнение оборотных средств',
  },
  {
    title: 'перекрытие кассового разрыва',
    value: 'перекрытие кассового разрыва',
  },
  {
    title: 'приобретение основных средств / расширение бизнеса',
    value: 'приобретение основных средств / расширение бизнеса',
  },
  { title: 'выполнение гос. контракта', value: 'выполнение гос. контракта' },
  { title: 'прочие цели', value: 'прочие цели' },
];

/**
 * при изменяем значения слайдера сохраняем текущее значение в сторе
 * для отображение в уловиях транша
 */
const changeSliderAmount = (value) => {
  nextTick(() => {
    store.pages.loanTerm.amountCustomParams = roundAmount(value);
  });
};

/*
    Логика передвижения слайдера суммы, при понижении срока займа
    Срабатывает, когда регулярный платёж (в месяц или неделю) больше FCFLimit (в месяц)
  */
watch(
  () => store.pages.loanTerm.loanPeriodCustomParams,
  (newVal, oldVal) => {
    if (store.isNewLimit) {
      return;
    }
    if (
      oldVal > newVal &&
      !isFocused.value &&
      (store.currentOrder.repayment_frequency === 30
        ? interestAmount.value > store.currentOrder.loan_terms_offer?.fcf_limit
        : (interestAmount.value / 7) * 30 >
          store.currentOrder.loan_terms_offer?.fcf_limit)
    ) {
      const value =
        store.currentOrder.loan_terms_offer?.fcf_limit *
        store.pages?.loanTerm?.loanPeriodCustomParams;

      if (value > store.currentOrder.loan_terms_offer?.min_limit) {
        store.pages.loanTerm.amountCustomParams = value;
      } else {
        store.pages.loanTerm.amountCustomParams =
          store.currentOrder.loan_terms_offer?.min_limit;
      }
    }
  },
);
/*
    Логика передвижения слайдера срока, при повышении суммы займа
    Срабатывает, когда регулярный платёж (в месяц или неделю) больше FCFLimit (в месяц)
  */
watch(
  () => store.pages.loanTerm.amountCustomParams,
  (newVal, oldVal) => {
    if (store.isNewLimit) {
      return;
    }

    if (
      oldVal < newVal &&
      (store.currentOrder.repayment_frequency === 30
        ? interestAmount.value > store.currentOrder.loan_terms_offer?.fcf_limit
        : (interestAmount.value / 7) * 30 >
          store.currentOrder.loan_terms_offer?.fcf_limit)
    ) {
      store.pages.loanTerm.loanPeriodCustomParams = roundPeriod(
        store.isCustomParams
          ? store.pages?.loanTerm?.amountCustomParams
          : store.currentOrder.amount /
              store.currentOrder.loan_terms_offer?.fcf_limit,
        store.currentOrder.loan_terms_offer?.terms,
      );
    }
  },
);

watch(
  () => {
    return store.currentOrder.client_state;
  },
  () => {
    if (store.isBeforeCustomParams) {
      startPolling();
    } else {
      stopPolling();
      // запрашиваем companies для обновления данных после скоринга
      store.fetchCompanies({
        expand: ['funds', 'limits'],
      });
      // передвигаем слайдер в крайнее правое положение
      store.currentOrder.amount =
        store.currentOrder.loan_terms_offer?.available_limit;
      store.pages.loanTerm.amountCustomParams = store.currentOrder.amount;
    }
  },
  { immediate: true },
);

onMounted(() => {
  countdown.onTick = (val) => {
    leftTime.value = val;
  };
  loanTermsAccept.loanPurpose = suggestions[0].value;

  tracker.queue(tracker.commands.PARAMS, {
    visual_rate_mode: store.currentOrder.visual_rate_mode,
  });
  tracker.queue(tracker.commands.SEND, step_4_verifying);
  if (store.isMainFlowRequiredUpdateLimit) {
    tracker.queue(tracker.commands.SEND, SPA_LK_Conditions_limit_refrash);
  }
});
onUnmounted(() => {
  stopPolling();
});

const onClickPayments = () => {
  isShowModalPayments.value = true;
};
</script>
<style lang="scss" scoped>
.conditional-title {
  margin-top: -45px;
}

.input-value {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: $dipedive;

  :deep(input) {
    color: $dipedive;
  }
}

.left-panel {
  flex: 2;
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 0px 0px 14px 14px;
  position: relative;
  max-width: 778px;
}

.right-panel {
  width: 340px;
  padding-right: 30px;
  margin-left: 30px;
  background-color: #ffffff;
  padding-top: 12px;
  @media (max-width: $size_767) {
    border-radius: 14px;
    width: 100%;
    padding: 20px;
    margin-left: initial;
    margin-top: 17px;
  }
}

.loan-term-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media (max-width: $size_767) {
    background-color: $background;
  }
}

.form-slider {
  min-height: 177px;
  border-radius: 14px;
  background-color: $background;
  padding: 20px;

  .slider-title {
    font-size: 20px;

    @media (max-width: $size_767) {
      font-size: 18px;
    }
  }
}

.promo__wrapper {
  .promo_input {
    @include input;
    padding: 20px 60px 20px 20px;
  }

  .is-invalid {
    border: 1px solid rgba($red, 0.2);
  }

  .promo__btn-wrapper {
    top: 13px;
    right: 20px;
    position: absolute;

    .input__btn {
      width: 34px;
      height: 34px;
      background-color: $breakwater;
      background-image: url(@/assets/icons/arrows/arrow.svg);
      background-position: center;
      background-repeat: no-repeat;
      border-radius: 50%;
      border: none;
      color: $white;
    }

    .correct-icon {
      display: inline-block;
      width: 18px;
      height: 18px;
      background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
      background-repeat: no-repeat;
      background-position: center;
      filter: $filter-breakwater;
    }
  }
}

.tips {
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  text-transform: lowercase;
  display: flex;
  align-items: center;

  @media (max-width: $size_767) {
    display: none;
  }
}

.base-content {
  display: flex;
  flex-wrap: wrap;

  @media (max-width: $size_767) {
    flex-direction: column;
  }
}

.loan-terms {
  @media (max-width: $size_767) {
    width: 100%;
  }

  .loan-terms__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: $dipedive;
    margin-bottom: 10px;
  }

  .loan-terms__switcher {
    .switcher__btn {
      position: relative;
      width: 100%;
      color: $grey;
      border: 1px solid $dividers;
      border-radius: 8px;
      padding: 11px 0 11px 10px;
      background-color: transparent;

      .radio {
        position: absolute;
        top: 19px;
        right: 19px;
        height: 5px;
        width: 5px;
        border-radius: 50%;
        background-color: $background;
        box-shadow: 0 0 0 5px $background;
      }

      .radio-active {
        background-color: $white;
        box-shadow: 0 0 0 5px $breakwater;
      }
    }

    .switcher__btn-active {
      color: $breakwater;
      border: 1px solid $breakwater;
    }
  }
}

.dropdown_input {
  @include input;
}

.form-blur {
  filter: blur(8px);
}
</style>
