import { formatDate } from '@/utils';
import constants from '@/constants';

const { ORDER_STATES, SCORING_STATES } = constants;

export default {
  isCompanyUL() {
    return this.currentCompany.kind === 'UL';
  },
  // признак даты платежа
  isPaymentToday() {
    return !!(
      this.currentCompany.funds?.next_payment?.date &&
      formatDate(this.currentCompany.funds?.next_payment?.date) ===
        formatDate(new Date())
    );
  },
  // признак того, что есть хотя бы один активный заем
  isLoanExists() {
    return this.currentCompany.open_loans_count !== 0;
  },
  // признак того, что есть хотя бы одна открытая заявка
  isOrderExists() {
    return this.currentCompany.dashboard_orders_count !== 0;
  },
  // признак наличия просрочки
  isOverdue() {
    return !!this.currentCompany?.funds?.overdue?.main;
  },
  // признак наличия просрочки по ежемесячной комиссии
  isOverdueMonthlyCommission() {
    return !!this.currentCompany?.funds?.overdue?.monthly_commission;
  },
  // признак наличия штрафа платформы
  isCompanyHasPenalty() {
    return !!this.currentCompany?.funds?.overdue?.penalty;
  },
  // признак подписания БКИ при обновлении лимита заявки
  // должен использоваться только в онбординге
  isStatementUploaded() {
    return !!this.currentCompany.last_statement_file_uploaded_at;
  },
  isBkiSigned() {
    return !!this.currentCompany.bki_agreement_signed_at;
  },
  isCompanyFilled() {
    return !!this.currentCompany.company_profile_filled_at;
  },
  isPassedOnboarding() {
    return this.isStatementUploaded && this.isBkiSigned && this.isCompanyFilled;
  },
  companyOrderId() {
    return this.currentCompany.last_order_with_limit_id;
  },
  // признак потери актуальности лимита
  isMainFlowRequiredUpdateLimit() {
    if (this.isVKLFlow) {
      return false;
    }
    const states = [
      ORDER_STATES.rejected,
      ORDER_STATES.custom_params_asking,
      ORDER_STATES.user_verifying,
      ...SCORING_STATES,
    ];
    return !!(
      (this.currentCompany?.limits?.available_limit ||
        this.currentCompany?.limits?.available_limit === 0) &&
      !this.currentOrder.loan_terms_offer?.available_limit &&
      !states.includes(this.currentOrder.client_state)
    );
  },
  // признак протухшей (старше недели) выписки (604800000 - 7 дней в миллисекундах)
  isStatementOutdated() {
    let currentDate = new Date();
    let uploadDate = new Date(
      this.currentCompany.last_statement_file_uploaded_at,
    );
    return this.isStatementUploaded && currentDate - uploadDate > 604800000;
  },
  isLimitsBKIRequired() {
    return this.isMainFlowRequiredUpdateLimit && !this.isStatementOutdated;
  },
  isNewOrderAllowed() {
    return !!this.currentCompany.new_order_allowed;
  },
  isCompanyBanned() {
    return this.currentCompany.state === constants.COMPANY_STATES.banned;
  },
  isNewLimit() {
    return !!this.currentCompany.channel_for_new_limit;
  },
};
