<template>
  <div class="hit-desktop-payment">
    <div class="only_mobile">{{ getStatusTitlePayment(props.item) }}</div>
    <div
      v-for="(payment, indexPayment) in props.item.payments"
      :key="indexPayment"
      class="hit-desktop-payment-row"
    >
      <div class="hit-desktop-payment-name">заем № {{ payment.order_id }}</div>
      <div class="hit-desktop-payment-value">
        {{
          formatCurrency(getAmountPaymentItem(props.item.status, payment), 0)
        }}
        ₽
      </div>
    </div>
  </div>
</template>
<script setup>
import { formatCurrency } from '@/utils';
import { paymentUtils } from '../paymentUtils.js';

const { getStatusTitlePayment } = paymentUtils();
const props = defineProps({
  item: {
    type: Object,
    default() {
      return {};
    },
  },
});

const getAmountPaymentItem = (status, payment) => {
  let amount = 0;
  if (status === 'REFUNDED') {
    amount += payment.main;
    amount += payment.interest;
    amount += payment.monthly_commission;
  }
  if (status === 'OVERDUE') {
    amount += payment.main;
    amount += payment.interest;
    amount += payment.fine;
    amount += payment.monthly_commission;
  }
  if (status === 'REFUNDED_AFTER_OVERDUE') {
    amount += payment.main;
    amount += payment.interest;
    amount += payment.fine;
    amount += payment.monthly_commission;
  }
  if (status === 'AWAIT') {
    amount += payment.main;
    amount += payment.interest;
    amount += payment.ndfl;
    amount += payment.monthly_commission;
  }
  if (status === 'ACTIVE') {
    amount += payment.main;
    amount += payment.interest;
    amount += payment.monthly_commission;
  }
  return amount;
};
</script>
<style></style>
