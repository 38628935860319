/**
 * Throws an error when a value is falsely
 * @param {*} value
 * @param {String} [message]
 */
export function assert(value, message = '') {
  if (!value) {
    throw new Error(message);
  }
}
/**
 * Do nothing
 */
export function noOp() {
  // empty body
}
/**
 * Do nothing
 */
export function nextTick(fn) {
  setTimeout(fn, 1);
}

export function isString(any) {
  return typeof any === 'string';
}
