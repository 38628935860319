import { detect } from 'detect-browser';
import html2canvas from 'html2canvas';
import { resize } from 'resize-image';
import ArrayPreemptive from './ArrayPreemptive';

export default class Tracer {
  /**
   * @param {Object} VueConfig
   */
  constructor(VueConfig) {
    this.__log = new ArrayPreemptive(8);
    window.onerror = this.__onWindowError.bind(this);
    VueConfig.errorHandler = this.__onVueError.bind(this);
    // debug('Tracer was initialized')
  }
  trace(obj) {
    const timestamp = new Date();
    const entry = Object.assign(
      {
        url: window.location.href,
        error: null,
        dataUrl: null, // screenshot
        timestamp: timestamp.toISOString(),
      },
      obj,
    );
    this.__log.push(entry);
    this.onTrace(entry);
  }
  takeScreenshot() {
    const url = window.location.href;
    return html2canvas(document.body)
      .then((canvas) => resize(canvas, Math.min(canvas.width, 800)))
      .then((dataUrl) => this.trace({ dataUrl, url }));
  }
  dump() {
    return {
      browser: detect(),
      log: this.getLog(),
    };
  }
  getLog() {
    return this.__log.slice().reverse();
  }
  clearLog() {
    this.__log.length = 0;
  }
  toJSON() {
    return this.dump();
  }
  toString() {
    return String(this.getLog());
  }
  onTrace() {
    // debug('Tracer: onTrace', entry)
  }
  /**
   * @see https://blog.sentry.io/2016/01/04/client-javascript-reporting-window-onerror
   * @param {String} message
   * @param {String} url
   * @param {Number} lineNumber
   * @param {Number} columnNumber
   * @param {Error} error
   * @returns {Boolean}
   */
  __onWindowError(message, url, lineNumber, columnNumber, error) {
    // debugFn('Tracer: window.onerror')
    this.trace({
      error: {
        message,
        stack: error ? error.stack : '',
      },
    });
  }
  /**
   * @see https://vuejs.org/v2/api/#errorHandler
   * @param {Error} err
   * @param {Vue} vm
   * @param {String} info
   * @private
   */
  __onVueError(err) {
    // debug('Tracer: Vue.config.errorHandler')
    this.trace({
      error: {
        message: err.message,
        stack: err.stack,
      },
    });
    throw err; // <-- forwards an error
  }
}
