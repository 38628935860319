export default {
  setCurrentUser(data) {
    this.currentUser = data;
  },
  setCurrentUserPassport(data) {
    this.currentUserPassport = data;
  },
  setUTMs(data) {
    this.utms = data;
  },
  setToken(data) {
    this.session.token = data;
  },
};
