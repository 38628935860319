<template>
  <div class="container-fluid">
    <div class="container">
      <PlatformRules :amount="calculateAmount()" type="commission"
        ><template #title>оплата ежемесячной комиссии</template
        ><template #description>реквизиты для платежа </template></PlatformRules
      >
    </div>
  </div>
</template>

<script setup>
import PlatformRules from '@/components/auth/loan-card/modules/platform-rules/PlatformRules.vue';
import { useStateStore } from '@/stores/stateStore';

const store = useStateStore();

const calculateAmount = () => {
  if (store.currentOrder.loan?.payments?.overdue?.monthly_commission) {
    if (store.isPaymentToday) {
      return store.monthlyCommission + store.monthlyCommissionOverdue;
    } else {
      return store.monthlyCommissionOverdue;
    }
  } else if (store.isPaymentToday) {
    return store.monthlyCommission;
  } else {
    return store.monthlyCommission;
  }
};
</script>

<style lang="scss" scoped>
.container-fluid {
  background-color: $background;
  overflow: auto;
  min-height: 100%;
}
</style>
