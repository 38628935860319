<template>
  <div
    id="main-tab"
    class="form form-main-tab head-tabmenu-order"
    :class="applyFormStyles(false)"
    @mouseover="isOrderTabHovered = true"
    @mouseleave="isOrderTabHovered = false"
    @click="onClickSwitchTab(false)"
  >
    <div class="form-block h-100">
      <div v-if="store.companies.loading">
        <Loader />
      </div>
      <div v-if="!store.companies.loading">
        <div v-if="store.isLoanExists" class="form-title-wrapper">
          <div class="form-title-block">
            <div class="form-title head-section-title potok-text-uppercase">
              ПЛАТЕЖ
              {{
                formatDate(
                  store.currentCompany.funds?.next_payment?.date,
                  'dd.MM.yyyy',
                )
              }}
            </div>
            <div class="form-title-value">
              <div
                v-if="!store.isPaymentToday"
                class="head-section-title-amount head-section-title-amount-order potok-text-h2"
              >
                <span class="me-2">
                  {{
                    formatCurrency(
                      store.currentCompany.funds?.next_payment?.main +
                        store.currentCompany.funds?.next_payment?.interest +
                        store.currentCompany.funds?.next_payment?.ndfl +
                        (store.isBillingMonthlyCommission
                          ? store.currentCompany.funds?.next_payment
                              ?.monthly_commission
                          : 0),
                    )
                  }}
                  ₽
                </span>
                <NextPaymentHint
                  v-if="store.isLoanExists"
                  class="head-section-title-amount-order-icon"
                  @click="onClickSendAnalytics()"
                />
              </div>
              <div
                v-if="store.isPaymentToday"
                class="head-section-title-amount potok-text-h2"
              >
                {{
                  formatCurrency(
                    store.currentCompany.funds?.next_payment?.main +
                      store.currentCompany.funds?.next_payment?.interest +
                      (store.isBillingMonthlyCommission
                        ? store.currentCompany.funds?.next_payment
                            ?.monthly_commission
                        : 0),
                  )
                }}
                ₽
              </div>
            </div>
          </div>
          <div class="form-title-block">
            <div class="form-title head-section-title potok-text-uppercase">
              БАЛАНС
            </div>
            <div
              class="form-title-value head-section-title-amount potok-text-h2"
              :class="{ 'text-danger': isBalanceInsufficient }"
            >
              {{ formatCurrency(store.currentCompany.funds?.current_balance) }}
              ₽
            </div>
          </div>
        </div>
        <div v-if="!store.isLoanExists">
          <div class="form-title-wrapper">
            <div class="form-title-block border-0">
              <div class="form-title head-section-title potok-text-uppercase">
                МОИ ЗАЙМЫ
              </div>
              <div class="form-title-value fw-bold">нет займов</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="!isMobile">
        <div
          v-if="store.uiStates.isDashboardLimitTabActive"
          class="action-button-not-active"
          :class="{
            'bg-primary text-white rounded-5': isOrderTabHovered,
            'text-grey': !isOrderTabHovered,
          }"
        >
          <span class="small"> к текущим займам &#8594;</span>
        </div>
        <PotokButton
          v-if="!store.uiStates.isDashboardLimitTabActive"
          size="medium"
          theme="secondary"
          @click="onClickGoToRefill"
          ><template #default>пополнить баланс</template></PotokButton
        >
      </div>
    </div>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { PotokButton, Loader } from 'potok-uikit';
import { headUtils } from './headUtils.js';
import { formatDate, formatCurrency, detectMobile } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import { TRACKER_EVENTS } from '@/tracker/events';
import tracker from '@/tracker';
import NextPaymentHint from '@/components/auth/dashboard/head-section/NextPaymentHint.vue';

const { SPA_LK_Mainpage_payment_info, SPA_LK_Mainpage_add_money } =
  TRACKER_EVENTS;
const { isOrderTabHovered, applyFormStyles, onClickSwitchTab } = headUtils();

const store = useStateStore();
const router = useRouter();
const { isMobile } = detectMobile();

const isBalanceInsufficient = computed(() => {
  return (
    store.isPaymentToday.value &&
    store.currentCompany.value.funds?.next_payment?.main +
      store.currentCompany.value.funds?.next_payment?.interest >
      store.currentCompany.value.funds?.current_balance
  );
});

const onClickGoToRefill = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Mainpage_add_money);
  router.push({
    name: 'accountRefill',
  });
};
const onClickSendAnalytics = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Mainpage_payment_info);
};
</script>
<style lang="scss" scope>
.action-button-not-active {
  display: flex;
  align-items: center;
  padding: 5px 15px;
  min-height: 37px;
}
.head-section-title-amount-order {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}
.head-section-title-amount-order-icon {
  // margin-left: 10px;
  @media (max-width: $size_767) {
    // margin-left: -10px;
  }
}
</style>
