<template>
  <div class="mobile-menu">
    <div>
      <button class="modal-close" type="button" @click="emit('close')">
        &#8592; назад
      </button>
    </div>
    <div class="menu-items content_scroll">
      <div
        v-if="store.currentCompany.dashboard"
        class="menu-item"
        :class="{ 'menu-active': route.name === 'dashboard' }"
        @click="onClickHandleAction('main')"
      >
        главная
      </div>
      <div
        class="menu-item menu-submenu"
        :class="{ 'link-active': route.name === 'companyAnalytic' }"
        :to="{
          name: 'companyAnalytic',
        }"
        @click="onClickHandleAction('analytic')"
      >
        <div class="link-text">мой бизнес</div>
      </div>

      <div
        class="menu-item menu-submenu"
        :class="{ 'menu-active': isDebtsMenuShown }"
        @click="isDebtsMenuShown = !isDebtsMenuShown"
      >
        <div class="link-text">отчетность НДФЛ</div>
        <div v-if="!isDebtsMenuShown" class="icon-arrow-down"></div>
        <div v-if="isDebtsMenuShown" class="icon-arrow-up"></div>
      </div>
      <div v-if="isDebtsMenuShown" class="submenu-items">
        <div class="submenu-item" @click="goToNDFL()">6 НДФЛ</div>
        <div class="submenu-item" @click="goToNDFL()">
          6 НДФЛ (архив отчетов)
        </div>
        <div class="submenu-item" @click="goToNDFL()">
          2 НДФЛ (архив отчетов)
        </div>
      </div>
      <div class="menu-item" @click="onClickHandleAction('invest')">
        <span class="link-text">инвестору</span>
      </div>
    </div>
    <button
      type="button"
      class="current-company-button current-company-wrapper"
      @click="isShowCompaniesMenu = true"
    >
      <div v-if="props.loading" class="d-flex justify-content-center">
        <Loader />
      </div>
      <div v-if="!props.loading" class="current-company-panel">
        <div>
          <div class="current-company-text">
            {{ store.currentCompany.short_title || store.currentCompany.title }}
          </div>
          <div class="current-company-inn">
            инн {{ store.currentCompany.inn }}
          </div>
        </div>
        <div class="icon-arrow"></div>
      </div>
    </button>

    <CompaniesMenuMobile
      v-if="isShowCompaniesMenu"
      @close="isShowCompaniesMenu = false"
    />
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { useStateStore } from '@/stores/stateStore';
import { useRedirection } from '@/server/index';
import { Loader } from 'potok-uikit';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';
import CompaniesMenuMobile from './CompaniesMenuMobile.vue';

const emit = defineEmits(['close']);
const props = defineProps({
  loading: {
    type: Boolean,
    default: false,
  },
});
const {
  SPA_LK_Top_Mainpage_click,
  SPA_LK_Top_NDFL_click,
  SPA_LK_Top_invest_click,
} = TRACKER_EVENTS;

const route = useRoute();
const router = useRouter();
const store = useStateStore();

const isDebtsMenuShown = ref(false);
const isShowCompaniesMenu = ref(false);

const onClickHandleAction = (type) => {
  switch (type) {
    case 'main':
      tracker.queue(tracker.commands.SEND, SPA_LK_Top_Mainpage_click);
      router.push({
        name: 'dashboard',
      });
      emit('close');
      break;
    case 'analytic':
      router.push({
        name: 'companyAnalytic',
      });
      emit('close');
      break;
    case 'ndfl':
      tracker.queue(tracker.commands.SEND, SPA_LK_Top_NDFL_click);
      break;
    case 'invest':
      tracker.queue(tracker.commands.SEND, SPA_LK_Top_invest_click);
      window.open('https://investor.potok.digital/');
      break;
    default:
      break;
  }
};

const goToNDFL = () => {
  useRedirection(
    '/pvp/business/companies/' + store.currentCompany.id + '/ndfl6',
  );
};
</script>
<style lang="scss" scoped>
.mobile-menu {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: white;
  z-index: 1000;
}
.modal-close {
  border: none;
  margin-top: 30px;
  margin-left: 20px;
  width: 76px;
  height: 30px;
  background: $background;
  border-radius: 22px;
  padding: 4px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $dipedive;
}
.menu-items {
  margin-top: 0px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 20px;
}
.menu-item {
  margin-top: 30px;
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: $grey;
}
.menu-active {
  font-weight: 400;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.01em;
  color: $dipedive;
}
.menu-submenu {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.submenu-items {
  padding-left: 15px;
  border-left: 1px solid $dividers;
}
.submenu-item {
  margin-top: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  text-transform: lowercase;
  color: $grey;
}
.current-company-button {
  border: 0;
  background-color: white;
  text-align: left;
}
.current-company-wrapper {
  position: absolute;
  bottom: 0px;
  border-top: 1px solid $dividers;
  padding: 30px 20px 20px 20px;
  width: 100%;
}
.current-company-panel {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.current-company-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $dipedive;
}
.current-company-inn {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $grey;
  margin-top: 4px;
}
.content_scroll {
  height: calc(100vh - 0px);
}
</style>
