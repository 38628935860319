import config from '@/../config';

export default {
  getFounders: {
    url: config.VITE_BASE_URL + `/spa/business/founders`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  patchFounders$id: {
    url: config.VITE_BASE_URL + `/spa/business/person_founders/$id`,
    cache: 'no-cache',
    method: 'PATCH',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
};
