import { inject } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { useFlowManager } from '@/services/FlowManager';

export function useLoginEvents() {
  const store = useStateStore();
  const flowManager = useFlowManager();
  const emitter = inject('emitter');

  emitter.on('login-success', () => {
    store.fetchCompanies({
      expand: ['funds', 'limits'],
      onSuccess: () => flowManager.isGoToDashboard(),
    });
  });

  emitter.on('to-dashboard', () => {
    store.fetchCompanies({
      expand: ['funds', 'limits'],
      onSuccess: () => {
        flowManager.isGoToDashboard();
      },
    });
  });

  emitter.on('order-card-click', (params) => {
    flowManager.isGoToOrderDetails(params);
  });
}
