import { createApp } from 'vue';
import App from './components/App.vue';
import VueCookies from 'vue-cookies';
import router from './router';
import { createPinia } from 'pinia';
import * as Sentry from '@sentry/vue';
import { BrowserTracing } from '@sentry/browser';
import EventBus from '@/services/event-bus/EventBus';
import './assets/bootstrap/bootstrap.min.css';
import config from '@/../config';

import server from './server/index';
import Tracer from '@/libs/browser-tracer';
import {
  // Dropdown,
  // Tooltip,
  Menu,
} from 'floating-vue';

import '../src/assets/fonts/suisse.css';
import 'floating-vue/dist/style.css';

const pinia = createPinia();
const app = createApp(App);

export const tracer = new Tracer(app.config);
app.config.globalProperties.tracer = tracer;

server.onAfterComplete = function (error) {
  if (error instanceof Error) {
    tracer.trace({ error });
  }
};

if (import.meta.env.MODE === 'production') {
  Sentry.init({
    app,
    dsn: config.VITE_SENTRY_DSN,
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['b.potok.digital', /^\//],
      }),
    ],
    release: APP_VERSION, // eslint-disable-line no-undef
    tracePropagationTargets: [/^https:\/\/v3\.potok\.digital/],
    tracesSampleRate: 0.5,
  });
}

app.use(pinia);
app.use(router);
app.use(VueCookies);
app.provide('emitter', EventBus);
// app.component('VDropdown', Dropdown)
// app.component('VTooltip', Tooltip)
app.component('VMenu', Menu);

document.addEventListener('DOMContentLoaded', () => app.mount('#app'));
