<template>
  <div class="container-fluid">
    <div class="container pt-6">
      <Loader v-if="loading" />
      <ErrorItem v-if="errors.length !== 0" v-model="errors" class="ms-4" />
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { Loader } from 'potok-uikit';
import server from '@/server/index';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';

const route = useRoute();
const router = useRouter();

const loading = ref(false);
const autorization_code = ref(null);
const state = ref(null);
const errors = ref([]);

const postEverypaySessions = () => {
  loading.value = true;
  const query = {
    code: autorization_code.value,
    state: state.value,
  };
  return server.postEverypaySessions
    .send(query, {})
    .pipe(
      (obj) => {
        if (!obj) {
          return;
        }
        loading.value = false;
        if (obj.response?.status === 204) {
          router.push({
            name: 'dashboard',
          });
        }
      },
      (obj) => {
        loading.value = false;
        console.log(obj.data.messages);
        errors.value = obj.data.messages;
      },
    )
    .exec();
};

onMounted(() => {
  autorization_code.value = route.query.code;
  state.value = route.query.state;
  postEverypaySessions();
});
</script>
<style lang="scss" scoped></style>
