<template>
  <div class="btn-container">
    <label class="switch btn-color-mode-switch">
      <input
        id="color_mode"
        type="checkbox"
        name="color_mode"
        :value="props.modelValue"
        @change="emit('update:modelValue', $event.target.checked)"
      />
      <label
        for="color_mode"
        :data-on="`юр лица ${countBusinessFounders}`"
        :data-off="`физ лица ${countPersonFounders}`"
        class="btn-color-mode-switch-inner"
      ></label>
    </label>
  </div>
</template>

<script setup>
import { computed } from 'vue';

const emit = defineEmits(['update:modelValue']);
const props = defineProps({
  modelValue: { type: Boolean, required: true },
  ipFoundersArr: {
    type: Object,
    default() {
      return {};
    },
  },
  ulFoundersArr: {
    type: Object,
    default() {
      return {};
    },
  },
});

const countBusinessFounders = computed(() => {
  return props.ulFoundersArr?.length;
});
const countPersonFounders = computed(() => {
  return props.ipFoundersArr?.length;
});
</script>

<style scoped>
div.btn-container {
  width: 100%;
  vertical-align: middle;
  height: 44px;
  border-bottom: 1px solid #dce0e3;
}

.btn-color-mode-switch {
  display: inline-block;
  margin: 0px;
  position: relative;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner {
  margin: 0px;
  width: 220px;
  height: 32px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease;
  display: block;
  margin-bottom: -27px;
  margin-left: -6px;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:before {
  content: attr(data-on);
  cursor: pointer;
  position: absolute;
  top: 5px;
  right: 30px;
  color: #818e99;
}

.btn-color-mode-switch > label.btn-color-mode-switch-inner:after {
  content: attr(data-off);
  width: 100px;
  height: 32px;
  border-bottom: 2px solid #10273d;
  position: absolute;
  left: 6px;
  top: 0px;
  text-align: center;
  transition: all 0.3s ease;
  padding: 4px 0px;
}

.btn-color-mode-switch input[type='checkbox'] {
  cursor: pointer;
  width: 110px;
  height: 44px;
  opacity: 0;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0px;
}

.btn-color-mode-switch
  input[type='checkbox']:checked
  + label.btn-color-mode-switch-inner:after {
  content: attr(data-on);
  left: 112px;
}

.btn-color-mode-switch
  input[type='checkbox']:checked
  + label.btn-color-mode-switch-inner:before {
  content: attr(data-off);
  right: auto;
  left: 20px;
}
</style>
