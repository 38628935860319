<template>
  <div class="container pt-7">
    <div class="form w-100 m-auto">
      <h1 v-if="!isAgent" class="h3 fw-bold text-center">
        завершение регистрации
      </h1>
      <h1 v-if="isAgent" class="h3 fw-bold text-center">создайте пароль</h1>
      <h6 class="fw-normal text-center">
        придумайте пароль для входа на платформу<span v-if="!isAgent"
          >,
          <p>
            вы регистрируетесь с почтой
            <a :href="'mailto:' + email">{{ email }}</a>
          </p></span
        >
      </h6>
      <div class="input-wrapper m-auto">
        <div class="form-row text-center">
          <div class="input-with-icon position-relative">
            <input
              v-model="v$.password.$model"
              :type="fieldType"
              :class="{
                'is-invalid': v$.password.$invalid && v$.password.$dirty,
              }"
              placeholder="пароль"
            />
            <div
              class="icon"
              :class="[showPass ? 'icon-show' : 'icon-hidden']"
              @click="toggleShow()"
            ></div>
          </div>
        </div>
        <div class="pass-tip fw-normal">минимум 8 символов, буквы и цифры</div>
        <div class="form-row text-left">
          <div class="position-relative">
            <input
              v-model="v$.confirmPassword.$model"
              :type="fieldType"
              :class="{
                'is-invalid':
                  v$.confirmPassword.$invalid && v$.confirmPassword.$dirty,
              }"
              :disabled="!v$.password.$model && !v$.confirmPassword.$model"
              placeholder="повторите пароль"
              @keyup.enter="continueRegAsync(token, email, passwords.password)"
            />
          </div>
        </div>
        <div
          v-if="v$.confirmPassword.$invalid && v$.confirmPassword.$dirty"
          class="text-danger"
        >
          пароли не совпадают
        </div>
        <div v-if="errorsArr" class="text-danger">
          <div>{{ errorsArr[0] }}</div>
        </div>
        <div class="form-row mb-4 mt-4">
          <PotokButton
            v-if="!loading"
            :disabled="isDataUnvalid"
            text="зарегистрироваться"
            class="w-100"
            size="large"
            @click="continueRegAsync(token, email, passwords.password)"
          />
          <LoadingBtn v-if="loading" />
        </div>
        <div class="form-row">
          <h6 class="h6 fw-normal text-center">
            <router-link :to="loading ? '' : '/login'">войти</router-link>
          </h6>
        </div>
      </div>
    </div>
    <div v-if="!isAgent" class="text-wrapper m-auto">
      <div class="agreement-text text-center">
        регистрируясь, вы соглашаетесь на обработку
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
          target="_blank"
          >персональных данных</a
        >
        в соответствии с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
          target="_blank"
          >политикой</a
        >, ознакомлены с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Правила Инвестиционной платформы Поток.pdf"
          target="_blank"
          >правилами платформы</a
        >, и согласны с использованием
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Соглашение участников электронного взаимодействия_Инвестплатформа Поток.pdf"
          target="_blank"
          >простой электронной подписи</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import useVuelidate from '@vuelidate/core';
import { helpers, required, minLength, sameAs } from '@vuelidate/validators';
import { ref, computed, onMounted, reactive } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { useRoute, useRouter } from 'vue-router';
import server, { useRedirection } from '@/server/index';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Registration_show_screen_Agent_Cowboy,
  SPA_LK_Magic_Link_Page_View,
  SPA_LK_Magic_Link_success_event,
} = TRACKER_EVENTS;

const store = useStateStore();

const route = useRoute();
const router = useRouter();

const showPass = ref(false);
const loading = ref(false);
const email = ref('');
const token = ref('');
const errorsArr = ref([]);
const product_type = ref('');
const fieldType = ref('password');
const passwords = reactive({
  password: '',
  confirmPassword: '',
});
const isAgent = ref(false);

const passRef = computed(() => passwords.password);

const validations = {
  password: {
    required,
    minLength: minLength(8),
    passwordRequirements: helpers.withMessage(
      () => `Минимум 8 символов, буквы и цифры`,
      (value) => /(?=.*[a-zA-Z])(?=.*[0-9])/.test(value),
    ),
  },
  confirmPassword: {
    required,
    sameAsPassword: sameAs(passRef),
  },
};

const v$ = useVuelidate(validations, passwords);

const isDataUnvalid = computed(() => {
  return (
    v$.value.confirmPassword.$invalid ||
    v$.value.password.$invalid ||
    !email.value ||
    !token.value
  );
});

onMounted(() => {
  getQueryParams(route.query);
  sendAnalytics();
});

const toggleShow = () => {
  if (fieldType.value === 'password') {
    fieldType.value = 'text';
    showPass.value = true;
  } else {
    fieldType.value = 'password';
    showPass.value = false;
  }
};
const sendAnalytics = () => {
  if (product_type.value) {
    tracker.queue(tracker.commands.PARAMS, {
      product_type: product_type.value,
    });
  }
  tracker.queue(
    tracker.commands.SEND,
    isAgent.value
      ? SPA_LK_Registration_show_screen_Agent_Cowboy
      : SPA_LK_Magic_Link_Page_View,
  );
};
const getQueryParams = (query) => {
  if (query.email && query.token) {
    email.value = query.email;
    token.value = query.token;
    query?.product_type ? (product_type.value = query.product_type) : null;
    query?.agent_type === 'commercial'
      ? (isAgent.value = true)
      : (isAgent.value = false);
  } else {
    // router.push('/login');
  }
};
const continueRegAsync = (rawToken, rawEmail, rawPassword) => {
  loading.value = true;
  const query = {
    token: rawToken,
    email: rawEmail,
    password: rawPassword,
  };
  return server.postContinueReg
    .send(query)
    .pipe(onContinueRegAsyncSuccess.bind(this), onContinueRegAsyncError)
    .exec();
};
const onContinueRegAsyncSuccess = (obj) => {
  if (!obj || !obj.response) {
    return;
  }
  store.loginSuccess();
  return server.getUserData
    .send()
    .pipe(onGetUserDataSuccess.bind(this), () => {})
    .exec();
};
const onGetUserDataSuccess = ({ data }) => {
  loading.value = false;
  store.setCurrentUser(data);
  tracker.queue(tracker.commands.SEND, SPA_LK_Magic_Link_success_event);
  data.agent
    ? tracker.queue(tracker.commands.PARAMS, {
        user_type: 'agent',
      })
    : tracker.queue(tracker.commands.PARAMS, {
        user_type: 'generic',
      });
  if (data.confirmed === 'true') {
    useRedirection('/spa/users/sign_in');
  } else {
    isAgent.value
      ? router.push({
          path: '/phone_confirmation_ml',
          query: { agent_type: 'commercial' },
        })
      : router.push('/phone_confirmation_ml');
  }
};
const onContinueRegAsyncError = (error) => {
  loading.value = false;
  if (error.data.messages) {
    error.data.messages.includes('password недопустимый формат')
      ? (errorsArr.value = ['Недопустимый формат пароля'])
      : null;
    error.data.messages.includes('Вы уже прошли регистрацию')
      ? (errorsArr.value = [
          "Вы уже зарегистрировались ранее. Нажмите на 'Войти', чтобы перейти на экран входа.",
        ])
      : (errorsArr.value = error.data.messages);
  }
};
</script>

<style lang="scss" scoped>
.form {
  max-width: 700px;
  h6 {
    margin: 12px 0 30px 0;
    line-height: 22px;
  }
  .input-wrapper {
    max-width: 340px;
    .form-row {
      .input-with-icon {
        .icon {
          background-repeat: no-repeat;
          position: absolute;
          right: 20px;
          top: 24.5px;
          width: 16px;
          height: 16px;
          cursor: pointer;
          &:hover {
            filter: $filter-breakwater;
          }
          &-hidden {
            background: url(@/assets/icons/password/toggle_pass.svg);
          }
          &-show {
            background: url(@/assets/icons/password/toggle_pass_no.svg);
          }
        }
      }
      input {
        @include input;
      }
      .is-invalid {
        border: 1px solid rgba($red, 0.2);
      }
      .row-link {
        margin-bottom: 40px;
        a {
          color: $grey;
          &:hover {
            color: $grey-hover;
          }
        }
      }
    }
    .pass-tip {
      color: $grey;
      margin: 10px 0 15px 0;
    }
  }
}
.text-wrapper {
  max-width: 670px;
  .agreement-text {
    color: $grey;
    margin-top: 113px;
    @media (max-width: $size_767) {
      margin-top: 20px;
      font-size: 10px;
    }
  }
}
</style>
