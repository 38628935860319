<template>
  <div class="banned-content">
    <div class="banned-title mb-4">оформление кредитной линии недоступно</div>
    <div class="potok-text-body-1 text-banned">
      благодарим за регистрацию. По компании обнаружены серьезные негативные
      факторы, препятствующие оформлению кредитной линии и выдаче средств на
      платформе.
    </div>
    <div class="potok-text-h2">
      обратиться в поддержку: <span class="color-tiffany">ok@potok.team</span>
    </div>
  </div>
</template>
<script setup></script>
<style lang="scss" scoped>
.banned-content {
  padding: 28px 20px;
  max-width: 736px;
  @media (max-width: $size_767) {
    background-color: white;
  }
}
.banned-title {
  font-size: 30px;
  line-height: 36px;
  font-weight: 400;
}
.text-banned {
  margin-bottom: 100px;
}
</style>
