<template>
  <div class="modal-overlay" @click="close">
    <div class="modal-alert">
      <div class="modal-container" @click="$event.stopPropagation()">
        <button type="button" class="modal-close" @click="close">
          <span>&times;</span>
        </button>
        <div class="modal-title" v-text="title"></div>
        <div class="modal-text text-center">{{ message }}</div>
        <div v-if="isShowBtnOk" class="mt-3 text-center mx-auto">
          <PotokButton
            class="mx-auto"
            text="ОK"
            size="medium"
            @click="close"
          ></PotokButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import { onMounted, onUnmounted } from 'vue';
import { blockScrollWindow } from '@/utils';

const { disableScroll, enableScroll } = blockScrollWindow();

const props = defineProps({
  message: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  callback: {
    type: Function,
    default: null,
  },
  isShowBtnOk: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['close']);

onMounted(() => {
  disableScroll();
});

onUnmounted(() => {
  enableScroll();
});

const close = () => {
  if (props.callback) {
    props.callback();
  }
  emit('close');
};
</script>

<style lang="scss" scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  white-space: nowrap;
  z-index: 100;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-overlay::after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 100%;
}

.modal-alert {
  display: inline-block;
  vertical-align: middle;
}

.modal-container {
  position: relative;
  margin: 50px;
  padding: 20px;
  min-width: 200px;
  max-width: 500px;
  text-align: left;
  white-space: normal;
  background-color: $white;
  border-radius: 8px;

  @media (max-width: $size_767) {
    margin: 20px;
    min-width: 200px;
  }

  .button {
    width: 183px;
    height: 38px;
    margin-top: 20px;
    line-height: 38px;
  }
}

.modal-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  padding: 0;
  border: none;
  background-color: transparent;
  font-size: 24px;
  line-height: 1;
  color: $grey;
}

.modal-title {
  margin-bottom: 10px;
  font-size: 22px;
}
</style>
