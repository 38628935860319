export default {
  isAuth() {
    return !!this.session.loggedIn;
  },
  isCredentialsExists() {
    return !!(this.currentUser.last_name && this.currentUser.first_name);
  },
  fullName() {
    return (
      [
        this.currentUser.last_name,
        this.currentUser.first_name,
        this.currentUser.patronymic,
      ]
        .filter((it) => !!it)
        .join(' ') || null
    );
  },
  isAgent() {
    return this.currentUser.agent;
  },
  isUserUL() {
    return this.currentUser.ul_type === 'UL';
  },
  isHadOrders() {
    return !!this.currentUser.had_orders;
  },
};
