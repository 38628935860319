import config from '@/../config';

export default {
  getUsersPassports: {
    url: config.VITE_BASE_URL + `/spa/business/users/passports`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  postUsersPassports: {
    url: config.VITE_BASE_URL + `/spa/business/users/passports`,
    cache: 'no-cache',
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
  postSignatures: {
    url: config.VITE_BASE_URL + `/spa/business/signatures`,
    cache: 'no-cache',
    method: 'POST',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
  putSignatures$id: {
    url: config.VITE_BASE_URL + `/spa/business/signatures/$id`,
    cache: 'no-cache',
    method: 'PUT',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  },
};
