<template>
  <div class="d-block d-sm-flex justify-content-start text-nowrap mb-0 mb-sm-6">
    <template v-for="param in params" :key="param.id">
      <div
        v-if="param.visible"
        class="d-flex d-sm-block justify-content-between align-items-center me-0 me-sm-7 py-3 py-sm-0"
        :class="{ 'border-bottom': isMobile && param.id !== params.length }"
      >
        <div
          v-if="param.type !== 'overdue'"
          class="title color-grey mb-0 mb-sm-2 text-uppercase"
        >
          <span>{{ param.title }}&nbsp;</span>
          <span
            v-if="param.type === 'payment' && !isLoanPaymentToday"
            class="text-primary"
            >{{ formatDate(order.loan?.payments?.next_payment?.date) }}</span
          >
        </div>
        <div
          v-if="param.type === 'overdue'"
          class="title mb-0 mb-sm-1 d-flex align-items-center"
        >
          <span class="color-grey me-1 text-uppercase">
            {{ param.title }}
          </span>
          <PopoverIcon
            :position="isMobile ? 'right' : 'left'"
            :minwidth="'250px'"
          >
            <template #icon><span class="question-icon-black"></span></template>
            <template #text>
              <div class="d-flex justify-content-between">
                <div class="color-grey me-7">осн. долг</div>
                <div>
                  {{ formatCurrency(order.loan?.payments?.overdue?.main) }}
                  ₽
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="color-grey">проценты</div>
                <div>
                  {{ formatCurrency(order.loan?.payments?.overdue?.interest) }}
                  ₽
                </div>
              </div>
              <div class="d-flex justify-content-between">
                <div class="color-grey">пеня</div>
                <div>
                  {{ formatCurrency(order.loan?.payments?.overdue?.fine) }}
                  ₽
                </div>
              </div>
              <div
                v-if="store.isBillingMonthlyCommission"
                class="d-flex justify-content-between"
              >
                <div class="color-grey">ежем. комиссия</div>
                <div>
                  {{
                    formatCurrency(
                      order.loan?.payments?.overdue?.monthly_commission,
                    )
                  }}
                  ₽
                </div>
              </div>
            </template>
          </PopoverIcon>
        </div>
        <div>
          <span
            class="fw-bold"
            :class="{
              'text-danger': ['requirement', 'overdue'].includes(param.type),
              'me-2': param.type === 'ndfl',
            }"
            >{{ param.value }}
          </span>
          <PopoverIcon
            v-if="param.type === 'ndfl'"
            :position="isMobile ? 'right' : 'left'"
          >
            <template #icon><span class="question-icon-black"></span></template>
            <template #text
              ><span>сумма будет известна в дату платежа</span></template
            >
          </PopoverIcon>
        </div>
      </div>
    </template>
  </div>
</template>

<script setup>
import { toRefs, computed } from 'vue';
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import { useStateStore } from '@/stores/stateStore';
import { storeToRefs } from 'pinia';
import { formatCurrency, pluralize, formatDate } from '@/utils';
import { calculateAnnualRate } from '@/components/auth/dashboard/loan-terms/utils';
import { detectMobile } from '@/utils';
import constants from '@/constants';

const { isMobile } = detectMobile();
const { LOAN_STATES } = constants;
const store = useStateStore();
const {
  currentCompany,
  isLoanPaymentToday,
  isLoanOverdue,
  isLoanInCession,
  isLoanClosed,
} = storeToRefs(store);

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
});
const { order } = toRefs(props);

const showOnStates = (states) => {
  return !!states.find(
    (state) => order.value.loan?.state === LOAN_STATES[state],
  );
};

const getParamValue = (type) => {
  switch (type) {
    case 'requirement':
      return (
        formatCurrency(
          order.value.loan?.payments?.overdue?.main +
            order.value.loan?.payments?.overdue?.interest +
            order.value.loan?.payments?.overdue?.ndfl +
            order.value.loan?.payments?.overdue?.fine +
            order.value.loan?.payments?.overdue?.fine_tax,
        ) + ' ₽'
      );
    case 'main':
      return isLoanClosed.value
        ? formatCurrency(order.value.loan?.payments?.repayments_amount) + ' ₽'
        : formatCurrency(
            order.value.loan?.payments?.overdue?.main ||
              order.value.loan?.amount,
          ) + ' ₽';
    case 'interest':
      return isLoanInCession.value
        ? formatCurrency(
            order.value.loan?.payments?.overdue?.interest +
              order.value.loan?.payments?.overdue?.ndfl,
          ) + ' ₽'
        : formatCurrency(order.value.loan?.paid_interest_sum) + ' ₽';
    case 'fine':
      return isLoanInCession.value
        ? formatCurrency(
            order.value.loan?.payments?.overdue?.fine +
              order.value.loan?.payments?.overdue?.fine_tax,
          ) + ' ₽'
        : formatCurrency(order.value.loan?.paid_fine_sum) + ' ₽';
    case 'balance':
      return formatCurrency(currentCompany.value.funds?.current_balance) + ' ₽';
    case 'overdue':
      return (
        formatCurrency(
          order.value.loan?.payments?.overdue?.main +
            order.value.loan?.payments?.overdue?.interest +
            order.value.loan?.payments?.overdue?.fine +
            (store.isBillingMonthlyCommission
              ? order.value.loan?.payments?.overdue?.monthly_commission
              : 0),
        ) + ' ₽'
      );
    case 'payment': {
      let payment;
      if (isLoanPaymentToday.value) {
        payment =
          order.value.loan?.payments?.next_payment?.main +
          order.value.loan?.payments?.next_payment?.interest;
      } else {
        payment =
          order.value.loan?.payments?.next_payment?.main +
          order.value.loan?.payments?.next_payment?.interest +
          order.value.loan?.payments?.next_payment?.ndfl;
      }

      payment += order.value.loan?.payments?.next_payment?.monthly_commission;
      return formatCurrency(payment) + ' ₽';
    }
    case 'ndfl':
      return isLoanPaymentToday.value || isLoanOverdue.value
        ? formatCurrency(order.value.loan?.payments?.next_payment?.ndfl) + ' ₽'
        : 'неизвестен';
    case 'commission':
      return isLoanOverdue.value
        ? formatCurrency(
            order.value.loan?.payments?.overdue?.monthly_commission +
              order.value.loan?.payments?.next_payment?.monthly_commission,
          ) + ' ₽'
        : formatCurrency(
            order.value.loan?.payments?.next_payment?.monthly_commission,
          ) + ' ₽';
    case 'period':
      return (
        order.value.loan?.period_months_count +
        ' месяц' +
        pluralize(order.value.loan?.period_months_count, ['', 'а', 'ев'])
      );
    case 'rate':
      return (
        (order.value.rate ||
          calculateAnnualRate(
            order.value.loan?.period_months_count,
            order.value.loan_terms_offer?.terms,
          )) + '%'
      );
    default:
      break;
  }
};

const params = computed(() => {
  return [
    {
      id: 1,
      type: 'requirement',
      title: 'требование',
      value: getParamValue('requirement'),
      visible: showOnStates([LOAN_STATES.in_cession]),
    },
    {
      id: 2,
      type: 'main',
      title: 'основной долг',
      value: getParamValue('main'),
      visible: showOnStates([
        LOAN_STATES.in_cession,
        LOAN_STATES.expired,
        LOAN_STATES.repaid,
        LOAN_STATES.investing,
        LOAN_STATES.invested,
      ]),
    },
    {
      id: 3,
      type: 'interest',
      title: 'проценты',
      value: getParamValue('interest'),
      visible: showOnStates([
        LOAN_STATES.in_cession,
        LOAN_STATES.expired,
        LOAN_STATES.repaid,
      ]),
    },
    {
      id: 4,
      type: 'fine',
      title: 'пеня',
      value: getParamValue('fine'),
      visible: showOnStates([
        LOAN_STATES.in_cession,
        LOAN_STATES.expired,
        LOAN_STATES.repaid,
      ]),
    },
    {
      id: 5,
      type: 'balance',
      title: 'баланс',
      value: getParamValue('balance'),
      visible: showOnStates([LOAN_STATES.billing, LOAN_STATES.overdue]),
    },
    {
      id: 6,
      type: 'overdue',
      title: 'сумма просрочки',
      value: getParamValue('overdue'),
      visible: showOnStates([LOAN_STATES.overdue]),
    },
    {
      id: 7,
      type: 'payment',
      title: isLoanPaymentToday.value ? 'к оплате сегодня' : 'платеж',
      value: getParamValue('payment'),
      visible: showOnStates([LOAN_STATES.billing, LOAN_STATES.overdue]),
    },
    {
      id: 8,
      type: 'ndfl',
      title: 'НДФЛ к оплате в ФНС',
      value: getParamValue('ndfl'),
      visible: showOnStates([
        LOAN_STATES.billing,
        LOAN_STATES.overdue,
        LOAN_STATES.in_default,
      ]),
    },
    {
      id: 9,
      type: 'commission',
      title: 'комиссия',
      value: getParamValue('commission'),
      visible:
        showOnStates([
          LOAN_STATES.billing,
          LOAN_STATES.overdue,
          LOAN_STATES.in_default,
        ]) &&
        (order.value.loan?.payments?.next_payment?.monthly_commission ||
          order.value.loan?.payments?.overdue?.monthly_commission) &&
        !store.isBillingMonthlyCommission,
    },
    {
      id: 10,
      type: 'period',
      title: 'срок',
      value: getParamValue('period'),
      visible: !showOnStates([
        LOAN_STATES.in_cession,
        LOAN_STATES.overdue,
        LOAN_STATES.in_default,
      ]),
    },
    {
      id: 11,
      type: 'rate',
      title: 'ставка',
      value: getParamValue('rate'),
      visible: !showOnStates([LOAN_STATES.in_cession]),
    },
  ];
});
</script>

<style lang="scss" scoped>
.title {
  font-size: 8px;
  @media (max-width: $size_575) {
    font-size: 14px;
  }
}
</style>
