<template>
  <div class="container pt-7">
    <div class="form w-100 m-auto">
      <h1 class="h3 fw-bold text-center me-2">какой компании нужен заём?</h1>
      <h6 class="fw-normal text-center me-2 lh-base">
        введите инн компании
        <p>или индивидуального предпринимателя</p>
      </h6>
      <div class="input-wrapper m-auto">
        <div class="form-row text-center">
          <input
            id="inn"
            v-model="data.inn"
            v-maska
            type="text"
            :class="{
              'is-invalid': v$.inn.$invalid && v$.inn.$dirty,
            }"
            :data-maska="masks.inn()"
            placeholder="инн"
            @input="debouncedFn()"
            @keyup.enter="postInnAsync(data.inn)"
          />
        </div>
        <SuggestionList
          v-if="suggestionsArr.length !== 0"
          v-model="suggestionsArr"
          class="mt-2"
          width="340px"
          :keys-in-arr="['name', 'inn']"
          @choose-suggestion="chooseSuggestion"
        />
        <ErrorItem v-if="errorsArr.length !== 0" v-model="errorsArr" />
        <div class="form-row mb-4 mt-5">
          <PotokButton
            v-if="!loading"
            id="continue"
            class="btn-continue w-100"
            text="продолжить"
            size="large"
            @click="postInnAsync(data.inn)"
          />
          <LoadingBtn v-if="loading" />
        </div>
        <h6
          class="row-link fw-normal text-center"
          @click="loading ? null : router.push('/login')"
        >
          назад
        </h6>
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import SuggestionList from '@/components/_generic/ui/widgets/SuggestionList.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import AskUserPhone from './AskUserPhone.vue';
import useVuelidate from '@vuelidate/core';
import masks from '@/libs/js-text-masks';
import { vMaska } from 'maska';
import { required } from '@vuelidate/validators';
import { ref, reactive, onMounted } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { useStateStore } from '@/stores/stateStore';
import { useDebounceFn } from '@vueuse/core';
import { filter } from '@/utils';
import server from '@/server/index';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Registration_INN_show_screen } = TRACKER_EVENTS;

const route = useRoute();
const router = useRouter();
const store = useStateStore();

const loading = ref(false);
const data = reactive({
  inn: '',
});
const suggestionsArr = ref([]);
const errorsArr = ref([]);
const referLink = ref('');

const emit = defineEmits(['change-component']);

onMounted(() => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Registration_INN_show_screen);
  route.query?.ref ? (referLink.value = route.query?.ref) : null;
  route.query?.user_step === 'ask_user_phone'
    ? emit('change-component', AskUserPhone)
    : grabAndMergeAndCommitUtms();
});

const validations = {
  inn: {
    required,
  },
};

const v$ = useVuelidate(validations, data);

const debouncedFn = useDebounceFn(() => {
  postDadataInnAsync();
}, 500);
const postDadataInnAsync = () => {
  let isNum = /^\d+$/.test(data.inn);
  if (
    data.inn.toString().length !== 10 &&
    data.inn.toString().length !== 12 &&
    isNum
  ) {
    const query = {
      query: data.inn,
      status: ['ACTIVE'],
    };
    loading.value = true;
    return server.postDadataInn
      .send(query)
      .pipe(onPostDadataInnAsyncSuccess, onPostDadataInnAsyncError)
      .exec();
  }
};
const onPostDadataInnAsyncSuccess = ({ data }) => {
  const response = Array.isArray(data.suggestions) ? data.suggestions : [];
  response.forEach(function (array_item, index) {
    suggestionsArr.value[index] = {
      inn: array_item.data.inn,
      name: array_item.data.name.full_with_opf,
    };
  });
  loading.value = false;
};
const onPostDadataInnAsyncError = () => {
  loading.value = false;
};
const chooseSuggestion = (value) => {
  data.inn = value.inn;
};
const grabAndMergeAndCommitUtms = () => {
  if (route.query) {
    const utms = filter(route.query, (_, key) => key.startsWith('utm_'));
    store.setUTMs(utms);
  }
};
const postInnAsync = (rawInn) => {
  if (v$.value.inn.$invalid) {
    v$.value.$touch();
  } else {
    loading.value = true;
    const query = {
      order: {
        inn: rawInn.toString(),
        utm_data: {
          utm_tags: store.utms,
        },
        ref: referLink.value,
      },
    };
    return server.postInn
      .send(query)
      .pipe(onPostInnAsyncSuccess, onPostInnAsyncError)
      .exec();
  }
};
const onPostInnAsyncSuccess = () => {
  loading.value = false;
  emit('change-component', AskUserPhone);
};
const onPostInnAsyncError = (error) => {
  const response = Array.isArray(error.data?.messages)
    ? error.data?.messages
    : [];
  response.some((el) => el.includes('уже зарегистрирована'))
    ? router.push({
        name: 'login',
        query: { email: error?.data?.masked_email },
      })
    : (errorsArr.value = response);
  loading.value = false;
};
</script>

<style lang="scss" scoped>
.btn-continue {
  margin: auto;
}
.form {
  max-width: 450px;
  h6 {
    margin: 12px 0 30px 0;
  }
  .input-wrapper {
    max-width: 340px;
    .form-row {
      input {
        @include input;
      }
      .is-invalid {
        border: 1px solid rgba($red, 0.2);
      }
    }
    .row-link {
      cursor: pointer;
      color: $grey;
      &:hover {
        color: $breakwater;
      }
    }
  }
}
</style>
