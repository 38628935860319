import { format } from 'date-fns';
import { copyAsJson } from '@/libs/utils.js';

/**
 * @param {Object} retry
 * @param {Deferred} deferred
 * @param {Request} reqOpts
 */
function request(retry, deferred, reqOpts) {
  const { delays, retries, statuses } = retry;
  const currentRetry = { retries: retries - 1, delays, statuses };
  const onError = onRequestError.bind(
    undefined,
    currentRetry,
    deferred,
    reqOpts,
  );
  // The server can respond with successful but with 502 status code
  const onSuccess = function (response) {
    const fn = response.ok ? deferred.resolve : onError;
    fn(response);
  };
  /*
    IMPORTANT NOTE

    req.clone() doesn't work in FF for multipart/form-data requests with files
    so we are forced to use this way for cloning

    https://developer.mozilla.org/en-US/docs/Web/API/Request/Request
  */
  window.fetch(reqOpts.url, reqOpts).then(onSuccess, onError);
}

/**
 * @param {Object} retry
 * @param {Deferred} deferred
 * @param {Request} reqOpts
 * @param {Response|Error} errOrRes
 */
function onRequestError(retry, deferred, reqOpts, errOrRes) {
  const { delays, retries, statuses } = retry;
  if (retries >= 0 && shouldRetryAgain(errOrRes, statuses)) {
    const delay = getRandomDelay(delays[retries]);
    // eslint-disable-next-line no-prototype-builtins, no-unused-vars
    const logEntry = {
      timestamp: format(new Date(), 'yyyy-MM-dd HH:mm:ss'),
      retries: retries + 1,
      delay,
      req: [reqOpts.method, reqOpts.url].join(' '),
      res: String(errOrRes),
    };
    wait(delay).then(() => request(retry, deferred, reqOpts));
  } else {
    deferred.reject(errOrRes);
  }
}

/**
 * @param {Number} ms
 * @returns {Promise.<*>}
 */
function wait(ms) {
  return new Promise((resolve) => window.setTimeout(resolve, ms));
}
/**
 * @param {Response|Error} errOrRes
 * @param {Array.<Number>} statuses
 * @returns {Boolean}
 */
function shouldRetryAgain(errOrRes, statuses) {
  return errOrRes instanceof Error || statuses.indexOf(errOrRes.status) !== -1;
}
/**
 * @param {Number} minVal
 * @return {Number}
 */
function getRandomDelay(minVal) {
  const maxVal = Math.round(minVal * defaultOptions.varianceFactor);
  return getRandomIntInclusive(minVal, maxVal);
}
/**
 * @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/random
 * @param {Number} min
 * @param {Number} max
 * @return {Number}
 */
function getRandomIntInclusive(_min, _max) {
  const min = Math.ceil(_min);
  const max = Math.floor(_max);
  // The maximum is inclusive and the minimum is inclusive
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

class Deferred {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
  resolve() {}
  reject() {}
}

const defaultOptions = {
  retries: 1,
  delays: [1000],
  statuses: [502, 503],
  varianceFactor: 1.25,
};

const _defaultOptions = defaultOptions;
export { _defaultOptions as defaultOptions };

/**
 * @param {String} url
 * @param {Object} fetchOptions
 * @param {Object} retryOptions
 * @returns {Promise.<*>}
 */
export function fetchRetry(url, fetchOptions, retryOptions = {}) {
  const mergedOptions = Object.assign(
    copyAsJson(defaultOptions),
    copyAsJson(retryOptions),
  );
  const { delays, retries, statuses } = mergedOptions;
  const retry = { delays: delays.reverse(), retries, statuses };
  const reqOpts = Object.assign({ url }, fetchOptions);
  const deferred = new Deferred();
  request(retry, deferred, reqOpts);
  return deferred.promise;
}
