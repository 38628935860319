/**
 * @param {Response} response
 * @returns {String}
 */
const getResponseType = function (response) {
  const typeAndCharset = response.headers.get('content-type') || '';
  const idx = typeAndCharset.indexOf(';');
  const type = idx !== -1 ? typeAndCharset.slice(0, idx) : typeAndCharset;
  switch (type) {
    case 'application/json':
      return 'json';
    case 'text/plain':
    case 'text/html':
      return 'text';
    default:
      return 'blob';
  }
};
const _getResponseType = getResponseType;
export { _getResponseType as getResponseType };

/**
 * @param {Response} response
 * @returns {Promise.<Blob|Object|String>}
 */
export function parseResponseBody(response) {
  const type = getResponseType(response);
  switch (type) {
    case 'json':
      if (response.status === 204) {
        return response;
      }
      return response.json();
    case 'text':
      return response.text();
    default:
      return response.blob();
  }
}
/**
 * @param {String} string
 * @returns {Object|null}
 */
export function parseAsJson(string) {
  try {
    return JSON.parse(string);
  } catch (any) {
    return null;
  }
}
/**
 * @param {*} any
 * @returns {Boolean}
 */
export function isNull(any) {
  return any === null;
}

export default {
  getResponseType,
  parseResponseBody,
  parseAsJson,
  isNull,
};
