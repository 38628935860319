<template>
  <div class="d-flex flex-wrap">
    <div
      class="status-label ms-2 bg-opacity-10"
      :class="{
        'text-tiffany bg-tiffany': state === LOAN_STATES.billing,
        'text-primary bg-grey':
          !REJECTED_STATES.includes(state) && state !== LOAN_STATES.billing,
        'text-danger bg-danger': REJECTED_STATES.includes(state),
      }"
    >
      {{ ORDER_STATES_RU[state] || getLoanStatesRu(isTypeOrder)?.[state] }}
    </div>

    <div
      v-if="order?.agent_order"
      class="status-label ms-2 bg-opacity-10 text-primary bg-grey"
    >
      агентская заявка
    </div>
  </div>
</template>

<script setup>
import constants from '@/constants';
import { toRefs, computed } from 'vue';

const { LOAN_STATES, ORDER_STATES, getLoanStatesRu, ORDER_STATES_RU } =
  constants;

const REJECTED_STATES = [
  LOAN_STATES.cancelled,
  LOAN_STATES.overdue,
  LOAN_STATES.in_cession,
  LOAN_STATES.in_default,
  LOAN_STATES.expired,
  ORDER_STATES.rejected,
  ORDER_STATES.self_destructed,
];
const props = defineProps({
  isTypeOrder: {
    type: Boolean,
    default: true,
    required: false,
  },
  order: {
    type: Object,
    required: true,
  },
});
const { isTypeOrder, order } = toRefs(props);

const state = computed(() => {
  return order.value?.loan?.state || order.value?.state;
});
</script>

<style lang="scss" scoped>
.status-label {
  height: 24px;
  font-size: 8px;
  padding: 5px 8px 4px 8px;
  border-radius: 6px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  align-items: center;
}
</style>
