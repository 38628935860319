<template>
  <h1 class="fw-bold text-center">выписки содержат ошибки</h1>
  <h6 class="fw-normal mb-sm-6 mb-5 text-center lh-base">
    загруженные выписки содержат ошибки,
    <p>мы не можем использовать их для анализа финансов</p>
  </h6>
  <div
    :class="{
      'scroll-wrapper': props.files.length > 2,
    }"
  >
    <template v-for="(file, index) in props.files" :key="index">
      <div
        class="form-row-after-item"
        @mouseover="showTrash = index"
        @mouseleave="showTrash = null"
      >
        <div class="d-flex align-items-center">
          <i class="upload-icon-fail"></i>
          <div style="height: 40px; font-size: 14px">
            <span class="ms-4 d-flex align-items-center"
              ><span class="file-name">{{
                file?.name || file?.response?.filename
              }}</span>
              <span v-if="file?.size && !isMobile"
                >- {{ formatSize(file.size) }} -</span
              >
              <span v-if="file?.size && !isMobile"
                >&nbsp;{{ file.progress }} %</span
              >
            </span>
            <span
              v-if="
                file?.response?.errors?.[0] ||
                file?.response?.error?.messages?.[0]
              "
              class="error-hint"
              @click="
                emit(
                  'emit-file-info',
                  file?.name,
                  file?.response?.errors?.[0] ||
                    file?.response?.error?.messages?.[0],
                  file?.response?.suggestions?.[0],
                )
              "
              ><span class="d-sm-inline d-none"
                >здесь есть ошибки, подробнее &#8594;</span
              ><span class="d-sm-none d-inline"
                >смотреть ошибки &#8594;</span
              ></span
            >
          </div>
        </div>
        <span class="row-text-line-after"></span>
      </div>
    </template>
  </div>
  <div class="form-row-after mb-4 position-relative">
    <div class="upload-wrapper position-absolute">
      <PotokButton
        text="загрузить новые файлы"
        size="medium"
        @click="clearErrorsArr()"
      />
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import { ref, onMounted } from 'vue';
import { formatSize } from '../utils';
import tracker from '@/tracker';
import { detectMobile } from '@/utils';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Vipiska_show_screen_all_problems,
  SPA_LK_Vipiska_all_problems_click_try_again,
} = TRACKER_EVENTS;

const { isMobile } = detectMobile();

const emit = defineEmits(['emit-file-info', 'clear-errors-arr']);
const props = defineProps({
  files: {
    type: Array,
    default() {
      return [];
    },
  },
});

const showTrash = ref(null);

onMounted(() => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Vipiska_show_screen_all_problems);
});

const clearErrorsArr = () => {
  emit('clear-errors-arr');
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Vipiska_all_problems_click_try_again,
  );
};
</script>

<style lang="scss" scoped>
h1 {
  font-size: 30px;
  margin-bottom: 12px;
  @media (max-width: $size_575) {
    font-size: 20px;
    line-height: 26px;
  }
}
h6 {
  @media (max-width: $size_575) {
    font-size: 14px;
    line-height: 22px;
  }
}
.form-row-after-item {
  height: 90px;
  background: $background;
  border-radius: 20px 20px 0px 0px;
  padding: 20px 20px 20px 20px;
  position: relative;
  .upload-icon-fail {
    min-width: 60px;
    height: 60px;
    background-image: url(@/assets/icons/files/file-uploads-sm-fail.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
  .trash-icon {
    position: absolute;
    right: 20px;
    top: 33px;
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/trash-icon.svg);
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
  }
  .file-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    width: 130px;
  }
  .error-hint {
    position: absolute;
    left: 100px;
    bottom: 20px;
    color: red;
    &:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
  .row-text-line-after {
    position: absolute;
    bottom: 0;
    left: 5%;
    display: block;
    width: 90%;
    background-color: $grey-hover;
    border: 0.5px solid $grey-hover;
    opacity: 0.2;
  }
}
.form-row-after-item:not(:first-child) {
  border-radius: 0px 0px 0px 0px;
  box-shadow: none;
}
.form-row-after {
  height: 90px;
  background: $background;
  border-radius: 0px 0px 20px 20px;
  padding: 20px 20px 20px 20px;
  .upload-wrapper {
    width: 222px;
    right: 20px;
  }
}
// Custom scrollbar for all browsers
.scroll-wrapper {
  height: 180px;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-track {
  background: $background;
  margin-top: 20px;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: $grey;
  border-radius: 10px;
}
</style>
