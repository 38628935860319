const phone = function () {
  return ['+7 (###) ###-##-##'];
};

const digits = function (n) {
  return new Array(n).fill('#').join('');
};

const passport = {
  series: function () {
    return digits(4);
  },
  number: function () {
    return digits(6);
  },
  department_code: function () {
    return '###-###';
  },
  issued_date: function () {
    return '##-##-####';
  },
  birthday: function () {
    return '##-##-####';
  },
};

const inn = function () {
  return '############';
};

const bankAccount = function () {
  return '##### ### # #### #######';
};

const rate = function () {
  return '##.##';
};

export default {
  phone,
  inn,
  passport,
  digits,
  bankAccount,
  rate,
};
