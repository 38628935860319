<template>
  <template v-if="store.isNewBorrowerBankAccount">
    <OnClickOutside @trigger="isShowSelectList = false">
      <div class="accounts-panel">
        <Loader v-if="loading" />
        <template v-if="!loading">
          <div
            class="select"
            :class="{ 'cursor-pointer': bankAccounts.length }"
            @click="onClickSelect"
          >
            <div class="select-wrapper">
              <div
                class="selected-content"
                :class="{ 'color-grey': !selectedValue }"
              >
                <div>{{ formatedSelectedValue }}</div>
              </div>
              <div
                :class="{
                  'icon-select-arrow-down': !isShowSelectList,
                  'icon-select-arrow-up': isShowSelectList,
                }"
              ></div>
            </div>
          </div>

          <div
            v-if="isShowSelectList && bankAccounts.length"
            class="select-list"
          >
            <div
              v-for="(item, index) in bankAccounts"
              :key="index"
              class="select-list-item"
              @click="onClickSelectedValue(item)"
            >
              <div>{{ formatedValue(item) }}</div>
            </div>
          </div>
        </template>
      </div>
    </OnClickOutside>
  </template>
  <template v-if="!store.isNewBorrowerBankAccount">
    <div class="bg-background rounded-3 p-4">
      <span v-if="store.currentOrder.borrower_bank_account"
        >деньги поступят на {{ store.currentOrder.borrower_bank_name }} —
        {{ mask.masked(store.currentOrder.borrower_bank_account) }}</span
      >
      <span
        v-if="!store.currentOrder.borrower_bank_account"
        class="default-text"
        >счет для зачисления транша появится после завершения регистрации</span
      >
    </div>
  </template>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import { Mask } from 'maska';
import masks from '@/libs/js-text-masks';
import server from '@/server/index';
import { Loader } from 'potok-uikit';
import { OnClickOutside } from '@vueuse/components';
import { useStateStore } from '@/stores/stateStore';

const mask = new Mask({ mask: masks.bankAccount });
const loading = ref(false);
const bankAccounts = ref([]);
const selectedValue = ref(null);
const isShowSelectList = ref(false);
const inited = ref(false);
const store = useStateStore();

const defaultText = computed(() => {
  if (
    inited.value &&
    (!bankAccounts.value || bankAccounts.value.length === 0)
  ) {
    return 'счет для зачисления транша появится после завершения регистрации';
  }
  return 'выберите расчетный счет для зачисления транша';
});

const formatedSelectedValue = computed(() => {
  if (!selectedValue.value) {
    return defaultText.value;
  }
  return (
    selectedValue.value.bank_name + ' — ' + mask.masked(selectedValue.value.ean)
  );
});

const getBankAccouts = () => {
  loading.value = true;

  server.getBankAccounts
    .send()
    .pipe(onGetBankAccountsSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onGetBankAccountsSuccess = ({ data }) => {
  inited.value = true;
  loading.value = false;
  bankAccounts.value = data;

  if (bankAccounts.value.length) {
    selectedValue.value = bankAccounts.value.find((i) => i.current === true);
  }
};

const putBankAccouts = (item) => {
  loading.value = true;
  const query = {
    bank_account: {
      current: true,
    },
  };
  server.putBankAccount
    .send(query, {
      params: {
        bank_account_id: item.id,
      },
    })
    .pipe(
      () => {
        loading.value = false;
      },
      () => {
        loading.value = false;
      },
    )
    .exec();
};

const onClickSelect = () => {
  isShowSelectList.value = !isShowSelectList.value;
};

const onClickSelectedValue = (item) => {
  selectedValue.value = item;
  isShowSelectList.value = false;
  putBankAccouts(item);
};

const formatedValue = (item) => {
  return item.bank_name + ' — ' + mask.masked(item.ean);
};

onMounted(() => {
  if (store.isNewBorrowerBankAccount) {
    getBankAccouts();
  }
});
</script>
<style lang="scss" scoped>
.accounts-panel {
  min-height: 60px;
  position: relative;
  padding: 18px 25px 18px 20px;
  border-radius: 8px;
  background-color: #f2f4f8;
}

.default-text {
  color: #818e99;
}

.white-block {
  padding: 0 !important;
  margin-bottom: 0 !important;
}

.select-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.select-list {
  position: absolute;
  top: 70px;
  left: 0;
  min-height: 30px;
  width: 100%;
  background-color: white;
  box-shadow: 0px 2px 22px rgba(13, 39, 65, 0.12);
  border-radius: 12px;
  z-index: 100;
  padding: 6px;
  max-height: 250px;
  overflow-y: auto;
}

.select-list-item {
  padding: 10px 10px;
  cursor: pointer;

  &:hover {
    background-color: $background;
    border-radius: 8px;
  }
}
</style>
