'use strict';

import {
  request,
  extend,
  isUndefined,
  formatUrlWithNamedParams,
  next,
  wrapCallback,
} from '../lang';
import PromisePipeline from './PromisePipeline';
const SERVER_REF = Symbol('server');

export default class Action {
  /**
   * @param {Object} options
   * @param {Object} server
   */
  constructor(options, server) {
    // It prevents merging the cross references with Vue
    this[SERVER_REF] = server;
    this.options = options;
    this.isPending = false;
    this.onComplete = this.onComplete.bind(this);
  }
  /**
   * @param {*} body
   * @returns {Promise.<T>}
   */
  get(body) {
    return this.send(body, { method: 'GET' });
  }
  /**
   * @param {*} body
   * @returns {Promise.<T>}
   */
  post(body) {
    return this.send(body, { method: 'POST' });
  }
  /**
   * @param {*} body
   * @param {Object} [options]
   * @returns {Promise.<T>}
   */
  send(body, options) {
    const { onComplete } = this;

    let opts = extend({}, this[SERVER_REF].options, this.options);
    opts = isUndefined(body) ? opts : extend(opts, { body });
    opts = isUndefined(options) ? opts : extend(opts, options);
    const fetchFn = this.constructor.fetch();
    opts.url = formatUrlWithNamedParams(opts.url, opts.params);

    this[SERVER_REF].onBeforeSend(opts);
    this.onBeforeSend(opts);

    const pipeline = new PromisePipeline(() => {
      const requestFn = this.constructor.request();
      return requestFn(opts.url, opts, fetchFn);
    });

    for (const pipe of this[SERVER_REF].pipes) {
      pipeline.pipe(pipe[0], pipe[1]);
    }
    this.isPending = true;
    return pipeline
      .pipe(onComplete, onComplete)
      .pipe(onComplete, wrapCallback(this[SERVER_REF].onError))
      .pipe(
        wrapCallback(this[SERVER_REF].onAfterComplete),
        wrapCallback(this[SERVER_REF].onAfterComplete),
      );

    // pipe(parseResponse,parseResponse)
    // pipe(throwErrorWhenItHappened,throwErrorWhenItHappened)
    // pipe(appendLogToDevTools,appendLogToDevTools)
    // pipe(onComplete,onComplete)
    // pipe(onComplete,wrapCallback(this[SERVER_REF].onError))
    // pipe(Success,Error = next) Обработка в компонете, если нет обработчика Error,
    // то выполняется Next, который пробрасывает Error в следующий pipe
    // pipe(null, Error = wrapperError) Усли Error не был обработан в компоненте, то выполняется wrapperError
  }
  /**
   * @param options
   */
  onBeforeSend() {}
  /**
   * @param {*} response
   */
  onComplete(response) {
    this.isPending = false;
    return next(response);
  }
  /**
   * DI
   * @returns {Function}
   */
  static fetch() {
    return (window.jQuery && window.jQuery.ajax) || window.fetch;
  }
  /**
   * DI
   * @returns {Function}
   */
  static request() {
    return request;
  }
}
