<template>
  <button
    v-if="isShowOrderBtn"
    :class="{
      hovering: isHovering,
      'color-dipedive': !isHovering,
      'order-btn-mobile d-block text-center': isMobile,
      'order-btn ms-2 small': !isMobile,
    }"
    @click="onClickGoToDetails()"
  >
    {{ continueText(order.loan?.state || order.state) }}
  </button>
</template>

<script setup>
import constants from '@/constants';
import tracker from '@/tracker';
import { detectMobile } from '@/utils';
import { computed, inject, toRefs } from 'vue';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Mainpage_order_click } = TRACKER_EVENTS;

const { isMobile } = detectMobile();
const emitter = inject('emitter');
const { ORDER_STATES } = constants;

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
  isHovering: {
    type: Boolean,
  },
});
const { isHovering, order } = toRefs(props);

const isShowOrderBtn = computed(() => {
  return !constants.SCORING_STATES.includes(order.value.state);
});

const onClickGoToDetails = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Mainpage_order_click);
  emitter.emit('order-card-click', order.value);
};
const continueText = (type) => {
  switch (type) {
    case ORDER_STATES.user_verifying:
    case ORDER_STATES.founders_assessing:
    case ORDER_STATES.filling_full_borrower_profile:
    case ORDER_STATES.done_decision_system_statements:
    case ORDER_STATES.filling_borrower_profile:
    case ORDER_STATES.statement_file_assessing:
    case ORDER_STATES.done_decision_system_inn:
    case ORDER_STATES.custom_params_asking:
      return 'оформить \u2192';
    case ORDER_STATES.user_destructed:
      return '';
    default:
      return 'подробнее \u2192';
  }
};
</script>

<style lang="scss" scoped>
.order-btn {
  background-color: transparent;
  border: none;
  padding: 6px 12px;
  &:hover {
    color: $white;
    background-color: $primary;
    border-radius: 50px;
  }
}
.hovering {
  color: $white;
  background-color: $primary;
  border-radius: 50px;
}
.order-btn-mobile {
  @include button-primary;
  white-space: nowrap;
  padding: 10px 18px;
  height: 44px;
  &:hover {
    background-color: $primary;
    color: $white;
  }
}
</style>
