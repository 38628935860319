<template>
  <div class="container pt-7 mb-7">
    <div class="form m-auto">
      <h1 class="h3 fw-bold mb-6 text-center">
        проверьте и дополните паспортные данные
      </h1>
      <div class="inputs-container mb-2">
        <div
          v-for="(input, index) in inputFields"
          :key="index"
          class="input-box"
        >
          <input
            type="text"
            :class="{
              'text-danger':
                invalidData &&
                input.name === 'passport' &&
                !isPassportFullFileld(store.currentUserPassport),
            }"
            :disabled="input.name !== 'passport'"
            :readonly="input.name === 'passport'"
            :value="inputFields[index].value"
            @click="onClickGoToComponent(input.name)"
          />
          <span class="placeholder">{{ input.title }}</span>
          <div
            v-if="input.name === 'passport'"
            class="text-fill"
            :class="{
              'text-primary':
                isPassportFullFileld(store.currentUserPassport) && !invalidData,
              'text-danger': invalidData || props.error,
            }"
            @click="onClickGoToComponent(input.name)"
          >
            <span
              v-if="
                isPassportFullFileld(store.currentUserPassport) && !invalidData
              "
              >редактировать &#8594;</span
            >
            <span v-else>заполнить &#8594;</span>
          </div>
          <i
            v-if="input.value && input.name !== 'passport'"
            class="icon icon-correct"
          ></i>
          <hr v-if="index !== inputFields.length - 1" class="divider mb-2" />
        </div>
      </div>
      <span v-if="props.error" class="text-danger">{{ props.error }}</span>

      <div class="d-flex mt-4 mb-5 align-items-center position-relative">
        <Checkbox v-model:isChecked="isConfirmCondition"
          ><template #default
            ><div class="potok-text-body-3 color-grey">
              Подтверждаю, что не являюсь публичным

              <PopoverIcon :minwidth="'350px'">
                <template #icon
                  ><span class="color-dipedive"
                    >должностным лицом</span
                  ></template
                >
                <template #text>
                  ДЛПМО — должностные лица публичных международных организаций
                  <br />
                  ИПДЛ — иностранные публичные должностные лица <br />
                  РПДЛ — лица, замещающие (занимающие) государственные должности
                  РФ
                </template>
              </PopoverIcon>

              и/или родственником такого лица. Подтверждаю, что являюсь
              бенефициарным владельцем,
              <PopoverIcon :minwidth="'350px'">
                <template #icon
                  ><span class="color-dipedive"
                    >выгодоприобретатели</span
                  ></template
                >
                <template #text>
                  лица, к выгоде которых действует заемщик, в том числе на
                  основании агентского договора, договоров поручения, комиссии и
                  доверительного управления, при проведении операций с денежными
                  средствами и иным имуществом] отсутствуют
                </template>
              </PopoverIcon>
              отсутствуют.
            </div></template
          ></Checkbox
        >
      </div>

      <PotokButton
        v-if="!props.loading"
        class="mb-4 mt-5 w-100"
        :disabled="leftTime !== 0"
        size="large"
        @click="onClickSignAgreement()"
        ><template #default
          ><span v-if="leftTime === 0">подписать анкету</span>
          <span v-if="leftTime !== 0"
            >отправить заново через {{ leftTime }} секунд{{
              pluralize(leftTime, ['у', 'ы', ''])
            }}</span
          ></template
        ></PotokButton
      >
      <LoadingBtn v-if="props.loading" class="mb-4 mt-5" />
      <h6
        class="row-link fw-normal text-center"
        @click="onClickRedirectToUserCabinet()"
      >
        заполнить позже
      </h6>
    </div>
    <PageAlert v-if="invalidData" color="red">
      <i class="icon-notify"></i>
      чтобы продолжить, заполните все данные
    </PageAlert>
    <div class="footer-text color-grey text-center mx-auto">
      Нажимая подтвердить, вы предоставляете
      <a
        href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
        target="_blank"
      >
        Согласие на обработку персональных данных и запрос в бюро кредитных
        историй. </a
      >Если у вас более одного учредителя или бенефициара, вы подтверждаете
      согласие от лица каждого из них. <br /><br />
      Нажимая подтвердить, вы подтверждаете отсутствие неснятой или непогашенной
      судимости за преступления в сфере экономики или против государственной
      власти, интересов государственной службы и службы в органах местного
      самоуправления. Отсутствие фактов, которые могут оказать существенное
      негативное влияние на возврат займа. Деловая репутация заемщика —
      положительная.
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import PageAlert from '@/components/_generic/ui/widgets/PageAlert.vue';
import WithParser from '@/components/auth/user-passports/_generic/WithParser.vue';
import WithoutParser from '@/components/auth/user-passports/_generic/WithoutParser.vue';
import { ref, computed, watch, onActivated } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { storeToRefs } from 'pinia';
import { pluralize } from '@/utils';
import { Mask } from 'maska';
import { usePassport } from '../_generic/utils';
import masks from '@/libs/js-text-masks';
import Checkbox from '@/components/_generic/ui/widgets/Checkbox.vue';
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';
import { isPassportFullFileld } from '@/utils';

const emit = defineEmits([
  'change-component',
  'redirect-to-user-cabinet',
  'send-otp-async',
]);
const props = defineProps({
  ulFoundersArr: {
    type: Array,
    default() {
      return [];
    },
  },
  ipFoundersArr: {
    type: Array,
    default() {
      return [];
    },
  },
  errorsArr: {
    type: Array,
    default() {
      return [];
    },
  },
  countdown: {
    type: Object,
    default() {
      return {};
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isFoundersPassportsFilled: {
    type: Boolean,
    default: false,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isPassportDataCorrect: {
    type: Boolean,
    default: false,
  },
});

const mask = new Mask({ mask: masks.phone() });
const { isParsed, isError } = usePassport();
const invalidData = ref(false);
const countdownTimer = props.countdown;
const leftTime = ref(0);
const store = useStateStore();
const isConfirmCondition = ref(true);

const {
  currentUserPassport,
  fullName,
  currentCompanyInfo,
  currentUser,
  isParsingEnabled,
} = storeToRefs(store);

onActivated(() => {
  countdownTimer.onTick = (val) => {
    leftTime.value = val;
  };
});

// watch error notify value, to reset after animation (4s)
watch(
  () => invalidData.value,
  () => {
    invalidData.value
      ? setTimeout(() => (invalidData.value = false), 3500)
      : null;
  },
);
// Свойство, которое используется для смены текста в зависимости от isPassportFullFileld(store.currentUserPassport)
const passportValidText = computed(() => {
  return isPassportFullFileld(store.currentUserPassport)
    ? currentUserPassport.value.series + ' ' + currentUserPassport.value.number
    : 'нет данных';
});
const inputFields = ref([
  {
    title: 'индивидуальный предприниматель',
    value: computed(() => {
      return fullName.value || currentCompanyInfo.value.title;
    }),
    name: 'title',
  },
  {
    title: 'телефон индивидуального предпринимателя',
    value: computed(() => {
      return currentUser.value.phone
        ? mask.masked(currentUser.value.phone)
        : '';
    }),
    name: 'phone',
  },
  {
    title: 'почта индивидуального предпринимателя',
    value: computed(() => {
      return currentUser.value.email;
    }),
    name: 'email',
  },
  {
    title: 'паспортные данные',
    value: computed(() => {
      return passportValidText.value;
    }),
    name: 'passport',
  },
]);
// переходим на паспортные данные только на последнем инпуте
const onClickGoToComponent = (type) => {
  if (props.loading || type !== 'passport') {
    return;
  }
  if (
    isPassportFullFileld(store.currentUserPassport) ||
    isParsed.value ||
    isError.value ||
    !isParsingEnabled.value
  ) {
    emit('change-component', WithoutParser);
  } else {
    emit('change-component', WithParser);
  }
};
const onClickSignAgreement = () => {
  if (!isPassportFullFileld(store.currentUserPassport)) {
    invalidData.value = true;
  } else {
    emit('send-otp-async');
  }
};
const onClickRedirectToUserCabinet = () => {
  if (props.loading) {
    return;
  }
  emit('redirect-to-user-cabinet');
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 460px;
  .form {
    .inputs-container {
      background-color: $background;
      border-radius: 14px;
      padding: 20px 0px;
      .input-box {
        position: relative;
        margin: 0px 10px;
        &:hover input {
          background-color: white;
        }
        input {
          @include input;
          height: 78px;
          padding: 20px 40px 20px 20px;
          border: none;
          cursor: pointer;
          border-radius: 14px;
          margin-bottom: 8px;
          white-space: nowrap;
          overflow: hidden;
        }
        .placeholder {
          position: absolute;
          left: 20px;
          top: 10px;
          font-size: 8px;
          line-height: 12px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $grey;
        }
        .text-fill {
          position: absolute;
          right: 20px;
          top: 30px;
          cursor: pointer;
          color: $tiffany;
        }
        .icon {
          position: absolute;
          right: 20px;
          top: 30px;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center;
          cursor: pointer;
          &-correct {
            background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
            filter: $filter-breakwater;
          }
        }

        .divider {
          border-top: 1px solid $grey;
          margin: 0px;
        }
      }
    }
    .row-link {
      cursor: pointer;
      color: $grey;
      &:hover {
        color: $breakwater;
      }
    }
  }
  .icon-notify {
    margin-right: 12px;
    width: 24px;
    height: 24px;
    background-image: url(@/assets/icons/notices/notify-alert.svg);
    background-repeat: no-repeat;
    background-position: center;
  }
}
.footer-text {
  font-size: 12px;
  margin-top: 30px;
  line-height: 20px;
}
</style>
