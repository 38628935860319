import UserLogin from '@/pages/UserLoginPage.vue';
import LoginRecover from '@/pages/UserLogin/LoginRecover.vue';
import AgentReg from '@/pages/AgentRegPage.vue';
import Registration from '@/pages/RegistrationPage.vue';
import BankStatement from '@/pages/BankStatementPage.vue';
import CompanyAnalytic from '@/pages/CompanyAnalyticPage.vue';
import CompanyProfile from '@/pages/CompanyProfilePage.vue';
import UserPassports from '@/pages/UserPassportsPage.vue';
import LoanCard from '@/pages/LoanCardPage.vue';
import Dashboard from '@/pages/DashboardPage.vue';
import AccountRefill from '@/pages/AccountRefillPage.vue';
import CommissionPayment from '@/pages/CommissionPaymentPage.vue';
import LoanPartialRepayment from '@/pages/LoanPartialRepaymentPage.vue';
import LoanFullRepayment from '@/pages/LoanFullRepaymentPage.vue';
import OrderCard from '@/pages/OrderCardPage.vue';
import AccountsPage from '@/pages/AccountsPage.vue';
import EverypayPage from '@/pages/EverypayPage.vue';
import ProfilePage from '@/pages/ProfilePage.vue';

export default [
  {
    path: '/',
    name: 'home',
    component: UserLogin,
    beforeEnter: (to, from, next) => {
      next('/login');
    },
  },
  {
    path: '/login',
    name: 'login',
    component: UserLogin,
  },
  {
    path: '/login/recover',
    name: 'loginRecover',
    component: LoginRecover,
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration,
  },
  {
    path: '/registration/agent',
    name: 'agentReg',
    component: AgentReg,
  },
  {
    path: '/orders/statement_files',
    name: 'bankStatement',
    component: BankStatement,
  },
  {
    path: '/business/company_analytic',
    name: 'companyAnalytic',
    component: CompanyAnalytic,
  },
  {
    path: '/business/company_profiles',
    name: 'companyProfile',
    component: CompanyProfile,
  },
  {
    path: '/business/users/passports',
    name: 'userPassports',
    component: UserPassports,
  },
  {
    path: '/loan/:order_id',
    name: 'loanCard',
    component: LoanCard,
  },
  {
    path: '/order/:order_id',
    name: 'orderCard',
    component: OrderCard,
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
  },
  {
    path: '/account_refill',
    name: 'accountRefill',
    component: AccountRefill,
  },
  {
    path: '/commission_payment',
    name: 'commissionPayment',
    component: CommissionPayment,
  },
  {
    path: '/repayment/partial',
    name: 'loanPartialRepayment',
    component: LoanPartialRepayment,
  },
  {
    path: '/repayment/full',
    name: 'loanFullRepayment',
    component: LoanFullRepayment,
  },
  {
    path: '/accounts',
    name: 'accounts',
    component: AccountsPage,
  },
  {
    path: '/everypay',
    name: 'everypay',
    component: EverypayPage,
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfilePage,
  },
];
