<template>
  <div>
    <Message
      v-if="
        store.isRejectedState && store.currentCompany.allow_next_loan_after_date
      "
    >
      <template #content>
        <span>
          Оформление траншей временно недоступно. Вы сможете обновить лимит и
          подать заявку повторно после
          {{ formatDate(store.currentCompany.allow_next_loan_after_date) }}
        </span>
      </template>
    </Message>

    <Message
      v-if="store.currentCompany?.funds?.overdue?.fine"
      :message-type="'alert'"
    >
      <template #content>
        <div class="overdue-message-content">
          <div>
            у вас начислена пеня
            {{ formatCurrency(store.currentCompany?.funds?.overdue?.fine) }} Р,
            пополните баланс на эту сумму как можно быстрее
          </div>
          <div
            class="notify-btn d-flex align-items-center cursor-pointer"
            @click="router.push({ name: 'accountRefill' })"
          >
            <span class="btn-text text-danger"
              ><span class="me-2">пополнить баланс</span
              ><i v-if="!isMobile" class="btn-arrow"></i
            ></span>
          </div>
        </div>
      </template>
    </Message>

    <OverdueNotify v-if="store.isOverdue" type="overdue" />
    <OverdueNotify
      v-if="
        !store.isBillingMonthlyCommission && store.isOverdueMonthlyCommission
      "
      type="overdue_commission"
    />
    <OverdueNotify v-if="store.isCompanyHasPenalty" type="penalty" />
  </div>
</template>
<script setup>
import { useStateStore } from '@/stores/stateStore';
import Message from '@/components/_generic/ui/widgets/Message.vue';
import OverdueNotify from '@/components/auth/dashboard/messages/OverdueNotify.vue';
import { formatDate, formatCurrency } from '@/utils';
import { useRouter } from 'vue-router';
import { detectMobile } from '@/utils';

const { isMobile } = detectMobile();

const store = useStateStore();
const router = useRouter();
</script>
<style lang="scss" scoped>
.overdue-message-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
</style>
