<template>
  <ModalFull @close-modal="emit('close-modal')">
    <template #title>
      загрузите фотографии паспорта <br />
      {{
        inclineLastname(lastName, 'genitive') +
        ' ' +
        inclineFirstname(firstName, 'genitive') +
        ' ' +
        inclineMiddlename(patronymic, 'genitive')
      }}
    </template>
    <template #content>
      <div
        class="w-100 bg-background rounded-3 p-4 mb-4 d-flex align-items-center"
      >
        <PassportUploader
          v-model="pages.passport_main"
          :options="makeOptions('passport_main')"
        />
        <span class="file-name small ms-4 me-4">{{
          pages.passport_main[0]?.filename ||
          pages.passport_main[0]?.name ||
          'главный разворот паспорта'
        }}</span>
        <i
          class="mx-auto status-icon"
          :class="{
            'status-icon-true': pages.passport_main[0]?.success,
            'status-icon-false': pages.passport_main[0]?.success === false,
          }"
        ></i>
      </div>
      <div class="bg-background rounded-3 p-4 mb-4 d-flex align-items-center">
        <PassportUploader
          v-model="pages.passport_registration"
          :options="makeOptions('passport_registration')"
        />
        <span class="file-name small ms-4 me-4">{{
          pages.passport_registration[0]?.filename ||
          pages.passport_registration[0]?.name ||
          'страница с пропиской'
        }}</span>
        <i
          class="mx-auto status-icon"
          :class="{
            'status-icon-true': pages.passport_registration[0]?.success,
            'status-icon-false':
              pages.passport_registration[0]?.success === false,
          }"
        ></i>
      </div>
      <div class="bg-background rounded-3 p-4 mb-4 d-flex align-items-center">
        <PassportUploader
          v-model="pages.passport_selfie"
          :options="makeOptions('passport_selfie')"
        />
        <span class="file-name small ms-4 me-4">{{
          pages.passport_selfie[0]?.filename ||
          pages.passport_selfie[0]?.name ||
          'селфи с главным разворотом паспорта'
        }}</span>
        <i
          class="mx-auto status-icon"
          :class="{
            'status-icon-true': pages.passport_selfie[0]?.success,
            'status-icon-false': pages.passport_selfie[0]?.success === false,
          }"
        ></i>
      </div>
      <PotokButton
        v-if="!loading"
        class="mt-3 w-100"
        text="отправить"
        size="large"
        @click="onClickStartParsing()"
      />
      <LoadingBtn v-if="loading" class="mt-6" />
      <ErrorItem
        v-if="errors.length"
        v-model="errors"
        class="error text-center mx-auto mt-4"
      />
    </template>
  </ModalFull>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import PassportUploader from '@/components/_generic/auth/resource-uploader/PassportUploader.vue';
import { inclineFirstname, inclineLastname, inclineMiddlename } from 'lvovich';
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import { useStateStore } from '@/stores/stateStore';
import { onMounted, computed } from 'vue';
import config from '@/../config';
import constants from '@/constants';
import { useParser } from './utils';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Guarantor_Parsing_start } = TRACKER_EVENTS;

const store = useStateStore();
const emit = defineEmits(['close-modal', 'get-guarantors-async']);
const props = defineProps({
  isGuaranter: {
    type: Boolean,
    default: false,
  },
});

const {
  pages,
  loading,
  errors,
  getUploadedPassportsListAsync,
  postCommandsAsync,
} = useParser(emit);

onMounted(() => {
  getUploadedPassportsListAsync();
});

const lastName = computed(() => {
  let result;
  if (props.isGuaranter) {
    result = store.currentGuarantor?.last_name;
  }
  if (!props.isGuaranter) {
    result = store.currentUser?.last_name;
  }
  return result || '';
});

const firstName = computed(() => {
  let result;
  if (props.isGuaranter) {
    result = store.currentGuarantor?.first_name;
  }
  if (!props.isGuaranter) {
    result = store.currentUser?.first_name;
  }
  return result || '';
});

const patronymic = computed(() => {
  let result;
  if (props.isGuaranter) {
    result = store.currentGuarantor?.patronymic;
  }
  if (!props.isGuaranter) {
    result = store.currentUser?.patronymic;
  }
  return result || '';
});

const isPagesUpload = computed(() => {
  return !!(
    pages.passport_main?.[0]?.success &&
    pages.passport_registration?.[0]?.success &&
    pages.passport_selfie?.[0]?.success
  );
});

const isPassportFilled = computed(() => {
  return (
    Object.keys(store.currentGuarantor.passport).length !== 0 &&
    Object.values(store.currentGuarantor.passport).every((v) => v)
  );
});

const onClickStartParsing = () => {
  if (loading.value) {
    return;
  }
  if (!isPagesUpload.value) {
    errors.value.push('загрузите сканы паспортов');
    return;
  }
  if (store.isParsingEnabled && !isPassportFilled.value) {
    tracker.queue(tracker.commands.SEND, SPA_LK_Guarantor_Parsing_start);
    postCommandsAsync();
  } else {
    emit('get-guarantors-async');
    emit('close-modal');
  }
};

const makeOptions = (type) => {
  return {
    file: 'file',
    inputId: type,
    postAction: config.VITE_BASE_URL + '/spa/business/attachments',
    headers: { Authorization: store.session.token },
    data: {
      kind: type,
      attachable_id: store.currentGuarantor.id,
      attachable_type: 'Guarantor',
    },
    extensions: constants.PASSPORT_FILE_EXTENSIONS,
    accept: 'image/png,image/gif,image/jpeg,image/webp,application/pdf',
    loading: loading.value,
  };
};
</script>

<style lang="scss" scoped>
.file-name {
  max-width: 270px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.status-icon {
  min-width: 18px;
  min-height: 18px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  &-true {
    background: url(@/assets/icons/rounded/true_icon_pass.svg);
  }
  &-false {
    background: url(@/assets/icons/rounded/false_icon_pass.svg);
  }
}
</style>
