import { computed } from 'vue';

export const roundAmount = (amount) => {
  if (amount > 500000 && amount < 1000000) {
    return Math.round(amount / 100000) * 100000;
  } else if (amount > 1000000 && amount < 5000000) {
    return Math.round(amount / 500000) * 500000;
  } else if (amount > 5000000) {
    return Math.round(amount / 1000000) * 1000000;
  }
  return Math.round(amount / 50000) * 50000;
};

export const roundPeriod = (period, terms) => {
  const isInRange = getAndSortPeriodInMonth(terms).find((el) => el === period);
  const getMaxPeriod = Math.max(...getAndSortPeriodInMonth(terms));
  const getMinPeriod = Math.min(...getAndSortPeriodInMonth(terms));
  if (period <= 1) {
    return isInRange ? 1 : getMinPeriod;
  } else if (period > 1 && period <= 2) {
    return isInRange ? 2 : getMaxPeriod;
  } else if (period > 2 && period <= 3) {
    return isInRange ? 3 : getMaxPeriod;
  } else if (period > 3 && period <= 4) {
    return isInRange ? 4 : getMaxPeriod;
  } else if (period > 4 && period <= 5) {
    return isInRange ? 5 : getMaxPeriod;
  } else if (period > 5 && period <= 6) {
    return isInRange ? 6 : getMaxPeriod;
  } else if (period > 6 && period <= 9) {
    return isInRange ? 9 : getMaxPeriod;
  } else if (period > 9 && period <= 12) {
    return isInRange ? 12 : getMaxPeriod;
  } else if (period > 12 && period <= 18) {
    return isInRange ? 18 : getMaxPeriod;
  } else if (period > 18 && period <= 24) {
    return getMaxPeriod;
  }
  return getMaxPeriod;
};

export const getAndSortPeriodInMonth = (arr) => {
  let terms = [];
  if (Array.isArray(arr)) {
    arr.forEach((el) => terms.push(el.loan_period / 30));
    return terms.sort(function (a, b) {
      return a - b;
    });
  }
  return [];
};

export const calculateSteps = (minLimit, maxLimit) => {
  const steps = computed(() => {
    const { points } = calculatePoints(minLimit, maxLimit);
    let result = [];
    points.value.forEach((point, idx) => {
      if (idx < points.value.length) {
        const nextPoint = points.value[idx + 1];
        for (let i = point.value; i <= nextPoint.value; i += point.step) {
          result.push(i);
        }
      }
    });

    const uniqueValues = new Set(result);
    return [...uniqueValues];
  });
  return { steps: steps };
};

const calculatePoints = (minLimit, maxLimit) => {
  const points = computed(() => {
    if (maxLimit > 500000 && maxLimit <= 1000000) {
      return [
        { value: minLimit, step: 50000 },
        {
          value: 500000,
          step: 100000,
        },
        {
          value: maxLimit,
        },
      ];
    }
    if (maxLimit > 1000000 && maxLimit <= 5000000) {
      return [
        { value: minLimit, step: 50000 },
        {
          value: 500000,
          step: 100000,
        },
        {
          value: 1000000,
          step: 500000,
        },
        {
          value: maxLimit,
        },
      ];
    }
    if (maxLimit > 5000000) {
      return [
        { value: minLimit, step: 50000 },
        {
          value: 500000,
          step: 100000,
        },
        {
          value: 1000000,
          step: 500000,
        },
        {
          value: 5000000,
          step: 1000000,
        },
        {
          value: maxLimit,
        },
      ];
    }
    return [
      { value: minLimit, step: 50000 },
      {
        value: maxLimit,
      },
    ];
  });
  return { points: points };
};

export const getCurrentPeriodData = (period, terms) => {
  if (Array.isArray(terms)) {
    return terms.find((el) => (el.loan_period === period * 30 ? el : null));
  }
  return {};
};

export const calculateOneTimeCommissionPercent = (opts) => {
  const period = opts.period;
  const terms = opts.terms;
  const type = opts.type;
  const commission = opts.commission;

  if (
    type === 'monthly_commission' ||
    type === 'monthly_commission_with_discount'
  ) {
    return commission;
  }
  if (type === 'custom_params') {
    return getCurrentPeriodData(period, terms)?.commission_percent;
  }
  if (type === 'custom_params_commission_discount') {
    return getCurrentPeriodData(period, terms)
      ?.commission_percent_with_discount;
  }
  return null;
};

export const calculateAnnualRate = (period, terms, rate, type) => {
  switch (type) {
    case 'order':
      return rate ? rate : getCurrentPeriodData(period, terms)?.rate;
    default:
      return getCurrentPeriodData(period, terms)?.rate;
  }
};

export const calculateOneTimeCommission = (opts) => {
  const amount = opts.amount;
  return (
    (amount *
      calculateOneTimeCommissionPercent({
        period: opts.period,
        terms: opts.terms,
        type: opts.type,
        inputedAmount: opts.inputedAmount,
        commission: opts.commission,
      })) /
    100
  );
};

export const calculateRepaymentFrequency = (payFreq) => {
  return payFreq === 7
    ? 'еженедельно'
    : payFreq === 14
      ? 'раз в 2 недели'
      : 'ежемесячно';
};

/**
 * Расчет регулярного платежа если выбран тип платежа Annuitet
 */
export const calculateInterestAmountAnnuitet = (opts) => {
  const amount = opts.amount;
  const period = opts.period;
  const terms = opts.terms;
  const rate = opts.rate;
  const type = opts.type;

  // расчет готовой ставки
  const localRate = calculateAnnualRate(period, terms, rate, type);
  // расчет регулярного платежа
  const interestPerMonth = () => {
    const r = localRate / 100 / 12;
    return amount / ((1 / r) * (1 - 1 / (1 + r) ** period));
  };
  return interestPerMonth();
};

export const calculateInterestAmount = (opts) => {
  const amount = opts.amount;
  const period = opts.period;
  const terms = opts.terms;
  const freq = opts.repayment_frequency;
  const rate = opts.rate;
  const type = opts.type;

  const localRate = calculateAnnualRate(period, terms, rate, type);
  const interestPerMonth = () => {
    return (
      amount / period + (amount / period) * ((localRate / 100 / 12) * period)
    );
  };
  const interestPerWeek = () => {
    return (
      ((amount / period +
        (amount / period) * ((localRate / 100 / 12) * period)) /
        30) *
      7
    );
  };
  const interestPerTwoWeek = () => {
    return (
      ((amount / period +
        (amount / period) * ((localRate / 100 / 12) * period)) /
        30) *
      14
    );
  };
  return freq === 30
    ? interestPerMonth()
    : freq === 14
      ? interestPerTwoWeek()
      : interestPerWeek();
};

export const calculateMonthlyCommission = (amount, percent) => {
  return (amount * percent) / 100;
};
