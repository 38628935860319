import { useRouter, useRoute } from 'vue-router';
import { useStateStore } from '@/stores/stateStore';
import { useRedirection } from '@/server/index';
import constants from '@/constants';
import server from '@/server/index';

export function useFlowManager() {
  const store = useStateStore();
  const router = useRouter();
  const route = useRoute();

  const isGoToDashboard = () => {
    if (store.currentUser.agent || !store.currentCompany.dashboard) {
      useRedirection('/spa/users/sign_in');
      return;
    }

    if (route.query.confirmation_token) {
      const query = {
        confirmation_token: route.query.confirmation_token,
      };
      server.postConfirmEmail.send(query).pipe().exec();
    }

    return router.push({
      name: 'dashboard',
    });
  };

  const isGoToOrderDetails = (params) => {
    if (
      params.product_type ===
      constants.ORDER_STATES.control_statement_file_assessing
    ) {
      return useRedirection('/pvp/business/orders/' + params.id);
    }

    if (
      (params.loan?.state === constants.LOAN_STATES.invested ||
        params.loan?.state === constants.LOAN_STATES.investing) &&
      params.id
    ) {
      return router.push({
        name: 'loanCard',
        params: {
          order_id: params.id,
        },
      });
    }

    if (params.loan?.state && store.isNewLoanPageEnabled) {
      return router.push({
        name: 'orderCard',
        params: {
          order_id: params.id,
        },
      });
    }

    if (params.loan?.state) {
      return useRedirection('/pvp/business/loans/' + params.loan_id);
    }
    router.push({
      name: 'loanCard',
      params: {
        order_id: params.id,
      },
    });
  };
  return {
    isGoToDashboard,
    isGoToOrderDetails,
  };
}
