import config from '@/../config';

export default {
  getPages: {
    url: config.VITE_BASE_URL + `/teleport/pages`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  getServerStatuses: {
    url: config.VITE_BASE_URL + `/teleport/statuses`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  postSelectStatus: {
    url: config.VITE_BASE_URL + `/teleport/status`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
};
