export const LOAN_TERMS_EVENTS = {
  SPA_LK_Conditions_limit_refrash: 'SPA_LK_Conditions_limit_refrash',
  SPA_LK_Conditions_Business_about_rewrite:
    'SPA_LK_Conditions_Business_about_rewrite',
  SPA_LK_Conditions_vipiska_refrash: 'SPA_LK_Conditions_vipiska_refrash',
  SPA_LK_Conditions_BKI_refrash: 'SPA_LK_Conditions_BKI_refrash',
  SPA_LK_Conditions_Business_analytics: 'SPA_LK_Conditions_Business_analytics',
  SPA_LK_Conditions_open: 'SPA_LK_Conditions_open',
  SPA_LK_Conditions_limit_refrash_success:
    'SPA_LK_Conditions_limit_refrash_success',
  SPA_LK_Conditions_reject: 'SPA_LK_Conditions_reject',
  SPA_LK_Conditions_success: 'SPA_LK_Conditions_success',
  SPA_LK_Conditions_changed: 'SPA_LK_Conditions_changed',
  SPA_LK_Conditions_monthly: 'SPA_LK_Conditions_monthly',
  SPA_LK_Conditions_once: 'SPA_LK_Conditions_once',
  SPA_LK_Conditions_personal_aim: 'SPA_LK_Conditions_personal_aim',
};

export const LOAN_TERMS_VKL_EVENTS = {
  SPA_LK_VKL_Conditions_open: 'SPA_LK_VKL_Conditions_open',
  SPA_LK_VKL_Conditions_success: 'SPA_LK_VKL_Conditions_success',
  SPA_LK_VKL_Conditions_personal_aim: 'SPA_LK_VKL_Conditions_personal_aim',
  SPA_LK_VKL_Conditions_changed: 'SPA_LK_VKL_Conditions_changed',
  SPA_LK_VKL_Conditions_monthly: 'SPA_LK_VKL_Conditions_monthly',
  SPA_LK_VKL_Conditions_onece: 'SPA_LK_VKL_Conditions_onece',
  SPA_LK_VKL_Conditions_reject: 'SPA_LK_VKL_Conditions_reject',
  SPA_LK_VKL_Conditions_Business_analytics:
    'SPA_LK_VKL_Conditions_Business_analytics',
  SPA_LK_VKL_Conditions_vipiska_refrash:
    'SPA_LK_VKL_Conditions_vipiska_refrash',
  SPA_LK_VKL_Conditions_BKI_refrash: 'SPA_LK_VKL_Conditions_BKI_refrash',
  SPA_LK_VKL_Conditions_limit_refrash_success:
    'SPA_LK_VKL_Conditions_limit_refrash_success',
  SPA_LK_VKL_Conditions_Business_about_rewrite:
    'SPA_LK_VKL_Conditions_Business_about_rewrite',
};
