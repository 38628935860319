<template>
  <div class="page-title">{{ store.currentCompany.short_title }}</div>

  <div class="content">
    <div class="content-right-wrapper p-4 bg-white rounded-4 mt-6">
      <i class="icon-house me-2"></i>
      <span v-if="companiesCount > 1">мои компании</span>
      <span v-if="companiesCount <= 1">моя компания</span>
      &nbsp;{{ companiesCount }}
    </div>

    <CustomSelect
      v-model="selectedMenuTab"
      class="mt-4"
      :items="[
        {
          title: 'руководитель',
          value: 'manager',
        },
        {
          title: 'учредители',
          value: 'founders',
        },
        {
          title: 'поручители',
          value: 'garanters',
        },
      ]"
      default-title="выберите цель займа"
      type="alt"
    />
    <div class="mt-4">
      <component :is="currentComponent"></component>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted, computed } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import ProfileManager from '@/components/auth/profile/ProfileManager.vue';
import ProfileFounders from '@/components/auth/profile/ProfileFounders.vue';
import ProfileGaranters from '@/components/auth/profile/ProfileGaranters.vue';
import CustomSelect from '@/components/_generic/ui/widgets/CustomSelect.vue';

const store = useStateStore();

const selectedMenuTab = ref('manager');
const selectedCompany = ref({});

const currentComponent = computed(() => {
  if (selectedMenuTab.value === 'manager') {
    return ProfileManager;
  }
  if (selectedMenuTab.value === 'founders') {
    return ProfileFounders;
  }
  return ProfileGaranters;
});

const companiesCount = computed(() => {
  return store.companies.items.filter((i) => i.id).length;
});

onMounted(() => {
  selectedCompany.value = store.currentCompany;
});
</script>
<style lang="scss" scoped>
:deep(.select) {
  border: 1px solid $input;
  border-radius: 8px;
}
.content-left {
  flex: 2;
}
.content-right {
  flex: 1;
  padding-left: 20px;
}
.content-right-wrapper {
  height: 84px;
  display: flex;
  align-items: center;
}
</style>
