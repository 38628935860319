<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="container-page-background"
    :class="{
      'wrapper-page-full': isStatementLoaded && !isCalculationFinished,
    }"
  >
    <div class="container-page">
      <Message v-if="isOldStatement">
        <template #content>
          <div class="old-statement-wrapper">
            <div>
              банковская выписка устарела, загрузите новую и мы обновим
              аналитику по выручке
            </div>
            <div>
              <button
                class="btn-text btn-text-tiffany"
                @click="onClickRedirectLoadStatement"
              >
                загрузить новую выписку
              </button>
            </div>
          </div>
        </template>
      </Message>

      <div class="page-title">анализ бизнеса</div>
      <div class="page-desc mt-4">
        показываем, как выглядит ваш бизнес глазами профессионального
        андеррайтера: на что он обращает внимание при принятии решения.
      </div>
      <div class="mt-4">
        <div
          class="white-block d-flex justify-content-between align-items-center"
        >
          <div class="select-form-wrapper">
            <CustomExtendedSelect
              v-model="selectedDatePeriods"
              :items="datePeriods"
              :selected-icon="'icon-calendar'"
            />
          </div>
          <div class="no_mobile">
            <button
              class="btn-text btn-text-tiffany"
              type="button"
              @click="isShowModalHelp = true"
            >
              как это рассчитано и расшифровывается?
            </button>
          </div>
        </div>
      </div>
      <div class="mt-4"><AnalyticAccounts /></div>
      <div class="mt-4">
        <ElementDesktop
          v-if="store.currentCompany && !isMobile"
          :turnovers-filtered="turnoversFiltered"
          :is-calculation-finished="isCalculationFinished"
          :is-statement-loaded="isStatementLoaded"
          :selected-period="selectedDatePeriods"
        />
        <ElementMobile
          v-if="store.currentCompany && isMobile"
          :turnovers-filtered="turnoversFiltered"
          :is-calculation-finished="isCalculationFinished"
          :is-statement-loaded="isStatementLoaded"
          :selected-period="selectedDatePeriods"
          @show-modal-help="isShowModalHelp = true"
        />
      </div>

      <div class="actions-panel">
        <PotokButton
          :disabled="store.companies.loading"
          class="button-gomain"
          text="на главную"
          size="large"
          @click="onClickRedirectToMain()"
        />
        <PotokButton
          :disabled="store.companies.loading"
          class="button-feedback"
          text="есть неточности? сообщите нам"
          size="large"
          theme="tertiary"
          @click="onClickShowFeedback()"
        />
      </div>
    </div>

    <ModalRight v-if="isShowModalHelp" @close-modal="isShowModalHelp = false">
      <template #title>как мы рассчитываем статистику</template>
      <template #content
        ><div v-html="formatedModalHelpMessage"></div
      ></template>
    </ModalRight>

    <AnalyticFeedbackModal
      v-if="isShowModalAnalyticFeedback"
      @close="isShowModalAnalyticFeedback = false"
    ></AnalyticFeedbackModal>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';

import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import { useRouter } from 'vue-router';
import { parseISO, differenceInDays } from 'date-fns';
import format from 'format';
import { useStateStore } from '@/stores/stateStore';
import Message from '@/components/_generic/ui/widgets/Message.vue';
import ModalRight from '@/components/_generic/ui/modals/ModalRight.vue';
import CustomExtendedSelect from '@/components/auth/company-analytic/modules/CustomExtendedSelect.vue';
import AnalyticFeedbackModal from '@/components/auth/company-analytic/modules/AnalyticFeedbackModal.vue';
import ElementDesktop from '@/components/auth/company-analytic/ElementDesktop.vue';
import ElementMobile from '@/components/auth/company-analytic/ElementMobile.vue';
import AnalyticAccounts from '@/components/auth/company-analytic/modules/AnalyticAccounts.vue';
import { modalHelpMessage } from '@/components/auth/company-analytic/modules/modalHelpMessage.js';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';
import { detectMobile } from '@/utils';
import { generalUtils } from '@/modules/generalUtils.js';

const {
  SPA_LK_Business_analytics_filtration_time,
  SPA_LK_Business_analytics_help_open,
  SPA_LK_Business_analytics_open,
} = TRACKER_EVENTS;

const { getOrderAsync } = generalUtils();

const { isMobile } = detectMobile();

const router = useRouter();
const loadedData = ref(null);
const store = useStateStore();
const isShowModalHelp = ref(false);
const isShowModalAnalyticFeedback = ref(false);
const datePeriods = [
  {
    title: '3 месяца',
    value: 3,
  },
  {
    title: '6 месяцев',
    value: 6,
  },
  {
    title: '9 месяцев',
    value: 9,
  },
  {
    title: '12 месяцев',
    value: 12,
  },
  {
    title: '18 месяцев',
    value: 18,
  },
  {
    title: '24 месяца',
    value: 24,
  },
];
const selectedDatePeriods = ref(12);

let pollingTimeout;

const formatedModalHelpMessage = computed(() => {
  const inn = store.currentCompany.inn;
  return format(modalHelpMessage, inn);
});

const isCalculationFinished = computed(() => {
  return (
    isStatementLoaded.value &&
    store.currentCompany &&
    !store.currentCompany.turnovers_calculation_in_progress
  );
});

const isStatementLoaded = computed(() => {
  if (!loadedData.value) {
    return false;
  }
  if (store.currentCompany.need_to_reupload_statements) {
    return false;
  }
  return store.isStatementUploaded;
});

const isOldStatement = computed(() => {
  if (!isStatementLoaded.value) {
    return false;
  }
  if (
    differenceInDays(
      new Date(),
      parseISO(store.currentCompany.last_statement_file_uploaded_at),
    ) > 30
  ) {
    return true;
  }
  return false;
});

const turnoversFiltered = computed(() => {
  let result = [];
  if (!store.currentCompany.turnovers) {
    return result;
  }
  try {
    let items = JSON.parse(JSON.stringify(store.currentCompany.turnovers));
    const reversed = items.reverse();
    const removedItems = reversed.splice(0, selectedDatePeriods.value);
    result = removedItems.reverse();
  } catch (e) {
    console.error('Ошибка фильтрации данных turnovers: ', e);
    return result;
  }
  return result;
});

const onClickRedirectLoadStatement = () => {
  router.push({
    name: 'bankStatement',
  });
};
const onClickRedirectToMain = () => {
  router.push({
    name: 'dashboard',
  });
};
const onClickShowFeedback = () => {
  isShowModalAnalyticFeedback.value = true;
  tracker.queue(
    tracker.commands.SEND,
    'SPA_LK_VKL_Business_analytics_Feedback_click',
  );
};

const getCompaniesAsync = () => {
  const options = {
    expand: ['turnovers', 'legal_info'],
    onSuccess: () => onGetCompaniesAsyncSuccess(),
  };
  store.fetchCompanies(options);
};

const onGetCompaniesAsyncSuccess = () => {
  loadedData.value = true;

  if (store.currentCompany.turnovers_calculation_in_progress) {
    pollingTimeout = setTimeout(() => {
      getCompaniesAsync();
    }, 5000);
  }
  getOrderAsync({ isLoading: false });
};

const initPage = () => {
  getCompaniesAsync();
};

watch(
  () => {
    return selectedDatePeriods.value;
  },
  () => {
    tracker.queue(
      tracker.commands.SEND,
      SPA_LK_Business_analytics_filtration_time,
    );
  },
);

watch(
  () => {
    return isShowModalHelp.value;
  },
  (value) => {
    if (value) {
      tracker.queue(tracker.commands.SEND, SPA_LK_Business_analytics_help_open);
    }
  },
);

watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    initPage();
  },
);

onMounted(() => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Business_analytics_open);
  initPage();
});
onUnmounted(() => {
  clearTimeout(pollingTimeout);
});
</script>
<style lang="scss" scoped>
.old-statement-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.select-form-wrapper {
  @media (max-width: $size_767) {
    width: 100%;
  }
}
.page-desc {
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: lowercase;
  max-width: 992px;
}

.actions-panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.button-feedback {
  max-width: 326px;
  @media (max-width: $size_767) {
    max-width: initial;
  }
}
.button-gomain {
  max-width: 150px;
  margin-right: 20px;
  @media (max-width: $size_767) {
    max-width: initial;
    margin-right: initial;
  }
}

:deep(.graphs-item) {
  @media (max-width: $size_767) {
    min-height: 381px;
  }
}
:deep(.block-title) {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $dipedive;
}
:deep(.graph-wrapper-empty) {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
:deep(.graph-empty-block) {
  background-image: url(@/assets/icons/analytic/graph_empty.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  min-height: 202px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
:deep(.button-redirect) {
  max-width: 210px;
  border: 1px solid $dipedive;
}
:deep(.desc-text) {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $grey;
}
</style>
