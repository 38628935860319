import constants from '@/constants';
import { formatDate } from '@/utils';

const { LOAN_STATES } = constants;

export default {
  // признак даты платежа на странице займа
  isLoanPaymentToday() {
    return !!(
      this.currentOrder.loan?.payments?.next_payment?.date &&
      formatDate(this.currentOrder.loan?.payments?.next_payment?.date) ===
        formatDate(new Date())
    );
  },
  // признак наличия просрочки у займа
  isLoanOverdue() {
    return !!(
      this.currentOrder.loan?.payments?.overdue?.main &&
      this.currentOrder.loan?.state === LOAN_STATES.overdue
    );
  },
  // признак займа в цессии
  isLoanInCession() {
    return !!this.currentOrder.loan?.state === LOAN_STATES.in_cession;
  },
  // признак закрытого займа
  isLoanClosed() {
    return !!(
      this.currentOrder.loan?.state === LOAN_STATES.repaid ||
      this.currentOrder.loan?.state === LOAN_STATES.expired
    );
  },
};
