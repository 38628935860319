<template>
  <KeepAlive :exclude="['WithParser', 'WithoutParser']">
    <component
      :is="currentComponent"
      v-model:errorsArr="errors"
      v-model:isPassportDataCorrect="isPassportDataCorrect"
      v-model:isHighlighted="isFormHighlighted"
      :loading="loading"
      :is-disabled="store.isBkiSigned && !store.isHadOrders"
      :countdown="countdown"
      :ul-founders-arr="ulFoundersArr"
      :ip-founders-arr="ipFoundersArr"
      :is-founders-passports-filled="isFoundersPassportsFilled"
      @change-component="changeComponent"
      @redirect-to-user-cabinet="redirectToUserCabinet"
      @send-otp-async="sendOtpAsync"
      @get-user-data-async="getUserDataAsync"
      @get-founders-async="getFoundersAsync"
      @get-users-passports-async="getUsersPassportsAsync"
    ></component
  ></KeepAlive>
</template>

<script setup>
import { ref, shallowRef, onMounted, provide, computed, inject } from 'vue';
import IP_SummaryInfo from '@/components/auth/user-passports/ip-user-type/IP_SummaryInfo.vue';
import UL_SummaryInfo from '@/components/auth/user-passports/ul-user-type/UL_SummaryInfo.vue';
import SignAgreement from '@/components/auth/user-passports/_generic/SignAgreement.vue';
import server from '@/server/index';
import { useStateStore } from '@/stores/stateStore';
import Countdown from '@/modules/Countdown';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';
import { isPassportFullFileld } from '@/utils';

const { SPA_LK_BKI_signature_start, SPA_LK_BKI_open, SPA_LK_Later } =
  TRACKER_EVENTS;

const store = useStateStore();

const countdown = new Countdown();
const loading = ref(false);
const ipFoundersArr = ref([]);
const ulFoundersArr = ref([]);
const errors = ref([]);
const userType = ref('IP');
const emitter = inject('emitter');

const isPassportDataCorrect = ref(false);
const isFormHighlighted = ref(false);

const agreementId = ref('');
provide('agreementId', agreementId);

// STEPS: SummaryInfo -> PassportData -> SignAgreement

onMounted(() => {
  getUserDataAsync();
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_open);
});

let currentComponent = shallowRef(IP_SummaryInfo);

const isFoundersPassportsFilled = computed(() => {
  return (
    ipFoundersArr.value.every(
      (el) => el.passport && isPassportFullFileld(el.passport),
    ) &&
    ulFoundersArr.value.every(
      (el) => el.passport && isPassportFullFileld(el.passport),
    )
  );
});

const changeComponent = (newComponentName) => {
  currentComponent.value = newComponentName;
};

const redirectToUserCabinet = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Later);
  emitter.emit('to-dashboard');
};
const getUserDataAsync = () => {
  loading.value = true;
  return server.getUserData
    .send()
    .pipe(onGetUserDataAsyncSuccess, onGetUserDataAsyncError)
    .exec();
};
const onGetUserDataAsyncSuccess = ({ data }) => {
  data.ul_type === 'UL' ? (userType.value = 'UL') : (userType.value = 'IP');
  store.setCurrentUser(data);
  getCompanyProfiles();
};
const onGetUserDataAsyncError = (error) => {
  errors.value = error.data?.messages;
  loading.value = false;
};
const getCompanyProfiles = () => {
  const query = {
    company_ids: store.currentCompany.id,
  };
  return server.getCompanyProfiles
    .send(query, {})
    .pipe(onGetCompanyProfilesSuccess, onGetCompanyProfilesError)
    .exec();
};
const onGetCompanyProfilesSuccess = ({ data }) => {
  store.setCurrentCompanyInfo(data[0]);
  getUsersPassportsAsync();
};
const onGetCompanyProfilesError = (error) => {
  errors.value = error.data?.messages;
  loading.value = false;
};
const getUsersPassportsAsync = () => {
  store.fetchUserPassport({
    onSuccess: () => {
      isPassportDataCorrect.value = isPassportFullFileld(
        store.currentUserPassport,
      );
      if (userType.value === 'UL') {
        getFoundersAsync();
        changeComponent(UL_SummaryInfo);
      } else {
        loading.value = false;
      }
    },
    onError: (error) => {
      errors.value = error.data?.messages;
    },
  });
};
const getFoundersAsync = () => {
  const query = {
    'company_ids[]': store.currentCompany.id,
  };
  return server.getFounders
    .send(query, {})
    .pipe(onGetFoundersAsyncSuccess, onGetFoundersAsyncError)
    .exec();
};
const onGetFoundersAsyncSuccess = ({ data }) => {
  ipFoundersArr.value = [];
  ulFoundersArr.value = [];
  data?.[0].business?.forEach((item) => {
    const title = item.title;
    item.founders.forEach((obj) => {
      obj.passport && isPassportFullFileld(obj.passport)
        ? (obj.isCorrect = true)
        : (obj.isCorrect = false);
      obj.title = title;
      obj.share ? (obj.share += '%') : (obj.share = null);
      ulFoundersArr.value.push(obj);
    });
  });
  data?.[0].person?.forEach((item) => {
    item.passport && isPassportFullFileld(item.passport)
      ? (item.isCorrect = true)
      : (item.isCorrect = false);
    item.share ? (item.share += '%') : (item.share = null);
    ipFoundersArr.value.push(item);
  });
  loading.value = false;
};
const onGetFoundersAsyncError = (error) => {
  loading.value = false;
  errors.value = error.data?.messages;
};
const sendOtpAsync = () => {
  loading.value = true;
  const query = {
    signature: {
      confirmable_id: parseInt(
        store.currentOrderId || store.currentCompany.last_order_with_limit_id,
      ),
      confirmable_type: 'Order',
      kind: 'signing_bki_agreement',
    },
  };
  return server.postSignatures
    .send(query)
    .pipe(onSendOtpAsyncSuccess, onSendOtpAsyncError)
    .exec();
};
const onSendOtpAsyncSuccess = ({ data }) => {
  agreementId.value = data?.id;
  countdown.reset();
  countdown.start();
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_signature_start);
  changeComponent(SignAgreement);
  loading.value = false;
};
const onSendOtpAsyncError = (response) => {
  errors.value = response.data?.messages;
  loading.value = false;
};
</script>
