<template>
  <AbstractCurrencyInput
    v-model="_modelValue"
    v-model:isFocused="_isFocused"
    class="input"
    :options="{
      locale: 'ru-Ru',
      currency: 'RUB',
      hideCurrencySymbolOnFocus: false,
      hideGroupingSeparatorOnFocus: false,
      valueRange: {
        min: 0,
        max: 50000000,
      },
    }"
  />
  <span v-if="!isMobile && _isFocused" class="input-btn">сохранить</span>
</template>

<script setup>
import AbstractCurrencyInput from '@/components/_generic/ui/inputs/AbstractCurrencyInput.vue';
import { computed } from 'vue';
import { detectMobile } from '@/utils';

const { isMobile } = detectMobile();

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(['update:isFocused', 'update:modelValue']);

const _modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:modelValue', value);
  },
});
const _isFocused = computed({
  get() {
    return props.isFocused;
  },
  set(value) {
    emit('update:isFocused', value);
  },
});
</script>

<style lang="scss" scoped>
.input {
  cursor: pointer;
  width: 100%;
  height: 44px;
  text-overflow: ellipsis;
  outline: none;
  border: none;
  border-radius: 8px;
  color: $dipedive;
  background-color: transparent;
  &:focus {
    width: 220px;
    background-color: $white;
    @media (max-width: $size_767) {
      background-color: transparent;
    }
    & ~ .input-btn {
      display: inline;
    }
  }
}
.input-btn {
  display: none;
  margin-left: 10px;
  padding: 12px 18px;
  color: white;
  background-color: $primary;
  border-radius: 8px;
  cursor: pointer;
  &:hover {
    color: $primary;
    background-color: transparent;
    border: 1px solid $primary;
  }
}
</style>
