import { inject, computed, ref } from 'vue';
import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';
import { useRouter } from 'vue-router';

import server, { openInNewTab } from '@/server/index';
import tracker from '@/tracker';
import Countdown from '@/modules/Countdown';
import { TRACKER_EVENTS } from '@/tracker/events';
import { useStateStore } from '@/stores/stateStore';

const {
  SPA_LK_application_signature_start,
  SPA_LK_VKL_application_signature_start,
  SPA_LK_signature_SMS_success,
  SPA_LK_VKL_signature_SMS_success,
} = TRACKER_EVENTS;

const isModalSigningVKLOpen = ref(false);
const leftTime = ref(0);
const loadingSignVKL = ref(false);
const agreementId = ref(null);
const countdown = new Countdown();
const errors = ref([]);

export function loanCardVKLUtils() {
  const router = useRouter();
  const store = useStateStore();
  const emitter = inject('emitter');
  const {
    order,
    guarantors,
    isApprovedByCreditCommittee,
    isPassportUploaded,
    isGuaranteeFullFilled,
    isPassportFilled,
    isPlatformRulesOpen,
    isPassportModalOpen,
    isPassportFillModalOpen,
    isGuaranteeIssueOpen,
    isDocsModalOpen,
    isGuaranteeSigningOpen,
    isDocsSigned,
    isDocsVKLSigned,
    isManagerModalOpen,
    docsToUpload,
    isConfirmedByRouble,
    isNeedUploadStatementFile,
    isPassportPartiallyUploaded,
    isGuaranteePartiallyFilled,
    isNeedAdditionalDocsUpload,
    isGuaranteeAllSignedByRub,
    isGuarantorsFilledOnBackend,
    isGuaranteePartiallySignedByRub,
    getOrdersAsync,
  } = loanCardShared();

  const additionalDocs = computed(() => {
    let result = [
      {
        title: 'инвестиционное предложение',
        value: 'invest',
      },
    ];
    if (store.isVKLFirstTranche) {
      result.push({
        title: 'договор кредитной линии',
        value: 'credit_line',
      });
      result.push({
        title: 'присоединение к правилам платформы',
        value: 'rules',
      });
    }
    return result;
  });

  const additionalDocsWithTemplate = computed(() => {
    let result = [
      {
        title: 'инвестиционное предложение (образец)',
        value: 'investTemplate',
      },
    ];
    if (store.isVKLFirstTranche) {
      result.push({
        title: 'договор кредитной линии',
        value: 'credit_line',
      });
      result.push({
        title: 'присоединение к правилам платформы',
        value: 'rules',
      });
    }
    return result;
  });

  const getOrderStatusTitle = computed(() => {
    if (store.isVKLFlowInvesting) {
      return 'собираем деньги';
    }
    return isApprovedByCreditCommittee.value
      ? 'транш готов к сбору'
      : 'транш готовится к сбору';
  });

  const onClickGoToChosenStep = (stepName) => {
    if (stepName === 'upload_statement') {
      return router.push({ name: 'bankStatement' });
    }
    if (stepName === 'rules') {
      return (isPlatformRulesOpen.value = true);
    }
    if (stepName === 'passport') {
      return (isPassportModalOpen.value = true);
    }
    if (stepName === 'passport-manual-fill') {
      return (isPassportFillModalOpen.value = true);
    }
    if (stepName === 'guarantee') {
      return (isGuaranteeIssueOpen.value = true);
    }
    if (stepName === 'documents') {
      return (isDocsModalOpen.value = true);
    }
    if (stepName === 'signing_vkl') {
      return onClickSignVKLDocs();
    }
    if (
      stepName === 'founder_signing' &&
      isDocsSigned.value &&
      isGuarantorsFilledOnBackend.value
    ) {
      return (isGuaranteeSigningOpen.value = true);
    }
  };

  const onClickGoDashboard = () => {
    emitter.emit('to-dashboard');
  };
  const closeModal = () => {
    isPassportModalOpen.value = false;
    isGuaranteeIssueOpen.value = false;
    isModalSigningVKLOpen.value = false;
    isDocsModalOpen.value = false;
    isGuaranteeSigningOpen.value = false;
    isPassportFillModalOpen.value = false;
    isManagerModalOpen.value = false;
  };

  const closeModalAndGetOrder = () => {
    getOrdersAsync();
    closeModal();
  };
  const isShownSection = (item) => {
    if (item.name === 'guarantee' || item.name === 'founder_signing') {
      return !!guarantors.value.length;
    } else if (item.name === 'documents') {
      return !!docsToUpload.value.length;
    } else if (item.name === 'passport-manual-fill') {
      return !isPassportFilled.value;
    } else {
      return true;
    }
  };
  const isActionEnabledVKL = (itemName) => {
    if (itemName === 'upload_statement') {
      return isNeedUploadStatementFile.value;
    }
    if (itemName === 'rules') {
      return !isConfirmedByRouble.value;
    }
    if (itemName === 'passport') {
      return !(isPassportUploaded.value && isPassportPartiallyUploaded.value);
    }
    if (itemName === 'passport-manual-fill') {
      return !isPassportFilled.value;
    }
    if (itemName === 'guarantee') {
      return !(isGuaranteeFullFilled.value && isGuaranteePartiallyFilled.value);
    }
    if (itemName === 'documents') {
      return isNeedAdditionalDocsUpload.value;
    }
    if (itemName === 'signing' || itemName === 'signing_vkl') {
      return true;
    }
    if (itemName === 'founder_signing') {
      if (!isGuarantorsFilledOnBackend.value) {
        return false;
      }
      if (!isGuaranteeAllSignedByRub.value) {
        return true;
      }
      return false;
    }
  };
  const detectTextColorVKL = (itemName) => {
    if (itemName === 'rules') {
      return isConfirmedByRouble.value ? '' : 'text-tiffany';
    } else if (itemName === 'passport') {
      return isPassportUploaded.value
        ? ''
        : isPassportPartiallyUploaded.value
          ? ''
          : 'text-tiffany';
    } else if (itemName === 'passport-manual-fill') {
      return isPassportFilled.value ? '' : 'text-tiffany';
    } else if (itemName === 'guarantee') {
      return isGuaranteeFullFilled.value
        ? ''
        : isGuaranteePartiallyFilled.value
          ? ''
          : 'text-tiffany';
    } else if (itemName === 'documents') {
      return !isNeedAdditionalDocsUpload.value ? '' : 'text-tiffany';
    } else if (itemName === 'signing' || itemName === 'signing_vkl') {
      return 'text-tiffany';
    } else if (itemName === 'founder_signing') {
      if (!isGuarantorsFilledOnBackend.value) {
        return 'text-grey';
      }
      if (!isGuaranteeAllSignedByRub.value) {
        return 'text-tiffany';
      }
      if (isGuaranteePartiallySignedByRub.value) {
        return '';
      }
      return 'color-grey';
    }
  };
  const whichTextShownVKL = (itemName) => {
    if (itemName === 'upload_statement') {
      return !isNeedUploadStatementFile.value
        ? undefined
        : `загрузить&nbsp;\u{2192}`;
    }
    if (itemName === 'rules') {
      return isConfirmedByRouble.value ? undefined : `реквизиты&nbsp;\u{2192}`;
    }
    if (itemName === 'passport') {
      return isPassportUploaded.value
        ? undefined
        : isPassportPartiallyUploaded.value
          ? `продолжить&nbsp;\u{2192}`
          : `приложить&nbsp;\u{2192}`;
    }
    if (itemName === 'passport-manual-fill') {
      return isPassportFilled.value ? undefined : `заполнить&nbsp;\u{2192}`;
    }
    if (itemName === 'guarantee') {
      return isGuaranteeFullFilled.value
        ? undefined
        : isGuaranteePartiallyFilled.value
          ? `продолжить&nbsp;\u{2192}`
          : `оформить&nbsp;\u{2192}`;
    }
    if (itemName === 'documents') {
      return !isNeedAdditionalDocsUpload.value
        ? undefined
        : `приложить&nbsp;\u{2192}`;
    }
    if (itemName === 'signing') {
      return isDocsSigned.value
        ? undefined
        : `проверить и подписать&nbsp;\u{2192}`;
    }
    if (itemName === 'signing_vkl') {
      return isDocsSigned.value && isDocsVKLSigned.value
        ? undefined
        : `подписать&nbsp;\u{2192}`;
    }
    if (itemName === 'founder_signing') {
      if (!isGuarantorsFilledOnBackend.value) {
        return 'станет доступно после заполнения анкеты поручителей';
      }
      if (!isDocsSigned.value && !isGuaranteeAllSignedByRub.value) {
        return 'станет доступно после подписи документов';
      }
      if (isGuaranteeAllSignedByRub.value) {
        return undefined;
      }
      if (isGuaranteePartiallySignedByRub.value) {
        return `продолжить&nbsp;\u{2192}`;
      }
      return `подписать&nbsp;\u{2192}`;
    }
  };
  const whichMobileTextShown = (item) => {
    if (item.name === 'rules') {
      return isConfirmedByRouble.value ? undefined : '\u{2192}';
    } else if (item.name === 'passport') {
      return isPassportUploaded.value ? undefined : '\u{2192}';
    } else if (item.name === 'passport-manual-fill') {
      return isPassportFilled.value ? undefined : '\u{2192}';
    } else if (item.name === 'guarantee') {
      return isGuaranteeFullFilled.value ? undefined : '\u{2192}';
    } else if (item.name === 'documents') {
      return isNeedAdditionalDocsUpload.value ? undefined : '\u{2192}';
    } else if (item.name === 'signing') {
      return `\u{2192}`;
    } else if (item.name === 'signing_vkl') {
      if (!isDocsSigned.value || !isDocsVKLSigned.value) {
        return `\u{2192}`;
      }
    } else if (item.name === 'founder_signing') {
      if (!isGuarantorsFilledOnBackend.value) {
        return 'станет доступно после заполнения анкеты поручителей';
      }
      if (!isDocsSigned.value && !isGuaranteeAllSignedByRub.value) {
        return 'станет доступно после подписи документов';
      }
      if (isGuaranteeAllSignedByRub.value) {
        return undefined;
      }
      return `\u{2192}`;
    }
  };
  const isShowIconVKL = (itemName) => {
    if (itemName === 'upload_statement') {
      return !isNeedUploadStatementFile.value;
    }
    if (itemName === 'rules') {
      return isConfirmedByRouble.value;
    }
    if (itemName === 'passport') {
      return isPassportUploaded.value;
    }
    if (itemName === 'passport-manual-fill') {
      return isPassportFilled.value;
    }
    if (itemName === 'guarantee') {
      return isGuaranteeFullFilled.value;
    }
    if (itemName === 'documents') {
      return !isNeedAdditionalDocsUpload.value;
    }
    if (itemName === 'signing') {
      return isDocsSigned.value;
    }
    if (itemName === 'signing_vkl') {
      return isDocsSigned.value && isDocsVKLSigned.value;
    }
    if (itemName === 'founder_signing') {
      return (
        isGuaranteeAllSignedByRub.value && isGuarantorsFilledOnBackend.value
      );
    }
  };

  const onClickSignVKLDocs = () => {
    if (leftTime.value === 0) {
      sendOtpAsync();
    }
    isModalSigningVKLOpen.value = true;
  };
  const sendOtpAsync = () => {
    loadingSignVKL.value = true;
    let query = null;
    if (
      store.isVKLFirstTranche &&
      store.currentOrder.credit_line.state === 'init'
    ) {
      query = {
        signature: {
          confirmable_id: parseInt(store.currentOrder.credit_line?.id),
          confirmable_type: 'CreditLine',
          kind: 'credit_line',
          order_amount: store.pages?.loanTerm?.amountCustomParams,
        },
      };
    } else {
      query = {
        signature: {
          confirmable_id: parseInt(store.currentOrder.id),
          confirmable_type: 'Order',
          kind: 'signing_documents',
          order_amount: store.pages?.loanTerm?.amountCustomParams,
        },
      };
    }
    return server.postSignatures
      .send(query)
      .pipe(onSendOtpAsyncSuccess, onSendOtpAsyncError)
      .exec();
  };
  const onSendOtpAsyncSuccess = ({ data }) => {
    tracker.queue(
      tracker.commands.SEND,
      order.value.credit_line
        ? SPA_LK_VKL_application_signature_start
        : SPA_LK_application_signature_start,
    );
    agreementId.value = data?.id;
    countdown.reset();
    countdown.start();
    loadingSignVKL.value = false;
  };
  const onSendOtpAsyncError = (error) => {
    loadingSignVKL.value = false;
    error.data.messages ? (errors.value = error.data.messages) : null;
  };
  const confirmOtpAsync = (rawOtp) => {
    const query = {
      signature: {
        code: rawOtp,
      },
    };
    loadingSignVKL.value = true;
    return server.putSignatures$id
      .send(query, {
        params: {
          id: agreementId.value,
        },
      })
      .pipe(onConfirmOtpAsyncSuccess, onConfirmOtpAsyncError)
      .exec();
  };
  const onConfirmOtpAsyncSuccess = () => {
    tracker.queue(
      tracker.commands.SEND,
      order.value.credit_line
        ? SPA_LK_VKL_signature_SMS_success
        : SPA_LK_signature_SMS_success,
    );
    loadingSignVKL.value = false;
    isModalSigningVKLOpen.value = false;
    getOrdersAsync();
    closeModal();
  };
  const onConfirmOtpAsyncError = (error) => {
    error.data?.messages ? (errors.value = error.data.messages) : null;
    loadingSignVKL.value = false;
  };

  const onClickOpenDoc = (type) => {
    switch (type) {
      case 'investTemplate':
        window.open(
          'https://files.potok.digital/%D0%94%D0%BE%D0%BA%D1%83%D0%BC%D0%B5%D0%BD%D1%82%D1%8B/%D0%BB%D0%BA%D0%B7/investment_offer_template.pdf',
          '_blank',
        );
        break;
      case 'invest': {
        openInNewTab(
          '/documents/unsigned/investment_offer.pdf?order_id=' +
            store.currentOrder.id,
        );
        break;
      }
      case 'credit_line':
        openInNewTab(
          '/spa/business/documents/unsigned/credit_line_agreement?credit_line_id=' +
            store.currentOrder.credit_line.id,
        );
        break;
      case 'rules':
        openInNewTab(
          '/documents/unsigned/borrower_accepting_the_rules.pdf?order_id=' +
            store.currentOrder.id,
        );
        break;
      case 'schedule':
        openInNewTab(
          '/documents/draft_schedule.pdf?order_id=' + store.currentOrder.id,
        );
        break;
    }
  };

  return {
    isApprovedByCreditCommittee,
    getOrderStatusTitle,
    loadingSignVKL,
    isModalSigningVKLOpen,
    errors,
    countdown,
    leftTime,
    additionalDocs,
    additionalDocsWithTemplate,
    isNeedAdditionalDocsUpload,
    isNeedUploadStatementFile,
    onClickGoToChosenStep,
    onClickGoDashboard,
    closeModal,
    closeModalAndGetOrder,
    isShownSection,
    detectTextColorVKL,
    whichTextShownVKL,
    whichMobileTextShown,
    isShowIconVKL,
    confirmOtpAsync,
    isActionEnabledVKL,
    onClickOpenDoc,
  };
}
