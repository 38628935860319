<template>
  <ModalFull @close-modal="emit('close')">
    <template #content>
      <div class="modal-feedback-wrapper">
        <div class="potok-text-body-2-bold">
          опишите обнаруженные расхождения
        </div>
        <div>
          <PotokTextArea
            v-model="feedbackComment"
            class="mt-4"
            :placeholder="'Ваш комментарий'"
            :disabled="loading"
          ></PotokTextArea>
        </div>
        <div>
          <FileUpload
            ref="supportUpload"
            v-model="files"
            name="file"
            input-id="support_file"
            :maximum="5"
            :extensions="constants.ADDITIONAL_DOC_EXTENSIONS"
            :disabled="loading"
          >
            <div class="upload-btn d-flex align-items-center mt-4">
              <i class="clip-icon me-2"></i>загрузите документ
            </div>
          </FileUpload>

          <div class="d-flex align-items-center mb-3">
            <template v-for="(file, index) in files" :key="index">
              <div class="uploaded-file me-2">
                <i
                  class="remove-uploaded"
                  @click="onClickRemoveFile(index)"
                ></i>
              </div>
            </template>
          </div>
        </div>
        <div class="text-danger">{{ errorMessage }}</div>
        <div class="actions-block mt-6">
          <PotokButton
            class="button-send"
            text="отправить"
            :disabled="loading"
            @click="onClickAction"
          ></PotokButton>
        </div>
      </div>
    </template>
  </ModalFull>
</template>
<script setup>
import { ref } from 'vue';
import { PotokButton, PotokTextArea } from 'potok-uikit';

import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import FileUpload from 'vue-upload-component';
import constants from '@/constants';
import server from '@/server/index';
import { useStateStore } from '@/stores/stateStore';

const emit = defineEmits(['close']);

const store = useStateStore();
const feedbackComment = ref('');
const files = ref([]);
const loading = ref(false);
const errorMessage = ref(null);

const onClickRemoveFile = (index) => {
  files.value.splice(index, 1);
};

const onClickAction = () => {
  if (!feedbackComment.value) {
    errorMessage.value = 'нужно заполнить поле комментария';
    return;
  }
  postFeedback();
};

const postFeedback = () => {
  loading.value = true;
  const query = {
    order_id: store.currentOrderId,
    kind: 'clarification_from_client',
    other_comment: feedbackComment.value,
  };
  return server.postFeedback
    .send(query, {
      params: {
        company_id: store.currentCompany.id,
      },
    })
    .pipe(onPostFeedbackSuccess, () => {
      loading.value = false;
    })
    .exec();
};

const onPostFeedbackSuccess = (data) => {
  loading.value = false;
  const result = data.data || {};
  if (!result.id) {
    return;
  }
  if (files.value.length > 0) {
    postUserAttachmentsAsync(result.id);
  } else {
    closeFeedbackFormAfterSending();
  }
};

const postUserAttachmentsAsync = (attachable_id) => {
  loading.value = true;
  Promise.all(
    files.value.map((item) => {
      let formData = new FormData();
      formData.append('kind', 'feedback_document');
      formData.append('attachable_id', attachable_id);
      formData.append('attachable_type', 'Feedback');
      formData.append('file', item.file);
      return server.postAttachments
        .send(formData)
        .pipe(
          () => {},
          () => {},
        )
        .exec();
    }),
  );
  loading.value = false;
  closeFeedbackFormAfterSending();
};

const closeFeedbackFormAfterSending = () => {
  store.setNotification({
    message: 'благодарим за обратную связь, ваше сообщение отправлено',
  });
  emit('close');
};
</script>
<style lang="scss" scoped>
:deep(.modal-wrapper) {
  min-width: 550px;
  @media (max-width: $size_767) {
    max-width: initial;
  }
}
:deep(.modal-container) {
  max-width: initial;
  width: 100%;
}

.upload-btn {
  cursor: pointer;
  color: $tiffany;

  &:hover .clip-icon {
    filter: $filter-tiffany;
  }

  .clip-icon {
    width: 18px;
    height: 18px;
    background-image: url(@/assets/icons/clip-green.svg);
    background-repeat: no-repeat;
    background-position: center;
    color: $tiffany;
  }

  label {
    cursor: pointer;
  }
}

.uploaded-file {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  border: 1px solid $input;
  background-image: url(@/assets/icons/files/file-uploads-sm-void.svg);
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  &:hover .remove-uploaded {
    display: block;
  }
}

.remove-uploaded {
  display: none;
  position: absolute;
  right: -5px;
  top: -5px;
  width: 16px;
  height: 16px;
  background-image: url(@/assets/icons/exits/close-icon-rounded.svg);
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.button-send {
  max-width: 141px;
  @media (max-width: $size_767) {
    max-width: initial;
  }
}
</style>
