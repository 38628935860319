import server from '@/server/index';

export default {
  fetchCompanies({ expand, onSuccess, onError }) {
    const onFetchCompaniesSuccess = ({ data }) => {
      this.companies.loading = false;
      const findCurrent = data.find((el) => el.current);
      if (findCurrent) {
        this.currentCompany = findCurrent;
      }
      this.setCompaniesItems(data);
      if (onSuccess) {
        onSuccess(data);
      }
    };
    const onFetchCompaniesError = (error) => {
      this.companies.loading = false;
      if (onError) {
        onError(error);
      }
    };
    const query = {
      'company_ids[]': this.currentCompany.id,
      'expand[]': expand,
    };
    this.companies.loading = true;
    return server.getCompanies
      .send(query, {})
      .pipe(
        onFetchCompaniesSuccess.bind(this),
        onFetchCompaniesError.bind(this),
      )
      .exec();
  },
  fetchUserPassport({ onSuccess, onError }) {
    const onFetchUserPassportSuccess = ({ data }) => {
      data.series = data?.series_number?.slice(0, 4);
      data.number = data?.series_number?.slice(5, 11);
      this.setCurrentUserPassport(Object.keys(data).length ? data : {});
      if (onSuccess) {
        onSuccess(data);
      }
    };
    const onFetchCompaniesError = (error) => {
      if (onError) {
        onError(error);
      }
    };
    return server.getUsersPassports
      .send()
      .pipe(
        onFetchUserPassportSuccess.bind(this),
        onFetchCompaniesError.bind(this),
      )
      .exec();
  },
  fetchFeatures(options) {
    const onFetchFeaturesSuccess = ({ data }) => {
      this.setFeatures(data);
      if (onSuccess) {
        onSuccess(data);
      }
    };
    const onFetchFeaturesError = (error) => {
      if (onError) {
        onError(error);
      }
    };
    const { onSuccess, onError } = options || {};
    return server.getFeatures
      .send()
      .pipe(onFetchFeaturesSuccess.bind(this), onFetchFeaturesError.bind(this))
      .exec();
  },
};
