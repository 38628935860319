<template>
  <div class="container pt-7">
    <div class="form w-100 m-auto">
      <h1 class="h3 fw-bold mb-sm-4 mb-3 text-center">вход</h1>
      <h6 v-if="emailHint" class="fw-normal text-center">
        Компания зарегистрирована пользователем с этой электронной почтой
        <a :href="'mailto:' + emailHint">{{ emailHint }}</a
        >. Введите пароль, чтобы войти в систему. Если у вас нет доступа к этой
        электронной почте, обратитесь в поддержку по адресу
        <a :href="'mailto:ok@potok.team'">ok@potok.team</a>
      </h6>
      <LoginToggle
        v-model:isEmailInput="isEmailInput"
        class="mb-3"
        :loading="loading"
        @clear-on-click="clearOnClick"
      />
      <div class="form-row mb-4">
        <template v-if="!isEmailInput"
          ><input
            id="phone"
            v-model="v$.login.$model"
            v-maska
            type="tel"
            :class="{
              'is-invalid': v$.login.$invalid && v$.login.$dirty,
            }"
            :data-maska="masks.phone()"
            placeholder="+7 (999) 999-99-99"
            autofocus
            @focus="clearErrors"
          />
          <div
            v-if="v$.login.$invalid && v$.login.$dirty && isError"
            class="text-danger mt-2"
          >
            <div>введите корректный телефон</div>
          </div>
        </template>
        <template v-else>
          <input
            v-model="v$.login.$model"
            :class="{
              'is-invalid': v$.login.$invalid && v$.login.$dirty,
            }"
            placeholder="почта"
            @focus="clearErrors"
          />
          <div
            v-if="v$.login.$invalid && v$.login.$dirty && isError"
            class="text-danger mt-2"
          >
            <div>введите корректный еmail</div>
          </div>
        </template>
      </div>
      <div v-if="!v$.login.$invalid" class="form-row text-left mb-5">
        <div class="input-with-icon position-relative">
          <input
            id="password"
            v-model="user.password"
            :type="fieldType"
            :class="{
              'is-invalid':
                !isValidPassword(user.password) && v$.password.$dirty,
            }"
            placeholder="пароль"
            @focus="clearErrors"
            @keyup.enter="loginAsync(user.login, user.password)"
          />
          <div
            class="icon"
            :class="[showPass ? 'icon-show' : 'icon-hidden']"
            @click="toggleShow()"
          ></div>
        </div>
        <ErrorItem
          v-if="errorsArr.length !== 0"
          v-model="errorsArr"
          class="mt-2"
        />
      </div>
      <div class="form-row mb-4">
        <PotokButton
          id="signIn"
          text="продолжить"
          size="large"
          class="w-100"
          :loading="loading"
          @click="loginAsync(user.login, user.password)"
        />
      </div>
      <div class="form-row">
        <h6 class="h6 row-link fw-normal text-center">
          <a
            id="registration"
            :disabled="loading"
            @click="sendAnalytics('newReg')"
            >регистрация</a
          >
        </h6>
        <h6 class="h6 row-link fw-normal text-center mt-4">
          <RouterLink
            :to="{ name: 'loginRecover' }"
            @click="sendAnalytics('forgotPass')"
          >
            забыли пароль?
          </RouterLink>
        </h6>
      </div>
    </div>
  </div>
  <span
    class="text-white d-md-block d-none position-fixed bottom-0 start-0 ms-1"
    >{{ store.app.version }}</span
  >
</template>

<script setup>
import { reactive, ref, computed, onMounted, inject } from 'vue';
import { useRoute, useRouter } from 'vue-router';

import { PotokButton } from 'potok-uikit';
import LoginToggle from '@/components/_generic/ui/widgets/LoginToggle.vue';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import useVuelidate from '@vuelidate/core';
import { required, email, or, helpers } from '@vuelidate/validators';
import { useStateStore } from '@/stores/stateStore';
import { vMaska, Mask } from 'maska';
import masks from '@/libs/js-text-masks';
import server, { useRedirection } from '@/server/index';
import tracker from '@/tracker';
import { filter } from '@/utils';
import { formatNumber } from '@/utils';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Login_ask_password_reset,
  SPA_LK_Login_Page_Registration_click,
  SPA_LK_Login_Success,
  SPA_LK_Login_Page_View,
} = TRACKER_EVENTS;

const mask = new Mask({ mask: masks.phone() });

const store = useStateStore();

const route = useRoute();
const router = useRouter();
const emitter = inject('emitter');

const user = reactive({
  login: '',
  password: '',
});
const showPass = ref(false);
const loading = ref(false);
const emailHint = ref('');
const isError = ref(false);
const errorsArr = ref([]);
const fieldType = ref('password');
const isEmailInput = ref(false);

const isPhone = helpers.regex(
  /^(\+7[ ]\([0-9]{3}\)[ ])([0-9]{3}-)([0-9]{2}-)[0-9]{2}$/,
);
const validations = {
  login: {
    required,
    valid: or(email, isPhone),
  },
  password: {
    required,
  },
};

const v$ = useVuelidate(validations, user);

/**
 * Проверка на завершенность регистрации
 */
const isUserRegistered = computed(() => {
  return store.currentUser.agent
    ? !!(
        store.currentUser.confirmed &&
        store.currentUser.password_set &&
        store.currentUser.email_set &&
        store.currentUser.agent_inn_set
      )
    : !!(
        store.currentUser.confirmed &&
        store.currentUser.password_set &&
        store.currentUser.email_set
      );
});

const clearOnClick = (value) => {
  isEmailInput.value = value;
  user.login = '';
  user.password = '';
  v$.value.$reset();
};

const clearErrors = () => {
  isError.value = false;
};

const getQueryParams = () => {
  if (route.query.email) {
    emailHint.value = route.query.email;
  }
};
const sendAnalytics = (value) => {
  if (loading.value) {
    return;
  }
  if (value === 'forgotPass') {
    tracker.queue(tracker.commands.SEND, SPA_LK_Login_ask_password_reset);
  } else {
    tracker.queue(tracker.commands.SEND, SPA_LK_Login_Page_Registration_click);
    router.push('/registration');
  }
};
const isValidPassword = (password) => {
  return Boolean(password);
};
const toggleShow = () => {
  if (fieldType.value === 'password') {
    fieldType.value = 'text';
    showPass.value = true;
  } else {
    fieldType.value = 'password';
    showPass.value = false;
  }
};
const grabAndMergeAndCommitUtms = () => {
  if (route.query) {
    const utms = filter(route.query, (_, key) => key.startsWith('utm_'));
    store.setUTMs(utms);
    inject('$cookies').set(
      'utm',
      store.utms,
      60 * 60 * 24 * 7,
      null,
      'potok.digital',
    );
  }
};
const loginAsync = (rawLogin, rawPassword) => {
  if (v$.value.login.$invalid) {
    isError.value = true;
    return;
  }
  if (!isValidPassword(rawPassword)) {
    return;
  }
  loading.value = true;
  const query = {
    user: {
      login: isPhone(rawLogin) ? formatNumber(rawLogin) : rawLogin,
      password: rawPassword,
    },
  };
  return server.postAuth
    .send(query)
    .pipe(onLoginAsyncSuccess, onLoginAsyncError)
    .exec();
};
const onLoginAsyncSuccess = (obj) => {
  if (!obj || !obj.response) {
    return;
  }

  const token = obj.response.headers.get('Authorization');
  if (token) {
    store.setToken(token);
  }

  checkLoginAsync();

  router.push({
    name: 'dashboard',
  });
};

const onLoginAsyncError = (error) => {
  loading.value = false;
  errorsArr.value = error.data?.messages;
};

const logOut = () => {
  store.logout();
  if (route.query.email) {
    router.push({
      name: 'login',
      query: { email: emailHint.value },
    });
  } else {
    router.push({
      name: 'login',
      query: route.query,
    });
  }
};

const checkLoginAsync = () => {
  loading.value = true;
  return server.getUserData
    .send()
    .pipe(onCheckLoginAsyncSuccess, () => {
      loading.value = false;
      logOut();
    })
    .exec();
};

const onCheckLoginAsyncSuccess = ({ data }) => {
  loading.value = false;

  store.session.login = isPhone(user.login)
    ? formatNumber(user.login)
    : user.login;
  store.setCurrentUser(data);
  store.loginSuccess();
  tracker.queue(tracker.commands.PARAMS, {
    user_param: store.companyOrderId,
    user_type: data.agent ? 'agent' : 'generic',
    order_id: store.companyOrderId,
    id: store.currentUser.id,
    borrower_company_id: store.currentUser.company_id,
    had_orders: store.currentUser.had_orders,
    had_loans: store.currentUser.had_loans,
  });

  tracker.queue(tracker.commands.SEND, SPA_LK_Login_Success);

  if (store.currentUser.agent) {
    loading.value = false;
    if (
      store.currentUser.agent_type === 'commercial' &&
      !isUserRegistered.value
    ) {
      emitter.emit('to-reg');
    } else {
      return useRedirection('/spa/users/sign_in');
    }
  } else {
    if (!isUserRegistered.value) {
      emitter.emit('to-reg');
    } else {
      store.fetchFeatures({
        onSuccess: () => {
          loading.value = false;
          emitter.emit('login-success');
        },
        onError: (error) => {
          loading.value = false;
          errorsArr.value = error.data?.messages;
        },
      });
    }
  }
};

onMounted(() => {
  user.login = store.session.login ? store.session.login : '';
  isEmailInput.value = !isPhone(mask.masked(user.login));
  checkLoginAsync();
  tracker.queue(tracker.commands.SEND, SPA_LK_Login_Page_View);
  getQueryParams();
  grabAndMergeAndCommitUtms();
});
</script>

<style lang="scss" scoped>
.form {
  max-width: 340px;

  &-row {
    position: relative;

    .input-with-icon {
      .icon {
        background-repeat: no-repeat;
        position: absolute;
        right: 20px;
        top: 24.5px;
        width: 16px;
        height: 16px;
        cursor: pointer;

        &:hover {
          filter: $filter-breakwater;
        }

        &-hidden {
          background: url(@/assets/icons/password/toggle_pass.svg);
        }

        &-show {
          background: url(@/assets/icons/password/toggle_pass_no.svg);
        }
      }
    }

    input {
      @include input;
    }

    .is-invalid {
      border: 1px solid rgba($red, 0.2);
    }

    .row-link {
      cursor: pointer;

      a {
        color: $primary;

        &:hover {
          color: $grey;
        }
      }

      &:last-child {
        a {
          color: $grey;

          &:hover {
            color: $primary;
          }
        }
      }
    }
  }
}
</style>
