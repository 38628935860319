<template>
  <div>
    <div class="feedback-wrapper">
      не устраивают условия?&nbsp;
      <a href="#" class="action-text" @click="onClickShowModalFeedback">
        расскажите →</a
      >
    </div>
    <FeedbackForm
      v-if="isShowModalFeedback"
      @close-modal="isShowModalFeedback = false"
    />
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue';
import tracker from '@/tracker';
import FeedbackForm from '@/components/_generic/auth/feedback-form/FeedbackForm.vue';

const isShowModalFeedback = ref(false);

const onClickShowModalFeedback = () => {
  tracker.queue(tracker.commands.SEND, 'SPA_LK_VKL_Conditions_Feedback_open');
  isShowModalFeedback.value = true;
};

onMounted(() => {
  tracker.queue(tracker.commands.SEND, 'SPA_LK_VKL_Conditions_Feedback_show');
});
</script>
<style lang="scss" scoped>
.feedback-wrapper {
  min-height: 44px;
  background-color: $background;
  border-radius: 8px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  line-height: 22px;
  color: $dipedive;
  width: 100%;
  padding: 0 15px;
  flex-wrap: wrap;
}
.action-text {
  color: $tiffany;
  margin-left: 10px;
  @media (max-width: $size_767) {
    margin-left: initial;
  }
}
.icon-feedback {
  display: block;
  background-image: url(@/assets/icons/feedback-icon.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: 50px;
  height: 50px;
  cursor: pointer;
  @media (max-width: $size_575) {
  }
}
</style>
