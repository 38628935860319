<template>
  <div class="input-money">
    <label class="input-money-label" :for="`input-${uuid}`">
      <input
        :id="`input-${uuid}`"
        v-model="value"
        v-money="moneyOptions"
        class="input-money-input"
        type="text"
        :disabled="props.disabled"
        @input="inputValue"
      />
    </label>
  </div>
</template>
<script setup>
import { computed } from 'vue';
import { VMoney as vMoney } from 'v-money';

const uuidv4 = function () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = (Math.random() * 16) | 0,
      v = c === 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  placeholder: {
    type: String,
    default: '',
  },
  input: {
    type: Function,
    default() {
      return null;
    },
  },
});
const emit = defineEmits(['update:model-value', 'inputValue']);

const moneyOptions = {
  decimal: '.',
  thousands: ' ',
  prefix: '',
  suffix: ' ₽',
  precision: 0,
  masked: true,
};
const uuid = uuidv4();

const value = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit('update:model-value', value);
  },
});

const inputValue = () => {
  emit('inputValue');
};
</script>
<style lang="scss" scoped>
label {
  width: 100%;
}

.input-money-input {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $dipedive;
  display: block;
  width: 100%;
  height: 60px;
  padding: 6px 20px;
  background-color: $background;
  border-radius: 8px;
  border: 0px;
  &:disabled {
    color: $grey;
  }
  &:focus-visible {
    outline: 0px;
  }
}

.input-money-input[data-mask-raw-value='0'] {
  color: $grey;
}
</style>
