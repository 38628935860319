<template>
  <div class="container pt-7 pb-7">
    <div class="form m-auto">
      <h1 class="h3 fw-bold mb-6 text-center">учредители</h1>
      <FoundersToggle
        v-model="isBusinessUser"
        :ul-founders-arr="props.ulFoundersArr"
        :ip-founders-arr="props.ipFoundersArr"
      />
      <template v-if="isBusinessUser">
        <div
          v-for="(form, idx) in ul_inputs.data"
          :key="idx"
          class="inputs-container mb-4 mt-4"
        >
          <div
            v-for="(input, index) in ul_inputs.inputs"
            :key="index"
            class="input-box"
            @click="onClickFillPassport(form, ul_inputs.data)"
          >
            <input
              type="text"
              :disabled="index !== 2"
              :value="
                form[input.type] ||
                (index !== 2 ? 'нет данных' : form.passport?.series_number)
              "
            />
            <span v-if="input.type === 'full_name'" class="placeholder"
              >{{ input.title }} {{ idx + 1 }}</span
            >
            <span v-if="input.type !== 'full_name'" class="placeholder">{{
              input.title
            }}</span>
            <div v-if="index === 2 && !form?.isCorrect" class="text-fill">
              заполнить &#8594;
            </div>
            <i
              class="icon"
              :class="{
                'icon-correct': isShownCorrectIconUL(input.type, form, index),
              }"
            ></i>
            <hr
              v-if="index !== ul_inputs.inputs.length - 1"
              class="divider mb-2"
            />
          </div>
        </div>
      </template>
      <template v-if="!isBusinessUser">
        <div
          v-for="(form, idx) in ip_inputs.data"
          :key="idx"
          class="inputs-container mb-4 mt-4"
        >
          <div
            v-for="(input, index) in ip_inputs.inputs"
            :key="index"
            class="input-box"
            @click="onClickFillPassport(form, ip_inputs.data)"
          >
            <input
              type="text"
              :disabled="index !== 1"
              :value="
                form[input.type] ||
                (index !== 1 ? 'нет данных' : form.passport?.series_number)
              "
            />
            <span class="placeholder">{{ input.title }}</span>
            <div v-if="index === 1 && !form?.isCorrect" class="text-fill">
              заполнить &#8594;
            </div>
            <i
              class="icon"
              :class="{
                'icon-correct': isShownCorrectIconIP(input.type, form),
              }"
            ></i>
            <hr
              v-if="index !== ip_inputs.inputs.length - 1"
              class="divider mb-2"
            />
          </div>
        </div>
      </template>
      <h6
        class="row-link fw-normal text-center mt-6"
        @click="changeComponent()"
      >
        назад
      </h6>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue';
import SummaryInfo from './UL_SummaryInfo.vue';
import FoundersToggle from './modules/FoundersToggle.vue';
import FounderPassport from '@/components/auth/user-passports/ul-user-type/modules/FounderPassport.vue';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_BKI_cofaunder_open } = TRACKER_EVENTS;

const emit = defineEmits(['change-component', 'get-user-data-async']);
const props = defineProps({
  ulFoundersArr: {
    type: Array,
    default() {
      return [];
    },
  },
  ipFoundersArr: {
    type: Array,
    default() {
      return [];
    },
  },
  errorsArr: {
    type: Array,
    default() {
      return [];
    },
  },
  countdown: {
    type: Object,
    default() {
      return {};
    },
  },
  loading: {
    type: Boolean,
    default: false,
  },
  isFoundersPassportsFilled: {
    type: Boolean,
    default: false,
  },
});
const store = useStateStore();

onMounted(() => {
  tracker.queue(tracker.commands.SEND, SPA_LK_BKI_cofaunder_open);
});

const isBusinessUser = ref(false);

const ip_inputs = ref({
  data: computed(() => {
    return props.ipFoundersArr;
  }),
  inputs: [
    {
      title: 'ФИО',
      type: 'full_name',
    },
    {
      title: 'паспортные данные',
    },
    {
      title: 'доля',
      type: 'share',
    },
  ],
});
const ul_inputs = ref({
  data: computed(() => {
    return props.ulFoundersArr;
  }),
  inputs: [
    {
      title: 'название компании',
      type: 'title',
    },
    {
      title: 'учредитель',
      type: 'full_name',
    },
    {
      title: 'паспортные данные',
    },
    {
      title: 'доля',
      type: 'share',
    },
  ],
});
const isShownCorrectIconUL = (type, form, index) => {
  return (
    (type === 'title' && form.title) ||
    (type === 'full_name' && form.full_name) ||
    (index === 2 && form.isCorrect) ||
    (type === 'share' && form.share)
  );
};
const isShownCorrectIconIP = (type, form) => {
  return (
    (type === 'full_name' && form.full_name) ||
    (!/full_name|share/.test(type) && form.isCorrect) ||
    (type === 'share' && form.share)
  );
};
// при клике, передаём данные нажатого элемента из массива inputs.data и сам массив inputs.data
const onClickFillPassport = (form, data) => {
  // пушим в массив unFilledFounders все элементы с id неравным нажатому
  data?.forEach((el) => {
    if (el.id !== form.id) {
      store.currentFounder.unFilledFounders.push({
        id: el.id,
        full_name: el.full_name,
        passport: el.passport,
        contact_phone: el.contact_phone,
      });
    }
  });
  // делаем текущими данные нажатого элемента
  store.currentFounder.id = form.id;
  store.currentFounder.name = form.full_name;
  store.currentFounder.phone = form.contact_phone;
  form.passport?.series_number
    ? (form.passport.series = form.passport?.series_number.slice(0, 4))
    : null;
  form.passport?.series_number
    ? (form.passport.number = form.passport?.series_number.slice(5, 11))
    : null;
  // делаем текущими паспортными данными, паспортные данные нажатого элемента
  // если объект passport пришёл с бэка пустым, очищаем значения текущих паспортных данных
  form.passport !== null
    ? (store.currentFounder.passport = form.passport)
    : Object.keys(store.currentFounder.passport).forEach(
        (key) => (store.currentFounder.passport[key] = ''),
      );
  emit('change-component', FounderPassport);
};
const changeComponent = () => {
  emit('change-component', SummaryInfo);
  emit('get-user-data-async');
};
</script>

<style lang="scss" scoped>
.container {
  max-width: 460px;
  .form {
    .inputs-container {
      background-color: $background;
      border-radius: 14px;
      padding: 20px 0px;
      .input-box {
        position: relative;
        margin: 0px 10px;
        &:hover input {
          background-color: white;
        }
        input {
          @include input;
          height: 78px;
          border: none;
          padding: 20px 40px 20px 20px;
          cursor: pointer;
          border-radius: 14px;
          margin-bottom: 8px;
        }
        .placeholder {
          position: absolute;
          left: 20px;
          top: 10px;
          font-size: 8px;
          line-height: 12px;
          letter-spacing: 0.1em;
          text-transform: uppercase;
          color: $grey;
        }
        .text-fill {
          position: absolute;
          right: 20px;
          top: 30px;
          cursor: pointer;
          color: $tiffany;
        }
        .icon {
          position: absolute;
          right: 20px;
          top: 30px;
          width: 20px;
          height: 20px;
          background-repeat: no-repeat;
          background-position: center;
          &-correct {
            pointer-events: none;
            background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
            filter: $filter-breakwater;
          }
        }
        .divider {
          border-top: 1px solid $grey;
          margin: 0px;
        }
      }
    }
    .row-link {
      cursor: pointer;
      color: $grey;
      &:hover {
        color: $breakwater;
      }
    }
  }
}
</style>
