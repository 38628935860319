import { inject } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { useFlowManager } from '@/services/FlowManager';
import { useRouter } from 'vue-router';
import tracker from '@/tracker';
import constants from '@/constants';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Onbording_success } = TRACKER_EVENTS;

const { LIMIT_UPDATE_STATES } = constants;

export function useOnboardingEvents() {
  const router = useRouter();
  const store = useStateStore();
  const flowManager = useFlowManager();
  const emitter = inject('emitter');
  const VKL_REDIRECT_STATES = [
    constants.CLIENT_STATES.user_verifying,
    constants.CLIENT_STATES.manual_check,
    constants.CLIENT_STATES.ready,
    constants.CLIENT_STATES.investing,
  ];

  emitter.on('statement-upload-success', () => {
    // ВКЛ флоу переходы после обновление выписки
    if (store.isVKLFlow && store.currentOrder?.id) {
      if (VKL_REDIRECT_STATES.includes(store.currentOrder.client_state)) {
        return router.push({
          name: 'loanCard',
          params: {
            order_id: store.currentOrder.id,
          },
        });
      }

      return router.push({ name: 'dashboard' });
    }
    // мозаичный флоу обновления лимита
    if (store.uiStates.limitUpdateState === LIMIT_UPDATE_STATES.statement) {
      store.uiStates.limitUpdateState = undefined;
      store.fetchCompanies({
        expand: ['funds', 'limits'],
        onSuccess: () => router.push({ name: 'dashboard' }),
      });
      return;
    }
    // если у пользователя повторная заявка, то ведём его по линейному флоу выписка-анкета-БКИ
    if (store.isHadOrders) {
      router.push({
        name: 'companyProfile',
      });
      return;
    }
    // если у пользователя первая заявка, то при выполнении третьего шага ведём его на выбор условий, иначе на онбординг
    if (store.isBkiSigned && store.isCompanyFilled) {
      tracker.queue(tracker.commands.SEND, SPA_LK_Onbording_success);
      store.setUiState('isDashboardLimitTabActive', true);
      store.fetchCompanies({
        expand: ['funds', 'limits'],
        onSuccess: () => router.push({ name: 'dashboard' }),
      });
    } else {
      store.fetchCompanies({
        onSuccess: () => flowManager.isGoToDashboard(),
      });
    }
  });
  emitter.on('company-profile-success', () => {
    // мозаичный флоу обновления лимита
    if (store.uiStates.limitUpdateState === LIMIT_UPDATE_STATES.company) {
      store.uiStates.limitUpdateState = undefined;
      store.fetchCompanies({
        expand: ['funds', 'limits'],
        onSuccess: () => router.push({ name: 'dashboard' }),
      });
      return;
    }
    if (store.isHadOrders) {
      router.push({
        name: 'userPassports',
      });
      return;
    }

    if (store.isAPIPartnersFlow && store.isPartnerStatementRequired) {
      store.fetchCompanies({
        // onSuccess: () =>
        // router.push({
        //   name: 'onboarding',
        // }),
      });
      return;
    }

    if (store.isBkiSigned && store.isStatementUploaded) {
      tracker.queue(tracker.commands.SEND, SPA_LK_Onbording_success);
      store.setUiState('isDashboardLimitTabActive', true);
      store.fetchCompanies({
        expand: ['funds', 'limits'],
        onSuccess: () => router.push({ name: 'dashboard' }),
      });
    } else {
      store.fetchCompanies({
        onSuccess: () => flowManager.isGoToDashboard(),
      });
    }
  });
  emitter.on('agreement-sign-success', () => {
    // мозаичный флоу обновления лимита
    if (store.uiStates.limitUpdateState === LIMIT_UPDATE_STATES.agreement) {
      store.uiStates.limitUpdateState = undefined;
      store.fetchCompanies({
        expand: ['funds', 'limits'],
        onSuccess: () => router.push({ name: 'dashboard' }),
      });
      return;
    }
    if (store.isHadOrders) {
      store.setUiState('isDashboardLimitTabActive', true);
      store.fetchCompanies({
        expand: ['funds', 'limits'],
        onSuccess: () => router.push({ name: 'dashboard' }),
      });
      return;
    }

    if (store.isAPIPartnersFlow && store.isPartnerStatementRequired) {
      store.fetchCompanies({
        // onSuccess: () =>
        //   router.push({
        //     name: 'onboarding',
        //   }),
      });
      return;
    }

    if (store.isCompanyFilled && store.isStatementUploaded) {
      tracker.queue(tracker.commands.SEND, SPA_LK_Onbording_success);
      store.setUiState('isDashboardLimitTabActive', true);
      store.fetchCompanies({
        expand: ['funds', 'limits'],
        onSuccess: () => router.push({ name: 'dashboard' }),
      });
    } else {
      store.fetchCompanies({
        onSuccess: () => flowManager.isGoToDashboard(),
      });
    }
  });
}
