<template>
  <button class="loading-button button-transp">
    <span class="loader"></span>
  </button>
</template>

<script setup>
const props = defineProps({
  width: {
    type: String,
    required: false,
    default: '100%',
  },
  padding: {
    type: String,
    required: false,
    default: '29px 0',
  },
  margin: {
    type: String,
    required: false,
    default: '20px',
  },
});
</script>

<style lang="scss" scoped>
.loading-button {
  position: relative;
  @include button-primary;
  width: v-bind('props.width');
  padding: v-bind('props.padding');
  color: white;
  &:hover .loader {
    background: #173b57;
  }
}
.button-transp {
  background-color: transparent;
  .loader {
    background-color: $primary;
  }
  &:hover {
    background-color: $primary;
    .loader {
      background-color: $white;
    }
  }
}
</style>

<style scoped>
.loader {
  --load_width: 20px;
  position: absolute;
  top: 0%;
  left: calc(50% - var(--load_width) / 2);
  width: var(--load_width);
  padding: 3px;
  margin-top: v-bind('props.margin');
  aspect-ratio: 1;
  border-radius: 50%;
  background: white;
  --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
  -webkit-mask: var(--_m);
  mask: var(--_m);
  -webkit-mask-composite: source-out;
  mask-composite: subtract;
  animation: s3 1s infinite linear;
}
@keyframes s3 {
  to {
    transform: rotate(1turn);
  }
}
</style>
