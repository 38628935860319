<template>
  <button class="back-btn" type="button">&#8592; назад</button>
</template>

<script setup>
import { toRefs } from 'vue';

const props = defineProps({
  bgColor: {
    type: String,
    default: '#f2f4f8',
  },
});
const { bgColor } = toRefs(props);
</script>

<style lang="scss" scoped>
.back-btn {
  border: none;
  background: v-bind('bgColor');
  border-radius: 22px;
  padding: 4px 10px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $dipedive;
}
</style>
