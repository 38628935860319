import config from '@/../config';

export default {
  getOrders: {
    url: config.VITE_BASE_URL + `/spa/business/orders`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  postOrders: {
    url: config.VITE_BASE_URL + `/spa/business/orders`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  getOrders$order_id: {
    url: config.VITE_BASE_URL + `/spa/business/orders/$order_id`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
  postOrderTermsAcceptance: {
    url:
      config.VITE_BASE_URL +
      `/spa/business/orders/$order_id/loan_terms_acceptances`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  postInn: {
    url: config.VITE_BASE_URL + `/spa/orders`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  postPromo: {
    url: config.VITE_BASE_URL + `/spa/business/orders/$order_id/promo_codes`,
    cache: 'no-cache',
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
  getManagerInfo: {
    url: config.VITE_BASE_URL + `/spa/business/orders/$order_id/manager`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
};
