<template>
  <PhoneConfirmation
    class="pt-7"
    :left-time="leftTime"
    :loading="loading"
    :errors-arr="errorsArr"
    @confirm-otp-async="confirmOtpAsync"
    @send-otp-async="sendOtpAsync"
    @clear-errors="clearErrors"
  />
  <div class="form-row mt-4">
    <div
      class="row-link fw-normal m-auto"
      @click="loading ? null : emit('change-component', AskUserPhone)"
    >
      назад
    </div>
  </div>
</template>

<script setup>
import PhoneConfirmation from '@/components/_generic/unauth/PhoneConfirmation.vue';
import AskUserPhone from './AskUserPhone.vue';
import SetUserPassword from './SetUserPassword.vue';
import { ref, onMounted } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import server from '@/server/index';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Registration_Phone_check_sms_again,
  SPA_LK_Registration_Phone_check_success,
  step_2_contacts,
} = TRACKER_EVENTS;

const props = defineProps({
  countdown: {
    type: Object,
    default() {
      return {};
    },
  },
});
const emit = defineEmits(['change-component']);

const route = useRoute();
const router = useRouter();
const errorsArr = ref([]);
const loading = ref(false);
const leftTime = ref(0);

const countdownTimer = props.countdown;

onMounted(() => {
  sendOtpAsync();
  countdownTimer.onTick = (val) => {
    leftTime.value = val;
  };
});
const clearErrors = () => {
  errorsArr.value = [];
};
const sendOtpAsync = () => {
  loading.value = true;
  return server.postRequestOtp$Phone
    .send()
    .pipe(onSendOtpAsyncSuccess, onSendOtpAsyncError)
    .exec();
};
const onSendOtpAsyncSuccess = () => {
  props.countdown.reset();
  props.countdown.start();
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Registration_Phone_check_sms_again,
  );
  loading.value = false;
};
const onSendOtpAsyncError = (error) => {
  loading.value = false;
  errorsArr.value = error.data?.messages;
};
const confirmOtpAsync = (rawOtp) => {
  const query = {
    otp: rawOtp,
  };
  loading.value = true;
  return server.postUsersConfirmOtp
    .send(query)
    .pipe(onConfirmOtpAsyncSuccess, onConfirmOtpAsyncError)
    .exec();
};
const onConfirmOtpAsyncSuccess = () => {
  loading.value = false;
  if (route.query?.step) {
    return router.push('/login');
  }

  tracker.queue(tracker.commands.SEND, SPA_LK_Registration_Phone_check_success);
  tracker.queue(tracker.commands.SEND, step_2_contacts);
  emit('change-component', SetUserPassword);
};
const onConfirmOtpAsyncError = (error) => {
  loading.value = false;
  errorsArr.value = error.data?.messages;
};
</script>

<style lang="scss" scoped>
.form-row {
  .row-link {
    color: $grey;
    width: fit-content;
    cursor: pointer;
    &:hover {
      color: $grey-hover;
    }
  }
}
</style>
