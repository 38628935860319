<template>
  <ModalFull :maxwidth="'100%'" @close-modal="emit('close')"
    ><template #content>
      <PaymentSummaryDesktop />
    </template>
  </ModalFull>
</template>
<script setup>
import ModalFull from '@/components/_generic/ui/modals/ModalFull.vue';
import PaymentSummaryDesktop from '@/components/auth/dashboard/payment-summary/PaymentSummaryDesktop.vue';

const emit = defineEmits(['close']);
</script>
<style></style>
