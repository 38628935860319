import { ref } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';
import tracker from '@/tracker';
import { generalUtils } from '@/modules/generalUtils.js';

export function ordersUtils() {
  const { getOrderAsync } = generalUtils();

  const orders = ref([]);
  const loadingOrders = ref(false);
  const initiated = ref(false);
  const store = useStateStore();

  const page = ref(1);
  const perPage = ref(5);
  const totalPages = ref(0);

  const getOrdersAsync = (options) => {
    if (options.isLoaderShown) {
      loadingOrders.value = true;
    }
    const query = {
      'company_ids[]': options.companyId,
      'expand[]': ['loan', 'loan_terms_offer', 'payments'],
      page: page.value,
      per: perPage.value,
      'filters[]': [],
    };
    if (store.orders?.filters?.selectedSortType) {
      if (store.orders.filters.selectedSortType === 'sort_payment') {
        query.sort_by =
          'common_schedule_payment_date' +
          (store.orders?.filters?.selectedSortUp ? '_asc' : '_desc');
      }
      if (store.orders.filters.selectedSortType === 'sort_form') {
        query.sort_by =
          'created_at' +
          (store.orders?.filters?.selectedSortUp ? '_asc' : '_desc');
      }
    }
    if (store.orders?.filters?.selectedFilter === 'open') {
      query['filters[]'].push('opened');
    }
    if (store.orders?.filters?.selectedFilter === 'closed') {
      query['filters[]'].push('closed');
    }
    return server.getOrders
      .send(query, {})
      .pipe(onGetOrdersAsyncSuccess, onGetOrdersAsyncError)
      .exec();
  };
  const onGetOrdersAsyncSuccess = ({ response, data }) => {
    totalPages.value = Number(response.headers.get('x-totalpages'));
    page.value === 1
      ? (orders.value = data)
      : (orders.value = [...orders.value, ...data]);
    initiated.value = true;
    store.uiStates.isDashboardLimitTabActive
      ? (loadingOrders.value = false)
      : getOrderAsync({
          callback: (data) => {
            onGetOrderAsyncSuccess({ data });
          },
        });
  };
  const onGetOrdersAsyncError = () => {
    loadingOrders.value = false;
    initiated.value = true;
  };

  const onGetOrderAsyncSuccess = ({ data }) => {
    store.setCurrentOrder(data);
    loadingOrders.value = false;
    // передаем признак наличия холда
    tracker.queue(tracker.commands.PARAMS, {
      is_user_in_hold: store.currentOrder.loan_terms_offer?.hold_manual_scoring,
    });
  };

  return {
    orders,
    loadingOrders,
    initiated,
    page,
    perPage,
    totalPages,
    getOrdersAsync,
  };
}
