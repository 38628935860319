import { ref } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';

const isOrderTabHovered = ref(false);
const isLimitTabHovered = ref(false);
const loading = ref(false);
const limits = ref({});

export function headUtils() {
  const store = useStateStore();

  const getCompanyLimit = () => {
    loading.value = true;
    let query = {
      company_id: store.currentCompany.id,
    };
    return server.getCompanyLimits
      .send(
        {},
        {
          params: query,
        },
      )
      .pipe(
        ({ data }) => {
          loading.value = false;
          limits.value = data;
        },
        () => {
          loading.value = false;
        },
      )
      .exec();
  };

  const applyFormStyles = (limitTab) => {
    if (limitTab) {
      if (store.uiStates.isDashboardLimitTabActive) {
        return 'bg-white';
      } else if (isLimitTabHovered.value) {
        return 'bg-input cursor-pointer';
      } else {
        return 'bg-desert cursor-pointer';
      }
    } else {
      if (isOrderTabHovered.value && store.uiStates.isDashboardLimitTabActive) {
        return 'bg-input cursor-pointer';
      } else if (store.uiStates.isDashboardLimitTabActive) {
        return 'bg-desert cursor-pointer';
      } else {
        return 'bg-white';
      }
    }
  };

  const onClickSwitchTab = (value) => {
    store.setUiState('isDashboardLimitTabActive', value);
  };

  return {
    loading,
    limits,
    isOrderTabHovered,
    isLimitTabHovered,
    getCompanyLimit,
    onClickSwitchTab,
    applyFormStyles,
  };
}
