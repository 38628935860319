import { useRouter } from 'vue-router';
import { useStateStore } from '@/stores/stateStore';
import { ref, inject, computed } from 'vue';
import server from '@/server/index';
import tracker from '@/tracker';

import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Loan_comission_pay_click,
  SPA_LK_Loan_main_page_click,
  SPA_LK_Loan_balance_add_click,
  SPA_LK_Loan_repay_partly_click,
  SPA_LK_Loan_repay_early_click,
  SPA_LK_Loan_docks_click,
} = TRACKER_EVENTS;

export function loanCardUtils() {
  const router = useRouter();
  const store = useStateStore();
  const order = ref({});
  const isDocsSent = ref(false);
  const loading = ref(false);
  const errorsArr = ref([]);
  const emitter = inject('emitter');

  const isCHDPEnabled = computed(() => {
    return order.value?.loan?.allow_partial_early_payments;
  });

  const isPDPEnabled = computed(() => {
    return order.value?.loan?.allow_full_early_payments;
  });

  const onClickGoToCommission = () => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Loan_comission_pay_click);
    store.setMonthlyCommission(
      order.value.loan?.payments?.next_payment?.monthly_commission || 0,
    );
    store.setMonthlyCommissionOverdue(
      order.value.loan?.payments?.overdue?.monthly_commission || 0,
    );
    router.push({
      name: 'commissionPayment',
    });
  };

  const onClickGoToDashboard = () => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Loan_main_page_click);
    emitter.emit('to-dashboard');
  };
  const onClickGoToRefill = () => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Loan_balance_add_click);
    router.push({ name: 'accountRefill' });
  };
  const onClickGoToCHDP = () => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Loan_repay_partly_click);
    router.push({ name: 'loanPartialRepayment' });
  };
  const onClickGoToPDP = () => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Loan_repay_early_click);
    router.push({ name: 'loanFullRepayment' });
  };

  const getLoanContractsAsync = () => {
    loading.value = true;
    const query = {
      loan_id: order.value.loan_id,
    };
    return server.postArchiveLoanContracts
      .send(query)
      .pipe(getLoanContractsAsyncSuccess, getLoanContractsAsyncError)
      .exec();
  };
  const getLoanContractsAsyncSuccess = () => {
    tracker.queue(tracker.commands.SEND, SPA_LK_Loan_docks_click);
    isDocsSent.value = true;
    loading.value = false;
  };
  const getLoanContractsAsyncError = (error) => {
    errorsArr.value = error.data?.messages;
    loading.value = false;
  };

  const getScheduleError = (value) => {
    errorsArr.value = value;
  };

  return {
    order,
    onClickGoToCommission,
    onClickGoToDashboard,
    onClickGoToRefill,
    onClickGoToCHDP,
    onClickGoToPDP,
    getLoanContractsAsync,
    getScheduleError,
    isCHDPEnabled,
    isPDPEnabled,
    isDocsSent,
    loading,
    errorsArr,
  };
}
