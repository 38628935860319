import { ref } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';
import { useRoute } from 'vue-router';

export function generalUtils() {
  const store = useStateStore();
  const route = useRoute();

  const loading = ref(false);

  const getOrderAsync = (options) => {
    let query = {};
    if (options.query) {
      query = options.query;
    }
    if (options.isLoading) {
      loading.value = true;
    }

    const orderId = route.params?.order_id || store.companyOrderId;
    return server.getOrders$order_id
      .send(query, {
        params: {
          order_id: orderId,
        },
      })
      .pipe(onGetOrderAsyncSuccess.bind(this, options), onGetOrderAsyncError)
      .exec();
  };
  const onGetOrderAsyncSuccess = (options, { data }) => {
    store.setCurrentOrder(data);
    loading.value = false;
    if (options.callback) {
      options.callback(data);
    }
  };
  const onGetOrderAsyncError = () => {
    loading.value = false;
  };

  return {
    getOrderAsync,
    loading,
  };
}
