import { createRouter, createWebHistory } from 'vue-router';
import routes from './routes/index';

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export const alwaysAvailablePaths = new Set([
  'home',
  'login',
  'loginRecover',
  'agentReg',
  'registration',
  'continueRegistrations',
  'continue_registrations_ml',
  'userCredentials',
  'setPassword',
  'emailConfirmation',
  'phoneConfirmation',
  'setEmail',
]);

router.beforeEach((to, from, next) => {
  const publicPages = [
    '/',
    '/login',
    '/login/recover',
    '/registration',
    '/registration/agent',
    '/continue_registrations',
    '/user_credentials',
    '/set_password',
    '/email_confirmation',
    '/phone_confirmation',
    '/set_email',
    '/orders/:id/statement_files',
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');
  // trying to access a restricted page + not logged in + not impers => redirect to login page
  if (authRequired && !loggedIn) {
    next('/login');
  } else {
    next();
  }
});

export default router;
