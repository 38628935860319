<template>
  <div class="container pt-7">
    <div class="form w-100 m-auto">
      <h1 class="h3 fw-bold text-center mb-sm-6 mb-5">регистрация</h1>
      <div class="form-row">
        <input
          id="phone"
          v-model="v$.rawPhone.$model"
          v-maska
          type="tel"
          :class="{
            'is-invalid': v$.rawPhone.$invalid && v$.rawPhone.$dirty,
          }"
          placeholder="+7 (999) 999-99-99"
          :data-maska="masks.phone()"
          @keyup.enter="sendPhoneAsync()"
        />
      </div>
      <div class="text-danger mb-5 mt-2">
        <div v-if="v$.rawPhone.phoneRegex.$invalid && v$.rawPhone.$dirty">
          неверный формат
        </div>
        <ErrorItem v-if="errorsArr.length !== 0" v-model="errorsArr" />
      </div>
      <div class="form-row mb-4">
        <PotokButton
          v-if="!loading"
          id="continue"
          class="btn-continue w-100"
          :disabled="v$.rawPhone.$invalid"
          text="продолжить"
          size="large"
          @click="sendPhoneAsync()"
        />
        <LoadingBtn v-if="loading" />
      </div>
      <div class="form-row">
        <h6 class="h6 fw-normal text-center row-link">
          <a @click="loading ? null : router.push('/login')">вход</a>
        </h6>
      </div>
    </div>
    <div class="text-wrapper m-auto">
      <div class="agreement-text text-center">
        регистрируясь, вы соглашаетесь на обработку
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
          target="_blank"
          >персональных данных</a
        >
        в соответствии с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Политика обработки персональных данных.pdf"
          target="_blank"
          >политикой</a
        >, ознакомлены с
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Правила Инвестиционной платформы Поток.pdf"
          target="_blank"
          >правилами платформы</a
        >, и согласны с использованием
        <a
          href="https://files.potok.digital/Документы/раздел правила платформы/Соглашение участников электронного взаимодействия_Инвестплатформа Поток.pdf"
          target="_blank"
          >простой электронной подписи</a
        >
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';

import LoadingBtn from '@/components/_generic/ui/loaders/LoadingBtn.vue';
import PhoneConfirmation from './PhoneConfirmation.vue';
import useVuelidate from '@vuelidate/core';
import ErrorItem from '@/components/_generic/ui/widgets/ErrorItem.vue';
import { helpers, required } from '@vuelidate/validators';
import { vMaska } from 'maska';
import masks from '@/libs/js-text-masks';
import { ref, reactive, onMounted, inject } from 'vue';
import { useStateStore } from '@/stores/stateStore';
import { useRouter } from 'vue-router';
import server from '@/server/index';
import tracker from '@/tracker';
import { formatNumber } from '@/utils';
import { TRACKER_EVENTS } from '@/tracker/events';

const {
  SPA_LK_Registration_show_screen_Registration,
  step1_1_first_order,
  step1_1,
  SPA_LK_Registration_show_screen_Phone_check,
} = TRACKER_EVENTS;

const store = useStateStore();

const router = useRouter();

const errorsArr = ref([]);
const rawPhoneRef = reactive({
  rawPhone: '',
});
const loading = ref(false);

const phoneRegex = helpers.regex(/^(\+7[" "])(\(\9)/);

const validations = {
  rawPhone: {
    required,
    phoneRegex,
  },
};

const v$ = useVuelidate(validations, rawPhoneRef);

const emit = defineEmits(['change-component']);

onMounted(() => {
  sendAnalytics();
});

const sendAnalytics = () => {
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Registration_show_screen_Registration,
  );
  Number(inject('$cookies').get('orders_cnt')) === 1
    ? tracker.queue(tracker.commands.SEND, step1_1_first_order)
    : tracker.queue(tracker.commands.SEND, step1_1);
};
const sendPhoneAsync = () => {
  loading.value = true;
  const query = {
    user: {
      phone: formatNumber(rawPhoneRef.rawPhone),
    },
  };
  return server.postUserPhoneRegister
    .send(query)
    .pipe(onSendPhoneAsyncSuccess, onSendPhoneAsyncError)
    .exec();
};
const onSendPhoneAsyncSuccess = ({ data }) => {
  store.setCurrentUser(data);
  store.loginSuccess();
  tracker.queue(tracker.commands.PARAMS, {
    user_param: store.companyOrderId,
    user_type: data.agent ? 'agent' : 'generic',
    order_id: store.companyOrderId,
    id: store.currentUser.id,
    borrower_company_id: store.currentUser.company_id,
  });
  loading.value = false;
  tracker.queue(
    tracker.commands.SEND,
    SPA_LK_Registration_show_screen_Phone_check,
  );
  emit('change-component', PhoneConfirmation);
};
const onSendPhoneAsyncError = (error) => {
  loading.value = false;
  errorsArr.value = error.data?.messages;
  errorsArr.value.some((el) => el.includes('уже используется'))
    ? setTimeout(() => router.push('/login'), 3000)
    : null;
};
</script>

<style lang="scss" scoped>
.btn-continue {
  margin: auto;
}
.form {
  max-width: 340px;
  &-row {
    position: relative;
    input {
      @include input;
    }
    .is-invalid {
      border: 1px solid rgba($red, 0.2);
    }
    .row-link {
      margin-bottom: 40px;
      cursor: pointer;
      a {
        color: $primary;
        &:hover {
          color: $link-hover-color;
        }
      }
    }
  }
}
.text-wrapper {
  max-width: 670px;
  .agreement-text {
    color: $grey;
    margin-top: 225px;
    @media (max-width: $size_767) {
      margin-top: 289px;
      font-size: 10px;
    }
  }
}
</style>
