<template>
  <div @mouseover="isHovering = true" @mouseout="isHovering = false">
    <div class="d-flex justify-content-between mb-0 mb-sm-2 align-items-center">
      <div
        class="d-flex"
        :class="{ 'w-100 justify-content-between': isMobile }"
        style="flex: 1"
      >
        <div class="text-title">
          заявка № {{ order.id }} <br v-if="isMobile" />
          <span v-if="order.created_at" class="color-grey text-title-grey"
            >от {{ formatDate(order.created_at) }}</span
          >
        </div>
        <OrderStatus :order="order" />
      </div>
      <div v-if="!isMobile" class="d-flex align-items-center">
        <CommissionBtn :order="order" />
        <OrderBtn :order="order" :is-hovering="isHovering" />
      </div>
    </div>
    <div
      :class="[isMobile ? 'd-block' : 'd-flex justify-content-between mt-6']"
    >
      <span v-if="store.isBeforeCustomParams" class="color-grey"
        >сумма и срок будут известны после<br />заполнения всех данных</span
      >
      <ParamsTable v-else :order="order" />
      <ProgressBar
        v-if="
          order.loan?.state === LOAN_STATES.billing ||
          order.loan?.state === LOAN_STATES.overdue
        "
        class="mt-3"
        :class="{ 'mb-3': isMobile }"
        :order="props.order"
      />
      <div v-if="isMobile" class="mt-0 mt-sm-3">
        <CommissionBtn :order="order" />
        <OrderBtn :order="order" />
      </div>
      <div
        v-if="order.loan?.state === LOAN_STATES.in_cession"
        class="button-wrapper mt-sm-0 mt-3"
      >
        <PotokButton text="погасить долг" size="medium" theme="secondary" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import CommissionBtn from '@/components/auth/dashboard/orders/CommissionBtn.vue';
import ParamsTable from '@/components/auth/dashboard/orders/ParamsTable.vue';
import OrderBtn from '@/components/auth/dashboard/orders/OrderBtn.vue';
import OrderStatus from '@/components/auth/dashboard/orders/OrderStatus.vue';
import ProgressBar from '@/components/auth/dashboard/orders/ProgressBar.vue';
import { ref } from 'vue';
import { formatDate } from '@/utils';
import { detectMobile } from '@/utils';
import { toRefs } from 'vue';
import constants from '@/constants';
import { useStateStore } from '@/stores/stateStore';

const { LOAN_STATES } = constants;
const { isMobile } = detectMobile();
const store = useStateStore();

const isHovering = ref(false);

const props = defineProps({
  order: {
    type: Object,
    required: true,
    default() {
      return {};
    },
  },
});
const { order } = toRefs(props);
</script>

<style lang="scss" scoped>
.button-wrapper {
  width: 150px;
  @media (max-width: $size_767) {
    width: 100%;
  }
}
</style>
