<template>
  <template v-if="isShowCommissionBtn">
    <template v-if="!isMobile">
      <div
        class="order-commission d-flex align-items-center small cursor-pointer"
        @click="onClickGoToCommission()"
      >
        <i
          :class="{
            'icon-overdue': order.loan?.payments?.overdue?.monthly_commission,
          }"
        ></i
        ><button
          class="commission-btn"
          :class="{
            'btn-overdue': order.loan?.payments?.overdue?.monthly_commission,
          }"
        >
          оплатить комиссию
        </button>
      </div>
    </template>
    <template v-if="isMobile">
      <PotokButton
        class="mb-3"
        text="оплатить комиссию"
        size="medium"
        @click="onClickGoToCommission()"
      />
    </template>
  </template>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import { useRouter } from 'vue-router';
import { detectMobile } from '@/utils';
import { useStateStore } from '@/stores/stateStore';
import { computed, toRefs } from 'vue';
import constants from '@/constants';

const router = useRouter();
const store = useStateStore();
const { isMobile } = detectMobile();
const { LOAN_STATES } = constants;

const props = defineProps({
  order: {
    type: Object,
    required: true,
  },
});
const { order } = toRefs(props);

const isShowCommissionBtn = computed(() => {
  if (store.isBillingMonthlyCommission) {
    return false;
  }
  return (
    order.value.monthly_commission_percent !== 0 &&
    order.value.loan &&
    order.value.loan?.state !== LOAN_STATES.repaid &&
    order.value.loan?.state !== LOAN_STATES.in_default
  );
});

const onClickGoToCommission = () => {
  store.setCurrentOrder(order.value);
  store.setMonthlyCommission(
    order.value.loan?.payments?.next_payment?.monthly_commission || 0,
  );
  store.setMonthlyCommissionOverdue(
    order.value.loan?.payments?.overdue?.monthly_commission || 0,
  );
  router.push({
    name: 'commissionPayment',
  });
};
</script>

<style lang="scss" scoped>
.order-commission {
  &:hover {
    .commission-btn {
      opacity: 0.6;
    }

    i {
      opacity: 0.6;
    }
  }

  .commission-btn {
    background-color: transparent;
    border: none;
    color: $tiffany;
    padding: 0;
    margin-left: 4px;
  }

  i {
    width: 15px;
    height: 15px;
    background-image: url(@/assets/icons/rounded/discount.svg);
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
  }

  .icon-overdue {
    filter: $filter-danger;
  }

  .btn-overdue {
    color: $danger;
  }
}
</style>
