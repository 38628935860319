<template>
  <div
    class="position-relative d-inline-block cursor-pointer"
    @mouseover="isPopShown = true"
    @mouseleave="isPopShown = false"
  >
    <div ref="buttonRef">
      <slot name="icon"></slot>
    </div>
    <div
      v-if="isPopShown"
      ref="contentRef"
      class="content-wrapper"
      :class="{
        left: props.position === 'left',
        right: props.position === 'right',
      }"
      :style="{
        width: props.width,
        'min-width': props.minwidth,
      }"
    >
      <slot name="text"></slot>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, nextTick } from 'vue';

const props = defineProps({
  position: {
    type: String,
    default: 'left',
  },
  width: {
    type: String,
    default: 'auto',
  },
  minwidth: {
    type: String,
    default: 'initial',
  },
});
const isPopShown = ref(false);
const contentRef = ref(null);
const buttonRef = ref(null);

watch(
  () => {
    return isPopShown.value;
  },
  (value) => {
    if (value) {
      nextTick(() => {
        calculatePosition();
      });
    }
  },
);

const calculatePosition = () => {
  if (props.position === 'right_center') {
    const elContentWidth = contentRef.value.offsetWidth;
    const elContentHeight = contentRef.value.offsetHeight;
    const elBtnHeight = buttonRef.value.offsetHeight;
    contentRef.value.style.right = `${elContentWidth * -1 - 15}px`;
    contentRef.value.style.top = `${
      ((elContentHeight - elBtnHeight) * -1) / 2
    }px`;
  }
  if (props.position === 'left_center') {
    const elContentWidth = contentRef.value.offsetWidth;
    const elContentHeight = contentRef.value.offsetHeight;
    const elBtnWidth = buttonRef.value.offsetWidth;
    const elBtnHeight = buttonRef.value.offsetHeight;
    contentRef.value.style.left = `${
      (elContentWidth + elBtnWidth) * -1 + 15
    }px`;
    contentRef.value.style.top = `${
      ((elContentHeight - elBtnHeight) * -1) / 2
    }px`;
  }
  if (props.position === 'top_center') {
    const elContentWidth = contentRef.value.offsetWidth;
    const elContentHeight = contentRef.value.offsetHeight;
    const elBtnWidth = buttonRef.value.offsetWidth;
    contentRef.value.style.left = `${
      ((elContentWidth - elBtnWidth) * -1) / 2
    }px`;
    contentRef.value.style.top = `${elContentHeight * -1 - 15}px`;
  }
  if (props.position === 'bottom_center') {
    const elContentWidth = contentRef.value.offsetWidth;
    const elContentHeight = contentRef.value.offsetHeight;
    const elBtnWidth = buttonRef.value.offsetWidth;
    contentRef.value.style.left = `${
      ((elContentWidth - elBtnWidth) * -1) / 2
    }px`;
    contentRef.value.style.bottom = `${elContentHeight * -1 - 15}px`;
  }
};
</script>

<style lang="scss" scoped>
.content-wrapper {
  height: fit-content;
  position: absolute;
  bottom: 40px;
  font-size: 14px;
  font-style: normal;
  padding: 10px 14px;
  background-color: $white;
  box-shadow: 0px 16px 64px rgba(183, 203, 228, 0.5);
  border-radius: 8px;
  color: $dipedive;
  white-space: break-spaces;
  z-index: 100;
}
.left {
  left: 0;
}
.right {
  right: 0;
}
</style>
