import config from '@/../config';

export default {
  getBills: {
    url: config.VITE_BASE_URL + `/spa/business/bills`,
    cache: 'no-cache',
    method: 'GET',
    credentials: 'include',
  },
};
