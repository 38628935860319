<template>
  <div class="recover-new-pass">
    <div class="subtitle potok-text-body-1">
      придумайте новый пароль для аккаунта <br />
      {{ resetEmail }}
    </div>
    <div class="password-container">
      <FieldPasspord
        id="password"
        v-model:password="password"
        placeholder="новый пароль"
        @update:password="onPressKeyPassword"
        @keyup.enter="onSave"
      />
      <p
        v-if="!errorMessagePassword"
        class="potok-text-body-1 color-grey password-tip"
      >
        минимум 8 символов
      </p>
      <div v-if="errorMessagePassword" class="potok-text-body-1 password-error">
        {{ errorMessagePassword }}
      </div>
    </div>
    <PotokButton
      text="сохранить"
      size="large"
      class="mt-6"
      :loading="loading"
      full-width-desktop
      @click="onSave"
    />
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import validators from '@/validators';
import server from '@/server';
import { PotokButton } from 'potok-uikit';
import { useStateStore } from '@/stores/stateStore';
import { useRouter, useRoute } from 'vue-router';
import FieldPasspord from '@/components/_generic/ui/inputs/FieldPasspord.vue';
import tracker from '@/tracker/index.js';

const props = defineProps({
  resetToken: {
    type: [String, undefined],
    required: true,
  },
});

const store = useStateStore();
const router = useRouter();
const route = useRoute();

const password = ref(null);

const errorMessagePassword = ref(null);

const loading = ref(false);

const resetEmail = computed(() => {
  let result = '';
  for (let query in route.query) {
    const email = query.match('email')

    if (email) {
      result = route.query[email['input']]
    }
  }
  return result;
});

const isValidPassword = () => {
  if (!password.value) {
    errorMessagePassword.value = 'обязательное поле';
    return false;
  }
  if (password.value.length < 8) {
    errorMessagePassword.value = 'минимальная длина пароля 8 символов';
    return false;
  }
  if (password.value.length > 32) {
    errorMessagePassword.value = 'максимальная длина пароля 32 символов';
    return false;
  }
  if (!validators.passwordAlpha.$validator(password.value)) {
    errorMessagePassword.value =
      'пароль должен состоять из строчных и/или заглавных букв латинского алфавита (A—Z), цифр и знаков препинания.';
    return false;
  }
  return true;
};

const onPressKeyPassword = () => {
  errorMessagePassword.value = null;
};

const onSave = () => {
  if (!isValidPassword()) {
    return;
  }

  changeRecoverPassword();
};

const changeRecoverPassword = () => {
  loading.value = true;

  const query = {
    user: {
      password: password.value,
      password_confirmation: password.value,
      reset_password_token: props.resetToken,
    },
  };

  server.putChangeRecoverPassword
    .send(query)
    .pipe(onSuccessChangeRecoverPassword, (error) => {
      loading.value = false;
      errorMessagePassword.value = error.data?.messages[0] || 'Неизвестная ошибка';
    })
    .exec();
};
const onSuccessChangeRecoverPassword = () => {
  loading.value = false;
  tracker.queue(tracker.commands.SEND, 'SPA_LK_Password_reset_success');
  store.setNotification({
    message: 'чтобы продолжить войдите в Поток с новым паролем',
    title: 'пароль изменен',
    timer: 5000,
    isError: false,
  });
  router.push({ name: 'login' });
};
</script>

<style lang="scss" scoped>
.subtitle {
  text-align: center;
}

.password-container {
  margin-top: 30px;
}

.password-tip {
  margin-top: 8px;
}
.password-error {
  color: $red;
  margin-top: 8px;
}
</style>
