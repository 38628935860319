<template>
  <component
    :is="currentComponent"
    v-if="messageForStatus"
    :message="messageForStatus"
  />
  <component :is="currentComponent" v-if="!messageForStatus" />
</template>

<script setup>
import { computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import constants from '@/constants';
import LoanCardVKL from '@/components/auth/loan-card/vkl-flow/LoanCardVKL.vue';
import LoanCardMain from '@/components/auth/loan-card/main-flow/LoanCardMain.vue';
import Rejected from '@/components/auth/loan-card/pages/Rejected.vue';
import { onMounted } from 'vue';
import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';
import { useStateStore } from '@/stores/stateStore';
import MessageStatus from '@/components/auth/loan-card/vkl-flow/MessageStatus.vue';

const { getUserDataAsync } = loanCardShared();

const store = useStateStore();
const router = useRouter();

const currentComponent = computed(() => {
  if (
    store.isVKLFlow &&
    (store.currentOrder.client_state ===
      constants.CLIENT_STATES.custom_params_asking ||
      store.currentOrder.client_state === constants.CLIENT_STATES.onboarding ||
      store.currentOrder.client_state === constants.CLIENT_STATES.billing)
  ) {
    return MessageStatus;
  }
  if (store.isVKLFlowRequiredUpdateLimit) {
    return MessageStatus;
  }

  if (store.currentOrder.client_state === constants.CLIENT_STATES.rejected) {
    return Rejected;
  }
  if (store.isVKLFlow) {
    return LoanCardVKL;
  }

  if (!store.isVKLFlow) {
    return LoanCardMain;
  }
  return null;
});

const messageForStatus = computed(() => {
  if (
    store.isVKLFlow &&
    (store.currentOrder.client_state ===
      constants.CLIENT_STATES.custom_params_asking ||
      store.currentOrder.client_state === constants.CLIENT_STATES.onboarding)
  ) {
    return 'перейдите на главную страницу';
  }
  if (
    store.isVKLFlow &&
    store.currentOrder.client_state === constants.CLIENT_STATES.billing
  ) {
    return 'нет заявки в процессе оформления';
  }
  if (store.isVKLFlowRequiredUpdateLimit) {
    return 'требуется обновить лимиты';
  }
  return null;
});

watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    router.push({
      name: 'dashboard',
    });
  },
);
onMounted(() => {
  getUserDataAsync();
});
</script>
<style lang="scss" scoped>
:deep(.container-fluid) {
  background-color: $background;
  min-height: 100%;
}

:deep(.title) {
  font-size: 30px;
  line-height: 36px;
  padding: 30px 0;

  @media (max-width: $size_575) {
    font-size: 20px;
    padding: 20px 0px;
  }
}

:deep(.form) {
  max-height: 650px;
  width: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 20px;

  &:first-child {
    margin-bottom: 20px;

    @media (max-width: $size_767) {
      margin-bottom: 16px;
    }
  }

  &:last-child {
    max-width: 373px;

    @media (max-width: $size_767) {
      max-width: 100%;
    }
  }

  @media (max-width: $size_767) {
    margin-bottom: 16px;
  }
}

:deep(.form__title) {
  font-weight: 500;
  font-size: 20px;
  line-height: 26px;
  color: $dipedive;
  margin-bottom: 10px;
}

:deep(.form__menu) {
  .form__menu-item {
    padding: 20px 0px;
    cursor: pointer;
    white-space: pre-wrap;

    @media (max-width: $size_767) {
      padding: 16px 0px;
    }

    &:not(:first-child) {
      padding: 20px 0px;
      border-top: 1px solid rgba($grey, 0.2);
    }

    &:hover:not(:first-child) {
      border-top: 1px solid rgba($grey, 0);
    }

    &:hover {
      background: $background;
      border-radius: 14px;
      padding: 20px;
    }

    &:hover + .form__menu-item {
      border-top: 1px solid rgba($grey, 0);
    }
  }
}

:deep(.correct) {
  width: 18px;
  height: 18px;
  background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
  background-repeat: no-repeat;
  background-position: center;
  filter: $filter-breakwater;
}

:deep(.notices-block) {
  background-color: #e8ebee;
  padding: 20px;
  border-radius: 14px;
}

:deep(.loan-content-wrapper) {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .loan-content-wrapper-left {
    flex: 2;
    margin-right: 30px;

    @media (max-width: $size_767) {
      width: 100%;
      margin-right: initial;
      flex: initial;
    }
  }

  .loan-content-wrapper-right {
    flex: 1;
    height: fit-content;

    @media (max-width: $size_767) {
      width: 100%;
      flex: initial;
    }
  }
}

:deep(.loan-actions-block) {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 50px;

  button {
    margin-right: 20px;
    width: initial;

    &:last-child {
      margin-right: initial;
    }

    @media (max-width: $size_767) {
      margin-right: initial;
      margin-top: 20px;
      width: 100%;
    }
  }
}
</style>
