const CLIENT_STATES = {
  rejected: 'rejected',
  billing: 'billing',
  auto_scoring: 'auto_scoring',
  manual_check: 'manual_check',
  investing: 'investing',
  ready: 'ready',
  custom_params_asking: 'custom_params_asking',
  onboarding: 'onboarding',
};
const ORDER_STATES = {
  // скоринг
  waiting_decision_system_inn: 'waiting_decision_system_inn',
  waiting_decision_system_founders: 'waiting_decision_system_founders',
  waiting_decision_system_statements: 'waiting_decision_system_statements',
  waiting_for_decision: 'waiting_for_decision',
  // загрузка выписки (тут уже есть учреды из КонтрФокуса и решение от СПР)
  statement_file_assessing: 'statement_file_assessing',
  done_decision_system_inn: 'done_decision_system_inn',
  // заполнение анкеты
  founders_assessing: 'founders_assessing',
  filling_borrower_profile: 'filling_borrower_profile',
  filling_full_borrower_profile: 'filling_full_borrower_profile',
  done_decision_system_statements: 'done_decision_system_statements',
  // выбор условий займа
  custom_params_asking: 'custom_params_asking',
  // рассмотрение и оформление
  user_verifying: 'user_verifying',
  // между user_verifying и investing
  ready_for_billing: 'ready_for_billing',
  sent_to_billing: 'sent_to_billing',
  // загрузка контрольной выписки
  control_statement_file_assessing: 'control_statement_file_assessing',
  // мониторинг перед выдачей
  monitoring: 'monitoring',
  // отказ
  rejected: 'rejected',
  // пользователь нажал "удалить заявку"
  self_destructed: 'self_destructed',
  // закрыта по просьбе пользователя (сейлзом из админки)
  user_destructed: 'user_destructed',
  // закрыта
  done: 'done',
};

const ORDER_STATES_RU = {
  waiting_decision_system_founders: 'оформление',
  waiting_for_decision: 'оформление',
  waiting_decision_system_inn: 'оформление',
  waiting_decision_system_statements: 'оформление',
  statement_file_assessing: 'оформление',
  done_decision_system_inn: 'оформление',
  founders_assessing: 'оформление',
  filling_borrower_profile: 'оформление',
  filling_full_borrower_profile: 'оформление',
  done_decision_system_statements: 'оформление',
  custom_params_asking: 'оформление',
  user_verifying: 'оформление',
  ready_for_billing: 'оформление',
  sent_to_billing: 'оформление',
  control_statement_file_assessing: 'оформление',
  monitoring: 'готова к выдаче',
  rejected: 'не сейчас',
  self_destructed: 'не сейчас',
  user_destructed: 'закрыта',
  done: 'закрыта',
};

/*
  statement_file_assessing - не загружена
  waiting_decision_system_statements - скоринг по выписке
  done_decision_system_statements - скоринг по выписке прошел
  */
const STATES_FLOW = [
  ORDER_STATES.waiting_decision_system_inn,
  ORDER_STATES.done_decision_system_inn,
  ORDER_STATES.statement_file_assessing,
  ORDER_STATES.waiting_decision_system_statements,
  ORDER_STATES.done_decision_system_statements,
  ORDER_STATES.filling_borrower_profile,
  ORDER_STATES.filling_full_borrower_profile,
  ORDER_STATES.founders_assessing,
  ORDER_STATES.waiting_decision_system_founders,
  ORDER_STATES.custom_params_asking,
  ORDER_STATES.user_verifying,
  ORDER_STATES.ready_for_billing,
  ORDER_STATES.sent_to_billing,
  ORDER_STATES.control_statement_file_assessing,
  ORDER_STATES.monitoring,
  ORDER_STATES.rejected,
  ORDER_STATES.self_destructed,
  ORDER_STATES.user_destructed,
  ORDER_STATES.done,
];

const SCORING_STATES = [
  ORDER_STATES.waiting_decision_system_inn,
  ORDER_STATES.waiting_decision_system_founders,
  ORDER_STATES.waiting_decision_system_statements,
  ORDER_STATES.waiting_for_decision,
];

const LOAN_STATES = {
  investing: 'investing',
  cancelled: 'cancelled',
  invested: 'invested',
  billing: 'billing',
  overdue: 'overdue',
  in_default: 'in_default',
  in_cession: 'in_cession',
  repaid: 'repaid',
  expired: 'expired',
};

const getLoanStatesRu = (isOrder) => {
  return {
    investing: 'на сборе',
    cancelled: 'не сейчас',
    invested: isOrder ? 'готова к выдаче' : 'готов к выдаче',
    billing: isOrder ? 'активная' : 'активный',
    overdue: isOrder ? 'просрочена' : 'просрочен',
    in_default: 'в дефолте',
    in_cession: 'в цессии',
    repaid: isOrder ? 'закрыта' : 'закрыт',
    expired: isOrder ? 'закрыта' : 'закрыт',
  };
};

const MANUAL_SCORING_STATES = {
  // одобрено кредитным комитетом (выбранные условия)
  approved_by_credit_committee: 'approved_by_credit_committee',
  // автовыдача
  auto_issuance: 'auto_issuance',
};

const ADDITIONAL_DOC_TYPES = {
  military_card: 'military_card',
  rent_document: 'rent_document',
  leasing_agreements_table: 'leasing_agreements_table',
  osv: 'osv',
  other_documents: 'other_documents',
  payments_schedule: 'payments_schedule',
  previous_passport: 'previous_passport',
};

const ADDITIONAL_DOC_EXTENSIONS = ['gif', 'jpg', 'jpeg', 'png', 'webp', 'pdf'];

const PASSPORT_FIELD_TYPES = {
  inn: 'inn',
  series: 'series',
  number: 'number',
  department_code: 'department_code',
  issued_date: 'issued_date',
  issued_by: 'issued_by',
  birthday: 'birthday',
  citizenship: 'citizenship',
  place_of_birth: 'place_of_birth',
  registration_address: 'registration_address',
};

const PASSPORT_FILE_EXTENSIONS = ['gif', 'jpg', 'jpeg', 'png', 'webp', 'pdf'];

const PARSING_STATES = {
  not_parsed: 'not_parsed',
  waiting_for_parsing: 'waiting_for_parsing',
  parsed_with_success: 'parsed_with_success',
  parsed_with_failure: 'parsed_with_failure',
  approved_by_user: 'approved_by_user',
};

const PRODUCT_TYPE = {
  main: 'main',
  installment: 'installment', // рассрочка
  api_partner_full: 'api_partner_full',
  express_wildberries: 'express_wildberries',
  factoring: 'factoring',
};

const SCHEDULE_TYPE = {
  ordinary: 'ordinary',
  annuitet: 'annuitet',
};

// варианты отображения процентной ставки (см. ORD-1362)
const VISUAL_RATE_MODES = {
  yearly: 'yearly',
  monthly: 'monthly',
  hidden: 'hidden',
};

// шаги при обновлении лимита
const LIMIT_UPDATE_STATES = {
  company: 'company',
  statement: 'statement',
  agreement: 'agreement',
};

const COMPANY_STATES = {
  banned: 'banned',
  onboarding: 'onboarding',
  active: 'active',
};

export default {
  CLIENT_STATES,
  ORDER_STATES,
  ORDER_STATES_RU,
  STATES_FLOW,
  SCORING_STATES,
  LOAN_STATES,
  getLoanStatesRu,
  MANUAL_SCORING_STATES,
  ADDITIONAL_DOC_TYPES,
  ADDITIONAL_DOC_EXTENSIONS,
  PASSPORT_FIELD_TYPES,
  PASSPORT_FILE_EXTENSIONS,
  PARSING_STATES,
  PRODUCT_TYPE,
  SCHEDULE_TYPE,
  VISUAL_RATE_MODES,
  LIMIT_UPDATE_STATES,
  COMPANY_STATES,
};
