import { ref, computed } from 'vue';
import server from '@/server/index';
import Countdown from '@/modules/Countdown';
import { useStateStore } from '@/stores/stateStore';
import { formatCurrency, formatDate } from '@/utils';
import { useRouter } from 'vue-router';

export function loansUtils() {
  const store = useStateStore();
  const loading = ref(false);
  const loans = ref([]);
  const selectedLoanId = ref(null);
  const router = useRouter();
  const countdown = new Countdown();
  const leftTime = ref(0);

  const activeLoans = computed(() => {
    return loans.value.map((i) => {
      return {
        value: i.id,
        title: `<span class="text-title">${formatCurrency(
          i.amount,
          0,
        )} ₽</span> — №${i.id} от ${formatDate(i.started_at)}`,
        data: i,
      };
    });
  });

  const currentLoanNotificationType = computed(() => {
    if (!selectedLoanId.value || !loans.value || !loans.value.length === 0) {
      return;
    }
    return selectedLoan.value.early_payments_notification_type;
  });

  const isInfoStatus = computed(() => {
    if (!currentLoanNotificationType.value) {
      return true;
    }
    if (
      currentLoanNotificationType.value === 'success' ||
      currentLoanNotificationType.value === 'already_launched'
    ) {
      return true;
    }
    return false;
  });

  const selectedLoan = computed(() => {
    return loans.value.find((i) => i.id === selectedLoanId.value);
  });

  const defaultTitle = computed(() => {
    if (activeLoans.value.length === 0) {
      return 'нет активных займов';
    }
    return '';
  });

  const getLoansAsync = (options) => {
    loading.value = true;
    return server.getLoans
      .send(
        {
          ...options,
          company_ids: [store.currentCompany.id],
        },
        {},
      )
      .pipe(onGetLoansAsyncSuccess, onGetLoansAsyncError)
      .exec();
  };
  const onGetLoansAsyncSuccess = ({ data }) => {
    loading.value = false;
    loans.value = data;
    if (activeLoans.value && activeLoans.value.length > 0) {
      selectedLoanId.value = activeLoans.value[0].value;
    }
  };
  const onGetLoansAsyncError = () => {
    loading.value = false;
  };

  const onClickCancel = () => {
    if (selectedLoan.value && selectedLoan.value.order_id) {
      router.push({
        name: 'orderCard',
        params: {
          order_id: selectedLoan.value.order_id,
        },
      });
    } else {
      router.push({
        name: 'dashboard',
      });
    }
  };

  const startCountdown = () => {
    countdown.onTick = (val) => {
      leftTime.value = val;
    };
    countdown.reset();
    countdown.start();
  };

  return {
    loading,
    loans,
    currentLoanNotificationType,
    isInfoStatus,
    selectedLoan,
    defaultTitle,
    selectedLoanId,
    activeLoans,
    leftTime,
    getLoansAsync,
    onClickCancel,
    startCountdown,
  };
}
