import config from '@/../config';

export default {
  getNotices: {
    url: config.VITE_BASE_URL + `/spa/business/notices`,
    method: 'GET',
    credentials: 'include',
  },
  putNotice: {
    url: config.VITE_BASE_URL + `/spa/business/notices/$uuid`,
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  },
};
