<template>
  <component
    :is="currentComponent"
    :countdown="countdown"
    @change-component="changeComponent"
  ></component>
</template>

<script setup>
import { shallowRef, onMounted } from 'vue';
import AskInn from '@/components/unauth/main-flow/modules/AskInn.vue';
import PhoneConfirmation from '@/components/unauth/main-flow/modules/PhoneConfirmation.vue';
import SetUserPassword from '@/components/unauth/main-flow/modules/SetUserPassword.vue';
import SetUserEmail from '@/components/unauth/main-flow/modules/SetUserEmail.vue';
import Countdown from '@/modules/Countdown';
import { useRoute } from 'vue-router';

const route = useRoute();

// STEPS: AskInn-> AskUserPhone -> PhoneConfirmation -> SetUserPassword -> SetUserEmail -> BankStatement
const currentComponent = shallowRef(AskInn);

const countdown = new Countdown();

const changeComponent = (newComponentName) => {
  currentComponent.value = newComponentName;
};

const getStep = (step_id) => {
  if (step_id === 2) return PhoneConfirmation;
  if (step_id === 3) return SetUserPassword;
  if (step_id === 4) return SetUserEmail;
  return null;
};

onMounted(() => {
  if (route.query?.step) {
    const step = getStep(parseInt(route.query.step));
    changeComponent(step);
  }
});
</script>
