<template>
  <div class="container-page pt-7">
    <component
      :is="currentComponent"
      :dictionary="industry_dictionary_category"
      :loading="loading"
      :industry-category="parseIndustryCategory"
      @change-component="changeComponent"
      @redirect-to-user-cabinet="redirectToUserCabinet"
      @put-company-profiles="putCompanyProfiles"
    ></component>
  </div>
</template>

<script setup>
import { ref, shallowRef, onMounted, computed, inject } from 'vue';
import AboutCompany from '@/components/auth/company-profile/modules/AboutCompany.vue';
import CompanyLocation from '@/components/auth/company-profile/modules/CompanyLocation.vue';
import CompanySites from '@/components/auth/company-profile/modules/CompanySites.vue';
import SummaryInfo from '@/components/auth/company-profile/modules/SummaryInfo.vue';
import server from '@/server/index';
import { useStateStore } from '@/stores/stateStore';
import tracker from '@/tracker';
import { TRACKER_EVENTS } from '@/tracker/events';

const { SPA_LK_Business_open, SPA_LK_Later } = TRACKER_EVENTS;

// STEPS: AboutCompany -> CompanyLocation -> CompanySites -> SummaryInfo -> (opt.) GeneralInfo

const store = useStateStore();
const currentComponent = shallowRef(AboutCompany);
const loading = ref(false);
const industry_dictionary_category = ref([]);
const emitter = inject('emitter');

onMounted(() => {
  getIndustryDictionaryCategories();
  tracker.queue(tracker.commands.SEND, SPA_LK_Business_open);
});

// парсим массив категорий на предмет совпадений с industry_dictionary_category_id
const parseIndustryCategory = computed(() => {
  let findEl;
  Object.values(industry_dictionary_category.value).forEach((el) =>
    el.child_categories.find((e) =>
      e.id === store.currentCompanyInfo.industry_dictionary_category_id
        ? (findEl = e.name)
        : null,
    ),
  );
  return findEl;
});

// при исполнении всех запросов, запускаем функцию для выявления нужного шага, если какие-то поля уже заполнены
const detectStep = () => {
  if (
    !store.currentCompanyInfo.industry_dictionary_category_id ||
    !store.currentCompanyInfo.primary_occupation
  ) {
    return;
  } else if (
    !store.currentCompanyInfo.business_location &&
    !store.currentCompanyInfo.business_location_eq_company_address
  ) {
    changeComponent(CompanyLocation);
  } else if (
    store.currentCompanyInfo.sites[0] === '' &&
    !store.currentCompanyInfo.no_site
  ) {
    changeComponent(CompanySites);
  } else {
    changeComponent(SummaryInfo);
  }
};
const changeComponent = (newComponentName) => {
  currentComponent.value = newComponentName;
};
const getIndustryDictionaryCategories = () => {
  return server.getIndustryDictionaryCategories
    .send()
    .pipe(
      onGetIndustryDictionaryCategoriesSuccess,
      onGetIndustryDictionaryCategoriesError,
    )
    .exec();
};
const onGetIndustryDictionaryCategoriesSuccess = ({ data }) => {
  industry_dictionary_category.value = data;
  getCompanyProfiles();
};
const onGetIndustryDictionaryCategoriesError = () => {
  loading.value = false;
};
const getCompanyProfiles = () => {
  const query = {
    company_ids: store.currentCompany.id,
  };
  return server.getCompanyProfiles
    .send(query, {})
    .pipe(onGetCompanyProfilesSuccess, onGetCompanyProfilesError)
    .exec();
};
/* Устанавливаем значения переменных Sites = [''] и no_site = false, т.к. в случае нового юзера, 
они придут с бэка со значением null, что мешает фреймворку отрисовывать компоненты на странице сайта и no_site = null будет
не валидным при отправке запроса, т.к. на бэке проверка на !null */
const onGetCompanyProfilesSuccess = ({ data }) => {
  data[0].sites?.length ? null : (data[0].sites = ['']);
  data[0].no_site ? null : (data[0].no_site = false);
  store.setCurrentCompanyInfo(data[0]);
  detectStep();
  loading.value = false;
};
const onGetCompanyProfilesError = () => {
  loading.value = false;
};
const putCompanyProfiles = (query, { onSuccess } = {}) => {
  loading.value = true;
  const onPutCompanyProfilesSuccess = () => {
    if (onSuccess) {
      onSuccess();
    }
    loading.value = false;
  };
  return server.putCompanyProfiles$company_id
    .send(
      {
        company_profile: query,
      },
      {
        params: {
          company_id: store.currentCompany.id,
        },
      },
    )
    .pipe(onPutCompanyProfilesSuccess.bind(this), onPutCompanyProfilesError)
    .exec();
};
const onPutCompanyProfilesError = () => {
  loading.value = false;
};
const redirectToUserCabinet = () => {
  tracker.queue(tracker.commands.SEND, SPA_LK_Later);
  emitter.emit('to-dashboard');
};
</script>

<style lang="scss" scoped>
.container-page {
  max-width: 460px;
}
</style>
