<template>
  <div class="container-fluid">
    <div class="container">
      <div>
        <div class="d-flex align-items-center">
          <div class="title fw-bold ms-3">{{ props.message }}</div>
        </div>

        <div class="loan-content-wrapper">
          <div class="loan-content-wrapper-left">
            <div class="loan-actions-block">
              <PotokButton
                text="на главную"
                size="medium"
                theme="secondary"
                @click="onClickGoDashboard"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { PotokButton } from 'potok-uikit';
import { loanCardVKLUtils } from '@/components/auth/loan-card/vkl-flow/utils/loanCardVKLUtils.js';

const { onClickGoDashboard } = loanCardVKLUtils();
const props = defineProps({
  message: {
    type: String,
    default: '',
  },
});
</script>
<style lang="scss" scoped></style>
