<template>
  <div>
    <template v-for="(item, index) in payments" :key="index">
      <div class="payment-item-row" @click="onClickRow(item)">
        <div class="payment-item-type">
          <PopoverIcon position="right_center" class="text-nowrap">
            <template #icon>
              <div
                class="payment-item-icon"
                :class="getStatusClassPayment(item)"
              ></div>
            </template>
            <template #text>
              <div class="payment-item-icon-content">
                {{ getStatusTitlePayment(item) }}
              </div>
            </template>
          </PopoverIcon>
        </div>
        <div class="payment-item-date">{{ formatDate(item.date) }}</div>
        <div class="payment-item-amount">
          <div>{{ formatCurrency(getAmountPayment(item), 0) }} ₽</div>
        </div>
        <div>
          <div
            :class="{
              'icon-arrow-down': !item.open,
              'icon-arrow-up': item.open,
            }"
          ></div>
        </div>
      </div>
      <div v-if="item.open" class="payment-item-extend">
        <component :is="getComponentHit(item)" :item="item" />
      </div>
    </template>
  </div>
</template>

<script setup>
import PaymentHitOneRefunded from '@/components/auth/dashboard/payment-summary/hit/PaymentHitOneRefunded.vue';
import PaymentHitOneRefundedAfterOverdue from '@/components/auth/dashboard/payment-summary/hit/PaymentHitOneRefundedAfterOverdue.vue';
import PaymentHitOneOverdue from '@/components/auth/dashboard/payment-summary/hit/PaymentHitOneOverdue.vue';
import PaymentHitOneAwait from '@/components/auth/dashboard/payment-summary/hit/PaymentHitOneAwait.vue';
import PaymentHitOneActive from '@/components/auth/dashboard/payment-summary/hit/PaymentHitOneActive.vue';
import PaymentHitMany from '@/components/auth/dashboard/payment-summary/hit/PaymentHitMany.vue';
import PopoverIcon from '@/components/_generic/ui/widgets/PopoverIcon.vue';

import { formatCurrency, formatDate } from '@/utils';
import { toRefs } from 'vue';
import { useLoanPaymentUtils } from '@/components/auth/dashboard/modules/loan-card/ui/modules/loan-payment-summary/utils';

const { getAmountPayment, getStatusTitlePayment, getStatusClassPayment } =
  useLoanPaymentUtils();
const props = defineProps({
  payments: {
    type: Array,
    required: true,
    default() {
      return [];
    },
  },
});
const { payments } = toRefs(props);

const getComponentHit = (item) => {
  if (item.payments.length === 1) {
    if (item.status === 'REFUNDED') {
      return PaymentHitOneRefunded;
    }
    if (item.status === 'REFUNDED_AFTER_OVERDUE') {
      return PaymentHitOneRefundedAfterOverdue;
    }
    if (item.status === 'OVERDUE') {
      return PaymentHitOneOverdue;
    }
    if (item.status === 'AWAIT') {
      return PaymentHitOneAwait;
    }
    if (item.status === 'ACTIVE') {
      return PaymentHitOneActive;
    }
  }
  if (item.payments.length > 1) {
    return PaymentHitMany;
  }
};

const onClickRow = (item) => {
  item.open = !item.open;
};
</script>

<style lang="scss" scoped>
.payment-item-row {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $dipedive;
  padding: 14px 10px;
  border-bottom: 1px solid $dividers;
  text-align: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  &:hover {
    background-color: $background;
    cursor: pointer;
  }
}
.payment-item-date {
  min-width: 150px;
}
.payment-item-amount {
  min-width: 150px;
}
.payment-item-type {
  min-width: 20px;
}
.payment-item-icon {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: $dividers;
}
.payment-item-icon-red {
  background-color: $red;
}
.payment-item-icon-yellow {
  background-image: url(@/assets/icons/rounded/yellow_ok.svg);
  background-color: initial;
  background-size: cover;
  border-radius: initial;
}
.payment-item-icon-green {
  background-image: url(@/assets/icons/rounded/green_ok.svg);
  background-color: initial;
  background-size: cover;
  border-radius: initial;
}
.payment-item-icon-content {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-transform: lowercase;
  color: $dipedive;
}
.payment-item-extend {
  background-color: $background;
  padding: 10px 20px;
  border-radius: 8px;
}
</style>
