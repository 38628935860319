<template>
  <div class="p-4 bg-white rounded-4 mb-6">
    <Loader v-if="loading" class="mt-3" />
    <template v-if="!loading">
      <div>
        <FormInput :text="heads.full_name" :placeholder="'ФИО'"></FormInput>
      </div>
      <div class="divider my-4"></div>
      <div>
        <FormInput :text="heads.phone" :placeholder="'Телефон'"></FormInput>
      </div>
      <div class="divider my-4"></div>
      <div>
        <FormInput :text="heads.email" :placeholder="'Почта'"></FormInput>
      </div>
      <div class="divider my-4"></div>
      <div class="passport-box">
        <FormInput
          :text="statusPassportUploaded"
          :placeholder="'Документы'"
        ></FormInput>
        <div
          v-if="heads"
          class="btn-text btn-text-tiffany btn-passport"
          @click="onClickFillPassword"
        >
          <span v-if="!heads?.passport_uploaded"
            >заполнить {{ `\u{2192}` }}</span
          >
          <span v-if="heads?.passport_uploaded">обновить {{ `\u{2192}` }}</span>
          <i class="input-icon"></i>
        </div>
      </div>
    </template>
  </div>

  <UploadPassportModal
    v-if="isPassportModalOpen"
    v-model:isPassportUploaded="isPassportUploaded"
    v-model:isPassportPartiallyUploaded="isPassportPartiallyUploaded"
    :is-docs-signed="false"
    :user-name="heads.full_name"
    :attachable-id="store.currentUser.id"
    :attachable-type="'User'"
    @close-modal="closeModal"
  />
</template>
<script setup>
import { ref, onMounted, watch } from 'vue';
import { Loader } from 'potok-uikit';
import FormInput from '@/components/_generic/ui/inputs/FormInput.vue';
import UploadPassportModal from '@/components/_generic/auth/upload-passport/UploadPassportModal.vue';
import { loanCardShared } from '@/components/auth/loan-card/utils/loanCardShared.js';
import { useStateStore } from '@/stores/stateStore';
import server from '@/server/index';

const store = useStateStore();

const loading = ref(false);
const heads = ref({});

const { isPassportModalOpen, isPassportUploaded, isPassportPartiallyUploaded } =
  loanCardShared();

const statusPassportUploaded = ref(null);

const closeModal = () => {
  isPassportModalOpen.value = false;
};

const getHeadsAsync = (companyId) => {
  loading.value = true;
  const query = {
    'company_ids[]': [companyId],
  };
  return server.getHeads
    .send(query, {})
    .pipe(onGetUserDataAsyncSuccess, () => {
      loading.value = false;
    })
    .exec();
};
const onGetUserDataAsyncSuccess = (obj) => {
  loading.value = false;
  if (!obj) {
    return;
  }
  const result = obj.data;
  if (result.length === 0) {
    return;
  }
  return result[0];
};

const getHeads = async () => {
  heads.value = await getHeadsAsync(store.currentCompany.id);
  if (heads.value.passport_uploaded) {
    statusPassportUploaded.value = 'заполнено';
  } else {
    statusPassportUploaded.value = 'нет данных';
  }
};

const onClickFillPassword = () => {
  isPassportModalOpen.value = true;
};

const initPage = () => {
  getHeads();
};

watch(
  () => {
    return store.currentCompany.id;
  },
  () => {
    initPage();
  },
);
onMounted(() => {
  initPage();
});
</script>
<style lang="scss" scoped>
.passport-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
