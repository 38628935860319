<template>
  <div class="d-flex align-items-center">
    <AbstractCurrencyInput
      v-model="_modelValue"
      v-model:isFocused="_isFocused"
      class="input"
      :class="{
        'cursor-pointer': !props.disabled,
      }"
      :options="props.options"
      :disabled="props.disabled"
    />
    <i
      v-if="!props.disabled"
      class="icon-edit"
      @click="emit('update:isFocused', true)"
    ></i>
  </div>
</template>

<script setup>
import AbstractCurrencyInput from '@/components/_generic/ui/inputs/AbstractCurrencyInput.vue';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Number,
    default: 0,
  },
  options: {
    type: Object,
    default() {
      return {};
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  isFocused: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['update:isFocused', 'update:modelValue']);

const _modelValue = computed({
  get() {
    return props.modelValue;
  },
  set(_value) {
    let value = _value;
    if (props.options?.valueRange?.max) {
      if (value > props.options.valueRange.max) {
        value = props.options.valueRange.max;
      }
    }
    if (props.options?.valueRange?.min) {
      if (value < props.options.valueRange.min) {
        value = props.options.valueRange.min;
      }
    }
    emit('update:modelValue', value);
  },
});
const _isFocused = computed({
  get() {
    return props.isFocused;
  },
  set(value) {
    emit('update:isFocused', value);
  },
});
</script>

<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.input {
  font-size: 20px;
  border: none;
  width: 140px;
  background-color: transparent;
  &:focus-visible {
    outline: none;
  }
}
.icon-edit {
  width: 20px;
  height: 20px;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  background-image: url(@/assets/icons/edit/edit-icon.svg);
}
</style>
