<template>
  <div :class="{ 'form-blur pe-none': isApplyBlur }">
    <div
      class="form bg-white w-100 py-sm-7 py-4 px-sm-6 px-4 mb-6 d-block d-sm-flex align-items-center justify-content-between text-center text-sm-start"
    >
      <div class="d-block d-sm-flex align-items-center">
        <i class="profile me-sm-6 me-0 mb-sm-0 mb-3"></i
        ><span class="d-block d-sm-inline mb-sm-0 mb-3"
          >расскажите нам о вашем<br />
          бизнесе</span
        >
      </div>
      <div class="btn-wrapper" @click="goToChosenStep('company_profile')">
        <PotokButton
          v-if="!store.isLimitsCompanyFilled"
          text="описать бизнес"
          size="medium"
          theme="secondary"
        />
        <div
          v-if="store.isLimitsCompanyFilled"
          class="d-flex justify-content-sm-end justify-content-center"
        >
          <div
            class="success-wrapper d-flex align-items-center justify-content-end"
          >
            <i class="success-icon"></i
            ><span class="ms-2 text-tiffany">выполнено</span>
          </div>
          <div class="edit-wrapper">
            <i class="edit-icon"></i>
          </div>
        </div>
      </div>
    </div>
    <div
      class="form bg-white py-sm-7 py-4 px-sm-6 px-4 mb-6 d-block d-sm-flex align-items-center justify-content-between text-center text-sm-start"
    >
      <div class="d-block d-sm-flex align-items-center">
        <i class="agreement me-sm-6 me-0 mb-sm-0 mb-3"></i
        ><span class="d-block d-sm-inline mb-sm-0 mb-3"
          >заполните согласие на проверку<br />
          кредитной истории</span
        >
      </div>
      <div
        v-if="isBKIBtnShowOnPartnersFlow"
        class="btn-wrapper"
        @click="goToChosenStep('user_passports')"
      >
        <PotokButton
          v-if="!store.isLimitsBkiSigned"
          text="заполнить анкету<"
          size="medium"
          theme="secondary"
        />
        <div
          v-if="store.isLimitsBkiSigned"
          class="d-flex justify-content-sm-end justify-content-center"
        >
          <div
            class="success-wrapper d-flex align-items-center justify-content-end"
          >
            <i class="success-icon"></i
            ><span class="ms-2 text-tiffany">выполнено</span>
          </div>
          <div class="edit-wrapper">
            <i class="show-icon"></i>
          </div>
        </div>
      </div>
      <div
        v-if="!isBKIBtnShowOnPartnersFlow"
        class="color-grey text-sm-end text-center"
      >
        станет доступно после<br />описания бизнеса
      </div>
    </div>
    <div
      class="form bg-white py-sm-7 py-4 px-sm-6 px-4 mb-sm-0 mb-4 d-block d-sm-flex align-items-center justify-content-between text-center text-sm-start"
    >
      <div class="d-block d-sm-flex align-items-center">
        <i class="statement me-sm-6 me-0 mb-sm-0 mb-3"></i
        ><span class="d-block d-sm-inline mb-sm-0 mb-3"
          >загрузите выписки по счетам<br />
          в формате экспорта для 1С</span
        >
      </div>
      <div class="btn-wrapper" @click="goToChosenStep('bank_statement')">
        <template v-if="isShowBtnUploadStatement">
          <PotokButton
            text="загрузить выписку"
            size="medium"
            theme="secondary"
          />
        </template>
        <template v-if="!isShowBtnUploadStatement">
          <div class="d-flex justify-content-sm-end justify-content-center">
            <div
              class="success-wrapper d-flex align-items-center justify-content-end"
            >
              <i class="success-icon"></i
              ><span class="ms-2 text-tiffany">выполнено</span>
            </div>
            <div class="edit-wrapper">
              <i class="edit-icon"></i>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
  <template v-if="isApplyBlur">
    <span class="await-text text-center"
      >пожалуйста, подождите, проверяем <br />
      предоставленные данные<br /><span class="text-tiffany"
        >обычно это занимает пару минут</span
      >
    </span>
  </template>
</template>

<script setup>
import { PotokButton } from 'potok-uikit';
import { useStateStore } from '@/stores/stateStore';
import { updateLimitUtils } from './utils';
import { computed } from 'vue';
import constants from '@/constants';

const store = useStateStore();
const { goToChosenStep, isBKIBtnShowOnPartnersFlow } = updateLimitUtils();

// блюрим форму на апи_партнер_флоу по этим условиям - все шаги выполнены и нет возможности загрузить доп выписку
const isApplyBlur = computed(() => {
  return (
    store.isAPIPartnersFlow &&
    store.isLimitsCompanyFilled &&
    store.isLimitsBkiSigned &&
    store.isLimitsStatementUploaded &&
    !store.isPartnerStatementRequired &&
    store.isBeforeCustomParams
  );
});
const isShowBtnUploadStatement = computed(() => {
  if (
    store.currentOrder.product_type ===
    constants.PRODUCT_TYPE.express_wildberries
  ) {
    return !store.currentOrder.last_statement_file_uploaded;
  }
  if (!store.isStatementUploaded) {
    return true;
  }
  if (store.isStatementUploaded && store.isPartnerStatementRequired) {
    return true;
  }
  return false;
});
</script>

<style lang="scss" scoped>
.form-blur {
  filter: blur(8px);
}
.form {
  border-radius: 14px;
  border: 1px solid $dividers;
  .await-text {
    position: absolute;
    top: 45%;
    left: 35%;
    transform: translate(-50%, -50%);
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
  }
  i {
    display: inline-block;
    width: 62px;
    height: 58px;
    background-repeat: no-repeat;
    background-position: center;
    &.profile {
      background-image: url(@/assets/icons/onboarding/profile.svg);
    }
    &.statement {
      background-image: url(@/assets/icons/onboarding/statement.svg);
    }
    &.agreement {
      background-image: url(@/assets/icons/onboarding/agreement.svg);
    }
  }
  .btn-wrapper {
    cursor: pointer;
    width: 185px;
    @media (max-width: $size_767) {
      width: auto;
    }
    .success-wrapper {
      background: rgba(4, 204, 217, 0.1);
      padding: 10px;
      border-radius: 8px;
      .success-icon {
        display: inline-block;
        width: 18px;
        height: 18px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(@/assets/icons/rounded/true_icon_pass.svg);
        filter: $filter-tiffany;
      }
    }
    .edit-wrapper {
      background-color: $background;
      border-radius: 8px;
      padding: 12px;
      margin-left: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      .edit-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(@/assets/icons/edit/edit-icon.svg);
      }
      .show-icon {
        display: inline-block;
        width: 20px;
        height: 20px;
        background-repeat: no-repeat;
        background-position: center;
        background-image: url(@/assets/icons/password/toggle_pass.svg);
      }
      &:hover .edit-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.8854 4.53322L5.04374 11.7749C4.78541 12.0499 4.53541 12.5916 4.48541 12.9666L4.17707 15.6666C4.06874 16.6416 4.76874 17.3082 5.73541 17.1416L8.41874 16.6832C8.79374 16.6166 9.31874 16.3416 9.57707 16.0582L16.4187 8.81655C17.6021 7.56655 18.1354 6.14155 16.2937 4.39989C14.4604 2.67489 13.0687 3.28322 11.8854 4.53322Z' fill='%23173b57'/%3E%3Cpath d='M10.7461 5.74023C11.1044 8.04023 12.9711 9.79857 15.2878 10.0319' stroke='white' stroke-width='1.5' stroke-miterlimit='10' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
      &:hover .show-icon {
        background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M0.0775761 7.13678C-0.0258588 7.36784 -0.0258586 7.63211 0.0775763 7.86317C1.43292 10.8908 4.46991 13 7.99906 13C11.5282 13 14.5652 10.8908 15.9205 7.86317C16.024 7.63211 16.024 7.36784 15.9205 7.13678C14.5652 4.10913 11.5282 2 7.99906 2C4.46991 2 1.43292 4.10913 0.0775761 7.13678ZM9.99854 7.5C9.99854 8.60457 9.1031 9.5 7.99854 9.5C6.89397 9.5 5.99854 8.60457 5.99854 7.5C5.99854 6.39543 6.89397 5.5 7.99854 5.5C9.1031 5.5 9.99854 6.39543 9.99854 7.5Z' fill='%23173b57'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
</style>
